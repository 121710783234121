import React, { useState, useEffect } from "react";
import Select from "react-select";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import moment from "moment";
import HawkeyeThumbnail from "./HawkeyeThumbnail";
import { MdArrowUpward, MdArrowDownward } from "react-icons/md";
import NoVideo from "../../../../Images/novideo.png";

import { getTimeOnly } from "../../../../logic/useGetTime";
import { useOpenItem } from "./useOpenItem";

function OpenItem({ item: data, index, tab, userActivity, openMappingModal }) {
  const { item, setReviewQty, reviewQty, onSelect, select } = useOpenItem(
    data,
    userActivity,
    tab
  );

  return (
    <>
      {item && userActivity.length > 0 && (
        <div className="w-full h-[120px] flex justify-between items-center px-3 border border-gray-200 rounded py-3 space-x-[12px]">
          <div className="w-3 h-24 bg-green-500 my-2"></div>

          <div className="w-[10px]">{`${index + 1}.`}</div>

          <div className="h-full flex flex-col items-center justify-center pt-[7px]">
            {item?.productInfos.length === 1 &&
            item?.syncStatus !== "CONFLICT" ? (
              <div style={{ width: "100px" }}>
                <Select
                  value={{
                    label: (
                      <img
                        src={item?.productInfos[0]?.imageUrl}
                        height="25px"
                        width="25px"
                      />
                    ),
                    value: "",
                  }}
                  isDisabled={true}
                />
              </div>
            ) : item?.productInfos?.some(
                (ele) => ele?.productId === item?.productId
              ) && item?.syncStatus === "CONFLICT" ? (
              <div style={{ width: "100px" }}>
                <Select
                  value={{
                    label: (
                      <img
                        alt="image"
                        src={
                          item?.productInfos?.find(
                            (ele) => ele?.productId === item?.productId
                          )?.imageUrl
                        }
                        height="25px"
                        width="25px"
                      />
                    ),
                    value: "",
                  }}
                  onChange={(value) => onSelect(value, "product")}
                  options={data?.productInfos?.map((prod, index) => {
                    return {
                      label: (
                        <img
                          src={prod?.imageUrl}
                          height="30px"
                          width="30px"
                          key={index}
                        />
                      ),
                      value: prod,
                    };
                  })}
                />
              </div>
            ) : item?.productInfos?.some(
                (ele) => ele?.productId === item?.productId
              ) &&
              (item.syncStatus === "CART_REVIEWED" ||
                item.syncStatus === "PROCESSED") ? (
              <div style={{ width: "100px" }}>
                <Select
                  value={{
                    label: (
                      <img
                        src={
                          item?.productInfos?.find(
                            (ele) => ele?.productId === item?.productId
                          )?.imageUrl
                        }
                        height="25px"
                        width="25px"
                      />
                    ),
                    value: "",
                  }}
                  isDisabled={true}
                />
              </div>
            ) : item?.productId === null && item?.syncStatus === "CONFLICT" ? (
              <div style={{ width: "100px" }}>
                <Select
                  value={select?.product}
                  isDisabled={tab === "approved" ? true : false}
                  onChange={(value) => onSelect(value, "product")}
                  options={item?.productInfos?.map((prod, index) => {
                    return {
                      label: (
                        <img
                          src={prod?.imageUrl}
                          height="30px"
                          width="30px"
                          key={index}
                        />
                      ),
                      value: prod,
                    };
                  })}
                />
              </div>
            ) : (
              <div style={{ width: "100px" }}>
                <Select
                  value={select?.product}
                  isDisabled={tab === "approved" ? true : false}
                  onChange={(value) => onSelect(value, "product")}
                  options={data?.productInfos?.map((prod, index) => {
                    return {
                      label: (
                        <img
                          src={prod?.imageUrl}
                          height="30px"
                          width="30px"
                          key={index}
                        />
                      ),
                      value: prod,
                    };
                  })}
                />
              </div>
            )}

            {item?.productInfos.length === 1 && (
              <h2 className="mt-1.5 text-xs w-[160px] ">
                {item?.productInfos[0]?.name}
              </h2>
            )}

            {item?.productInfos.length !== 1 && (
              <h2 className="mt-2 text-xs  w-[160px]">
                {select?.product?.value?.name}
              </h2>
            )}
          </div>

          <div className="h-full flex flex-col justify-center w-[140px]">
            <Select
              value={select?.shopper}
              isDisabled={tab === "approved" ? true : false}
              onChange={(value) => onSelect(value, "shopper")}
              options={userActivity.map((shopper, index) => {
                return {
                  label: <div className="">{`Shopper ${index + 1}`}</div>,
                  value: shopper,
                };
              })}
            />
          </div>

          <div className="w-52 flex flex-col items-center border border-gray-200">
            <div className="p-2 w-full">
              <>
                <div className="flex items-center w-52">
                  {item?.productInfos.length === 1 ? (
                    <>
                      {item?.productInfos[0]?.estimatedQuantity !== "" && (
                        <>
                          <div className="flex items-center text-left">
                            <h2 className="text-xs w-32 font-bold">{`Calculated Quantity`}</h2>
                            <span className="text-xs font-bold mr-1">:</span>
                            {tab === "approved" ? (
                              <p>{item?.productInfos[0]?.estimatedQuantity}</p>
                            ) : (
                              <input
                                className="text-xs font-bold w-[35px] text-center focus:outline-none border-2"
                                value={reviewQty}
                                onChange={(e) => {
                                  setReviewQty(e.target.value);
                                }}
                              />
                            )}
                          </div>
                          {item?.eventType === "PICK" ? (
                            <MdArrowUpward className="w-4 h-4 ml-2 text-green-500 " />
                          ) : (
                            <MdArrowDownward className="w-4 h-4 ml-2 text-red-500 " />
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {select?.product?.value?.estimatedQuantity !==
                        undefined && (
                        <>
                          <div className="flex items-center text-left ">
                            <h2 className="text-xs w-32 font-bold">{`Calculated Quantity`}</h2>
                            <span className="text-xs font-bold mr-1">:</span>
                            {tab === "approved" ? (
                              <p>{item?.productInfos[0]?.estimatedQuantity}</p>
                            ) : (
                              <input
                                className="text-xs font-bold w-[35px] text-center focus:outline-none border-2"
                                value={reviewQty}
                                onChange={(e) => {
                                  setReviewQty(e.target.value);
                                }}
                              />
                            )}
                          </div>

                          {item?.eventType === "PICK" ? (
                            <MdArrowUpward className="w-4 h-4 ml-2 text-green-500 " />
                          ) : (
                            <MdArrowDownward className="w-4 h-4 ml-2 text-red-500 " />
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
                {item?.productInfos.length === 1 ? (
                  <div className="flex items-center text-left">
                    <h2 className="text-xs w-32 font-bold">{`Checkout Weight`}</h2>
                    <h2 className="text-xs font-bold">{`: ${parseFloat(
                      item?.productInfos[0]?.estimatedChangeInWeight
                    )?.toFixed(3)} ${
                      item?.productInfos[0]?.unitOfMeasure
                    }`}</h2>
                  </div>
                ) : (
                  <>
                    {select?.product?.value?.estimatedQuantity !==
                      undefined && (
                      <div className="flex items-center text-left">
                        <h2 className="text-xs w-32 font-bold">{`Checkout Weight`}</h2>
                        <h2 className="text-xs font-bold">{`: ${parseFloat(
                          select?.product?.value?.estimatedChangeInWeight
                        )?.toFixed(3)} ${
                          select?.product?.value?.unitOfMeasure
                        }`}</h2>
                      </div>
                    )}
                  </>
                )}
                {item?.productInfos.length === 1 ? (
                  <div className="flex items-center text-left">
                    <h2 className="text-xs w-32 font-bold">{`Unit Weight`}</h2>
                    <h2 className="text-xs font-bold">{`: ${parseFloat(
                      item?.productInfos[0]?.unitWeight
                    ).toFixed(3)} ${item?.productInfos[0]?.unitOfMeasure}`}</h2>
                  </div>
                ) : (
                  <>
                    {select?.product?.value?.estimatedQuantity !==
                      undefined && (
                      <div className="flex items-center text-left">
                        <h2 className="text-xs w-32 font-bold">{`Unit Weight`}</h2>
                        <h2 className="text-xs font-bold">{`: ${parseFloat(
                          select?.product?.value?.unitWeight
                        ).toFixed(3)} ${
                          select?.product?.value?.unitOfMeasure
                        }`}</h2>
                      </div>
                    )}
                  </>
                )}
              </>

              <div className="flex items-center text-left">
                <h2 className="text-xs w-32 font-bold">{`Shelf ID`}</h2>
                <h2 className="text-xs font-bold">{`: ${
                  item?.groupId ? item?.groupId : "Null"
                }`}</h2>
              </div>
            </div>
          </div>

          <div
            className="flex space-x-[12px] items-center cursor-pointer"
            onClick={() => openMappingModal(item)}
          >
            <div className="w-52 flex flex-col items-center border border-gray-200 ">
              <div className="p-2 w-full">
                <div className="flex items-center text-left">
                  <h2
                    className={`${
                      tab === "approved" ? "text-black" : "text-blue-500"
                    } text-xs  w-24 font-bold`}
                  >{`Scale Position`}</h2>

                  <button className="text-xs font-bold">{`: ${
                    item?.scaleInternalCode ? item?.scaleInternalCode : "-"
                  }`}</button>
                </div>

                <div className="flex items-center text-left">
                  <h2 className="text-xs w-24 font-bold">{`Scale ID`}</h2>
                  <h2 className="text-xs font-bold">{`: ${
                    item?.scaleId ? item?.scaleId : "Null"
                  }`}</h2>
                </div>

                <div className="flex items-center text-left">
                  <h2 className="text-xs w-24 font-bold">{`Event Time`}</h2>
                  <h2 className="text-xs font-bold">{`: ${
                    item?.eventTime
                      ? getTimeOnly(item?.eventTime, "IST")
                      : "Null"
                  }`}</h2>
                </div>

                <div className="flex items-center text-left">
                  <h2 className="text-xs w-24 font-bold">{`Store Time`}</h2>
                  <h2 className="text-xs font-bold">{`: ${
                    item?.eventTime
                      ? getTimeOnly(item?.eventTime, item?.storeTimeZone)
                      : "Null"
                  }`}</h2>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`border-l border-r w-[280px] border-gray-200  h-24 flex items-center justify-start space-x-3 px-2`}
          >
            {item?.storeCartVideoPaths?.length > 0 ? (
              item?.storeCartVideoPaths?.map((obj, index) => {
                return (
                  <HawkeyeThumbnail
                    img={obj?.thumbnail}
                    index={index}
                    videoUrl={obj?.videoUrl}
                    key={index}
                  />
                );
              })
            ) : (
              <img
                src={NoVideo}
                alt="NoVideo"
                className="w-20 h-24 rounded cursor-pointer"
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default OpenItem;
