import React, { useState, useMemo } from "react";
import { TextField2 } from "../../../../../../../validation/TextField2";
import { useHistory } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import Select from "react-select";
import { useAlert } from "react-alert";
import countryList from "react-select-country-list";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { usePut } from "../../../../../../../../api/usePut";
import { v4 as uuidv4 } from "uuid";
import Toggle from "react-toggle";
import { useLocation } from "react-router-dom";

const BrandTypes = [
  { value: "Amul", label: "Amul" },
  { value: "Horliks", label: "Horliks" },
];

let validate;
validate = Yup.object({
  name: Yup.string().required("Required"),
  email: Yup.string().email(),
  zip: Yup.number().typeError("Not a number").required("Required"),
  street1: Yup.string().required("Required"),
  street2: Yup.string(),
  city: Yup.string().required("Required"),
  administrativeArea: Yup.string(),
  latitude: Yup.number().typeError("Not a number"),
  longitude: Yup.number().typeError("Not a number"),
  state: Yup.string().required("Required"),
  contactFirstName: Yup.string().required("Required"),
  contactMiddleName: Yup.string(),
  contactLastName: Yup.string().required("Required"),
  contactNo: Yup.number().required("Required"),
});

const skuType = [
  { value: "STANDARD_SKU", label: "STANDARD SKU" },
  { value: "NON_STANDARD_SKU", label: "NON-STANDARD SKU" },
];

const countrtyList = [
  { label: "Germany", value: "DE" },
  { label: "Hungary", value: "HU" },
  { label: "France", value: "FR" },
];

const getLabel = (value) => {
  const { label } = skuType?.find((ele) => ele.value === value);
  return label;
};

function SupplierEdit() {
  const [title, setTitle] = useState("Supplier");
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const initialValues = location?.state?.initialValues;
  const options = useMemo(() => countryList().getData(), []);
  const [status, setStatus] = useState(initialValues.status);

  const [select, setSelect] = useState({
    country: {
      value: initialValues?.country,
      label: countryList().getLabel(initialValues?.country),
    },
    // country: { value: "", label: getLabel },
    brand: { value: initialValues?.brands, label: initialValues?.brands },
    sku: {
      value: initialValues?.skuType,
      label: getLabel(initialValues?.skuType),
    },
  });

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 55,
      minHeight: 35,
    }),
  };

  const handleSelect = (action, e) => {
    const name = e.name;
    setSelect({
      ...select,
      [name]: { value: action.value, label: action.label },
    });
  };

  const statusChecked = (e) => {
    setStatus(e.target.checked);
  };

  const editMerchant = usePut(
    `${process.env.REACT_APP_BASE_URL}/suppliers/${location?.state?.data?.id}`,
    "supplierList"
  );

  return (
    <>
      <div className="w-full h-screen text-left select-none flex">
        <div className="w-full h-full flex justify-center items-center bg-gray-200 ">
          <Formik
            initialValues={initialValues}
            validationSchema={validate}
            onSubmit={async (values) => {
              if (values.name !== "") {
                try {
                  editMerchant.mutate({
                    id: location?.state?.data?.id,
                    name: values.name,
                    status: status,
                    skuType: select.sku.value,
                    rootOrgId: parseInt(
                      JSON.parse(localStorage.getItem("dashboard-data"))?.id
                    ),
                    addresses: [
                      {
                        id: uuidv4(),
                        street1: values.street1,
                        street2: values.street2,
                        administrativeArea1: values.administrativeArea,
                        administrativeArea2: null,
                        cityOrVillage: values.city,
                        state: values.state,
                        zipCode: values.zip,
                        country: select.country.value,
                        contactFirstName: values.contactFirstName,
                        contactMiddleName: values.contactMiddleName,
                        contactLastName: values.contactLastName,
                        contactNumber: parseInt(values.contactNo),
                        contactEmail: values.email,
                        lat: parseFloat(values.latitude),
                        lng: parseFloat(values.longitude),
                      },
                    ],
                    brands: null,
                    //brands: select.brand.value,
                  });

                  //console.log("res: ", editMerchant)

                  if (
                    !editMerchant.isError &&
                    editMerchant.error === null &&
                    !editMerchant.isSuccess
                  ) {
                    history.goBack();
                    alert.success(
                      <div style={{ textTransform: "none" }}>
                        Successfully Added
                      </div>
                    );
                  } else {
                    alert.error(
                      <div style={{ textTransform: "none" }}>
                        Please Check all Fields
                      </div>
                    );
                  }
                } catch (error) {
                  alert.error(
                    <div style={{ textTransform: "none" }}>
                      you are offline!
                    </div>
                  );
                }
              }
            }}
          >
            {() => (
              <Form className="w-full  m-8 bg-white rounded-lg shadow-lg ">
                <div className="w-full h-12 flex bg-dash rounded-t-lg text-white">
                  <div className="w-10 p-2 h-full flex items-center">
                    <BiArrowBack
                      onClick={() => history.goBack()}
                      className="h-10 w-10 cursor-pointer"
                    />
                  </div>
                  <div className="w-full h-full px-7 flex flex-col justify-center">
                    <h2 className="font-bold text-2xl">Edit {title}</h2>
                  </div>
                </div>

                <div className="w-11/12 mx-auto px-6 mt-6 mb-2 ">
                  <div className="w-1/3 flex mt-2  items-center">
                    <div className="flex  px-4 w-full items-center select-none">
                      <TextField2
                        label="Supplier Name"
                        name="name"
                        type="text"
                      />
                    </div>

                    {/* <div className="flex  px-4    w-full items-center select-none">
                      <TextField2 label="Email" name="email" type="email" />
                    </div> */}
                  </div>

                  <h2 className="ml-4 mt-4 mb-2">Supplier Address</h2>

                  <div className=" grid grid-cols-3 gap-x-8 gap-y-5 px-4 ">
                    <div>
                      <Select
                        placeholder="Store Brands"
                        maxMenuHeight={162}
                        value={select.brand}
                        name="brand"
                        onChange={handleSelect}
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                        options={BrandTypes}
                      />
                    </div>

                    <div>
                      <Select
                        placeholder="Select Country"
                        maxMenuHeight={162}
                        name="country"
                        value={select.country}
                        onChange={handleSelect}
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                        options={countrtyList}
                      />
                    </div>

                    <div className="w-full ml-20">
                      <span className=" mr-4">Status</span>
                      <Toggle
                        onChange={statusChecked}
                        checked={status}
                        //icons={{ checked: null, unchecked: null }}
                        className="react-switch"
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-4 gap-x-8 px-4 gap-y-4 mt-4">
                    <div className="flex w-full items-center select-none">
                      <TextField2 label="Street 1" name="street1" type="text" />
                    </div>

                    <div className="flex w-full items-center select-none">
                      <TextField2 label="Street 2" name="street2" type="text" />
                    </div>

                    <div className="flex w-full items-center select-none">
                      <TextField2
                        label="City Or Village"
                        name="city"
                        type="text"
                      />
                    </div>

                    <div className="flex w-full items-center select-none">
                      <TextField2
                        label="Administrative Area"
                        name="administrativeArea"
                        type="text"
                      />
                    </div>

                    <div className="flex w-full items-center select-none">
                      <TextField2
                        label="Latitude"
                        name="latitude"
                        type="text"
                      />
                    </div>

                    <div className="flex w-full items-center select-none">
                      <TextField2
                        label="Longitude"
                        name="longitude"
                        type="text"
                      />
                    </div>

                    <div className="flex  w-full items-center select-none">
                      <TextField2 label="State" name="state" type="text" />
                    </div>

                    <div className="flex  w-full items-center select-none">
                      <TextField2 label="Zip Code" name="zip" type="text" />
                    </div>
                  </div>

                  <h2 className="ml-4 mt-4">Supplier Details</h2>

                  <div className="grid grid-cols-4 gap-x-8 px-4 gap-y-2 mt-2">
                    <div className="flex w-full items-center select-none">
                      <TextField2
                        label="First Name"
                        name="contactFirstName"
                        type="text"
                      />
                    </div>

                    <div className="flex  w-full items-center select-none">
                      <TextField2
                        label="Middle Name"
                        name="contactMiddleName"
                        type="text"
                      />
                    </div>

                    <div className="flex  w-full items-center select-none">
                      <TextField2
                        label="Last Name"
                        name="contactLastName"
                        type="text"
                      />
                    </div>

                    <div className="flex  w-full items-center select-none">
                      <TextField2
                        label="Contact Number"
                        name="contactNo"
                        type="text"
                      />
                    </div>

                    <div className="flex  w-full items-center select-none">
                      <TextField2
                        label="Email"
                        name="contactFirstName"
                        type="text"
                      />
                    </div>

                    <div className="flex  w-full items-center select-none">
                      <TextField2
                        label="Website"
                        name="contactMiddleName"
                        type="text"
                      />
                    </div>

                    <div className="flex w-full items-center select-none">
                      <TextField2
                        label="Tax Registration No."
                        name="contactLastName"
                        type="text"
                      />
                    </div>

                    <div style={{ width: "227px" }}>
                      <Select
                        maxMenuHeight={162}
                        value={select.sku}
                        name="sku"
                        onChange={handleSelect}
                        menuPortalTarget={document.body}
                        styles={customStyles}
                        menuPosition={"fixed"}
                        options={skuType}
                      />
                    </div>
                  </div>

                  <div className="w-full h-16 flex justify-end items-center px-4 -mt-2">
                    <button
                      type="submit"
                      className="bg-green-500 rounded-lg px-6 py-2  focus:outline-none text-white"
                    >
                      <span className="h-full flex items-center">
                        <svg
                          class="svg-icon"
                          viewBox="0 0 20 20"
                          width="24px"
                          height="24px"
                        >
                          <path
                            fill="#8A2BE2"
                            d="M19.404,6.65l-5.998-5.996c-0.292-0.292-0.765-0.292-1.056,0l-2.22,2.22l-8.311,8.313l-0.003,0.001v0.003l-0.161,0.161c-0.114,0.112-0.187,0.258-0.21,0.417l-1.059,7.051c-0.035,0.233,0.044,0.47,0.21,0.639c0.143,0.14,0.333,0.219,0.528,0.219c0.038,0,0.073-0.003,0.111-0.009l7.054-1.055c0.158-0.025,0.306-0.098,0.417-0.211l8.478-8.476l2.22-2.22C19.695,7.414,19.695,6.941,19.404,6.65z M8.341,16.656l-0.989-0.99l7.258-7.258l0.989,0.99L8.341,16.656z M2.332,15.919l0.411-2.748l4.143,4.143l-2.748,0.41L2.332,15.919z M13.554,7.351L6.296,14.61l-0.849-0.848l7.259-7.258l0.423,0.424L13.554,7.351zM10.658,4.457l0.992,0.99l-7.259,7.258L3.4,11.715L10.658,4.457z M16.656,8.342l-1.517-1.517V6.823h-0.003l-0.951-0.951l-2.471-2.471l1.164-1.164l4.942,4.94L16.656,8.342z"
                          ></path>
                        </svg>
                        <span className="px-2">Save</span>
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default SupplierEdit;
