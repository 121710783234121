import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useGet } from "../../../../api/useGet";
import axios from "axios";
import { useQuery } from "react-query";
import FadeLoader from "react-spinners/FadeLoader";
import MappingModal from "./MappingModal";


function OpenEvents() {
  const history = useHistory()
  const {
    state: { data: locationData },
  } = useLocation();

  const [reload, setReload] = useState(false);

  const fetchData = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_STORE_URL}/store-carts/open-cart?page=0&size=100&eventTime.greaterThanOrEqual=${locationData.entryTime}&eventTime.lessThanOrEqual=${locationData?.checkoutDate}&storeCode.equals=${locationData?.storeCode}&syncStatus.in=INITIALIZED,CONFLICT,ERRORED,CART_REVIEWED,PROCESSED&sort=eventTime,asc&cartRef.equals=${locationData?.sessionRef}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      });
      return res.data;
    } catch ({ message }) {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      }
    }
  };

  const { data, isLoading } = useQuery(
    ["openItemList", reload],
    async () => fetchData(),
    {
      enabled: true || reload,
      cacheTime:0,
      staleTime: 0,
     
    }
  );



  if (isLoading)
    return (
      <div className="bg-black bg-opacity-80 absolute inset-0 flex items-center justify-center">
        <div className="w-full h-20 absolute z-10 text-white flex flex-col items-center justify-center">
          <FadeLoader
            color="#FFFFFF"
            className=""
            loading={isLoading}
            size={150}
          />
        </div>
      </div>
    );

  return (
    <div className="h-[calc(100vh)] w-ful">
      <MappingModal
        locationData={locationData}
        data={data}
        reload={reload}
        isLoading={isLoading}
        setReload={setReload}
      />
    </div>
  );
}

export default OpenEvents;