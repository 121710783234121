import React from "react";

import { Link } from "react-router-dom";

function DashboardRoute({ params }) {
  const clicked = () => {
    localStorage.setItem("sessionRef", params.data?.sessionRef);
  };

  return (
    <>
      <Link className="font-medium cursor-default">{params.value}</Link>
    </>
  );
}

export default DashboardRoute;
