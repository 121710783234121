import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TextField3 } from "../../../../../../validation/TextField3";
import { useHistory } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import Select from "react-select";
import { useAlert } from "react-alert";
import { usePost } from "../../../../../../../api/usePost";
import { useTranslation } from "react-i18next";
import moment from "moment";

const distributionType = [
  { label: "Open", value: "OPEN" },
  { label: "Promotional", value: "PROMOTIONAL" },
];

const frequencyMode = [
  { label: "Day", value: "DAY" },
  { label: "Week", value: "WEEK" },
  { label: "Fortinight", value: "FORTINIGHT" },
  { label: "Month", value: "MONTH" },
];

let validate;
validate = Yup.object({
  name: Yup.string().required("Required"),
  consumptionQty: Yup.string().required("Required"),
  maxVoucher: Yup.number(),
});

function Distribution() {
  const { t } = useTranslation();
  const alert = useAlert();
  const history = useHistory();

  const [select, setSelect] = useState({
    distributionType: "",
    frequencyMode: "",
  });

  const handleSelect = (value, name) => {
    setSelect({ ...select, [name]: value });
  };

  const { mutate: submit } = usePost(
    `${process.env.REACT_APP_BASE_URL}/vouchers`,
    "voucherList"
  );

  return (
    <>
      <div className="w-full h-screen text-left select-none flex ">
        <div className="w-full h-full flex justify-center items-center bg-white ">
          <Formik
            initialValues={{
              name: "",
              description: "",
              voucherCodePrefix: "",
            }}
            validationSchema={validate}
            onSubmit={async (values) => {
              if (values.name !== "") {
                var body = {
                  name: values.name,

                  rootOrgId: parseInt(
                    JSON.parse(localStorage.getItem("dashboard-data"))?.id
                  ),
                };

                submit(body);
              }
            }}
          >
            {({ onSubmit }) => (
              <Form className="w-full mx-auto h-full ">
                <div className="w-full h-12 flex border-b border-black justify-center items-center ">
                  <h2 className="font-bold text-2xl">
                    {`${t("Voucher")} ${t("Distribution")}`}
                  </h2>
                </div>

                <div className="w-full h-[78vh] scrollbar-thin scrollbar-thumb-gray-100 scrollbar-track-gray-300 ">
                  <div className="w-full px-12 py-10 space-y-4">
                    <div className=" grid grid-cols-1 gap-x-12 gap-y-6 mt-1">
                      {/*  first row   */}

                      <TextField3
                        label={t("Voucher Name")}
                        placeholder="Enter Voucher Code"
                        fontType="font-bold"
                        name="name"
                        type="text"
                      />
                    </div>

                    <div className=" grid grid-cols-1 gap-x-12 gap-y-6 mt-3">
                      {/*  first row   */}

                      <div className="flex items-center space-x-4">
                        <label className="w-[30%] font-bold" for="Start Date">
                          {t("Distribution Type")}
                        </label>
                        <Select
                          className="w-[70%]"
                          placeholder="Select Dristribution Type"
                          maxMenuHeight={162}
                          value={select.products}
                          onChange={(value) => handleSelect(value, "products")}
                          styles={{
                            control: (style) => ({
                              ...style,
                              backgroundColor: "#D9D9D9",
                            }),
                          }}
                          options={distributionType}
                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-1 gap-x-12 gap-y-6 mt-1">
                      <div className="flex    w-full items-center select-none">
                        <TextField3
                          label="Max No Of Vouchers"
                          fontType="font-bold"
                          placeholder="Enter the maximum number of vouchers "
                          name="title"
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-1 gap-x-12 gap-y-6 mt-1">
                      <TextField3
                        label="Consumption Frequency qty by Shoppers"
                        fontType="font-bold"
                        placeholder="Enter Consumption Frequency qty by Shoppers"
                        name="title"
                        type="text"
                      />
                    </div>

                    <div className="grid grid-cols-1 gap-x-12 gap-y-6 mt-1">
                      <div className="flex items-center space-x-4">
                        <label className="w-[30%] font-bold" for="Start Date">
                          {t("Freequency Mode")}
                        </label>
                        <Select
                          className="w-[70%]"
                          placeholder="Select the frequency mode"
                          maxMenuHeight={162}
                          value={select.products}
                          onChange={(value) => handleSelect(value, "products")}
                          styles={{
                            control: (style) => ({
                              ...style,
                              backgroundColor: "#D9D9D9",
                            }),
                          }}
                          options={frequencyMode}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center items-center mt-6">
                    <button
                      type="submit"
                      onClick={onSubmit}
                      className="bg-green-600 rounded-lg px-10 py-1  focus:outline-none text-white"
                    >
                      {`${t("Submit")}`}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default Distribution;
