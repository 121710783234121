import { IoIosClose } from "react-icons/io";
import React, { useState, useMemo } from "react";

function ModalSubCategory({ open, onCloseModal, data, category }) {
  return (
    <>
      {open && (
        <div className="w-3/5 text-left select-none z-10 absolute top-1/2 left-1/2 ml-28  transform -translate-x-1/2 -translate-y-1/2 ">
          <div className="w-full  bg-gray-100 border-2 border-dash rounded-lg shadow-lg ">
            <div className="w-full h-12 flex bg-dash text-white ">
              <div className="w-full h-full px-7 flex flex-col justify-center">
                <h2 className="font-bold text-2xl">{`${category?.name}`}</h2>
              </div>
              <div className="w-16 px-4 h-full flex items-center">
                <div className="w-full flex justify-end  ">
                  <IoIosClose
                    onClick={onCloseModal}
                    className="h-12 w-12 cursor-pointer"
                  />
                </div>
              </div>
            </div>

            <div className="w-full">
              <div className="w-full h-full border-2 border-gray-200 rounded-lg  px-7">
                <h2 className="font-bold text-base mb-2 ">Details: </h2>

                <div class="grid grid-flow-row grid-rows-2 grid-cols-2  ">
                  <div>
                    <span className="font-bold text-base">
                       Product Category:{" "}
                    </span>
                    <span>{category?.name}</span>
                  </div>

                  <div className="mb-2">
                    <span className="font-bold text-base">Description: </span>{" "}
                    <span>{category?.description}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ModalSubCategory;
