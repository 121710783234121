import React, { useState, useMemo, useEffect } from "react";
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import Select from "react-select";
import { useAlert } from "react-alert";
import { usePost } from "../../../../../../../../api/usePost";
import { TextField2 } from "../../../../../../../validation/TextField2";
import { useTranslation } from "react-i18next";
import Datepicker from "../../../../../../../../components/MobileDatePicker";
import TimePicker from "../../../../../../../../components/MobileTimePicker";
import moment from "moment";
import * as Yup from "yup";

function CreateDynamicPrice(props) {
  const { t } = useTranslation();
  const alert = useAlert();
  const history = useHistory();

  const campaignTypeOptions = [
    { value: "SURGE", label: "Surge" },
    { value: "DISCOUNT", label: "Discount" },
  ];

  const applicableDaysOptions = [
    { label: "SUNDAY", value: "SUNDAY" },
    { label: "MONDAY", value: "MONDAY" },
    { label: "TUESDAY", value: "TUESDAY" },
    { label: "WEDNESDAY", value: "WEDNESDAY" },
    { label: "THURSDAY", value: "THURSDAY" },
    { label: "FRIDAY", value: "FRIDAY" },
    { label: "SATURDAY", value: "SATURDAY" },
  ];

  const [applicableDays, setApplicableDays] = useState([]);

  const [input, setInput] = useState({
    endDate: null,
    startDate: null,
    startTime1: null,
    endTime1: null,
    startTime2: null,
    endTime2: null,
  });

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 55,
      minHeight: 35,
    }),
  };

  const [select, setSelect] = useState({
    campaignType: "",
  });

  const handleSelect = (action, e) => {
    const name = e.name;

    setSelect({
      ...select,
      [name]: { value: action.value, label: action.label },
    });
  };

  const onChangeDate = (value, name) => {
    setInput({ ...input, [name]: value });
  };

  const onChangeTime = (value, name) => {
    setInput({ ...input, [name]: value });
  };

  const handleCheck = (event) => {
    var updatedList = [...applicableDays];
    if (event.target.checked) {
      updatedList = [...applicableDays, event.target.value];
    } else {
      updatedList.splice(applicableDays.indexOf(event.target.value), 1);
    }
    setApplicableDays(updatedList);
  };

  const createDynamicPriceConfiguration = usePost(
    `${process.env.REACT_APP_BASE_URL}/price-engine-configurations`,
    "dynamicPriceConfigList"
  );

  let validate;

  validate = Yup.object({
    campaignName: Yup.string().required("Required"),
    discountPercent: Yup.number().required("Required").moreThan(0),
    barCode: Yup.string().required("Required"),
    // applicableDays: Yup.array().min(1, "Select atleast one day").required("Required")
  });

  return (
    <div className="w-full h-screen text-left select-none flex ">
      <div className="w-full h-full flex justify-center items-center p-4 bg-gray-200 ">
        <Formik
          initialValues={{
            campaignName: "",
            campaignType: "",
            discountPercent: "",
            productName: "",
            barCode: "",
            startDate: "",
            endDate: "",
            startTime1: "",
            endTime1: "",
            startTime2: "",
            endTime2: "",
          }}
          validationSchema={validate}
          onSubmit={async (values) => {
            var startDate = moment(input?.startDate).format("yyyy-MM-DD");
            var endDate = moment(input?.endDate).format("yyyy-MM-DD");

            var priceEngineSchedule1 = null;
            var priceEngineSchedule2 = null;
            if (input.startTime1 !== null) {
              const startTime = moment(input?.startTime1).format("HH:mm");
              const endTime = moment(input?.endTime1).format("HH:mm");
              priceEngineSchedule1 = {
                startTime,
                endTime,
                applicableDays,
              };
            }

            if (input.startTime2 !== null) {
              const startTime = moment(input?.startTime2).format("HH:mm");
              const endTime = moment(input?.endTime2).format("HH:mm");
              priceEngineSchedule2 = {
                startTime,
                endTime,
                applicableDays,
              };
            }
            var schedules = [];
            if (priceEngineSchedule1 !== null) {
              schedules.push(priceEngineSchedule1);
            }
            if (priceEngineSchedule2 !== null) {
              schedules.push(priceEngineSchedule2);
            }

            var body = {
              campaignName: values.campaignName,
              status: true,
              barCode: values.barCode,
              campaignType: select["campaignType"]["value"],
              discountPercent: values.discountPercent,
              startDate,
              endDate,
              priceEngineSchedules: schedules,
              rootOrgId: parseInt(
                JSON.parse(localStorage.getItem("dashboard-data"))?.id
              ),
            };

            createDynamicPriceConfiguration.mutate(body);

            if (createDynamicPriceConfiguration.isIdle === true) {
              history.goBack();
              alert.success(
                <div style={{ textTransform: "none" }}>Successfully Added!</div>
              );
            } else {
              alert.error(
                <div style={{ textTransform: "none" }}>
                  Unable to create the dynamic price configuration
                </div>
              );
            }
          }}
        >
          {({ onSubmit }) => (
            <Form className="w-full mx-auto h-auto bg-white rounded-lg shadow-lg  ">
              <div className="w-full h-12 flex bg-dash rounded-t-lg text-white ">
                <div className="w-10 p-2 h-full flex items-center">
                  <BiArrowBack
                    onClick={() => history.goBack()}
                    className="h-10 w-10 cursor-pointer"
                  />
                </div>
                <div className="w-full h-full px-7 flex flex-col justify-center">
                  <h2 className="font-bold text-2xl">
                    {`${t("createNew")} Dynamic Price Configuration`}
                  </h2>
                </div>
              </div>
              <div className="w-full h-[78vh] scrollbar-thin scrollbar-thumb-gray-100 scrollbar-track-gray-300 ">
                <div className="flex ">
                  <div className="w-full px-12 py-10 ">
                    <div className=" grid grid-cols-2 gap-x-4 gap-y-6 mt-1">
                      <div className="flex w-full items-center select-none">
                        <TextField2
                          label={"Campaign Name"}
                          name="campaignName"
                          type="text"
                        />
                      </div>
                      <Select
                        placeholder="Campaign Type"
                        maxMenuHeight={162}
                        value={select.campaignType}
                        name="campaignType"
                        onChange={handleSelect}
                        menuPosition={"fixed"}
                        styles={customStyles}
                        options={campaignTypeOptions}
                      />
                    </div>
                    <div className="grid grid-cols-2 gap-x-4 gap-y-4 mt-3">
                      <div className="flex w-full items-center select-none">
                        <TextField2
                          label={"BarCode"}
                          name="barCode"
                          type="text"
                        />
                      </div>
                      <div className="flex w-full items-center select-none">
                        <TextField2
                          label={"Percentage"}
                          name="discountPercent"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-x-4 gap-y-4 mt-3">
                      <div className="flex w-full items-center select-none">
                        <Datepicker
                          value={input?.startDate}
                          clearIcon={false}
                          onChangeTime={onChangeDate}
                          name="startDate"
                          label={"Start Date"}
                        />
                      </div>
                      <div className="flex w-full items-center select-none">
                        <Datepicker
                          value={input?.endDate}
                          clearIcon={false}
                          onChangeTime={onChangeDate}
                          name="endDate"
                          label={"End Date"}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-x-4 gap-y-4 mt-3">
                      <div className="flex w-full items-center select-none">
                        <TimePicker
                          value={input?.startTime1}
                          clearIcon={false}
                          onChangeTime={onChangeTime}
                          name={"startTime1"}
                          label={"Schedule 1 Start Time"}
                        />
                      </div>
                      <div className="flex w-full items-center select-none">
                        <TimePicker
                          value={input?.endTime1}
                          clearIcon={false}
                          onChangeTime={onChangeTime}
                          name={"endTime1"}
                          label={"Schedule 1 End Time"}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-x-4 gap-y-4 mt-3">
                      <div className="flex w-full items-center select-none">
                        <TimePicker
                          value={input?.startTime2}
                          clearIcon={false}
                          onChangeTime={onChangeTime}
                          name={"startTime2"}
                          label={"Schedule 2 Start Time"}
                        />
                      </div>
                      <div className="flex w-full items-center select-none">
                        <TimePicker
                          value={input?.endTime2}
                          clearIcon={false}
                          onChangeTime={onChangeTime}
                          name={"endTime2"}
                          label={"Schedule 2 End Time"}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-1 gap-x-4 mt-3">
                      Applicable Days (Mandatory)
                    </div>
                    <div className="grid grid-cols-4 gap-x-4 mt-3">
                      {applicableDaysOptions.map((day, i) => (
                        <div key={`applicableDays-` + i}>
                          <input
                            type="checkbox"
                            onChange={handleCheck}
                            className="h-5 w-5"
                            name={day.label}
                            value={day.value}
                          />
                          <span className="mx-5 text-gray-500">
                            {day.label}
                          </span>
                        </div>
                      ))}
                    </div>
                    <div className="grid grid-cols-1 gap-x-4 mt-5 ">
                      <div className="flex justify-center items-center">
                        <button
                          type="submit"
                          onClick={onSubmit}
                          className="bg-green-500 rounded-lg px-4 py-2  focus:outline-none text-white"
                        >
                          <span className="h-full flex items-center">
                            <svg
                              class="svg-icon"
                              viewBox="0 0 20 20"
                              width="24px"
                              height="24px"
                            >
                              <path
                                fill="#8A2BE2"
                                d="M19.404,6.65l-5.998-5.996c-0.292-0.292-0.765-0.292-1.056,0l-2.22,2.22l-8.311,8.313l-0.003,0.001v0.003l-0.161,0.161c-0.114,0.112-0.187,0.258-0.21,0.417l-1.059,7.051c-0.035,0.233,0.044,0.47,0.21,0.639c0.143,0.14,0.333,0.219,0.528,0.219c0.038,0,0.073-0.003,0.111-0.009l7.054-1.055c0.158-0.025,0.306-0.098,0.417-0.211l8.478-8.476l2.22-2.22C19.695,7.414,19.695,6.941,19.404,6.65z M8.341,16.656l-0.989-0.99l7.258-7.258l0.989,0.99L8.341,16.656z M2.332,15.919l0.411-2.748l4.143,4.143l-2.748,0.41L2.332,15.919z M13.554,7.351L6.296,14.61l-0.849-0.848l7.259-7.258l0.423,0.424L13.554,7.351zM10.658,4.457l0.992,0.99l-7.259,7.258L3.4,11.715L10.658,4.457z M16.656,8.342l-1.517-1.517V6.823h-0.003l-0.951-0.951l-2.471-2.471l1.164-1.164l4.942,4.94L16.656,8.342z"
                              ></path>
                            </svg>
                            <span className="px-2 select-none">
                              {`${t("create")} ${t("dynamicPrice")}`}
                            </span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default CreateDynamicPrice;
