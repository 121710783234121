import React, { useState } from "react";
import moment from "moment";
import ModalImg from "./ModalImg";
import Radium, { Style } from 'radium'

const CartViewEdit = ({ item, index, inputChange }) => {
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  
  return (
    <>
      <h2 className="font-bold mt-2 ml-2 text-base">
        Machine Cart Item {index + 1}:
      </h2>
      {item?.productName ? (
        <div className="border-t-2 border-gray-200 py-1 px-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="">Product Name</div>
            <div className="">{item?.productName}</div>
          </div>
        </div>
      ) : null}
      {item?.row ? (
        <div className="border-t-2 border-gray-200 py-1 px-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="">Row</div>
            <div className="">{item?.row}</div>
          </div>
        </div>
      ) : null}
      {item?.column ? (
        <div className="border-t-2 border-gray-200 py-1 px-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="">Column</div>
            <div className="">{item?.column}</div>
          </div>
        </div>
      ) : null}
      {item?.initialWeight ? (
        <div className="border-t-2 border-gray-200 py-1 px-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="">Initial Weight</div>
            <div className="">{item?.initialWeight}</div>
          </div>
        </div>
      ) : null}

      {item?.productName ? (
        <div className="border-t-2 border-gray-200 py-1 px-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="">Product Image</div>
            <div className="w-16 h-16 rounded-lg">
              <img
                onClick={onOpenModal}
                src={item?.productImageUrl}
                className="object-fill  w-16 h-16  cursor-pointer"
              />
            </div>
          </div>
        </div>
      ) : null}

      <ModalImg
        open={open}
        onCloseModal={onCloseModal}
        img={item?.productImageUrl}
      />

      <div className="border-t-2 border-gray-200 py-1 px-4">
        <div className="grid grid-cols-2 gap-4">
          <div className="">Quantity</div>
          <div className="ml-2">{item?.quantity}</div>
        </div>
      </div>

      {
        <div className="border-t-2 border-gray-200 py-1 px-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="">Approved Quantity</div>

            <Style
              scopeSelector=".myClass"
              rules={{
                "::-webkit-input-placeholder": {
                  color: "#000",
                },
              }}
            />

            <input
              name={`reviewedQuantity${index}`}
              // placeholder={item?.reviewedQuantity ? item?.reviewedQuantity : item?.quantity}
              placeholder={item?.quantity}
              className="myClass"
              value={item?.reviewedQuantity}
              type="number"
              onChange={(e) =>
                inputChange(
                  e.target.value,
                  // consolecartIndex,
                  item.id
                )
              }
              className="focus:outline-none px-2 border rounded-sm w-48"
            />
          </div>
        </div>
      }

      {item?.unitCost ? (
        <div className="border-t-2 border-gray-200 py-1 px-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="">Unit Cost</div>
            <div className="">{parseFloat(item?.unitCost).toFixed(2)}</div>
          </div>
        </div>
      ) : null}
      {item?.transactionDate ? (
        <div className="border-t-2 border-gray-200 py-1 px-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="">Transaction Date</div>
            <div className="">
              {moment(item?.transactionDate).format("MMMM Do YYYY, h:mm A")}
            </div>
          </div>
        </div>
      ) : null}
      {item?.activityType ? (
        <div className="border-t-2 border-gray-200 py-1 px-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="">Activity Type</div>
            <div className="">{item?.activityType}</div>
          </div>
        </div>
      ) : null}

      {item?.reason ? (
        <div className="border-t-2 border-gray-200 py-1 px-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="">Reason</div>
            <div className="">{item?.reason}</div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Radium(CartViewEdit);
