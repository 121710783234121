import React, { useState, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "react-toggle/style.css";
import DashboardRoute from "./DashboardRoute";
import moment from "moment";
import FadeLoader from "react-spinners/FadeLoader";
import SearchData from "../../../../../../components/SearchDataMany";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import { useQuery } from "react-query";
import { getTime } from "../../../../../../logic/useGetTime";
import { toast } from "react-toastify";
import { MerchantsDetails } from "../../../../../../utils/action_types";

function Approved({
  listUrl,
  url,
  viewUrl,
  editUrl,
  sortedData,
  refresh,
  setRefresh,
}) {
  const [filteredData, setFilteredData] = useState({
    retailerStore: "",
    session: "",
  });
  const [searchData, setSearchData] = useState({
    retailerStore: "",
    session: "",
  });
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const [input, setInput] = useState({
    retailerStore: "",
    session: "",
    type: "",
  });

  const [activePage, setActivePage] = useState(1);

  const findUrl = (name) => {
    switch (name) {
      case "session":
        return `${listUrl}&page=${activePage - 1}&size=50&cartRef.equals=${
          input?.cartref
        }&status.equals=APPROVED`;

      case "retailerStore":
        return `${listUrl}&page=${activePage - 1}&size=50&storeId.equals=${
          searchData.retailerStore
        }&status.equals=APPROVED`;

      default:
        return `${listUrl}&page=${
          activePage - 1
        }&size=50&status.equals=APPROVED`;
    }
  };

  const searchDataFn = (name, data) => {
    setSearchData({ ...searchData, [name]: data?.id });
    setInput({ ...input, [name]: data?.name });
    setFilteredData({
      session: "",
      retailerStore: "",
    });
  };

  const fetchData = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: findUrl(input?.type),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      });

      return res.data;
    } catch ({ message }) {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message);
      }
    }
  };

  const { data, isLoading, isIdle, error } = useQuery(
    [
      "processed",
      activePage,
      searchData?.retailerStore,
      searchData?.session,
      refresh,
    ],
    async () => fetchData(activePage),
    {
      enabled:
        !!activePage ||
        !!searchData?.retailerStore ||
        !!searchData?.session ||
        refresh,
      cacheTime: 0,
      staleTime: 0,
    }
  );

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  const onChangeInput = (e, name) => {
    const value = e.target.value;

    setInput({ ...input, [name]: value, type: name });

    if (name === "retailerStore") {
      setLoading(true);

      axios({
        method: "GET",
        url: `${
          process.env.REACT_APP_BASE_URL
        }/_search/retail-stores?name.contains=${value.toLowerCase()}*`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      }).then((res) => {
        setFilteredData({ ...filteredData, [name]: res?.data });

        setLoading(false);
      });
    } else if (name === "session") {
      setLoading(true);

      axios({
        method: "GET",
        url: `${
          process.env.REACT_APP_BASE_URL
        }/_search/retail-stores?name.contains=${value.toLowerCase()}*`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      }).then((res) => {
        setFilteredData({ ...filteredData, [name]: res?.data });

        setLoading(false);
      });
    }
  };

  const clear = () => {
    setInput({ retailerStore: "", session: "" });
    setFilteredData({ retailerStore: "", session: "" });
    setSearchData({ retailerStore: "", session: "" });
  };

  const view = (data) => {
    history.push({
      pathname: "/support/match/processedEvents",
      state: { data: data },
    });
  };

  if (isLoading)
    return (
      <div className="bg-black bg-opacity-80 absolute inset-0 flex items-center justify-center">
        <div className="w-full h-20 absolute z-10 text-white flex flex-col items-center justify-center">
          <FadeLoader
            color="#FFFFFF"
            className=""
            loading={isLoading}
            size={150}
          />
        </div>
      </div>
    );

  return (
    <div className=" w-full h-[calc(100vh-184px)]">
      <div className="flex h-10 w-full items-center mt-2 justify-between py-8 px-5">
        <div className="w-auto flex space-x-6 items-center">
          <div className="w-72 flex justify-end items-center">
            <SearchData
              name="retailerStore"
              label={"Retailer Store"}
              input={input}
              setInput={setInput}
              onChangeInput={onChangeInput}
              filteredData={filteredData}
              searchDataFn={searchDataFn}
              setSearchData={setSearchData}
              loading={loading}
              margin="ml-[250px]"
            />
          </div>

          <div className="w-72 flex justify-end items-center">
            <SearchData
              name="session"
              label={"Session"}
              input={input}
              setInput={setInput}
              onChangeInput={onChangeInput}
              filteredData={filteredData}
              searchDataFn={searchDataFn}
              setSearchData={setSearchData}
              loading={loading}
              margin="ml-[250px]"
            />
          </div>
        </div>

        <button
          onClick={clear}
          className="bg-green-600 py-1.5 px-5 mr-7 rounded text-white cursor-pointer focus:outline-none"
        >
          Clear filters
        </button>
      </div>

      <div className="ag-theme-alpine  w-full h-full text-left">
        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            resizable: true,
          }}
          suppressRowClickSelection={true}
          rowSelection={"multiple"}
          rowData={data}
          onRowClicked={(params) => view(params?.data)}
          rowHeight={53}
        >
          <AgGridColumn
            headerName="Session Ref"
            field="sessionRef"
            minWidth={150}
            sortable={true}
            headerCheckboxSelection={true}
            headerCheckboxSelectionFilteredOnly={true}
            checkboxSelection={true}
            cellRendererFramework={(params) => (
              <DashboardRoute
                params={params}
                tab="approved"
                name="sessionRef"
              />
            )}
          />

          {/* <AgGridColumn
            headerName="Mobile"
            field="formattedPhone"
            minWidth={130}
            maxWidth={130}
            sortable={true}
          /> */}

          <AgGridColumn
            headerName="Merchant"
            field="storeCode"
            sortable={true}
            cellRendererFramework={(params) => {
              console.log(
                "merchantPhotu",
                MerchantsDetails.hasOwnProperty(params?.value)
                  ? MerchantsDetails.hasOwnProperty(params?.value)?.imgUrl
                  : "/images/noImage.jpg"
              );
              return (
                <div>
                  <img
                    src={
                      MerchantsDetails.hasOwnProperty(params?.value)
                        ? MerchantsDetails?.[params?.value]?.imgUrl
                        : "/images/noImage.jpg"
                    }
                    width="50px"
                    height="50px"
                    alt="/images/noImage.jpg"
                    // className="w-full h-[200px]"
                    // className="mt-3"
                  />
                </div>
              );
            }}
          />

          <AgGridColumn
            headerName="Entry Time"
            field="checkoutDate"
            minWidth={170}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <>
                  <p>
                    {getTime(
                      params?.data?.entryTime,
                      params?.data?.storeTimeZone
                    )}
                  </p>
                </>
              );
            }}
          />

          <AgGridColumn
            headerName="Duration"
            field="entryTime"
            sortable={true}
            minWidth={120}
            cellRendererFramework={(params) => {
              var startTime = moment(
                params?.data?.entryTime,
                "DD-MM-YYYY hh:mm:ss"
              );
              var endTime = moment(
                params?.data?.checkoutDate,
                "DD-MM-YYYY hh:mm:ss"
              );
              var minutesDiff = endTime.diff(startTime, "minutes");
              var secondsDiff = endTime.diff(startTime, "seconds");
              var b = secondsDiff % 60;
              // console.log('Minutes:' + minutesDiff);
              return (
                <p className="">
                  {+minutesDiff} mins {+b} sec
                </p>
              );
            }}
          />

          <AgGridColumn
            headerName="Checkout Time"
            field="checkoutDate"
            minWidth={170}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <>
                  <p>
                    {getTime(
                      params?.data?.checkoutDate,
                      params?.data?.storeTimeZone
                    )}
                  </p>
                </>
              );
            }}
          />

          <AgGridColumn
            headerName="Store Name"
            field="storeName"
            minWidth={170}
            sortable={true}
          />

          <AgGridColumn
            headerName="store Code"
            field="storeCode"
            minWidth={120}
            maxWidth={180}
            sortable={true}
          />

          <AgGridColumn
            headerName="Action"
            minWidth={40}
            cellRendererFramework={(params) => {
              return (
                <div className="flex w-20 h-full items-center">
                  <div className="w-full border-2 text-xs bg-green-400 border-gray-200 rounded-lg h-8 flex justify-center items-center my-1 ">
                    <button
                      onClick={() => view(params?.data)}
                      className="focus:outline-none"
                    >
                      <span className="text-white">View Cart</span>
                    </button>
                  </div>
                </div>
              );
            }}
          />
        </AgGridReact>
      </div>
      <div className="mb-9 right-12 flex justify-center item-center">
        <Pagination
          count={203}
          showFirstButton
          showLastButton
          page={activePage}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
}

export default Approved;
