import React, { useState } from "react";
import Toggle from "react-toggle";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { usePatch } from "../../../../../../../api/usePatch";
import { toast } from "react-toastify";

function Confirm({ params }) {
  const history = useHistory();

  //console.log("status_check: ", params?.data.status)

  const [status, setStatus] = useState(
    params?.data.status === "true" ? true : false
  );

  const statusChecked = (e) => {
    const value = e.target.checked;

    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const res = axios({
                method: "PATCH",
                url: `${process.env.REACT_APP_BASE_URL}/racks/${params?.data?.id}`,

                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("user-info")}`,
                },
                data: {
                  status: value,
                },
              });
            } catch ({ message }) {
              if (message === "Request failed with status code 401") {
                history.push("/login");
              } else {
                toast.error(message);
              }
            }

            // if (editStatus.isError === false) {
            //   setStatus(data?.status);
            // }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <>
      <Toggle
        onChange={statusChecked}
        checked={status}
        //icons={{ checked: null, unchecked: null }}
        className="react-switch"
      />
    </>
  );
}

export default Confirm;
