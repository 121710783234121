import React, { useState } from "react";
import Action from "./Action";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { useQuery } from "react-query";
import Pagination from "@mui/material/Pagination";
import "react-toggle/style.css";
import Address from "./Address";
import DashboardRoute from "./DashboardRoute";
import Confirm from "../../../../components/Confirm";
import axios from "axios";
import { MdClear } from "react-icons/md";
import { BiSearchAlt } from "react-icons/bi";
import {useHistory} from 'react-router-dom'
import {toast} from 'react-toastify'


function All({ url, viewUrl, editUrl, listUrl }) {
  const [allCount, setAllCount] = useState()
  const history = useHistory();
  
  const findUrl = (name) => {
    switch (name) {
      case "name":
        return `${listUrl}&page=${activePage - 1}&size=9&name.contains=${
          input?.name?.toLocaleLowerCase()
        }`;
       

      case "internalCode":
        return `${listUrl}&page=${activePage - 1}&size=9&internalCode.contains=${
          input?.internalCode?.toLocaleLowerCase()
        }`;
        

      default:
        return `${listUrl}&page=${activePage - 1}&size=9`;
        
    }
  };

  const [input, setInput] = useState({
    name: "",
    internalCode: "",
    type: "",
  });

  const [activePage, setActivePage] = useState(1);

  const fetchData = async () => {

    try {
      const res = await axios({
        method: "GET",
        url: findUrl(input?.type),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      });
      setAllCount(res?.headers?.["x-total-count"])
      return res.data;
    } catch ({message}) {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message)
      }
    }
    
  };

  const { data } = useQuery(
    ["merchantList", activePage, input?.name, input?.internalCode],
    async () => fetchData(),
    {
      enabled: !!activePage || !!input?.name || !!input?.internalCode,
      cacheTime: Infinity,
      staleTime: 0,
    }
  );

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  const onChangeInput = (e, name) => {
    const value = e.target.value;
    setInput({ ...input, [name]: value, type: name });
  };

  const clear = () => {
    setInput({
      name: "",
      internalCode: "",
      type: "",
    })
  }

  return (
    <div className=" w-full h_table">
      <div className="flex h-10 w-full items-center justify-between mt-2 py-8 px-4">
        <div className="w-auto flex space-x-6 items-center">

          <div className="w-72 flex justify-end items-center">
            <input
              name="name"
              placeholder="Search by name"
              value={input?.name}
              onChange={(e) => onChangeInput(e, "name")}
              className="w-full h-10  focus:outline-none rounded px-4 border border-black"
            />
            {input["name"]?.length > 0 ? (
              <MdClear
                size={24}
                className="cursor-pointer -ml-[34px]"
                onClick={clear}
              />
            ) : (
              <BiSearchAlt
                size={24}
                className="cursor-pointer -ml-[34px] text-blue-500"
              />
            )}
          </div>

          <div className="w-72 flex justify-end items-center">
            <input
              name="internalCode"
              placeholder="Search by Merchant Code"
              value={input?.internalCode}
              onChange={(e) => onChangeInput(e, "internalCode")}
              className="w-full h-10  focus:outline-none rounded px-4 border border-black"
            />
            {input["internalCode"]?.length > 0 ? (
              <MdClear
                size={24}
                className="cursor-pointer -ml-[34px]"
                onClick={clear}
              />
            ) : (
              <BiSearchAlt
                size={24}
                className="cursor-pointer -ml-[34px] text-blue-500"
              />
            )}
          </div>

          {/* <div className="w-72 flex justify-end items-center">
            <SearchData
              name="internalCode"
              label={"By Merchant Code"}
              input={input}
              setInput={setInput}
              onChangeInput={onChangeInput}
              filteredData={filteredData}
              searchDataFn={searchDataFn}
              setSearchData={setSearchData}
              loading={loading}
            />
          </div> */}
        </div>
        
        <button
        onClick={clear} 
        className="bg-green-600 py-1.5 px-5 mr-7 rounded text-white">
          Clear filters
        </button>
      </div>

      <div className="ag-theme-alpine  w-full h-full text-left">
        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            resizable: true,
          }}
          suppressRowClickSelection={true}
          rowSelection={"multiple"}
          rowData={data}
        >
          <AgGridColumn
            headerName="Name"
            field="name"
            minWidth={250}
            sortable={true}
            headerCheckboxSelection={true}
            headerCheckboxSelectionFilteredOnly={true}
            checkboxSelection={true}
            cellRendererFramework={(params) => (
              <DashboardRoute params={params} />
            )}
          />
          
          <AgGridColumn field="email" minWidth={250} sortable={true} />

          <AgGridColumn
            field="Address"
            minWidth={390}
            cellRendererFramework={(params) => <Address params={params} />}
          />

          <AgGridColumn
            headerName="Merchant Code"
            field="internalCode"
            minWidth={150}
            sortable={true}
          />

          <AgGridColumn
            field="status"
            minWidth={100}
            cellRendererFramework={(params) => (
              <Confirm
                params={params}
                url={`${process.env.REACT_APP_BASE_URL}/add/product`}
                getName={"productList"}
                title={"Confirm to submit"}
                message="Are you sure to do this."
                field="status"
              />
            )}
          />
          <AgGridColumn
            field="action"
            minWidth={100}
            cellRendererFramework={(params) => (
              <Action
                params={params}
                url={url}
                viewUrl={viewUrl}
                editUrl={editUrl}
              />
            )}
          />
        </AgGridReact>
      </div>
      <div className="absolute bottom-8 right-12  flex justify-center item-center">
        <Pagination
          count={allCount % 9 === 0 ? parseInt(allCount/9) : parseInt(allCount/9) + 1}
          showFirstButton
          showLastButton
          page={activePage}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
}

export default All;
