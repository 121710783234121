import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { FileUploader } from "react-drag-drop-files";
import { usePost } from "../../../../../../../../api/usePost";
import { useAlert } from "react-alert";
import axios from "axios";
import { toast } from "react-toastify";

const fileTypes = ["CSV"];

function BulkProductUpload() {
  const history = useHistory();
  const alert = useAlert();

  const [file, setFile] = useState(null);

  const handleChange = (file) => {
    setFile(file);
  };

  const fetchDownload = (url, name) => {
    const res = fetch(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
    })
      .then((res) => res.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${name}.xlsx`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  const download = async () => {
    fetchDownload(
      `${process.env.REACT_APP_BASE_URL}/products/download/${parseInt(
        JSON.parse(localStorage.getItem("dashboard-data"))?.id
      )}`,
      `Bulk Download`
    );
  };

  const submit = () => {
    const formData = new FormData();
    formData.append("productFile", file);

    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/products/bulk-upload`,

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
      data: formData,
    })
      .then((res) => {
        alert.success(<div className="text-sm">uploaded Successfully</div>);
      })
      .catch(({ message }) => {
        if (message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          toast.error(message);
        }
      });
  };

  return (
    <div className="h-screen w-full">
      <div className="w-full h-16 flex bg-gray-100 rounded-t-lg">
        <div className="w-10 p-2 h-full flex items-center">
          <BiArrowBack
            onClick={() => history.goBack()}
            className="h-10 w-10 cursor-pointer"
          />
        </div>

        <div className="h-full flex items-center">
          <h2 className="font-bold text-xl">{`Bulk Product Upload`}</h2>
        </div>
      </div>

      <div className="h_camera w-full flex flex-col items-center justify-center">
        <div className="mt-10 h-40 w-96 flex justify-center items-center">
          <FileUploader
            handleChange={handleChange}
            name="file"
            //types={fileTypes}
          />
        </div>
        <button
          className="focus:outline-none mt-10 bg-gray-200 w-24 h-8 rounded-lg"
          onClick={submit}
        >
          Submit
        </button>
      </div>
    </div>
  );
}

export default BulkProductUpload;
