import React from "react";
import { GrView } from "react-icons/gr";
import { FiEdit } from "react-icons/fi";
import { useHistory } from "react-router-dom";

function Action({ params, url, viewUrl, editUrl }) {
  const history = useHistory();


  const fetchInitialValue = () => {

    let initialValues = {
      firstName: params?.data?.firstName ? params?.data?.firstName : "",
      lastName: params?.data?.lastName ? params?.data?.lastName : "",
      email: params?.data?.email ? params?.data?.email : "",
      phone: params?.data?.userInfo?.phone ? params?.data?.userInfo?.phone : "",
      gender: params?.data?.userInfo?.gender ? params?.data?.userInfo?.gender : "",
    }

    return initialValues

  }

  const edit = () => {
    const initialValues = fetchInitialValue()
    history.push({
      pathname: editUrl,
      //search: "?query=abc",-
      state: { data: params.data, initialValues: initialValues},
    });
  };

//  console.log("employee params: ", params?.data)

 


  const view = () => {
    const initialValues = fetchInitialValue()
    history.push({
      pathname: viewUrl,
      //search: "?query=abc",-
      state: { data: params.data, initialValues: initialValues},
    })
  }
  return (
    <>
      <div className="flex w-16 h-10 items-center">
        <div className="border-2 border-gray-200 rounded-lg p-1 ">
          <GrView className="w-5 h-5 cursor-pointer " onClick={view} />
        </div>
        {/* <div className="border-2 border-gray-200 rounded-r-lg p-1">
          <FiEdit className="w-5 h-5 cursor-pointer" onClick={edit} />
        </div> */}
      </div>
    </>
  )
}

export default Action;
