import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
import Logo from "../Images/logo.jpeg";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { MdLock } from "react-icons/md";
import { ImUserTie } from "react-icons/im";
import { TextField1 } from "./validation/TextField1";
import { TextField2 } from "./validation/TextField2";
import axios from "axios";
import { toast } from "react-toastify";
import { storeEncryptedData } from "../utils/localStorageUtils";
import { v4 as uuidv4 } from "uuid";

let validate;
validate = Yup.object({
  username: Yup.string().min(4, "At least 4 charaters").required("Required"),
  password: Yup.string().min(4, "At least 4 charaters").required("Required"),
  otp: Yup.string().matches(/^[0-9]{6}$/g, 'OTP must be 6 digits number only'),
});

function LoginPage() {
  const history = useHistory();
  const cookies = new Cookies();
  const [remember, setRemember] = useState(false);
  const [authorize, setAuthorize] = useState("");

  return (
    <Formik
      initialValues={{
        username: cookies.get("AutonomoUsername"),
        password: cookies.get("AutonomoPassword"),
      }}
      validationSchema={validate}
      onSubmit={async (values) => {
        if (
          values.username !== "" &&
          values.password !== "" &&
          authorize === ""
        ) {
          //authorize api called for otp
          axios
            .post(`${process.env.REACT_APP_BASE_URL}/authorize`, {
              username: values.username,
              password: values.password,
              rememberMe: values.rememberMe,
            })
            .then(function ({ data }) {
              localStorage.setItem("uniqID",  uuidv4())
              storeEncryptedData('loginInfo', data);
              setAuthorize(data);

              //save cookie
              if (remember) {
                cookies.set("AutonomoUsername", values.username, {
                  path: "/",
                });
                cookies.set("AutonomoPassword", values.password, {
                  path: "/",
                });
              }

              //validate without otp **************************
              if (data?.jwtToken && data.jwtToken !== null) {
                localStorage.setItem("user-info", data?.jwtToken);
                localStorage.setItem("user-name", data.login);

                //open dashboard according to credentials
                if (
                  data?.authorities?.includes("ROLE_EMPLOYEE_ADMIN") ||
                  data?.authorities?.includes("ROLE_EMPLOYEE_USER")
                ) {
                  const rootOrgId = data?.userInfo?.rootOrgId;
                  axios({
                    method: "GET",
                    url: `${process.env.REACT_APP_BASE_URL}/orgs??rootOrgId.equals=${rootOrgId}`,
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${localStorage.getItem(
                        "user-info"
                      )}`,
                    },
                  })
                    .then((res) => {
                      localStorage.setItem(
                        "dashboard-data",
                        JSON.stringify(res?.data[0])
                      );
                      history.push("/admin/merchant/dashboard");
                    })
                    .catch(({ message }) => {
                      if (message === "Request failed with status code 401") {
                        toast.error("Bad Credential");
                      }
                    });

                  localStorage.setItem("role", "merchant");
                } else if (
                  data?.authorities?.includes("ROLE_SUPPORT_ADMIN") ||
                  data?.authorities?.includes("ROLE_SUPPORT_USER")
                ) {
                  localStorage.setItem("role", "support");
                  history.push("/support");
                } else {
                  localStorage.setItem("role", "admin");
                  history.push("/");
                }
              }
            })
            .catch(function ({ message }) {
              if (message === "Request failed with status code 401") {
                toast.error("Incorrect Credential");
              } else {
                toast.error(message);
              }
            });
        } else {
          // validate with otp logic  *************************
          if (authorize?.requiresOtp) {
            axios({
              method: "PUT",
              url: `${process.env.REACT_APP_BASE_URL}/authenticate/admin/otp`,
              headers: {
                "Content-Type": "application/json",
              },
              data: {
                login: authorize?.login,
                transactionRefId: authorize?.transactionRefId,
                token: values?.otp,
              },
            })
              .then(({ data }) => {
                if (data?.jwtToken && data?.jwtToken !== null) {
                  localStorage.setItem("user-info", data?.jwtToken);
                  localStorage.setItem("user-name", data?.login);

                  //open dashboard according to credentials
                  if (
                    authorize?.authorities?.includes("ROLE_EMPLOYEE_ADMIN") ||
                    authorize?.authorities?.includes("ROLE_EMPLOYEE_USER")
                  ) {
                    const rootOrgId = authorize?.userInfo?.rootOrgId;
                    axios({
                      method: "GET",
                      url: `${process.env.REACT_APP_BASE_URL}/orgs??rootOrgId.equals=${rootOrgId}`,
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                          "user-info"
                        )}`,
                      },
                    })
                      .then((res) => {
                        localStorage.setItem(
                          "dashboard-data",
                          JSON.stringify(res?.data[0])
                        );
                        history.push("/admin/merchant/dashboard");
                      })
                      .catch(({ message }) => {
                        if (message === "Request failed with status code 401") {
                          toast.error("Bad Credential");
                        }
                      });

                    localStorage.setItem("role", "merchant");
                  } else if (
                    authorize?.authorities?.includes("ROLE_SUPPORT_ADMIN") ||
                    authorize?.authorities?.includes("ROLE_SUPPORT_USER")
                  ) {
                    localStorage.setItem("role", "support");
                    history.push("/support");
                  } else {
                    localStorage.setItem("role", "admin");
                    history.push("/");
                  }
                }
              })
              .catch(function ({ message }) {
                toast.error(`Incorrect OTP`);
              });
          }
        }
      }}
    >
      {(formik) => (
        <Form>
          <div className="w-full h-screen bg-nav overflow-hidden select-none">
            <div className="w-full h-full  flex lg:px-32 py-20 ">
              <div className="w-full lg:w-3/5 h-full bg-white shadow-lg mx-4 rounded-lg">
                <div className="w-full h-16 pt-8 flex items-center justify-center">
                  <img className=" w-12" src={Logo} alt="logo" />
                  <div className="h-full border-r-2 pl-2 border-gray"></div>
                  <span className="pl-2 font-bold text-2xl animate-bounce ">
                    Autonomo
                  </span>
                </div>
                <div className="w-full flex flex-col justify-center items-center p-4 my-12 lg:my-0 lg:p-16 ">
                  <div className="mb-2">
                    <TextField1
                      label="Username"
                      name="username"
                      type="text"
                      Icon={<ImUserTie className="w-7 h-7 text-gray-900" />}
                    />
                  </div>

                  <div className="mt-2">
                    <TextField1
                      label="Password"
                      name="password"
                      type="password"
                      Icon={<MdLock className="w-7 h-7 text-gray-900" />}
                    />
                  </div>

                  <div className="w-full  mx-auto justify-between flex my-12  pl-2 lg:pl-8 lg:pr-5 ">
                    <div className="h-full flex items-center">
                      <input
                        type="checkbox"
                        onChange={(e) => setRemember(e.target.checked)}
                        class="form-checkbox h-5 w-5 text-green-600"
                      />
                      <span className="mx-2">Remember Me</span>
                    </div>

                    <div>
                      <Link to="/need" className="hover:text-blue-600">
                        Need help?
                      </Link>
                    </div>
                  </div>

                  {authorize?.requiresOtp ? (
                    <>
                      <div className="mt-2">
                        <TextField2
                          label="Enter OTP"
                          name="otp"
                         
                        />
                      </div>

                      <button
                        type="submit"
                        className="focus:outline-none w-32 my-4 lg:my-4 px-4 py-3 text-green-400 hover:text-nav rounded-lg  border-2 hover:border-nav border-green-400 select-none"
                      >
                        Submit
                      </button>
                    </>
                  ) : (
                    <button
                      type="submit"
                      className="focus:outline-none w-32 my-4 lg:my-4 px-4 py-3 text-green-400 hover:text-nav rounded-lg  border-2 hover:border-nav border-green-400 select-none"
                    >
                      Login
                    </button>
                  )}
                </div>
              </div>

              <div className="hidden lg:contents ">
                <div className="w-3/4 px-4 ">
                  <div className="w-full">
                    <h1 className="text-white text-4xl">
                      Welcome to the Autonomo!
                    </h1>
                    <p className="text-white my-8">
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout. The point of using Lorem Ipsum is that it
                      has a more-or-less normal distribution of letters, as
                      opposed to using 'Content here, content here', making it
                      look like readable English. Many desktop publishing
                      packages and web page editors now use Lorem Ipsum as their
                      default model text, and a search for 'lorem ipsum' will
                      uncover many web sites still in their infancy. Various
                      versions have evolved over the years, sometimes by
                      accident, sometimes on purpose (injected humour and the
                      like).
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default LoginPage;
