import React from "react";

import { Link } from "react-router-dom";

function DashboardRoute({ params }) {
  //console .log('navi: ', navigate)


  const clicked = () => {
    localStorage.setItem("sessionRef", params.data?.sessionRef);
  }

  return (
    <>
      <Link
        // to={{
        //   pathname: "/admin/hawkaye/monitoring",
        //   //data: params,
        // }}
        // onClick={clicked}
        className="font-medium cursor-default text-center"
      >
        {params.value}
      </Link>
    </>
  );
}

export default DashboardRoute;
