import React, { useState, useMemo, useEffect } from "react";

import { useHistory, useLocation } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import Select from "react-select";
import { useAlert } from "react-alert";

import * as Yup from "yup";
import { usePut } from "../../../../../../../../api/usePut";
import axios from "axios";
import Toggle from "react-toggle";
import NumberFormat from "react-number-format";

let validate;
validate = Yup.object({
  machineName: Yup.string().required("Required"),
  macAddress: Yup.string().required("Required"),
});

function MachineEdit() {
  const [title, setTitle] = useState("Machine");
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();

  const data = location?.data;
  const [cartData, setCartData] = useState({ ...data });

  const editMachine = usePut(
    `${process.env.REACT_APP_BASE_URL}/scales/${data?.id}`,
    "machineList"
  );

  const [searchValue, setSearchValue] = useState(null);

  const [input, setInput] = useState({
    row: "",
    column: "",
    maxUnit: "",
    minUnit: "",
  });

  const inputChangeHandler = (e, name) => {
    const value = e.target.value;
    setInput({ ...input, [name]: value });

    var updatedData = { ...cartData };
    updatedData[name] = value;
    setCartData(updatedData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("submit: ", cartData);

    editMachine.mutate(cartData);

    if (editMachine.isError === false) {
      history.goBack();
      alert.success(
        <div style={{ textTransform: "none" }}>Successfully Edit!</div>
      );
    } else {
      alert.error(
        <div style={{ textTransform: "none" }}>Please Check all Fields!</div>
      );
    }
  };

  return (
    <>
      <div className="w-full h-screen overflow-y-hidden text-left flex select-none bg-gray-200">
        <div className="w-full h-full px-4">
          <form onSubmit={handleSubmit} className="w-full h-full ">
            <div className="w-full h-8 rounded-t-lg flex bg-white my-2">
              <div className="w-10 p-2 h-full flex items-center">
                <BiArrowBack
                  onClick={() => history.goBack()}
                  className="h-10 w-10 cursor-pointer"
                />
              </div>
              <div className="w-full h-full px-2 flex flex-col justify-center">
                <h2 className="font-bold text-xl">Edit {title}</h2>
              </div>
            </div>

            <div className="w-full h-auto grid grid-cols-2 gap-2 bg-white ">
              <div className="w-full h-full  p-2 select-none">
                <table className="table-auto rounded border-collapse border  w-full h-full">
                  <tbody className="text-left">
                    <tr>
                      <th className="w-32 border border-gray-300 bg-gray-200 px-2">
                        Scale Id
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        {data?.scaleId}
                      </th>
                    </tr>
                    <tr>
                      <th className="w-40 border border-gray-300 bg-gray-200 px-2">
                        row :
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        {data?.row}
                      </th>
                    </tr>
                    <tr>
                      <th className="w-32 border border-gray-300 bg-gray-200 px-2">
                        column :
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        {data?.column}
                      </th>
                    </tr>
                    <tr>
                      <th className="w-40 border border-gray-300 bg-gray-200 px-2">
                        Max Unit :
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        {data?.maxUnit}
                      </th>
                    </tr>
                    <tr>
                      <th className="w-32 border border-gray-300 bg-gray-200 px-2">
                        Min Unit :
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        {data?.minUnit}
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="w-full h-full  p-2 select-none">
                <table className="table-auto rounded border-collapse border  w-full h-full">
                  <tbody className="text-left">
                    <tr>
                      <th className="w-32 border border-gray-300 bg-gray-200 px-2">
                        Product Name :
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        {data?.variant}
                      </th>
                    </tr>
                    <tr>
                      <th className="w-40 border border-gray-300 bg-gray-200 px-2">
                        current Weight :
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        {data?.currentWeight}
                      </th>
                    </tr>
                    <tr>
                      <th className="w-32 border border-gray-300 bg-gray-200 px-2">
                        Quantity :
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        {data?.quantity}
                      </th>
                    </tr>
                    <tr>
                      <th className="w-32 border border-gray-300 bg-gray-200 px-2">
                        Status :
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        {data?.status}
                      </th>
                    </tr>
                    <tr>
                      <th className="w-32 border border-gray-300 bg-gray-200 px-2">
                        Last Active Time :
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        {data?.lastActiveTime}
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="w-full h-auto bg-white my-2">
              <h2 className="px-2 font-serif font-bold ">About Machine</h2>
              <div className=" grid grid-cols-2 gap-2  ">
                <div className="w-full h-full  p-2 select-none">
                  <table className="table-auto rounded border-collapse border  w-full h-full">
                    <tbody className="text-left">
                      <tr>
                        <th className="w-32 border border-gray-300 bg-gray-200 px-2">
                          Machine Name :
                        </th>
                        <th className="border border-gray-300 px-2 text-gray-500">
                          {data?.machine?.name}
                        </th>
                      </tr>

                      {data?.machine?.deviceIdentifier ? (
                        <tr>
                          <th className="w-40 border border-gray-300 bg-gray-200 px-2">
                            Device Identifier :
                          </th>
                          <th className="border border-gray-300 px-2 text-gray-500">
                            {data?.machine?.deviceIdentifier}
                          </th>
                        </tr>
                      ) : null}

                      {data?.machine?.status ? (
                        <tr>
                          <th className="w-48 border border-gray-300 bg-gray-200 px-2">
                            status
                          </th>
                          <th className="border border-gray-300 px-2 text-gray-500">
                            {data?.machine?.status}
                          </th>
                        </tr>
                      ) : null}
                    </tbody>
                  </table>
                </div>

                <div className="w-full h-full  p-2 select-none">
                  <table className="table-auto rounded border-collapse border  w-full h-full">
                    <tbody className="text-left">
                      {data?.machine?.qrCode ? (
                        <tr>
                          <th className="w-48 border border-gray-300 bg-gray-200 px-2">
                            Qr Code:
                          </th>
                          <th className="border border-gray-300 px-2 text-gray-500">
                            {data?.machine?.qrCode}
                          </th>
                        </tr>
                      ) : null}

                      {data?.machine?.mode ? (
                        <tr>
                          <th className="w-48 border border-gray-300 bg-gray-200 px-2">
                            mode :
                          </th>
                          <th className="border border-gray-300 px-2 text-gray-500">
                            {data?.machine?.mode}
                          </th>
                        </tr>
                      ) : null}

                      {data?.machine?.macAddress ? (
                        <tr>
                          <th className="w-48 border border-gray-300 bg-gray-200 px-2">
                            Mac Address :
                          </th>
                          <th className="border border-gray-300 px-2 text-gray-500">
                            {data?.machine?.macAddress}
                          </th>
                        </tr>
                      ) : null}
                    </tbody>
                  </table>
                </div>
              </div>

              <h2 className="px-2 font-serif font-bold ">
                About Machine's Retail Store
              </h2>
              <div className=" grid grid-cols-2 gap-2  ">
                <div className="w-full h-full  p-2 select-none">
                  <table className="table-auto rounded border-collapse border  w-full h-full">
                    <tbody className="text-left">
                      <tr>
                        <th className="w-32 border border-gray-300 bg-gray-200 px-2">
                          Name :
                        </th>
                        <th className="border border-gray-300 px-2 text-gray-500">
                          {data?.machine?.retailStore?.name}
                        </th>
                      </tr>

                      {data?.machine?.retailStore?.address?.cityOrVillage ? (
                        <tr>
                          <th className="w-48 border border-gray-300 bg-gray-200 px-2">
                            City Or Village:
                          </th>
                          <th className="border border-gray-300 px-2 text-gray-500">
                            {data?.machine?.retailStore?.address?.cityOrVillage}
                          </th>
                        </tr>
                      ) : null}
                    </tbody>
                  </table>
                </div>

                <div className="w-full h-full  p-2 select-none">
                  <table className="table-auto rounded border-collapse border  w-full h-full">
                    <tbody className="text-left">
                      <tr>
                        <th className="w-32 border border-gray-300 bg-gray-200 px-2">
                          Store Type :
                        </th>
                        <th className="border border-gray-300 px-2 text-gray-500">
                          {data?.machine?.retailStore?.storeType}
                        </th>
                      </tr>

                      {data?.machine?.retailStore?.address?.zipCode ? (
                        <tr>
                          <th className="w-48 border border-gray-300 bg-gray-200 px-2">
                            Zip Code:
                          </th>
                          <th className="border border-gray-300 px-2 text-gray-500">
                            {data?.machine?.retailStore?.address?.zipCode}
                          </th>
                        </tr>
                      ) : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="w-full h-44 bg-white rounded-b-lg my-2 py-3">
              <h2 className="px-2 h-8 text-lg font-black font-serif">
                Fill out the form below for edit
              </h2>
              <div className=" grid grid-cols-2 gap-2  ">
                <div className="w-full h-full  p-2 select-none">
                  <table className="table-auto rounded border-collapse border  w-full h-full">
                    <tbody className="text-left">
                      <tr>
                        <th className="w-32 border border-gray-500 bg-gray-200 px-2">
                          Row :
                        </th>
                        <th className="border border-gray-500 px-2 text-gray-500">
                          <input
                            className="focus:outline-none"
                            placeholder="Enter Row No"
                            type="number"
                            value={input?.row}
                            onChange={(e) => inputChangeHandler(e, "row")}
                          />
                        </th>
                      </tr>
                      <tr>
                        <th className="w-32 border border-gray-500 bg-gray-200 px-2">
                          Min unit :
                        </th>
                        <th className="border border-gray-500 px-2 text-gray-500">
                          <input
                            className="focus:outline-none"
                            type="number"
                            placeholder="Enter Min Unit"
                            value={input?.minUnit}
                            onChange={(e) => inputChangeHandler(e, "minUnit")}
                          />
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="w-full h-12  p-2 select-none">
                  <table className="table-auto rounded border-collapse border  w-full h-full">
                    <tbody className="text-left">
                      <tr>
                        <th className="w-32 border border-gray-500 bg-gray-200 px-2">
                          col :
                        </th>
                        <th className="border border-gray-500 px-2 text-gray-500">
                          <input
                            className="focus:outline-none"
                            type="number"
                            placeholder="Enter Column No"
                            value={input?.column}
                            onChange={(e) => inputChangeHandler(e, "column")}
                          />
                        </th>
                      </tr>
                      <tr>
                        <th className="w-32 border border-gray-500 bg-gray-200 px-2">
                          Max unit :
                        </th>
                        <th className="border border-gray-500 px-2 text-gray-500">
                          <input
                            className="focus:outline-none"
                            placeholder="Enter Max Unit"
                            type="number"
                            value={input?.maxUnit}
                            onChange={(e) => inputChangeHandler(e, "maxUnit")}
                          />
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="flex h-10  w-full items-center  justify-end mt-1.5  px-2">
                <button
                  type="submit"
                  className="bg-green-500 rounded-lg px-6 py-1.5  focus:outline-none text-white"
                >
                  <span className="h-full flex items-center">
                    <svg
                      class="svg-icon"
                      viewBox="0 0 20 20"
                      width="24px"
                      height="24px"
                    >
                      <path
                        fill="#8A2BE2"
                        d="M19.404,6.65l-5.998-5.996c-0.292-0.292-0.765-0.292-1.056,0l-2.22,2.22l-8.311,8.313l-0.003,0.001v0.003l-0.161,0.161c-0.114,0.112-0.187,0.258-0.21,0.417l-1.059,7.051c-0.035,0.233,0.044,0.47,0.21,0.639c0.143,0.14,0.333,0.219,0.528,0.219c0.038,0,0.073-0.003,0.111-0.009l7.054-1.055c0.158-0.025,0.306-0.098,0.417-0.211l8.478-8.476l2.22-2.22C19.695,7.414,19.695,6.941,19.404,6.65z M8.341,16.656l-0.989-0.99l7.258-7.258l0.989,0.99L8.341,16.656z M2.332,15.919l0.411-2.748l4.143,4.143l-2.748,0.41L2.332,15.919z M13.554,7.351L6.296,14.61l-0.849-0.848l7.259-7.258l0.423,0.424L13.554,7.351zM10.658,4.457l0.992,0.99l-7.259,7.258L3.4,11.715L10.658,4.457z M16.656,8.342l-1.517-1.517V6.823h-0.003l-0.951-0.951l-2.471-2.471l1.164-1.164l4.942,4.94L16.656,8.342z"
                      ></path>
                    </svg>
                    <span className="px-2 select-none cursor-pointer">
                      Save
                    </span>
                  </span>
                </button>
              </div>
            </div>

            {/* <div className="w-11/12 h-full mx-auto px-6 bg-red-500">
                  <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-12 gap-y-12 py-16">
                    <div>
                      <div className="flex items-center h-10">
                        <TextField2 label="Name" name="name" type="text" />
                      </div>
                    </div>

                    <div>
                      <div className="flex items-center h-10">
                        <TextField2
                          label="Device Identifier"
                          name="deviceIdentifier"
                          type="text"
                        />
                      </div>
                    </div>

                    <div>
                      <div className=" flex items-center h-10">
                        <TextField2
                          label="Mac Address"
                          name="macAddress"
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="flex items-center h-10 ">
                      <div className="flex   w-full select-none">
                        <div style={{ width: "400px" }}>
                          <Select
                            options={optionsMode}
                            placeholder="Select Mode"
                            maxMenuHeight={162}
                            value={mode}
                            onChange={changeHandlerMode}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex items-center h-10">
                      <div className="flex  w-full select-none">
                        <div style={{ width: "400px" }}>
                          <Select
                            placeholder="Select Retailer Store"
                            maxMenuHeight={132}
                            value={selectedRetailer}
                            onChange={changeHandlerRetailer}
                            onInputChange={handleInputChange}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            options={retailer?.map((item, index) => {
                              return {
                                label: item?.name,
                                value: item?.id,
                                // key: index,
                                data: item,
                                orgId: item?.rootOrgId,
                              };
                            })}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex items-center h-10">
                      <div className="flex  w-full select-none">
                        <div style={{ width: "400px" }}>
                          <Select
                            placeholder="Select Merchant..."
                            maxMenuHeight={162}
                            value={orgId}
                            onChange={changeHandlerOrg}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            options={[
                              {
                                label: merchant.name,
                                value: merchant.name,
                              },
                            ]}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex items-center lg:-mt-4">
                      <TextField2 label="QR Code" name="qrCode" type="text" />
                    </div>

                    <div className="flex items-center lg:-mt-4 ">
                      <div className="w-full h-full flex  justify-start items-center rounded-lg">
                        <span className=" mr-4">Status</span>
                        <Toggle
                          onChange={handleChange}
                          checked={checked}
                          //icons={{ checked: null, unchecked: null }}
                          className="react-switch"
                        />
                      </div>

                      <div className="w-full h-16 flex justify-end items-center  ">
                        <button
                          type="submit"
                          className="bg-green-500 rounded-lg px-6 py-3  focus:outline-none text-white"
                        >
                          <span className="h-full flex items-center">
                            <svg
                              class="svg-icon"
                              viewBox="0 0 20 20"
                              width="24px"
                              height="24px"
                            >
                              <path
                                fill="#8A2BE2"
                                d="M19.404,6.65l-5.998-5.996c-0.292-0.292-0.765-0.292-1.056,0l-2.22,2.22l-8.311,8.313l-0.003,0.001v0.003l-0.161,0.161c-0.114,0.112-0.187,0.258-0.21,0.417l-1.059,7.051c-0.035,0.233,0.044,0.47,0.21,0.639c0.143,0.14,0.333,0.219,0.528,0.219c0.038,0,0.073-0.003,0.111-0.009l7.054-1.055c0.158-0.025,0.306-0.098,0.417-0.211l8.478-8.476l2.22-2.22C19.695,7.414,19.695,6.941,19.404,6.65z M8.341,16.656l-0.989-0.99l7.258-7.258l0.989,0.99L8.341,16.656z M2.332,15.919l0.411-2.748l4.143,4.143l-2.748,0.41L2.332,15.919z M13.554,7.351L6.296,14.61l-0.849-0.848l7.259-7.258l0.423,0.424L13.554,7.351zM10.658,4.457l0.992,0.99l-7.259,7.258L3.4,11.715L10.658,4.457z M16.656,8.342l-1.517-1.517V6.823h-0.003l-0.951-0.951l-2.471-2.471l1.164-1.164l4.942,4.94L16.656,8.342z"
                              ></path>
                            </svg>
                            <span className="px-2 select-none cursor-pointer">
                              Save
                            </span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}
          </form>
        </div>
      </div>
    </>
  );
}

export default MachineEdit;
