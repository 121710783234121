import React from "react";
import TableStr from "./TableStr";
import All from "./options/All";

function Shoppers() {
    const rootOrgId= parseInt(JSON.parse(localStorage.getItem("dashboard-data"))?.id);

    return (
        <div className="h-full w-full ">
            <TableStr
                listUrl={`${process.env.REACT_APP_SHOPPER_URL}/users?rootOrgId.equals=${rootOrgId}`}
                getName={"ShoppersList"}
                dashboard={true}
                title={"Shoppers"}
                All={All}
            />
        </div>
    );
};

export default Shoppers;