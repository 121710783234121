import React, { useState, useMemo } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import countryList from "react-select-country-list";
import Select from "react-select";
import { useAlert } from "react-alert";
import { usePost } from "../../../../../../../../api/usePost";
import { TextField2 } from "../../../../../../../validation/TextField2";
import Toggle from "react-toggle";
import axios from "axios";

let validate;
validate = Yup.object({
  name: Yup.string().required("Required"),
  zip: Yup.number().typeError("Not a number").required("Required"),
  street1: Yup.string().required("Required"),
  street2: Yup.string(),
  city: Yup.string().required("Required"),
  administrativeArea: Yup.string(),
  latitude: Yup.number().typeError("Not a number"),
  longitude: Yup.number().typeError("Not a number"),
  state: Yup.string().required("Required"),
  contactFirstName: Yup.string().required("Required"),
  contactMiddleName: Yup.string(),
  contactLastName: Yup.string().required("Required"),
  contactNo: Yup.number().required("Required"),
  email: Yup.string().email(),
  tax: Yup.string(),
});

const skuType = [
  { value: "STANDARD_SKU", label: "STANDARD SKU" },
  { value: "NON-STANDARD_SKU", label: "NON-STANDARD SKU" },
];

const customStyles = {
  control: (base) => ({
    ...base,
    height: 55,
    minHeight: 35,
  }),
};

const countrtyList = [
  { label: "Germany", value: "DE" },
  { label: "Hungary", value: "HU" },
  { label: "France", value: "FR" },
];

function CreateSupplier() {
  const [title, setTitle] = useState("Supplier");
  // const options = useMemo(() => countryList().getData(), []);
  const alert = useAlert();
  const history = useHistory();
  const [addressType, setAdressType] = useState();
  const [status, setStatus] = useState(false);

  const [select, setSelect] = useState({
    country: { value: "", label: "Select Country" },
    sku: { value: "", label: "SKU Type" },
  });

  const createSupplier = usePost(
    `${process.env.REACT_APP_BASE_URL}/suppliers`,
    "supplierList"
  );

  const handleSelect = (action, e) => {
    const name = e.name;
    setSelect({
      ...select,
      [name]: { value: action.value, label: action.label },
    });
  };

  const statusChecked = (e) => {
    setStatus(e.target.checked);
  };

  return (
    <>
      <div className="w-full h-screen text-left select-none flex">
        <div className="w-full h-full flex justify-center items-center bg-gray-200 ">
          <Formik
            initialValues={{
              name: "",
              street1: "",
              street2: "",
              city: "",
              administrativeArea: "",
              latitude: "",
              longitude: "",
              state: "",
              zip: "",
              contactFirstName: "",
              contactLastName: "",
              contactMiddleName: "",
              contactNo: "",
              email: "",
              tax: "",
            }}
            validationSchema={validate}
            onSubmit={async (values) => {
              //console.log(values);
              if (values.name !== "") {
                try {
                  const data = {
                    name: values.name,
                    status: status ? "Active" : "Inactive",
                    rootOrgId: JSON.parse(
                      localStorage.getItem("dashboard-data")
                    )?.id,
                    webSite: values?.website,
                    taxRegistrationNumber: values?.tax,
                    skuType: select.sku.value,
                    addresses: [
                      {
                        zipCode: values.zip,
                        street1: values.street1,
                        street2: values.street2,
                        cityOrVillage: values.city,
                        administrativeArea1: values.administrativeArea,
                        state: values.state,
                        country: select.country.value,
                        lat: parseFloat(values.latitude),
                        lng: parseFloat(values.longitude),
                        addressType: addressType ? "PRIMARY" : "OTHER",
                        contactFirstName: values.contactFirstName,
                        contactMiddleName: values?.contactMiddleName,
                        contactLastName: values.contactLastName,
                        contactNumber: values.contactNo,
                        contactEmail: values.email,
                      },
                    ],
                  };

                  createSupplier.mutate(data);

                  if (createSupplier.isError === false) {
                    alert.success(
                      <div style={{ textTransform: "none" }}>
                        Successfully Added!
                      </div>
                    );
                    history.goBack();
                  } else {
                    alert.error(
                      <div style={{ textTransform: "none" }}>
                        Please Check all Fields!
                      </div>
                    );
                  }
                } catch (error) {
                  alert.error(
                    <div style={{ textTransform: "none" }}>
                      you are offline!
                    </div>
                  );
                }
              }
            }}
          >
            {() => (
              <Form className="w-11/12 mx-auto h-8.2/10 bg-white rounded-lg shadow-lg ">
                <div className="w-full h-16 flex bg-dash text-white rounded-t-lg">
                  <div className="w-10 p-2 h-full flex items-center">
                    <BiArrowBack
                      onClick={() => history.goBack()}
                      className="h-10 w-10 cursor-pointer"
                    />
                  </div>
                  <div className="w-full h-full px-7 flex flex-col justify-center">
                    <h2 className="font-bold text-2xl">
                      {`Create New ${title}`}
                    </h2>
                  </div>
                </div>

                <div className="w-11/12 mx-auto px-6 my-4">
                  <div className="grid grid-cols-3 gap-x-16 px-4 gap-y-2 mt-2">
                    <div className="flex w-full items-center select-none">
                      <TextField2
                        label="Supplier Name"
                        name="name"
                        type="text"
                      />
                    </div>

                    <div className="flex w-full items-center select-none">
                      <div style={{ width: "268px" }}>
                        <Select
                          options={countrtyList}
                          name="country"
                          maxMenuHeight={162}
                          value={select.country}
                          onChange={handleSelect}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                        />
                      </div>
                    </div>

                    {/*status toggle */}
                    <div className="w-full pl-9  py-4 h-full flex items-center rounded-lg">
                      <span className=" mr-4">Status</span>
                      <Toggle
                        onChange={statusChecked}
                        checked={status}
                        //icons={{ checked: null, unchecked: null }}
                        className="react-switch"
                      />
                    </div>
                  </div>
                  <h2 className="ml-4 mt-4">Supplier Address</h2>

                  <div className="grid grid-cols-4 gap-x-8 px-4 gap-y-2 mt-2">
                    <div className="flex w-full items-center select-none">
                      <TextField2 label="Street 1" name="street1" type="text" />
                    </div>

                    <div className="flex w-full items-center select-none">
                      <TextField2 label="Street 2" name="street2" type="text" />
                    </div>

                    <div className="flex w-full items-center select-none">
                      <TextField2
                        label="City or Village"
                        name="city"
                        type="text"
                      />
                    </div>

                    <div className="flex w-full items-center select-none">
                      <TextField2
                        label="Administrative Area"
                        name="administrativeArea"
                        type="text"
                      />
                    </div>

                    <div className="flex w-full items-center select-none">
                      <TextField2
                        label="Latitude"
                        name="latitude"
                        type="text"
                      />
                    </div>

                    <div className="flex w-full items-center select-none">
                      <TextField2
                        label="Longitude"
                        name="longitude"
                        type="text"
                      />
                    </div>

                    <div className="flex    w-full items-center select-none">
                      <TextField2 label="State" name="state" type="text" />
                    </div>

                    <div className="flex w-full items-center select-none">
                      <TextField2 label="Zip Code" name="zip" type="text" />
                    </div>
                  </div>

                  <h2 className="ml-4 mt-4">Supplier Details</h2>

                  <div className="grid grid-cols-4 gap-x-8 px-4 gap-y-2 mt-2">
                    <div className="flex w-full items-center select-none">
                      <TextField2
                        label="First Name"
                        name="contactFirstName"
                        type="text"
                      />
                    </div>

                    <div className="flex w-full items-center select-none">
                      <TextField2
                        label="Middle Name"
                        name="contactMiddleName"
                        type="text"
                      />
                    </div>

                    <div className="flex w-full items-center select-none">
                      <TextField2
                        label="Last Name"
                        name="contactLastName"
                        type="text"
                      />
                    </div>

                    <div className="flex w-full items-center select-none">
                      <TextField2
                        label="Contact Number"
                        name="contactNo"
                        type="text"
                      />
                    </div>

                    <div className="flex w-full items-center select-none">
                      <TextField2 label="Email" name="email" type="text" />
                    </div>

                    <div className="flex  w-full items-center select-none">
                      <TextField2 label="Website" name="website" type="text" />
                    </div>

                    <div className="flex w-full items-center select-none">
                      <TextField2
                        label="Tax Registration No."
                        name="tax"
                        type="text"
                      />
                    </div>

                    <div style={{ width: "227px" }}>
                      <Select
                        maxMenuHeight={162}
                        value={select.sku}
                        name="sku"
                        onChange={handleSelect}
                        menuPortalTarget={document.body}
                        styles={customStyles}
                        menuPosition={"fixed"}
                        options={skuType}
                      />
                    </div>
                  </div>

                  <div className="flex w-full h-full items-center justify-between select-none px-4 mt-3.5">
                    <div className="flex items-center h-6">
                      <input
                        type="checkbox"
                        onChange={(e) => setAdressType(e.target.checked)}
                        class="form-checkbox h-5 w-5 text-green-600"
                      />
                      <span className="mx-1 -mt-0.5 px-2 ">
                        Save as Primary Address
                      </span>
                    </div>

                    <button
                      type="submit"
                      className="bg-green-500 rounded-md px-3 py-1.5 focus:outline-none text-white"
                    >
                      <span className="h-full flex items-center">
                        <svg
                          class="svg-icon"
                          viewBox="0 0 20 20"
                          width="28px"
                          height="28px"
                        >
                          <path
                            fill="#ffffff"
                            d="M13.388,9.624h-3.011v-3.01c0-0.208-0.168-0.377-0.376-0.377S9.624,6.405,9.624,6.613v3.01H6.613c-0.208,0-0.376,0.168-0.376,0.376s0.168,0.376,0.376,0.376h3.011v3.01c0,0.208,0.168,0.378,0.376,0.378s0.376-0.17,0.376-0.378v-3.01h3.011c0.207,0,0.377-0.168,0.377-0.376S13.595,9.624,13.388,9.624z M10,1.344c-4.781,0-8.656,3.875-8.656,8.656c0,4.781,3.875,8.656,8.656,8.656c4.781,0,8.656-3.875,8.656-8.656C18.656,5.219,14.781,1.344,10,1.344z M10,17.903c-4.365,0-7.904-3.538-7.904-7.903S5.635,2.096,10,2.096S17.903,5.635,17.903,10S14.365,17.903,10,17.903z"
                          ></path>
                        </svg>
                        <span className="px-2">Create {title}</span>
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default CreateSupplier;
