import React, { useState, useEffect } from "react";
import TableStr from "./TableStr";
import MatchedExit from "./options/MatchedExit";
import OpenEntryEvents from "./options/OpenEntryEvents";
import OpenExitEvents from "./options/OpenExitEvents";
import Processed from "./options/Processed";
import FullfillmentEvents from "./options/FullfillmentEvents";
import EntryDecisionEvents from "./options/EntryDecisionEvents";
import ExitDecisionEvents from "./options/ExitDecisionEvents";

function ShopperActivity() {
  return (
    <div className="w-full h-full">
      <TableStr
        title={"Shopper Activity"}
        viewUrl={"/support/shopper-activity/shopperEntryEvent"}  
        editUrl={"/support/shopper-activity/exit"}
        listUrl={`${process.env.REACT_APP_STORE_URL}/user-activities`}
        entryDecisionListUrl={`${process.env.REACT_APP_STORE_URL}/entry-decision-events`}
        entryDecisionViewUrl={"/support/shopper-activity/entry-decision"}
        exitDecisionListUrl={`${process.env.REACT_APP_STORE_URL}/user-exit-activities`}
        getName={"shopperList"}
        OpenEntryEvents={OpenEntryEvents}
        MatchedExit={MatchedExit}
        Processed={Processed}
        OpenExitEvents={OpenExitEvents}
        FullfillmentEvents={FullfillmentEvents}
        EntryDecisionEvents={EntryDecisionEvents}
        ExitDecisionEvents={ExitDecisionEvents}
        exitDecisionViewUrl={"/support/shopper-activity/exit-decision"}
      />
    </div>
  );
}

export default ShopperActivity;
