import React, { useState, useMemo } from "react";
import { IoIosClose } from "react-icons/io";

function ModalImg({ open, onCloseModal, img }) {
  const [title, setTitle] = useState("Brand");
  //console.log("supplier: ", brand)
  return (
    <>
      {open && (
        <div className="w-64 h-64 text-left select-none z-10 absolute top-1/2 -mt-8 left-1/3   transform -translate-x-1/2 -translate-y-1/2 ">
          <div className="w-full  bg-gray-100 border-2 border-dash rounded-lg shadow-lg ">
            <div className="w-full h-10 flex bg-dash text-white">
              <div className="w-full flex justify-end items-center  ">
                <IoIosClose
                  onClick={onCloseModal}
                  className="h-12 w-12 cursor-pointer"
                />
              </div>
            </div>

            <div className="w-full">
              <div className="w-full h-full border-2 border-gray-200 rounded-lg  px-7">
                <img src={img} alt="Product img" className="w-64 h-64 p-1" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ModalImg;
