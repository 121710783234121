import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { FiCoffee } from "react-icons/fi";
import {toast} from 'react-toastify'

const findUrl = (tab, params) => {
  console.log("params: ", params);
  switch (tab) {
    case "inReview":
      return `${process.env.REACT_APP_STORE_URL}/store-carts?eventTime.greaterThanOrEqual=${params?.data?.entryTime}&eventTime.lessThanOrEqual=${params?.data?.checkoutDate}&storeCode.equals=${params?.data}&syncStatus.in=INITIALIZED,CONFLICT&sort=eventTime,desc`;

    case "rejected":
      return `${process.env.REACT_APP_STORE_URL}/store-carts?cartRef.equals=${params?.data?.sessionRef}&storeCode.equals=${params?.data?.storeCode}&sort=eventTime,desc`;

    case "approved":
      return `${process.env.REACT_APP_STORE_URL}/store-carts?cartRef.equals=${params?.data?.sessionRef}&storeCode.equals=${params?.data?.storeCode}&sort=eventTime,desc`;

    case "inflight":
      return `${process.env.REACT_APP_STORE_URL}/store-carts?eventTime.greaterThanOrEqual=${params?.data?.entryTime}&eventTime.lessThanOrEqual=${params?.data?.checkoutDate}&syncStatus.in=INITIALIZED,CONFLICT&sort=eventTime,desc`;

    default:
  }
};

function DashboardRoute({ params, tab }) {
  const history = useHistory();

  const clicked = () => {
    localStorage.setItem("storeCart", JSON.stringify(params.data));

    axios({
      method: "GET",
      url: findUrl(tab, params),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
    }).then((res) => {
      if (tab === "inReview" || tab === "inflight") {
        if (res?.data?.length === 0) {
          localStorage.setItem("way", "direct");
          history.push("/support/hawkeye/cartSummary");
        } else if (res?.data?.length !== 0) {
          localStorage.setItem("openCart", JSON.stringify(res?.data));
          history.push("/support/hawkeye/openCartItem");
        }
      } else {
        history.push({
          pathname: "/support/match/processedEvents",
          state: { data: res?.data },
        });
      }
    }).catch(({ message }) => {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message)
      }
    })
  };

  return (
    <div
      // onClick={clicked}
      className="w-[140px] focus:outline-none flex font-bold select-text justify-between items-center pr-5"
    >
      {params?.data?.sessionRef}
      <span>{params?.data?.type === "DISPENSER" && <FiCoffee  color="brown" size={22}/>}</span>
    </div>
  );
}

export default DashboardRoute;
