import React, { useState, useEffect, useLayoutEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { BsArrowRightShort, BsArrowLeftShort } from "react-icons/bs";
import { IoPersonCircleOutline } from "react-icons/io5";
import { Link, useHistory } from "react-router-dom";
import * as s from "./Sidebar.styles";

const Sidebar = (props) => {
  const history = useHistory();
  const {
    backgroundImage = "",
    sidebarHeader = {
      fullName: "",
      shortName: "",
    },
    menuItems = [
      {
        name: "Welcome",
        to: "/admin/merchant/dashboard",
        icon: "/icons/home.svg",
        subMenuItems: [],
      },
    ],
    fonts = {
      header: "",
      menu: "",
    },
    colorPalette = {
      bgColor1: "rgba(11, 171, 100, 0.8)",
      bgColor2: "rgba(59, 183, 143, 0.8)",
      fontColor: "rgba(22, 46, 39)",
      fontColorSelected: "rgba(255, 255, 255)",
      dividerColor: "rgba(122, 204, 178)",
      selectedBackgroundCollapsedMode: "dark",
    },
  } = props;

  // State
  const [selected, setSelectedMenuItem] = useState(menuItems[0].name);
  const [isSidebarOpen, setSidebarState] = useState(true);
  const [header, setHeader] = useState(sidebarHeader.fullName);
  const [subMenusStates, setSubmenus] = useState({});

  // Effects

  // Set selected menu item based on URL pathname
  useLayoutEffect(() => {
    const path = window.location.pathname;
    const parts = path.split("/");

    if (
      path !== "/" &&
      parts[1].charAt(0).toUpperCase() !== menuItems[0].name
    ) {
      const selectedItem = parts[1].charAt(0).toUpperCase() + parts[1].slice(1);
      setSelectedMenuItem(selectedItem);
    }
  }, [menuItems]);

  // Update of header state
  useEffect(() => {
    isSidebarOpen
      ? setTimeout(() => setHeader(sidebarHeader.fullName), 200)
      : setHeader(sidebarHeader.shortName);
  }, [isSidebarOpen, sidebarHeader]);

  // Update of sidebar state
  useEffect(() => {
    const updateWindowWidth = () => {
      if (window.innerWidth < 1280) setSidebarState(false);
      else setSidebarState(true);
    };

    window.addEventListener("resize", updateWindowWidth);

    return () => window.removeEventListener("resize", updateWindowWidth);
  }, [isSidebarOpen]);

  // Add index of items that contain sub menu items
  useEffect(() => {
    const newSubmenus = {};

    menuItems.forEach((item, index) => {
      const hasSubmenus = !!item.subMenuItems.length;

      if (hasSubmenus) {
        newSubmenus[index] = {};
        newSubmenus[index]["isOpen"] = true;
        newSubmenus[index]["selected"] = null;
      }
    });

    // Set selected submenu if user landed on one
    const path = window.location.pathname;
    const parts = path.split("/");

    if (parts.length === 3) {
      const selectedItem = parts[1].toLowerCase();
      const selectedSubItem = parts[2].toLowerCase();
      const selectedItemIndex = menuItems.findIndex(
        (item) => item.name.toLowerCase() === selectedItem
      );
      const selectedSubItemIndex = menuItems[
        selectedItemIndex
      ]?.subMenuItems.findIndex(
        (subItem) => subItem.name.toLowerCase() === selectedSubItem
      );

      if (selectedItemIndex !== -1)
        newSubmenus[selectedItemIndex]["isOpen"] = true;
      if (selectedItemIndex !== -1 && selectedSubItemIndex !== -1)
        newSubmenus[selectedItemIndex]["selected"] = selectedSubItemIndex;
    }

    Object.keys(subMenusStates).length === 0 && setSubmenus(newSubmenus);
  }, [menuItems, subMenusStates]);

  const handleMenuItemClick = (name, index) => {
    setSelectedMenuItem(name);

    const subMenusCopy = JSON.parse(JSON.stringify(subMenusStates));

    if (subMenusStates.hasOwnProperty(index)) {
      subMenusCopy[index]["isOpen"] = !subMenusStates[index]["isOpen"];
      setSubmenus(subMenusCopy);
    } else {
      for (let item in subMenusStates) {
        subMenusCopy[item]["isOpen"] = false;
        subMenusCopy[item]["selected"] = null;
      }
      setSubmenus(subMenusCopy);
    }
  };

  const handleSubMenuItemClick = (menuItemIdx, subMenuItemIdx) => {
    const subMenusCopy = JSON.parse(JSON.stringify(subMenusStates));

    subMenusCopy[menuItemIdx]["selected"] = subMenuItemIdx;
    setSubmenus(subMenusCopy);
  };

  const menuItemsJSX = menuItems.map((item, index) => {
    const isItemSelected = selected === item.name;

    const hasSubmenus = !!item.subMenuItems.length;
    const isOpen = subMenusStates[index]?.isOpen;

    const subMenusJSX = item.subMenuItems.map(
      (subMenuItem, subMenuItemIndex) => {
        const isSubmenuItemSelected =
          subMenusStates[index]?.selected === subMenuItemIndex;

        return (
          <Link
            to={`${subMenuItem.to}`}
            style={{ textDecoration: "none" }}
            key={subMenuItemIndex}
            className="select-none text-left"
          >
            <s.SubMenuItem
              onClick={() => handleSubMenuItemClick(index, subMenuItemIndex)}
              selected={isSubmenuItemSelected}
              colorPalette={colorPalette}
            >
              {!isSidebarOpen && subMenuItem.name === "Tax Categories"
                ? "Tax Categ .."
                : subMenuItem.name === "Product Category"
                ? "Category"
                : subMenuItem.name === "Unit of Measure"
                ? "UOM"
                : subMenuItem.name}
            </s.SubMenuItem>
          </Link>
        );
      }
    );

    return (
      <s.ItemContainer key={index}>
        <Link to={item.to} style={{ textDecoration: "none" }}>
          <s.MenuItem
            font={fonts.menu}
            selected={isItemSelected}
            onClick={() => handleMenuItemClick(item.name, index)}
            isSidebarOpen={isSidebarOpen}
            isOpen={isOpen}
            colorPalette={colorPalette}
          >
            <s.Icon isSidebarOpen={isSidebarOpen} src={item.icon} />
            <s.Text isSidebarOpen={isSidebarOpen}>{item.name}</s.Text>
            {hasSubmenus && isSidebarOpen && (
              <s.DropdownIcon
                selected={isItemSelected}
                isOpen={isOpen}
                colorPalette={colorPalette}
              />
            )}
          </s.MenuItem>
        </Link>

        {/* Display submenus if they exist  */}
        <AnimatePresence>
          {hasSubmenus && isOpen && (
            <motion.nav
              initial={{ opacity: 0, y: -15 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.35 }}
              exit={{ opacity: 0, x: -30 }}
            >
              <s.SubMenuItemContainer
                isSidebarOpen={isSidebarOpen}
                colorPalette={colorPalette}
              >
                {subMenusJSX}
              </s.SubMenuItemContainer>
            </motion.nav>
          )}
        </AnimatePresence>
      </s.ItemContainer>
    );
  });

  const logout = () => {
    localStorage.clear();
    history.push("/login");
  };

  return (
    <s.SidebarContainer
      backgroundImage={backgroundImage}
      isSidebarOpen={isSidebarOpen}
      colorPalette={colorPalette}
    >
      <s.SidebarHeader>
        <span className="text-lg  font-bold text-white">{header}</span>
      </s.SidebarHeader>

      <div
        className={`h_sidebar scrollbar-thin ${
          localStorage.getItem("role") === "merchant"
            ? "scrollable-thumb-gray"
            : "scrollbar-thumb-emerald-400"
        }  scrollbar-track-transparent-300`}
      >
        <s.MenuItemContainer>{menuItemsJSX}</s.MenuItemContainer>
        {/* {isSidebarOpen ? (
          <BsArrowLeftShort
            className="text-white absolute top-0 right-1"
            onClick={() => setSidebarState(!isSidebarOpen)}
          />
        ) : (
          <BsArrowRightShort
            className="text-white absolute top-0 right-1"
            onClick={() => setSidebarState(!isSidebarOpen)}
          />
        )} */}
      </div>

      <div className="w-full h-24 justify-center items-center flex">
        <div className="flex justify-start">
          <IoPersonCircleOutline size={80} />
        </div>
        <div className="flex flex-col w-full justify-start ml-2">
          <h2>{localStorage.getItem("user-name")}</h2>
          <h2 onClick={logout} className="cursor-pointer">
            Logout
          </h2>
        </div>
      </div>
    </s.SidebarContainer>
  );
};

export default Sidebar;
