import React, { useState, useMemo, useEffect } from "react";
import { TextField2 } from "../../../validation/TextField2";
import { useHistory, useLocation } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import Select from "react-select";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { usePut } from "../../../../api/usePut";
import axios from "axios";
import Toggle from "react-toggle";
import { usePatch } from "../../../../api/usePatch"
import { toast } from "react-toastify"

let validate;
validate = Yup.object({
  name: Yup.string().required("Required"),
  deviceIdentifier: Yup.string().required("Required"),
  macAddress: Yup.string().required("Required").typeError("Invalid Format"),
  qrCode: Yup.string().typeError("Invalid Format"),
});

const optionsMode = [
  { value: "PROD", label: "PROD" },
  { value: "Dev", label: "Dev" },
];

function MachineEdit() {
  const [title, setTitle] = useState("Machine");

  const history = useHistory();
  const location = useLocation();

  const main = JSON.parse(localStorage.getItem("main"))

  const initialValues = main?.initialValues;
  const data = main?.data;

  const [mode, setMode] = useState({
    value: initialValues?.mode,
    label: initialValues?.mode,
  });
  const [orgId, setOrgId] = useState({
    value: main?.merchant?.rootOrgId,
    label: main?.merchant?.name,
  });

  // console.log("merchant: ", orgId)
  const [retailerId, setRetailerId] = useState({
    value: initialValues?.retailStore?.id,
    label: initialValues?.retailStore?.name,
  });
  const [selectedRetailer, setSelectedRetailer] = useState({});
  const [selecRetailerData, setSelecRetailerData] = useState({});
  const [ageRestriction, setAgeRestriction] = useState(data.ageRestricted)

  const [checked, setChecked] = useState(
    data?.status === "true" ? true : false
  );


  useEffect(() => {
    if (data.type === "chiller") {
      ageRestricted.mutate({
        id: location?.state?.data.id,
        ageRestricted: ageRestriction
      })
    }
  }, [ageRestriction])

  //console.log("bool: ", Boolean(initialValues?.status));
  // console.log("de: ", editMerchant)

  const handleChange = (e) => {
    setChecked(e.target.checked);
  };

  //console.log("de: ", checked)

  const changeHandlerMode = (value) => {
    console.log("val: ", value);
    setMode(value);
  };

  const changeHandlerOrg = (value) => {
    // console.log("val: ", value);
    setOrgId(value);
  };

  const changeHandlerRetailer = async (value) => {
    //console.log("val: ", value);
    setRetailerId(value);
    setSelecRetailerData(value.data);

    const res = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}/orgs/${value.orgId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
    });

    //console.log("val: ", res.data);
    setSelectedRetailer(res.data);
  };

  //console.log("orgList: ", main?.orgList);
  //console.log("retList: ", main?.retailerList);
  //console.log("machine: ", main.machine);

  const ageRestricted = usePatch(
    `${process.env.REACT_APP_BASE_URL}/machines/${location?.state?.data.id}/age-restriction`,
    "all"
  );

  const editMachine = usePut(
    `${process.env.REACT_APP_BASE_URL}/machines/${main?.data.id}`, "machineView");

  return (
    <>
      <div className="w-full h_screen text-left flex select-none">
        <div className="w-full h-full flex justify-center items-center bg-gray-200 ">
          <Formik
            initialValues={initialValues}
            validationSchema={validate}
            onSubmit={async (values) => {
              if (values.name !== "" && values.email !== "") {
                try {
                  const data = {
                    id: main?.data.id,
                    name: values.name
                      ? values.name
                      : main?.machine?.name,
                    status: checked,
                    deviceIdentifier: values.deviceIdentifier
                      ? values.deviceIdentifier
                      : main?.machine?.deviceIdentifier,
                    mode: mode?.value
                      ? mode.value
                      : main?.machine?.mode,
                    macAddress: values.macAddress
                      ? values.macAddress
                      : main?.machine?.macAddress,
                    qrCode: values.qrCode
                      ? values.qrCode
                      : main?.machine?.qrCode,
                    rootOrgId: orgId?.value
                      ? orgId?.value
                      : main?.machine?.rootOrgId,

                    retailStore: {
                      id: selecRetailerData?.id
                        ? selecRetailerData?.id
                        : main?.machine?.retailStore.id,
                      name: selecRetailerData?.name
                        ? selecRetailerData?.name
                        : main?.machine?.retailStore.name,
                      internalCode: selecRetailerData?.internalCode
                        ? selecRetailerData?.internalCode
                        : main?.machine?.retailStore.internalCode,
                      externalCode: selecRetailerData?.externalCode
                        ? selecRetailerData?.externalCode
                        : main?.machine?.retailStore.externalCode,
                      description: selecRetailerData?.description
                        ? selecRetailerData?.description
                        : main?.machine?.retailStore.description,
                      website: selecRetailerData?.website
                        ? selecRetailerData?.website
                        : main?.machine?.retailStore.website,
                      email: selecRetailerData?.email
                        ? selecRetailerData?.email
                        : main?.machine?.retailStore.email,
                      defaultLang: selecRetailerData?.defaultLang
                        ? selecRetailerData?.defaultLang
                        : main?.machine?.retailStore.defaultLang,
                      defaultLocale: selecRetailerData?.defaultLocale
                        ? selecRetailerData?.defaultLocale
                        : main?.machine?.retailStore.defaultLocale,
                      slug: selecRetailerData?.slug
                        ? selecRetailerData?.slug
                        : main?.machine?.retailStore.slug,
                      rootOrgId: selecRetailerData?.rootOrgId
                        ? selecRetailerData?.rootOrgId
                        : main?.machine?.retailStore.rootOrgId,
                      storeType: selecRetailerData?.storeType
                        ? selecRetailerData?.storeType
                        : main?.machine?.retailStore.storeType,
                      address: {
                        id: selecRetailerData?.address?.id
                          ? selecRetailerData?.address?.id
                          : main?.machine?.retailStore?.address?.id,
                        street1: selecRetailerData?.address?.street1
                          ? selecRetailerData?.address?.street1
                          : main?.machine?.retailStore?.address
                            ?.street1,
                        street2: selecRetailerData?.address?.street2
                          ? selecRetailerData?.address?.street2
                          : main?.machine?.retailStore?.address
                            ?.street2,
                        neighborhoodName: selecRetailerData?.address
                          ?.neighborhoodName
                          ? selecRetailerData?.address?.neighborhoodName
                          : main?.machine?.retailStore?.address
                            ?.neighborhoodName,
                        administrativeArea1: selecRetailerData?.address
                          ?.administrativeArea1
                          ? selecRetailerData?.address?.administrativeArea1
                          : main?.machine?.retailStore?.address
                            ?.administrativeArea1,
                        administrativeArea2: selecRetailerData?.address
                          ?.administrativeArea2
                          ? selecRetailerData?.address?.administrativeArea2
                          : main?.machine?.retailStore?.address
                            ?.administrativeArea2,
                        cityOrVillage: selecRetailerData?.address?.cityOrVillage
                          ? selecRetailerData?.address?.cityOrVillage
                          : main?.machine?.retailStore?.address
                            ?.cityOrVillage,
                        state: selecRetailerData?.address?.state
                          ? selecRetailerData?.address?.state
                          : main?.machine?.retailStore?.address
                            ?.state,
                        zipCode: selecRetailerData?.address?.zipCode
                          ? selecRetailerData?.address?.zipCode
                          : main?.machine?.retailStore?.address
                            ?.zipCode,
                        country: selecRetailerData?.address?.country
                          ? selecRetailerData?.address?.country
                          : main?.machine?.retailStore?.address
                            ?.country,
                        contactFirstName: selecRetailerData?.address
                          ?.contactFirstName
                          ? selecRetailerData?.address?.contactFirstName
                          : main?.machine?.retailStore?.address
                            ?.contactFirstName,
                        contactMiddleName: selecRetailerData?.address
                          ?.contactMiddleName
                          ? selecRetailerData?.address?.contactMiddleName
                          : main?.machine?.retailStore?.address
                            ?.contactMiddleName,
                        contactLastName: selecRetailerData?.address
                          ?.contactFirstName
                          ? selecRetailerData?.address?.contactFirstName
                          : main?.machine?.retailStore?.address
                            ?.contactLastName,
                        contactNumber: selecRetailerData?.address?.contactNumber
                          ? selecRetailerData?.address?.contactNumber
                          : main?.machine?.retailStore?.address
                            ?.contactNumber,
                        lat: selecRetailerData?.address?.lat
                          ? selecRetailerData?.address?.lat
                          : main?.machine?.retailStore?.address?.lat,
                        lng: selecRetailerData?.address?.lng
                          ? selecRetailerData?.address?.lng
                          : main?.machine?.retailStore?.address?.lng,
                      },
                    },
                  };


                  const handleSubmit = (e) => {

                    editMachine.mutate({ ...data, ageRestricted: ageRestriction });

                    if (editMachine.isError === false) {
                      history.goBack();
                      toast.success(

                        "Successfully Added!"

                      );
                    } else {
                      toast.error(

                        "Please Check all Fields!"

                      );
                    }
                  }
                } catch (error) { }

              }
            }
            }
          >
            {() => (
              <Form className="w-4/5 mx-auto h-4/5 bg-white rounded-lg shadow-lg">
                <div className="w-full h-16 rounded-t-lg  flex bg-nav text-white">
                  <div className="w-10 p-2 h-full flex items-center">
                    <BiArrowBack
                      onClick={() => history.goBack()}
                      className="h-10 w-10 cursor-pointer"
                    />
                  </div>
                  <div className="w-full h-full px-7 flex flex-col justify-center">
                    <h2 className="font-bold text-2xl select-none">
                      Edit {title}
                    </h2>

                  </div>
                </div>

                <div className="w-11/12 mx-auto px-6">
                  <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-12 gap-y-12 py-16">
                    <div>
                      <div className="flex items-center h-10">
                        <TextField2 label="Name" name="name" type="text" />
                      </div>
                    </div>

                    <div>
                      <div className="flex items-center h-10">
                        <TextField2
                          label="Device Identifier"
                          name="deviceIdentifier"
                          type="text"
                        />
                      </div>
                    </div>

                    <div>
                      <div className=" flex items-center h-10">
                        <TextField2
                          label="Mac Address"
                          name="macAddress"
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="flex items-center h-10 ">
                      <div className="flex   w-full select-none">
                        <div style={{ width: "400px" }}>
                          <Select
                            options={optionsMode}
                            placeholder="Select Mode"
                            maxMenuHeight={162}
                            value={mode}
                            onChange={changeHandlerMode}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex items-center h-10">
                      <div className="flex  w-full select-none">
                        <div style={{ width: "400px" }}>
                          <Select
                            placeholder="Select Retailer Store"
                            maxMenuHeight={162}
                            value={retailerId}
                            onChange={changeHandlerRetailer}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            options={main?.retailerList?.data?.map(
                              (item, index) => {
                                return {
                                  label: `${item.name} ${item.phone ? " " + `( ${item.phone} )` : ""
                                    }`,
                                  value: item.id,
                                  key: index,
                                  orgId: item?.rootOrgId,
                                };
                              }
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex items-center h-10">
                      <div className="flex  w-full select-none">
                        <div style={{ width: "400px" }}>
                          <Select
                            placeholder="Select Merchant..."
                            maxMenuHeight={162}
                            value={orgId}
                            onChange={changeHandlerOrg}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            options={[
                              {
                                label: selectedRetailer.name,
                                value: selectedRetailer.id,
                              },
                            ]}
                          />
                        </div>
                      </div>
                    </div>



                    <div className="flex items-center lg:-mt-4 ">
                      <div className="w-full h-full flex  justify-start items-center rounded-lg">
                        <span className=" mr-4">Status</span>
                        <Toggle
                          onChange={handleChange}
                          checked={checked}
                          //icons={{ checked: null, unchecked: null }}
                          className="react-switch"
                        />
                      </div>

                      {data.type === "chiller" && (
                      <div className="w-60 flex items-center rounded-lg">
                        <input
                          type="checkbox"
                          onChange={(e) => setAgeRestriction(e.target.checked)}
                          className=" h-5 w-5"
                          checked={ageRestriction}
                        />
                        <span className="mx-5 text-black-500">
                          {"Age Restriction"}
                        </span>
                      </div>
                      )}

                     
                    </div>
                  </div>
                  <div class="grid grid-cols-1 md:grid-cols-3 gap-x-3 gap-y-6 py-3">
                  <button
                          type="submit"
                          className="bg-green-500 rounded-lg px-24 py-3  focus:outline-none text-white"
                        >
                          <span className="h-full flex items-center">
                            <svg
                              class="svg-icon"
                              viewBox="0 0 20 20"
                              width="24px"
                              height="24px"
                            >
                              <path
                                fill="#8A2BE2"
                                d="M19.404,6.65l-5.998-5.996c-0.292-0.292-0.765-0.292-1.056,0l-2.22,2.22l-8.311,8.313l-0.003,0.001v0.003l-0.161,0.161c-0.114,0.112-0.187,0.258-0.21,0.417l-1.059,7.051c-0.035,0.233,0.044,0.47,0.21,0.639c0.143,0.14,0.333,0.219,0.528,0.219c0.038,0,0.073-0.003,0.111-0.009l7.054-1.055c0.158-0.025,0.306-0.098,0.417-0.211l8.478-8.476l2.22-2.22C19.695,7.414,19.695,6.941,19.404,6.65z M8.341,16.656l-0.989-0.99l7.258-7.258l0.989,0.99L8.341,16.656z M2.332,15.919l0.411-2.748l4.143,4.143l-2.748,0.41L2.332,15.919z M13.554,7.351L6.296,14.61l-0.849-0.848l7.259-7.258l0.423,0.424L13.554,7.351zM10.658,4.457l0.992,0.99l-7.259,7.258L3.4,11.715L10.658,4.457z M16.656,8.342l-1.517-1.517V6.823h-0.003l-0.951-0.951l-2.471-2.471l1.164-1.164l4.942,4.94L16.656,8.342z"
                              ></path>
                            </svg>
                            <span className="px-2 select-none cursor-pointer">
                              Save
                            </span>
                          </span>
                        </button>
                  </div>

                  
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default MachineEdit;