import React from "react";
import { GrView } from "react-icons/gr";
import { FiEdit } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import Tippy from "@tippyjs/react";

function Action({ params, url, viewUrl, editUrl }) {
  const history = useHistory();

  const edit = () => {
    history.push({
      pathname: editUrl,
      data: params.data,
    });
  };

  const view = () => {
    history.push({
      pathname: viewUrl,
      data: params.data,
    });
  };
  return (
    <>
      <div className="flex w-16 h-10 items-center">
        <Tippy content="View">
          <div className="border-2 border-gray-200 rounded-l-lg p-1 ">
            <GrView className="w-5 h-5 cursor-pointer " onClick={view} />
          </div>
        </Tippy>
        <Tippy content="edit">
          <div className="border-2 border-gray-200 rounded-r-lg p-1">
            <FiEdit className="w-5 h-5 cursor-pointer" onClick={edit} />
          </div>
        </Tippy>
      </div>
    </>
  );
}

export default Action;
