import React, { useState, useEffect } from "react";
import { GrView, GrList } from "react-icons/gr";
import { FiEdit } from "react-icons/fi";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "react-toggle/style.css";
import Select from "react-select";
import DashboardRoute from "./DashboardRoute";
import { useHistory } from "react-router-dom";
import ModalView from "../../../../../../../../modal/view/ModalSubCategory";
import ModalEdit from "../../../../../../../../modal/edit/ModalSubCategory";
import axios from "axios";
import {toast} from 'react-toastify'


function All({ isLoading, error, data, url, viewUrl, editUrl, sortedData, parentId }) {
  const [value, setValue] = useState("");
  const [selectedData, setSelectedData] = useState({});
  const [category, setCategory] = useState({});

  const [view, setView] = useState(false);
  const onOpenView = () => setView(true);
  const onCloseView = () => setView(false);

  const [edit, setEdit] = useState(false);
  const onOpenEdit = () => setEdit(true);
  const onCloseEdit = () => setEdit(false);
  const history = useHistory();

  const fetchMerchant = async (url) => {

    try {
      const res = await axios({
        method: "GET",
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      });
  
      return res;
    } catch ({message}) {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message)
      }
    }
    
  };

  const changeHandler = (value) => {
    setValue(value);
  };

  const editFn = async (data) => {
    setSelectedData(data);

    const res = await fetchMerchant(
      `${process.env.REACT_APP_BASE_URL}/categories/${data?.id}`
    );
    setCategory(res?.data);

    // console.log("data: ", res?.data)

    onOpenEdit();
  };

  const viewFn = async (data) => {
    setSelectedData(data);

    const res = await fetchMerchant(
      `${process.env.REACT_APP_BASE_URL}/api/categories/${data?.id}`
    );
    setCategory(res?.data);

    onOpenView();
  };

  return (
    <div className=" w-full h_das_table">
      <div className="flex h-10 w-full mt-2 items-center justify-between py-8 px-4">
        <input
          className="w-2/5 focus:outline-none py-1.5 px-2 border-2 border-gray-200 rounded-lg"
          placeholder="Search"
        />

        <div style={{ width: "40%" }}>
          <Select
            placeholder="Select"
            className="text-left"
            maxMenuHeight={162}
            value={value}
            onChange={changeHandler}
            options={sortedData?.map((item, index) => {
              return {
                label: item,
                value: item,
                key: index,
              };
            })}
          />
        </div>
        <button className="bg-green-600 py-1.5 px-5 mr-7 rounded text-white">
          Clear filters
        </button>
      </div>

      <div className="ag-theme-alpine w-full h-full text-left">
        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            resizable: true,
          }}
          suppressRowClickSelection={true}
          rowSelection={"multiple"}
          pagination={true}
          paginationPageSize={10}
          rowData={data}
        >
          <AgGridColumn
            headerName="Category Name"
            field="name"
            minWidth={280}
            sortable={true}
            headerCheckboxSelection={true}
            headerCheckboxSelectionFilteredOnly={true}
            checkboxSelection={true}
            cellRendererFramework={(params) => (
              <DashboardRoute params={params} />
            )}
          />

          <AgGridColumn
            headerName="Description"
            field="description"
            minWidth={200}
            sortable={true}
            cellRendererFramework={(params) => (
              <p className="ml-6">
                {params?.data.description ? params.data.description : "---"}
              </p>
            )}
          />

          <AgGridColumn
            field="action"
            minWidth={100}
            cellRendererFramework={(params) => (
              <>
                <div className="flex w-16 h-10 items-center">
                  {/* <div className="border-2 border-gray-200 rounded-l-lg p-1 ">
                    <GrView
                      className="w-5 h-5 cursor-pointer "
                      onClick={() => viewFn(params?.data)}
                    />
                  </div> */}
                  <div className="border-2 border-gray-200 rounded-lg p-1">
                    <FiEdit
                      className="w-5 h-5 cursor-pointer"
                      onClick={() => editFn(params?.data)}
                    />
                  </div>
                </div>
              </>
            )}
          />
          {/* <AgGridColumn
            headerName="Sub Categories"
            minWidth={200}
            sortable={true}
            cellRendererFramework={(params) => (
              <>
              <div className="flex w-16 h-10 items-center">
                <div className="border-2 ml-2 border-gray-200 rounded-lg p-1 ">
                  <GrList
                    className="w-5 h-5 cursor-pointer "
                    onClick={() => viewFn(params?.data)}
                  />
                </div>
             
              </div>
            </>
            )}
          /> */}
        </AgGridReact>
      </div>
      <ModalView
        open={view}
        onCloseModal={onCloseView}
        data={selectedData?.data}
        category={category}
      />

      <ModalEdit
        open={edit}
        onCloseModal={onCloseEdit}
        data={selectedData}
        category={category}
        parentId={parentId}
      />
    </div>
  );
}

export default All;
