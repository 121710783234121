import React from "react";
import { BiArrowBack } from "react-icons/bi";
import { useHistory, useLocation } from "react-router-dom";
import { BsFillLockFill, BsUnlockFill } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import { RiStoreFill } from "react-icons/ri";
import { VscDebugRestart } from "react-icons/vsc";
import TableStr from "./TableStr";
import Scales from "./options/Scales";
import Promotion from "./options/Promotion";
import { MdSyncDisabled } from "react-icons/md";
import { CgOpenCollective, CgCalibrate } from "react-icons/cg";
import { useGet } from "../../../../../../../api/useGet";

function MachineDashboard() {
  //console.log("data", JSON.parse(localStorage.getItem("dashboard-data")))

  const history = useHistory();
  const location = useLocation();

  const data = JSON.parse(localStorage.getItem("machineId"));

  return (
    <div className="w-full h-screen flex text-left select-none">
      <div className="w-full h-full flex flex-col items-center bg-gray-200 px-4 py-2">
        <div className=" w-full mx-auto  bg-white rounded-lg shadow-lg ">
          <div className="w-full h-10 flex bg-gray-100  items-center justify-between px-2">
            <div className="flex">
              <BiArrowBack
                onClick={() => history.goBack()}
                className="h-6 w-6 cursor-pointer"
              />
              <h2 className="font-bold px-6 text-xl">{data?.name}</h2>
            </div>
            <div className="flex justify-between  h-7">
              {/* <button className="focus:outline-none bg-grey-100 bg-dash text-white ml-1 hover:text-blue-500 text-grey-darkest font-bold py-1 px-4 rounded inline-flex items-center">
                <FaEdit className="w-4 " />
                <span className="mx-2">Edit</span>
              </button> */}

              {/*  

               <button className="focus:outline-none bg-dash hover:text-blue-500 ml-1 text-white font-bold py-1.5 px-4 rounded inline-flex items-center">
                <BsFillLockFill className="w-4 " />
                <span className="mx-2">Lock</span>
               </button>

              <button className="focus:outline-none bg-grey-100 bg-dash ml-1 text-white hover:text-blue-500 text-grey-darkest font-bold py-1 px-4 rounded inline-flex items-center">
                <BsUnlockFill className="w-4 " />
                <span className="mx-2">Unlock</span>
              </button>

               */}

              {/* <button className="focus:outline-none bg-dash hover:text-blue-500 ml-1 text-white font-bold py-1.5 px-4 rounded inline-flex items-center">
                <CgOpenCollective className="w-4 " />
                <span className="mx-2">Enable</span>
              </button>

              <button className="focus:outline-none bg-grey-100 bg-dash ml-1 text-white hover:text-blue-500 text-grey-darkest font-bold py-1 px-4 rounded inline-flex items-center">
                <MdSyncDisabled className="w-4 " />
                <span className="mx-2">Disable</span>
              </button>

              <button className="focus:outline-none bg-grey-100  bg-dash text-white ml-1 hover:text-blue-500 text-grey-darkest font-bold py-1 px-4 rounded inline-flex items-center">
                <VscDebugRestart className="w-4 " />
                <span className="mx-2">Restart</span>
              </button>

              <button className="focus:outline-none bg-grey-100 bg-dash text-white ml-1 hover:text-blue-500 text-grey-darkest font-bold py-1 px-4 rounded inline-flex items-center">
                <RiStoreFill className="w-4 " />
                <span className="mx-2">Refilling</span>
              </button> */}
            </div>
          </div>

          <div className="w-full h-24 grid grid-cols-2 gap-2">
            <div className="w-full h-full  p-2 select-none">
              <table className="table-auto rounded border-collapse border  w-full h-full">
                <tbody className="text-left">
                  <tr>
                    <th className="w-32 border border-gray-300 bg-gray-200 px-2">
                      Name :
                    </th>
                    <th className="border border-gray-300 px-2 text-gray-500">
                      {data?.name}
                    </th>
                  </tr>
                  <tr>
                    <th className="w-32 border border-gray-300 bg-gray-200 px-2">
                      Device Id :
                    </th>
                    <th className="border border-gray-300 px-2 text-gray-500">
                      {data?.deviceIdentifier}
                    </th>
                  </tr>
                  <tr>
                    <th className="w-32 border border-gray-300 bg-gray-200 px-2">
                      Location :
                    </th>
                    <th className="border border-gray-300 px-2 text-gray-500">
                      {`

                      ${
                        data?.retailStore?.address?.street1
                          ? `${data?.retailStore?.address?.street1}, `
                          : ""
                      }
                      ${
                        data?.retailStore?.address?.street2
                          ? `${data?.retailStore?.address?.street2}, `
                          : ""
                      }
                      ${
                        data?.retailStore?.address?.cityOrVillage
                          ? `${data?.retailStore?.address?.cityOrVillage}, `
                          : ""
                      }
                      ${
                        data?.retailStore?.address?.state
                          ? `${data?.retailStore?.address?.state}, `
                          : ""
                      }
                      ${
                        data?.retailStore?.address?.country
                          ? `${data?.retailStore?.address?.country}, `
                          : ""
                      } 
                      ${
                        data?.retailStore?.address?.zipCode
                          ? `${data?.retailStore?.address?.zipCode}`
                          : ""
                      }
                     
                      `}
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="w-full h-full  p-2 select-none">
              <table className="table-auto rounded border-collapse border  w-full h-full">
                <tbody className="text-left">
                  <tr>
                    <th className="w-32 border border-gray-300 bg-gray-200 px-2">
                      Qr Code :
                    </th>
                    <th className="border border-gray-300 px-2 text-gray-500">
                      {data?.qrCode}
                    </th>
                  </tr>
                  <tr>
                    <th className="w-32 border border-gray-300 bg-gray-200 px-2">
                      Mode :
                    </th>
                    <th className="border border-gray-300 px-2 text-gray-500">
                      {data?.mode}
                    </th>
                  </tr>
                  <tr>
                    <th className="w-32 border border-gray-300 bg-gray-200 px-2">
                      Mac Address
                    </th>
                    <th className="border border-gray-300 px-2 text-gray-500">
                      {data?.macAddress}
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="h-full w-full mt-4">
          <TableStr
            viewUrl={"/admin/merchant/dashboard/machine/management/scale/view"}
            editUrl={"/admin/merchant/dashboard/machine/management/scale/edit"}
            listUrl={`${
              process.env.REACT_APP_BASE_URL
            }/scales?sort=scaleId,asc&rootOrgId.equals=${parseInt(
              JSON.parse(localStorage.getItem("dashboard-data"))?.id
            )}`}
            //&rootOrgId.equals=${parseInt(JSON.parse(localStorage.getItem("dashboard-data"))?.id)}
            getName={"machineList"}
            deviceId={data?.deviceIdentifier}
            Scales={Scales}
            Promotion={Promotion}
          />
        </div>
      </div>
    </div>
  );
}

export default MachineDashboard;
