import React from "react";
import TableStr from "./TableStr";
import All from "./options/All";
import { useLocation } from "react-router-dom";
import { useGet } from "../../../../../../api/useGet";

function Rack() {
  const merchantId = parseInt(
    JSON.parse(localStorage.getItem("dashboard-data"))?.id
  );

  return (
    <div className="h-full w-full ">
      <TableStr
        title={"Merchant Config"}
        viewUrl={""}
        editUrl={`/admin/merchant/dashboard/merchantconfig/MerchantconfigEdit`}
        listUrl={`${process.env.REACT_APP_BASE_URL}/org-configurations/${merchantId}`}
        getName={"merchantConfig"}
        All={All}
        url={`/admin/merchant/dashboard/merchantconfig/CreateMerchantconfig`}
      />
    </div>
  );
}

export default Rack;
