import React, { useState, useEffect } from "react";
import ModalImg from "../../../ModalImg";
import { BsCheckAll } from "react-icons/bs";

const ProductItem = ({
  data,
  item,
  index,
  partial,
  partialSelect,
  refundType,
  onChangeOrderItems,
  path,
}) => {
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const [qty, setQty] = useState(item?.quantity);

  const onChangeInput = (value, id) => {
    setQty(value);
    onChangeOrderItems(value, id);
  };

  const itemCheck = (id) => {
    const find = partial?.items?.find(function (ele) {
      return ele.scaleIdentifier === id;
    });
    return find?.parentScaleIdentifier;
  };

  return (
    <div
      onClick={() => refundType === "PARTIAL" && partialSelect(item)}
      className="w-9.8/10 rounded-lg bg-white mb-2 flex items-center h-20  px-4  cursor-pointer"
    >
      <div className="w-32">
        {item?.imageUrl ? (
          <img
            onClick={onOpenModal}
            src={item?.imageUrl}
            alt={item?.productName}
            className="w-20 h-20 object-fill cursor-pointer rounded-lg p-1 bg-gray-200"
          />
        ) : null}
        <ModalImg
          open={open}
          onCloseModal={onCloseModal}
          img={item?.imageUrl}
        />
      </div>

      <div className="w-full h-full items-center flex justify-between border-b-2 border-gray-200">
        <div className="w-1/4 pl-8">
          <h2>{item?.productName}</h2>
        </div>

        {data?.status === "PAYMENT_PROCESSED" && (
          <div className="w-1/4 flex justify-center">
            {path === "OrderSummary" ? (
              <p>{item?.quantity}</p>
            ) : (
              <input
                onChange={(e) => {
                  onChangeInput(e.target.value, item?.id);
                }}
                value={qty}
                className="focus:outline-none w-16 border rounded-lg text-center"
              />
            )}
          </div>
        )}

        {(path !== "OrderSummary" || data?.status !== "PAYMENT_PROCESSED") && (
          <div className="w-1/4 flex justify-center">
            <p>{item?.refundQuantity}</p>
          </div>
        )}

        <div className="w-1/4 flex justify-center">
          <h2>
            {data?.currencySymbol} {parseFloat(item?.unitCost).toFixed(2)}
          </h2>
        </div>
        <div className="w-1/4 flex justify-center">
          <h2>
            {data?.currencySymbol} {parseFloat(item?.itemCost).toFixed(2)}
          </h2>
        </div>

        <div className="absolute right-20">
          {partial?.items.includes(item?.id) && refundType === "PARTIAL" && (
            <BsCheckAll size={20} color="green" />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductItem;
