import React from "react";
import TextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import MobileDatePicker from "@mui/lab/MobileDatePicker";

const Datepicker = ({value, onChangeTime, name, label, clearIcon, minDateTime, maxDateTime}) => {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        
          <MobileDatePicker
            label={label}
            value={value}
            clearIcon={clearIcon}
            onChange={(value)=> onChangeTime(value, name)}
            renderInput={(params) => <TextField {...params} />}
          />
      
      </LocalizationProvider>
    );
  };
  
  export default Datepicker;
  