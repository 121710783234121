import React from "react";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";

import BackArrowButton from "../../../../../../../components/buttons/BackarrowButton";

const CommonHeader = ({
  headerTitle,
  isCreateButton = true,
  createButtonTitle,
  handleCreateButtonEvent,
}) => {
  const history = useHistory();

  const handleCreateTemplateNavigation = () =>
    history.push("notifications/create-template");

  return (
    <div className="grid grid-cols-3 gap-4 w-[100%] p-4 border-b-2 border-black">
      <div className="flex items-center justify-start">
        <BackArrowButton />
      </div>
      <p className="text-3xl font-bold">
        {headerTitle || "Template Notifications"}
      </p>
      {isCreateButton && (
        <div className="flex items-center justify-end">
          <Button
            // fullWidth={false}
            variant="outlined"
            onClick={
              handleCreateButtonEvent
                ? handleCreateButtonEvent
                : handleCreateTemplateNavigation
            }
          >
            {createButtonTitle ? createButtonTitle : "Create Template"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default CommonHeader;
