import React, { createContext, useContext, useMemo, useState } from "react";
import moment from "moment";

const OpenEventsDataContext = createContext({
  checkedRack: [],
  setCheckedRack: () => {},
  opencartApiParams: {},
  setOpencartApiParams: () => {},
  flag: true,
  setFlag: () => {},
  selectedIndex: 1,
  setSelectedIndex: () => {},
});

const OpenEventshDataProvider = ({ children }) => {
  const [checkedRack, setCheckedRack] = useState([]);

  const [opencartApiParams, setOpencartApiParams] = useState({
    "scaleInternalCode.contains": "",
    "scaleInternalCode.in": "",
    "scaleIdentifier.equals": "",
    "scaleGroupId.equals": ""
  });

  const [flag, setFlag] = useState(true);

  const [selectedIndex, setSelectedIndex] = useState(1);

  const memoizedValue = useMemo(
    () => ({
      checkedRack,
      setCheckedRack,
      opencartApiParams,
      setOpencartApiParams,
      flag,
      setFlag,
      selectedIndex,
      setSelectedIndex,
    }),
    [checkedRack, opencartApiParams, flag, selectedIndex]
  );

  return (
    <OpenEventsDataContext.Provider value={memoizedValue}>
      {children}
    </OpenEventsDataContext.Provider>
  );
};

function useOpeneventDataProvider() {
  const context = useContext(OpenEventsDataContext);
  if (context === undefined) {
    throw new Error("Please provide Search data provider");
  }
  return context;
}

export { OpenEventshDataProvider, useOpeneventDataProvider };
