import React, {useState} from 'react'
import VideoModal from './VideoModal'

function HawkeyeThumbnail({imageUrl, videoUrl}) {
  
    const [video, setVideo] = useState(false);
    const onOpenVideo = () => setVideo(true);
    const onCloseVideo = () => setVideo(false);
    return (
        <>
        <div className={`cursor-pointer`} onClick={onOpenVideo} >
          <img src={imageUrl} alt="thumbnail" />
        </div>
          <VideoModal open={video} onCloseModal={onCloseVideo} videoUrl={videoUrl}/>
        </>
    )
}

export default HawkeyeThumbnail
