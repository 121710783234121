import { Formik, Form } from "formik";
import { useState } from "react";
import { IoIosClose } from "react-icons/io";
import axios from "axios";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { usePost } from "../../../api/usePost";

function ModalVoucherAllocation({ open, voucher, onCloseModal }) {
  const history = useHistory();
  const [search, setSearch] = useState({
    firstName: "",
    phone: "",
    email: "",
    login: "",
    filterBy: "",
  });
  const [isSearch, setIsSearch] = useState(false);
  const [selectedShopper, setSelectedshopper] = useState(null);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [userList, setUserList] = useState([]);

  const handleInput = (value, name) => {
    console.log(name);
    setSearch({ ...search, [name]: value, filterBy: name });
    setIsSearch(true);

    console.log(search?.filterBy);
  };

  const onClose = () => {
    setSearch({
      firstName: "",
      phone: "",
      login: "",
      email: "",
      filterBy: "",
    });
    setUserList([]);
    setSelectedshopper(null);
    setEnableSubmit(false);
    onCloseModal();
    setIsSearch(false);
  };

  const findUrl = (filterName) => {
    const listUrl = `${
      process.env.REACT_APP_SHOPPER_URL
    }/users?rootOrgId.equals=${parseInt(
      JSON.parse(localStorage.getItem("dashboard-data"))?.id
    )}`;
    switch (filterName) {
      case "firstName":
        return `${listUrl}&firstName.equals=${search?.firstName}`;

      case "phone":
        return `${listUrl}&phone.equals=${search?.phone}`;

      case "login":
        return `${listUrl}&login.equals=${search?.login}`;

      default:
        return `${listUrl}`;
    }
  };

  const fetchData = async () => {
    if (search?.barCode !== "") {
      try {
        const res = await axios({
          method: "GET",
          url: `${findUrl(search?.filterBy)}&page=${0}&size=10`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("user-info")}`,
          },
        });
        setUserList(res?.data);
        return res.data;
      } catch ({ message }) {
        if (message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          toast.error(message);
        }
      }
    }
  };

  const { users } = useQuery(
    ["users", search?.firstName, search?.login, search?.phone, search?.email],
    async () => fetchData(),
    {
      enabled:
        !!search?.firstName ||
        !search?.login ||
        !search?.phone ||
        !search?.email,
      cacheTime: Infinity,
      staleTime: 0,
    }
  );

  const onUserSelect = (data) => {
    setSelectedshopper(data);
    setEnableSubmit(true);
    setIsSearch(false);
  };

  const mapShopperToVoucher = usePost(
    `${process.env.REACT_APP_SHOPPER_URL}/shopper-vouchers/allocate/${voucher?.id}`,
    "mapShopperToVoucher"
  );

  const mapShopper = () => {
    try {
      mapShopperToVoucher.mutate({
        voucherCode: voucher?.voucherCode,
        voucherId: voucher?.id,
        login: selectedShopper?.login,
        phone: selectedShopper?.userInfoDTO?.phone,
        rootOrgId: voucher?.rootOrgId,
      });

      if (mapShopperToVoucher.isError === false) {
        onClose();
      } else {
        alert.error(
          <div style={{ textTransform: "none" }}>Please Check all Fields!</div>
        );
      }
    } catch (error) {
      alert.error(
        <div style={{ textTransform: "none" }}>you are offline!</div>
      );
    }
  };

  return (
    <>
      {open && (
        <div className="w-1/2 text-left select-none  z-10 absolute top-1/2 left-1/2  ml-32 transform -translate-x-1/2 -translate-y-1/2 ">
          <Formik>
            {() => (
              <Form className="w-full  bg-gray-100 border-2 border-dash rounded-lg shadow-lg ">
                <div className="w-full h-16 flex bg-dash text-white">
                  <div className="w-full h-full px-7 flex flex-col justify-center">
                    <h2 className="font-bold text-2xl">Voucher Allocation</h2>
                  </div>
                  <div className="w-16 px-4 h-full flex items-center">
                    <div className="w-full flex justify-end  ">
                      <IoIosClose
                        onClick={onClose}
                        className="h-12 w-12 cursor-pointer"
                      />
                    </div>
                  </div>
                </div>
                <div className="w-11/12 mx-auto px-6 my-2">
                  <div className="w-full mt-4  items-center">
                    <div className="flex px-4 mr-4 w-full items-center select-none">
                      <input
                        className="w-4/5 focus:outline-none py-1.5 px-2 mr-2 border-2 border-gray-200 rounded-lg"
                        placeholder="Search by First Name"
                        onChange={(e) =>
                          handleInput(e.target.value, "firstName")
                        }
                        // value={search?.barCode}
                      />
                      <input
                        className="w-4/5 focus:outline-none py-1.5 mr-2 px-2 border-2 border-gray-200 rounded-lg"
                        placeholder="Search by Phone"
                        onChange={(e) => handleInput(e.target.value, "phone")}
                        // value={search?.barCode}
                        type="number"
                      />
                      <input
                        className="w-4/5 focus:outline-none py-1.5 mr-2 px-2 border-2 border-gray-200 rounded-lg"
                        placeholder="Search by Login"
                        onChange={(e) => handleInput(e.target.value, "login")}
                        // value={search?.barCode}
                      />
                    </div>
                    {isSearch && (
                      <div className="w-[75%] focus:outline-none py-1.5 px-2 ml-5 mt-2 rounded-lg">
                        {userList &&
                          userList?.length > 0 &&
                          userList?.map((userView, index) => (
                            <div
                              className="w-[100%] flex justify-between items-center border border-gray-300 px-[20px] cursor-pointer bg-white mt-2"
                              key={`product-${index}`}
                              onClick={() => onUserSelect(userView)}
                            >
                              <div className="w-[90%]">
                                <p>
                                  {userView?.firstName} {userView?.lastName}
                                </p>
                              </div>
                              <div className="w-[90%]">
                                <p>{userView?.login}</p>
                              </div>
                              <div className="w-[90%]">
                                <p>{userView?.userInfoDTO?.phone}</p>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col w-8/12 ml-10 pl-10 my-2">
                    {!isSearch && selectedShopper !== null && (
                      <div className="flex bg-white justify-left">
                        <div className="w-[90%]">
                          <p>
                            {selectedShopper?.firstName}{" "}
                            {selectedShopper?.lastName}
                          </p>
                        </div>
                        <div className="w-[90%]">
                          <p>{selectedShopper?.login}</p>
                        </div>
                        <div className="w-[90%]">
                          <p>{selectedShopper?.userInfoDTO?.phone}</p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="flex w-11/12 justify-center">
                    <button
                      onClick={mapShopper}
                      className="bg-green-500 rounded-lg px-6 py-3 mb-2 focus:outline-none text-white"
                      disabled={!enableSubmit}
                    >
                      <span className="px-2">Allocate Voucher</span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
}

export default ModalVoucherAllocation;
