import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useGet } from "../../../../../../api/useGet";

function GroupShopper() {
  const history = useHistory();

  const exit = JSON.parse(localStorage.getItem("exitData"));
  const { data: groupShoppers } = useGet(
    `${process.env.REACT_APP_STORE_URL}/user-exit-activities?status.equals=RESOLVED&cartRef.equals=${exit?.cartRef}&sort=exitTime,desc`,
    "groupShopper"
  );

  return (
    <>
      <div className="w-full h-full flex flex-col justify-center items-center">
        <div className="w-full h-12 flex bg-gray-100  items-center mb-10">
          <BiArrowBack
            onClick={() => history.goBack()}
            className="h-6 w-6 cursor-pointer ml-5"
          />
          <h2 className="font-bold px-6 text-xl">{"Group Shopper"}</h2>
        </div>

        <div className="w-full h_group scrollbar-thin scrollbar-thumb-gray-900 scrollbar-track-gray-100">
          {groupShoppers?.map((groups) => {
            return (
              <div className="mb-12">
                <div className="w-full h-48 flex justify-center items-center">
                  {groups?.userImagePaths?.map((url, index) => {
                    return (
                      <div key={index} className="mx-2">
                        <img
                          src={url}
                          alt="groupShopper"
                          className="w-48 h-48 rounded-lg shadow-lg "
                        />
                      </div>
                    );
                  })}
                </div>

                <div className="w-full flex  justify-center items-center mt-4">
                  <div className="w-4/5 flex flex-col items-start">
                    <p>{`Qr Token : ${groups?.qrToken}`}</p>
                    <p className="mt-1">
                      {`Exit Time : ${moment(groups?.exitTime).format(
                        "MM-D-YYYY, hh:mm:ss A"
                      )}`}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default GroupShopper;
