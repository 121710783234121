import CryptoJS from "crypto-js";

// Encryption key (replace with a secure key)
const encryptionKey = "your-secret-key";

// Function to encrypt and store data in localStorage
export const storeEncryptedData = (key, data) => {
  try {
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      encryptionKey
    ).toString();
    localStorage.setItem(key, encryptedData);
  } catch (error) {
    console.error("Error storing encrypted data:", error);
  }
};

// Function to retrieve and decrypt data from localStorage
export const getDecryptedData = (key) => {
  try {
    const encryptedData = localStorage.getItem(key);
    if (encryptedData) {
      const decryptedData = CryptoJS.AES.decrypt(
        encryptedData,
        encryptionKey
      ).toString(CryptoJS.enc.Utf8);
      return decryptedData ? JSON.parse(decryptedData) : null;
    }
  } catch (error) {
    console.error("Error retrieving decrypted data:", error);
  }
  return null;
};
