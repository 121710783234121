import React from "react";
import { GrView } from "react-icons/gr";
import { MdDownload } from "react-icons/md";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import {Languages1} from '../../../../../../Languages'
import Tippy from "@tippyjs/react";

function Action({ params, allSection }) {

  const history = useHistory();
  const cookies = new Cookies();

  const fetchDownload = (url, name) => {
    fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem("user-info")}`
      })
    }
    )
      .then((res) => res.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${name}.pdf`)

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });


  };


  const detectedLan = (code) => {
    return Languages1?.filter((item) => {return item?.code === code})
  }

  const download = async () => {
    fetchDownload(`${process.env.REACT_APP_SHOPPER_URL}/orders/${allSection ? params?.data?.id : params?.data?.order?.id}/download?langKey=${detectedLan(cookies.get("i18next"))[0]?.value}`, `${params?.data?.orderNumber}_${detectedLan(cookies.get("i18next"))[0]?.code}`)
  }

  return (
    <>
      <div className="flex w-16 h-10 items-center justify-center">
      <Tippy content="Download receipt">
        <div className="border-2 border-gray-200 rounded-lg p-1 ">
          <MdDownload
            className="w-5 h-5 cursor-pointer "
            onClick={download}
          />
        </div>
        </Tippy>
      </div>
    </>
  );
}

export default Action;
