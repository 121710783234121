import React from "react";
import TableStr from "./TableStr";
import Enabled from "./options/Enabled";
import Disabled from "./options/Disabled";
import All from "./options/All";
import {useLocation} from "react-router-dom";

function Machine() {
  const location = useLocation();
  return (
    <div className="h-full w-full ">
      <TableStr
        title={"Machine"}
        url={"/admin/merchant/newMerchant"}
        viewUrl={"/admin/merchant/dashboard/machine/view"}
        editUrl={"/admin/merchant/dashboard/machine/edit"}
        listUrl={`${process.env.REACT_APP_BASE_URL}/machines?sort=createdDate,desc&rootOrgId.equals=${parseInt(JSON.parse(localStorage.getItem("dashboard-data"))?.id)} `}
        getName={"dasmachineList"}
        Enabled={Enabled}
        Disabled={Disabled}
        All={All}
        dashboard={true}
        reatilerList={true}
      />
    </div>
  );
}

export default Machine;