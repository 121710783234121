import React, { useState, useEffect } from "react";
import TableStr from "../../../../../../pages/support/dashboard/pages/order/TableStr";
import Enabled from "./options/Enabled";
import Disabled from "./options/Disabled";
import Refunded from "../../../../../../pages/support/dashboard/pages/order/options/Refunded";
// import All from "./options/All";
import All from "../../../../../../pages/support/dashboard/pages/order/options/All";
import axios from "axios";

//'http://api.shopper-uat.autonomo.tech/orders?sort=createdDate,desc&page=0&size=10
//src/pages/support/dashboard/pages/order/TableStr.js

function Order() {
  const appData = JSON.parse(localStorage.getItem("dashboard-data"));

  const rootId = parseInt(appData?.id);
  const orgName = appData?.name;
  console.log("🚀 ~ file: Order.js:19 ~ Order ~ orgName:", orgName);
  return (
    <div className="h-full w-full ">
      <TableStr
        title={"Orders"}
        listUrl={`${process.env.REACT_APP_SHOPPER_URL}/orders/summary?sort=createdDate,desc&rootOrgId.equals=${rootId}`}
        getName={"orderList"}
        All={All}
        Refunded={Refunded}
        refundUrl={`${process.env.REACT_APP_SHOPPER_URL}/order-refunds?projection=OrderListDTO&sort=createdDate,desc&rootOrgId.equals=${rootId}`}
        merchant
        orgName={orgName}
      />
    </div>
  );
}

export default Order;
