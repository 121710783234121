import React, { useState } from "react";
import VideoModal from "./VideoModal";
import { IoPlayOutline } from "react-icons/io5";

function HawkeyeThumbnail({ imageUrl, videoUrl, index, camera }) {
  const [video, setVideo] = useState(false);
  const onOpenVideo = () => setVideo(true);
  const onCloseVideo = () => setVideo(false);
  return (
    <div key={index}>
      {/* <div
        key={index}
        className="w-full h-full flex justify-center items-center cursor-pointer relative"
        onClick={onOpenVideo}
      >
        <img src={imageUrl} className="w-36 h-36 rounded" />

        <IoPlayOutline size={28} color="white" className="absolute" />
      </div> */}
      <button
        type="button"
        onClick={onOpenVideo}
        className="mt-2 cursor-pointer rounded-md bg-green-500 bg-opacity-80 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 w-[185px]"
      >
        Shopper Loop Video
      </button>

      <VideoModal
        open={video}
        onCloseModal={onCloseVideo}
        videoUrl={videoUrl}
        camera={camera}
      />
    </div>
  );
}

export default HawkeyeThumbnail;
