import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { getTime } from "../../../../../../../logic/useGetTime";
import moment from "moment";
import { useGet } from "../../../../../../../api/useGet";
import axios from "axios";
import { toast } from "react-toastify";
import NoImage from "../../../../../../../Images/noImage.jpg";
import EntryImages from "../../../shopperActivity/dashboard/EntryImages";
import SelectEvent from "../options/SelectEvent";
import SelectIREvents from "../options/SelectIREvent";
import { useQuery } from "react-query";
// import SelectTabEvents from "../dashboard/SelectTabEvents";

function ExitDecision() {
  const history = useHistory();
  const {
    state: { data: exitActivity },
  } = useLocation();
  const [select, setSelect] = useState();
  const [irSelect, setIRSelect] = useState();
  const [exitImages, setExitImages] = useState([]);
  const [decisionComment, setDecisionComment] = useState();
  const [entryList, setEntryList] = useState([]);
  const [userActivityId, setUserActivityId] = useState();
  const [
    isShopperRecommendationAvailable,
    setIsShopperRecommendationAvailable,
  ] = useState(false);
  const [exitTask, setExitTask] = useState();
  const [exitIrEvents, setExitIrEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  const onChangeInput = (value, name) => {
    if (name === "decisionComment") {
      setDecisionComment(value);
    }
  };

  const onSelect = (value) => {
    setSelect(value);
    const userActivityId = value?.userActivityId;
    setUserActivityId(userActivityId);
  };

  const onIrSelect = (data) => {
    setIRSelect(data);
  };

  const exitTaskUrl = `${process.env.REACT_APP_STORE_URL}/exit-tasks/user-exit-activity/${exitActivity?.id}`;
  useEffect(() => {
    setLoading(true);
    fetchExitTask()
      .then((data) => {
        setExitTask(data);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (exitTask !== null && exitTask !== undefined) {
      const userList = exitTask?.suggestedUserActivities;

      if (exitTask?.isShopperLoopExitSignalAvailable === true) {
        setIsShopperRecommendationAvailable(true);
        // if (userList && userList?.length > 0) {
        //     setSelect(userList[0]);
        //     const userActivityId = userList[0]?.userActivityId;
        //     if (userActivityId !== null && userActivityId !== undefined) {
        //         setUserActivityId(userActivityId);
        //     }
        // }
      } else {
        setIsShopperRecommendationAvailable(false);
      }
      setEntryList((prevList) => [...prevList, ...userList]);

      if (exitTask?.associatedExitIrEvents?.length > 0) {
        setExitIrEvents(exitTask?.associatedExitIrEvents);
      }
    }
  }, [exitTask]);

  const fetchExitTask = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: exitTaskUrl,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      });
      return res?.data;
    } catch ({ message }) {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message);
      }
    }
  };

  // const { data } = useQuery(
  //     ['exitTask'],
  //     async () =>  fetchExitTask(exitActivity?.id)
  // );

  const onApprove = () => {
    const baseActivities = exitTask?.baseActivities;
    if (irSelect !== null && irSelect !== undefined) {
      baseActivities["ExitIRActivity"] = irSelect?.id;
    }
    const payload = {
      ...exitTask,
      decisionComment: decisionComment,
      userActivityId,
      baseActivities,
    };

    axios({
      method: "PUT",
      url: `${process.env.REACT_APP_STORE_URL}/exit-tasks/${exitTask?.id}/approve-task`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
      data: payload,
    })
      .then((res) => {
        history.goBack();
      })
      .catch(({ message }) => {
        if (message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          toast.error(message);
        }
      });
  };

  const onReject = () => {
    const payload = {
      ...exitTask,
      decisionComment: decisionComment,
    };

    axios({
      method: "PUT",
      url: `${process.env.REACT_APP_STORE_URL}/exit-tasks/${exitTask?.id}/reject-task`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
      data: payload,
    })
      .then((res) => {
        history.goBack();
      })
      .catch(({ message }) => {
        if (message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          toast.error(message);
        }
      });
  };

  useEffect(() => {
    const people = [];
    for (let i = 0; i < exitActivity?.userImagePaths?.length; i++) {
      people.push(
        exitActivity?.userImagePaths?.[i]
          ? exitActivity.userImagePaths?.[i]
          : ""
      );
    }
    setExitImages(people);
  }, [exitActivity]);

  return (
    <div className="h-[calc(100vh-60px)] w-full bg-gray-300">
      <div className="h-8 flex items-center ">
        <div className="absolute mx-4 flex items-center">
          <BiArrowBack
            onClick={() => history.goBack()}
            className="h-6 w-6 cursor-pointer"
          />
        </div>
        <div className="w-full flex justify-center items-center">
          <h2 className="font-bold text-2xl">Review Exit Decision</h2>
        </div>
      </div>
      <div className="w-full h-[45%] flex rounded-t-xl pt-8 bg-white space-x-[10px]">
        <div className="w-[73%] h-full flex flex-col">
          <div className="cell_border_black flex h-full">
            <div className="w-[60%] ml-2 mt-2 mb-2 cell_border_black">
              <div className="mt-3 w-full ml-2 flex items-center justify-center">
                <p>
                  <span className="font-bold">Exit Event</span>
                </p>
              </div>
              <hr className="hr_style" />
              <p className="mt-3 mb-3">
                <span className="font-bold mt-3">Exit Time : </span>{" "}
                {getTime(exitActivity.exitTime, exitActivity?.storeTimeZone)}
              </p>
              <div className="flex w-full  justify-center items-center mb-4 mt-2">
                {exitImages?.length > 0 ? (
                  exitImages?.map((url, index) => (
                    <EntryImages url={url} index={index} key={index} />
                  ))
                ) : (
                  <img src={NoImage} className="w-40 h-44" alt="entryimage" />
                )}
              </div>
            </div>
            <div className="w-[40%]  ml-2 mt-2 mb-2 mr-2 cell_border_black">
              <div className="mt-3 w-full ml-2 flex items-center justify-center">
                <p>
                  <span className="font-bold">Exit IR Events</span>&nbsp;
                  (Please select one IR)
                </p>
              </div>
              <hr className="hr_style" />
              <div className="h-[30vh] mt-3 ml-2 mr-2 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
                {exitIrEvents && exitIrEvents?.length > 0 ? (
                  exitIrEvents?.map((irData, index) => {
                    return (
                      <div
                        key={`ir-${index}`}
                        className="border-b-4 border-gray-400 space-y-2 h-55 flex flex-col justify-between"
                      >
                        <SelectIREvents
                          data={irData}
                          index={index}
                          onSelect={onIrSelect}
                          select={irSelect}
                          key={index}
                          name={"irimagePaths"}
                          idName={"id"}
                          storeTimeZone={exitTask?.storeTimeZone}
                        />
                      </div>
                    );
                  })
                ) : (
                  <div>No Recommendations</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="w-[25.5%]">
          <div className="cell_border_black h-full">
            <div className="w-full mt-5">
              <p>
                <span className="font-bold mt-1">Final Review</span>
              </p>
              <hr className="hr_style" />
              <p>
                <span className="font-bold mt-1">Exit Decision</span>
              </p>
              <br />
              <table className="w-full h-auto cursor-pointer ml-8">
                <tbody>
                  <tr>
                    <td className="text-left">
                      <span className="font-bold">Exit TimeStamp</span>
                    </td>
                    <td>: &nbsp;</td>
                    <td className="text-left">
                      {getTime(
                        exitActivity?.exitTime,
                        exitActivity?.storeTimeZone
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <span className="font-bold">Entry TimeStamp</span>
                    </td>
                    <td>: &nbsp;</td>
                    <td className="text-left">
                      {select?.entryTime
                        ? getTime(
                            select?.entryTime,
                            exitActivity?.storeTimeZone
                          )
                        : ""}
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              <textarea
                className="font-bold w-80 ml-2 focus:outline-none border-2"
                onChange={(e) => {
                  onChangeInput(e.target.value, "decisionComment");
                }}
                placeholder="Enter comments"
                maxLength={250}
              />
              <br />
              <br />
              <button
                className={
                  "bg-green-400 w-80 rounded-full font-bold h-8 focus:outline-none text-white mb-5"
                }
                onClick={() => onApprove()}
              >
                Approve Task
              </button>
              <button
                className={
                  "bg-red-400 w-80 rounded-full font-bold h-8 focus:outline-none text-white mb-5"
                }
                onClick={() => onReject()}
              >
                Reject Task
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex rounded-t-xl pt-2 bg-white">
        {loading ? (
          <h1>Loading...</h1>
        ) : (
          <div className="flex w-full">
            <div className="w-full ml-2 mb-2 cell_border_black">
              <div className="mt-3 w-full ml-2 flex items-center justify-center">
                <p className="font-bold">Suggested Entries</p>
              </div>
              <hr className="hr_style" />
              <div className="h-[45vh] mt-3 ml-2 mr-2 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
                {entryList && entryList?.length > 0 ? (
                  entryList?.map((entryData, index) => {
                    return (
                      <div
                        key={`entry-${index}`}
                        className="border-b-4 border-gray-400 space-y-2 h-55 flex flex-col justify-between"
                      >
                        <SelectEvent
                          data={entryData}
                          index={index}
                          onSelect={onSelect}
                          select={select}
                          key={index}
                          name={"userActivityImagePaths"}
                          idName={"userActivityId"}
                          isRecommended={entryData?.isShopperLoopRecommended}
                          showImage={true}
                          timeZone={exitActivity?.storeTimeZone}
                        />
                        {/* <table className="w-full h-auto text-sm cursor-pointer ml-6">
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="text-left">
                                                                            <span className="font-bold">Entry Time</span>
                                                                        </td>
                                                                        <td>: &nbsp;</td>
                                                                        <td className="text-left">
                                                                            {getTime(entryData?.entryTime, exitActivity?.storeTimeZone)}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="text-left">
                                                                            <span className="font-bold">Confidence Score</span>
                                                                        </td>
                                                                        <td>: &nbsp;</td>
                                                                        <td className="text-left">
                                                                            {entryData?.confidenceScore > 0 ? `${entryData?.confidenceScore}%` : '-'}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table> */}
                      </div>
                    );
                  })
                ) : (
                  <div>No Recommendations</div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ExitDecision;
