import React from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";

const CustomSearch = (props) => {
  const {
    placeholder,
    onChange,
    name,
    value,
    onKeyDown,
    searchIconProps,
    inputProps,
    inputPropsStyle,
    handleClear,
    isClearIcon,
  } = props;

  return (
    <TextField
      name={name && name}
      placeholder={placeholder && placeholder}
      variant="outlined"
      size="small"
      value={value && value}
      onChange={onChange}
      onKeyDown={onKeyDown && onKeyDown}
      InputProps={{
        endAdornment: (
          <InputAdornment sx={{ borderRadius: "10px" }} position="end">
            {value && handleClear && isClearIcon ? (
              <IconButton onClick={handleClear}>
                <ClearIcon />
              </IconButton>
            ) : (
              <IconButton>
                <SearchIcon />
              </IconButton>
            )}
          </InputAdornment>
        ),
        style: inputPropsStyle && inputPropsStyle,
      }}
      {...props}
    />
  );
};

export default CustomSearch;
