import axios from "axios";
import { useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { removeEmptyStringKeys } from "../utils/utils";

export const useQueryGet = (url, getName, isAPIcall = true, params = {}) => {
  const history = useHistory();

  const [isApiLoading, setIsApiLoading] = useState(false);

  const { isLoading, error, data } = useQuery(
    getName,
    isAPIcall &&
      (async () => {
        setIsApiLoading(true);
        try {
          const res = await axios({
            method: "GET",
            url: url,
            params: removeEmptyStringKeys(params),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("user-info")}`,
            },
          });
          setIsApiLoading(false);
          return res;
        } catch (error) {
          setIsApiLoading(false);
          if (error?.response?.status === 401) {
            history.push("/login");
          } else {
            toast.error(error?.response?.data?.title);
          }
        }
      }),
    { cacheTime: Infinity, staleTime: 0 }
  );

  return { isLoading, error, data, isApiLoading };
};
