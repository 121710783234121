import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TextField2 } from "../../../../../../validation/TextField2";
import { useHistory } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import countryList from "react-select-country-list";
import Select from "react-select";
import { useAlert } from "react-alert";
import { usePost } from "../../../../../../../api/usePost";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Toggle from "react-toggle";

let validate;
validate = Yup.object({
  name: Yup.string().required("Required"),
  internalCode: Yup.string(),
  locationRef: Yup.string().required("Required"),
  maxShelves: Yup.number().typeError("Not a number").required("Required"),
});

const options = [
  { value: "INSTALLED", label: "INSTALLED" },
  { value: "LIVE", label: "LIVE" },
  // { value: 'DAMAGED', label: 'DAMAGED' }
];

const customStyles = {
  control: (base) => ({
    ...base,
    height: 55,
    minHeight: 35,
  }),
};

function CreateMerchant() {
  const [title, setTitle] = useState("Rack");
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();

  const [status, setStatus] = useState(null);

  const [orgId, setOrgId] = useState("");
  const [org, setOrg] = useState(null);

  useEffect(async () => {
    const res = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}/orgs?page=0&sort=createdDate,desc`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
    });
    setOrg(res?.data);
  }, []);

  const [store, setStore] = useState(null);
  const [storeId, setStoreId] = useState(null);

  useEffect(async () => {
    if (orgId) {
      const res = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/retail-stores?page=0&sort=createdDate,desc&rootOrgId.equals=${orgId?.value}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      });
      setStore(res?.data);
    }
  }, [orgId]);

  const changeHandlerOrg = (value) => {
    setOrgId(value);
  };

  const changeHandlerStaus = (value) => {
    setStatus(value);
  };

  const changeRetailStore = (value) => {
    setStoreId(value);
  };

  const createRack = usePost(
    `${process.env.REACT_APP_BASE_URL}/racks`,
    "rackList"
  );

  return (
    <>
      <div className="w-full h-screen flex">
        <div className="w-full h-full flex justify-center items-center bg-gray-200 p-12 ">
          <Formik
            initialValues={{
              name: "",
              internalCode: "",
              maxShelves: "",
              locationRef: "",
            }}
            validationSchema={validate}
            onSubmit={async (values) => {
              console.log(values);
              if (values.name !== "" && values.internalCode !== "") {
                try {
                  const data = {
                    name: values?.name,
                    status: status?.value,
                    internalCode: values?.internalCode,
                    locationRef: values?.locationRef.toUpperCase(),
                    maxShelves: values?.maxShelves,
                    rootOrgId: orgId?.value,
                    retailStore: {
                      id: storeId?.value,
                    },
                  };
                  createRack.mutate(data);

                  if (createRack.isError === false) {
                    history.goBack();
                    alert.success(
                      <div style={{ textTransform: "none" }}>
                        Successfully Added!
                      </div>
                    );
                  } else {
                    alert.error(
                      <div style={{ textTransform: "none" }}>
                        Please Check all Fields!
                      </div>
                    );
                  }
                } catch (error) {
                  alert.error(
                    <div style={{ textTransform: "none" }}>
                      you are offline!
                    </div>
                  );
                }
              }
            }}
          >
            {(values) => (
              <Form className="w-11/12 mx-auto h-auto bg-white rounded-lg shadow-lg text-left ">
                {console.log("🚀 ~ CreateMerchant ~ values:", values?.values)}
                <div className="w-full h-16 flex rounded-t-lg bg-nav text-white">
                  <div className="w-10 p-2 h-full flex items-center">
                    <BiArrowBack
                      onClick={() => history.goBack()}
                      className="h-10 w-10 cursor-pointer"
                    />
                  </div>
                  <div className="w-full h-full px-7 py-3 flex flex-col justify-center">
                    <h2 className="font-bold text-2xl">
                      {`Create New ${title}`}
                    </h2>
                  </div>
                </div>

                <div className="w-11/12 mx-auto  px-6 my-2">
                  <div class="grid h-96 lg:h-48 grid-cols-1 lg:grid-cols-3 gap-x-12 pt-2 lg:pt-8 ">
                    <div>
                      <div className="flex items-center h-10 my-2">
                        <TextField2 label="Rack Name" name="name" type="text" />
                      </div>
                    </div>
                    <div>
                      <div className="flex items-center h-10 my-2">
                        <TextField2
                          label="Rack ID"
                          name="internalCode"
                          type="text"
                        />
                      </div>
                    </div>

                    <div>
                      <div className=" flex items-center h-10 my-2">
                        <TextField2
                          label="Max Shelves"
                          name="maxShelves"
                          type="number"
                        />
                      </div>
                    </div>

                    <div>
                      <div className=" flex items-center h-10 my-2">
                        <TextField2
                          label="Location ID"
                          name="locationRef"
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="flex  w-full select-none">
                      <div style={{ width: "400px" }}>
                        <Select
                          placeholder="Select Merchant"
                          maxMenuHeight={162}
                          value={orgId}
                          onChange={changeHandlerOrg}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          styles={customStyles}
                          options={org?.map((item, index) => {
                            return {
                              label: item.name,
                              value: item.id,
                              key: index,
                            };
                          })}
                        />
                      </div>
                    </div>

                    <div className="flex  w-full select-none">
                      <div style={{ width: "400px" }}>
                        <Select
                          placeholder="Retail Store"
                          maxMenuHeight={162}
                          value={storeId}
                          onChange={changeRetailStore}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          styles={customStyles}
                          options={store?.map((item, index) => {
                            return {
                              label: item?.name,
                              value: item?.id,
                              key: index,
                            };
                          })}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-between">
                    <div className="flex  w-full select-none">
                      <div style={{ width: "245px" }}>
                        <Select
                          placeholder="Select Status"
                          maxMenuHeight={162}
                          value={status}
                          isSearchable={false}
                          onChange={changeHandlerStaus}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          // styles={customStyles}
                          options={options}
                        />
                      </div>
                    </div>

                    <div className="w-full h-16  flex justify-end items-center px-0 -mt-16 lg:-mt-2 select-none">
                      <button
                        type="submit"
                        className="bg-green-500 rounded-lg px-4 py-2  focus:outline-none text-white"
                      >
                        <span className="h-full flex items-center">
                          <svg
                            class="svg-icon"
                            viewBox="0 0 20 20"
                            width="24px"
                            height="24px"
                          >
                            <path
                              fill="#8A2BE2"
                              d="M19.404,6.65l-5.998-5.996c-0.292-0.292-0.765-0.292-1.056,0l-2.22,2.22l-8.311,8.313l-0.003,0.001v0.003l-0.161,0.161c-0.114,0.112-0.187,0.258-0.21,0.417l-1.059,7.051c-0.035,0.233,0.044,0.47,0.21,0.639c0.143,0.14,0.333,0.219,0.528,0.219c0.038,0,0.073-0.003,0.111-0.009l7.054-1.055c0.158-0.025,0.306-0.098,0.417-0.211l8.478-8.476l2.22-2.22C19.695,7.414,19.695,6.941,19.404,6.65z M8.341,16.656l-0.989-0.99l7.258-7.258l0.989,0.99L8.341,16.656z M2.332,15.919l0.411-2.748l4.143,4.143l-2.748,0.41L2.332,15.919z M13.554,7.351L6.296,14.61l-0.849-0.848l7.259-7.258l0.423,0.424L13.554,7.351zM10.658,4.457l0.992,0.99l-7.259,7.258L3.4,11.715L10.658,4.457z M16.656,8.342l-1.517-1.517V6.823h-0.003l-0.951-0.951l-2.471-2.471l1.164-1.164l4.942,4.94L16.656,8.342z"
                            ></path>
                          </svg>
                          <span className="px-2">Create {title}</span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default CreateMerchant;
