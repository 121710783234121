import React, { useState, useMemo, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import Select from "react-select";
import { usePut } from "../../../../../../../../api/usePut";
import { useGet } from "../../../../../../../../api/useGet";
import { TextField2 } from "../../../../../../../validation/TextField2";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ClipLoader from "react-spinners/ClipLoader";
import FadeLoader from "react-spinners/FadeLoader";
import Datepicker from "../../../../../../../../components/MobileDatePicker";
import TimePicker from "../../../../../../../../components/MobileTimePicker";
import moment from "moment";
import dayjs from "dayjs";

function DynamicPriceEdit() {
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 55,
      minHeight: 35,
    }),
  };

  const { t } = useTranslation();

  const history = useHistory();
  const {
    state: { data },
  } = useLocation();

  const [loading, setLoading] = useState(false);

  const campaignTypeOptions = [
    { value: "SURGE", label: "Surge" },
    { value: "DISCOUNT", label: "Discount" },
  ];

  const applicableDaysOptions = [
    { label: "SUNDAY", value: "SUNDAY" },
    { label: "MONDAY", value: "MONDAY" },
    { label: "TUESDAY", value: "TUESDAY" },
    { label: "WEDNESDAY", value: "WEDNESDAY" },
    { label: "THURSDAY", value: "THURSDAY" },
    { label: "FRIDAY", value: "FRIDAY" },
    { label: "SATURDAY", value: "SATURDAY" },
  ];

  const [select, setSelect] = useState({
    campaignType: {
      label: "",
      value: "",
    },
  });

  const [input, setInput] = useState({
    startDate: null,
    endDate: null,
    startTime1: null,
    endTime1: null,
    startTime2: null,
    endTime2: null,
  });

  const { mutate: updatePriceEngineConfiguration, isSuccess } = usePut(
    `${process.env.REACT_APP_BASE_URL}/price-engine-configurations/${data?.id}`,
    "dynamicPriceList"
  );

  const { data: initialValues } = useGet(
    `${process.env.REACT_APP_BASE_URL}/price-engine-configurations/${data.id}`,
    "dynamicPriceDetail"
  );

  const [initialData, setInitialData] = useState();
  const [status, setStatus] = useState(false);

  const [applicableDays, setApplicableDays] = useState([]);

  const findLabel = (arr, value) => {
    return arr.find((ele) => ele.value === value).label;
  };

  useEffect(() => {
    if (initialValues) {
      setInitialData({
        barCode: initialValues.barCode,
        startDate: initialValues.startDate,
        endDate: initialValues.endDate,
        campaignName: initialValues.campaignName,
        discountPercent: initialValues.discountPercent,
      });

      setSelect({
        campaignType: {
          value: initialValues?.campaignType
            ? initialValues?.campaignType
            : "SURGE",
          label: initialValues?.campaignType
            ? findLabel(campaignTypeOptions, initialValues?.campaignType)
            : "SURGE",
        },
      });

      const priceEngineSchedule1 =
        initialValues?.priceEngineSchedules?.length > 0
          ? initialValues?.priceEngineSchedules[0]
          : null;
      const priceEngineSchedule2 =
        initialValues?.priceEngineSchedules?.length > 1
          ? initialValues?.priceEngineSchedules[1]
          : null;

      const startTime1Arr = priceEngineSchedule1?.startTime?.split(":");
      const endTime1Arr = priceEngineSchedule1?.endTime?.split(":");
      const startTime2Arr = priceEngineSchedule2?.startTime?.split(":");
      const endTime2Arr = priceEngineSchedule2?.endTime?.split(":");
      setInput({
        startDate: initialValues.startDate,
        endDate: initialValues.endDate,
        startTime1:
          startTime1Arr && startTime1Arr.length > 0
            ? dayjs()
                .set("hour", startTime1Arr[0])
                .set("minute", startTime1Arr[1])
            : "",
        endTime1:
          endTime1Arr && endTime1Arr.length > 0
            ? dayjs().set("hour", endTime1Arr[0]).set("minute", endTime1Arr[1])
            : "",
        startTime2:
          startTime2Arr && startTime2Arr.length > 0
            ? dayjs()
                .set("hour", startTime2Arr[0])
                .set("minute", startTime2Arr[1])
            : null,
        endTime2:
          endTime2Arr && endTime2Arr.length > 0
            ? dayjs().set("hour", endTime2Arr[0]).set("minute", endTime2Arr[1])
            : null,
      });

      setApplicableDays(priceEngineSchedule1?.applicableDays);
    }
  }, [initialValues]);

  const handleSelect = (action, e) => {
    const name = e.name;
    setSelect({
      ...select,
      [name]: { value: action.value, label: action.label },
    });
  };

  const onChangeDate = (value, name) => {
    setInput({ ...input, [name]: value });
  };

  const onChangeTime = (value, name) => {
    setInput({ ...input, [name]: value });
  };

  const handleCheck = (event) => {
    var updatedList = [...applicableDays];
    if (event.target.checked) {
      updatedList = [...applicableDays, event.target.value];
    } else {
      updatedList.splice(applicableDays.indexOf(event.target.value), 1);
    }
    setApplicableDays(updatedList);
  };

  useEffect(() => {
    if (isSuccess) {
      setLoading(true);
      setTimeout(() => {
        history.goBack();
        setLoading(false);
      }, 2000);
    }
  }, [isSuccess]);

  if (loading) {
    return (
      <div className="h-full w-full flex justify-center items-center">
        <FadeLoader color="#000000" size={250} />
      </div>
    );
  }

  return (
    <>
      {initialData ? (
        <div className="w-full h-screen text-left select-none flex">
          <div className="w-full h-full flex justify-center items-center  p-4 bg-gray-200 ">
            <Formik
              initialValues={initialData}
              onSubmit={async (values) => {
                var startDate = moment(input?.startDate).format("yyyy-MM-DD");
                var endDate = moment(input?.endDate).format("yyyy-MM-DD");

                var priceEngineSchedule1 =
                  data?.priceEngineSchedules.length > 0
                    ? data?.priceEngineSchedules[0]
                    : null;
                var priceEngineSchedule2 =
                  data?.priceEngineSchedules.length > 1
                    ? data?.priceEngineSchedules[1]
                    : null;
                if (input.startTime1 !== null) {
                  const startTime = dayjs(input.startTime1).format("HH:mm");
                  const endTime = dayjs(input?.endTime1).format("HH:mm");
                  priceEngineSchedule1 = {
                    ...priceEngineSchedule1,
                    startTime,
                    endTime,
                    applicableDays,
                  };
                }

                if (input.startTime2 !== null) {
                  const startTime = dayjs(input?.startTime2).format("HH:mm");
                  const endTime = dayjs(input?.endTime2).format("HH:mm");
                  priceEngineSchedule2 = {
                    ...priceEngineSchedule2,
                    startTime,
                    endTime,
                    applicableDays,
                  };
                }
                var schedules = [];
                if (priceEngineSchedule1 !== null) {
                  schedules.push(priceEngineSchedule1);
                }
                if (priceEngineSchedule2 !== null) {
                  schedules.push(priceEngineSchedule2);
                }

                var body = {
                  id: data?.id,
                  campaignName: values.campaignName,
                  status: true,
                  barCode: values.barCode,
                  campaignType: select["campaignType"]["value"],
                  discountPercent: values.discountPercent,
                  startDate,
                  endDate,
                  priceEngineSchedules: schedules,
                  rootOrgId: parseInt(
                    JSON.parse(localStorage.getItem("dashboard-data"))?.id
                  ),
                };

                await updatePriceEngineConfiguration(body);
              }}
            >
              {({ handleSubmit }) => (
                <Form className="w-full mx-auto h-auto bg-white rounded-lg shadow-lg ">
                  <div className="w-full h-12 flex bg-dash rounded-t-lg text-white">
                    <div className="w-10 p-2 h-full flex items-center">
                      <BiArrowBack
                        onClick={() => history.goBack()}
                        className="h-10 w-10 cursor-pointer"
                      />
                    </div>
                    <div className="w-full h-full px-7 flex flex-col justify-center">
                      <h2 className="font-bold text-2xl">
                        {`${t("Edit")} ${t("dynamicPrice")}`}
                      </h2>
                    </div>
                  </div>
                  <div className="w-full h-[83.5vh] scrollbar-thin scrollbar-thumb-gray-100 scrollbar-track-gray-300 ">
                    <div className="flex ">
                      <div className="w-full px-12 py-5">
                        <div className=" grid grid-cols-2 gap-x-4 gap-y-6 mt-1">
                          <div className="flex w-full items-center select-none">
                            <TextField2
                              label={"Campaign Name"}
                              name="campaignName"
                              type="text"
                            />
                          </div>
                          <Select
                            placeholder="Campaign Type"
                            maxMenuHeight={162}
                            value={select.campaignType}
                            name="campaignType"
                            onChange={handleSelect}
                            menuPosition={"fixed"}
                            styles={customStyles}
                            options={campaignTypeOptions}
                          />
                        </div>
                        <div className="grid grid-cols-2 gap-x-4 gap-y-4 mt-3">
                          <div className="flex w-full items-center select-none">
                            <TextField2
                              label={"BarCode"}
                              name="barCode"
                              type="text"
                            />
                          </div>
                          <div className="flex w-full items-center select-none">
                            <TextField2
                              label={"Percentage"}
                              name="discountPercent"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-x-4 gap-y-4 mt-3">
                          <div className="flex w-full items-center select-none">
                            <Datepicker
                              value={input?.startDate}
                              clearIcon={false}
                              onChangeTime={onChangeDate}
                              name="startDate"
                              label={"Start Date"}
                            />
                          </div>
                          <div className="flex w-full items-center select-none">
                            <Datepicker
                              value={input?.endDate}
                              clearIcon={false}
                              onChangeTime={onChangeDate}
                              name="endDate"
                              label={"End Date"}
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-x-4 gap-y-4 mt-3">
                          <div className="flex w-full items-center select-none">
                            <TimePicker
                              value={input?.startTime1}
                              clearIcon={false}
                              onChangeTime={onChangeTime}
                              name={"startTime1"}
                              label={"Schedule 1 Start Time"}
                            />
                          </div>
                          <div className="flex w-full items-center select-none">
                            <TimePicker
                              value={input?.endTime1}
                              clearIcon={false}
                              onChangeTime={onChangeTime}
                              name={"endTime1"}
                              label={"Schedule 1 End Time"}
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-x-4 gap-y-4 mt-3">
                          <div className="flex w-full items-center select-none">
                            <TimePicker
                              value={input?.startTime2}
                              clearIcon={false}
                              onChangeTime={onChangeTime}
                              name={"startTime2"}
                              label={"Schedule 2 Start Time"}
                            />
                          </div>
                          <div className="flex w-full items-center select-none">
                            <TimePicker
                              value={input?.endTime2}
                              clearIcon={false}
                              onChangeTime={onChangeTime}
                              name={"endTime2"}
                              label={"Schedule 2 End Time"}
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-1 gap-x-4 mt-3">
                          Applicable Days
                        </div>
                        <div className="grid grid-cols-4 gap-x-4 mt-3">
                          {applicableDaysOptions.map((day, i) => (
                            <div key={`applicableDays-` + i}>
                              <input
                                type="checkbox"
                                onChange={handleCheck}
                                className="h-5 w-5"
                                name={day.label}
                                value={day.value}
                                checked={
                                  true
                                    ? applicableDays.includes(day.value)
                                    : false
                                }
                              />
                              <span className="mx-5 text-gray-500">
                                {day.label}
                              </span>
                            </div>
                          ))}
                        </div>
                        <div className="grid grid-cols-1 gap-x-4 mt-6 ">
                          <div className="w-full flex items-center justify-center">
                            <button
                              type="button"
                              onClick={handleSubmit}
                              className="bg-green-500 rounded-lg px-4 py-2  focus:outline-none text-white"
                            >
                              <span className="h-full flex items-center">
                                <svg
                                  className="svg-icon"
                                  viewBox="0 0 20 20"
                                  width="24px"
                                  height="24px"
                                >
                                  <path
                                    fill="#8A2BE2"
                                    d="M19.404,6.65l-5.998-5.996c-0.292-0.292-0.765-0.292-1.056,0l-2.22,2.22l-8.311,8.313l-0.003,0.001v0.003l-0.161,0.161c-0.114,0.112-0.187,0.258-0.21,0.417l-1.059,7.051c-0.035,0.233,0.044,0.47,0.21,0.639c0.143,0.14,0.333,0.219,0.528,0.219c0.038,0,0.073-0.003,0.111-0.009l7.054-1.055c0.158-0.025,0.306-0.098,0.417-0.211l8.478-8.476l2.22-2.22C19.695,7.414,19.695,6.941,19.404,6.65z M8.341,16.656l-0.989-0.99l7.258-7.258l0.989,0.99L8.341,16.656z M2.332,15.919l0.411-2.748l4.143,4.143l-2.748,0.41L2.332,15.919z M13.554,7.351L6.296,14.61l-0.849-0.848l7.259-7.258l0.423,0.424L13.554,7.351zM10.658,4.457l0.992,0.99l-7.259,7.258L3.4,11.715L10.658,4.457z M16.656,8.342l-1.517-1.517V6.823h-0.003l-0.951-0.951l-2.471-2.471l1.164-1.164l4.942,4.94L16.656,8.342z"
                                  ></path>
                                </svg>
                                <span className="px-4 select-none">
                                  {/* {`${t("update")}`} */}
                                  Update
                                </span>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      ) : (
        <div className="w-full h-full flex justify-center items-center">
          <ClipLoader size={150} />
        </div>
      )}
    </>
  );
}

export default DynamicPriceEdit;
