import React, { useEffect, useState } from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import { useLocation } from "react-router-dom";

import CommonHeader from "../header/NotificatonHeader";
import { usePost } from "../../../../../../../api/usePost";
import { usePut } from "../../../../../../../api/usePut";
import { toast } from "react-toastify";

const CreateTemplate = () => {
  const { state } = useLocation();

  const { rootOrgId, name: merchantName } =
    JSON.parse(localStorage.getItem("dashboard-data") || "") || {};

  const [formValues, setFormValues] = useState({
    templateName: "",
    placeholder: "",
    toListDE: "",
    ccListDE: "",
    subjectDE: "",
    bodyDE: "",
    toListEN: "",
    ccListEN: "",
    subjectEN: "",
    bodyEN: "",
    notificationType: "",
    merchant: rootOrgId,
    smsBodyEn: "",
    smsBodyDe: "",
    smsToEn: "",
    smsToDe: "",
  });

  console.log("🚀 ~ CreateTemplate ~ formValues:", formValues);
  useEffect(() => {
    if (state?.data) {
      const { id, notificationType, params, rootOrgId, template } =
        state?.data || {};
      console.log("🚀 ~ useEffect ~ params:", params);
      const {
        emailBodyDe,
        emailCcEn,
        emailSubjectDe,
        emailToDe,
        emailBodyEn,
        emailCcDe,
        emailSubjectEn,
        emailToEn,
        smsBodyEn,
        smsBodyDe,
        smsToEn,
        smsToDe,
        placeHolders,
      } = params || {};
      setFormValues({
        templateName: template,
        placeholder: placeHolders,
        toListDE: emailToDe,
        ccListDE: emailCcEn,
        subjectDE: emailSubjectDe,
        bodyDE: emailBodyDe,
        toListEN: emailToEn,
        ccListEN: emailCcDe,
        subjectEN: emailSubjectEn,
        bodyEN: emailBodyEn,
        notificationType: notificationType,
        merchant: rootOrgId,
        smsBodyEn: smsBodyEn,
        smsBodyDe: smsBodyDe,
        smsToEn: smsToEn,
        smsToDe: smsToDe,
      });
      console.log("state", state?.data);
    }
  }, [state]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const createNotificationTemplate = usePost(
    `${process.env.REACT_APP_BASE_STORE_MANAGEMENT_URL}/notification-service/api/configuration`,
    "createNotificationTemplate"
  );

  console.log("createNotificationTemplate", createNotificationTemplate);

  const updateNotificationTemplate = usePut(
    `${process.env.REACT_APP_BASE_STORE_MANAGEMENT_URL}/notification-service/api/configuration/${state?.data?.id}`,
    "createNotificationTemplate"
  );

  useEffect(() => {
    if (createNotificationTemplate?.isSuccess) {
      toast.success("Template created successfully!!!");
      createNotificationTemplate?.reset();
    }
  }, [createNotificationTemplate]);

  useEffect(() => {
    if (updateNotificationTemplate?.isSuccess) {
      toast.success("Template updated successfully!!!");
      updateNotificationTemplate?.reset();
    }
  }, [updateNotificationTemplate]);

  const handleSubmit = (event) => {
    event.preventDefault();

    console.log(formValues);

    const {
      merchant,
      templateName,
      notificationType,
      placeholder,
      subjectEN,
      bodyEN,
      subjectDE,
      bodyDE,
      toListEN,
      ccListDE,
      toListDE,
      ccListEN,
      smsBodyEn,
      smsBodyDe,
      smsToEn,
      smsToDe,
    } = formValues;

    const tempData = {
      rootOrgId: state?.data?.rootOrgId ? state?.data?.rootOrgId : rootOrgId,
      template: templateName,
      params: {
        placeHolders: placeholder,
        emailSubjectEn: subjectEN,
        emailSubjectDe: subjectDE,
        emailBodyEn: bodyEN,
        emailBodyDe: bodyDE,
        emailToEn: toListEN,
        emailToDe: toListDE,
        emailCcEn: ccListDE,
        emailCcDe: ccListEN,
        smsBodyEn,
        smsBodyDe,
        smsToEn,
        smsToDe,
      },
    };

    if (state?.data) {
      updateNotificationTemplate.mutate({ ...tempData, id: state?.data?.id });
    } else {
      createNotificationTemplate.mutate(tempData);
    }
  };

  return (
    <div className="h-[100%]">
      <CommonHeader
        headerTitle={
          state?.data
            ? `Update Store Alert - ${merchantName}`
            : `Create Store Alert - ${merchantName}`
        }
        isCreateButton={false}
      />
      <form onSubmit={handleSubmit} className="h-[100%]">
        <div className="w-[90%] mx-auto mt-7">
          <Grid container spacing={2}>
            {/* <Grid item xs={12} sm={6}>
              <TextField
                required
                variant="outlined"
                id="merchant"
                name="merchant"
                label="Merchant"
                fullWidth
                disabled
                value={merchantName}
                onChange={handleChange}
              />
            </Grid> */}

            <Grid item xs={12} sm={6}>
              <TextField
                required
                variant="outlined"
                id="templateName"
                name="templateName"
                label="Template Name"
                fullWidth
                value={formValues.templateName}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                variant="outlined"
                id="templateName"
                name="placeholder"
                label="Placeholder"
                fullWidth
                value={formValues.placeholder}
                onChange={handleChange}
              />
            </Grid>

            {/* <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel variant="outlined" id="notificationType-label">
                  Notification Type
                </InputLabel>
                <Select
                  variant="outlined"
                  labelId="notificationType-label"
                  id="notificationType"
                  name="notificationType"
                  value={formValues.notificationType}
                  onChange={handleChange}
                >
                  <MenuItem value="sms">SMS</MenuItem>
                  <MenuItem value="email">E-MAIL </MenuItem>
                  <MenuItem value="sms & email">SMS & E-MAIL</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}

            {/* Repeat the structure below for each language version (DE and EN) */}
            <Grid item xs={12} sm={6}>
              <TextField
                id="toListDE"
                variant="outlined"
                name="toListDE"
                label="Mail To list (DE)"
                fullWidth
                value={formValues.toListDE}
                onChange={handleChange}
              />

              <TextField
                id="toListDE"
                variant="outlined"
                name="smsToDe"
                label="SMS To list (DE)"
                fullWidth
                style={{ marginTop: "16px" }}
                value={formValues.smsToDe}
                onChange={handleChange}
              />
              <TextField
                id="ccListDE"
                variant="outlined"
                name="ccListDE"
                label="CC list (DE)"
                fullWidth
                style={{ marginTop: "16px" }}
                value={formValues.ccListDE}
                onChange={handleChange}
              />
              <TextField
                id="subjectDE"
                variant="outlined"
                name="subjectDE"
                label="Subject (DE)"
                fullWidth
                style={{ marginTop: "16px" }}
                value={formValues.subjectDE}
                onChange={handleChange}
              />

              <TextField
                id="bodyDE"
                variant="outlined"
                name="bodyDE"
                label="Mail Body (DE)"
                fullWidth
                multiline
                rows={4}
                style={{ marginTop: "16px" }}
                value={formValues.bodyDE}
                onChange={handleChange}
              />

              <TextField
                id="bodyDE"
                variant="outlined"
                name="smsBodyDe"
                label="SMS Body (DE)"
                fullWidth
                multiline
                rows={4}
                style={{ marginTop: "16px" }}
                value={formValues.smsBodyDe}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="toListEN"
                variant="outlined"
                name="toListEN"
                label="To list (EN)"
                fullWidth
                value={formValues.toListEN}
                onChange={handleChange}
              />

              <TextField
                id="toListEN"
                variant="outlined"
                name="smsToEn"
                label="SMS To list (EN)"
                fullWidth
                style={{ marginTop: "16px" }}
                value={formValues.smsToEn}
                onChange={handleChange}
              />
              <TextField
                id="ccListEN"
                variant="outlined"
                name="ccListEN"
                label="CC list (EN)"
                fullWidth
                style={{ marginTop: "16px" }}
                value={formValues.ccListEN}
                onChange={handleChange}
              />
              <TextField
                id="subjectEN"
                variant="outlined"
                name="subjectEN"
                label="Subject (EN)"
                fullWidth
                style={{ marginTop: "16px" }}
                value={formValues.subjectEN}
                onChange={handleChange}
              />

              <TextField
                id="bodyEN"
                variant="outlined"
                name="bodyEN"
                label="Mail Body (EN)"
                fullWidth
                multiline
                rows={4}
                style={{ marginTop: "16px" }}
                value={formValues.bodyEN}
                onChange={handleChange}
              />
              <TextField
                id="bodyEN"
                variant="outlined"
                name="smsBodyEn"
                label="SMS Body (EN)"
                fullWidth
                multiline
                rows={4}
                style={{ marginTop: "16px" }}
                value={formValues.smsBodyEn}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sx={{ mt: "auto" }}>
              <Button variant="contained" color="primary" type="submit">
                {state?.data ? "Update Template" : "Create Template"}
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
    </div>
  );
};

export default CreateTemplate;
