import React, { useState, useEffect } from "react";
import { BiImages, BiArrowBack } from "react-icons/bi";
import { useHistory, useLocation } from "react-router-dom";
import { useGet } from "../../../../api/useGet";
import OpenItem from "./OpenItem";
import moment from "moment";
import axios from "axios";
import ImageModal from "./ImageModal";
import NoImage from "../../../../Images/noImage.jpg";
import Pagination from "@mui/material/Pagination";
import { useQuery } from "react-query";
import FadeLoader from "react-spinners/FadeLoader";
import { getTime } from "../../../../logic/useGetTime";
import {toast} from 'react-toastify'

function ProcessedEvents() {
  const history = useHistory();
  const {
    state: { data: locationData },
  } = useLocation();
  const [loading, setLoading] = useState(true);
  const [shopper, setShopperUrl] = useState([]);

  const [allCount, setAllCount] = useState();
  const [input, setInput] = useState({
    scaleId: "",
    shelfId: "",
  });

  const { data: images, isLoading: imageLoading } = useGet(
    `${process.env.REACT_APP_STORE_URL}/user-activities?eventStatus.equals=PROCESSED&sort=entryTime,desc&cartRef.equals=${locationData?.sessionRef}`,
    "images"
  );

  const [activePage, setActivePage] = useState(1);
  const [cartSelected, setCartSelected] = useState("ProcessedCart");

  const findUrl = (name) => {
    switch (name) {
      case "ProcessedCart":
        return `${process.env.REACT_APP_STORE_URL}/store-carts?cartRef.equals=${
          locationData?.sessionRef
        }&storeCode.equals=${locationData?.storeCode}&page=${
          activePage - 1
        }&size=25&sort=eventTime,asc`;

      case "OpenCart":
        return `${
          process.env.REACT_APP_STORE_URL
        }/store-carts/open-events?cartRef.equals=${
          locationData?.sessionRef
        }&page=${activePage - 1}&size=25&storeCode.equals=${
          locationData?.storeCode
        }&sort=eventTime,asc`;

      default:
        return `${process.env.REACT_APP_STORE_URL}/store-carts?cartRef.equals=${
          locationData?.sessionRef
        }&storeCode.equals=${locationData?.storeCode}&page=${
          activePage - 1
        }&size=25&sort=eventTime,asc`;
    }
  };

  const fetchData = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: findUrl(cartSelected),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      });
      setAllCount(res?.headers?.["x-total-count"]);
      setLoading(false);
      return res.data;
    } catch ({message}) {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message)
      }
    }
    
  };

  const {
    data: openData,
    isLoading
  } = useQuery(
    ["openItemList", activePage, cartSelected],
    async () => fetchData(),
    {
      enabled: !!activePage || !!cartSelected,
      cacheTime: 0,
      staleTime: 0,
    }
  );

  const handlePageChange = (event, value) => {
    // setLoading(true)
    setActivePage(value);
  };

  const [filteredData, setFilteredData] = useState(openData);

  useEffect(() => {
    var urlCont = [];
    if (images?.length !== 0) {
      images?.map((img) => {
        urlCont.push(img?.userImagePaths[0]);
      });
    }
    setShopperUrl(urlCont);
  }, [images]);

  const onChangeInput = (value, name) => {
    if (name === "scaleId" && value !== "") {
      setInput({
        scaleId: value,
        shelfId: "",
      });

      setFilteredData(
        openData?.filter((item) => item?.scaleId === value.toString())
      );
    } else if (name === "shelfId" && value !== "") {
      setInput({
        scaleId: "",
        shelfId: value,
      });

      setFilteredData(
        openData?.filter((item) => item?.groupId === value.toString())
      );
    } else if (name === "shelfId" && name === "groupId") {
    } else {
      setInput({
        scaleId: "",
        shelfId: "",
      });
      setFilteredData(openData);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  if (loading)
    return (
      <div className="bg-black bg-opacity-80 absolute inset-0 flex items-center justify-center">
        <div className="w-full h-20 absolute z-10 text-white flex flex-col items-center justify-center">
          <FadeLoader
            color="#FFFFFF"
            className=""
            loading={loading}
            size={150}
          />
        </div>
      </div>
    );

  const tabSelection = (state) => {
    if (cartSelected !== state) {
      setCartSelected(state);
      setLoading(true);
    }
  };

  if (isLoading || imageLoading)
    return (
      <div className="bg-black bg-opacity-80 absolute inset-0 flex items-center justify-center">
        <div className="w-full h-20 absolute z-10 text-white flex flex-col items-center justify-center">
          <FadeLoader color="#FFFFFF" className="" loading={true} size={150} />
        </div>
      </div>
    );

  return (
    <div className="h-[calc(100vh)] w-full bg-gray-300">
      <div className="h-8 flex items-center">
        <div className="absolute mx-4 flex items-center">
          <BiArrowBack
            onClick={() => history.goBack()}
            className="h-6 w-6 cursor-pointer"
          />
        </div>

        <div className="w-full flex justify-center items-center">
          <h2 className="font-bold text-xl">Store Cart - Summary</h2>
        </div>
      </div>

      <div className="h-[calc(100%-33px)] w-full bg-white rounded-t-xl flex">
        <div className="w-[97%] flex flex-col">
          <div
            className={`w-full h-44 flex items-center py-2 mx-5 justify-between`}
          >
            <div className="w-[calc(100vw-(600px))] flex justify-start space-x-4">
              {shopper?.length > 0 ? (
                shopper?.map((img, index) => (
                  <ImageModal img={img} index={index} key={index} />
                ))
              ) : (
                <img
                  src={NoImage}
                  alt="NoImage"
                  className="w-40 h-40 rounded cursor-pointer"
                />
              )}
            </div>

            <div className="w-[300px] h-[135px] bg-gray-200 rounded-xl text-left px-4">
              <h2 className="font-bold text-lg text-left mb-1">Cart Summary</h2>

              <div className="flex items-center text-left ">
                <h2 className="w-32 font-bold">{`Cart Ref`}</h2>
                <h2 className="">{`: ${
                  images[0]?.cartRef ? images[0]?.cartRef : "-"
                }`}</h2>
              </div>

              <div className="flex items-center text-left">
                <h2 className="w-32 font-bold">Store Code</h2>
                <p className="">{`: ${
                  images[0]?.retailStoreCode ? images[0]?.retailStoreCode : "-"
                }`}</p>
              </div>

              <div className="flex items-center text-left ">
                <h2 className="w-32 font-bold">{`Mobile No`}</h2>
                <h2 className="">{`: ${
                  images[0]?.formattedPhone ? images[0]?.formattedPhone : "-"
                }`}</h2>
              </div>

              <div className="flex items-center text-left ">
                <h2 className="w-32 font-bold">{`# of shoppers`}</h2>
                <h2 className="">{`: ${
                  images[0]?.noOfGroupShoppers
                    ? images[0]?.noOfGroupShoppers
                    : "-"
                }`}</h2>
              </div>
            </div>
          </div>

          <div className="w-full flex justify-between items-center ml-5 mt-1">
            <div className="w-[70%] flex justify-start">
              <div className="pr-2  h-8 flex  justify-between items-center space-x-[5px]">
                <h2 className="font-bold text-[15px]"> Entry Time : </h2>
                {images[0]?.entryTime ? (
                  <p className="text-[15px] font-bold">
                    {getTime(images[0]?.entryTime, images[0]?.storeTimeZone)}
                  </p>
                ) : (
                  "-"
                )}
              </div>

              <div className="px-2 h-8 flex  justify-center items-center space-x-[5px]">
                <h2 className="font-bold text-[15px]"> Checkout Time : </h2>
                <p className="font-bold text-[15px]">
                  {getTime(images[0]?.exitTime, images[0]?.storeTimeZone)}
                </p>
              </div>
            </div>

            <div className="flex justify-end space-x-[20px]">
              {/* <div className="w-auto h-8 flex items-center space-x-[10px]">
                <input
                  placeholder="Search by Scale ID"
                  onChange={(e) => onChangeInput(e.target.value, "scaleId")}
                  value={input?.scaleId}
                  className="w-[180px] border focus:outline-none rounded-lg h-8 px-4 border-gray-500"
                />

                <input
                  placeholder="Search by Shelf ID"
                  onChange={(e) => onChangeInput(e.target.value, "shelfId")}
                  value={input?.shelfId}
                  className="w-[180px] border focus:outline-none rounded-lg h-8 px-4 border-gray-500"
                />
              </div> */}

              <div className="w-[220px] h-[30px] rounded-full flex">
                <button
                  onClick={() => tabSelection("OpenCart")}
                  className={`${
                    cartSelected === "OpenCart" ? "bg-green-500" : "bg-gray-200"
                  } ${
                    cartSelected === "OpenCart" ? "text-white" : "text-black"
                  } w-[120px] h-[30px] rounded-l-full focus:outline-none`}
                >
                  Not Assign
                </button>
                <button
                  onClick={() => tabSelection("ProcessedCart")}
                  className={`${
                    cartSelected === "ProcessedCart"
                      ? "bg-green-500"
                      : "bg-gray-200"
                  } ${
                    cartSelected === "ProcessedCart"
                      ? "text-white"
                      : "text-black"
                  } w-[120px] h-[30px] rounded-r-full`}
                >
                  Assigned
                </button>
              </div>
            </div>
          </div>

          <div
            className={`h-full mt-3  pr-4 w-full ml-5 space-y-3 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100`}
          >
            {openData?.length > 0 ? (
              <>
                {openData?.map((item, index) => (
                  <OpenItem
                    key={index}
                    item={item}
                    index={index}
                    tab="approved"
                    userActivity={images}
                  />
                ))}
              </>
            ) : (
              <div className="w-full h-full flex justify-center items-center ">
                No Rows To Show
              </div>
            )}
          </div>

          <div className=" w-full ml-5 pr-1 flex justify-between item-center my-1 items-center border border-gray-400 rounded-full">
            <Pagination
              count={
                allCount % 25 === 0
                  ? parseInt(allCount / 25)
                  : parseInt(allCount / 25) + 1
              }
              showFirstButton
              showLastButton
              page={activePage}
              onChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProcessedEvents;
