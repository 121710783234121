import React from 'react'
import Sidebar from "./components/Sidebar/Sidebar";
import * as s from "../../../App.styles";
import * as Palette from "../../../colors";
import MainView from './components/MainView/MainView'
import { Helmet } from "react-helmet";

function SupportDashboard() {

  const backgroundImage = "images/mountain.jpg";
  const sidebarHeader = {
    fullName: "Support Portal",
    shortName: "SP",
  };

  const menuItems = [
    { name: "Welcome", to: "/support", icon: "/icons/home.svg", subMenuItems: [] },
    { name: "Merchant", to: "/support/merchant", icon: "/icons/home.svg", subMenuItems: [] },
    { name: "Hawkeye", to: "/support/hawkeye", icon: "/icons/home.svg", subMenuItems: [] },
    { name: 'Shopper Activity', to:'/support/shopper-activity', icon: "/icons/home.svg", subMenuItems: []},
    { name: 'Decision Events', to: '/support', icon: "/icons/home.svg",
      subMenuItems: [
        { name: "Entry Decision", to: "/support/decision-events/entry-decision" },
        { name: "Product Decision", to: "/support/decision-events/product-decision" }
      ]},
    { name: "Shoppers", to: "/Support/shopper", icon: "/icons/home.svg", subMenuItems: [] },
    { name: "Orders", to: "/support/orders", icon: "/icons/home.svg", subMenuItems: [] },
    { name: "Change Password", to: "/support/password", icon: "/icons/home.svg", subMenuItems: [] } 
    
  ] 
  const fonts = {
    header: "ZCOOL KuaiLe",
    menu: "Poppins",
  };
  return (
    <s.App>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Support Dashboard`}</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
        <Sidebar
        backgroundImage={backgroundImage}
        sidebarHeader={sidebarHeader}
        menuItems={menuItems}
        fonts={fonts}
        colorPalette={Palette.swampy}
      />
     
      <MainView />
      
    </s.App>
  )
}

export default SupportDashboard
