import React from "react";
import All from "./options/All";
import TableStr from "./TableStr";
import ProcessedTasks from "./options/ProcessedTasks";

function ProductDecision() {
    return(
        <div className="w-full h-full">
            <TableStr
                title={"Product Decision Events"}
                url={"/support"}
                viewUrl={"/support/decision-events/product-decision/view"}
                listUrl={`${process.env.REACT_APP_STORE_URL}//cart-items`}
                getName={"productDecisionEvents"}
                All={All}
                dashboard={true}
                ProcessedProductEvents={ProcessedTasks}
                processedViewUrl={"/support/decision-events/product-decision/processed-view"}
            />
        </div>
    )
}

export default ProductDecision;
