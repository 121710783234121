import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Container } from "@mui/material";

const ChangePasswordCard = ({
  onChangeInput,
  submit,
  input,
  setInput,
  validations,
  setValidations,
}) => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const handleToggleCurrentPasswordVisibility = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const handleToggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  return (
    <>
      <Typography variant="h5" component="div">
        Change Password
      </Typography>
      <TextField
        label="Current Password"
        type={showCurrentPassword ? "text" : "password"}
        fullWidth
        value={input?.currentPassword}
        onChange={(e) => onChangeInput(e, "currentPassword")}
        margin="normal"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleToggleCurrentPasswordVisibility}>
                {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
          sx: { borderRadius: "12px" },
        }}
        sx={{ borderRadius: "12px" }}
        autoComplete="off" // Disable autofill
      />
      <TextField
        label="New Password"
        type={showNewPassword ? "text" : "password"}
        fullWidth
        value={input?.newPassword}
        onChange={(e) => onChangeInput(e, "newPassword")}
        margin="normal"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleToggleNewPasswordVisibility}>
                {showNewPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
          sx: { borderRadius: "12px" },
        }}
        sx={{ borderRadius: "12px" }}
        autoComplete="off" // Disable autofill
      />
      <Button
        variant="contained"
        color="primary"
        sx={{ marginY: 2, borderRadius: "12px" }}
        onClick={submit}
        fullWidth
      >
        Change Password
      </Button>
    </>
  );
};

export default ChangePasswordCard;
