import React, { useState, useEffect } from "react";
import { AiOutlineDropbox } from "react-icons/ai";
import { MdArrowUpward, MdArrowDownward } from "react-icons/md";
import { BiArrowBack } from "react-icons/bi";
import Select from "react-select";
import Pagination from "@mui/material/Pagination";
import { useHistory, Link } from "react-router-dom";
import Mapped from "./user-details/Mapped";
import CartView from "./user-details/CartView";
import { useGet } from "../../../../../../api/useGet";
import moment from "moment";
import HawkeyeThumbnail from "./HawkeyeThumbnail";
import { GrView } from "react-icons/gr";

const options = [
  { value: "Created", label: "Created" },
  { value: "In progress", label: "In progress" },
  { value: "Resolved", label: "Resolved" },
];

const users = [
  { value: "Akash", label: "Akash" },
  { value: "PD", label: "PD" },
];

function HawkeyeDashboard() {
  const history = useHistory();

  //const location = useLocation()

  const [map, setMap] = useState(false);
  const onOpenMap = () => setMap(true);
  const onCloseMap = () => setMap(false);

  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [checkbox, setCheckbox] = useState({
    vandalisation: false,
    wrongPlace: false,
    consumeStore: false,
  });

  const [input, setInput] = useState({
    qty: "2",
  });

  const [select, setSelect] = useState({
    user: "",
  });

  const onChangeInput = (e, name) => {
    const value = e.target.value;
    setInput({ ...input, [name]: value });
  };

  const onChangeCheckbox = (value, name) => {
    setCheckbox({
      ...checkbox,
      [name]: value,
    });
  };

  const changeHandler = (value, name) => {
    setSelect({ ...select, [name]: value });
  };

  const [selected, setSelected] = useState({
    value: "Created",
    label: "Created",
  });

  const onSelect = (value) => {
    setSelected(value);
  };

  const cart = useGet(
    `${
      process.env.REACT_APP_SHOPPER_URL
    }/cart-session-summary?sessionRef=${localStorage.getItem("sessionRef")}`,
    "Carts"
  );

  const [sessionCart, setSessionCart] = useState({});

  const [url, setUrl] = useState();

  useEffect(() => {
    if (cart?.data?.machineCartList[0]) {
      const data = cart?.data?.machineCartList[0];
      setSessionCart(data);
    }
  }, [cart?.data?.machineCartList[0]]);

  useEffect(() => {
    setUrl(
      `${process.env.REACT_APP_CAMERA_URL}${
        sessionCart?.machine?.deviceIdentifier
      }/sessions/${localStorage.getItem("sessionRef")}.mp4`
    );
  }, [sessionCart]);

  //console.log("sessionCart: ", sessionCart);
  //console.log("session-carts: ", session_cart?.data);

  const selectPage = (event, page) => {
    const data = cart?.data?.machineCartList[page - 1];
    // console.log("data: ", data);
    //setCurrentPage(page);
    setSessionCart(data);
  };

  const storeData = JSON.parse(localStorage.getItem("storeCart"));

  return (
    <div className="w-full h_screen  flex select-none">
      <div className="w-full h-full flex justify-center items-center bg-gray-100 p-4">
        <div className="w-7/10 h-full  ">
          <div className=" w-full h-full flex  bg-white rounded-lg mr-2 shadow-lg px-2 ">
            <div className="absolute w-16 h-10 px-3">
              <BiArrowBack
                onClick={() => history.goBack()}
                className="h-8 w-8 cursor-pointer px-1 mt-1 "
              />
            </div>

            <div className="w-4/5  mx-auto h-4/5 flex flex-col px-2 ">
              <div className="w-full mx-auto h-10 flex items-center  justify-center">
                <div className="w-full flex justify-between pt-2">
                  <Link
                    to="/support/hawkeye/monitoring/user"
                    className="text-blue-600 cursor-pointer"
                  >
                    <span className={"text-black"}>Shopper:</span>{" "}
                    {`${cart?.data?.user?.firstName} `}
                    {cart?.data?.user?.lastName}
                  </Link>
                  <div className="w-72 ">
                    <Pagination
                      count={cart?.data?.machineCartList.length}
                      showFirstButton
                      showLastButton
                      // page={currentPage}
                      onChange={selectPage}
                    />
                  </div>
                  <Link
                    onClick={() => onOpenMap()}
                    className="text-blue-600 cursor-pointer"
                  >
                    {sessionCart?.machine?.deviceIdentifier}
                  </Link>
                </div>
              </div>

              <div className="grid grid-flow-col grid-cols-2 grid-rows-2 gap-4 my-8">
                <div className="w-full rounded-lg border-2 border-gray-200 shadow-lg">
                  <HawkeyeThumbnail
                    imageUrl={`https://images.unsplash.com/photo-1528821128474-27f963b062bf?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80`}
                    videoUrl={
                      "https://www.youtube.com/watch?v=LesnixX76YY&t=16s"
                    }
                  />
                </div>
                <div className="w-full rounded-lg border-2 border-gray-200 shadow-lg">
                  <HawkeyeThumbnail
                    imageUrl={`https://images.unsplash.com/photo-1528821128474-27f963b062bf?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80`}
                    videoUrl={
                      "https://www.youtube.com/watch?v=LesnixX76YY&t=16s"
                    }
                  />
                </div>
                <div className="w-full rounded-lg border-2 border-gray-200 shadow-lg">
                  <HawkeyeThumbnail
                    imageUrl={`https://images.unsplash.com/photo-1528821128474-27f963b062bf?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80`}
                    videoUrl={
                      "https://www.youtube.com/watch?v=LesnixX76YY&t=16s"
                    }
                  />
                </div>
                <div className="w-full rounded-lg border-2 border-gray-200 shadow-lg">
                  <HawkeyeThumbnail
                    imageUrl={`https://images.unsplash.com/photo-1528821128474-27f963b062bf?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80`}
                    videoUrl={
                      "https://www.youtube.com/watch?v=LesnixX76YY&t=16s"
                    }
                  />
                </div>
              </div>
              <div>
                <div className="flex items-center h-6">
                  <input
                    type="checkbox"
                    name="vandalisation"
                    onChange={(e) =>
                      onChangeCheckbox(e.target.checked, "Vandalisation")
                    }
                    className="form-checkbox h-5 w-5 text-green-600"
                  />
                  <span className="mx-1 -mt-0.5 px-2 ">Vandalisation</span>
                </div>

                <div className="flex items-center h-6">
                  <input
                    type="checkbox"
                    name="consumeStore"
                    onChange={(e) =>
                      onChangeCheckbox(e.target.checked, "consumeStore")
                    }
                    className="form-checkbox h-5 w-5 text-green-600"
                  />
                  <span className="mx-1 -mt-0.5 px-2 ">
                    Product Consume within store
                  </span>
                </div>

                <div className="flex items-center h-6">
                  <input
                    type="checkbox"
                    name="wrongPlace"
                    onChange={(e) =>
                      onChangeCheckbox(e.target.checked, "wrongPlace")
                    }
                    className="form-checkbox h-5 w-5 text-green-600"
                  />
                  <span className="mx-1 -mt-0.5 px-2 ">
                    Product kept in a wrong place
                  </span>
                </div>
              </div>
            </div>
            <Mapped open={map} onCloseMap={onCloseMap} />
          </div>

          {/* <div className="w-full  h-20 bg-white mt-2 rounded-lg">
            <form className="w-full h-full flex items-center justify-between p-2">
              <input
                className="w-4/6 rounded-lg py-2 border-2 pl-2 border-black focus:outline-none"
                placeholder="Add Comment (optional)"
              />
              <div style={{ width: "150px" }}>
                <Select
                  maxMenuHeight={162}
                  onChange={onSelect}
                  value={selected}
                  options={options}
                  isSearchable={false}
                  menuPlacement="top"
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                />
              </div>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 rounded-lg focus:outline-none text-white "
              >
                submit
              </button>
            </form>
          </div> */}
        </div>

        <div className="w-3/10 h-full rounded-lg ml-2  scrollbar-thin px-2 scrollbar-thumb-gray-500 scrollbar-track-gray-200">
          <div class="w-full grid grid-flow-row  gap-2">
            {/* cart */}

            <div className="w-full h-auto pb-2 bg-white px-2 rounded-l-lg">
              <div className="w-full h-10 flex items-center justify-between ">
                <div className="w-full flex justify-between">
                  <div className="flex h-full items-center">
                    <h2 className=" text-md mr-1">Review</h2>
                    <GrView className="w-4 h-4" />
                  </div>
                  <h2>
                    User:{" "}
                    <span className="capitalize">
                      {localStorage.getItem("user-name")}
                    </span>
                  </h2>
                </div>

                {/* {cart?.data?.cart?.status === "PENDING" ? (
                  <div className="w-full flex justify-end ">
                    <Link
                      onClick={onOpenModal}
                      className="flex text-blue-600 cursor-pointer mr-2"
                    >
                      <BsEyeFill className="w-6 h-6 text-blue-400" />
                      <h2 className=" text-md ml-1">Review</h2>
                    </Link>

                  
                  </div>
                ) : 
                null} */}

                <CartView
                  data={sessionCart}
                  open={open}
                  onCloseModal={onCloseModal}
                />
              </div>

              <div className="w-full h-auto  border-t-2  border-gray-900 flex justify-between items-center">
                <div className="w-full h-full grid grid-cols-1 gap-2">
                  <div className="w-full h-full  py-2 select-none">
                    <table className="table-auto rounded border-collapse border  w-full h-full text-sm">
                      <tbody className="text-left">
                        <tr>
                          <th className="border border-gray-300 bg-gray-200 px-2">
                            Session Ref :
                          </th>
                          <th className="border border-gray-300 px-2 text-gray-500">
                            {storeData?.sessionRef}
                          </th>
                        </tr>
                        <tr>
                          <th className="w-24 border border-gray-300 bg-gray-200 px-2">
                            Entry Time :
                          </th>
                          <th className="border border-gray-300 px-2 text-gray-500">
                            {moment(storeData?.entryTime).format(
                              "MMMM Do YYYY, h:mm A"
                            )}
                          </th>
                        </tr>

                        <tr>
                          <th className="w-28 border border-gray-300 bg-gray-200 px-2">
                            Exit Time :
                          </th>
                          <th className="border border-gray-300 px-2 text-gray-500">
                            {moment(storeData?.checkoutDate).format(
                              "MMMM Do YYYY, h:mm A"
                            )}
                          </th>
                        </tr>
                        <tr>
                          <th className="w-24 border border-gray-300 bg-gray-200 px-2">
                            RetailerStore's name :
                          </th>
                          <th className="border border-gray-300 px-2 text-gray-500">
                            {cart?.data?.retailStore?.name}
                          </th>
                        </tr>
                        <tr>
                          <th className="w-24 border border-gray-300 bg-gray-200 px-2">
                            RetailerStore's Address :
                          </th>
                          <th className="border border-gray-300 px-2 text-gray-500">
                            {`

                            ${
                              cart?.data?.retailStore?.address?.street1
                                ? `${cart?.data?.retailStore?.address?.street1},`
                                : ""
                            }
                            ${
                              cart?.data?.retailStore?.address?.street2
                                ? `${cart?.data?.retailStore?.address?.street2},`
                                : ""
                            }
                            ${
                              cart?.data?.retailStore?.address?.cityOrVillage
                                ? `${cart?.data?.retailStore?.address?.cityOrVillage},`
                                : ""
                            }
                            ${
                              cart?.data?.retailStore?.address?.state
                                ? `${cart?.data?.retailStore?.address?.state},`
                                : ""
                            }
                            ${
                              cart?.data?.retailStore?.address?.country
                                ? `${cart?.data?.retailStore?.address?.country}-`
                                : ""
                            }
                            ${
                              cart?.data?.retailStore?.address?.zipCode
                                ? `${cart?.data?.retailStore?.address?.zipCode}`
                                : ""
                            }

                            `}
                          </th>
                        </tr>
                        <tr>
                          <th className="w-24 border border-gray-300 bg-gray-200 px-2">
                            Status :
                          </th>
                          <th className="border border-gray-300 px-2 text-gray-500">
                            {storeData?.status}
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* session cart */}

            <div className="w-full h-auto bg-white p-2 rounded-l-lg">
              <div className="w-full h-8 flex items-center text-left">
                <div className="w-full flex justify-between">
                  <h2 className=" text-md ">Cart Item</h2>
                </div>

                {/* <div className="w-full flex justify-end ">
                  <div className="flex cursor-pointer mr-2">
                    <BsEyeFill className="w-6 h-6 text-blue-400" />
                    <h2 className=" text-md ml-1">Review</h2>
                  </div>

                  <div className="flex cursor-pointer">
                    <FiEdit className="w-5 h-5 text-blue-400" />
                    <h2 className=" text-md mx-0.5">Edit</h2>
                  </div>
                </div> */}
              </div>

              {/* sessionCart */}
              <div className="grid grid-flow-col grid-cols-5 grid-rows-1 gap-x-2 border-t-2 border-gray-900 pt-1">
                <div>
                  <h2>Product</h2>
                </div>
                <div>
                  <h2>Action</h2>
                </div>
                <div>
                  <h2>Qty</h2>
                </div>
                <div className="w-full flex justify-center">
                  <AiOutlineDropbox className="w-5 h-5" />
                </div>
                <div>
                  <h2>Time</h2>
                </div>
              </div>

              {storeData?.storeCartItems?.map((item, index) => {
                return (
                  <div className="grid grid-flow-col grid-cols-5 grid-rows-1 gap-1 mt-2 ">
                    <div>
                      <h2>{`Milk`}</h2>
                    </div>

                    <div className="w-full hull flex justify-center items-center">
                      {item?.activityType === "PICK" ? (
                        <MdArrowUpward className="w-5 h-5 text-green-500 " />
                      ) : (
                        <MdArrowDownward className="w-5 h-5 text-red-500 " />
                      )}
                    </div>

                    <div className="w-full h-full flex  items-center">
                      <input
                        name="qty"
                        placeholder={item?.reviewedQuantity}
                        value={input?.qty}
                        onChange={(e) => onChangeInput(e, "qty")}
                        className="focus:outline-none border rounded-sm w-16 pl-6"
                      />
                    </div>

                    <div className="w-full h-full flex justify-center items-center">
                      <h2>{"SKU"}</h2>
                    </div>

                    <div className="w-full text-sm">
                      {moment(item?.transactionDate).format("h:mm A")}
                    </div>
                  </div>
                );
              })}
            </div>

            {/* order */}

            <div className="w-full h-auto bg-white p-2 rounded-lg">
              <h2 className="text-left ">Tagging Shopper</h2>

              <div className="grid grid-flow-col grid-cols-2 grid-rows-1 gap-x-2 border-t-2 border-gray-900 mt-1 py-2">
                <div className="w-full h-full flex items-center justify-start">
                  <h2>Shopper</h2>
                </div>

                <div className="w-full h-full flex items-center justify-end">
                  <div style={{ width: "150px" }}>
                    <Select
                      options={users}
                      placeholder="Name"
                      maxMenuHeight={162}
                      value={select?.user}
                      onChange={(value) => changeHandler(value, "user")}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                    />
                  </div>
                </div>
              </div>

              <div className="grid grid-flow-col grid-cols-2 grid-rows-1 gap-x-2  mt-8">
                <div className="w-full h-full flex items-center justify-start">
                  <button
                    onClick={() =>
                      history.push("/support/hawkeye/monitoring/review")
                    }
                    className="bg-green-600 py-1.5 px-5  rounded-full text-white focus:outline-none"
                  >
                    Update Cart
                  </button>
                </div>

                <div className="w-full h-full flex items-center justify-end">
                  <button className="bg-red-600 py-1.5 px-5 rounded-full text-white focus:outline-none">
                    Reject
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HawkeyeDashboard;
