import React, { useState, useEffect } from "react";
import TableStr from "./TableStr";
import MatchedExit from "./options/MatchedExit";
import OpenEntryEvents from "./options/OpenEntryEvents";
import OpenExitEvents from "./options/OpenExitEvents";
import Processed from "./options/Processed";

function ShopperActivity() {
  return (
    <div className="w-full h-full">
      <TableStr
        title={"Shopper Activity"}
        viewUrl={"/admin/shopper-activity/shopperEntryEvent"}  
        editUrl={"/admin/shopper-activity/exit"}
        listUrl={`${process.env.REACT_APP_STORE_URL}/user-activities`}
        getName={"shopperList"}
        OpenEntryEvents={OpenEntryEvents}
        MatchedExit={MatchedExit}
        Processed={Processed}
        OpenExitEvents={OpenExitEvents}      
      />
    </div>
  );
}

export default ShopperActivity;
