import React, { useState, useMemo } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TextField2 } from "../../../../../validation/TextField2";
import { useHistory } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import countryList from "react-select-country-list";
import Select from "react-select";
import { useAlert } from "react-alert";
import { usePost } from "../../../../../../api/usePost";
import { useLocation } from "react-router-dom";

const countryLists = [
  { label: "Germany", value: "DE" },
  { label: "Hungary", value: "HU" },
  { label: "France", value: "FR" },
];

function CreateMerchant() {
  const alert = useAlert();

  const history = useHistory();

  const location = useLocation();

  const options = useMemo(() => countryList().getData(), []);

  const [title, setTitle] = useState("Merchant");

  const [addressType, setAdressType] = useState();

  const [country, setCountry] = useState("");

  const { isCreateRetailerStore } = location?.state || {};

  const validate = Yup.object({
    name: Yup.string().required("Required"),
    email: Yup.string().email(),
    zip: Yup.number().typeError("Not a number").required("Required"),
    street1: Yup.string().required("Required"),
    street2: Yup.string(),
    city: Yup.string().required("Required"),
    internalCode: Yup.string().required(),
    administrativeArea: Yup.string(),
    latitude: Yup.number(),
    longitude: Yup.number(),
    state: Yup.string().required("Required"),
    contactFirstName: isCreateRetailerStore
      ? Yup.string().required("Required")
      : Yup.string(),
    contactLastName: Yup.string(),
    contactNo: Yup.number(),
    website: Yup.string(),
    country: Yup.string().required(),
    externalCode: Yup.string(),
    maxAllowedActiveShopping: Yup.string(),
    maxAllowedGroupShoppingUsers: Yup.string(),
  });

  const changeHandler = (value, setFieldValue, setErrors) => {
    setCountry(value);
    setFieldValue("country", value?.value);
    setErrors("country", "");
  };

  const createMerchant = usePost(
    `${process.env.REACT_APP_BASE_URL}/orgs`,
    "merchantList"
  );

  const retailStore = usePost(
    `${process.env.REACT_APP_BASE_URL}/retail-stores`,
    "retailStore"
  );

  return (
    <>
      <div className="w-full h-[100vh] flex">
        <div className="w-full h-full flex justify-center items-center bg-gray-200 p-12 ">
          <Formik
            initialValues={{
              name: "",
              email: "",
              website: "",
              street1: "",
              street2: "",
              city: "",
              administrativeArea: "",
              latitude: "",
              longitude: "",
              state: "",
              zip: "",
              internalCode: "",
              contactFirstName: "",
              contactLastName: "",
              contactNo: "",
              country: "",
              maxAllowedActiveShopping: "",
              maxAllowedGroupShoppingUsers: "",
              externalCode: "",
            }}
            validationSchema={validate}
            onSubmit={async (values) => {
              if (values.name !== "") {
                try {
                  if (isCreateRetailerStore) {
                    const rootOrgId = parseInt(
                      JSON.parse(localStorage.getItem("dashboard-data"))?.id
                    );
                    console.log("🚀 ~ onSubmit={ ~ rootOrgId:", rootOrgId);
                    const data = {
                      internalCode: values?.internalCode,
                      name: values?.name,
                      externalCode: values?.externalCode,
                      email: values?.email,
                      rootOrgId,
                      maxAllowedActiveShopping:
                        values?.maxAllowedActiveShopping,
                      maxAllowedGroupShoppingUsers:
                        values?.maxAllowedGroupShoppingUsers,
                      address: {
                        zipCode: values.zip,
                        street1: values.street1,
                        street2: values.street2,
                        cityOrVillage: values.city,
                        administrativeArea1: values.administrativeArea,
                        state: values.state,
                        country: country.value,
                        addressType: "PRIMARY",
                        contactFirstName: values.contactFirstName,
                        contactLastName: values.contactLastName,
                        contactNumber: values.contactNo,
                      },
                    };

                    retailStore.mutate(data);
                  } else {
                    const data = {
                      orgType: "RETAILER",
                      internalCode: values?.internalCode,
                      name: values.name,
                      email: values.email,
                      website: values?.website,
                      addresses: [
                        {
                          zipCode: values.zip,
                          street1: values.street1,
                          street2: values.street2,
                          cityOrVillage: values.city,
                          administrativeArea1: values.administrativeArea,
                          state: values.state,
                          country: country.value,
                          lat: parseFloat(values.latitude),
                          lng: parseFloat(values.longitude),
                          // addressType: addressType ? "PRIMARY" : "OTHER",
                          // contactPersonName: values.contactFirstName,
                          // contactPersonLastName: values.contactLastName,
                          contactPersonPhone: values.contactNo,
                        },
                      ],
                    };
                    createMerchant.mutate(data);
                    console.log(
                      "🚀 ~ onSubmit={ ~ createMerchant:",
                      createMerchant
                    );
                  }

                  if (createMerchant.isIdle === true) {
                    history.goBack();
                    alert.success(
                      <div style={{ textTransform: "none" }}>
                        Successfully Added!
                      </div>
                    );
                  }
                } catch (error) {
                  alert.error(
                    <div style={{ textTransform: "none" }}>
                      Something Went Wrong!!!
                    </div>
                  );
                }
              }
            }}
          >
            {({ errors, touched, setFieldValue, setErrors }) => (
              <Form className="w-11/12 mx-auto h-auto bg-white rounded-lg shadow-lg text-left">
                <div className="w-full h-16 flex rounded-t-lg bg-support text-white">
                  <div className="w-10 p-2 h-full flex items-center">
                    <BiArrowBack
                      onClick={() => history.goBack()}
                      className="h-10 w-10 cursor-pointer"
                    />
                  </div>
                  <div className="w-full h-full px-7 py-3 flex flex-col justify-center">
                    <h2 className="font-bold text-2xl">
                      {isCreateRetailerStore
                        ? "Create retail store"
                        : `Create new ${title}`}
                    </h2>
                  </div>
                </div>

                <div className="w-11/12 mx-auto px-6 mb-2">
                  <div className="w-full h-20 flex mt-4 justify-between items-center">
                    <div className="flex  px-4 mr-4 w-full items-center select-none">
                      <TextField2 label="Name" name="name" type="text" />
                    </div>

                    <div className="flex  px-4 ml-4 w-full items-center select-none">
                      <TextField2 label="Email" name="email" type="email" />
                    </div>

                    <div className="flex  px-4 ml-4 w-full items-center select-none">
                      <TextField2
                        label={
                          isCreateRetailerStore ? "Store Code" : "Internal Code"
                        }
                        name="internalCode"
                        type="internalCode"
                      />
                    </div>

                    {!isCreateRetailerStore && (
                      <div className="flex  px-4 ml-4   w-full items-center select-none">
                        <TextField2
                          label="Website"
                          name="website"
                          type="text"
                        />
                      </div>
                    )}
                  </div>

                  {isCreateRetailerStore && (
                    <div className="w-full h-20 flex mt-4 justify-between items-center">
                      <div className="flex  px-4 mr-4 w-full items-center select-none">
                        <TextField2
                          label="Max shoppers"
                          name="maxAllowedActiveShopping"
                          type="text"
                        />
                      </div>

                      <div className="flex  px-4 ml-4 w-full items-center select-none">
                        <TextField2
                          label="Max group shoppers"
                          name="maxAllowedGroupShoppingUsers"
                          type="text"
                        />
                      </div>

                      <div className="flex  px-4 ml-4 w-full items-center select-none">
                        <TextField2
                          label="External code"
                          name="externalCode"
                          type="text"
                        />
                      </div>
                    </div>
                  )}

                  <h2 className="px-5 my-2 text-left">Address : </h2>

                  <div className="w-full h-20 flex mt-2  items-center">
                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2 label="Street 1" name="street1" type="text" />
                    </div>

                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2 label="Street 2" name="street2" type="text" />
                    </div>

                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2
                        label="City Or Village"
                        name="city"
                        type="text"
                      />
                    </div>

                    <div className="flex  px-4   w-full items-center select-none">
                      <TextField2
                        label="Administrative Area"
                        name="administrativeArea"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="w-full h-20 flex  items-center">
                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2
                        label="Latitude"
                        name="latitude"
                        type="text"
                      />
                    </div>

                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2
                        label="Longitude"
                        name="longitude"
                        type="text"
                      />
                    </div>

                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2 label="State" name="state" type="text" />
                    </div>

                    <div className="flex  px-4 w-full items-center select-none">
                      <div style={{ width: "200px" }}>
                        <Select
                          options={countryLists}
                          placeholder="Select country"
                          maxMenuHeight={162}
                          value={country}
                          onChange={(e, value) =>
                            changeHandler(e, setFieldValue, setErrors)
                          }
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          name="country"
                        />
                        {errors.country && touched.country ? (
                          <p className="w-full text-right text-red-500">
                            Required
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div
                    className={`${
                      isCreateRetailerStore ? "w-full" : "w-[50%]"
                    } h-20 flex items-center`}
                  >
                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2 label="Zip Code" name="zip" type="text" />
                    </div>

                    {isCreateRetailerStore && (
                      <>
                        <div className="flex  px-4    w-full items-center select-none">
                          <TextField2
                            label="Contact First Name"
                            name="contactFirstName"
                            type="text"
                          />
                        </div>
                        <div className="flex  px-4    w-full items-center select-none">
                          <TextField2
                            label="Contact Last Name"
                            name="contactLastName"
                            type="text"
                          />
                        </div>
                      </>
                    )}

                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2
                        label="Contact Number"
                        name="contactNo"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="w-full h-16 flex justify-end items-center px-4 mt-2 ">
                    {/* <div className="flex items-center h-6">
                      <input
                        type="checkbox"
                        onChange={(e) => setAdressType(e.target.checked)}
                        className="form-checkbox h-5 w-5 text-green-600"
                      />
                      <span className="mx-1 -mt-0.5 px-2 ">
                        Save as Primary Address
                      </span>
                    </div> */}

                    <button
                      type="submit"
                      className="bg-green-500 rounded-md px-6 py-3  focus:outline-none text-white"
                    >
                      <span className="h-full flex items-center">
                        <svg
                          className="svg-icon"
                          viewBox="0 0 20 20"
                          width="24px"
                          height="24px"
                        >
                          <path
                            fill="#8A2BE2"
                            d="M19.404,6.65l-5.998-5.996c-0.292-0.292-0.765-0.292-1.056,0l-2.22,2.22l-8.311,8.313l-0.003,0.001v0.003l-0.161,0.161c-0.114,0.112-0.187,0.258-0.21,0.417l-1.059,7.051c-0.035,0.233,0.044,0.47,0.21,0.639c0.143,0.14,0.333,0.219,0.528,0.219c0.038,0,0.073-0.003,0.111-0.009l7.054-1.055c0.158-0.025,0.306-0.098,0.417-0.211l8.478-8.476l2.22-2.22C19.695,7.414,19.695,6.941,19.404,6.65z M8.341,16.656l-0.989-0.99l7.258-7.258l0.989,0.99L8.341,16.656z M2.332,15.919l0.411-2.748l4.143,4.143l-2.748,0.41L2.332,15.919z M13.554,7.351L6.296,14.61l-0.849-0.848l7.259-7.258l0.423,0.424L13.554,7.351zM10.658,4.457l0.992,0.99l-7.259,7.258L3.4,11.715L10.658,4.457z M16.656,8.342l-1.517-1.517V6.823h-0.003l-0.951-0.951l-2.471-2.471l1.164-1.164l4.942,4.94L16.656,8.342z"
                          ></path>
                        </svg>
                        <span className="px-2">
                          {isCreateRetailerStore
                            ? "Create store"
                            : `Create ${title}`}
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default CreateMerchant;
