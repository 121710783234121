import React, { useState } from "react";
import Toggle from "react-toggle";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

function Confirm({ params, handleRefresh }) {
  const history = useHistory();
  const [onHoldStatus, setOstatus] = useState(
    params?.data?.userInfoDTO?.onHoldStatus === "true" ? true : false
  );
  // 'https://portal-uat.autonomo.tech/shopper/api/users/un-hold?login=0000001403&rootOrgId=3201'
  // 'https://portal-uat.autonomo.tech/shopper/api/users/on-hold?login=0000001403&rootOrgId=3201'

  const statusChecked = (e) => {
    const value = e.target.checked;

    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            // var body = {
            //   id: params?.data?.id,
            //   name: params?.data?.name,
            //   status: value,
            // };

            try {
              let data = false;
              if (params?.data?.userInfoDTO?.onHoldStatus === true) {
                data = await axios({
                  method: "PUT",
                  url: `${
                    process.env.REACT_APP_SHOPPER_URL
                  }/users/un-hold?login=${params?.data?.login}&rootOrgId=${
                    params?.data?.userInfoDTO?.rootOrgId || 3201
                  }`,
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem(
                      "user-info"
                    )}`,
                  },
                });
              } else {
                data = await axios({
                  method: "PUT",
                  url: `${
                    process.env.REACT_APP_SHOPPER_URL
                  }/users/on-hold?login=${params?.data?.login}&rootOrgId=${
                    params?.data?.userInfoDTO?.rootOrgId || 3201
                  }`,
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem(
                      "user-info"
                    )}`,
                  },
                });
              }

              setOstatus(data);
              if (handleRefresh) handleRefresh();
              // window.locatio n.reload();
              history.push("/Support/shopper");
            } catch (error) {
              console.log(
                "🚀 ~ file: Confirm.js:72 ~ onClick: ~ error:",
                error?.response?.status === 401
              );
              if (error?.response?.status === 401) {
                history.push("/login");
              } else {
                toast.error(error?.response?.data?.title);
              }
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  //console.log("use: ", status);

  return (
    <>
      <Toggle
        onChange={statusChecked}
        checked={params?.data?.userInfoDTO?.onHoldStatus}
        //icons={{ checked: null, unchecked: null }}
        className="react-switch"
      />
    </>
  );
}

export default Confirm;
