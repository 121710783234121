import React from "react";
import { useHistory } from "react-router-dom";

function Protected(props) {
  let Cmp = props.Cmp;
  const history = useHistory();

  window.addEventListener("storage", (e) => {
    if (!localStorage.getItem("user-info")) {
      history.push("/login");
    }
  });

  return (
    <>
      <Cmp  header={false}/>
    </>
  );
}

export default Protected;
