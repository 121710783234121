import React, { useState, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "react-toggle/style.css";
import Select from "react-select";
import DashboardRoute from "./DashboardRoute";
import NumberFormat from "react-number-format";
import Confirm from "./Confirm";
import Action from "./Action";
import { BiArrowBack, BiSearchAlt } from "react-icons/bi";
import axios from "axios";
import FadeLoader from "react-spinners/FadeLoader";
import { useQuery } from "react-query";
import Pagination from "@mui/material/Pagination";
import { MdClear } from "react-icons/md";
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
import { useGet } from "../../../../../../../api/useGet";
import { FiEdit } from "react-icons/fi";

function ConfigureStore() {
  const [allCount, setAllCount] = useState(0);
  const [input, setInput] = useState({
    name: "",
  });

  const location = useLocation();
  console.log("🚀 ~ ConfigureStore ~ location:", location);

  const history = useHistory();

  const [activePage, setActivePage] = useState(1);

  const { data, isApiLoading: isLoading } = useGet(
    input?.name
      ? `${process.env.REACT_APP_BASE_URL}/retail-store-configurations/${location?.state?.id}?configName.contains=${input?.name}`
      : `${process.env.REACT_APP_BASE_URL}/retail-store-configurations/${location?.state?.id}`,
    ["configration", input]
  );

  useEffect(() => {
    console.log("dataaaaaaaaaaaaaaaaaaaaaaaa", data);
  }, [data]);

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  const onChangeInput = (e, name) => {
    const value = e.target.value;

    setInput({ ...input, [name]: value, type: name });
  };

  const clear = () => {
    setInput({ name: "" });
  };

  return (
    <div className=" w-full h_das_table">
      <div
        className={
          isLoading
            ? "bg-black bg-opacity-0 absolute inset-0 z-10 flex items-center justify-center"
            : null
        }
      >
        <FadeLoader className="absolute " loading={isLoading} size={150} />
      </div>

      <div
        className={`w-full h-16 flex justify-between p-6 items-center bg-gray-100`}
      >
        <div className="w-full h-12 flex bg-gray-100  items-center">
          <BiArrowBack
            onClick={() => history.goBack()}
            className="h-6 w-6 cursor-pointer"
          />
          <h2 className="font-bold text-2xl ml-3">Configure retail store</h2>
        </div>

        <div>
          <button
            onClick={() =>
              history.push({
                pathname: "configure-store/create",
                state: {
                  data,
                  isConfigureRetailStore: true,
                  retailerStore: location?.state,
                },
              })
            }
            className={`w-44 bg-green-500 rounded-sm h-9 justify-center flex items-center
               focus:outline-none text-white`}
          >
            Create configuration
          </button>
        </div>
      </div>

      <div className="flex h-10 w-full items-center mt-2 justify-between py-8 px-5">
        <div className="w-3/4 flex  h-full items-center space-x-[5px]">
          <div className="w-72 flex justify-end items-center">
            <input
              name="name"
              placeholder="Search by Name"
              value={input?.name}
              onChange={(e) => onChangeInput(e, "name")}
              className="w-full h-10  focus:outline-none rounded px-4 border border-black"
            />
            {input["name"]?.length > 0 ? (
              <MdClear
                size={24}
                className="cursor-pointer -ml-[34px]"
                onClick={clear}
              />
            ) : (
              <BiSearchAlt
                size={24}
                className="cursor-pointer -ml-[34px] text-blue-500"
              />
            )}
          </div>
        </div>
        <button
          onClick={clear}
          className="bg-green-600 py-1.5 px-5 mr-7 rounded text-white cursor-pointer focus:outline-none"
        >
          Clear filters
        </button>
      </div>

      <div className="ag-theme-alpine  w-full h-full text-left">
        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 80,
            resizable: true,
          }}
          suppressRowClickSelection={true}
          rowSelection={"multiple"}
          rowData={data}
        >
          <AgGridColumn
            headerName="Config Name"
            field="configName"
            minWidth={100}
            sortable={true}
            // headerCheckboxSelection={true}
            // headerCheckboxSelectionFilteredOnly={true}
            // checkboxSelection={true}
            cellRendererFramework={(params) => (
              <DashboardRoute params={params} />
            )}
          />

          <AgGridColumn
            headerName="Config Value"
            field="configValue"
            minWidth={80}
            sortable={true}
          />

          <AgGridColumn
            field="action"
            minWidth={80}
            cellRendererFramework={(params) => (
              // <Action
              //   params={params}
              //   // url={url}
              //   // viewUrl={viewUrl}
              //   // editUrl={editUrl}
              // />
              <div className="border-2 border-gray-200 rounded-r-lg p-1">
                <FiEdit
                  className="w-5 h-5 cursor-pointer"
                  onClick={() =>
                    history.push({
                      pathname: "configure-store/edit",
                      state: { data: params.data, isEditeRetailerStore: true },
                    })
                  }
                />
              </div>
            )}
          />
        </AgGridReact>
      </div>

      <div className="absolute bottom-8 right-12  flex justify-center item-center">
        <Pagination
          count={
            allCount % 9 === 0
              ? parseInt(allCount / 9)
              : parseInt(allCount / 9) + 1
          }
          showFirstButton
          showLastButton
          page={activePage}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
}

export default ConfigureStore;
