import React, { useState, useEffect } from "react";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";

const Swipper = ({ arr, selectedData, setSelectedData }) => {


 
  const [index, setIndex] = useState(0);

  const nextArr = () => {
    if (index > 0 && index < arr.length - 1) {
      setIndex(index + 1);
    } else if (index === 0 && arr.length !== 1) {
      setIndex(index + 1);
    }
  };
  const prevArr = () => {
    if (index > 0 && index < arr.length) {
      setIndex(index - 1);
    } else if (index === arr.length) {
      setIndex(arr.length - 1);
    }
  };

  useEffect(() => {
    setSelectedData(arr[index]);
  }, [index]);

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex justify-center items-center space-x-0">
        <HiChevronLeft
          onClick={prevArr}
          size={28}
          color={index === 0 && "gray"}
        >
          next
        </HiChevronLeft>
        <div className="w-[150px] rounded-lg h-[150px] flex justify-center items-center">
          {selectedData && (
            <img
              src={selectedData?.imageUrl || "/noImage.jpg"}
              className={`w-full h-full bg-cover ${selectedData?.imageUrl === "" && "border"} `}
            />
          )}
        </div>
        <HiChevronRight
          onClick={nextArr}
          size={28}
          color={index === arr?.length - 1 && "gray"}
        >
          prev
        </HiChevronRight>
      </div>
      <div>
        <p>{selectedData?.productName}</p>
      </div>
    </div>
  );
};

export default Swipper;
