// ProductEventSummaryModal.js
import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import ProductEventSummaryTable from "../dashboard/ProductEventSummaryTable";
import useStyles from "../../../../../../theme/variants/Button";

const ProductEventSummaryModal = ({
  productEventSummary,
  opencartApiParams,
  setOpencartApiParams,
  open,
  handleOpen,
  handleOnChange,
  handleFilter,
  checkedRack,
  setCheckedRack,
  handleClose,
  handleAllChecked,
}) => {
  const classes = useStyles();
  console.log(
    "===============",
    productEventSummary?.shelfSummary?.length,
    opencartApiParams?.["scaleInternalCode.in"]?.split(",")?.length
  );

  return (
    <div>
      <Button
        variant="contained"
        sx={{ fontSize: "12px", mb: "3px", bgcolor: "#000" }}
        onClick={handleOpen}
        startIcon={<FilterListIcon />}
        className={
          opencartApiParams?.["scaleInternalCode.in"] === "" ||
          productEventSummary?.shelfSummary?.length ===
            opencartApiParams?.["scaleInternalCode.in"]?.split(",")?.length
            ? classes.greyButton
            : classes.blueButton
        }
      >
        Filter by Rack
      </Button>
      {open && (
        <Dialog open={open} onClose={handleClose}>
          {/* <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">Filter by rack</Typography>
            <div className="flex items-center">
              <Checkbox
                sx={{ p: 0, mr: 1 }}
                // checked={checkedRack.includes(row?.scaleCode)}
                // onChange={(e) => handleOnChange(e, row)}
              />
              <Typography variant="body2">Select All</Typography>
            </div>
          </DialogTitle> */}
          <DialogTitle textAlign="center">Filter by rack</DialogTitle>
          <Divider />
          <DialogContent>
            <ProductEventSummaryTable
              productEventSummary={productEventSummary}
              setOpencartApiParams={setOpencartApiParams}
              opencartApiParams={opencartApiParams}
              handleOnChange={handleOnChange}
              checkedRack={checkedRack}
              setCheckedRack={setCheckedRack}
              handleAllChecked={handleAllChecked}
            />
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
            <Button
              variant="contained"
              className={classes.blueButton}
              onClick={handleFilter}
            >
              Filter
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default ProductEventSummaryModal;
