import React, { useState } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "react-toggle/style.css";
import { useHistory } from "react-router-dom";
import DashboardRoute from "./DashboardRoute";
import { useQuery } from "react-query";
import FadeLoader from "react-spinners/FadeLoader";
import axios from "axios";
import { toast } from "react-toastify";
import Pagination from "@mui/material/Pagination";
import Action from "./Action";
import { getTime } from "../../../../../../../logic/useGetTime";
import { AiFillDelete } from "react-icons/ai";
import moment from "moment";

function All({
    url,
    viewUrl,
    editUrl,
    listUrl,
    refresh,
    setRefresh,
}) {

    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [allCount, setAllCount] = useState();

    const [activePage, setActivePage] = useState(1);

    const handlePageChange = (event, value) => {
        setActivePage(value);
    };

    const abandonEvent = (data) =>  {
        const payload = {
            ...data,
            eventStatus: 'ABANDONED'
        };

        let updateUrl = '';
        if (data?.eventType === 'Video Event') {
            updateUrl = `${process.env.REACT_APP_STORE_URL}/cart-item-video-activities/${data?.id}`;
        } else if (data?.eventType === 'Pre Image Event') {
            updateUrl = `${process.env.REACT_APP_STORE_URL}/cart-item-pre-image-activities/${data?.id}`;
        } else if (data?.eventType === 'Post Image Event') {
            updateUrl = `${process.env.REACT_APP_STORE_URL}/cart-item-post-image-activities/${data?.id}`;
        }

        axios({
            method: "PATCH",
            url: updateUrl,
            headers: {
              "Content-Type": "application/merge-patch+json",
              Authorization: `Bearer ${localStorage.getItem("user-info")}`,
            },
            data: payload,
          }).catch(({ message }) => {
            if (message === "Request failed with status code 401") {
              history.push("/login");
            } else {
              toast.error(message)
            }
          })
    }

    const fetchData = async () => {
        try {
            const requestUrl = `${listUrl}?sort=createdDate,desc&page=${activePage - 1}&size=10&eventTime.greaterThanOrEqual=${moment().utc().subtract(3, "hours").format("YYYY-MM-DDTHH:mm:ss.S[Z]")}&eventStatus.equals=DETECTED&isNotifiedForVideoProcessing.equals=true`;
            
            const res = await axios({
                method: 'GET',
                url: requestUrl,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("user-info")}`,
                },
            })
            setAllCount(res?.headers?.["x-total-count"]);
            return res.data;
        } catch({message}) {
            if (message === "Request failed with status code 401") {
                history.push("/login");
              } else {
                toast.error(message);
              }
        }
    }

    const { data, isLoading } = useQuery(
        [
            "productDecisionEvents",
            activePage,
            refresh
        ],
        async () => fetchData(),
        {
            enabled:
                !!activePage,
                refresh,
            cacheTime: 0,
            staleTime: 0,
        }
    );

    if (isLoading) {
        return (
            <div className="bg-black bg-opacity-80 absolute inset-0 flex items-center justify-center">
              <div className="w-full h-20 absolute z-10 text-white flex flex-col items-center justify-center">
                <FadeLoader
                  color="#FFFFFF"
                  className=""
                  loading={isLoading}
                  size={150}
                />
              </div>
            </div>
          );
    }
    

    return (
        <div className=" w-full h-[calc(100vh-184px)]">
            {/* <div className="flex h-10 w-full items-center mt-2 justify-between py-8 px-4">    
            </div> */}
            <div className="ag-theme-alpine mt-2 w-full h-full text-left py-8">
                <AgGridReact
                    defaultColDef={{
                        flex: 1,
                        minWidth: 100,
                        resizable: true,
                    }}
                    rowHeight={50}
                    suppressRowClickSelection={true}
                    rowSelection={"multiple"}
                    rowData={data}
                >
                    <AgGridColumn
                        headerName="Event"
                        field="scaleInternalCode"
                        minWidth={250}
                        sortable={true}
                        cellRendererFramework={(params) => (
                            <DashboardRoute params={params} name="scaleInternalCode" />
                        )}
                    />
                    <AgGridColumn
                        headerName="Timestamp"
                        field="eventTime"
                        cellRendererFramework={(params) => {
                            return (
                              <p className={'list_font_style'}>
                                {getTime(
                                  params?.data?.eventTime,
                                  params?.data?.storeTimeZone
                                )}
                              </p>
                            );
                          }}
                    />
                    {/* <AgGridColumn
                        headerName="Review Needed?"
                        field="reviewNeeded"
                        minWidth={80}
                        cellRendererFramework={(params) => {
                            return (params?.data?.isNotifiedForVideoProcessing && params?.data?.isVideoAvailable && params?.data?.isPreImageAvailable && params?.data?.isPostImageAvailable) ? (
                                "-"
                            ) : (<p><span className="font-bold text-red">Review Needed</span></p>)
                        }}
                    /> */}
                    <AgGridColumn
                        headerName="Item Name"
                        field="itemName"
                        cellRendererFramework={(params) => (
                            <DashboardRoute params={params} name="recommendedProductNames" />
                        )}
                    />
                    <AgGridColumn
                        headerName="Task Summary"
                        field="taskSummary"
                        cellRendererFramework={(params) => (
                            <DashboardRoute params={params} name="decisionState" />
                        )}
                    />
                    <AgGridColumn
                        headerName="Action"
                        field="action"
                        minWidth={80}
                        cellRendererFramework={(params) => (
                            <Action
                              params={params}
                              url={url}
                              viewUrl={viewUrl}
                              editUrl={editUrl}
                              through={"productDecisionEvents"}
                            />
                        )}
                    />
                </AgGridReact>
            </div>
            <div className="mb-9 right-12 flex justify-center item-center">
                <Pagination
                count={
                    allCount % 9 === 0
                    ? parseInt(allCount / 9)
                    : parseInt(allCount / 9) + 1
                }
                showFirstButton
                showLastButton
                page={activePage}
                onChange={handlePageChange}
                />
            </div>
        </div>
    );
}

export default All;