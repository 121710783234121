import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box, Button, IconButton } from "@mui/material";
import { usePut } from "../../../../../../api/usePut";
import { toast } from "react-toastify";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import AddBoxIcon from "@mui/icons-material/AddBox";

const columns = [
  { id: "position", label: "Position", minWidth: 170 },
  { id: "image", label: "Image", minWidth: 100, align: "center" },
  { id: "qty", label: "Qty", minWidth: 100, align: "center" },
  {
    id: "productName",
    label: "Product Name",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "type",
    label: "Type",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "action",
    label: "Action",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
];

export default function FinalCartReview({
  storeCarts,
  setCurrentTab,
  setRefreshApis,
  finalCartReivewData,
  setFinalCartReviewData,
  processCart,
}) {
  console.log("finalCartReivewData", finalCartReivewData);

  const {
    mutate: updateCartItem,
    isSuccess,
    reset,
  } = usePut(
    `${process.env.REACT_APP_SHOPPER_URL}/stores/modify-cart-item`,
    "updateCartItem"
  );

  const onUpdate = (item) => {
    console.log("🚀 ~ file: FinalReview.js:101 ~ onUpdate ~ item:", item);
    try {
      const paylaod = {
        id: finalCartReivewData?.id,
        storeCartItems: [
          {
            id: item?.id,
            quantity: item?.reviewedQuantity,
          },
        ],
      };

      updateCartItem(paylaod);
      toast.success("updated sucessfully");
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleQuentity = (operation, prod, value) => {
    setFinalCartReviewData((prevState) => {
      const updatedCartItems = prevState.storeCartItems.map((item) => {
        if (item.id === prod?.id) {
          const updatedQuantity =
            operation === "+"
              ? item.reviewedQuantity + 1
              : operation === "-"
              ? item.reviewedQuantity - 1
              : value;
          console.log("updatedQuantity", updatedQuantity);
          return { ...item, reviewedQuantity: Math.max(updatedQuantity, 0) }; // Ensure quantity does not go below 0
        }
        return item;
      });
      return { ...prevState, storeCartItems: updatedCartItems };
    });
  };

  React.useEffect(() => {
    if (isSuccess) {
      // processCart();
      setRefreshApis((flag) => !flag);
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", height: "100%" }}>
      <TableContainer sx={{ maxHeight: "100%" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    fontWeight: 700,
                    fontSize: "1rem",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {finalCartReivewData?.storeCartItems?.map((row, i) => {
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  sx={{ height: "70px", cursor: "pointer" }}
                  // onClick={() => {
                  //   setCurrentTab(1);
                  // }}
                >
                  <TableCell
                    onClick={() => {
                      setCurrentTab(1);
                    }}
                  >
                    <div>
                      <p className="mt-1 font-bold">{row?.scaleInternalCode}</p>
                    </div>
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      setCurrentTab(1);
                    }}
                  >
                    <div className="flex items-center justify-center">
                      {row?.imageUrl ? (
                        <img
                          style={{ width: "60px", height: "60px" }}
                          src={row?.imageUrl}
                          alt=""
                        />
                      ) : (
                        <img
                          style={{ width: "60px", height: "60px" }}
                          src="/images/noImage.jpg"
                          alt=""
                        />
                      )}
                    </div>
                  </TableCell>
                  <TableCell
                    align="center"
                    // onClick={() => {
                    //   setCurrentTab(1);
                    // }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <IconButton onClick={() => handleQuentity("-", row, 0)}>
                        <IndeterminateCheckBoxIcon fontSize="large" />
                      </IconButton>
                      <input
                        // readOnly
                        type="number"
                        value={row?.reviewedQuantity}
                        onChange={(e) =>
                          handleQuentity("menual", row, e.target.value)
                        }
                        className="w-[45px] h-[30px] bg-white flex items-center justify-center text-center"
                      />
                      <IconButton onClick={() => handleQuentity("+", row, 0)}>
                        <AddBoxIcon fontSize="large" />
                      </IconButton>
                    </Box>
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      setCurrentTab(1);
                    }}
                  >
                    <div className="flex items-center flex-col justify-center">
                      <p className="mt-1 font-bold">{row?.name}</p>
                      {row?.isStockClearanceDiscount === true && (
                        <div
                          style={{
                            padding: "6px",
                            backgroundColor: "rgba( 0, 102, 0, 0.21)",
                            width: "150px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "10px",
                            marginTop: "6px",
                            marginBottom: "10px",
                            marginLeft: "10px",
                          }}
                        >
                          <img
                            src="/images/discountTag.svg"
                            alt=""
                            style={{ marginTop: "3px" }}
                          />
                          <p style={{ marginLeft: "5px" }}>Discounted</p>
                        </div>
                      )}
                    </div>
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      setCurrentTab(1);
                    }}
                  >
                    <p className="mt-1 font-bold text-center">
                      {row?.resolutionType}
                    </p>
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      disabled={
                        row?.reviewedQuantity ==
                        storeCarts?.[i]?.reviewedQuantity
                      }
                      onClick={() => onUpdate(row)}
                    >
                      Save
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Paper>
  );
}
