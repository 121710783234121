import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import NumberFormat from "react-number-format";
import ProductItem from "./ProductItem";
import OrderSummaryHeader from "./OrderSummaryHeader";
import { MdDownload } from "react-icons/md";
import ModalSession from "./ModalSession";
import { Languages1 } from "../../../../../../Languages";
import Cookies from "universal-cookie";
import axios from "axios";
import { getTime } from "../../../../../../logic/useGetTime";
import { useGet } from "../../../../../../api/useGet";
import FadeLoader from "react-spinners/FadeLoader";
import { toast } from "react-toastify";
import { fields } from "../../../../../../statusField";
import Tippy from "@tippyjs/react";
import PaymentStepperModal from "../modal/PaymentProcessModal";

function OrderSummary({ header }) {
  const [title, setTitle] = useState("Order Summary");
  const history = useHistory();
  const {
    state: { data: locationData },
  } = useLocation();
  console.log("locationData", locationData);
  const cookies = new Cookies();
  const [input, setInput] = useState({
    qty: "",
    reviewQty: "",
  });

  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = (e, reason) => {
    if (reason && reason === "backdropClick") return;
    console.log("reason", reason);
    setModalOpen(false);
  };

  const detectedLan = (code) => {
    return Languages1?.filter((item) => {
      return item?.code === code;
    });
  };

  const { data, isLoading } = useGet(
    `${process.env.REACT_APP_SHOPPER_URL}/orders/${locationData?.id}`,
    "orderData"
  );

  const fetchDownload = (url, name) => {
    const res = fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      }),
    })
      .then((res) => res.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${name}.pdf`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  const download = async () => {
    fetchDownload(
      `${process.env.REACT_APP_SHOPPER_URL}/orders/${
        data?.id
      }/download?langKey=${detectedLan(cookies.get("i18next"))[0]?.value}`,
      `${data?.orderNumber}`
    );
  };

  const refund = (data) => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_SHOPPER_URL}/order-refunds/${data?.id}/request`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
    })
      .then((res) => {
        history.push({
          pathname: "/support/order/refund",
          state: { orderId: data.id, data: res.data },
        });
      })
      .catch(({ message }) => {
        if (message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          toast.error(message);
        }
      });
  };

  const viewCart = (data) => {
    let sessionRef;
    let storeCode;

    axios({
      method: "GET",
      url: `${process.env.REACT_APP_SHOPPER_URL}/orders/${data?.id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
    })
      .then((res) => {
        sessionRef = res?.data?.orderSessions[0]?.sessionRef;
        storeCode = res?.data?.retailStore?.internalCode;
        console.log("orderapi: ", res?.data);
        axios({
          method: "GET",
          url: `${process.env.REACT_APP_STORE_URL}/store-carts?cartRef.equals=${sessionRef}&storeCode.equals=${storeCode}&sort=eventTime,desc`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("user-info")}`,
          },
        }).then((res) => {
          history.push({
            pathname: "/support/match/processedEvents",
            state: {
              openData: res?.data,
              data: {
                sessionRef: sessionRef,
                storeCode: storeCode,
              },
            },
          });
        });
      })
      .catch(({ message }) => {
        if (message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          toast.error(message);
        }
      });
  };

  if (isLoading)
    return (
      <div className="bg-black bg-opacity-80 absolute inset-0 flex items-center justify-center">
        <div className="w-full h-20 absolute z-10 text-white flex flex-col items-center justify-center">
          <FadeLoader
            color="#FFFFFF"
            className=""
            loading={isLoading}
            size={150}
          />
        </div>
      </div>
    );

  return (
    <div
      className={`w-full ${
        header ? "h-[calc(100vh)]" : "h-[100vh] pt-4"
      }  text-left flex select-none bg-gray-200`}
    >
      <div className="w-full h-full px-4  py-2">
        <div className="w-full  h-full  ">
          <div className="w-full h-8 flex items-center bg-white rounded-lg">
            <BiArrowBack
              onClick={() => history.goBack()}
              className="h-8 w-8 cursor-pointer absolute ml-4"
            />

            <div className="w-full flex justify-center items-center">
              <h2 className="font-bold text-xl">
                Order No : {data?.orderNumber}
              </h2>
            </div>
          </div>

          <div className="w-full  rounded-lg px-2 bg-white my-2 pb-2">
            <div className="flex justify-center items-center my-1">
              <h2 className="px-2 font-bold">Order Summary</h2>
            </div>

            {/* firstLine */}
            <div className="w-full flex justify-between px-2 pt-1">
              <div className="w-[300px] px-2">
                <h2>
                  <span className="font-bold">{`Login: `}</span>
                  <NumberFormat
                    displayType={"text"}
                    value={data?.login}
                    format="###-###-####"
                  />
                </h2>
              </div>
              <div className="w-[300px]  px-2">
                <h2>
                  <span className="font-bold">{`Email:`}</span>{" "}
                  {data?.email ? data?.email : "-"}
                </h2>
              </div>

              <div className="w-[300px]  px-2">
                <h2>
                  <span className="font-bold">{`Retailer store:`}</span>{" "}
                  {data?.retailStore?.name}
                </h2>
              </div>
            </div>
            {/* secondLine */}

            <div className="w-full flex justify-between px-2 pt-1">
              <div className="w-[300px]  px-2">
                <h2 className="">
                  <span className="font-bold">{`Total :`}</span>{" "}
                  {data?.formattedEffectiveOrderTotalCurrency}
                </h2>
              </div>

              <div className={`w-[300px]  px-2`}>
                <h2>
                  <span className="font-bold">{`Status: `}</span>

                  <span className={fields[data?.status]?.color}>
                    {fields[data?.status]?.title}
                  </span>
                </h2>
              </div>

              <div className="w-[300px] flex  px-2">
                <div className="flex ">
                  <h2 className="mr-3 font-bold">Receipt</h2>
                  <Tippy content="Download Receipt">
                    <button onClick={download}>
                      <MdDownload className="w-6 h-6 mt-1 cursor-pointer hover:text-blue-500 " />
                    </button>
                  </Tippy>
                </div>
              </div>
            </div>

            {/* thirdLine */}
            <div className="w-full flex justify-between px-2 ">
              <div className="w-[300px]  px-2">
                <h2>
                  <span className="font-bold">{`Order Time :`}</span>{" "}
                  {getTime(data?.orderTime, data?.storeTimeZone)}
                </h2>
              </div>

              <div className="w-[300px]  px-2 flex justify-between">
                {/* <button
                  onClick={onOpenModal}
                  className="text-blue-500 cursor-pointer focus:outline-none"
                >
                  Session Summary
                </button> */}

                <button
                  onClick={() => refund(data)}
                  className="text-blue-500 cursor-pointer focus:outline-none underline"
                >
                  Initiate Refund
                </button>
                <button
                  // onClick={() => handleOpenModal(true)}
                  className="text-blue-500 cursor-pointer focus:outline-none underline"
                >
                  Payment History
                </button>
              </div>

              <div className="w-[300px]  px-2">
                <button
                  onClick={() => viewCart(data)}
                  className="text-blue-500 cursor-pointer focus:outline-none underline"
                >
                  View Cart
                </button>
              </div>
            </div>
          </div>

          <ModalSession open={open} onCloseModal={onCloseModal} />

          <div className="w-full h-[60%] rounded-lg bg-white flex flex-col ">
            <OrderSummaryHeader path="OrderSummary" status={data?.status} />

            <div className="h-[17rem] mt-2  px-1.5 scrollbar-thin  scrollbar-thumb-gray-300 scrollbar-thumb-rounded-lg scrollbar-track-gray-100">
              {data?.orderItems?.map((item, index) => (
                <ProductItem
                  key={index}
                  data={data}
                  item={item}
                  index={index}
                  input={input}
                  setInput={setInput}
                  path={"OrderSummary"}
                />
              ))}
            </div>

            <div className="px-5 my-4 space-y-2 mr-[53px]">
              <div className="flex justify-end space-x-[78px]">
                <div className="space-y-1">
                  {data?.discountTotal > 0 && (
                    <p className="font-bold">{data?.offerName}</p>
                  )}

                  <p className="font-bold">Total</p>
                </div>
                <div className="space-y-1 flex flex-col items-center">
                  {data?.discountTotal > 0 && (
                    <div className="flex space-x-2">
                      <p className="w-[3px] font-bold">-</p>
                      <p className="">
                        {data?.formattedEffectiveDiscountTotalCurrency}
                      </p>
                    </div>
                  )}
                  <div className="w-[100px] h-[1.5px] bg-gray-200"></div>
                  <div className="flex space-x-2">
                    <p className="w-[3px] font-bold"></p>
                    <p>{data?.formattedEffectiveOrderTotalCurrency}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full  rounded-lg px-4 bg-white my-2 py-2">
            {/* firstLine */}
            <div className="w-full flex justify-between border-b-2 text-center border-gray-300 px-[20%] ">
              <div className="w-1/4 px-2 flex justify-center">
                <h2 className="w-[80px] text-left">
                  <span className="font-bold">{`Tax %`}</span>
                </h2>
              </div>
              <div className="w-1/4 px-2">
                <h2>
                  <span className="font-bold">{`Net`}</span>
                </h2>
              </div>

              <div className="w-1/4 px-2">
                <h2>
                  <span className="font-bold">{`Tax`}</span>
                </h2>
              </div>
              <div className="w-1/4 px-2">
                <h2>
                  <span className="font-bold">{`Gross`}</span>
                </h2>
              </div>
            </div>
            {/* secondLine */}
            {Object.entries(data?.orderTaxGroup).map(([key, val]) => (
              <div
                key={key}
                className="w-full flex justify-between text-center px-[20%]"
              >
                <div className="w-1/4 px-2 flex justify-center">
                  <p className="w-[80px] text-left"> {val?.taxValue}</p>
                </div>
                <div className="w-1/4 px-2">
                  {val?.formattedEffectiveOrderItemNetCostCurrency}
                </div>

                <div className="w-1/4 px-2">
                  {val?.formattedEffectiveOrderItemTaxCurrency}
                </div>
                <div className="w-1/4 px-2">
                  {val?.formattedEffectiveOrderItemGrossCostCurrency}
                </div>
              </div>
            ))}

            {/* thirdLine */}
            <div className="w-full flex justify-between text-center px-[20%] border-t-2 border-gray-300">
              <div className="w-[25%] px-2 flex justify-center">
                <p className="w-[80px] text-left">Total</p>
              </div>

              <div className="w-[25%] px-2">
                {data.formattedEffectiveNetTotalCurrency}
              </div>

              <div className="w-[25%] px-2">
                {data?.formattedEffectiveTaxTotalCurrency}
              </div>

              <div className="w-[25%]  px-2">
                {data?.formattedEffectiveOrderTotalCurrency}
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalOpen && (
        <PaymentStepperModal
          open={modalOpen}
          onClose={handleCloseModal}
          data={locationData}
        />
      )}
    </div>
  );
}

export default OrderSummary;
