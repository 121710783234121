import React from "react";

import { Link } from "react-router-dom";

function DashboardRoute({ params }) {
  console.log("params", params);
  return (
    <>
      <Link
        to={{
          pathname: "/support/order/order-summary",
          state: { data: params.data },
        }}
        className="text-blue-900 font-bold"
      >
        {params.value}
      </Link>
    </>
  );
}

export default DashboardRoute;
