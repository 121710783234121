import React, { useState, useEffect } from "react";
import Action from "./Action";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { GrView } from "react-icons/gr";
import { FiEdit } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import "react-toggle/style.css";
import Address from "./Address";
import DashboardRoute from "./DashboardRoute";
import Confirm from "./Confirm";
import { toast } from "react-toastify";
import { CgOpenCollective, CgCalibrate } from "react-icons/cg";
import { BiSearchAlt, BiMapPin } from "react-icons/bi";
import { IoMdOptions } from "react-icons/io";
import { MdSyncDisabled } from "react-icons/md";
import { FaHandsHelping } from "react-icons/fa";
import { GiWeight } from "react-icons/gi";
import ModalView from "../../../../../../../modal/view/ModalScale";
import ModalEdit from "../../../../../../../modal/edit/ModalScale";
import axios from "axios";
import { usePost, useGet } from "../../../../../../../../api/usePost";
import { useAlert } from "react-alert";

function Scale({
  isLoading,
  error,
  data,
  url,
  viewUrl,
  editUrl,
  sortedData,
  deviceId,
  orgList,
  retailerList,
}) {
  const [selectedData, setSelectedData] = useState({});
  const [brand, setBrand] = useState({});
  const alert = useAlert();

  const [view, setView] = useState(false);
  const onOpenView = () => setView(true);
  const onCloseView = () => setView(false);
  const history = useHistory();

  const [edit, setEdit] = useState(false);
  const onOpenEdit = () => setEdit(true);
  const onCloseEdit = () => setEdit(false);

  const [status, setStatus] = useState(false);
  const [value, setValue] = useState("");

  const changeHandler = (value) => {
    setValue(value);
  };

  const action = usePost(
    `${process.env.REACT_APP_SHOPPER_URL}/machine/actions/add`,
    "action"
  );

  const scaleSelect = (e) => {
    e.preventDefault();
  };

  const fetchMerchant = async (url) => {
    try {
      const res = await axios({
        method: "GET",
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      });

      return res;
    } catch ({ message }) {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message);
      }
    }
  };

  const ping = () => {
    const data = {
      machineId: deviceId,
      actions: [],
      scales: [
        {
          scaleId: "001",
          actions: [
            {
              type: "PING",
            },
          ],
        },
      ],
    };
    action.mutate(data);

    if (!action.isError) {
      alert.success(<div style={{ textTransform: "none" }}>Ping success</div>);
    }
  };

  const calibrate = () => {
    const data = {
      machineId: deviceId,
      actions: [],
      scales: [
        {
          scaleId: "001",
          actions: [
            {
              type: "CALIBRATE",
            },
          ],
        },
      ],
    };

    action.mutate(data);

    if (!action.isError) {
      alert.success(
        <div style={{ textTransform: "none" }}>Calibrate success</div>
      );
    }
  };

  const [filterData, setFilterData] = useState(null);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setFilterData(data?.filter((el) => el.scaleId.search(value) != -1));
  };

  const search = () => {};

  return (
    <div className=" w-full h_scale -mt-4">
      <div className=" h-20 flex flex-col justify-center  py-2 bg-white">
        <div className="flex  justify-between items-center">
          <div className="flex justify-between w-auto h-7">
            <button
              onClick={ping}
              className="focus:outline-none bg-grey-100 bg-dash text-white ml-1 hover:text-blue-500 text-grey-darkest font-bold py-1 px-4 rounded inline-flex items-center"
            >
              <FaHandsHelping className="w-4 " />
              <span className="mx-2">Ping</span>
            </button>

            <button
              onClick={calibrate}
              className="focus:outline-none bg-grey-100  bg-dash text-white ml-1 hover:text-blue-500 text-grey-darkest font-bold py-1 px-4 rounded inline-flex items-center"
            >
              <CgCalibrate className="w-4 " />
              <span className="mx-2">Calibrate</span>
            </button>

            <button className="focus:outline-none bg-grey-100 bg-dash text-white ml-1 hover:text-blue-500 text-grey-darkest font-bold py-1 px-4 rounded inline-flex items-center">
              <IoMdOptions className="w-4 " />
              <span className="mx-2">Offset</span>
            </button>

            <div className=" w-auto ml-4 border rounded border-dash flex justify-between items-center">
              <input
                placeholder="Wgt"
                className="w-20 focus:outline-none px-1"
              />
            </div>
            <button className="focus:outline-none bg-grey-100 -ml-2 bg-dash text-white  hover:text-blue-500 text-grey-darkest font-bold  pl-2 rounded-r inline-flex items-center">
              <GiWeight className="w-4" />
              <span className="mx-2">Ref. weight</span>
            </button>
          </div>

          <div className=" w-auto h-auto border-b border-dash mr-2 flex justify-between items-center">
            <input
              placeholder="Search scale here"
              className="w-40 h-6 focus:outline-none px-1 "
              onChange={handleSearch}
            />
            <span>
              <BiSearchAlt
                onClick={search}
                className="w-6 h-6 hover:text-blue-500 cursor-pointer"
              />
            </span>
          </div>
        </div>
        <div className="flex justify-end px-2 mt-2">
          {/* <form onSubmit={scaleSelect} className="h-8 ">
            <span className="mr-1">Select scales from</span>
            <input className="w-20 h-6 border border-dash rounded focus:outline-none px-1" />
            <span className="mx-1">to</span>
            <input className="w-20 h-6 border border-dash rounded focus:outline-none px-1" />
            <button
              type="submit"
              className="ml-1 bg-dash hover:text-blue-500 text-white rounded px-3 focus:outline-none"
            >
              <span></span>
              <span className="mx-2"> Select</span>
            </button>
          </form> */}

          {/* <div className="h-8">
            <button className="ml-1 bg-dash hover:text-blue-500 flex items-center text-white rounded px-3 focus:outline-none">
              <BiMapPin className="w-4 " />
              <span className="mx-2"> Bulk Map</span>
            </button>
          </div> */}
        </div>
      </div>
      <div className="ag-theme-alpine  w-full h-full text-left -mt-1 ">
        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            resizable: true,
          }}
          suppressRowClickSelection={true}
          rowSelection={"multiple"}
          pagination={true}
          paginationPageSize={10}
          rowData={filterData ? filterData : data}
        >
          <AgGridColumn
            headerName="Scale Id"
            field="scaleId"
            minWidth={140}
            sortable={true}
            headerCheckboxSelection={true}
            headerCheckboxSelectionFilteredOnly={true}
            checkboxSelection={true}
          />
          <AgGridColumn
            headerName="Product"
            field="variant"
            minWidth={220}
            sortable={true}
            cellRendererFramework={(params) => (
              <DashboardRoute params={params} />
            )}
          />

          <AgGridColumn
            headerName="Row"
            field="row"
            minWidth={80}
            sortable={true}
          />
          <AgGridColumn
            headerName="Column"
            field="column"
            minWidth={80}
            sortable={true}
          />
          <AgGridColumn
            headerName="Min Unit"
            field="minUnit"
            minWidth={80}
            sortable={true}
          />
          <AgGridColumn
            headerName="Max Unit"
            field="maxUnit"
            minWidth={80}
            sortable={true}
          />
          <AgGridColumn
            headerName="Current Weight"
            field="currentWeight"
            minWidth={135}
            sortable={true}
          />
          <AgGridColumn
            headerName="Quantity"
            field="quantity"
            minWidth={80}
            sortable={true}
          />
          {/* <AgGridColumn
            headerName="Last Active"
            field="lastActiveTime"
            minWidth={100}
            sortable={true}
          />
          <AgGridColumn
            headerName="Status"
            field="status"
            minWidth={100}
            cellRendererFramework={(params) => <Confirm params={params} />}
          /> */}

          <AgGridColumn
            field="action"
            minWidth={80}
            cellRendererFramework={(params) => (
              <>
                <div className="flex w-24 h-10 items-center">
                  <Action
                    params={params}
                    url={url}
                    editUrl={editUrl}
                    viewUrl={viewUrl}
                  />
                </div>
              </>
            )}
          />
        </AgGridReact>
      </div>
      {/* <ModalView
        open={view}
        onCloseModal={onCloseView}
        data={selectedData?.data}
        brand={brand}
      />

      <ModalEdit
        open={edit}
        onCloseModal={onCloseEdit}
        data={selectedData?.data}
        brand={brand}
      /> */}
    </div>
  );
}

export default Scale;
