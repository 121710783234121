import React, { useState, useMemo } from "react";
import { IoIosClose } from "react-icons/io";
import Map from "./Map";

function MapModal(props) {
  const { open, onCloseModal } = props;
  return (
    <>
      {open && (
        <div className="bg-black bg-opacity-80 absolute inset-0 z-10 flex items-center justify-center">
          <div className="w-4/5 h-4/5 shadow-lg  bg-gray-200 " style={{borderRadius:20}}>
         
           <div
              onClick={onCloseModal}
              className="absolute mt-4 ml-4 cursor-pointer z-10 shadow-lg w-8 h-8 justify-center items-center flex rounded-full bg-white"
            >
              <IoIosClose onClick={onCloseModal} className="h-8 w-8" />
            </div>
          
            <Map />

          </div>
        </div>
      )}
    </>
  );
}

export default MapModal;
