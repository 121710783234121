import React, { useState, useEffect } from "react";

function Mapping({
  data,
  colSelect,
  colSelection,
  method,
  selectedData,
  setSelectedData,
}) {
  const selectScale = (data) => {
    console.log("XXXX", data);
    console.log("XXXXMethod", method);

    if (
      method === "MAP" &&
      // data?.productInfos?.length < 4 &&
      data?.status !== "disabled"
    ) {
      colSelection(data);
    } else if (
      method === "UNMAP" &&
      data?.productInfos?.length > 0 &&
      data?.status !== "disabled"
    ) {
      colSelection(data);
    } else if (
      method === "REPLENISH" &&
      data?.productInfos?.length > 0 &&
      data?.status !== "disabled"
    ) {
      colSelection(data);
    } else if (
      method === "RETURN" &&
      data?.productInfos?.length > 0 &&
      data?.status !== "disabled"
    ) {
      colSelection(data);
    } else if (
      method !== "MAP" &&
      method !== "UNMAP" &&
      method !== "RETURN" &&
      method !== "REPLENISH"
    ) {
      if (selectedData?.scaleIdentifier === data?.scaleIdentifier) {
        setSelectedData("");
      } else {
        if (data.productInfos.length > 0) {
          setSelectedData(data);
        }
      }
    }
  };

  return (
    <div
      className="flex flex-col h-[350px] justify-center items-center cursor-pointer"
      // onClick={() => selectScale(data)}
    >
      {/* image hovering */}
      <div className="absolute flex flex-col justify-center items-center space-y-2 h-[320px] mt-[25px]">
        {data?.productInfos?.map((product, index) => {
          return (
            <div key={index}>
              {/* product?.isPrimary  */}
              {product?.isPrimary === true && (
                <div
                  className={`${
                    product?.scaleProductId === selectedData?.scaleProductId &&
                    "border-4 border-blue-500"
                  }`}
                >
                  {product?.imageUrl !== "" ? (
                    <div className="z-0">
                      <img src={product?.imageUrl} className="w-16 h-16" />
                    </div>
                  ) : (
                    <div className="w-[80px] h-[70px] relative bottom-0  bg-gray-900  flex justify-center  items-center">
                      <p className="text-xs  text-white line-clamp-2  hover:line-clamp-none hover:text-[10px] px-1">
                        {product?.productName}
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>

      {/* scale */}
      <p
        style={{
          backgroundColor: "#094DB4",
          color: "#fff",
          padding: "5px 25px",
          borderRadius: "10px",
          marginBottom: "5px",
        }}
        onClick={() => selectScale(data)}
      >
        {data?.scaleIdentifier}
      </p>
      <div
        className={`w-[100px] h-[320px] mx-1.5 cursor-pointer flex 
        ${
          selectedData &&
          selectedData?.scaleIdentifier === data?.scaleIdentifier &&
          "border-2 border-blue-500"
        }
        ${
          colSelect?.scales?.some((id) => id === data?.scaleIdentifier)
            ? "bg-yellow-500"
            : data?.status === "disabled"
            ? "bg-black"
            : data?.productInfos?.length > 0
            ? "bg-green-500"
            : "bg-red-500"
        }`}
      ></div>
    </div>
  );
}

export default Mapping;
