import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { DatePicker, Space } from "antd";
import { Box } from "@material-ui/core";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
import { usePost } from "../../../../../../../api/usePost";
import moment from "moment";
import { toast } from "react-toastify";
import { Backdrop, CircularProgress } from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import "./index.css";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

function isTimeDifferenceLessThan30Minutes(time1, time2) {
  const format = "YYYY-MM-DD HH:mm:ss";
  const dateTime1 = moment(time1, format);
  const dateTime2 = moment(time2, format);
  const difference = dateTime2.diff(dateTime1, "minutes");
  const differenceInHr = dateTime2.diff(dateTime1, "hours");
  console.log("Math.abs(difference)", Math.abs(difference));

  return {
    isApiCall: Math.abs(difference) < 30,
    timeDiff: difference,
  };
}

function AlertDialog({
  open,
  setOpen,
  handleClickOpen,
  handleClose,
  timeDiff,
  handleApiCall,
}) {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Large file size video!!!!
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`This video is about ${timeDiff} minuts long, Do you want to proceed?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button
            variant="contained"
            // color="success"
            onClick={handleApiCall}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
// Custom styles to adjust z-index
const customStyles = {
  ".ant-picker-dropdown": {
    zIndex: 1500, // Override default styling
  },
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-container": {
    "& .MuiPaper-root": {
      width: "100%",
      maxWidth: "700px", // Set your width here
      height: "500px",
    },
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(5),
    // width: 700,
    // height: 500,
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function RequestPriorityVideoModal({
  open,
  setOpen,
  handleClickOpen,
  handleClose,
  data,
}) {
  console.log("🚀 ~ data:", data, dayjs(data?.entryTime), data?.entryTime);
  const [apiData, setApiData] = React.useState({
    entryTime: null,
    checkoutTime: null,
  });
  const [apiData2, setApiData2] = React.useState({
    entryTime: null,
    checkoutTime: null,
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [openAlertDialog, setOpenalertDialog] = React.useState(false);

  const handleAlertDialogOpen = () => setOpenalertDialog(true);
  const handleAlertDialogClose = () => setOpenalertDialog(false);

  React.useEffect(() => {
    if (data) {
      setApiData({
        ...data,
      });
    }
  }, [data]);

  const apiRequest = usePost(
    `${process.env.REACT_APP_STORE_URL}/priority-tile-videos`,
    "requestPriorityVideo"
  );

  React.useEffect(() => {
    if (apiRequest?.isSuccess) {
      setIsLoading(false);
      toast.success("Requested priority tile video successfully!!!");
      apiRequest?.reset();
      handleClose();
    }
  }, [apiRequest]);

  const handleApiCall = () => {
    if (openAlertDialog) handleAlertDialogClose();
    const { entryTime, checkoutTime } = apiData2 || {};
    console.log("🚀 ~ handleApiCall ~ apiData2:", apiData2);

    setIsLoading(true);

    const tempData = {
      entryTime: moment(data?.entryTime).toISOString(),
      checkoutTime: moment(data?.checkoutTime).toISOString(),
      retailStoreCode: data?.storeCode,
      cartRef: data?.cartRef,
      additionalRacks: null,
      priorityTileVideo: {
        entryTime: entryTime === null ? null : moment(entryTime).toISOString(),
        checkoutTime:
          checkoutTime === null ? null : moment(checkoutTime).toISOString(),
      },
    };

    apiRequest.mutate(tempData);
  };

  const handleApiCallValidation = async () => {
    const { entryTime, checkoutTime } = apiData || {};
    const { entryTime: entryTime2, checkoutTime: exitTime2 } = apiData2 || {};
    const isValid = isTimeDifferenceLessThan30Minutes(
      entryTime2 && entryTime2 !== null ? entryTime2 : entryTime,
      exitTime2 && exitTime2 !== null ? exitTime2 : checkoutTime
    );
    if (isValid?.isApiCall) {
      handleApiCall();
    } else {
      handleAlertDialogOpen();
    }
  };

  const handleDefaultValue = () => {
    if (data) dayjs(data?.entryTime);

    return dayjs();
  };

  return (
    <React.Fragment>
      {isLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
          //   onClick={() => setIsLoading(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        width="xl"
      >
        <DialogTitle
          sx={{ m: 0, p: 2, fontWeight: 600, textAlign: "center" }}
          id="customized-dialog-title"
        >
          Request Priority Videos
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography gutterBottom width="35%" fontWeight={600}>
              Entry Timestamp
            </Typography>
            {/* <div style={customStyles}> */}
            <DatePicker
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentNode;
              }}
              showTime
              name="entryTime"
              value={dayjs(apiData?.entryTime)}
              defaultValue={dayjs(data?.entryTime)}
              onChange={(value, dateString) => {
                setApiData((prevData) => ({
                  ...prevData,
                  entryTime: dateString,
                }));
                setApiData2((prevData) => ({
                  ...prevData,
                  entryTime: dateString,
                }));
              }}
              //   onOk={onOk}
              style={{ width: "100%" }}
            />
            {/* </div> */}
          </Box>
          <Box
            display="flex"
            mt={4}
            justifyContent="center"
            alignItems="center"
          >
            <Typography gutterBottom width="35%" fontWeight={600}>
              Exit Timestamp
            </Typography>
            {/* <div style={customStyles}> */}
            <DatePicker
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentNode;
              }}
              showTime
              name="checkoutTime"
              value={dayjs(apiData?.checkoutTime)}
              defaultValue={dayjs(data?.checkoutTime)}
              onChange={(value, dateString) => {
                setApiData((prevData) => ({
                  ...prevData,
                  checkoutTime: dateString,
                }));
                setApiData2((prevData) => ({
                  ...prevData,
                  checkoutTime: dateString,
                }));
              }}
              //   onOk={onOk}
              style={{ width: "100%" }}
              className=""
            />
            {/* </div> */}
          </Box>
          <Box
            display="flex"
            mt={4}
            justifyContent="center"
            alignItems="center"
          >
            <Typography gutterBottom width="35%" fontWeight={600}>
              Cart reference
            </Typography>
            <Typography gutterBottom width="100%" fontWeight={600}>
              {data?.cartRef}
            </Typography>
          </Box>
          <Box
            display="flex"
            mt={4}
            justifyContent="center"
            alignItems="center"
          >
            <Typography gutterBottom width="35%" fontWeight={600}>
              Store code
            </Typography>
            <Typography gutterBottom width="100%" fontWeight={600}>
              {data?.storeCode}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 3,
          }}
        >
          <Button
            autoFocus
            variant="contained"
            color="success"
            onClick={handleApiCallValidation}
          >
            Request Priority Videos
          </Button>
        </DialogActions>
      </BootstrapDialog>
      {openAlertDialog && (
        <AlertDialog
          open={openAlertDialog}
          setOpen={setOpenalertDialog}
          handleOpen={handleAlertDialogOpen}
          handleClose={handleAlertDialogClose}
          timeDiff={
            isTimeDifferenceLessThan30Minutes(
              apiData2?.entryTime && apiData2?.entryTime !== null
                ? apiData2?.entryTime
                : apiData?.entryTime,
              apiData2?.checkoutTime && apiData2?.checkoutTime !== null
                ? apiData2?.checkoutTime
                : apiData?.checkoutTime
            )?.timeDiff
          }
          handleApiCall={handleApiCall}
        />
      )}
    </React.Fragment>
  );
}
