import React from 'react'

function ScrollImg({ img, index }) {
    return (

        <div
            key={index}
            className={`mx-3`}>
            <img src={img?.image} className='w-16 h-16 rounded'/>
        </div>

    )
}

export default ScrollImg
