import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  DialogActions,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { usePost } from "../../../../../../api/usePost";
import { useGet } from "../../../../../../api/useGet";
import { toast } from "react-toastify";

function SendAlertModal() {
  const [open, setOpen] = useState(false);

  const [selectedTemplate, setSelectedTemplate] = useState([]);
  console.log("🚀 ~ SendAlertModal ~ selectedTemplate:", selectedTemplate);

  const [formData, setFormData] = useState({
    event: "",
    rootOrgId: "",
    params: {},
  });
  console.log("🚀 ~ SendAlertModal ~ formData:", formData);

  const getNotificationTemplates = useGet(
    `${process.env.REACT_APP_BASE_STORE_MANAGEMENT_URL}/notification-service/api/configuration`,
    "createNotificationTemplate"
  );

  const handleResetForm = () => {
    setFormData({
      event: "",
      rootOrgId: "",
      params: {},
    });
    setSelectedTemplate([]);
  };

  const handleClickOpen = () => {
    handleResetForm();
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target || {};

    if (event.target.name === "event") {
      const selectedItem = getNotificationTemplates?.data?.find(
        (item, index) => item.template === event.target.value
      );
      if (
        selectedItem?.params?.placeHolders?.split(",") &&
        selectedItem?.params?.placeHolders?.split(",")?.length > 0
      ) {
        setSelectedTemplate(selectedItem);
      } else {
        setSelectedTemplate([]);
      }
      setFormData((prev) => ({
        ...prev,
        params: {},
      }));
    }

    if (name.startsWith("params.")) {
      // For fields that are part of `params`
      const paramName = name.split(".")[1]; // Extract the actual param name
      setFormData((prevState) => ({
        ...prevState,
        params: {
          ...prevState.params,
          [paramName]: value,
        },
      }));
    } else {
      // For top-level fields (event and templateName)
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const sendAlerts = usePost(
    `${process.env.REACT_APP_BASE_STORE_MANAGEMENT_URL}/notification-service/api/notification/send`,
    "createNotificationTemplate"
  );

  const { data: orgs } = useGet(
    `${process.env.REACT_APP_BASE_URL}/orgs?sort=createdDate,desc`,
    "orgs"
  );

  useEffect(() => {
    if (sendAlerts?.isSuccess) {
      toast.success("Sended Alert Successfully!!!!!");
      sendAlerts?.reset();
      handleClose();
    }
  }, [sendAlerts]);

  const handleSubmit = () => {
    const tempData = {
      event: formData?.event,
      rootOrgId: formData?.rootOrgId,
      params: {
        ...formData?.params,
      },
    };

    sendAlerts.mutate(tempData);
  };

  useEffect(() => {
    selectedTemplate?.params?.placeHolders
      ?.split(",")
      ?.forEach((placeholder) => {
        setFormData((prev) => ({
          ...prev,
          params: {
            ...prev.params,
            [placeholder]: "",
          },
        }));
      });
  }, [selectedTemplate]);

  function checkEmptyValues(data) {
    if (Array.isArray(data)) {
      // If it's an array, check each element
      return data.some((item) => checkEmptyValues(item));
    } else if (typeof data === "object" && data !== null) {
      // If it's an object, check each property
      return Object.values(data).some((value) => checkEmptyValues(value));
    } else {
      // Base case: check if the data is an empty string
      return data === "";
    }
  }

  const isEmptryDormData = checkEmptyValues(formData);

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Send Alert
      </Button>
      {open && (
        <Dialog open={open} onClose={handleClose} fullWidth>
          <DialogTitle textAlign="center">Send Alert</DialogTitle>
          <DialogContent>
            <FormControl fullWidth margin="normal">
              <InputLabel id="template-name-label">Select Merchant</InputLabel>
              <Select
                required
                labelId="template-name-label"
                value={orgs?.find((item) => item?.id === formData?.rootOrgId)}
                label="Select Merchant"
                name="rootOrgId"
                onChange={handleChange}
              >
                {orgs?.map((item) => (
                  <MenuItem value={item?.id}>{item?.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel id="template-name-label">Template Name</InputLabel>
              <Select
                required
                labelId="template-name-label"
                value={formData.event}
                label="Template Name"
                name="event"
                onChange={handleChange}
              >
                {/* Replace with actual options */}
                {getNotificationTemplates?.data?.flatMap((item) =>
                  item?.rootOrgId === formData?.rootOrgId ? (
                    <MenuItem value={item?.template}>{item?.template}</MenuItem>
                  ) : (
                    []
                  )
                )}
              </Select>
            </FormControl>
            {selectedTemplate?.params?.placeHolders
              ?.split(",")
              ?.map((placeholder) => (
                <TextField
                  fullWidth
                  // required
                  margin="normal"
                  label={placeholder}
                  name={`params.${placeholder}`}
                  value={formData?.params?.[placeholder]}
                  onChange={handleChange}
                />
              ))}
            {/* <TextField
            fullWidth
            margin="normal"
            label="Incident Time"
            type="time"
            name="incidentTime"
            value={formData.incidentTime}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Rack Info"
            name="rackInfo"
            value={formData.rackInfo}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Store Number"
            name="storeNumber"
            value={formData.storeNumber}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Video Link"
            name="videoLink"
            value={formData.videoLink}
            onChange={handleChange}
          /> */}
          </DialogContent>
          <DialogActions
            sx={{
              p: "20px 24px",
              pt: 0,
              // display: "flex",
              // justifyContent: "space-between",
              // alignItems: "center",
            }}
          >
            <div>
              {/* <Button sx={{ mr: 1 }} onClick={handleResetForm}>
              Reset
            </Button> */}
              <Button onClick={handleClose}>Cancel</Button>
            </div>
            <Button
              sx={{ mr: 1 }}
              color="primary"
              variant="contained"
              onClick={handleSubmit}
              disabled={isEmptryDormData}
            >
              Send Alert
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

export default SendAlertModal;
