import React, { useState, useEffect } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useHistory, useLocation } from "react-router-dom";
import { FaStop, FaEdit, FaCopy, FaShareAlt, FaFileCsv } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { GrPowerReset } from "react-icons/gr";
import Toggle from "react-toggle";
import Confirm from "./Confirm";

function EmployeeView() {
  const [status, setStatus] = useState(false);

  const statusChecked = (e) => {
    setStatus(e.target.checked);
  };
  const location = useLocation();
  const history = useHistory();

  const data = location?.state?.data;
  const initialValues = location?.state?.initialValues;
  //console.log(data);

  const edit = () => {
    const initialValues = location?.state?.initialValues;
    history.push({
      pathname: "/admin/merchant/dashboard/employees/edit",
      //search: "?query=abc",-
      state: { data: data, initialValues: initialValues },
    });
  };

  return (
    <>
      <div className="w-full h-screen flex text-left select-none">
        <div className="w-full h-full flex flex-col justify-center items-center bg-gray-200">
          <div className="w-full h-12 flex bg-gray-100  items-center">
            <BiArrowBack
              onClick={() => history.goBack()}
              className="h-6 w-6 cursor-pointer ml-5"
            />
            {/* <h2 className="font-bold px-6 text-xl">{data?.name}</h2> */}
          </div>

          <div className="w-full h-full flex p-2">
            <div className="w-7/10 h-full bg-white rounded-lg p-2">
              <div className="w-full border-b-2">
                <h2 className="text-black text-bold text-lg select-none">
                  Information
                </h2>
              </div>

              <div className="flex h-20 mt-1 flex-wrap space-y-1 items-center select-none ">
                <button
                  onClick={edit}
                  className="focus:outline-none bg-gray-200 border-2 border-black ml-1 hover:bg-grey text-grey-darkest font-bold py-1 px-4 rounded inline-flex items-center"
                >
                  <FaEdit className="w-4 h-4" />
                  <span className="mx-2">Edit</span>
                </button>

                <Confirm />
              </div>

              <div className="h-3/4 mt-3 select-none scrollbar scrollbar-thumb-gray-900 scrollbar-track-gray-100">
                <div className="border-t-2 border-gray-200 py-1 px-4">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="">Name</div>
                    <div className="">{`${data?.firstName} ${data?.lastName}`}</div>
                  </div>
                </div>

                <div className="border-t-2 border-gray-200 py-1 px-4">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="">Email</div>
                    <div className="">{data?.email}</div>
                  </div>
                </div>

                <h2 className="font-bold mt-2 ml-4 text-base">User Info: </h2>

                {data?.userInfo?.gender ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">Gender</div>
                      <div className="">{data?.userInfo?.gender}</div>
                    </div>
                  </div>
                ) : null}

                {data?.userInfo?.nickname ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">Nickname</div>
                      <div className="">{data?.userInfo?.nickname}</div>
                    </div>
                  </div>
                ) : null}

                {data?.userInfo?.phone ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">Phone</div>
                      <div className="">{data?.userInfo?.phone}</div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="w-3/10 h-full ml-2 bg-white rounded-lg p-2">
              <div className="w-full border-b-2">
                <h2 className="text-black text-bold text-lg">Log</h2>
              </div>

              <div className="flex"></div>

              <div></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmployeeView;
