import React, { useState, useMemo } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TextField2 } from "../../../validation/TextField2";
import { useHistory } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import countryList from "react-select-country-list";
import Select from "react-select";
import { useAlert } from "react-alert";
import { usePost } from "../../../../api/usePost";
import { useGet } from "../../../../api/useGet";
import { useLocation } from "react-router-dom";

let validate;
validate = Yup.object({
  name: Yup.string().required("Required"),
  email: Yup.string().email(),
  internalCode: Yup.string(),
  zip: Yup.number().typeError("Not a number").required("Required"),
  street1: Yup.string().required("Required"),
  street2: Yup.string(),
  city: Yup.string().required("Required"),
  administrativeArea: Yup.string(),
  latitude: Yup.number().typeError("Not a number"),
  longitude: Yup.number().typeError("Not a number"),
  state: Yup.string().required("Required"),
  contactFirstName: Yup.string(),
  contactLastName: Yup.string(),
  contactNo: Yup.number(),
});

const countryLists = [
  { label: "Germany", value: "DE" },
  { label: "Hungary", value: "HU" },
  { label: "France", value: "FR" },
];

function CreateLocation() {
  const [title, setTitle] = useState("Location");
  const [addressType, setAdressType] = useState();
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();

  //console.log("mer", location?.state?.orgList);

  const [country, setCountry] = useState("");
  const [orgId, setOrgId] = useState("");
  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (value) => {
    setCountry(value);
  };

  const changeHandlerOrg = (value) => {
    setOrgId(value);
  };

  const { data: orgList } = useGet(
    `${process.env.REACT_APP_BASE_URL}/orgs?sort=createdDate,desc`,
    "merchantList"
  );

  const createRetailer = usePost(
    `${process.env.REACT_APP_BASE_URL}/retail-stores`,
    "retailerList"
  );

  return (
    <>
      <div className="w-full h_screen flex select-none">
        <div className="w-full h-full flex justify-center items-center bg-gray-200 ">
          <Formik
            initialValues={{
              name: "",
              email: "",
              internalCode: "",
              street1: "",
              street2: "",
              city: "",
              administrativeArea: "",
              latitude: "",
              longitude: "",
              state: "",
              zip: "",
              contactFirstName: "",
              contactLastName: "",
              contactNo: "",
            }}
            validationSchema={validate}
            onSubmit={async (values) => {
              //console.log(values);
              if (values.name !== "") {
                try {
                  const data = {
                    internalCode: values.internalCode,
                    name: values.name,
                    email: values.email,
                    rootOrgId: orgId?.value,
                    address: {
                      zipCode: values.zip,
                      street1: values.street1,
                      street2: values.street2,
                      cityOrVillage: values.city,
                      administrativeArea1: values.administrativeArea,
                      state: values.state,
                      country: country.value,
                      lat: parseFloat(values.latitude),
                      lng: parseFloat(values.longitude),
                      addressType: addressType ? "PRIMARY" : "OTHER",
                      contactPersonName: values.contactFirstName,
                      contactPersonLastName: values.contactLastName,
                      contactPersonPhone: values.contactNo,
                    },
                  };

                  //console.log("data: ", data)
                  createRetailer.mutate(data);

                  if (createRetailer.isError === false) {
                    history.goBack();
                    alert.success(
                      <div style={{ textTransform: "none" }}>
                        Successfully Added!
                      </div>
                    );
                  } else {
                    alert.error(
                      <div style={{ textTransform: "none" }}>
                        Please Check all Fields!
                      </div>
                    );
                  }
                } catch (error) {
                  alert.error(
                    <div style={{ textTransform: "none" }}>
                      you are offline!
                    </div>
                  );
                }
              }
            }}
          >
            {() => (
              <Form className="w-11/12 mx-auto h-auto bg-white rounded-lg shadow-lg select-none">
                <div className="w-full h-16 flex bg-nav rounded-t-lg text-white justify-start items-center">
                  <div className="w-10 p-2 h-full flex items-center ml-8">
                    <BiArrowBack
                      onClick={() => history.goBack()}
                      className="h-10 w-10 cursor-pointer"
                    />
                  </div>

                  <h2 className="font-bold text-2xl ml-4">
                    {`Create new ${title}`}
                  </h2>
                </div>

                <div className="w-11/12 mx-auto px-6 my-2">
                  <div className="w-full h-20 flex mt-4  items-center">
                    <div className="flex  px-4 mr-4   w-full items-center select-none">
                      <TextField2 label="Name" name="name" type="text" />
                    </div>

                    <div className="flex  px-4 ml-4   w-full items-center select-none">
                      <TextField2 label="Email" name="email" type="email" />
                    </div>

                    <div className="flex  px-4 ml-4 w-full items-center select-none">
                      <div style={{ width: "300px" }}>
                        <Select
                          placeholder="Select Merchant ..."
                          maxMenuHeight={162}
                          value={orgId}
                          onChange={changeHandlerOrg}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          options={orgList?.map((item, index) => {
                            return {
                              label: `${item.name} ${
                                item.phone ? " " + `( ${item.phone} )` : ""
                              }`,
                              value: item.id,
                              key: index,
                            };
                          })}
                        />
                      </div>
                    </div>
                  </div>

                  <h2 className="px-5 my-2 text-left">Address : </h2>

                  <div className="w-full h-20 flex mt-2  items-center">
                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2 label="Street 1" name="street1" type="text" />
                    </div>

                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2 label="Street 2" name="street2" type="text" />
                    </div>

                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2
                        label="City Or Village"
                        name="city"
                        type="text"
                      />
                    </div>

                    <div className="flex  px-4   w-full items-center select-none">
                      <TextField2
                        label="Administrative Area"
                        name="administrativeArea"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="w-full h-20 flex  items-center">
                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2
                        label="Latitude"
                        name="latitude"
                        type="text"
                      />
                    </div>

                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2
                        label="Longitude"
                        name="longitude"
                        type="text"
                      />
                    </div>

                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2 label="State" name="state" type="text" />
                    </div>

                    <div className="flex  px-4 w-full items-center select-none">
                      <div style={{ width: "222px" }}>
                        <Select
                          options={countryLists}
                          placeholder="Select country"
                          maxMenuHeight={162}
                          value={country}
                          onChange={changeHandler}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="w-full h-20 flex items-center">
                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2 label="Zip Code" name="zip" type="text" />
                    </div>

                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2
                        label="Contact First Name"
                        name="contactFirstName"
                        type="text"
                      />
                    </div>

                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2
                        label="Contact Last Name"
                        name="contactLastName"
                        type="text"
                      />
                    </div>

                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2
                        label="contact Number"
                        name="contactNo"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="w-full h-16 flex justify-between items-center px-4 -mt-2">
                    <div className="flex items-center h-6">
                      <input
                        type="checkbox"
                        onChange={(e) => setAdressType(e.target.checked)}
                        class="form-checkbox h-5 w-5 text-green-600"
                      />
                      <span className="mx-1 -mt-0.5 px-2 ">
                        Save as Primary Address
                      </span>
                    </div>

                    <button
                      type="submit"
                      className="bg-green-500 rounded-lg px-6 py-3  focus:outline-none text-white"
                    >
                      <span className="h-full flex items-center">
                        <svg
                          class="svg-icon"
                          viewBox="0 0 20 20"
                          width="24px"
                          height="24px"
                        >
                          <path
                            fill="#8A2BE2"
                            d="M19.404,6.65l-5.998-5.996c-0.292-0.292-0.765-0.292-1.056,0l-2.22,2.22l-8.311,8.313l-0.003,0.001v0.003l-0.161,0.161c-0.114,0.112-0.187,0.258-0.21,0.417l-1.059,7.051c-0.035,0.233,0.044,0.47,0.21,0.639c0.143,0.14,0.333,0.219,0.528,0.219c0.038,0,0.073-0.003,0.111-0.009l7.054-1.055c0.158-0.025,0.306-0.098,0.417-0.211l8.478-8.476l2.22-2.22C19.695,7.414,19.695,6.941,19.404,6.65z M8.341,16.656l-0.989-0.99l7.258-7.258l0.989,0.99L8.341,16.656z M2.332,15.919l0.411-2.748l4.143,4.143l-2.748,0.41L2.332,15.919z M13.554,7.351L6.296,14.61l-0.849-0.848l7.259-7.258l0.423,0.424L13.554,7.351zM10.658,4.457l0.992,0.99l-7.259,7.258L3.4,11.715L10.658,4.457z M16.656,8.342l-1.517-1.517V6.823h-0.003l-0.951-0.951l-2.471-2.471l1.164-1.164l4.942,4.94L16.656,8.342z"
                          ></path>
                        </svg>
                        <span className="px-2">Create {title}</span>
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default CreateLocation;
