import React, { useState } from "react";

import { Link, useHistory } from "react-router-dom";

import { useGet } from "../../../../../../../../api/useGet";

import axios from "axios";

function DashboardRoute({ params }) {
  const history = useHistory();

  const fetchInitialValue = (data) => {
    let initialValues = {};

    data?.productCategories?.map((item) => {
      initialValues = {
        barCode: params?.data?.barCode ? params?.data?.barCode : "",
        name: params?.data?.name ? params?.data?.name : "",
        packageType: params?.data?.packageType ? params?.data?.packageType : "",

        hsnCode: params?.data?.hsnCode ? params?.data?.hsnCode : "",
        description: params?.data?.description ? params?.data?.description : "",

        sellPrice: params?.data?.sellPrice ? params?.data?.sellPrice : "",
        purchasePrice: params?.data?.purchasePrice
          ? params?.data?.purchasePrice
          : "",

        vegMark: params?.data?.vegMark ? params?.data?.vegMark : null,
        status: params?.data?.status ? params?.data?.status : "",

        title: params?.data?.title ? params?.data?.title : "",
        unitWeight: params?.data?.unitWeight ? params?.data?.unitWeight : "",
        category: item?.category ? item?.category : null,
        supplier: params?.data?.supplier?.name
          ? params?.data?.supplier?.name
          : null,

        packageType: params?.data?.packageType
          ? params?.data?.packageType
          : null,
        unitOfMeasure: params?.data?.unitOfMeasure
          ? params?.data?.unitOfMeasure
          : null,
        supplier: params?.data?.supplier?.name
          ? params?.data?.supplier?.name
          : null,

        taxCategoryType: params?.data?.taxCategoryType
          ? params?.data?.taxCategoryType
          : null,
        taxCategoryValue: params?.data?.taxCategoryValue
          ? params?.data?.taxCategoryValue
          : null,
        brand: params?.data?.brand?.name ? params?.data?.brand?.name : null,
      };
    });
    return initialValues;
  };

  const Clicked = async () => {
    const res = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}/products/${params?.data?.productId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
    });

    console.log("res: ", res?.data);

    history.push({
      pathname: `/admin/merchant/dashboard/productManagement/product/view`,
      state: { data: res?.data, initialValues: fetchInitialValue(res?.data) },
    });
  };

  return (
    <>
      <Link
        //to="/admin/merchant/dashboard"
        onClick={Clicked}
        className="text-blue-900 font-bold"
      >
        {params.value}
      </Link>
    </>
  );
}

export default DashboardRoute;
