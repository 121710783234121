import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { useGet } from "../../../../../../../../api/useGet";
import { toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";
import { FaEdit } from "react-icons/fa";

function DynamicPriceView() {
  const {
    state: { data: lData },
  } = useLocation();
  const history = useHistory();

  const [data, setData] = useState();

  const { data: configViewData } = useGet(
    `${process.env.REACT_APP_BASE_URL}/price-engine-configurations/${lData?.id}`,
    `dynamicPriceConfigDetail`
  );

  useEffect(() => {
    setData(configViewData);
  }, [configViewData]);

  const edit = () => {
    history.push({
      pathname: "/admin/merchant/dashboard/productManagement/dynamicPrice/edit",
      state: { data: data },
    });
  };

  return (
    <div>
      {data ? (
        <div className="w-full h-screen text-left select-none flex">
          <div className="w-full h-full flex flex-col justify-center items-center bg-gray-200">
            <div className="w-full h-12 flex bg-gray-100  items-center">
              <BiArrowBack
                onClick={() => history.goBack()}
                className="h-6 w-6 cursor-pointer ml-5"
              />
            </div>
            <div className="w-full h_dasView flex p-2">
              <div className="w-7/10 h-full bg-white rounded-lg p-2.5">
                <div className="w-full border-b-2">
                  <h2 className="text-black text-bold text-lg select-none">
                    Information
                  </h2>
                </div>
                <div className="w-full flex justify-between">
                  <div className="w-48 flex h-20 mt-1 items-center select-none ">
                    <button
                      onClick={edit}
                      className="focus:outline-none bg-gray-200 border-2 border-black ml-1 hover:bg-grey text-grey-darkest font-bold py-1 px-4 rounded inline-flex items-center"
                    >
                      <FaEdit className="w-4 h-4" />
                      <span className="mx-2">Edit</span>
                    </button>
                  </div>
                </div>
                <div className="h-3/4 mt-3  scrollbar-thin scrollbar-thumb-gray-900  scrollbar-track-gray-100">
                  <div className="border-t-2 border-gray-200 py-4 px-4">
                    <div className="grid grid-cols-4 gap-4">
                      <div className="font-bold">Campaign Name</div>
                      <div>{data?.campaignName}</div>
                      <div className="font-bold">Campaign Type</div>
                      <div>{data?.campaignType}</div>
                    </div>
                  </div>
                  <div className="border-t-2 border-gray-200 py-5 px-4">
                    <div className="grid grid-cols-4 gap-2">
                      <div className="font-bold">Percentage</div>
                      <div>{data?.discountPercent}</div>
                    </div>
                  </div>
                  <div className="border-t-2 border-gray-200 py-5 px-4">
                    <div className="grid grid-cols-4 gap-2">
                      <div className="font-bold">Product Name</div>
                      <div>{data?.productName}</div>
                      <div className="font-bold">BarCode</div>
                      <div>{data?.barCode}</div>
                    </div>
                  </div>
                  <div className="border-t-2 border-gray-200 py-5 px-4">
                    <div className="grid grid-cols-4 gap-2">
                      <div className="font-bold">Start Date</div>
                      <div>{data?.startDate}</div>
                      <div className="font-bold">End Date</div>
                      <div>{data?.endDate}</div>
                    </div>
                  </div>
                  <div className="border-t-2 border-gray-200 py-5 px-4">
                    <span className="font-bold">Dynamic Price Schedules</span>
                    {data?.priceEngineSchedules.map((schedule, i) => (
                      <div className="grid grid-cols-4 gap-2 mt-2" key={i}>
                        <div className="font-bold">Start Time</div>
                        <div>{schedule?.startTime}</div>
                        <div className="font-bold">End Time</div>
                        <div>{schedule?.endTime}</div>
                      </div>
                    ))}
                  </div>
                  <div className="border-t-2 border-gray-200 py-5 px-4">
                    <span className="font-bold">Applicable Days</span>
                    <div className="grid grid-cols-4 gap-2 mt-2">
                      {data?.priceEngineSchedules.length > 0 ? (
                        data?.priceEngineSchedules[0].applicableDays.map(
                          (day, i) => <span key={i}>{day}</span>
                        )
                      ) : (
                        <div>-</div>
                      )}
                    </div>
                    {/* {data?.priceEngineSchedules.length > 0
                                                ? data?.priceEngineSchedules[0]?.applicableDays.map((day, i) => (
                                                    <div className="grid grid-cols-4 gap-2 mt-2" key={i}>
                                                        <span>{day}</span>
                                                    </div>
                                                ))
                                                : (
                                                    <div>
                                                        -
                                                    </div>
                                                )
                                            } */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-full flex justify-center items-center">
          <ClipLoader size={150} />
        </div>
      )}
    </div>
  );
}

export default DynamicPriceView;
