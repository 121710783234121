import React from "react";
import { IoIosClose } from "react-icons/io";

function ModalImg({ open, onCloseModal, img }) {
  return (
    <>
      {open && (
        <div className="w-72 h-72  text-left select-none z-10 absolute top-1/2 left-1/2 mt-10  transform -translate-x-1/2 -translate-y-1/2 ">
          <div className="w-full  bg-gray-100 border-2 border-dash rounded-lg shadow-lg ">
            <div className="h-8 w-8 cursor-pointer absolute -right-2 shadow-lg -top-2 rounded-full bg-gray-200">
              <IoIosClose onClick={onCloseModal} className="h-8 w-8 " />
            </div>
            <div className="w-full h-full border-2 border-gray-200 rounded-lg">
              <img
                src={img}
                alt="Product img"
                className="w-96 h-96 rounded-lg"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ModalImg;
