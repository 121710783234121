import React from "react";
import { useField } from "formik";

//import {TextareaAutosize} from "@material-ui/core";


export const TextArea = ({ label, errors, touched, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="w-full flex flex-col justify-center border border-gray-200">
      <textarea
        //error={meta.touched && meta.error ? meta.error : ""}
        style={{height:"3.3rem", padding:"5px", borderRadius: '5px'}}
        className="focus:outline-none"
        placeholder={label}
        variant="outlined"
        {...field}
        {...props}
        autoComplete="off"
      />

      {/* {meta.touched && meta.error ? (
        <p className="w-full text-right text-red-500">{meta.error}</p>
      ) : null} */}
    </div>
  );
};
