import React from "react";
import { GrView } from "react-icons/gr";
import { FiEdit } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { useGet } from "../../../../../../api/useGet";
import axios from "axios";
import { toast } from "react-toastify";

function Action({ params, url, viewUrl, editUrl }) {
  const history = useHistory();

  const retailerList = useGet(
    `${process.env.REACT_APP_BASE_URL}/retail-stores?page=0&sort=createdDate`,
    "retailerIdList"
  );

  const fetchMerchant = async (url) => {
    try {
      const res = await axios({
        method: "GET",
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      });

      return res;
    } catch ({ message }) {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message);
      }
    }
  };

  const fetchInitialValue = () => {
    let initialValues = {};

    initialValues = {
      deviceIdentifier: params?.data?.deviceIdentifier,
      name: params?.data?.name,
      qrCode: params?.data?.qrCode,
      macAddress: params?.data?.macAddress,
      mode: params?.data?.mode,
      retailStore: params?.data?.retailStore,
      status: params?.data?.status,
    };
    return initialValues;
  };

  const edit = async () => {
    console.log("params: ", params);
    console.log("url: ", editUrl);

    const initialValues = fetchInitialValue();
    const res = await fetchMerchant(
      `${process.env.REACT_APP_BASE_URL}/machines/${params?.data?.id}`
    );
    const merchant = await fetchMerchant(
      `${process.env.REACT_APP_BASE_URL}/orgs/${params?.data?.rootOrgId}`
    );

    history.push({
      pathname: editUrl,
      state: {
        data: params.data,
        machine: res?.data,
        retailerList: retailerList ? retailerList : null,
        initialValues: initialValues,
        merchant: merchant?.data,
      },
    });
  };

  const view = async () => {
    const initialValues = fetchInitialValue();

    const res = await fetchMerchant(
      `${process.env.REACT_APP_BASE_URL}/machines/${params?.data?.id}`
    );
    const merchant = await fetchMerchant(
      `${process.env.REACT_APP_BASE_URL}/orgs/${params?.data?.rootOrgId}`
    );

    history.push({
      pathname: viewUrl,
      state: {
        data: params.data,
        machine: res?.data,
        initialValues: initialValues,
        retailerList: retailerList ? retailerList : null,
        merchant: merchant?.data,
      },
    });
    // console.log("Id: ", res?.data)
  };

  return (
    <>
      <div className="flex w-16 h-10 items-center">
        <div className="border-2 border-gray-200 rounded-l-lg p-1 ">
          <GrView className="w-5 h-5 cursor-pointer " onClick={view} />
        </div>
        <div className="border-2 border-gray-200 rounded-r-lg p-1">
          <FiEdit className="w-5 h-5 cursor-pointer" onClick={edit} />
        </div>
      </div>
    </>
  );
}

export default Action;
