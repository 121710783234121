import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import { useGet } from "../../../../../../../api/useGet";
import { FiEdit } from "react-icons/fi";
import { GrView } from "react-icons/gr";

const NotificationTemplateTable = () => {
  const history = useHistory();

  const { rootOrgId, name: merchantName } =
    JSON.parse(localStorage.getItem("dashboard-data") || "") || {};

  const getNotificationTemplates = useGet(
    `${process.env.REACT_APP_BASE_STORE_MANAGEMENT_URL}/notification-service/api/configuration`,
    "createNotificationTemplate"
  );

  const redirectToViewemplate = (data) => {
    history.push({
      pathname: "/admin/merchant/dashboard/notifications/view-template",
      state: { data: data?.data },
    });
  };

  const redirectToUpdateTemplate = (data) => {
    history.push({
      pathname: "/admin/merchant/dashboard/notifications/update-template",
      state: { data: data?.data },
    });
  };

  console.log("getNotificationTemplates", getNotificationTemplates);

  return (
    <div>
      <div className="ag-theme-alpine w-full h-full text-left">
        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 80,
            resizable: true,
          }}
          suppressRowClickSelection={true}
          pagination={false}
          paginationPageSize={8}
          rowData={
            getNotificationTemplates?.data?.filter(
              (item) => item?.rootOrgId === rootOrgId
            ) || []
          }
          domLayout="autoHeight"
          rowHeight={50}
        >
          <AgGridColumn
            headerName=" Template Name"
            field="template"
            minWidth={150}
          />

          {/* <AgGridColumn
            field="orderTotal"
            headerName={`Total`}
            minWidth={90}
            maxWidth={90}
            cellStyle={{ paddingRight: 10 }}
            cellRendererFramework={(params) => {
              return (
                <div key={params?.data?.id}>
                  <p className="text-left w-[50px]">
                    {params?.data?.formattedEffectiveOrderTotalCurrency}
                  </p>
                </div>
              );
            }}
          /> */}

          <AgGridColumn
            headerName="Action"
            minWidth={90}
            // sortable={true}
            cellRendererFramework={(params) => (
              <div className="flex h-10 items-center">
                <div className="border-2 border-gray-200 rounded-lg py-1 px-1 mx-1 ">
                  <GrView
                    className="w-5 h-5 cursor-pointer"
                    onClick={() => redirectToViewemplate(params)}
                  />
                </div>

                <div className="border-2 border-gray-200 rounded-r-lg p-1">
                  <FiEdit
                    className="w-5 h-5 cursor-pointer"
                    onClick={() => redirectToUpdateTemplate(params)}
                  />
                </div>
              </div>
            )}
          />
        </AgGridReact>
      </div>
    </div>
  );
};

export default NotificationTemplateTable;
