import React from "react";


function DashboardRoute({ params, name }) {
  const clicked = () => {
    localStorage.setItem("exitData", JSON.stringify(params.data));
  };

  return (
    <>
      <span
        className="font-bold"
      >
        <p className={params?.data?.[name] === 'Gate Event' ? "list_font_style" : ''}>{params?.data?.[name]}</p>
      </span>
    </>
  );
}

export default DashboardRoute;
