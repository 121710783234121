import React from "react";
import { Disclosure } from "@headlessui/react";

const DisclosureCmp = ({
  allScales,
  onSelectRow,
  selectRow,
  input,
  setSelectData,
}) => {
  console.log("allScales", allScales);
  return (
    <div className="w-full space-y-1">
      {input?.name.length > 0 || input?.barcode?.length > 0 ? (
        <div className="w-full text-sm text-gray-500 border-6 rounded border-gray-100">
          <table className="w-full">
            <tbody className="text-sm divide-y divide-gray-400 text-left space-y-[1px]">
              {allScales?.map((product, index) => {
                console.log("999", allScales);
                return (
                  <>
                    <tr
                      onClick={() => {
                        setSelectData(product);
                        onSelectRow(product);
                      }}
                      key={index}
                      className="w-full grid grid-cols-4 items-center px-2 cursor-pointer"
                    >
                      <td>
                        <input
                          type="checkbox"
                          className="w-6 h-6"
                          checked={product?.id === selectRow?.id}
                        />
                      </td>
                      <td className="w-[100px]">
                        <div className="flex flex-col space-y-1 justify-center items-center">
                          <img
                            src={product?.imageUrl || "/images/noImage.jpg"}
                            className="w-full h-[70px] rounded"
                          />
                        </div>
                      </td>

                      <td className="">
                        <div className="w-full flex  space-y-1 justify-start items-center font-medium text-gray-800">
                          <div
                            className="text-left text-[18px] font-bold"
                            key={index}
                          >
                            <p>{product?.name}</p>
                          </div>
                        </div>
                      </td>

                      <td className="w-full flex justify-center pl-12">
                        <div className="w-full flex  space-y-1 justify-start items-center font-medium text-gray-800">
                          <div
                            className="text-left text-[18px] font-bold"
                            key={index}
                          >
                            <p>{product?.barCode}</p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        allScales?.map((scale) => {
          return (
            <Disclosure defaultOpen={true}>
              {({ open }) => (
                <>
                  {open && scale?.productInfos?.length > 0 && (
                    <Disclosure.Panel
                      open={true}
                      className="text-sm text-gray-500 border-6 rounded border-gray-100"
                    >
                      <div className="w-full flex justify-between items-center">
                        <span className="">{`Scale No : ${scale?.scaleIdentifier}`}</span>
                        <span>{`product mapped : ${scale?.productInfos?.length}`}</span>
                      </div>
                      <table className="w-full">
                        <tbody className="text-sm divide-y divide-gray-100">
                          {scale?.productInfos?.map((product, index) => {
                            return (
                              <>
                                {product.isPrimary ? (
                                  <tr
                                    onClick={() => {
                                      setSelectData(scale);
                                      onSelectRow(product);
                                    }}
                                    key={index}
                                    className={`                 
                                  "bg-green-100"} 
                                  w-full flex justify-between items-center px-2 cursor-pointer
                                  `}
                                  >
                                    <td>
                                      <input
                                        type="checkbox"
                                        className="w-6 h-6"
                                        checked={
                                          product?.scaleProductId ===
                                          selectRow?.scaleProductId
                                        }
                                      />
                                    </td>
                                    {scale?.productInfos?.length > 1 && (
                                      <td>
                                        <p className="font-[500] text-black">
                                          {index + 1}
                                        </p>
                                      </td>
                                    )}
                                    <td className="">
                                      <div className="flex flex-col space-y-1 justify-center items-center">
                                        <img
                                          src={
                                            product?.imageUrl ||
                                            "/images/noImage.jpg"
                                          }
                                          alt=""
                                          className="w-full h-[70px] rounded"
                                        />
                                      </div>
                                    </td>

                                    <td className="w-[400px]">
                                      <div className="w-full flex flex-col space-y-1 justify-center items-center font-medium text-gray-800">
                                        <div
                                          className="text-center text-[18px] font-bold flex items-center justify-between"
                                          key={index}
                                        >
                                          <p>{product?.productName}</p>
                                          {product?.isStockClearanceDiscount ===
                                            true && (
                                            <div
                                              style={{
                                                padding: "6px",
                                                backgroundColor:
                                                  "rgba( 0, 102, 0, 0.21)",
                                                width: "150px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                borderRadius: "10px",
                                                marginTop: "6px",
                                                marginBottom: "10px",
                                                marginLeft: "10px",
                                              }}
                                            >
                                              <img
                                                src="/images/discountTag.svg"
                                                alt=""
                                                style={{ marginTop: "3px" }}
                                              />
                                              <p style={{ marginLeft: "5px" }}>
                                                Discounted
                                              </p>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </td>

                                    <td className="">
                                      <div className="w-full flex flex-col space-y-1 justify-center items-center font-medium text-gray-800">
                                        <div
                                          className="text-center text-[18px] font-bold"
                                          key={index}
                                        >
                                          <p>{product?.barCode}</p>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                ) : (
                                  <div className="w-full h-full flex justify-center items-center">
                                    <p>Not Mapped</p>
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </tbody>
                      </table>
                    </Disclosure.Panel>
                  )}
                </>
              )}
            </Disclosure>
          );
        })
      )}
    </div>
  );
};

export default DisclosureCmp;
