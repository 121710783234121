import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useGet } from "../../../../../api/useGet";

function TableStr({
  title,
  url,
  viewUrl,
  editUrl,
  listUrl,
  orgIdUrl,
  getOrgName,
  retailerIdUrl,
  getRetailerName,
  getName,
  Enabled,
  All,
  Disabled,
  reatilerList,
}) {
  const [click, setClicked] = useState("all");

  const { isLoading, error, data } = useGet(listUrl, getName);

  const options = (e) => {
    const type = e.target.name;
    switch (type) {
      case "all":
        setClicked(type);
        break;
      case "enabled":
        setClicked(type);
        break;
      case "disabled":
        setClicked(type);
        break;
      case "initialized":
        setClicked(type);

      case "notInitialized":
        setClicked(type);
      default:
    }
  };

  return (
    <div className="w-full h-full shadow-lg select-none">
      <div
        className={`w-full  h-12  flex justify-between p-6 items-center bg-gray-100`}
      >
        <div>
          <h2 className="font-bold text-2xl">{title}</h2>
        </div>
      </div>

      <div className="w-full h-10 p-2  -mt-2 ">
        <div className="h-10 w-full  flex justify-start ">
          <button
            onClick={options}
            name="all"
            className={`mx-4 border-b-2 ${
              click === "all" ? "border-blue-500" : null
            } focus:outline-none`}
          >
            All
          </button>

          <button
            onClick={options}
            name="enabled"
            className={`mx-4 border-b-2 ${
              click === "enabled" ? "border-blue-500" : null
            } focus:outline-none`}
          >
            Enabled
          </button>

          <button
            onClick={options}
            name="disabled"
            className={`mx-4 border-b-2 ${
              click === "disabled" ? "border-blue-500" : null
            } focus:outline-none`}
          >
            Disabled
          </button>
        </div>
      </div>

      {click === "enabled" ? (
        <Enabled />
      ) : click === "disabled" ? (
        <Disabled />
      ) : click === "all" ? (
        <All data={data} />
      ) : null}
    </div>
  );
}

export default TableStr;
