import React from "react";

function Address({ params }) {
  //console.log("pa1: ", params?.data?.add);
  return (
    <div>
      <h2>
        {`
        ${params?.data?.address?.street1 ? `${params?.data?.address?.street1},`:""}
        ${params?.data?.address?.street2 ? `${params?.data?.address?.street2},`:""} 
        ${params?.data?.address?.administrativeArea ? `${params?.data?.address?.administrativeArea},`:""}
        ${params?.data?.address?.city ? `${params?.data?.address?.city},`:""}
        ${params?.data?.address?.state ? `${params?.data?.address?.state},`:""}
        ${params?.data?.address?.country ? `${params?.data?.address?.country} - `:""} 
        ${params?.data?.address?.zipCode ? `${params?.data?.address?.zipCode}`:""}
      `}
      </h2>
    </div>
  );
}

export default Address;
