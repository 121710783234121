import React, { useState, useEffect } from "react";
import NoImage from "../../../../Images/noImage.jpg";
import {
  MdArrowUpward,
  MdArrowDownward,
  MdBackspace,
  MdAssignmentTurnedIn,
  MdOutlineAssignmentReturned,
} from "react-icons/md";
import { VscRefresh } from "react-icons/vsc";
import { confirmAlert } from "react-confirm-alert";
import { FaBackward, FaForward, FaFastForward } from "react-icons/fa";
import { getTimeOnly } from "../../../../logic/useGetTime";
import Select from "react-select";
import VideoSwapper from "../../../../components/VideoSwapper";
import axios from "axios";
import ReactHover, { Trigger, Hover } from "react-hover";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Tab } from "@headlessui/react";
import FadeLoader from "react-spinners/FadeLoader";
import moment from "moment";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import DisclosureCmp from "./DisclosureCmp";

const customStyles = {
  control: (base) => ({
    ...base,
    height: 50,
    minHeight: 35,
    fontWeight: "bold",
  }),
  container: (base) => ({
    ...base,
    height: 48,
  }),

  valueContainer: (base) => ({
    ...base,
    height: 48,
  }),
};

const findQty = (data) => {
  let qty = 0;

  if (data?.estimatedQuantity === data?.reviewedQuantity) {
    qty = data?.reviewedQuantity;
  } else if (data?.reviewedQuantity === 0) {
    qty = data?.estimatedQuantity;
  } else {
    qty = data?.reviewedQuantity;
  }

  return qty;
};

const MappingModal = ({ locationData, data, reload, setReload, isLoading }) => {
  const history = useHistory();
  const [allScales, setAllScales] = useState();
  const [selectedShopper, setSelectedShopper] = useState();
  const [selectedIndex, setSelectedIndex] = useState(1);

  const [reviewQty, setReviewQty] = useState(0);
  const [openData, setOpenData] = useState([]);

  const [item, setItem] = useState();
  const [shoppersData, setShoppersData] = useState([]);

  const [selectedRow, setSelectedRow] = useState();

  const onSelectRow = (data) => {
    setReviewQty(0);
    setSelectedRow(data);
  };

  //scale api
  const apiCall = (item) => {
    if (
      item &&
      item.scaleInternalCode !== "" &&
      item.scaleInternalCode !== null
    ) {
      //scale api
      const temp = item?.scaleInternalCode?.split("-");
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/scale-products/shelf?sort=column,asc&retailStoreCode.equals=${item?.storeCode}&shelfName.equals=${temp[0]}-${temp[1]}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      }).then(({ data }) => {
        setAllScales(data);
      });

      //users api call
      const entryTime = moment(item?.eventTime)
        .subtract(60, "minutes")
        .utc()
        .format();

      axios({
        method: "GET",
        url: `${process.env.REACT_APP_STORE_URL}/user-activities?entryTime.greaterThanOrEqual=${entryTime}&sort=entryTime,desc&size=10&page=0&isActive.equals=true`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      }).then((res) => {
        setShoppersData(res?.data);
      });
    }
  };

  //assign index from localStorage if available otherwise assign zero
  const [itemIndex, setItemIndex] = useState(
    localStorage.getItem("mIndex")
      ? parseInt(localStorage.getItem("mIndex"))
      : 0
  );

  //index decrease by 1
  const previous = () => {
    setSelectedShopper(null);
    if (itemIndex > 0) {
      setItemIndex(itemIndex - 1);
      localStorage.setItem("mIndex", parseInt(itemIndex - 1));
    }
  };

  //index increase by 1
  const next = () => {
    setSelectedShopper(null);
    if (itemIndex < openData.length - 1) {
      setItemIndex(itemIndex + 1);
      localStorage.setItem("mIndex", parseInt(itemIndex + 1));
    } else {
      processCart();
    }
  };

  //set index as last
  const last = () => {
    setSelectedShopper(null);
    if (itemIndex < openData.length) {
      setItemIndex(openData.length - 1);
      localStorage.setItem("mIndex", parseInt(openData.length - 1));
    }
  };

  //assign value in row-select
  useEffect(() => {
    if (item?.productId === null) {
      setSelectedRow();
    } else {
      const prod = item?.productInfos?.find(
        (ele) => ele?.productId === item?.productId
      );
      setSelectedRow(prod);
    }
  }, [item]);

  //shopper selection function
  const shopperSelection = (data) => {
    setSelectedShopper(data);
  };

  //find shopper
  const findShopper = (assignedId) => {
    return shoppersData.find((ele) => ele.id == assignedId);
  };

  //find confidence score
  const findConfidence = (userActivityId) => {
    return item?.recommendedShoppers?.find(
      (ele) => ele.userActivityId === userActivityId
    )?.confidenceScore;
  };

  //setInitial Value
  useEffect(() => {
    setSelectedShopper();
    setReviewQty();
    setSelectedRow();
  }, []);

  const assignFn = (item, selectedShopper) => {
    if (selectedRow === undefined || selectedRow === "") {
      toast.error("No product selected");
    } else if (reviewQty === undefined || parseInt(reviewQty) === 0) {
      toast.error("Enter valid qty");
    } else if (selectedShopper === undefined || selectedShopper === "") {
      toast.error("Select shopper");
    } else {
      const payload = {
        ...item,
        reviewedQuantity: reviewQty,
        productId: selectedRow?.productId,
        productInfos: [selectedRow],
        syncStatus: "APPROVED",
        userInfos: [
          {
            id: selectedShopper?.id,
            name: selectedShopper?.name,
            login: selectedShopper?.login,
            userRole: selectedShopper?.userType,
            cartRef: selectedShopper?.cartRef,
          },
        ],
      };

      axios({
        method: "PUT",
        url: `${process.env.REACT_APP_STORE_URL}/store-carts/${item?.id}/review-event`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
        data: payload,
      })
        .then(() => {
          setReload(!reload);
          setSelectedShopper();
          setReviewQty();
          setSelectedRow();
        })
        .catch(({ message }) => {
          if (message === "Request failed with status code 401") {
            history.push("/login");
          } else {
            setReload(!reload);
            toast.error(message);
          }
        });
    }
  };

  const unassignFn = (item) => {
    if (item) {
      confirmAlert({
        title: "Confirm to submit",
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              const payload = {
                id: item?.id,
                storeCode: item?.storeCode,
                cartItemRef: item?.cartItemRef,
                cartRef: item?.cartRef,
                rootOrgId: item?.rootOrgId,
                versionNumber: item?.versionNumber
              };

              axios({
                method: "PUT",
                url: `${process.env.REACT_APP_STORE_URL}/store-carts/${item?.id}/unassign-event`,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("user-info")}`,
                },
                data: payload,
              })
                .then(() => {
                  setReload(!reload);
                  setSelectedShopper();
                  setReviewQty();
                  setSelectedRow();
                })
                .catch(({ message }) => {
                  if (message === "Request failed with status code 401") {
                    history.push("/login");
                  } else {
                    setReload(!reload);
                    toast.error(message);
                  }
                });
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    }
  };

  //process cart
  const processCart = async () => {
    const payload = {
      sessionRef: locationData?.sessionRef,
      rootOrgId: locationData?.rootOrgId
        ? locationData?.rootOrgId
        : locationData?.storeCartTokens[0]?.rootOrgId,
    };

    axios({
      method: "PUT",
      url: `${process.env.REACT_APP_SHOPPER_URL}/stores/cart-review`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
      data: payload,
    })
      .then(({ data }) => {
        localStorage.setItem("way", "indirect");
        history.push({
          pathname: "/admin/hawkeye/cartSummary",
          state: { data: data },
        });
      })
      .catch(({ message }) => {
        if (message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          toast.error(message);
        }
      });
  };

  //at first filter all unassign items
  useEffect(() => {
    setOpenData([]);
    setItemIndex(0);
    localStorage.setItem("mIndex", parseInt(0));
    if (data !== undefined && data.length > 0) {
      setOpenData(data.filter((ele) => ele.syncStatus !== "CART_REVIEWED"));
    }
  }, [data]);

  const onChangeTabs = (value) => {
    setSelectedIndex(value);
  };

  //set Index
  useEffect(() => {
    if (selectedIndex === 0) {
      setOpenData(
        data !== undefined &&
          data.filter((ele) => ele.syncStatus === "CART_REVIEWED")
      );
      setItemIndex(0);
      localStorage.setItem("mIndex", parseInt(0));
    } else {
      setOpenData(
        data !== undefined &&
          data.filter((ele) => ele.syncStatus !== "CART_REVIEWED")
      );
      setItemIndex(0);
      localStorage.setItem("mIndex", parseInt(0));
    }
  }, [selectedIndex]);

  //setItem
  useEffect(() => {
    if (openData.length > 0 && itemIndex >= 0) {
      apiCall(openData[itemIndex]);
      setItem("");
      setTimeout(() => {
        setItem(openData[itemIndex]);
      }, 3);
    }
  }, [itemIndex, openData]);

  //all users during shopping times and set quantity
  useEffect(() => {
    if (item) {
      if (item.syncStatus === "CART_REVIEWED") {
        setReviewQty(item?.quantity);
      } else {
        if (item?.productInfos?.length === 1) {
          setReviewQty(item?.quantity);
        }
      }
    }
  }, [item]);

  return (
    <div className="w-[100vw] h-[100vh]  text-left  z-50 absolute top-1/2 left-1/2   transform -translate-x-1/2 -translate-y-1/2">
      <div className="w-full h-full bg-white py-1 flex flex-col justify-between items-center">
        {/* Swipper videos and tabel */}
        <div className="w-full h-[64.8vh] flex justify-between items-center pl-8 pr-4 space-x-6">
          <div className="w-full h-full flex justify-center items-center">
            {item?.storeCartVideoPaths?.length > 0 && openData?.length > 0 ? (
              <VideoSwapper arr={item?.storeCartVideoPaths} playRate={1.5} />
            ) : (
              <div className="w-full h-[64vh] flex justify-center items-center bg-gray-200 shadow-lg rounded-md">
                No video available
              </div>
            )}
          </div>

          {/* scales map */}
          <div className="w-full h-[64vh] flex flex-col space-y-2">
            <div className="w-[97%] h-[40px] flex justify-between items-center bg-gray-200 rounded px-2">
              {/* session strip */}
              <div className="flex space-x-4">
                <p className="text-[17px] font-bold">
                  {item?.cartRef ? item?.cartRef : ""}
                </p>
                <p className="text-[17px] font-bold">{`ST : ${getTimeOnly(
                  locationData?.entryTime,
                  locationData?.storeTimeZone
                )} - ${getTimeOnly(
                  locationData?.checkoutDate,
                  locationData?.storeTimeZone
                )}`}</p>
                <p className="text-[17px] font-bold">{`ET : ${getTimeOnly(
                  item?.eventTime,
                  item?.storeTimeZone
                )}`}</p>
              </div>

              {/* filter btn */}
              <div className="flex space-x-4 items-center">
                <Tab.Group
                  selectedIndex={selectedIndex}
                  onChange={onChangeTabs}
                  className="flex items-center"
                >
                  <Tab.List>
                    <Tab className="focus:outline-none">
                      {({ selected }) => (
                        <div>
                          <MdAssignmentTurnedIn
                            size={28}
                            className={`focus:outline-none ${
                              selected && "text-green-500"
                            }`}
                          />
                        </div>
                      )}
                    </Tab>
                    <Tab className="focus:outline-none">
                      {({ selected }) => (
                        <div>
                          <MdOutlineAssignmentReturned
                            size={28}
                            className={`focus:outline-none ${
                              selected && "text-green-500"
                            }`}
                          />
                        </div>
                      )}
                    </Tab>
                  </Tab.List>
                </Tab.Group>

                <div className="w-8 h-8 flex text-[14px] font-bold text-white justify-center items-center rounded-full bg-pink-500">
                  {openData?.length}
                </div>

                <VscRefresh
                  className="cursor-pointer"
                  size={28}
                  onClick={() => setReload(!reload)}
                />
                <MdBackspace
                  className="cursor-pointer"
                  size={28}
                  onClick={() => history.goBack()}
                />
              </div>
            </div>

            {/* tabel */}
            <div className="w-full text-left h-[calc(64vh-48px)] scrollbar-thin scrollbar-thumb-white scrollbar-track-white">
              <div className=" h-full w-[97%]">
                {allScales?.length > 0 ? (
                  <DisclosureCmp
                    allScales={allScales}
                    item={item}
                    selectedRow={selectedRow}
                    onSelectRow={onSelectRow}
                  />
                ) : (
                  <div className="w-full h-full flex justify-center items-center font-bold">
                    Invalid item
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* shopper loop */}
        <div className="w-full px-10 h-[185px] flex justify-center items-center mt-[4px]">
          <div className="w-full h-full flex justify-between">
            <div className="h-full w-full flex justify-between items-center">
              <div className="w-full h-full flex space-x-4 z-[100] justify-start  items-start">
                {shoppersData?.length > 0 ? (
                  shoppersData?.map((obj, index) => {
                    return (
                      <div
                        className="w-28 cursor-pointer "
                        onClick={() =>
                          item?.syncStatus === "CART_REVIEWED" ||
                          item?.syncStatus === "PROCESSED"
                            ? void 0
                            : shopperSelection(obj)
                        }
                      >
                        <ReactHover
                          options={{
                            followCursor: true,
                            shiftX: 0,
                            shiftY: -300,
                          }}
                        >
                          <Trigger type="trigger">
                            <div
                              className={`w-28 flex flex-col justify-center  items-center 
                                      ${
                                        obj.id === selectedShopper?.id &&
                                        "border-4 border-[#558b2f]"
                                      }
                                      
                                      ${
                                        (item?.syncStatus === "CART_REVIEWED" ||
                                          item?.syncStatus === "PROCESSED") &&
                                        openData?.length > 0 &&
                                        findShopper(item?.userInfos[0]?.id)
                                          ?.id === obj?.id &&
                                        "border-4 border-[#558b2f]"
                                      }`}
                              key={index}
                            >
                              {obj && obj?.userImagePaths[0] ? (
                                <img
                                  src={obj?.userImagePaths[0]}
                                  alt="event"
                                  className="w-28 h-40 rounded cursor-pointer"
                                />
                              ) : (
                                <img
                                  src={NoImage}
                                  alt="No Image"
                                  className="w-28 h-40 rounded cursor-pointer border"
                                />
                              )}

                              {openData?.length > 0 && (
                                <p className="text-[15px] font-bold">
                                  {`${
                                    findConfidence(obj.id)
                                      ? findConfidence(obj.id)
                                      : 0
                                  } %`}
                                </p>
                              )}
                            </div>
                          </Trigger>
                          <Hover type="hover">
                            {obj && obj?.userImagePaths[0] ? (
                              <div className="p-1 bg-gray-900 shadow-lg z-50">
                                <img
                                  src={obj?.userImagePaths[0]}
                                  alt="event"
                                  className="w-[100%] h-[100%] rounded cursor-pointer"
                                />
                              </div>
                            ) : (
                              <img
                                src={NoImage}
                                alt="No Image"
                                className="w-28 h-40 rounded cursor-pointer border"
                              />
                            )}
                          </Hover>
                        </ReactHover>
                      </div>
                    );
                  })
                ) : (
                  <img
                    src={NoImage}
                    alt="NoImage"
                    className="w-28 h-40 rounded cursor-pointer"
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        {/* next stripe */}
        {item && openData?.length > 0 ? (
          <div className="h-[8vh] w-full  px-8 mt-2">
            <div
              className={`w-full h-full flex justify-between items-center  ${
                selectedShopper ||
                item?.syncStatus === "CART_REVIEWED" ||
                item?.syncStatus === "PROCESSED"
                  ? "bg-[#558b2f] text-white"
                  : "bg-[#c5e1a5]"
              } rounded-md px-4`}
            >
              <div className="w-[150px] flex flex-col items-center justify-between">
                <p className="text-[25px] font-bold">
                  {item?.scaleInternalCode}
                </p>
                <p className="text-[17px] font-bold">
                  {item?.eventTime
                    ? getTimeOnly(item?.eventTime, item?.storeTimeZone)
                    : "Null"}
                </p>
              </div>

              {selectedRow && item?.scaleInternalCode !== null && (
                <>
                  <div className="h-full flex items-center space-x-4 z-[100]">
                    {/* product img hover */}
                    <div className="w-full z-[100] h-full space-x-4  flex  items-center justify-center my-[10px] ">
                      <ReactHover
                        options={{
                          followCursor: true,
                          shiftX: 20,
                          shiftY: -300,
                        }}
                      >
                        <Trigger type="trigger">
                          <div className="w-[8vh] h-[8vh]">
                            <img
                              src={selectedRow?.imageUrl}
                              className="w-full h-full p-0.5 rounded"
                              alt="image"
                            />
                          </div>
                        </Trigger>
                        <Hover type="hover">
                          <img
                            src={selectedRow?.imageUrl}
                            className="w-[60%] h-[60%]"
                            alt="image"
                          />
                        </Hover>
                      </ReactHover>

                      <div className="flex flex-col justify-between min-w-[400px]">
                        <p className="text-[20px] font-bold   ">
                          {selectedRow?.productName || selectedRow?.name}
                        </p>

                        <p className="text-[21px] font-bold text-yellow-500 ">{` ${parseFloat(
                          selectedRow?.unitWeight
                        )?.toFixed(3)} ${selectedRow?.unitOfMeasure}`}</p>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center ">
                    {/* input and kept-put code only for length === 1*/}
                    {item?.productInfos.length === 1 ? (
                      <div className="flex items-center text-left space-x-4">
                        <p className="text-[21px] font-bold">{` ${parseFloat(
                          item?.productInfos[0]?.estimatedChangeInWeight
                        )?.toFixed(3)} ${
                          item?.productInfos[0]?.unitOfMeasure
                        }`}</p>

                        <input
                          className="text-[25px] rounded text-center text-black focus:outline-none border-2 w-[100px] font-bold"
                          value={reviewQty}
                          disabled={
                            item?.syncStatus === "CART_REVIEWED" ||
                            item?.syncStatus === "PROCESSED"
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            setReviewQty(e.target.value);
                          }}
                        />

                        {item?.eventType === "PICK" ? (
                          <MdArrowUpward
                            size={28}
                            className=" text-green-500 "
                          />
                        ) : (
                          <MdArrowDownward
                            size={28}
                            className=" text-red-500 "
                          />
                        )}
                      </div>
                    ) : (
                      <div className="flex items-center text-left space-x-4">
                        {/* input and kept-put code only for length > 1*/}

                        <p className="text-[21px] font-bold">{` ${parseFloat(
                          item?.productInfos[0]?.estimatedChangeInWeight
                        )?.toFixed(3)} ${
                          item?.productInfos[0]?.unitOfMeasure
                        }`}</p>

                        <div className="flex items-center text-left ">
                          <input
                            className="text-[25px] rounded text-center text-black focus:outline-none border-2 font-bold w-[100px]"
                            value={reviewQty}
                            disabled={
                              item?.syncStatus === "CART_REVIEWED" ||
                              item?.syncStatus === "PROCESSED"
                                ? true
                                : false
                            }
                            onChange={(e) => setReviewQty(e.target.value)}
                          />
                        </div>

                        {item?.eventType === "PICK" ? (
                          <MdArrowUpward
                            size={28}
                            className=" text-green-500 "
                          />
                        ) : (
                          <MdArrowDownward
                            size={28}
                            className=" text-red-500 "
                          />
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}

              <div className="flex space-x-4">
                {/* assign */}
                <div className=" h-full flex flex-col justify-center items-center">
                  {!isLoading && (
                    <button
                      onClick={() => {
                        if (
                          item?.syncStatus === "CART_REVIEWED" ||
                          item?.syncStatus === "PROCESSED"
                        ) {
                          unassignFn(item);
                        } else {
                          assignFn(item, selectedShopper);
                        }
                      }}
                      className={`text-[25px] cursor-poiner ${
                        item?.syncStatus === "CART_REVIEWED" ||
                        item?.syncStatus === "PROCESSED"
                          ? "bg-red-500 active:bg-red-600"
                          : "bg-gray-500 active:bg-gray-600"
                      }  text-white rounded px-4 py-0.2 focus:outline-none`}
                    >
                      {item?.syncStatus === "CART_REVIEWED" ||
                      item?.syncStatus === "PROCESSED"
                        ? "Unassign"
                        : "Assign"}
                    </button>
                  )}
                </div>

                {/* next - forward btn */}
                <div className="flex justify-between items-center px-4 space-x-4">
                  <FaBackward
                    size={28}
                    onClick={() => previous()}
                    className={`${
                      itemIndex === 0
                        ? "text-gray-400"
                        : "text-blue-400  active:text-blue-700"
                    } cursor-pointer`}
                  />

                  {itemIndex === openData.length - 1 ? (
                    <FaFastForward
                      size={28}
                      onClick={() => next()}
                      className={`${
                        itemIndex === openData.length - 1
                          ? "text-red-500"
                          : "text-blue-400 active:text-blue-700"
                      } cursor-pointer`}
                    />
                  ) : (
                    <>
                      <FaForward
                        size={28}
                        onClick={() => next()}
                        className="text-blue-400 active:text-blue-700 cursor-pointer"
                      />

                      <FaFastForward
                        size={28}
                        onClick={() => last()}
                        className={`${
                          itemIndex === openData.length - 1
                            ? "text-red-500"
                            : "text-blue-400 active:text-blue-700 cursor-pointer"
                        } `}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full flex justify-end px-10 items-center my-6">
            <FaFastForward
              size={28}
              onClick={() => processCart()}
              className="text-red-500 cursor-pointer"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MappingModal;
