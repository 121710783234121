import React, { useEffect, useState } from "react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { BiArrowBack } from "react-icons/bi";
import { useHistory, useLocation } from "react-router-dom";
import { useGet } from "../../../../api/useGet";
import { usePut } from "../../../../api/usePut";
import FadeLoader from "react-spinners/FadeLoader";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import NoImage from "../../../../Images/noImage1.jpg";
import { toast } from "react-toastify";
import ImageModal from "./ImageModal";
import {
  getTimeWithoutYear,
  getTimeOnly,
} from "../../../../logic/useGetTime";
import VideoController from "../../../../custom-control/VideoController";

function FinalReview() {
  const history = useHistory();
  const {
    state: { data },
  } = useLocation();
  const [videoUrl, setVideoUrl] = useState();
  const [sessionAllVideos, setSessionAllVideos] = useState();
  const [storeData, setStoreData] = useState([]);

  const [submitItems, setSubmitItems] = useState([]);

  const onReviewSubmit = (id) => {
    setSubmitItems([...submitItems, id]);
  };

  const { data: images } = useGet(
    `${process.env.REACT_APP_STORE_URL}/user-activities?eventStatus.equals=CHECKEDOUT&sort=entryTime,asc&cartRef.equals=${data?.sessionRef}&eventTime.greaterThanOrEqual=${data?.entryTime}&eventTime.lessThanOrEqual=${data?.checkoutDate}&storeCode.equals=${data?.storeCode}`,
    "images"
  );

  const { data: storeCarts } = useGet(
    `${process.env.REACT_APP_SHOPPER_URL}/stores/carts?sessionRef.equals=${data?.sessionRef}&sort=createdDate,desc&page=0&size=50`,
    "storesCart"
  );

  const { data: storeCartsWithRackInfo, isLoading } = useGet(
    `${process.env.REACT_APP_STORE_URL}/store-carts?cartRef.equals=${data?.sessionRef}&sort=eventTime,desc&size=50`,
    "storeCartswithRackInfo"
  );

  const filterArray = () => {
    let temp = [];
    if (images[0]?.shopperVideoPaths?.length > 0) {
      temp = images[0]?.shopperVideoPaths?.filter(
        (ele) => ele?.name !== "ShopperLoop"
      );
    }
    return temp;
  };

  useEffect(() => {
    if (images?.length > 0) {
      setSessionAllVideos(filterArray());
      setVideoUrl(filterArray()[0]);
    }
  }, [images]);

  const { mutate } = usePut(
    `${process.env.REACT_APP_SHOPPER_URL}/stores/cart-finalize`,
    "hawkeyeList"
  );

  const [loading, setLoading] = useState(true);

  const onCellValueChanged = (params) => {
    var updatedCartData = { ...storeData };

    updatedCartData?.storeCartItems?.map((cartItem, index) => {
      const qty = cartItem.quantity;
      if (cartItem.id === params?.data?.id) {
        cartItem.reviewedQuantity = params?.data?.reviewedQuantity
          ? params?.data?.reviewedQuantity
          : cartItem?.skuType === "NON_STANDARD_SKU"
          ? qty.toFixed(3)
          : qty;
      }
    });

    setStoreData(updatedCartData);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (storeCarts?.length > 0) {
      setStoreData(storeCarts[0]);
    }
  }, [storeCarts]);

  const findRack = (extRef, storeCartsWithRackInfo) => {
    if (extRef && storeCartsWithRackInfo.length > 0) {
      let find = storeCartsWithRackInfo?.find((ele) =>
        extRef.includes(ele.cartItemRef)
      );

      return find?.scaleInternalCode;
    }
  };

  if (loading)
    return (
      <div className="bg-black bg-opacity-80 absolute inset-0 flex items-center justify-center">
        <div className="w-full h-20 absolute z-10 text-white flex flex-col items-center justify-center">
          <h2 className="">
            {" "}
            {localStorage.getItem("way") === "indirect"
              ? `please wait`
              : `There are no open items for shopper duration in the store.`}
          </h2>
          <FadeLoader
            color="#FFFFFF"
            className=""
            loading={loading}
            size={150}
          />
        </div>
      </div>
    );

  if (isLoading)
    return (
      <div className="bg-black bg-opacity-80 absolute inset-0 flex items-center justify-center">
        <div className="w-full h-20 absolute z-10 text-white flex flex-col items-center justify-center">
          <h2 className="">
            {" "}
            {localStorage.getItem("way") === "indirect"
              ? `please wait`
              : `There are no open items for shopper duration in the store.`}
          </h2>
          <FadeLoader
            color="#FFFFFF"
            className=""
            loading={loading}
            size={150}
          />
        </div>
      </div>
    );

  const confirm = () => {
    try {
      mutate({ ...storeData, status: "APPROVED" });
      setLoading(true);
      const timer = setTimeout(() => {
        setLoading(false);
        history.push("/admin/shopper-activity");
      }, 2000);
      return () => clearTimeout(timer);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const rejected = () => {
    mutate({ ...storeData, status: "REJECTED" });
    history.push("/admin/hawkeye");
  };

  const goBack = () => {
    history.goBack();
    localStorage.setItem("way", "reviewback");
  };

  const columnDefs = [
    {
      headerName: "Rack",
      field: "rack",
      cellRendererFramework: (params) => {
        return (
          <div className="">
            <p className="font-bold">
              {findRack(params?.data?.externalRef, storeCartsWithRackInfo)
                ? findRack(params?.data?.externalRef, storeCartsWithRackInfo)
                : " - "}
            </p>
          </div>
        );
      },
    },
    {
      headerName: "Image",
      field: "imageUrl",
      cellRendererFramework: (params) => (
        <>
          {params?.data?.imageUrl ? (
            <div className="w-10 h-10  flex items-center justify-center">
              <img src={params?.data?.imageUrl} alt="productImg" />
            </div>
          ) : (
            <div className="w-[46px] h-[46px]  flex items-center justify-center">
              <img src={NoImage} alt="No Image" />
            </div>
          )}
        </>
      ),
    },
    {
      headerName: "Product Name",
      field: "name",
      minWidth: 300,
    },
    {
      headerName: "Qty *",
      field: "reviewedQuantity",

      editable: true,
      cellRendererFramework: (params) => {
        return <p className="w-full px-1">{params?.data?.reviewedQuantity}</p>;
      },
    },
    {
      headerName: "Time",
      field: "transactionDate",

      cellRendererFramework: (params) => (
        <p className="">
          {getTimeOnly(
            params?.data?.transactionDate,
            params?.data?.storeTimeZone
          )}
        </p>
      ),
    },
    {
      headerName: "",
      fieldName: "imageUrl",
      checkboxSelection: true,
      maxWidth: 60,
    },
  ];

  return (
    <div className="w-full h-[calc(100vh-102px)] px-8 flex flex-col justify-between items-center py-4">
      <div className="h-8 w-full flex items-center  justify-between">
        <div className="h-full items-center flex">
          <BiArrowBack onClick={goBack} className="h-6 w-6 cursor-pointer" />
          <h2 className="ml-3">Cart Summary</h2>
        </div>
      </div>

      {/* shopper's images */}
      <div className={`w-full h-44 flex items-center justify-between`}>
        <div className="w-full h-full flex justify-between items-center">
          <div className="w-full flex space-x-2">
            {images?.length > 0 ? (
              images?.map((obj, index) => (
                <div key={index} className="rounded">
                  <ImageModal obj={obj} index={index} />
                </div>
              ))
            ) : (
              <img
                src={NoImage}
                alt="NoImage"
                className="w-44 h-44 rounded cursor-pointer"
              />
            )}
          </div>
        </div>
        {localStorage.getItem("navigate") === "approved" ||
        localStorage.getItem("navigate") === "rejected" ? (
          <button
            className={`focus:outline-none ${
              localStorage.getItem("navigate") === "approved"
                ? "bg-green-500"
                : "bg-red-500"
            } ml-1 text-white font-bold py-1.5 px-4 rounded-full inline-flex items-center`}
          >
            <span className="mx-2 text-xl capitalize">Order Summary</span>
          </button>
        ) : (
          <button
            onClick={() =>
              history.push({
                pathname: "/admin/match/openEvents/AddItem",
                state: {
                  data: storeData,
                  entryTime: data?.entryTime,
                  storeTimeZone: data?.storeTimeZone,
                  checkoutDate: data?.checkoutDate,
                },
              })
            }
            className="focus:outline-none bg-dash text-white font-bold  rounded-full w-[160px] justify-center py-2 flex items-center"
          >
            <span className="mx-2 text-xl ">Add Item</span>
            <AiOutlineShoppingCart className="w-4 " />
          </button>
        )}
      </div>

      {/* session video & table */}
      <div className="w-full h-[60%] flex items-center my-1 space-x-4">
        <div className="w-[60%] h-[100%]">
          {videoUrl ? (
            <div className="w-full h-[100%] bg-black">
              <VideoController url={videoUrl?.videoUrl} playRate={4} />
            </div>
          ) : (
            <div className="w-full h-[100%] flex justify-center items-center border border-gray-400">
              No video available
            </div>
          )}
        </div>

        <div className="ag-theme-alpine w-[80%] h-full text-left">
          <AgGridReact
            defaultColDef={{
              flex: 1,
              resizable: true,
            }}
            suppressRowClickSelection={true}
            checkboxSelection={true}
            rowSelection={"multiple"}
            pagination={false}
            rowData={
              storeData?.storeCartItems?.length > 0
                ? storeData?.storeCartItems?.filter(
                    (ele) => ele?.status === "APPROVED"
                  )
                : []
            }
            columnDefs={columnDefs}
            onCellValueChanged={onCellValueChanged}
            onRowSelected={({ data }) => onReviewSubmit(data?.id)}
          />
          {/* <ProductReviewModal
             open={edit}
             onCloseModal={onCloseEdit}
             data={formData}
           /> */}
        </div>
      </div>

      {/* session video btn */}
      <div className="w-full h-16 flex mt-2 space-x-2">
        <div className="w-[90%] grid grid-cols-9 gap-2">
          {sessionAllVideos?.map((ele, index) => {
            return (
              <button
                key={index}
                onClick={() => setVideoUrl(ele)}
                className={`bg-gray-200 px-4 py-1 ${
                  ele?.name === videoUrl?.name && "bg-[#558b2f] text-white"
                }`}
              >
                {ele?.name}
              </button>
            );
          })}
        </div>

        <div className="w-[10%] h-full flex  items-center ">
          {(localStorage.getItem("navigate") === "inReview" ||
            localStorage.getItem("navigate") === "inflight" ||
            localStorage.getItem("navigate") === "openEntryEvents" ||
            localStorage.getItem("navigate") === "processedEntryEvents") && (
            <div className="w-full h-full flex flex-col justify-between items-center space-y-2">
              {storeData?.storeCartItems?.length > 0 ? (
                <>
                  <button
                    onClick={confirm}
                    className="bg-green-600 w-28 py-1 rounded text-white focus:outline-none "
                  >
                    Confirm
                  </button>
                  <button
                    onClick={rejected}
                    className="bg-red-600 w-28 py-1 rounded text-white focus:outline-none  "
                  >
                    Reject
                  </button>
                </>
              ) : (
                <button
                  onClick={rejected}
                  className="bg-red-600 w-28 py-1 rounded text-white focus:outline-none  "
                >
                  Reject
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default FinalReview;
