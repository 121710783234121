import moment from "moment";
import React, { createContext, useContext, useMemo, useState } from "react";

const SearchDataContext = createContext({
  matchExitSearchInput: {},
  setMatchExitSearchInput: () => {},
  matchExitSearchData: {},
  setMatchExitSearchData: () => {},
  openEntrySearchInput: {},
  setOpenEntrySearchInput: () => {},
  openEntrySearchData: {},
  setOpenEntrySearchData: () => {},
});

const SearchDataProvider = ({ children }) => {
  // ------------------ MatchEntry tab ---------------------------
  const [matchExitSearchInput, setMatchExitSearchInput] = useState({
    retailerStore: "",
    cartref: "",
    to: moment().add(-24, "hours"),
    from: new Date(),
    type: "",
  });

  const [matchExitSearchData, setMatchExitSearchData] = useState({
    retailerStore: "",
    cartRef: "",
  });

  // ------------------ Open entry tab -------------------------
  const [openEntrySearchInput, setOpenEntrySearchInput] = useState({
    retailerStore: "",
    cartref: "",
    to: moment().add(-24, "hours"),
    from: new Date(),
    type: "",
  });

  const [openEntrySearchData, setOpenEntrySearchData] = useState({
    to: "",
    from: "",
  });

  const memoizedValue = useMemo(
    () => ({
      matchExitSearchInput,
      setMatchExitSearchInput,
      matchExitSearchData,
      setMatchExitSearchData,
      openEntrySearchInput,
      setOpenEntrySearchInput,
      openEntrySearchData,
      setOpenEntrySearchData,
    }),
    [
      matchExitSearchInput,
      matchExitSearchData,
      openEntrySearchInput,
      openEntrySearchData,
    ]
  );

  return (
    <SearchDataContext.Provider value={memoizedValue}>
      {children}
    </SearchDataContext.Provider>
  );
};

function useSearchDataProvider() {
  const context = useContext(SearchDataContext);
  if (context === undefined) {
    throw new Error("Please provide Search data provider");
  }
  return context;
}

export { SearchDataProvider, useSearchDataProvider };
