import React, { useState } from "react";
import { IoMdRefresh } from "react-icons/io";

const initialRoute = (value) => {
    const type = value;

    switch(type) {
        case "openTasks":
            return "openTasks";
        case "processedTasks":
            return "processedTasks";
        default:
            return "openTasks";
    }
};

function TableStr({
    title,
    url,
    listUrl,
    viewUrl,
    getName,
    All,
    dashboard,
    ProcessedProductEvents,
    processedViewUrl
}) {

    const [refresh, setRefresh] = useState(false);

    const [click, setClicked] = useState(
        initialRoute(localStorage.getItem("tab"))
    );
    

    const options = (e) => {
        const type = e.target.name;

        switch (type) {
            case "openTasks":
                setClicked(type);
                localStorage.setItem("tab", type);
                break;
            case "processedTasks":
                setClicked(type);
                localStorage.setItem("tab", type);
                break;
            default:
        }
    };

    return (
        <div className="w-full h-full shadow-lg select-none">
            <div
                className={`w-full ${
                dashboard ? "h-16" : "h-12"
                } flex justify-between p-6 items-center bg-gray-100`}
            >
                <h2 className="font-bold text-2xl">{title}</h2>
                <IoMdRefresh
                    onClick={()=>setRefresh(!refresh)}
                    size={28}
                    className="cursor-pointer"
                    />
            </div>
            <div className="w-full flex h-10 p-2  -mt-2 ">
                <div className="h-10 w-full  flex justify-start ">
                    <button
                        onClick={options}
                        name="openTasks"
                        className={`mx-4 border-b-2 ${click === "openTasks" ? "border-blue-500" : null
                    } focus:outline-none`}
                    >
                        Open Product Events
                    </button>
                    <button
                        onClick={options}
                        name="processedTasks"
                        className={`mx-4 border-b-2 ${click === "processedTasks" ? "border-blue-500" : null
                    } focus:outline-none`}
                    >
                        Processed Product Events
                    </button>
                </div>
            </div>

            { click === "openTasks" ? (
                <All
                    url={url}
                    viewUrl={viewUrl}
                    listUrl={listUrl}
                    getName={getName}
                    refresh={refresh}
                    setRefresh={setRefresh}
                />
                ) : click === 'processedTasks' ? (
                    <ProcessedProductEvents
                        listUrl={listUrl}
                        viewUrl={processedViewUrl}
                        getName={getName}
                        refresh={refresh}
                        setRefresh={setRefresh}
                    />
                ) : null
            }
            
        </div>
    );
}

export default TableStr;