import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { IoIosClose } from "react-icons/io";
import SessionViewThumbnail from "./SessionViewThumbnail";
import NoVideo from "../Images/novideo.jpg";

const SessionAllVideos = ({ shopperVideoPaths, isOpen, closeModal }) => {
  const trackingVideo = (shopperVideoPaths) => {
    return shopperVideoPaths?.filter((ele) => ele.name !== "ShopperLoop");
  };
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-60" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-[48rem] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 flex justify-between items-center"
                >
                  <p>Session Videos</p>

                  <IoIosClose
                    onClick={closeModal}
                    size={28}
                    className="bg-gray-500 rounded-full bg-opacity-20 shadow-lg cursor-pointer"
                  />
                </Dialog.Title>

                <div className="mt-6 grid grid-cols-4  gap-y-12">
                  {shopperVideoPaths?.length > 0 ? (
                    shopperVideoPaths?.map((ele, index) => {
                      return (
                        <div
                          key={index}
                          className="flex flex-col items-center justify-center"
                        >
                          <SessionViewThumbnail
                            imageUrl={ele?.thumbnail}
                            videoUrl={ele?.videoUrl}
                            camera={ele?.name}
                            index={index}
                          />
                          <p>{ele.name}</p>
                        </div>
                      );
                    })
                  ) : (
                    <img src={NoVideo} className="w-64 h-48" alt="exitimage" />
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default SessionAllVideos;
