import React from "react";
import FadeLoader from "react-spinners/FadeLoader";
import { MdClear } from "react-icons/md";
import { BiSearchAlt } from "react-icons/bi";

function SearchData({
  input,
  setInput,
  onChangeInput,
  filteredData,
  setSearchData,
  searchDataFn,
  loading,
  label,
  name,
  type,
  width,
  margin,
  handleClear,
}) {
  console.log("name", name);
  const clear = () => {
    setInput({ ...input, [name]: "", type: "" });
    setSearchData("");
    if (handleClear) {
      handleClear(name);
    }
  };

  return (
    <div className={`${width ? width : "w-full"} my-1`}>
      <div className={`flex  justify-between items-center`}>
        <input
          placeholder={label}
          value={input[name]}
          type={type}
          onChange={(e) => onChangeInput(e, name)}
          className="w-full h-10  focus:outline-none rounded px-4 border border-black"
        />
        {input[name]?.length > 0 ? (
          <MdClear
            size={24}
            className={`absolute cursor-pointer ${
              margin ? margin : "ml-[255px]"
            }`}
            onClick={clear}
          />
        ) : (
          <BiSearchAlt
            size={24}
            className={`cursor-pointer absolute ${
              margin ? margin : "ml-[255px]"
            } text-blue-500`}
          />
        )}
      </div>

      {filteredData[name] && input[name].length !== 0 && (
        <div
          className={`bg-gray-200  ${
            width ? width : "w-72"
          } min-h-[20px] max-h-[160px] rounded-b-lg flex  flex-col border-t border-white absolute z-10 scrollbar-thin scrollbar-thumb-gray-900 scrollbar-track-gray-100`}
        >
          <>
            <div
              className={
                loading
                  ? "bg-black bg-opacity-0 absolute inset-0 z-10 flex items-center justify-center"
                  : null
              }
            >
              <FadeLoader className="absolute " loading={loading} size={150} />
            </div>
            {filteredData[name]?.length ? (
              <>
                {filteredData[name]?.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="cursor-pointer border-b border-white py-2"
                      onClick={() => searchDataFn(name, data)}
                    >
                      <p className="text-left px-4 text-xs">{data?.name}</p>
                      {/* <img src={product?.imageUrl} className="w-12 h-12"/> */}
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="w-full h-full flex justify-center items-center">
                <p>No data found !</p>
              </div>
            )}
          </>
        </div>
      )}
    </div>
  );
}

export default SearchData;
