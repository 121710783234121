import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#D9D9D9",
    color: theme.palette.common.black,
    fontWeight: 700,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function ProductListTable({
  productList,
  handleApiCall,
  storeData,
}) {
  console.log("🚀 ~ file: ProductListTable.js:28 ~ storeData:", storeData);
  console.log(
    "🚀 ~ file: ProductListTable.js:23 ~ ProductListTable ~ productList:",
    productList
  );

  const countMap = {};
  productList?.forEach((item) => {
    const key = `${item.shelfName}-${item.scaleIdentifier}`;
    countMap[key] = (countMap[key] || 0) + 1;
  });
  console.log("🚀 ~ countMap:", countMap);
  return (
    <TableContainer
      sx={{ height: "100%", border: "1px solid black", resize: "vertical" }}
      component={Paper}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell sx={{ fontWeight: 800 }}>Image</StyledTableCell>
            <StyledTableCell sx={{ fontWeight: 800 }}>
              Product Name
            </StyledTableCell>
            <StyledTableCell sx={{ fontWeight: 800 }}>Position</StyledTableCell>
            <StyledTableCell sx={{ fontWeight: 800 }}>Action</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {productList?.map((row) => {
            console.log("🚀 ~ {productList?.map ~ row:", row);
            const keyVal = `${row.shelfName}-${row.scaleIdentifier}`;
            return (
              <StyledTableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <StyledTableCell component="th" scope="row">
                  {row?.product?.imageUrl ? (
                    <div className="w-10 h-10  flex items-center justify-center">
                      <img src={row?.product?.imageUrl} alt="" />
                    </div>
                  ) : (
                    <div className="w-[46px] h-[46px]  flex items-center justify-center">
                      {/* <img src={NoImage} alt="No Image" /> */}
                    </div>
                  )}
                  {/* <div className="w-10 h-10  flex items-center justify-center">
                  <img src={row?.product?.imageUrl} alt="" />
                </div> */}

                </StyledTableCell>
                <StyledTableCell>{row?.product?.name}</StyledTableCell>
                <StyledTableCell>
                  {countMap?.[keyVal] >= 2
                    ? `${row?.shelfName}-${row?.productPosition}`
                    : keyVal}
                </StyledTableCell>
                <StyledTableCell>
                  <Button
                    variant="contained"
                    onClick={() =>
                      handleApiCall({
                        id: storeData?.id,
                        storeCartItems: [
                          {
                            productId: row?.product?.id,
                            quantity: 1,
                            note: "",
                            scaleGroupId: row?.scaleGroupId,
                            scaleIdentifier: row?.scaleIdentifier,
                            activityType: "PICK",
                          },
                        ],
                      })
                    }
                  >
                    Add Item
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
