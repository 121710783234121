import React, { useState } from "react";
import Toggle from "react-toggle";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { usePut } from "../../../../../../api/usePut";

function Confirm({ params, name }) {
  //console.log("params : ", active)
  const history = useHistory();

  const [status, setStatus] = useState(
    params?.data.status === "true" ? true : false
  );

  const editMachine = usePut(
    `${process.env.REACT_APP_BASE_URL}/machines/${params?.data?.id}`,
    name
  );

  //console.log("status: ", status)

  const statusChecked = (e) => {
    const value = e.target.checked;

    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            var body = {
              id: params?.data?.id,
              name: params?.data?.name,
              status: value,
            };

            editMachine.mutate(body);

            if (editMachine.isError === false) {
              setStatus(editMachine?.data?.status);
              // window.location.reload();
              history.push("/admin/machine");
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  //console.log("use: ", status);

  return (
    <>
      <Toggle
        onChange={statusChecked}
        checked={status}
        //icons={{ checked: null, unchecked: null }}
        className="react-switch"
      />
    </>
  );
}

export default Confirm;
