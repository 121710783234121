import React, { useEffect, useState } from "react";
import ExitImages from "./ExitImages";
import moment from "moment";
import NoImage from "../../../../../../Images/noImage.jpg";
import { Grid } from "@mui/material";
import Slider from "react-slick";
import Iconify from "../../../../../../components/iconify/Iconify";
import VideoController from "../../../../../../custom-control/VideoController";
import NoVideo from "../../../../../../Images/novideo.jpg";

const videoSliderStyle = {
  maxWidth: "200px", // Set the desired width
  maxHeight: "200px", // Set the desired height
  padding: "10px",
  paddingTop: "0",
  // marginLeft: "130px",
  // marginRight: "50px",
  // display: "flex",
  // justifyContent: "center",
  // alignItems: "center",
};

const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <Iconify color="black" icon="ooui:next-ltr" />,
  prevArrow: <Iconify color="black" icon="ooui:previous-ltr" />,
};

const sliderStyle = {
  maxWidth: "300px", // Set the desired width
  maxHeight: "200px", // Set the desired height
  padding: "10px",
  paddingTop: "0",
  // marginLeft: "50px",
  // marginRight: "50px",
  // display: "flex",
  // justifyContent: "center",
  // alignItems: "center",
};

function AlreadySelected({ data, index, setSelect, select, isConfidenceScore }) {
  console.log(
    "🚀 ~ file: AlreadySelected.js:45 ~ AlreadySelected ~ data:",
    data
  );
  const [entryImages, setEntryImages] = useState([]);

  useEffect(() => {
    setSelect(data);
  }, [data]);

  useEffect(() => {
    const people = [];

    for (let i = 0; i < 3; i++) {
      people.push(data?.userImagePaths?.[i] ? data.userImagePaths?.[i] : "");
    }
    setEntryImages(people);
  }, [data]);

  // console.log(
  //   "Selected Time: ",
  //   moment(select?.exitTime).format("DD-MM-YYYY hh:mm:ss A")
  // );
  const confidenceScore = data?.suggestedUserActivities?.find(
    (item) => item?.cartRef == data?.cartRef
  )?.confidenceScore;
  console.log('confidenceScore', confidenceScore);
  return (
    <>
      {Array.isArray(data) ? (
        <div key={index} className={`flex h-44 w-full space-x-4`}>
          {data?.length > 0 ? (
            <Grid container className="justify-center" px={2} spacing={2}>
              <Grid item xs={isConfidenceScore ? 5 : 6}>
                <div className="flex justify-center items-center">
                  {entryImages?.length > 0 ? (
                    // <div style={sliderStyle}>
                    //   <Slider {...settings}>
                    //     {data?.userImagePaths?.map((url, index) => (
                    //       <div key={index}>
                    //         <img
                    //           src={url}
                    //           height="100%"
                    //           width="100%"
                    //           alt="/images/noImage.jpg"
                    //         />
                    //       </div>
                    //     ))}
                    //   </Slider>
                    // </div>
                    entryImages?.map((url, index) => (
                      <ExitImages
                        key={index}
                        url={url}
                        index={index}
                        through="processedEntry"
                      />
                    ))
                  ) : (
                    <img src={NoImage} alt="" className="w-44 h-44" />
                  )}
                </div>
              </Grid>
              {isConfidenceScore && <Grid item xs={2}>
                <div className="flex justify-center items-center h-[100%]">
                <div className="border-2 border-black px-3 py-5 mb-2">
                  <p>Confidence Score</p>
                  <p className="text-2xl font-bold">
                    {confidenceScore ? `${confidenceScore}%` : " 0%"}
                  </p>
                </div>
                </div>
              </Grid>}
              <Grid item xs={isConfidenceScore ? 5 : 6}>
                <div className="w-[100%] flex items-center justify-center">
                  <div style={videoSliderStyle}>
                    {data?.shopperVideoPaths?.filter(
                      (item) => item?.name == "Exit"
                    )?.length > 0 ? (
                      // <div style={sliderStyle}>
                      //   <Slider {...settings}>
                      //     {data?.exitVideoPaths?.map((item, index) => (
                      //       <VideoController url={data?.exitVideoPaths?.[0]  || "/D28_compressed.mp4"} />
                      //     ))}
                      //   </Slider>
                      // </div>
                      <VideoController
                        url={
                          data?.shopperVideoPaths?.filter(
                            (item) => item?.name == "Exit"
                          )?.shopperVideoPaths?.[0]?.videoUrl
                        }
                      />
                    ) : (
                      <img src={NoVideo} alt="" className="w-44 h-44" />
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          ) : (
            <div className="w-full flex items-center justify-center font-bold">
              No User Exit Activity Available
            </div>
          )}
        </div>
      ) : (
        <div key={index} className={`flex h-44 w-full space-x-4`}>
          {data ? (
            <Grid container className="justify-center" px={2} spacing={2}>
              <Grid item xs={isConfidenceScore ? 5 : 6}>
                <div className="flex justify-center items-center">
                  {entryImages?.length > 0 ? (
                    entryImages?.map((url, index) => (
                      <ExitImages
                        key={index}
                        url={url}
                        index={index}
                        through="processedEntry"
                      />
                    ))
                  ) : (
                    <img src={NoImage} alt="" className="w-44 h-44" />
                  )}
                </div>
              </Grid>
              {isConfidenceScore && <Grid item xs={2}>
                <div className="flex justify-center items-center h-[100%]">
                <div className="border-2 border-black px-3 py-5 mb-2">
                  <p>Confidence Score</p>
                  <p className="text-2xl font-bold">
                    {confidenceScore ? `${confidenceScore}%` : " 0%"}
                  </p>
                </div>
                </div>
              </Grid>}
              <Grid item xs={isConfidenceScore ? 5 : 6}>
                <div className="w-[100%] flex items-center justify-center">
                  <div style={videoSliderStyle}>
                    {data?.exitVideoPaths?.filter(
                      (item) => item?.name == "Exit"
                    )?.length > 0 ? (
                      // <div style={sliderStyle}>
                      //   <Slider {...settings}>
                      //     {data?.exitVideoPaths?.map((item, index) => (
                      //       <VideoController url={data?.exitVideoPaths?.[0]  || "/D28_compressed.mp4"} />
                      //     ))}
                      //   </Slider>
                      // </div>
                      <VideoController
                        url={
                          data?.exitVideoPaths?.filter(
                            (item) => item?.name == "Exit"
                          )?.[0]?.videoUrl
                        }
                      />
                    ) : (
                      <img src={NoVideo} alt="" className="w-44 h-44" />
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          ) : (
            <div className="w-full flex items-center justify-center font-bold">
              No User Exit Activity Available
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default AlreadySelected;
