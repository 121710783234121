import { IoIosClose } from "react-icons/io";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TextField2 } from "../../../../../validation/TextField2";
import { useEffect, useState } from "react";
import MobileDateTimePicker from "../../../../../../components/MobileDateTimepicker";
import moment from "moment";
import DesktopDateTimePicker from "../../../../../../components/DateTimePicker";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { usePost } from "../../../../../../api/usePost";
import { useAlert } from "react-alert";

function ModalTileVideoForm({
  open,
  onCloseModal,
  cartRef,
  entryTime,
  exitTime,
  storeCode,
}) {
  console.log(
    "🚀 ~ file: ModalTileVideoForm.js:23 ~ cartRef:",
    cartRef,
    entryTime,
    exitTime,
    storeCode
  );
  dayjs.extend(utc);
  dayjs.extend(timezone);
  // dayjs.tz.setDefault('Europe/Berlin');

  const alert = useAlert();

  const [startTime, setStartTime] = useState(entryTime);
  const [endTime, setEndTime] = useState(exitTime);
  const [rackList, setRackList] = useState([]);
  const dateTimeFormat = "YYYY-MM-DDTHH:mm:ss.SSS";

  useEffect(() => {
    const startTimeTz = dayjs(entryTime).tz("Europe/Berlin");
    const endTimeTz = dayjs(exitTime).tz("Europe/Berlin");
    setStartTime(startTimeTz);
    setEndTime(endTimeTz);
  }, [cartRef]);

  const rackOptionList = Array.from({ length: 50 }, (_, index) => ({
    label: `Rack ${index + 1}`,
    value: (index + 1).toString(),
  }));

  const onChangeEntryTime = (value, name) => {
    setStartTime(value);
  };

  const onChangeExitTime = (value, name) => {
    setEndTime(value);
  };

  const onClose = () => {
    onCloseModal();
    setRackList([]);
  };

  const handleCheck = (event) => {
    var updatedList = [...rackList];
    if (event.target.checked) {
      updatedList = [...rackList, event.target.value];
    } else {
      updatedList.splice(rackList.indexOf(event.target.value), 1);
    }
    setRackList(updatedList);
  };

  const requestVideos = usePost(
    `${process.env.REACT_APP_STORE_URL}/repost-tile-videos`,
    "tileVideoRequest"
  );

  return (
    <>
      {open && (
        <div className="w-1/2 text-left select-none  absolute top-1/2 left-1/2  ml-32 transform -translate-x-1/2 -translate-y-1/2  z-[1200]">
          <Formik
            initialValues={{
              cartRef: cartRef ? cartRef : "",
              storeCode: storeCode ? storeCode : "",
            }}
            onSubmit={async (values) => {
              const payload = {};
              const entryTimeUTC =
                dayjs.utc(startTime).format(dateTimeFormat) + "Z";
              const exitTimeUTC =
                dayjs.utc(endTime).format(dateTimeFormat) + "Z";
              const additionalRacks = rackList.join(",");
              payload["entryTime"] = entryTimeUTC;
              payload["checkoutTime"] = exitTimeUTC;
              payload["retailStoreCode"] = storeCode;
              payload["cartRef"] = cartRef;
              if (rackList.length > 0) {
                payload["additionalRacks"] = additionalRacks;
              }

              try {
                if (dayjs.utc(startTime).isAfter(dayjs.utc(endTime))) {
                  alert.error(
                    <div style={{ textTransform: "none" }}>
                      Entry time is after Exit time. Please check!
                    </div>
                  );
                } else {
                  requestVideos.mutate(payload);

                  if (requestVideos.isError === false) {
                    onClose();
                  } else {
                    alert.error(
                      <div style={{ textTransform: "none" }}>
                        Request failed!
                      </div>
                    );
                  }
                }
              } catch (e) {
                alert.error(
                  <div style={{ textTransform: "none" }}>Request failed!</div>
                );
              }
            }}
          >
            {() => (
              <Form className="w-full  bg-gray-100 border-2 border-dash rounded-lg shadow-lg ">
                <div className="w-full h-16 flex bg-dash text-white">
                  <div className="w-full h-full px-7 flex flex-col justify-center">
                    <h2 className="font-bold text-2xl">
                      Tile Video Request Form
                    </h2>
                  </div>
                  <div className="w-16 px-4 h-full flex items-center">
                    <div className="w-full flex justify-end  ">
                      <IoIosClose
                        onClick={onClose}
                        className="h-12 w-12 cursor-pointer"
                      />
                    </div>
                  </div>
                </div>
                <div className="w-11/12 mx-auto px-6 my-2">
                  <div className="w-full flex mt-4  items-center">
                    <div className="flex  px-4 mr-4   w-full items-center select-none">
                      <TextField2
                        label="Cart Ref"
                        name="cartRef"
                        editable={false}
                        value={cartRef}
                      />
                    </div>
                    <div className="flex  px-4 ml-4   w-full items-center select-none">
                      <div className="ml-4 " style={{ width: "350px" }}>
                        <TextField2
                          label="Store Code"
                          name="storeCode"
                          type="text"
                          editable={false}
                          value={storeCode}
                          sx={{ backgroundColor: "white" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-11/12 mx-auto px-6 my-2">
                  <div className="w-full flex mt-4  items-center">
                    <div className="flex  px-4 mr-4   w-full items-center select-none">
                      <DesktopDateTimePicker
                        onChange={onChangeEntryTime}
                        timeZone="Europe/Berlin"
                        label="Entry Time (Europe/Berlin)"
                        value={startTime}
                        name="entryTime"
                      />
                    </div>
                    <div className="flex  px-4 mr-4   w-full items-center select-none">
                      <DesktopDateTimePicker
                        onChange={onChangeExitTime}
                        timeZone="Europe/Berlin"
                        label="Exit Time (Europe/Berlin)"
                        value={endTime}
                        name="exitTime"
                      />
                    </div>
                  </div>
                </div>
                <div className="w-11/12 mx-auto px-6 my-2">
                  <div className="w-full flex mt-4  items-center">
                    <div className="flex  px-4 mr-4   w-full items-center select-none">
                      Additional Racks
                    </div>
                  </div>
                  <div className="px-4 grid grid-cols-5 gap-x-4 mt-3">
                    {rackOptionList.map((rack, i) => (
                      <div key={`applicableDays-` + i}>
                        <input
                          type="checkbox"
                          onChange={handleCheck}
                          className="h-5 w-5"
                          name={rack.label}
                          value={rack.value}
                        />
                        <span className="mx-5 text-gray-500">{rack.label}</span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="w-full h-16 flex justify-center items-center px-3 mt-2 pr-8">
                  <button
                    type="submit"
                    className="bg-green-500 rounded-lg px-6 py-3  focus:outline-none text-white"
                  >
                    <span className="h-full flex items-center">
                      <svg
                        class="svg-icon"
                        viewBox="0 0 20 20"
                        width="24px"
                        height="24px"
                      >
                        <path
                          fill="#8A2BE2"
                          d="M19.404,6.65l-5.998-5.996c-0.292-0.292-0.765-0.292-1.056,0l-2.22,2.22l-8.311,8.313l-0.003,0.001v0.003l-0.161,0.161c-0.114,0.112-0.187,0.258-0.21,0.417l-1.059,7.051c-0.035,0.233,0.044,0.47,0.21,0.639c0.143,0.14,0.333,0.219,0.528,0.219c0.038,0,0.073-0.003,0.111-0.009l7.054-1.055c0.158-0.025,0.306-0.098,0.417-0.211l8.478-8.476l2.22-2.22C19.695,7.414,19.695,6.941,19.404,6.65z M8.341,16.656l-0.989-0.99l7.258-7.258l0.989,0.99L8.341,16.656z M2.332,15.919l0.411-2.748l4.143,4.143l-2.748,0.41L2.332,15.919z M13.554,7.351L6.296,14.61l-0.849-0.848l7.259-7.258l0.423,0.424L13.554,7.351zM10.658,4.457l0.992,0.99l-7.259,7.258L3.4,11.715L10.658,4.457z M16.656,8.342l-1.517-1.517V6.823h-0.003l-0.951-0.951l-2.471-2.471l1.164-1.164l4.942,4.94L16.656,8.342z"
                        ></path>
                      </svg>
                      <span className="px-2">Submit Request</span>
                    </span>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
}

export default ModalTileVideoForm;
