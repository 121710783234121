import React from "react";
import { CgCheckO } from "react-icons/cg";
import { usePut } from "../../../../../../api/usePut";
import { toast } from "react-toastify";

function Action({ params, setReload, reload }) {
  const { mutate: checkout } = usePut(
    `${process.env.REACT_APP_SHOPPER_URL}/stores/dispenser/checkout`,
    "hawkeyeInflightList"
  );

  // const { mutate: checkout } = usePut(
  //   `${process.env.REACT_APP_BASE_URL}/users`,
  //   "hawkeyeInflightList"
  // );

  const edit = () => {
    checkout({
      sessionRef: params.data.sessionRef,
      checkoutDate: params.data.checkoutDate,
      rootOrgId: params.data.rootOrgId,
    });

    // checkout({
    //   id: "3f869f7e-3421-486b-8e6e-f625d97303fd",
    //   login: "deepak.a",
    //   firstName: "Deepak",
    //   lastName: "A",
    //   email: "deepak.a@autonomo.com",
    //   imageUrl: null,
    //   activated: true,
    //   langKey: "en_US",
    //   createdBy: "admin",
    //   createdDate: "2022-05-25T06:22:22.004Z",
    //   lastModifiedBy: "admin",
    //   lastModifiedDate: "2022-06-13T18:02:50.584Z",
    //   authorities: [],
    //   userInfo: {
    //     externalCode: null,
    //     internalCode: null,
    //     nickname: null,
    //     gender: null,
    //     phone: "7550047396",
    //     qualification: null,
    //     lastLogin: null,
    //     loginFailureCount: null,
    //     loginCount: null,
    //     slug: null,
    //     rootOrgId: null,
    //     orgSlug: null,
    //     subOrgs: [],
    //     salutation: null,
    //   },
    //   org: null,
    //   rootOrg: null,
    //   permissions: null,
    //   jwtToken: null,
    // });

    setTimeout(() => {
      setReload(!reload);
    }, 500);

    toast.success("Your request is successfully submitted");
  };

  return (
    <>
      <div className="flex w-16 h-10 items-center">
        {params.data.type === "DISPENSER" && (
          <div className="border-2 border-gray-200 rounded-lg py-1 px-2">
            <CgCheckO className="w-5 h-5 cursor-pointer" onClick={edit} />
          </div>
        )}
      </div>
    </>
  );
}

export default Action;
