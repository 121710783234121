import React, { useState, useEffect } from "react";
import { BiImages, BiArrowBack } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import SelectEvent from "./SelectEvent";
import { useGet } from "../../../../api/useGet";
import { usePut } from "../../../../api/usePut";
import moment from "moment";
import axios from "axios";
import { useAlert } from "react-alert";

const exitImages = [
  "https://res.cloudinary.com/akash-autonomo/image/upload/v1636551127/autonomo/exit/618b5c2e57e4255c4aee70e1_exit__4_hciwqt.jpg",
  "https://res.cloudinary.com/akash-autonomo/image/upload/v1636551127/autonomo/exit/618b5c2e57e4255c4aee70e1_exit__5_hwcp9c.jpg",
  "https://res.cloudinary.com/akash-autonomo/image/upload/v1636551127/autonomo/exit/618b5c2e57e4255c4aee70e1_exit__10_n0w22p.jpg",
  "https://res.cloudinary.com/akash-autonomo/image/upload/v1636551127/autonomo/exit/618b5c2e57e4255c4aee70e1_exit__6_ph72t4.jpg",
  "https://res.cloudinary.com/akash-autonomo/image/upload/v1636551127/autonomo/exit/618b5c2e57e4255c4aee70e1_exit__9_dfqi47.jpg",
  "https://res.cloudinary.com/akash-autonomo/image/upload/v1636551126/autonomo/exit/618b5c2e57e4255c4aee70e1_exit__8_mm6p8c.jpg",
];



function MatchEvent() {
  const history = useHistory();
  const alert = useAlert();

  const exit = JSON.parse(localStorage.getItem("exitData"));
  const time = moment(exit?.exitTime).subtract(120, "minutes");

  //   console.log("exit time: ", moment(time).format("MMM Do YYYY, h:mm A"));
  const shopperList = useGet(`${process.env.REACT_APP_STORE_URL}/user-activities?eventStatus.in=AUTHORIZED,TRACKED&entryTime.lessThanOrEqual=${exit?.exitTime}&entryTime.greaterThanOrEqual=${moment.utc(time).format()}&sort=entryTime,desc`,"shopperList");

  const [select, setSelect] = useState()
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("exitData"))
  );
  const assignUser = usePut(
    `${process.env.REACT_APP_STORE_URL}/user-exit-activities/${user.id}/assign-user`,
    "assignUser"
  );

  const chunk = (myArray, chunk_size) => {
    var index = 0;
    var arrayLength = myArray?.length;
    var tempArray = [];

    for (index = 0; index < arrayLength; index += chunk_size) {
      const myChunk = myArray.slice(index, index + chunk_size);

      tempArray.push(myChunk);
    }
    //console.log("[StoreGallery.js] tempArray ", tempArray)
    return tempArray;
  };

  const goNext = () => {
    console.log("cartRef : ", select?.cartRef);

    if (select && select?.cartRef) {
      assignUser.mutate({ ...user, userActivityId: select?.id });
      const time = {
        exitTime: exit?.exitTime,
        entryTime: select?.entryTime,
        sessionRef: select?.cartRef,
        rootOrgId: select?.rootOrgId,
      };

      localStorage.setItem("eventTime", JSON.stringify(time));

      history.push("/admin/match/openEvents");
    } else {
      alert.error(<div className="w-96">Match the correct event.</div>);
    }
  };

  return (
    <div className="h_screen w-full bg-gray-300">
      <div className="h-8 flex items-center">
        <div className="absolute mx-4 flex items-center">
          <BiArrowBack
            onClick={() => history.goBack()}
            className="h-6 w-6 cursor-pointer"
          />
        </div>

        <div className="w-full flex justify-center items-center">
          <h2>Match the following</h2>
        </div>
      </div>

      <div className="h_camera w-full flex bg-white rounded-t-xl">
        <div className="w-3/4 h-full flex flex-col  ">

          <div className="flex w-full justify-center items-center">
            {exitImages?.map((url) => {
              return (
                <div className="mt-10 mx-1.5">
                  <img src={url} alt="event" className="w-36 h-56 rounded" />
                </div>
              );
            })}
          </div>

          <div className="mt-4 mx-4">
            <div className="flex justify-start my-2">
              <h2>Choose the matching entry</h2>
            </div>

            {/* <div className="flex w-full justify-start mx-4 items-center mt-6">
                  <img src={entryImages} alt="event" className="w-52 h-64 rounded" />
              </div> */}

            <div className="w-full h-80 scrollbar-thin scrollbar-thumb-gray-900 scrollbar-track-gray-100">
              {shopperList?.data?.length > 0 ? (
                chunk(shopperList?.data, 5)?.map((dataRowMap, index) => {
                  console.log("dataMap: ", dataRowMap);
                  return (
                    <div className="flex">
                      {dataRowMap?.map((rowData) => {
                        console.log("rowData: ", rowData);
                        return (
                          <>
                            <SelectEvent
                              data={rowData}
                              index={index}
                              setSelect={setSelect}
                              select={select}
                            />
                          </>
                        );
                      })}
                    </div>
                  );
                })
              ) : (
                <div className="w-full h-76 flex justify-center items-center bg-white">
                  <h2 className="bg-gray-200 rounded p-2">
                    There is no user found in the record.
                  </h2>
                </div>
              )}
            </div>


          </div>
        </div>

        <div className="w-1/4 h-full pr-6 py-10">
          <div className="bg-gray-200 w-full h-full flex flex-col justify-between rounded-lg p-3">
            <div className="w-full flex flex-col items-start">
              <div className="flex flex-col items-start">
                <p>Exit Time: {moment(exit?.exitTime).format("h:mm A")}</p>
                <p>Exit Date: {moment(exit?.exitTime).format("MMM Do YYYY")}</p>
                <p>Store ID: {exit?.retailStoreCode}</p>
              </div>
            </div>
            {select ? (
              <div className="w-full  flex flex-col px-4 py-2 bg-white rounded">
                <div className="my-6 w-full flex justify-center items-center">
                  <img
                    src={select?.img}
                    alt="event"
                    className="w-40 h-32 rounded"
                  />
                </div>

                <div className="flex  items-center">
                  <h2>User:</h2>
                  <h2 className="mx-2 ">{select?.name}</h2>
                </div>

                <div className="flex  items-center mt-2">
                  <h2>Login:</h2>
                  <h2 className="mx-2">{select?.login}</h2>
                </div>

                <div className="flex  items-center mt-2">
                  <h2>Entry Date:</h2>
                  <h2 className="mx-2">
                    {select?.entryTime
                      ? moment(select?.entryTime).format("MMM Do YYYY")
                      : null}
                  </h2>
                </div>

                <div className="flex  items-center mt-2">
                  <h2>Entry time:</h2>
                  <h2 className="mx-2">
                    {select?.entryTime
                      ? moment(select?.entryTime).format("h:mm A")
                      : null}
                  </h2>
                </div>
              </div>
            ) : (
              <div className="w-full  flex flex-col px-4 py-2 bg-white rounded">
                Match the correct event
              </div>
            )}

            <div className="mb-8">
              <button
                onClick={goNext}
                className="bg-green-600 text-white w-24 h-8 rounded"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MatchEvent;
