import React from "react";
import FadeLoader from "react-spinners/FadeLoader";

function SearchInput({input, onChangeInput, filteredData, retailerSelect, loading, label, name}) {
  return (
    <div className="w-68 my-1 relative z-10">
      <div className="flex items-center">
        {/* <h2 className={`text-left ${name !== "singleProduct" ? "w-28" : "w-18" }`}>{label}</h2> */}
        <input
          placeholder={label} 
          value={input[name]}
          onChange={(e) => onChangeInput(e, name)}
          className=" h-10 bg-gray-200 focus:outline-none rounded px-4 w-98"
        />
      </div>
      {(filteredData && input[name].length !== 0 ) && (
        <div className={` absolute shadow-lg w-68 h-24  mt-0.5 bg-gray-200 rounded scrollbar-thin scrollbar-thumb-gray-900 scrollbar-track-gray-100`}>
          <>
            <div
              className={
                loading
                  ? "bg-black bg-opacity-0 absolute inset-0 z-10 flex items-center justify-center"
                  : null
              }
            >
              <FadeLoader className="absolute " loading={loading} size={150} />
            </div>
            {filteredData.map((data, index) => {
              return (
                <div
                  key={index}
                  className="cursor-pointer flex flex-row items-center border-b border-white py-1"
                  onClick={() => retailerSelect(name, data)}
                >
                  <p className="text-left px-4 text-xs">{data?.name}</p>
                  
                </div>
              );
            })}
          </>
        </div>
      )}
    </div>
  );
}

export default SearchInput;

