import React, { useState, useEffect } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { useGet } from "../../../../../../api/useGet";
import OpenItem from "./OpenItem";
import moment from "moment";
import axios from "axios";
import ModalImg from "./ModalImg";

function OpenEvents() {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const time = JSON.parse(localStorage.getItem("eventTime"));

  const url = `${process.env.REACT_APP_STORE_URL}/store-carts/open-cart?eventTime.greaterThanOrEqual=${time?.entryTime}&eventTime.lessThanOrEqual=${time.exitTime}&syncStatus.in=INITIALIZED,CONFLICT&sort=eventTime,desc`;
  const openData = useGet(url, "openList");

  const images = useGet(
    `${process.env.REACT_APP_STORE_URL}/user-activities?eventStatus.equals=CHECKEDOUT&sort=entryTime,desc&cartRef.equals=${time?.sessionRef}`,
    "images"
  );
  const [shopper, setShopperUrl] = useState(null);

  useEffect(() => {
    if (images?.data?.length !== 0) {
      images?.data?.map((img) => setShopperUrl(img?.userImagePaths[0]));
    }
  }, [images]);

  const processCart = async () => {
    const data = {
      sessionRef: JSON.parse(localStorage.getItem("eventTime"))?.sessionRef,
      rootOrgId: JSON.parse(localStorage.getItem("eventTime"))?.rootOrgId,
    };

    try {
      await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_SHOPPER_URL}/stores/cart-review`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
        data: data,
      });
    } catch (error) {
      console.log("err: ", error);
    }

    localStorage.setItem("way", "indirect");
    history.push("/support/hawkeye/cartSummary");
  };

  return (
    <div className="h_screen w-full bg-gray-300">
      <div className="h-8 flex items-center">
        <div className="absolute mx-4 flex items-center">
          <BiArrowBack
            onClick={() => history.push("/support/shopper-activity")}
            className="h-6 w-6 cursor-pointer"
          />
        </div>

        <div className="w-full flex justify-center items-center">
          <h2>Store Event</h2>
        </div>
      </div>

      <div className="h_camera w-full bg-white rounded-t-xl flex">
        <div className="w-7/10 flex flex-col">
          <div className="w-full grid grid-cols-4 gap-4 pt-6">
            <div className="flex justify-center ">
              <img
                src={shopper}
                alt="event"
                onClick={onOpenModal}
                className="w-32 h-20 rounded cursor-pointer"
              />
            </div>

            <ModalImg open={open} onCloseModal={onCloseModal} img={shopper} />
            <div className="border border-blue-500 h-full flex flex-col justify-center items-center">
              <h2> Store Name</h2>
              <p>{"Autonomo GMBH"}</p>
            </div>
            <div className="border border-blue-500 h-full flex flex-col justify-center items-center">
              <h2> Entry Time</h2>
              {time?.entryTime ? (
                <p>{moment(time?.entryTime).format("DD-MM-YYYY HH:MM:SS A")}</p>
              ) : (
                "Null"
              )}
            </div>
            <div className="border border-blue-500 h-full flex flex-col justify-center items-center">
              <h2> Exit Time</h2>
              <p>{moment(time?.exitTime).format("DD-MM-YYYY HH:MM:SS A")}</p>
            </div>
          </div>

          <div className=" h_open  my-8 pr-4 w-full ml-5 scrollbar-thin scrollbar-thumb-gray-900 scrollbar-track-gray-100 ">
            {openData?.data?.map((item, index) => (
              <OpenItem item={item} index={index} />
            ))}
          </div>
        </div>

        <div className="w-3/10 px-12 mt-6 ">
          <div className="w-84 h-96 bg-gray-200 rounded-xl flex justify-center pt-4">
            <h2>Cart Total</h2>
          </div>

          <div className="w-84">
            {openData?.data?.length !== 0 ? (
              <button
                onClick={processCart}
                className="w-32 mt-12 h-10 bg-yellow-500 rounded text-white"
              >
                Process Cart
              </button>
            ) : (
              <button
                //onClick={() => history.push("/support/hawkeye/cartSummary")}
                onClick={processCart}
                className="w-32 mt-12 h-10 bg-green-500 rounded text-white"
              >
                Process Cart
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OpenEvents;
