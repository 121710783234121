import React, { useEffect } from "react";
import ExitImages from "./ExitImages";
import moment from "moment";
import NoImage from "../../../../Images/noImage.jpg";

function AlreadySelected({ data, index, setSelect, select }) {
  useEffect(() => {
    setSelect(data);
  }, [data]);

  // console.log(
  //   "Selected Time: ",
  //   moment(select?.exitTime).format("DD-MM-YYYY hh:mm:ss A")
  // );
  return (
    <>
      {Array.isArray(data) ? (
        <div key={index} className={`flex h-44 w-full space-x-4`}>
          {data?.length > 0 ? (
            <>
              {data?.userImagePaths?.length > 0 ? (
                data?.userImagePaths?.map((url, index) => (
                  <ExitImages
                    url={url}
                    index={index}
                    through={"processedEntry"}
                  />
                ))
              ) : (
                <img src={NoImage} className="w-44 h-44" />
              )}
            </>
          ) : (
            <div className="w-full flex items-center justify-center font-bold">
              No User Exit Activity Available
            </div>
          )}
        </div>
      ) : (
        <div key={index} className={`flex h-44 w-full space-x-4`}>
          {data ? (
            <>
              {data?.userImagePaths?.length > 0 ? (
                data?.userImagePaths?.map((url, index) => (
                  <ExitImages
                    url={url}
                    index={index}
                    through={"processedEntry"}
                  />
                ))
              ) : (
                <img src={NoImage} className="w-44 h-44" />
              )}
            </>
          ) : (
            <div className="w-full flex items-center justify-center font-bold">
              No User Exit Activity Available
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default AlreadySelected;
