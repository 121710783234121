import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import { usePut } from "../../../../../../api/usePut";
import { useGet } from "../../../../../../api/useGet";
import axios from "axios";
import FadeLoader from "react-spinners/FadeLoader";
import { BsFillCircleFill } from "react-icons/bs";
import Mapping from "./Mapping";
import Swipper from "../../../../../../components/Swipper";
import { ImCross } from "react-icons/im";
import { toast } from "react-toastify";
import MappingTabel from "./MappingTabel";
import SurfboardMapping from "./SurfboardMapping";
import GroupMapImage from "../../../../../../Images/Group.png";
import MultiMapImage from "../../../../../../Images/Multi.png";
import FullMapImage from "../../../../../../Images/Full.png";
import CircularProgressLoader from "../../../../../../components/loader/CircularProgressLoader";

const options = [
  {
    label: (
      <span className="text-xs w-full flex justify-start">SKU Fulfillment</span>
    ),
    value: "STANDARD_SKU",
  },
  {
    label: (
      <span className="text-xs w-full flex justify-start">
        Non SKU Fulfillment
      </span>
    ),
    value: "NON_STANDARD_SKU",
  },
];

const mapTypeOptions = [
  {
    label: <span className=" w-full flex justify-start">FULL MAPPING</span>,
    value: "FULL",
  },
  {
    label: <span className=" w-full flex justify-start">MULTI MAPPING</span>,
    value: "PARTIAL",
  },
  {
    label: <span className=" w-full flex justify-start">GROUP MAPPING</span>,
    value: "GROUP",
  },
];

function Fullfillment() {
  const history = useHistory();
  const location = useLocation();
  const [selectedData, setSelectedData] = useState();
  console.log("🚀 ~ Fullfillment ~ selectedData:", selectedData);
  // let [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState();
  const [selectedProduct, setSelectedProduct] = useState("");
  console.log("🚀 ~ Fullfillment ~ selectedProduct:", selectedProduct);
  const [allScales, setAllScales] = useState([]);
  const [scaleESLs, setScaleESLs] = useState([]);
  const [scale, setScale] = useState({
    scaleType: "",
    searchScale: "",
  });

  const [method, setMethod] = useState("");

  const [select, setSelect] = useState({
    fullfillmentType: { label: "SKU Fulfillment", value: "STANDARD_SKU" },
    mappingType: { label: "Map Type", value: "" },
    selectionType: { label: "Select", value: "" },
  });

  const [mapTypeSelect, setMapTypeSelect] = useState();

  const onMapTypeSelect = (selectedType) => {
    setMapTypeSelect(selectedType?.value);
  };

  const [input, setInput] = useState({
    qty: "",
    reason: "",
    name: "",
    barcode: "",
    productPosition: "",
    eslBarCode: "",
    unitWeight: "",
    primaryScaleIdentifier: 1,
    isStockClearanceDiscount: false,
  });

  const {
    state: { rackData, fullfillment: fullfillmentDetails },
  } = location;

  const [colSelect, setColSelect] = useState({
    scales: [],
  });
  console.log("🚀 ~ Fullfillment ~ colSelect:", colSelect);

  const [isLoading, setLoading] = useState(false);

  const id = parseInt(JSON.parse(localStorage.getItem("dashboard-data"))?.id);

  const { data: isStockClearanceDiscount } = useGet(
    `${process.env.REACT_APP_BASE_URL}/_search/org-configurations?page=0&size=10&configName.equals=IS_STOCK_CLEARANCE_DISCOUNT&rootOrgId.equals=${id}`,
    "isStockClearanceDiscount"
  );

  useEffect(() => {
    if (isStockClearanceDiscount && isStockClearanceDiscount?.length > 0) {
      setInput({
        ...input,
        isStockClearanceDiscount: isStockClearanceDiscount?.[0]?.configValue,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStockClearanceDiscount]);

  console.log(
    "🚀 ~ Fullfillment ~ isStockClearanceDiscount:",
    isStockClearanceDiscount
  );

  //all api
  const { data: alreadyScale, isApiLoading } = useGet(
    `${
      process.env.REACT_APP_BASE_URL
    }/scale-products/shelf?sort=column,asc&retailStoreId.equals=${
      rackData.retailStoreId
        ? rackData.retailStoreId
        : fullfillmentDetails?.retailStoreId
    }&shelfName.equals=${rackData?.name}`,
    "selectedScale"
  );

  const cartClose = usePut(
    `${process.env.REACT_APP_BASE_URL}/stores/cart-submit`,
    ""
  );

  const mapCall = usePut(
    `${process.env.REACT_APP_BASE_URL}/stores/map-scale`,
    "selectedScale"
  );

  const unmapCall = usePut(
    `${process.env.REACT_APP_BASE_URL}/stores/unmap-scale`,
    "selectedScale"
  );

  const replenishCall = usePut(
    `${process.env.REACT_APP_BASE_URL}/stores/replenish-scale`,
    "selectedScale"
  );

  const returnCall = usePut(
    `${process.env.REACT_APP_BASE_URL}/stores/return-scale`,
    "selectedScale"
  );

  // close api

  useEffect(() => {
    if (alreadyScale) {
      const scales = [];
      const scaleESL = [];
      const scaleType = alreadyScale[0]?.scaleType;

      for (let i = 0; i < 10; i++) {
        scales.push(
          alreadyScale[i]
            ? alreadyScale[i]
            : {
                ...alreadyScale[i],
                scaleIdentifier: i + 1,
                status: "disabled",
                scaleType: scaleType,
              }
        );
        const eslBarCode = alreadyScale[i]?.eslBarCode;
        scaleESL.push({ scaleIdentifier: i + 1, eslBarCode: eslBarCode });
      }
      setAllScales(scales);
      setScaleESLs(scaleESL);
    }
  }, [alreadyScale]);

  const onSelect = (value, name) => {
    setColSelect({
      scales: [],
      selected: false,
    });

    setSelect({ ...select, [name]: value });
  };

  const onSelectedMethod = (value) => {
    console.log("🚀 ~ onSelectedMethod ~ value:", value);
    cancel();
    setScale({
      scaleType: "",
      searchScale: "",
    });
    setMethod(value);
  };

  const mapFn = () => {
    if (colSelect.scales.length > 0) {
      if (
        selectedProduct != "" &&
        selectedProduct?.unitOfMeasure &&
        selectedProduct?.unitWeight &&
        (selectedProduct?.unitOfMeasure?.toLowerCase() == "gm" ||
        selectedProduct?.unitOfMeasure?.toLowerCase() == "ml"
          ? selectedProduct?.unitWeight <= 1
          : selectedProduct?.unitWeight * 1000 <= 1)
      ) {
        if (
          !selectedProduct.hasOwnProperty("newUnitWeight") ||
          (selectedProduct.hasOwnProperty("newUnitWeight") &&
            selectedProduct?.newUnitWeight <= 1)
        ) {
          toast("Please enter proper weight.");
          return;
        }
      }

      const data = {
        scaleGroupId: fullfillmentDetails?.internalCode,
        product: {
          barCode: selectedProduct?.barCode,
          unitWeight: selectedProduct?.newUnitWeight || null,
        },
        quantity: 6,
        retailStore: {
          id: rackData?.retailStore?.id
            ? rackData.retailStore.id
            : rackData?.retailStoreId,
        },
        scaleProducts: colSelect.scales.map((scale) => {
          return {
            scaleIdentifier: scale,
            productPosition: parseInt(input?.productPosition),
            mapType: mapTypeSelect === "" ? null : mapTypeSelect,
            isStockClearanceDiscount: input?.isStockClearanceDiscount || false,
          };
        }),
        searchScale: scale?.searchScale,
        eslBarCode: input?.eslBarCode,
        isCreateEsl: true,
      };
      mapCall.mutate(data);
      cancel();
    } else {
      toast("Select atleast one scale");
    }
  };

  const unmapFn = () => {
    if (colSelect.scales.length > 0) {
      const data = {
        scaleGroupId: fullfillmentDetails?.internalCode,
        product: {
          barCode: selectedData?.barCode,
        },
        quantity: 6,
        retailStore: {
          id: rackData?.retailStore?.id
            ? rackData.retailStore.id
            : rackData?.retailStoreId,
        },
        scaleProducts: [
          {
            parentScaleIdentifier: selectedData.parentScaleId,
          },
        ],
      };

      unmapCall.mutate(data);
      cancel();
    } else {
      toast("Select atleast one scale");
    }
  };

  const replenishFn = () => {
    if (colSelect.scales.length > 0) {
      const data = {
        scaleGroupId: fullfillmentDetails?.internalCode,
        product: {
          barCode: selectedData?.barCode,
        },
        quantity: input?.qty,
        retailStore: {
          id: rackData?.retailStore?.id
            ? rackData.retailStore.id
            : rackData?.retailStoreId,
        },
        scaleProducts: [
          {
            parentScaleIdentifier: selectedData.parentScaleId,
          },
        ],
      };

      replenishCall.mutate(data);
      cancel();
    } else {
      toast("Select atleast one scale");
    }
  };

  const returnFn = () => {
    if (colSelect.scales.length > 0) {
      const data = {
        scaleGroupId: fullfillmentDetails?.internalCode,
        product: {
          barCode: selectedData?.barCode,
        },
        quantity: input?.qty,
        retailStore: {
          id: rackData?.retailStore?.id
            ? rackData.retailStore.id
            : rackData?.retailStoreId,
        },
        scaleProducts: [
          {
            parentScaleIdentifier: selectedData.parentScaleId,
          },
        ],
      };

      returnCall.mutate(data);
      cancel();
    } else {
      toast("Select atleast one scale");
    }
  };

  const cancel = () => {
    console.log("cancel");
    setMethod("");
    setSelectedProduct("");
    setSelectedData("");
    setMapTypeSelect("");
    setInput({ ...input, eslBarCode: "", primaryScaleIdentifier: null });
    setColSelect({ ...colSelect, scales: [] });

    console.log(mapTypeSelect);
  };

  const confirm = () => {
    if (method === "MAP") {
      mapFn();
    } else if (method === "UNMAP") {
      unmapFn();
    } else if (method === "REPLENISH" && input?.qty) {
      replenishFn();
    } else if (method === "RETURN" && input?.qty) {
      returnFn();
    } else {
      toast("Enter qty");
    }
  };

  useEffect(() => {
    // activeCart();
    setUpCart();
  }, []);

  const setUpCart = async () => {
    setLoading(true);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/stores/setup-cart`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
      data: {
        storeId: rackData.retailStoreId
          ? rackData.retailStoreId
          : fullfillmentDetails?.retailStoreId,
      },
    })
      .then(({ data }) => {
        setLoading(false);
        localStorage.setItem(
          "cart-info",
          JSON.stringify({
            login: data.login,
            sessionRef: data.sessionRef,
            rootOrgId: data.rootOrgId,
          })
        );
      })
      .catch((err) => setLoading(false));
  };

  // const activeCart = async () => {
  //   setLoading(true);

  //   try {
  //     const { data, status, error } = await axios({
  //       method: "GET",
  //       url: `${process.env.REACT_APP_BASE_URL}/stores/cart-current`,
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${localStorage.getItem("user-info")}`,
  //       },
  //     });

  //     if (data) {
  //       localStorage.setItem(
  //         "cart-info",
  //         JSON.stringify({
  //           login: data.login,
  //           sessionRef: data.sessionRef,
  //           rootOrgId: data.rootOrgId,
  //         })
  //       );
  //     }
  //   } catch (error) {
  //     if (error.message) {
  //       console.log("generate data ---------- >");
  //       generateToken();
  //     }
  //   }
  // };

  // const validateToken = (token) => {
  //   if (token) {
  //     axios({
  //       method: "GET",
  //       url: `${process.env.REACT_APP_BASE_URL}/retail-stores/${
  //         rackData.retailStoreId
  //           ? rackData.retailStoreId
  //           : fullfillmentDetails?.retailStoreId
  //       }`,
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${localStorage.getItem("user-info")}`,
  //       },
  //     })
  //       .then((res) => {
  //         const data = {
  //           token: token,
  //           retailerCode: res?.data?.internalCode,
  //         };
  //         axios({
  //           method: "POST",
  //           url: `${process.env.REACT_APP_SHOPPER_URL}/internal/validate-code`,
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${localStorage.getItem("user-info")}`,
  //           },
  //           data: data,
  //         });
  //       })
  //       .then(() => {
  //         axios({
  //           method: "GET",
  //           url: `${process.env.REACT_APP_BASE_URL}/stores/cart-current`,
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${localStorage.getItem("user-info")}`,
  //           },
  //         }).then(({ data }) => {
  //           localStorage.setItem(
  //             "cart-info",
  //             JSON.stringify({
  //               login: data.login,
  //               sessionRef: data.sessionRef,
  //               rootOrgId: data.rootOrgId,
  //             })
  //           );
  //         });
  //       });
  //   } else {
  //     console.log("Generate the token first");
  //   }
  // };

  // const generateToken = () => {
  //   const data = {
  //     // lat: rackData?.retailStore?.address?.lat,
  //     // lng: rackData?.retailStore?.address?.lng,
  //     regenerate: false,
  //   };
  //   axios({
  //     method: "POST",
  //     url: `${process.env.REACT_APP_SHOPPER_URL}/authenticate/request-code`,
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${localStorage.getItem("user-info")}`,
  //     },
  //     data: data,
  //   }).then((res) => {
  //     validateToken(res?.data);
  //   });
  // };

  const onChangeInput = (value, name) => {
    setInput({ ...input, [name]: value });
    console.log("XXXXXX", JSON.parse(localStorage.getItem("dashboard-data")));

    const dashboardData = JSON.parse(
      localStorage.getItem("dashboard-data") || "{}"
    );

    if (name === "name" && value !== "") {
      setInput({ barcode: "" });
      axios({
        method: "GET",
        url: `${
          process.env.REACT_APP_BASE_URL
        }/_search/products?name.contains=${value.toLowerCase()}*&rootOrgId.equals=${
          dashboardData?.rootOrgId
        }`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      })
        .then((res) => {
          setFilteredData(res?.data);
          // console.log("pord: ", res?.data);
        })
        .catch(({ message }) => {
          if (message === "Request failed with status code 401") {
            history.push("/login");
          } else {
            toast.error(message);
          }
        });
    }

    if (name === "barcode" && value !== "") {
      setInput({ name: "" });
      axios({
        method: "GET",
        url: `${
          process.env.REACT_APP_BASE_URL
        }/_search/products?barCode.equals=${value.toLowerCase()}&rootOrgId.equals=${
          dashboardData?.rootOrgId
        }`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      })
        .then((res) => {
          setFilteredData(res?.data);
          // console.log("pord: ", res?.data);
        })
        .catch(({ message }) => {
          if (message === "Request failed with status code 401") {
            history.push("/login");
          } else {
            toast.error(message);
          }
        });
    }
  };

  const removedData = (array, value) => {
    return array?.filter((ele) => ele !== value);
  };

  const existAlready = (array, value) => {
    return array?.some((ele) => ele === value);
  };

  const colSelection = (data) => {
    if (method === "MAP") {
      let primaryScaleIdentifier = input?.primaryScaleIdentifier;
      console.log("selectedData", data);
      if (
        input.primaryScaleIdentifier === null ||
        input.primaryScaleIdentifier === undefined
      ) {
        primaryScaleIdentifier = data?.scaleIdentifier;
        setInput({ ...input, primaryScaleIdentifier: data?.scaleIdentifier });
      }
      if (
        parseInt(input.primaryScaleIdentifier) > parseInt(data?.scaleIdentifier)
      ) {
        primaryScaleIdentifier = data?.scaleIdentifier;
        setInput({
          ...input,
          primaryScaleIdentifier: parseInt(data?.scaleIdentifier),
        });
      }
      let eslBarCodeOfPrimaryScale = input?.eslBarCode;
      for (let x in scaleESLs) {
        let scaleIdentifier = input.primaryScaleIdentifier;
        if (
          input.primaryScaleIdentifier === null ||
          input.primaryScaleIdentifier === undefined
        ) {
          scaleIdentifier = data?.scaleIdentifier;
        }
        if (
          parseInt(scaleESLs[x]["scaleIdentifier"]) ===
          parseInt(primaryScaleIdentifier)
        ) {
          eslBarCodeOfPrimaryScale = scaleESLs[x]["eslBarCode"];
          setInput({ ...input, eslBarCode: eslBarCodeOfPrimaryScale });
          break;
        }
      }
      setInput({
        ...input,
        primaryScaleIdentifier: primaryScaleIdentifier,
        eslBarCode: eslBarCodeOfPrimaryScale,
      });
      setScale({
        scaleType: data?.scaleType,
        searchScale: data?.scaleGroupName,
      });
      if (existAlready(colSelect?.scales, data.scaleIdentifier)) {
        setSelectedData("");
        setColSelect({
          ...colSelect,
          scales: removedData(colSelect?.scales, data.scaleIdentifier),
        });
      } else {
        setColSelect({
          ...colSelect,
          scales: [...colSelect?.scales, data?.scaleIdentifier],
        });
      }
    } else if (method === "UNMAP") {
      if (
        existAlready(colSelect?.scales, data.scaleIdentifier) &&
        colSelect.scales.length === 1
      ) {
        setSelectedData("");
        setColSelect({
          ...colSelect,
          scales: removedData(colSelect?.scales, data.scaleIdentifier),
        });
        setSelectedProduct("");
      } else if (colSelect.scales.length === 0) {
        setColSelect({
          ...colSelect,
          scales: [...colSelect?.scales, data?.scaleIdentifier],
        });
        setSelectedProduct(data?.productInfos);
      }
    } else if (method === "REPLENISH") {
      if (
        existAlready(colSelect?.scales, data.scaleIdentifier) &&
        colSelect.scales.length === 1
      ) {
        setSelectedData("");
        setColSelect({
          ...colSelect,
          scales: removedData(colSelect?.scales, data.scaleIdentifier),
        });
        setSelectedProduct("");
      } else if (colSelect.scales.length === 0) {
        setColSelect({
          ...colSelect,
          scales: [...colSelect?.scales, data?.scaleIdentifier],
        });
        setSelectedProduct(data?.productInfos);
      }
    } else if (method === "RETURN") {
      if (
        existAlready(colSelect?.scales, data.scaleIdentifier) &&
        colSelect.scales.length === 1
      ) {
        setSelectedData("");
        setColSelect({
          ...colSelect,
          scales: removedData(colSelect?.scales, data.scaleIdentifier),
        });
        setSelectedProduct("");
      } else if (colSelect.scales.length === 0) {
        setColSelect({
          ...colSelect,
          scales: [...colSelect?.scales, data?.scaleIdentifier],
        });
        setSelectedProduct(data?.productInfos);
      }
    }
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 1500);
  // }, []);

  const close = () => {
    cartClose.mutate(JSON.parse(localStorage.getItem("cart-info")));
    localStorage.removeItem("cart-info");
    history.goBack();
  };

  function kgToGrams(kg) {
    console.log("🚀 ~ kgToGrams ~ kg:", kg);
    return kg * 1000;
  }

  if (isLoading || isApiLoading)
    return (
      // <div className="bg-black bg-opacity-80 absolute inset-0 flex items-center justify-center">
      //   <div className="w-64 h-20 absolute z-10 text-white flex flex-col items-center justify-center">
      //     <h2 className="">Create cart, please wait ...</h2>
      //     <FadeLoader
      //       color="#FFFFFF"
      //       className=""
      //       loading={loading}
      //       size={150}
      //     />
      //   </div>
      // </div>
      <CircularProgressLoader open={true} />
    );

  return (
    <div className="h_screen w-full bg-gray-300">
      {/* header */}
      {selectedProduct === "" && method === "MAP" && (
        <div className="relative h-[108.7%]  bg-black bg-opacity-80 inset-0 z-10 flex justify-center">
          <div className="bg-white w-[100%] h-[38%]  px-[10px] pt-4">
            <div className="w-[100%] h-[35px] flex space-x-2 justify-between items-center">
              <input
                onChange={(e) => onChangeInput(e.target.value, "name")}
                value={input.name}
                placeholder="Search the product name"
                className="w-[60%] h-[100%] px-[10px] focus:outline-none border border-gray-500 rounded"
              />

              <input
                onChange={(e) => onChangeInput(e.target.value, "barcode")}
                value={input.barcode}
                placeholder="Search the product barcode"
                className="w-[60%] h-[100%] px-[10px] focus:outline-none border border-gray-500 rounded"
              />

              <div
                onClick={() => setMethod("")}
                className="w-[4%] h-[100%] flex space-x-2 justify-center items-center border border-gray-500 rounded"
              >
                <ImCross />
              </div>
            </div>

            {filteredData && filteredData.length > 0 && (
              <div className="scrollbar-thin scrollbar-thumb-gray-900 scrollbar-track-gray-100 w-[100%] h-[79.4%] my-[10px] py-[10px] px-[10px] flex flex-col  space-y-2   border border-gray-400">
                {filteredData?.map((prod, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => setSelectedProduct(prod)}
                      className="w-[100%] h-[100px] flex justify-between items-center border border-gray-300 px-[20px] cursor-pointer"
                    >
                      <div className="w-[10%] h-[100%] border-r border-gray-300 flex items-center">
                        <img
                          src={prod?.imageUrl || "/noImage.jpg"}
                          className="h-[90px] rounded"
                        />
                      </div>
                      <div className="w-[90%] h-[10px]">
                        <p>{prod?.name}</p>
                      </div>
                      <div className="w-[90%] h-[10px]">
                        <p>{prod?.barCode}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}

      <div className="absolute top-0 w-[83.3%] left-[16.7%] h-[100%]">
        <div className="h-8 flex items-center justify-between pr-4">
          <div className="mx-4 flex items-center">
            <div className="w-full flex justify-center items-center ml-0">
              <h2 className="font-bold">
                {select.fullfillmentType?.value === "STANDARD_SKU"
                  ? "Standard SKU Fulfilment"
                  : "Non - Standard SKU Fulfilment"}
              </h2>
            </div>
          </div>
          <div className="ml-[30px] flex justify-between space-x-[10px] items-center">
            <p className="font-bold">
              Rack Name: <span>{rackData?.rack.name}</span>
            </p>
            <p> | </p>
            <p className="font-bold">
              Shelf Name: <span>{rackData?.name}</span>
            </p>
          </div>
          <div>
            <button
              className="focus:outline-none w-24 h-6 cursor-pointer font-bold"
              onClick={close}
            >
              Close Cart
            </button>
          </div>
        </div>

        <div className="h_camera w-full bg-white rounded-t-xl py-3 ">
          {/* top Section */}
          {true && (
            <div className="h-12 w-full flex justify-between items-center pr-4 mt-4">
              <div className="w-[40%] flex  items-center pl-4">
                <button
                  onClick={() => onSelectedMethod("MAP")}
                  className={` rounded h-[35px] w-[100px] ${
                    method === "MAP" ? "bg-green-500" : "bg-[#E5E5E5]"
                  }`}
                >
                  MAP
                </button>
                {/* <button
                  onClick={() => onSelectedMethod("REPLENISH")}
                  className={` rounded h-[35px] w-[100px] ${
                    method === "REPLENISH" ? "bg-green-500" : "bg-[#E5E5E5]"
                  }`}
                >
                  REPLENISH
                </button> */}
                <button
                  onClick={() => onSelectedMethod("UNMAP")}
                  className={` rounded h-[35px] w-[100px] ml-2 ${
                    method === "UNMAP" ? "bg-yellow-500" : "bg-[#E5E5E5]"
                  }`}
                >
                  UNMAP
                </button>
                {/* <button
                  onClick={() => onSelectedMethod("RETURN")}
                  className={` rounded h-[35px] w-[100px] ${
                    method === "RETURN" ? "bg-red-500" : "bg-[#E5E5E5]"
                  }`}
                >
                  RETURN
                </button> */}
              </div>

              <div style={{ width: "180px" }}>
                <Select
                  onChange={(value) => onSelect(value, "fullfillmentType")}
                  value={select?.fullfillmentType}
                  options={options}
                />
              </div>
            </div>
          )}

          <div className="flex flex-col  w-full justify-between items-center mt-6 px-4 ">
            {/* mapping label */}
            <div className="w-full flex justify-between items-center">
              <div>
                <p>Select scale to fulfil</p>
              </div>

              <div className="w-[35%] h-10 flex justify-between pr-1">
                <div className="h-8 flex items-center ">
                  <BsFillCircleFill className="text-green-500" />
                  <p className="ml-4">Mapped</p>
                </div>

                <div className="h-8 flex items-center ">
                  <BsFillCircleFill className="text-red-500" />
                  <p className="ml-4">Unmapped</p>
                </div>

                <div className="h-8 flex items-center">
                  <BsFillCircleFill className="text-black" />
                  <p className="ml-4">Disabled</p>
                </div>

                <div className="h-8 flex items-center">
                  <BsFillCircleFill className="text-yellow-500" />
                  <p className="ml-4">Selected</p>
                </div>
              </div>
            </div>

            {/* all scale */}
            <div className="w-[100%] flex items-center bg-gray-200 px-[15px] rounded justify-between space-x-[10px]">
              {select?.fullfillmentType.value === "STANDARD_SKU" ? (
                <div className="w-[100%] flex justify-center items-center pt-[15px] pb-[15px]">
                  {allScales?.map((data, index) => {
                    return (
                      <>
                        {data.scaleType === "SURFBOARD" &&
                        data.status !== "disabled" ? (
                          <SurfboardMapping
                            key={index}
                            data={data}
                            index={index}
                            method={method}
                            colSelect={colSelect}
                            colSelection={colSelection}
                            selectedData={selectedData}
                            mappingType={select?.mappingType?.value}
                            setSelectedData={setSelectedData}
                          />
                        ) : data.scaleType !== "SURFBOARD" ? (
                          <Mapping
                            key={index}
                            data={data}
                            index={index}
                            method={method}
                            colSelect={colSelect}
                            colSelection={colSelection}
                            selectedData={selectedData}
                            mappingType={select?.mappingType?.value}
                            setSelectedData={setSelectedData}
                          />
                        ) : null}
                      </>
                    );
                  })}
                </div>
              ) : (
                <div className="w-full h-52  cursor-pointer flex  justify-center items-center ">
                  <div className="w-96 h-48 bg-gray-500 rounded"></div>
                </div>
              )}
            </div>
          </div>

          {/* showing mapped products */}
          <div className="w-full h-[45%] px-4 mt-[17px] flex items-center justify-center">
            {method !== "" ? (
              <div className="w-[90%] h-[100%] flex items-center justify-center space-x-32">
                {!Array.isArray(selectedProduct) && selectedProduct ? (
                  <div className=" h-[150px] flex flex-col justify-center items-center space-y-[4px]">
                    <img
                      src={selectedProduct?.imageUrl || "/noImage.jpg"}
                      className={`w-[150px] h-full bg-cover ${
                        selectedProduct?.imageUrl === "" && "border"
                      }`}
                      alt="noImage"
                    />

                    <p>{selectedProduct?.name}</p>
                    <p>{selectedProduct?.barCode}</p>
                  </div>
                ) : selectedProduct?.length > 0 ? (
                  <>
                    <Swipper
                      arr={selectedProduct.filter((ele) => ele.isPrimary)}
                      selectedData={selectedData}
                      setSelectedData={setSelectedData}
                    />
                  </>
                ) : null}

                <div
                  className={`w-[200px] min-h-[170px] max-h-[200px] ${
                    method === "RETURN" ? "space-y-3" : "space-y-5"
                  }  flex flex-col justify-center items-center`}
                >
                  {(method === "RETURN" || method === "REPLENISH") && (
                    <>
                      {method === "RETURN" && (
                        <textarea
                          className="w-full focus:outline-none min-h-[70px] border border-gray-500 rounded-lg px-1"
                          placeholder="Enter Reason"
                          onChange={(e) =>
                            setInput({ ...input, reason: e.target.value })
                          }
                        />
                      )}
                      <input
                        className="w-[80px] text-center focus:outline-none border  border-gray-500 rounded"
                        placeholder="Qty"
                        onChange={(e) =>
                          setInput({ ...input, qty: e.target.value })
                        }
                        type="number"
                      />
                    </>
                  )}
                  <div className="w-full h-full flex flex-col justify-between items-center space-y-6">
                    {method === "MAP" && scale?.scaleType === "SURFBOARD" && (
                      <input
                        type="number"
                        placeholder="Product Position"
                        value={input.productPosition}
                        onChange={(e) =>
                          setInput({
                            ...input,
                            productPosition: e.target.value,
                          })
                        }
                        className="px-2 py-2 text-center focus:outline-none border  border-gray-500 rounded"
                      />
                    )}
                    {method === "MAP" && (
                      <Select
                        className="px-2 py-2 text-center focus:outline-none border w-full border-gray-500 rounded"
                        onChange={(value) => onMapTypeSelect(value)}
                        // value={select?.fullfillmentType}
                        options={mapTypeOptions}
                        placeholder="Select Map Type"
                      />
                    )}
                    {method === "MAP" && (
                      <div>
                        <p>ESL BarCode (Mandatory)</p>
                        <input
                          type="text"
                          placeholder=""
                          value={input.eslBarCode}
                          onChange={(e) =>
                            setInput({
                              ...input,
                              eslBarCode: e.target.value,
                            })
                          }
                          className="px-2 py-2 text-center focus:outline-none border  border-gray-500 rounded"
                        />
                      </div>
                    )}
                    {method === "MAP" &&
                      selectedProduct != "" &&
                      selectedProduct?.unitOfMeasure &&
                      selectedProduct?.unitWeight &&
                      (selectedProduct?.unitOfMeasure?.toLowerCase() == "gm" ||
                      selectedProduct?.unitOfMeasure?.toLowerCase() == "ml"
                        ? selectedProduct?.unitWeight <= 1
                        : selectedProduct?.unitWeight * 1000 <= 1) && (
                        <div>
                          <p>
                            Unit weight (in {selectedProduct?.unitOfMeasure})
                            (Mandatory)
                          </p>
                          <div className="relative">
                            <input
                              type="text"
                              value={selectedProduct.newUnitWeight}
                              defaultValue={selectedProduct.unitWeight}
                              onChange={(e) =>
                                setSelectedProduct({
                                  ...selectedProduct,
                                  newUnitWeight: e.target.value,
                                })
                              }
                              className="px-2 py-2 text-center focus:outline-none border border-gray-500 rounded w-full"
                              placeholder="Enter the Gross unit weight in gms"
                            />
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                              <img
                                alt=""
                                src={
                                  selectedProduct?.newUnitWeight > 1
                                    ? "/icons/okIcon.svg"
                                    : "/icons/warningIcon.svg"
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    {method === "MAP" &&
                      isStockClearanceDiscount?.length > 0 &&
                      (isStockClearanceDiscount?.[0]?.configValue?.toLowerCase() ==
                        "true" ||
                        isStockClearanceDiscount?.[0]?.configValue == true) &&
                      selectedProduct?.isStockClearanceDiscount === true && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <p style={{ marginRight: "10px" }}>Enable Discount</p>
                          <input
                            type="checkbox"
                            placeholder=""
                            checked={input.isStockClearanceDiscount}
                            onChange={(e) => {
                              setInput({
                                ...input,
                                isStockClearanceDiscount: e.target.checked,
                              });
                            }}
                            className="px-2 py-2 text-center focus:outline-none border  border-gray-500 rounded"
                          />
                        </div>
                      )}
                    <div className="w-full  flex justify-between items-center">
                      <button
                        onClick={confirm}
                        className="bg-green-500 h-[35px] w-[90px] text-center rounded-lg"
                      >
                        Confirm
                      </button>
                      <button
                        onClick={cancel}
                        className="bg-gray-500 h-[35px] w-[90px] text-center rounded-lg"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
                {method === "MAP" && (
                  <div
                    className={`w-[400px] h-[400px] space-y-3 flex flex-col justify-center items-center border border-black rounded-lg`}
                  >
                    {/* <MdInfoOutline className="info-icon"/> */}
                    <p>
                      <span className="font-bold">
                        Mapping Type Illustration
                      </span>
                    </p>
                    <div>
                      <img
                        src={FullMapImage}
                        className="w-full h-[100px] rounded"
                        alt="Full Mapping"
                      />
                    </div>
                    <div>
                      <img
                        src={MultiMapImage}
                        className="w-full h-[100px] rounded"
                        alt="Multi Mapping"
                      />
                    </div>
                    <div>
                      <img
                        src={GroupMapImage}
                        className="w-full h-[100px] rounded"
                        alt="Group Mapping"
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="w-[100%] h-[100%] scrollbar-thin scrollbar-thumb-gray-900 scrollbar-track-gray-100">
                <table className="w-[100%] h-[100%] table-auto border border-gray-400">
                  <thead className="border-b border-gray-400">
                    <tr>
                      <th className="w-[10%] border-r border-gray-400">
                        Scale
                      </th>
                      <th className="w-[45%] border-r border-gray-400">
                        Product Image
                      </th>
                      <th className="w-[45%] border-r border-gray-400">
                        Product Name
                      </th>
                    </tr>
                  </thead>
                  {selectedData ? (
                    <MappingTabel
                      prod={selectedData}
                      isStockClearanceDiscount={isStockClearanceDiscount}
                    />
                  ) : (
                    allScales?.map((prod, index) => (
                      <MappingTabel
                        prod={prod}
                        key={index}
                        isStockClearanceDiscount={isStockClearanceDiscount}
                      />
                    ))
                  )}
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Fullfillment;
