import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { useAlert } from "react-alert";
import NumberFormat from "react-number-format";
import ProductItem from "./ProductItem";
import OrderSummaryHeader from "./OrderSummaryHeader";
import moment from "moment";
import { BsCloudDownload } from "react-icons/bs";
import ModalSession from "./ModalSession";
import { Languages1 } from "../../../../../../../Languages";
import Cookies from "universal-cookie";
import { useQuery } from "react-query";
import axios from "axios";
import {toast} from 'react-toastify'

function RefundSummary({ header }) {

  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const cookies = new Cookies();
  const [input, setInput] = useState({
    qty: "",
    reviewQty: "",
  });

  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const data = JSON.parse(localStorage.getItem("orderData"));
  const { state:{sessionRef}} = useLocation(

  )

 

// http://api.shopper-uat.autonomo.tech/order-refunds?projection=OrderListDTO&sort=createdDate,desc&page=0&size=9
//  const [id, setId] = useState(location?.state?.id)
//  const fetchData = async (id) => {
//    const res = await axios({
//      method: "GET",
//      url: `${process.env.REACT_APP_SHOPPER_URL}/order-refunds/${id}`,
//      headers: {
//        "Content-Type": "application/json",
//        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
//      },
//    });
//    return res.data;
//  };

//  const { data, isLoading, isIdle, error } = useQuery(
//    ["refundData", id],
//    async () => fetchData(id),
//    {
//      enabled: !!id,
//      cacheTime: 5,
//    }
//  );

  

  const detectedLan = (code) => {
    return Languages1?.filter((item) => {
      return item?.code === code;
    });
  };

  const fetchDownload = (url, name) => {
    const res = fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      }),
    })
      .then((res) => res.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${name}.pdf`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };
  const download = async () => {
    fetchDownload(
      `${process.env.REACT_APP_SHOPPER_URL}/orders/${
        data?.id
      }/download?langKey=${detectedLan(cookies.get("i18next"))[0]?.value}`,
      `${data?.orderNumber}`
    );
  };

  const verifyRefund = () => {
    axios({
      method: "PUT",
      url: `${process.env.REACT_APP_SHOPPER_URL}/order-refunds/${location?.state?.id}/verify-order-refund`,
      
      headers: {
        "Content-Type": "application/json",
         Authorization:  `Bearer ${localStorage.getItem("user-info")}`  
      },
      data: data
    }).then(()=>{
      localStorage.setItem("navigate", "refunds")
      history.goBack();
    }).catch(({ message }) => {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message)
      }
    })
  }

  return (
    <>
      <div
        className={`w-full ${
          header ? "h-[100vh]" : "h-screen pt-4"
        }  text-left flex select-none bg-gray-200`}
      >
        <div className="w-full h-full px-4  py-2">
          <div className="w-full  h-full  ">
            <div className="w-full h-8  flex bg-white rounded-lg">
              <div className="w-10 p-2 h-full flex items-center">
                <BiArrowBack
                  onClick={() => history.goBack()}
                  className="h-10 w-10 cursor-pointer"
                />
              </div>
              <div className="w-full h-full px-2 flex flex-col justify-center">
                <h2 className="font-bold text-xl">
                  Refunded No : {data?.refundOrderNumber}
                </h2>
              </div>
            </div>

            <div className="w-full h-36 rounded-lg px-2 bg-white my-2 py-2 ">
              <h2 className="px-2 font-bold">Refund Summary :</h2>

              <div className="w-full flex justify-between px-2 pt-2  ">
                <div className="w-1/3 border-l-2 border-bg-500 text-gray-500 px-2">
                  <h2>
                    {`Login: `}
                    <NumberFormat
                      displayType={"text"}
                      value={data?.login}
                      format="###-###-####"
                    />
                  </h2>
                </div>
                <div className="w-1/3 border-l-2 border-bg-500 text-gray-500 px-2">
                  <h2>
                    {`Status: `} {data?.refundStatus}
                  </h2>
                </div>
                <div className="w-1/3 border-l-2 border-bg-500 text-gray-500 px-2">
                  <h2>
                    {`Retailer store:`} {data?.storeName}
                  </h2>
                </div>
              </div>
              {/* secondLine */}

              <div className="w-full flex justify-between px-2 pt-2  ">
                <div className="w-1/3 border-l-2 border-bg-500 text-gray-500 px-2">
                  <h2>
                    {`Email:`} {data?.email ? data?.email : "Nill"}
                  </h2>
                </div>
                <div className="w-1/3 border-l-2 border-bg-500 text-gray-500 px-2">
                  <h2>
                    {`Name: `} {data?.firstName ? data?.firstName : "Nill"}{" "}
                    {data?.userDTO?.lastName ? data?.userDTO?.lastName : null}
                  </h2>
                </div>
                <div className="w-1/3 flex border-l-2 border-bg-500 text-gray-500 px-2">
                  <div className="mr-20">
                    <h2 className="">
                      {`Total :`} {" "}
                      {data?.formattedRefundAmountCurrency}
                    </h2>
                  </div>
                  {/* <div className="flex ">
                    <h2 className="mr-3">Receipt</h2>
                    <BsCloudDownload
                      className="w-5 h-5 mt-1 cursor-pointer hover:text-blue-500 "
                      onClick={download}
                    />
                  </div> */}
                </div>
              </div>

              {/* thirdLine */}
              <div className="w-full flex justify-start px-2 pt-2  ">
                <div className="w-1/3 border-l-2 border-bg-500 text-gray-500 pl-2">
                  <h2>
                    {`Refunded Time :`}{" "}
                    {moment(data?.refundOrderTime).format(
                      "MMMM Do YYYY, h:mm A"
                    )}
                  </h2>
                </div>

                <div className="w-1/3 border-l-2 border-bg-500 text-gray-500 pl-2">
                  <h2>
                    {`Refunded Type :`} {`${data?.refundType}`}
                  </h2>
                </div>

                {/* <div className="px-8">
                  <button
                    onClick={onOpenModal}
                    className="text-blue-500 cursor-pointer focus:outline-none"
                  >
                
                    Refunded Summary
                  </button>
                </div>
                <div className="">
                  <button
                    onClick={()=>history.push('/order/refund')}
                    className="text-blue-500 cursor-pointer focus:outline-none"
                  >
                    
                    Refund Initiate
                  </button>
                </div> */}
              </div>
            </div>

            <ModalSession sessionRef = {sessionRef} open={open} onCloseModal={onCloseModal} />

            <div className="w-full h-7.1/10 rounded-lg bg-white flex flex-col ">
             
              <div>
                <OrderSummaryHeader path="OrderSummary" />
                <div className="h-88 mt-2  px-1.5 scrollbar-thin  scrollbar-thumb-gray-500 scrollbar-thumb-rounded-lg scrollbar-track-gray-100">
                  {data?.refundOrderItems?.map((item, index) => (
                    <ProductItem
                      data={data}
                      item={item}
                      index={index}
                      input={input}
                      setInput={setInput}
                      path={"OrderSummary"}
                    />
                  ))}
                  <h2 className="w-full text-2xl text-gray-500 pr-24 flex justify-end items-center">
                    <span className="mr-4">Total Order</span>
                    {data?.formattedRefundAmountCurrency}
                  </h2>
                </div>
              </div>

             {data?.refundStatus === "REFUND_SUBMITTED" && ( <div className="w-full flex justify-end mt-4">
                <button
                  className="bg-gray-300 px-4 h-8 rounded-lg mb-1.5 mr-20 focus:outline-none"
                  onClick={verifyRefund}
                >
                  Verify Order Refund
                </button>
              </div>)}

            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default RefundSummary;
