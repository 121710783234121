import React from "react";

import NotificationsHeader from "./header/NotificatonHeader";
import NotificationTemplateTable from "./notificationTemplateTable/NotificationTemplateTable";

const Notifications = () => {
  return (
    <div>
      <NotificationsHeader />
      <div className="p-5">
        <NotificationTemplateTable />
      </div>
    </div>
  );
};

export default Notifications;
