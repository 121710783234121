import React from "react";
import { GrView } from "react-icons/gr";
import { FiEdit } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import axios from "axios";

function Action({ params, url, viewUrl, editUrl }) {
  const history = useHistory();

  const Edit = async () => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}/orgs/${params?.data?.id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
    }).then((res) => {
      history.push({
        pathname: editUrl,
        state: {
          data: res?.data,
          id: params?.data?.id,
          getName: "merchantList",
        },
      });
    });
  };

  const view = async () => {
    history.push({
      pathname: viewUrl,
      state: { id: params?.data?.id },
    });
  };

  return (
    <>
      <div className="flex w-16 h-10 items-center">
        <div className="border-2 border-gray-200 rounded-l-lg p-1 ">
          <GrView className="w-5 h-5 cursor-pointer " onClick={view} />
        </div>
        <div className="border-2 border-gray-200 rounded-r-lg p-1">
          <FiEdit className="w-5 h-5 cursor-pointer" onClick={Edit} />
        </div>
      </div>
    </>
  );
}

export default Action;
