import React from "react";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";

// Define custom styles for your button
const useStyles = makeStyles((theme) => ({
  customButton: {
    borderRadius: 4,
    padding: "10px 20px",
    fontWeight: "bold",
    "&:hover": {
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
  },
  greenButton: {
    backgroundColor: "#006600",
    borderRadius: "8px",
    color: "white",
    "&:hover": {
      backgroundColor: "#005500",
    },
  },
  orangeButton: {
    backgroundColor: "#D9A032",
    borderRadius: "8px",
    color: "white",
    "&:hover": {
      backgroundColor: "#C7892B",
    },
  },
  blueButton: {
    backgroundColor: "#5885D3",
    borderRadius: "8px",
    color: "white",
    "&:hover": {
      backgroundColor: "#4B74C1",
    },
  },
  greyButton: {
    backgroundColor: "#868686",
    borderRadius: "8px",
    color: "white",
    "&:hover": {
      backgroundColor: "#646464",
    },
  },
}));

export default useStyles;
