import React from "react";

import { Link } from "react-router-dom";

function DashboardRoute({ params }) {
  const clicked = () => {
    localStorage.setItem("machineId", JSON.stringify(params?.data));
  };

  return (
    <>
      <Link
        onClick={clicked}
        to={{
          pathname: "/admin/merchant/dashboard/machine/management",
        }}
        className="text-blue-900 font-bold"
      >
        {params.value}
      </Link>
    </>
  );
}

export default DashboardRoute;
