import React, { useEffect, useRef, useState } from "react";
import usePasswordChange from "./usePasswordChange";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { toast } from "react-toastify";
import ChangePasswordCard from "./ChangePassword";
import { Card, CardContent, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  customCard: {
    borderRadius: "12px", // Adjust the border radius to your desired value
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1), 0 0 20px rgba(0, 0, 0, 0.1)", // Customize the box shadow to make it visible from all sides
  },
});

const initialValidations = {
  isSpecialCharacterInMiddleExists: false,
  isUppercaseCharacterExists: false,
  isUppercaseCharacterInMiddleExists: false,
  isLowercaseCharacterExists: false,
  isSpecialCharacterExists: false,
  isCharacterLengthExists: false,
  isNumericCharacterExists: false,
  isNumericCharacterInMiddleExists: false,
};

const PasswordChange = () => {
  const classes = useStyles();

  const [input, setInput] = useState({
    currentPassword: "",
    newPassword: "",
  });
  const oldText = useRef("");

  const [validations, setValidations] = useState({ ...initialValidations });

  const { passwordChange, passwordValidations } = usePasswordChange(input);

  const onChangeInput = async (e, name) => {
    oldText.current = input.newPassword;
    setInput({ ...input, [name]: e.target.value });
    // console.log("oldText", oldText.current?.length, e.target.value?.length);

    if (name === "newPassword") {
      if (e.target.value?.length > 4) {
        const tempValidatioins = await passwordValidations(e.target.value);
        setValidations(tempValidatioins?.data);
      } else {
        setValidations({ ...initialValidations });
      }
    }
  };

  // const tempValidatioinsFun = async () =>
  //   await passwordValidations(input?.newPassword);

  // useEffect(() => {
  //   const tempValidatioins = tempValidatioinsFun(input?.newPassword);
  //   setValidations(tempValidatioins);
  //   console.log(
  //     "🚀 ~ file: index.js:55 ~ submit ~ validatioins:",
  //     tempValidatioins
  //   );
  // }, [input?.newPassword]);

  const submit = async () => {
    if (input?.newPassword?.length < 5) {
      toast.error("Please enter atleast 4 character");
      return;
    }
    const tempValidatioins = await passwordValidations(input?.newPassword);
    setValidations(tempValidatioins);
    console.log(
      "🚀 ~ file: index.js:55 ~ submit ~ validatioins:",
      tempValidatioins
    );
    const res = await passwordChange();
    console.log("🚀 ~ file: index.js:84 ~ submit ~ res:", res, res.ok);
    // setInput({
    //   currentPassword: "",
    //   newPassword: "",
    // });
    // setValidations({ ...initialValidations });
  };

  return (
    <div
      className={`w-full ${
        localStorage.getItem("role") === "admin" ? "h_screen" : "h-full"
      }  flex flex-col justify-center items-center`}
    >
      <Container maxWidth="sm">
        <Card className={classes.customCard}>
          <CardContent sx={{ p: 3 }}>
            <ChangePasswordCard
              onChangeInput={onChangeInput}
              submit={submit}
              input={input}
              setInput={setInput}
              validations={validations}
              setValidations={setValidations}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
                textAlign: "start",
                marginTop: "10px",
              }}
            >
              <span style={{ marginTop: "8px", display: "flex" }}>
                {validations?.isLowercaseCharacterExists ? (
                  <CheckCircleIcon style={{ color: "green", marginRight: 2 }} />
                ) : (
                  <CancelIcon style={{ color: "red", marginRight: 2 }} />
                )}
                A minimum of 1 lower case letter [a-z]
              </span>
              <span style={{ marginTop: "8px", display: "flex" }}>
                {validations?.isUppercaseCharacterExists ? (
                  <CheckCircleIcon style={{ color: "green", marginRight: 2 }} />
                ) : (
                  <CancelIcon style={{ color: "red", marginRight: 2 }} />
                )}
                A minimum of 1 upper case letter [A-Z]
              </span>
              <span style={{ marginTop: "8px", display: "flex" }}>
                {validations?.isNumericCharacterExists ? (
                  <CheckCircleIcon style={{ color: "green", marginRight: 2 }} />
                ) : (
                  <CancelIcon style={{ color: "red", marginRight: 2 }} />
                )}
                A minimum of 1 numeric character [0-9]
              </span>
              <span style={{ marginTop: "8px", display: "flex" }}>
                {validations?.isSpecialCharacterExists ? (
                  <CheckCircleIcon style={{ color: "green", marginRight: 2 }} />
                ) : (
                  <CancelIcon style={{ color: "red", marginRight: 2 }} />
                )}
                {
                  'A minimum of 1 special character: ~`!@#$%^&*()-_+={}[]|;:"<>,./?'
                }
              </span>
              <span style={{ marginTop: "8px", display: "flex" }}>
                {validations?.isNumericCharacterInMiddleExists &&
                validations?.isSpecialCharacterInMiddleExists &&
                validations?.isUppercaseCharacterInMiddleExists ? (
                  <CheckCircleIcon style={{ color: "green", marginRight: 2 }} />
                ) : (
                  <CancelIcon style={{ color: "red", marginRight: 2 }} />
                )}
                At least 1 upper case, numeric, and special character must be
                EMBEDDED somewhere in the middle of the password, and not just
                be the first or the last character of the password string.
              </span>
              <span style={{ marginTop: "8px", display: "flex" }}>
                {validations?.isCharacterLengthExists ? (
                  <CheckCircleIcon style={{ color: "green", marginRight: 2 }} />
                ) : (
                  <CancelIcon style={{ color: "red", marginRight: 2 }} />
                )}
                Passwords must be at least 10 characters in length, but can be
                much longer.
              </span>
              {/* <span style={{ marginTop: '8px' }}>
            {validations?.isUnique ? (
              <CheckCircleIcon style={{ color: "green", marginRight: 2 }} />
            ) : (
              <CancelIcon style={{ color: "red", marginRight: 2 }} />
            )}
            Passsword should not be the last 3 used ones
          </span> */}
            </div>
          </CardContent>
        </Card>
      </Container>
      {/* <div className="w-full max-w-lg"> */}
      {/* <div className="bg-white shadow-lg rounded-lg px-8 pt-6 pb-8 mb-4 text-left border">
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="currentPassword"
            >
              Current Password
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="password"
              placeholder="*****"
              autoComplete="off"
              value={input.currentPassword}
              onChange={(e) => onChangeInput(e.target.value, "currentPassword")}
            />
          </div>
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="newPassword"
            >
              New Password
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              type="password"
              placeholder="*****"
              autoComplete="off"
              value={input.newPassword}
              onChange={(e) => onChangeInput(e.target.value, "newPassword")}
            />
          </div>
          <div className="flex items-center justify-center">
            <button
              className="bg-blue-500 hover-bg-blue-700 text-white font-bold py-1.5 px-5 rounded-full focus-outline-none focus-shadow-outline"
              onClick={submit}
            >
              Change password
            </button>
          </div>
        </div> */}

      {/* </div> */}
    </div>
  );
};

export default PasswordChange;
