import React, { useState } from "react";
import { useGet } from "../../../../../api/useGet";
import { IoIosClose } from "react-icons/io";
import { useHistory } from "react-router-dom";

function Id({ open, onCloseMap }) {
  const history = useHistory();

  const { data } = useGet(
    `${process.env.REACT_APP_BASE_URL}/api/suppliers?page=0&sort=createdDate`,
    "supplierList"
  );

  return (
    <>
      {open && (
        <div className="w-8/10 z-10 h_modal bg-gray-300  px-4 py-2  absolute top-1/2 left-1/2 ml-28 mt-7 transform -translate-x-1/2 -translate-y-1/2 rounded-lg ">
          <div className="w-full h-12 border-2 border-gray-200 bg-blue-100 rounded-lg flex items-center justify-end select-none ">
            <h2 className="select-none w-full text-center">
              Variants Mapped to the Machine
            </h2>
            <IoIosClose
              onClick={() => onCloseMap()}
              className="h-6 w-6 cursor-pointer mr-5"
            />
          </div>

          <div className="w-full h-0.88 mt-4 rounded-lg overflow-x-hidden overflow-y-auto p-2">
            <section className="w-full h-full text-gray-600 body-font">
              <div className="flex flex-wrap -m-4">
                <div className="lg:w-1/4 md:w-1/2 p-4 w-full">
                  <div className="">
                    <p className="mt-1">Position: 1R 1C</p>
                  </div>

                  <a className="block relative h-48 rounded overflow-hidden">
                    <img
                      alt="ecommerce"
                      className="object-cover object-center w-full h-full block"
                      src="https://images.unsplash.com/photo-1546695259-ad30ff3fd643?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=889&q=80"
                    />
                  </a>
                  <div className="mt-4">
                    <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">
                      juice - Pineapple Delight 1 L
                    </h3>
                    <p className="mt-1">$16.00</p>
                  </div>
                </div>
                <div className="lg:w-1/4 md:w-1/2 p-4 w-full">
                  <div className="">
                    <p className="mt-1">Position: 1R 1C</p>
                  </div>
                  <a className="block relative h-48 rounded overflow-hidden">
                    <img
                      alt="ecommerce"
                      className="object-cover object-center w-full h-full block"
                      src="https://dummyimage.com/421x261"
                    />
                  </a>
                  <div className="mt-4">
                    <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">
                      juice - Pineapple Delight 1 L
                    </h3>

                    <p className="mt-1">$21.15</p>
                  </div>
                </div>
                <div className="lg:w-1/4 md:w-1/2 p-4 w-full">
                  <div className="">
                    <p className="mt-1">Position: 1R 1C</p>
                  </div>
                  <a className="block relative h-48 rounded overflow-hidden">
                    <img
                      alt="ecommerce"
                      className="object-cover object-center w-full h-full block"
                      src="https://dummyimage.com/422x262"
                    />
                  </a>
                  <div className="mt-4">
                    <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">
                      juice - Pineapple Delight 1 L
                    </h3>

                    <p className="mt-1">$12.00</p>
                  </div>
                </div>
                <div className="lg:w-1/4 md:w-1/2 p-4 w-full">
                  <div className="">
                    <p className="mt-1">Position: 1R 1C</p>
                  </div>
                  <a className="block relative h-48 rounded overflow-hidden">
                    <img
                      alt="ecommerce"
                      className="object-cover object-center w-full h-full block"
                      src="https://dummyimage.com/423x263"
                    />
                  </a>
                  <div className="mt-4">
                    <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">
                      juice - Pineapple Delight 1 L
                    </h3>

                    <p className="mt-1">$18.40</p>
                  </div>
                </div>
                <div className="lg:w-1/4 md:w-1/2 p-4 w-full">
                  <div className="">
                    <p className="mt-1">Position: 1R 1C</p>
                  </div>
                  <a className="block relative h-48 rounded overflow-hidden">
                    <img
                      alt="ecommerce"
                      className="object-cover object-center w-full h-full block"
                      src="https://dummyimage.com/424x264"
                    />
                  </a>
                  <div className="mt-4">
                    <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">
                      juice - Pineapple Delight 1 L
                    </h3>

                    <p className="mt-1">$16.00</p>
                  </div>
                </div>
                <div className="lg:w-1/4 md:w-1/2 p-4 w-full">
                  <div className="">
                    <p className="mt-1">Position: 1R 1C</p>
                  </div>
                  <a className="block relative h-48 rounded overflow-hidden">
                    <img
                      alt="ecommerce"
                      className="object-cover object-center w-full h-full block"
                      src="https://dummyimage.com/425x265"
                    />
                  </a>
                  <div className="mt-4">
                    <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">
                      juice - Pineapple Delight 1 L
                    </h3>

                    <p className="mt-1">$21.15</p>
                  </div>
                </div>
                <div className="lg:w-1/4 md:w-1/2 p-4 w-full">
                  <div className="">
                    <p className="mt-1">Position: 1R 1C</p>
                  </div>
                  <a className="block relative h-48 rounded overflow-hidden">
                    <img
                      alt="ecommerce"
                      className="object-cover object-center w-full h-full block"
                      src="https://dummyimage.com/427x267"
                    />
                  </a>
                  <div className="mt-4">
                    <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">
                      juice - Pineapple Delight 1 L
                    </h3>

                    <p className="mt-1">$12.00</p>
                  </div>
                </div>
                <div className="lg:w-1/4 md:w-1/2 p-4 w-full">
                  <div className="">
                    <p className="mt-1">Position: 1R 1C</p>
                  </div>
                  <a className="block relative h-48 rounded overflow-hidden">
                    <img
                      alt="ecommerce"
                      className="object-cover object-center w-full h-full block"
                      src="https://dummyimage.com/428x268"
                    />
                  </a>
                  <div className="mt-4">
                    <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">
                      juice - Pineapple Delight 1 L
                    </h3>

                    <p className="mt-1">$18.40</p>
                  </div>
                </div>
                <div className="lg:w-1/4 md:w-1/2 p-4 w-full">
                  <a className="block relative h-48 rounded overflow-hidden">
                    <div className="">
                      <p className="mt-1">Position: 1R 1C</p>
                    </div>
                    <img
                      alt="ecommerce"
                      className="object-cover object-center w-full h-full block"
                      src="https://dummyimage.com/428x268"
                    />
                  </a>
                  <div className="mt-4">
                    <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">
                      juice - Pineapple Delight 1 L
                    </h3>

                    <p className="mt-1">$18.40</p>
                  </div>
                </div>
                <div className="lg:w-1/4 md:w-1/2 p-4 w-full">
                  <a className="block relative h-48 rounded overflow-hidden">
                    <div className="">
                      <p className="mt-1">Position: 1R 1C</p>
                    </div>
                    <img
                      alt="ecommerce"
                      className="object-cover object-center w-full h-full block"
                      src="https://dummyimage.com/428x268"
                    />
                  </a>
                  <div className="mt-4">
                    <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">
                      juice - Pineapple Delight 1 L
                    </h3>

                    <p className="mt-1">$18.40</p>
                  </div>
                </div>
                <div className="lg:w-1/4 md:w-1/2 p-4 w-full">
                  <div className="">
                    <p className="mt-1">Position: 1R 1C</p>
                  </div>
                  <a className="block relative h-48 rounded overflow-hidden">
                    <img
                      alt="ecommerce"
                      className="object-cover object-center w-full h-full block"
                      src="https://dummyimage.com/428x268"
                    />
                  </a>
                  <div className="mt-4">
                    <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">
                      CATEGORY
                    </h3>

                    <p className="mt-1">$18.40</p>
                  </div>
                </div>
                <div className="lg:w-1/4 md:w-1/2 p-4 w-full">
                  <a className="block relative h-48 rounded overflow-hidden">
                    <img
                      alt="ecommerce"
                      className="object-cover object-center w-full h-full block"
                      src="https://dummyimage.com/428x268"
                    />
                  </a>
                  <div className="mt-4">
                    <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">
                      CATEGORY
                    </h3>

                    <p className="mt-1">$18.40</p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      )}
    </>
  );
}

export default Id;
