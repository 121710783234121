import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const SearchableDropdown = (props) => {
  const { options, label, onChange, value, disableSearch } = props || {};

  return (
    <Autocomplete
      // filterOptions={(options) => options}
      id="controlled-demo"
      options={options}
      clearIcon={null}
      onChange={onChange}
      value={value}
      sx={{
        "& .MuiAutocomplete-clearIndicator": {
          display: "none",
        },
      }}
      renderInput={(params) => {
        console.log(params);
        return <TextField {...params} size="small" label={label} />;
      }}
      {...props}
    />
  );
};

export default SearchableDropdown;
