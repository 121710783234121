import React from "react";

function Address({ params }) {
  //console.log("pa1: ", params?.data?.add);
  return (
    <div>
      <h2>
        {`${params?.data?.retailStore?.name ? `${params?.data?.retailStore?.name}` : ""}`}
      </h2>
    </div>
  );
}

export default Address;
