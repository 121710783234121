import React from "react";

import { Link } from "react-router-dom";

function DashboardRoute({ params }) {
//   const clicked = () => {
//     localStorage.setItem("shelf", JSON.stringify(params?.data));
//   };

  return (
    <>
       <Link
        //onClick={clicked}
        // to={{
        //   pathname: "/admin/merchant/dashboard/rack/view/shelves",
        // }}
        className="font-bold ml-6"
      >
        {params.value}
      </Link>
    </>
  );
}

export default DashboardRoute;