import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/system";
import SwipperText from "../../../../../../../../components/SwipperText";
import ProductEventImages from "../../ProductEventImages";
import NoImage from "../../../../../../../../Images/noImage.jpg";

// Your existing component code...

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProductRecognizationModal = ({
  handleOpenModal,
  isModalOpen,
  handleCloseModal,
  preImageList,
  postImageList,
  allImageList,
  recommendedProductList,
  selectedData,
  setSelectedData,
}) => {
  return (
    <div className="w-[500px] text-left">
      {/* Your existing UI code... */}

      {/* Add a button or trigger to open the modal */}

      {/* Modal */}
      <StyledModal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div
          style={{ minWidth: "700px", background: "white", padding: "20px" }}
        >
          {/* Add your modal content here */}
          <p id="modal-title" className="font-bold text-center text-xl">
            Product Recommendation
          </p>
          <div className="w-full text-left h-[55%]">
            <div className="mt-2 ml-1 h-full w-[97%] border_black">
              <table className="table_style">
                <tr className="mb-2">
                  <th className="cell_style text-center">Pre Images</th>
                  <th className="cell_style text-center">Post Images</th>
                </tr>
                <tr>
                  <td>
                    <div className="flex w-full  justify-center items-center">
                      {preImageList?.length > 0 ? (
                        preImageList?.map((preImage, index) => (
                          <ProductEventImages
                            url={preImage.imageUrl}
                            index={index}
                            key={index}
                            allImages={allImageList}
                          />
                        ))
                      ) : (
                        <img
                          src={NoImage}
                          className="w-28 h-44"
                          alt="entryimage"
                        />
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="flex w-full  justify-center items-center">
                      {postImageList?.length > 0 ? (
                        postImageList?.map((postImage, index) => (
                          <ProductEventImages
                            url={postImage.imageUrl}
                            index={index}
                            key={index}
                            allImages={allImageList}
                          />
                        ))
                      ) : (
                        <img
                          src={NoImage}
                          alt="NoImage"
                          className="w-28 h-40 rounded cursor-pointer"
                        />
                      )}
                    </div>
                  </td>
                </tr>
              </table>
              <hr />
              <div className="mt-2 ml-2 items-center">
                <p className="font-bold mb-2">Recommended Product</p>
                <SwipperText
                  arr={recommendedProductList}
                  selectedData={selectedData}
                  setSelectedData={setSelectedData}
                />
              </div>
            </div>
          </div>
        </div>
      </StyledModal>
    </div>
  );
};

export default ProductRecognizationModal;
