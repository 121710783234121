import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { useHistory } from "react-router-dom";

import { useGet } from "../../../../../../api/useGet";

function TableStr({
  title,
  viewUrl,
  editUrl,
  listUrl,
  getName,
  Intialized,
  All,
  url,
}) {
  const [click, setClicked] = useState("all");

  const history = useHistory();
  const { isLoading, error, data } = useGet(listUrl, getName);

  const options = (e) => {
    const type = e.target.name;
    switch (type) {
      case "all":
        setClicked(type);
        break;
      case "Intialized":
        setClicked(type);
        break;

      default:
    }
  };

  return (
    <div className="w-full h-full shadow-lg select-none">
      <div
        className={`w-full h-16 flex justify-between p-6 items-center bg-gray-100`}
      >
        <h2 className="font-bold text-2xl">{title}</h2>
      </div>

      <div className="w-full h-10 p-2 -mt-2 ">
        <div className="h-10 w-full flex justify-start">
          <button
            onClick={options}
            name="all"
            className={`mx-4 border-b-2 ${
              click === "all" ? "border-blue-500" : null
            } focus:outline-none`}
          >
            All
          </button>

          <button
            onClick={options}
            name="Intialized"
            className={`mx-4 border-b-2 ${
              click === "Intialized" ? "border-blue-500" : null
            } focus:outline-none`}
          >
            Intialized
          </button>
        </div>
      </div>

      {click === "all" ? (
        <All
          data={data}
          //isLoading={isLoading}
          // error={error}
          viewUrl={viewUrl}
          editUrl={editUrl}
          //sortedData={findField(dataFilter(data, "APPROVED"))}
        />
      ) : click === "Intialized" ? (
        <Intialized
          data={data}
          // isLoading={isLoading}
          // error={error}
          viewUrl={viewUrl}
          editUrl={editUrl}
          // sortedData={findField(dataFilter(data, "IN_REVIEW"))}
        />
      ) : null}
    </div>
  );
}

export default TableStr;
