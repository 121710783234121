import React, {useEffect, useState} from "react";
import { GrView } from "react-icons/gr";
import { FiEdit } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { useGet } from "../../../../api/useGet";
import axios from "axios";
import {toast} from "react-toastify"

function Action({ params, url, viewUrl, editUrl}) {


  const history = useHistory();

  const retailerList = useGet(
    `${process.env.REACT_APP_BASE_URL}/retail-stores?page=0&sort=createdDate`
  );

  const fetchData = async (url) => {

    try {
      const res = await axios({
        method: "GET",
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      });
  
      return res;
    } catch ({message}) {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message)
      }
    }

    
  };


  const fetchInitialValue = () => {
    let initialValues = {};

    initialValues = {
      deviceIdentifier: params?.data?.deviceIdentifier,
      name: params?.data?.name,
      qrCode: params?.data?.qrCode,
      macAddress: params?.data?.macAddress,
      mode: params?.data?.mode,
      retailStore: params?.data?.retailStore,
      status: params?.data?.status,
    };
    return initialValues;
  };

  const edit = async () => {
    console.log("params: ", params);

    if (
      params?.data?.status === "INITIALIZED" ||
      params?.data?.status === "NotINITIALIZED"
    ) {
      const data = {
        id: params?.data?.id,

        name: params?.data?.name,
        status: "ACTIVE",

        deviceIdentifier: params?.data?.deviceIdentifier
          ? params?.data?.deviceIdentifier
          : "",

        mode: params?.data?.mode ? params?.data?.mode : null,
        macAddress: params?.data?.macAddress ? params?.data?.macAddress : "",

        qrCode: params?.data?.qrCode ? params?.data?.qrCode : "",

        rootOrgId: params?.data?.rootOrgId ? params?.data?.rootOrgId : null,
        retailStore: {
          id: params?.data?.retailStore ? params?.data?.retailStore : null,
        },
        scales: null,
        ageRestricted: false,
      };

      console.log("dataMac: ", data);

      const res = axios({
        method: "PUT",
        url: `${process.env.REACT_APP_BASE_URL}/machines/${params?.data?.id}`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
        data: data,
      });
    } else {
      const initialValues = fetchInitialValue();
      const res = await fetchData(
        `${process.env.REACT_APP_BASE_URL}/machines/${params?.data?.id}`
      );
      const merchant = await fetchData(
        `${process.env.REACT_APP_BASE_URL}/orgs/${params?.data?.rootOrgId}`
      );

      history.push({
        pathname: editUrl,
        state: {
          data: params.data,
          machine: res?.data,
          retailerList: retailerList ? retailerList : null,
          initialValues: initialValues,
          merchant: merchant?.data,
        },
      });
    }
  };

  const view = async () => {
   console.log("view")
    const initialValues = fetchInitialValue();

    const res = await fetchData(
      `${process.env.REACT_APP_BASE_URL}/machines/${params?.data?.id}`
    );
    const merchant = await fetchData(
      `${process.env.REACT_APP_BASE_URL}/orgs/${params?.data?.rootOrgId}`
    );

    history.push({
      pathname: viewUrl,
     
      state: {
        data: params.data,
        machine: res?.data,
        initialValues: initialValues,
        retailerList: retailerList ? retailerList : null,
        merchant: merchant?.data,
      },
    });

    // console.log("Id: ", res?.data)
  };
  return (
    <>
      <div className="flex w-16 h-10 items-center">
        <div className="border-2 border-gray-200 rounded-l-lg p-1 ">
          <GrView className="w-5 h-5 cursor-pointer " onClick={view} />
        </div>
        <div className="border-2 border-gray-200 rounded-r-lg p-1">
          <FiEdit className="w-5 h-5 cursor-pointer" onClick={edit} />
        </div>
      </div>
    </>
  );
}

export default Action;
