import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useGet } from "../../../../../../../api/useGet";
import { getTime } from "../../../../../../../logic/useGetTime";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#D9D9D9",
    color: theme.palette.common.black,
    fontWeight: 700,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

export default function CustomizedTables({ OpenCartData }) {
  console.log(
    "🚀 ~ file: OpenCartsTable.jsx:47 ~ CustomizedTables ~ OpenCartData:",
    OpenCartData
  );

  return (
    <TableContainer
      component={Paper}
      sx={{ height: "100%", border: "1px solid black" }}
    >
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Rack</StyledTableCell>
            <StyledTableCell>Product </StyledTableCell>
            <StyledTableCell>Weight Change</StyledTableCell>
            <StyledTableCell>Qty</StyledTableCell>
            <StyledTableCell>Event Time</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {OpenCartData?.map((item) => (
            <StyledTableRow key={item?.scaleInternalCode}>
              <StyledTableCell component="th" scope="row">
                {item?.scaleInternalCode}
              </StyledTableCell>
              <StyledTableCell>{item?.productInfos?.[0]?.name}</StyledTableCell>
              <StyledTableCell>
                {item?.changeInWeight?.toFixed(3)} kg{" "}
                {item?.eventType == "PICK" ? (
                  <NorthIcon color="success" />
                ) : (
                  <SouthIcon color="error" />
                )}
              </StyledTableCell>
              <StyledTableCell>
                {item?.productInfos?.[0]?.estimatedQuantity}
              </StyledTableCell>
              <StyledTableCell>
                {getTime(item?.eventTime, item?.storeTimeZone)}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
