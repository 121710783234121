import React from "react";
import { IoIosClose } from "react-icons/io";

function ModalImg({ open, onCloseModal, preImg, postImg }) {
  return (
    <>
    {open && (
      <div className="w-screen h-screen select-none z-10 absolute bg-gray-100 top-0 left-0 ">
        <div className="h-8 w-8 cursor-pointer absolute shadow-lg rounded-full bg-gray-400 right-5 top-5">
          <IoIosClose onClick={onCloseModal} className="h-8 w-8 " />
        </div>

        <div className="w-full h-full border-2 border-gray-200 rounded-lg flex justify-center items-center">
          <img src={preImg} alt="Pre img" className="h-full rounded-lg w-[50%]" />
          <img src={postImg} alt="Post img" className="h-full rounded-lg w-[50%]"/>
        </div>
      </div>
    )}
  </>
  );
}

export default ModalImg;
