import React, { useState, useEffect } from "react";
import { usePut } from "../../../../../../../api/usePut";
import { IoIosClose, IoMdAddCircle } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import Select from "react-select";

import { useAlert } from "react-alert";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import CartViewEdit from "./CartViewEdit";

const options = [
  { value: "APPROVED", label: "Approved" },
  { value: "REJECTED", label: "Rejected" },
];

function CartView({ data, open, onCloseModal }) {
  //console.log("data2: ", data)

  const alert = useAlert();
  const history = useHistory();

  const [cartData, setCartData] = useState({ ...data });

  useEffect(() => {
    setCartData(data);
  }, [data]);

  const editCart = usePut(
    `${process.env.REACT_APP_SHOPPER_URL}/machine-carts/${data?.id}/review-session`,
    "Carts"
  );

  const inputChange = (value, id) => {
    // console.log("value, cartIndex, id");
    // console.log(value, cartIndex, id);
    var updatedCartData = { ...cartData };
    updatedCartData?.machineCartItems.map((cart) => {
      if (cart.id == id) {
        cart.reviewedQuantity = value ? value : "";
      }
    });

    //console.log("updatedCartData :: ", updatedCartData);
    setCartData(updatedCartData);
  };

  const inputChangeHandler = (value, name) => {
    var updatedCartData = { ...cartData };

    updatedCartData[name] = value;

    // updatedCartData?.machineCartList.map((machineCart) => {
    //   machineCart[name] = value; // machineCart.status = machineCart["status"]
    // });
    // console.log("updatedCartData :: ", updatedCartData);

    setCartData(updatedCartData);
  };

  const submit = () => {
    if (cartData?.status === "IN_REVIEW") {
      alert.error(<div className="w-96">Select status option</div>);
    } else {
      editCart.mutate(cartData);
      //console.log("editData: ", editCart);

      if (editCart?.isError === false) {
        onCloseModal();
        alert.success("Submit Successfully");
      }
    }
  };

  return (
    <>
      {open && (
        <div className=" w_modal bg-gray-200 absolute top-16 left-60 h_modal  px-4 py-2 rounded-lg ">
          <div className="w-full h-10  bg-white rounded-lg flex items-center px-4 justify-between select-none ">
            <h2 className="text-black text-bold text-lg select-none">
              Session Review
            </h2>
            <IoIosClose
              onClick={() => onCloseModal()}
              className="h-8 w-8 cursor-pointer"
            />
          </div>

          <div className="w-full h_content ">
            <div className="w-full h-full flex flex-col justify-center  text-left ">
              <div className="w-full h-full flex py-2">
                <div className="w-7/10 h-full bg-white rounded-lg p-2 ">
                  <h2 className="font-bold mt-0 ml-2 text-base">
                    Carts Details:
                  </h2>

                  <div className="h-96  mt-3 select-none scrollbar-thin scrollbar-thumb-gray-900 scrollbar-track-gray-100">
                    {data?.login ? (
                      <div className="border-t-2 border-gray-200 py-1 px-4">
                        <div className="grid grid-cols-2 gap-4">
                          <div className="">login</div>
                          <div className="">{data?.login}</div>
                        </div>
                      </div>
                    ) : null}

                    {cartData?.status ? (
                      <div className="border-t-2 border-gray-200 py-1 px-4">
                        <div className="grid grid-cols-2 gap-4">
                          <div className="">Status</div>

                          <div>
                            <Select
                              placeholder={cartData?.status}
                              value={cartData?.status}
                              onChange={(e) =>
                                inputChangeHandler(e?.value, "status")
                              }
                              options={options}
                              search={false}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div className="border-t-2 border-gray-200 py-1 px-4">
                      <div className="grid grid-cols-2 gap-4">
                        <div className="">Comment</div>
                        <textarea
                          className="px-2 border rounded-sm min-h-6  focus:outline-none"
                          placeholder="please note something..."
                          onChange={(e) =>
                            inputChangeHandler(e?.target?.value, "reviewNote")
                          }
                        />
                      </div>
                    </div>

                    {data?.machineCartItems?.map((item, index) => (
                      <CartViewEdit
                        item={item}
                        index={index}
                        inputChange={inputChange}
                      />
                    ))}
                  </div>

                  <div className="flex h-10  mt-1.5 pt-2 items-center justify-end select-none">
                    <button className="focus:outline-none bg-gray-200 border-2 border-black ml-1 hover:bg-grey text-grey-darkest font-bold py-1 px-4 rounded inline-flex items-center">
                      <IoMdAddCircle className="w-4 h-4" />
                      <span onClick={submit} className="mx-2">
                        Add
                      </span>
                    </button>
                    <button className="focus:outline-none bg-gray-200 border-2 border-black ml-1 hover:bg-grey text-grey-darkest font-bold py-1 px-4 rounded inline-flex items-center">
                      <FaEdit className="w-4 h-4" />
                      <span onClick={submit} className="mx-2">
                        Submit
                      </span>
                    </button>
                  </div>
                </div>

                <div className="w-3/10 h-full ml-2 bg-white rounded-lg p-2">
                  <div className="w-full border-b-2">
                    <h2 className="text-black text-bold text-lg">Log</h2>
                  </div>

                  <div className="flex"></div>

                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CartView;
