import React, { useState, useEffect } from "react";
import Action from "./Action";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "react-toggle/style.css";
import DashboardRoute from "./DashboardRoute";
import axios from "axios";
import { BiSearchAlt } from "react-icons/bi";
import FadeLoader from "react-spinners/FadeLoader";
import { useTranslation } from "react-i18next";
import Pagination from "@mui/material/Pagination";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

function All({ url, viewUrl, editUrl, listUrl, getName }) {
  let [loading, setLoading] = useState(false);
  const [allCount, setAllCount] = useState();
  const history = useHistory();

  const { t } = useTranslation();

  const [selectProduct, setSelectProduct] = useState(null);
  const [filteredData, setFilteredData] = useState(null);

  const [search, setSearch] = useState({
    catby: "",
    nameby: "",
    barcodeby: "",
    filterby: "",
  });

  const handleInput = (value, name) => {
    setSearch({ ...search, [name]: value, filterby: name });
  };

  // const productHandler = (value, name) => {
  //   setSearch({ ...search, [name]: value, filterby: name });
  // };

  const clear = () => {
    setSearch({
      catby: "",
      nameby: "",
      barcodeby: "",
      filterby: "",
    });
    setSelectProduct(null);
    setFilteredData(null);
  };

  const [activePage, setActivePage] = useState(1);

  const findUrl = (name) => {
    switch (name) {
      case "catby":
        return `${listUrl}&category.contains=${search?.catby}*`;

      case "nameby":
        return `${listUrl}&name.contains=${search?.nameby}*`;

      case "barcodeby":
        return `${listUrl}&barCode.contains=${search?.barcodeby}*`;

      default:
        return `${listUrl}`;
    }
  };

  const fetchData = async (page) => {
    try {
      const res = await axios({
        method: "GET",
        url: `${findUrl(search?.filterby)}&page=${page - 1}&size=10`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      });
      setAllCount(res?.headers?.["x-total-count"]);
      return res.data;
    } catch ({ message }) {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message);
      }
    }
  };

  const { data, isLoading, isIdle, error } = useQuery(
    [
      "productList",
      activePage,
      search?.barcodeby,
      search?.nameby,
      search?.catby,
    ],
    async () => fetchData(activePage),
    {
      enabled:
        !!activePage ||
        !!search?.barcodeby ||
        !search?.nameby ||
        !!search?.catby,
      cacheTime: Infinity,
      staleTime: 0,
    }
  );

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  return (
    <div className="w-full h_das_table select-text">
      <div className="my-5">
        <div
          className={
            loading
              ? "bg-black bg-opacity-0 absolute inset-0 z-10 flex items-center justify-center"
              : null
          }
        >
          <FadeLoader className="absolute " loading={loading} size={150} />
        </div>
        <div className="flex h-10 w-full mt-2 items-center justify-between py-8 px-4">
          <div className="w-1/4 flex justify-end items-center">
            <input
              type="number"
              onChange={(e) => handleInput(e.target.value, "barcodeby")}
              className="w-full focus:outline-none py-1.5 px-2 border-2 border-gray-200 rounded-lg"
              placeholder={`${t("searchBarcode")}`}
              value={search?.barcodeby}
            />
            <BiSearchAlt className="w-12 h-5 absolute  text-blue-500" />
          </div>

          <div className="w-1/4 flex justify-end items-center">
            <input
              onChange={(e) => handleInput(e.target.value, "nameby")}
              className="w-full focus:outline-none py-1.5 px-2 border-2 border-gray-200 rounded-lg"
              placeholder={`${t("search by name")}`}
              value={search?.nameby}
            />
            <BiSearchAlt className="w-12  h-5 mr-4 absolute   text-blue-500" />
          </div>

          <div className="w-1/4 flex justify-end items-center">
            <input
              onChange={(e) => handleInput(e.target.value, "catby")}
              className="w-full focus:outline-none py-1.5 px-2 border-2 border-gray-200 rounded-lg"
              placeholder={`${t("search by category")}`}
              value={search?.catby}
            />
            <BiSearchAlt className="w-12  h-5 mr-4 absolute   text-blue-500" />
          </div>

          {/* <div style={{ width: "25%" }}>
          <Select
            className="text-left"
            placeholder={`${t("searchProduct")}`}
            maxMenuHeight={162}
            value={search?.catby}
            onChange={(value)=>productHandler(value,"catby")}
            options={data?.map((item, index) => {
              return {
                label: item?.name,
                value: item?.barCode,
                key: index,
              };
            })}
          />
        </div> */}

          <div className="w-48 flex justify-end">
            <button
              onClick={clear}
              className="bg-green-600 py-1.5 px-5 mr-7 rounded text-white"
            >
              {t("clearFilter")}
            </button>
          </div>
        </div>
      </div>

      <div className="ag-theme-alpine w-full h-full text-left select-text">
        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            resizable: true,
          }}
          suppressRowClickSelection={true}
          pagination={false}
          rowData={filteredData ? filteredData : data}
          onRowClicked={(params) =>
            history.push({
              pathname: viewUrl,
              state: { data: params.data },
            })
          }
        >
          <AgGridColumn
            headerName={t("productName")}
            field="name"
            minWidth={400}
            sortable={true}
            headerCheckboxSelection={true}
            headerCheckboxSelectionFilteredOnly={true}
            checkboxSelection={true}
            cellRendererFramework={(params) => (
              <DashboardRoute params={params} />
            )}
          />

          <AgGridColumn
            headerName={"UOM"}
            field="unitOfMeasure"
            minWidth={80}
            maxWidth={100}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <>
                  <p className="ml-4">{params?.data?.unitOfMeasure}</p>
                </>
              );
            }}
          />

          <AgGridColumn
            headerName={t("unitWeight")}
            field="unitWeight"
            minWidth={80}
            maxWidth={150}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <div className="flex">
                  <p className="ml-4 mr-2">
                    {parseFloat(params?.data?.unitWeight).toFixed(4)}
                  </p>
                  {params?.data?.unitOfMeasure &&
                    params?.data?.unitWeight &&
                    (params?.data?.unitOfMeasure?.toLowerCase() == "gm" ||
                    params?.data?.unitOfMeasure?.toLowerCase() == "ml"
                      ? params?.data?.unitWeight <= 1
                      : params?.data?.unitWeight * 1000 <= 1) && (
                      <img alt="" src={"/icons/warningIcon.svg"} />
                    )}
                </div>
              );
            }}
          />

          {/* <AgGridColumn
            headerName={t("categories")}
            field="productCategories"
            minWidth={110}
            cellStyle={{ textAlign: "center" }}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <>
                  {params?.data?.productCategories?.map((cat, index) => {
                    <p key={index}>{cat}</p>;
                  })}
                </>
              );
            }}
          /> */}

          <AgGridColumn
            headerName={t("purchasePrice")}
            field="purchasePrice"
            minWidth={100}
            maxWidth={150}
            sortable={true}
            //cellStyle={{ textAlign: "center" }}
            cellRendererFramework={(params) => {
              return (
                <>
                  <p className="ml-4">
                    {params?.data?.currencySymbol}{" "}
                    {parseFloat(params?.data?.purchasePrice).toFixed(2)}
                  </p>
                </>
              );
            }}
          />
          <AgGridColumn
            headerName={t("Gross Selling Price")}
            field="sellPriceWithTax"
            // cellStyle={{ textAlign: "center" }}
            minWidth={120}
            maxWidth={150}
            cellRendererFramework={(params) => {
              return (
                <>
                  <p className="ml-4">
                    {params?.data?.currencySymbol}{" "}
                    {parseFloat(params?.data?.sellPriceWithTax).toFixed(2)}
                  </p>
                </>
              );
            }}
            sortable={true}
          />

          <AgGridColumn
            headerName={t("Net Selling Price")}
            field="sellPrice"
            // cellStyle={{ textAlign: "center" }}
            minWidth={120}
            maxWidth={150}
            cellRendererFramework={(params) => {
              return (
                <>
                  <p className="ml-4">
                    {params?.data?.currencySymbol}{" "}
                    {parseFloat(params?.data?.sellPrice).toFixed(2)}
                  </p>
                </>
              );
            }}
            sortable={true}
          />
          <AgGridColumn
            headerName={t("barcode")}
            field="barCode"
            minWidth={140}
            maxWidth={180}
            sortable={true}
          />
          {/* <AgGridColumn
            headerName={t("packageType")}
            field="packageType"
            //cellStyle={{ textAlign: "center" }}
            minWidth={120}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <>
                  <p className="ml-4">{params?.data?.packageType}</p>
                </>
              );
            }}
          /> */}

          {/* <AgGridColumn
            headerName={t("Status")}
            field="status"
            minWidth={80}
            cellRendererFramework={(params) => <Confirm params={params} />}
          /> */}

          <AgGridColumn
            headerName={t("Action")}
            field="action"
            minWidth={100}
            maxWidth={200}
            cellRendererFramework={(params) => (
              <Action
                params={params}
                url={url}
                viewUrl={viewUrl}
                editUrl={editUrl}
              />
            )}
          />
        </AgGridReact>
      </div>
      <div className="  absolute bottom-8 right-12  flex justify-center item-center ">
        <Pagination
          count={
            allCount % 10 === 0
              ? parseInt(allCount / 10)
              : parseInt(allCount / 10) + 1
          }
          showFirstButton
          showLastButton
          page={activePage}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
}

export default All;
