import React from "react";
import { useField } from "formik";

//import {TextareaAutosize} from "@material-ui/core";

export const TextArea2 = ({ label, errors, touched, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="w-full flex flex-col justify-center">
      <div className="w-full flex justify-between space-x-4 items-center">
        <label className="w-[14.1%]" for={label}>
          {label}
        </label>
        <textarea
          //error={meta.touched && meta.error ? meta.error : ""}

          className="focus:outline-none w-[85.9%] bg-[#D9D9D9] py-1 px-6"
          variant="outlined"
          {...field}
          {...props}
          autoComplete="off"
        />
      </div>

      {/* {meta.touched && meta.error ? (
        <p className="w-full text-right text-red-500">{meta.error}</p>
      ) : null} */}
    </div>
  );
};
