import React, { useState } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "react-toggle/style.css";
import { useHistory } from "react-router-dom";
import DashboardRoute from "./DashboardRoute";
import { useQuery } from "react-query";
import FadeLoader from "react-spinners/FadeLoader";
import axios from "axios";
import { toast } from "react-toastify";
import Pagination from "@mui/material/Pagination";
import Action from "./Action";
import { getTime } from "../../../../../../logic/useGetTime";
import { AiFillDelete } from "react-icons/ai";
import moment from "moment";
import { MerchantsDetails } from "../../../../../../utils/action_types";

function ExitDecisionEvents({
  url,
  viewUrl,
  editUrl,
  listUrl,
  refresh,
  setCheckedoutEntryCount,
}) {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [allCount, setAllCount] = useState();

  const [activePage, setActivePage] = useState(1);

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  const fetchData = async () => {
    try {
      const requestUrl = `${listUrl}?sort=createdDate,desc&page=${
        activePage - 1
      }&size=10&status.equals=OPEN`;

      const res = await axios({
        method: "GET",
        url: requestUrl,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      });
      setAllCount(res?.headers?.["x-total-count"]);
      setCheckedoutEntryCount(res?.headers?.["x-total-count"] || 0);
      return res.data;
    } catch ({ message }) {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message);
      }
    }
  };

  const { data, isLoading } = useQuery(
    ["exitDecisionEvents", activePage, refresh],
    async () => fetchData(),
    {
      enabled: !!activePage,
      refresh,
      cacheTime: 0,
      staleTime: 0,
    }
  );

  if (isLoading)
    return (
      <div className="bg-black bg-opacity-80 absolute inset-0 flex items-center justify-center">
        <div className="w-full h-20 absolute z-10 text-white flex flex-col items-center justify-center">
          <FadeLoader
            color="#FFFFFF"
            className=""
            loading={isLoading}
            size={150}
          />
        </div>
      </div>
    );

  return (
    <div className=" w-full h-[calc(100vh-184px)]">
      <div className="flex h-10 w-full items-center mt-2 justify-between py-8 px-4"></div>
      <div className="ag-theme-alpine  w-full h-full text-left">
        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            resizable: true,
          }}
          rowHeight={50}
          suppressRowClickSelection={true}
          rowSelection={"multiple"}
          rowData={data}
        >
          <AgGridColumn
            headerName="Event Type"
            field="eventType"
            minWidth={250}
            sortable={true}
            cellRendererFramework={(params) => (
              <DashboardRoute params={params} name="eventType" />
            )}
          />

          <AgGridColumn
            headerName="Merchant"
            field="retailStoreCode"
            sortable={true}
            cellRendererFramework={(params) => {
              console.log(
                "merchantPhotu",
                MerchantsDetails.hasOwnProperty(params?.value)
                  ? MerchantsDetails.hasOwnProperty(params?.value)?.imgUrl
                  : "/images/noImage.jpg"
              );
              return (
                <div>
                  <img
                    src={
                      MerchantsDetails.hasOwnProperty(params?.value)
                        ? MerchantsDetails?.[params?.value]?.imgUrl
                        : "/images/noImage.jpg"
                    }
                    width="50px"
                    height="50px"
                    alt="/images/noImage.jpg"
                    // className="w-full h-[200px]"
                    // className="mt-3"
                  />
                </div>
              );
            }}
          />

          <AgGridColumn
            headerName="Event TimeStamp"
            field="exitTime"
            minWidth={250}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <p
                  className={`${
                    params?.data?.eventType === "Exit Event"
                      ? "list_font_style"
                      : ""
                  }`}
                >
                  {getTime(params?.data?.exitTime, params?.data?.storeTimeZone)}
                </p>
              );
            }}
          />
          <AgGridColumn
            headerName="Is Shopper recommended?"
            field="isShopperRecommended"
            minWidth={250}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <p>
                  {params?.shopperLoopRecommendedShopperId === null ||
                  params?.shopperLoopRecommendedShopperId === undefined
                    ? "No"
                    : "Yes"}
                </p>
              );
            }}
          />
          <AgGridColumn
            headerName="Action"
            field="action"
            minWidth={80}
            cellRendererFramework={(params) => {
              return (
                <Action
                  params={params}
                  url={url}
                  viewUrl={viewUrl}
                  editUrl={editUrl}
                  through={"exitDecisionEvents"}
                />
              );
            }}
          />
        </AgGridReact>
      </div>
      <div className="absolute bottom-14 right-12 flex justify-center item-center">
        <Pagination
          count={
            allCount % 9 === 0
              ? parseInt(allCount / 9)
              : parseInt(allCount / 9) + 1
          }
          showFirstButton
          showLastButton
          page={activePage}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
}

export default ExitDecisionEvents;
