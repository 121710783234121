import React, { useState, useMemo } from "react";
import { IoIosClose } from "react-icons/io";
import { useTranslation } from "react-i18next";
import ImageUploader from "react-images-upload";
import { usePost } from "../../../../../api/usePost";

function UploadImage(props) {
  const { t } = useTranslation();

  const { onCloseImage, open, productId } = props;

  const [pictures, setPictures] = useState([]);

  const onProject = (picture) => {
    setPictures(picture);
  };

  const uploadImg = usePost(
    `${process.env.REACT_APP_BASE_URL}/products/${productId}/upload`,
    "productDetail"
  );

  const upload = () => {
    var formdata = new FormData();
    var metadataString = [];

    pictures?.map((picture) => {
      formdata.append("files", picture, picture.name);
      metadataString.push("front");
    });
    formdata.append("metadata", metadataString.toString());

    uploadImg.mutate(formdata);

    onCloseImage();
  };

  return (
    <>
      {open && (
        <div className="bg-black bg-opacity-80 absolute inset-0 z-10 flex items-center justify-center">
          <div className="w-96 h-auto  text-left select-none z-10 absolute top-1/2 left-1/2   transform -translate-x-1/2 -translate-y-1/2 ">
            <div className="w-full h-full bg-gray-100  rounded-lg shadow-lg ">
              <div className="w-full h-8 flex text-gray-500 px-1 justify-end">
                <IoIosClose
                  onClick={onCloseImage}
                  className="h-10 w-10 cursor-pointer"
                />
              </div>

              <div className="mt-4 mb-4 flex flex-col justify-center items-center">
                <div className="w-full mx-4">
                  <ImageUploader
                    {...props}
                    withIcon={false}
                    withPreview={true}
                    label={""}
                    onChange={onProject}
                    imgExtension={[".jpg", ".jpeg", ".png", ".gif"]}
                    buttonText={t("Select Image")}
                    maxFileSize={25000000}
                  />
                </div>

                <div className="flex my-2">
                  <button
                    onClick={upload}
                    className="w-32 h-8 bg-gray-500 rounded flex items-center justify-center text-white focus:outline-none"
                  >
                    {"Upload Image"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default UploadImage;
