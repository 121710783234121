import Reac, { useState, useEffect } from "react";
import TableStr from "./TableStr";
import Intialized from "./options/Intialized";
import All from "./options/All";
import axios from "axios";

function Rack() {
 

  return (
    <div className="h-full w-full ">
      <TableStr
        title={"Rack"}
        viewUrl={"/admin/rack/view"}
        editUrl={"/admin/rack/edit"}
        listUrl={`${process.env.REACT_APP_BASE_URL}/racks?projection=RackListDTO&page=0&sort=createdDate,desc`}
        getName={"rackList"}
        All={All}
        Intialized={Intialized}
        url={"/admin/rack/newRack"}
      />
    </div>
  );
}

export default Rack;
