import React from "react";
import TextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import MobileDateTimePicker from '@mui/lab/MobileDateTimePicker';

const DateTimepicker = ({value, onChangeTime, name, label, clearIcon, minDateTime, maxDateTime}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      
        <MobileDateTimePicker
          label={label}
          value={value}
          clearIcon={clearIcon}
          minDateTime={minDateTime}
          maxDateTime={maxDateTime}
          onChange={(value)=> onChangeTime(value, name)}
          renderInput={(params) => <TextField {...params} />}
        />
    
    </LocalizationProvider>
  );
};

export default DateTimepicker;
