import React, { useState, useEffect } from "react";
import Toggle from "react-toggle";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { usePut } from "../../../../../../api/usePut";

function Confirm({ machine, name }) {
  //const [status, setStatus] = useState(machine?.status === "true" ? true : false)
  const editMachine = usePut(
    `${process.env.REACT_APP_BASE_URL}/machines/${machine?.id}`,
    "machineView"
  );

  const statusChecked = (e) => {
    const value = e.target.checked;
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            var body = {
              id: machine?.id,
              name: machine?.name,
              status: value,
            };

            editMachine.mutate(body);
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
    //setStatus(editMachine?.data?.data?.status === "true" ? true : false)
  };

  //console.log("use: ", status);

  return (
    <div className="font-bold py-2 px-4 rounded inline-flex items-center">
      <span className="mx-2">Status</span>
      <Toggle
        onChange={statusChecked}
        checked={machine?.status === "true" ? true : false}
        //icons={{ checked: null, unchecked: null }}
        className="react-switch"
      />
    </div>
  );
}

export default Confirm;
