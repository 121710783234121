import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { useAlert } from "react-alert";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Action from "./Action";
import DashboardRoute from "./DashboardRoute";
import CreateShelf from "./CreateShelf";
import EditShelf from "./EditShelf";
import ViewShelf from "./ViewShelf";
import { GrView } from "react-icons/gr";
import { FiEdit } from "react-icons/fi";
import Select from "react-select";
import axios from "axios";
import { usePut } from "../../../../../../api/usePut";
import { useGet } from "../../../../../../api/useGet";
import * as yup from "yup";

let schema = yup.object().shape({
  internalCode: yup.string().matches(/^[A-Z0-9]$/),
});

const options = [
  { value: "INSTALLED", label: "INSTALLED" },
  { value: "LIVE", label: "LIVE" },
  { value: "DAMAGED", label: "DAMAGED" },
];

function RackEdit({ header }) {
  const [title, setTitle] = useState("Shelf");
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();

  const [searchValue, setSearchValue] = useState(null);
  const [retailName, setRetailerName] = useState(null);
  const [retailStore, setRetailerStore] = useState(null);
  const [retailerStoreValue, setRetailerStoreValue] = useState(null);

  const [status, setStatus] = useState(null);

  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [view, setView] = useState(false);
  const onOpenView = () => setView(true);
  const onCloseView = () => setView(false);
  const [viewData, setViewData] = useState(null);

  const [edit, setEdit] = useState(false);
  const onOpenEdit = () => setEdit(true);
  const onCloseEdit = () => setEdit(false);
  const [editData, setEditData] = useState(null);

  const [data, setData] = useState({ ...location?.data });

  const [orgId, setOrgId] = useState("");
  const [org, setOrg] = useState(null);

  const [store, setStore] = useState(null);
  const [storeId, setStoreId] = useState(null);

  const shelf = useGet(
    `${process.env.REACT_APP_BASE_URL}/shelves?rackId.equals=${data?.id}&page=0&sort=createdDate,desc`,
    "shelvesList"
  );

  const [input, setInput] = useState({
    name: "",
    internalCode: "",
    maxShelves: "",
  });

  const inputChangeHandler = (e, name) => {
    const value = e.target.value;
    const updatedData = { ...data };
    if (name === "maxShelves") {
      updatedData[name] = parseInt(value);
    } else {
      updatedData[name] = value;
    }

    setData(updatedData);

    setInput({ ...input, [name]: value });
  };

  useEffect(async () => {
    const res = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}/orgs?page=0&sort=createdDate,desc`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
    });
    setOrg(res?.data);
  }, []);

  useEffect(async () => {
    if (orgId) {
      const res = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/retail-stores?page=0&sort=createdDate,desc&rootOrgId.equals=${orgId?.value}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      });
      setStore(res?.data);
    }
  }, [orgId]);

  const changeHandlerOrg = (value) => {
    const updatedData = { ...data };
    updatedData["rootOrgId"] = value?.value;
    setData(updatedData);
    setOrgId(value);
  };

  const changeRetailStore = (value) => {
    const updatedData = { ...data, retailStore: { id: value?.value } };
    setData(updatedData);
    setStoreId(value);
  };

  const editRack = usePut(
    `${process.env.REACT_APP_BASE_URL}/racks/${location?.data?.id}`,
    "rackList"
  );

  const changeHandlerStaus = (value) => {
    const updatedData = { ...data };
    updatedData["status"] = value?.value;
    setData(updatedData);

    setStatus(value);
  };

  const editFn = async (data) => {
    onOpenEdit();
    setEditData(data);
  };

  const viewFn = async (data) => {
    onOpenView();
    setViewData(data);
  };

  const submit = (e) => {
    e.preventDefault();
    // const data = {
    //   name: input?.name,
    //   status: checked,
    //   internalCode: input?.internalCode,
    //   maxShelves: input?.maxShelves,
    //   rootOrgId: orgId?.value,
    //   retailStore: {
    //     id: storeId?.value,
    //   },
    // };
    //console.log("edit: ", data);
    editRack.mutate(data);
    if (editRack.isError === false) {
      history.goBack();
      alert.success(
        <div style={{ textTransform: "none" }}>Successfully Updated!</div>
      );
    } else {
      alert.error(
        <div style={{ textTransform: "none" }}>Please Check all Fields!</div>
      );
    }
  };

  return (
    <>
      <div
        className={`w-full   h_screen pt-2
         text-left flex select-none bg-gray-200`}
      >
        <div className="w-full h-full px-4  py-2">
          <div className="w-full  h-full  ">
            <div className="w-full h-12 flex justify-between bg-white rounded-lg">
              <div className="flex">
                <div className="w-10 p-2 h-full flex items-center">
                  <BiArrowBack
                    onClick={() => history.goBack()}
                    className="h-10 w-10 cursor-pointer"
                  />
                </div>
                <div className="w-full h-full px-2 flex flex-col justify-center">
                  <h2 className="font-bold text-xl">
                    Update Rack {data?.orderNumber}
                  </h2>
                </div>
              </div>

              <div className="h-full flex items-center">
                <button
                  onClick={onOpenModal}
                  className={` rounded-xs  py-0.5 px-3
                mx-4 focus:outline-none text-green-500 border border-black `}
                >
                  <span className="h-full flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 64 64"
                      width="28px"
                      height="28px"
                    >
                      <linearGradient
                        id="KJ7ka9GQp0CHqT_2YsWMsa"
                        x1="32"
                        x2="32"
                        y1="5.75"
                        y2="59.005"
                        gradientUnits="userSpaceOnUse"
                        spreadMethod="reflect"
                      >
                        <stop offset="0" stop-color="#1a6dff" />
                        <stop offset="1" stop-color="#c822ff" />
                      </linearGradient>
                      <path
                        fill="url(#KJ7ka9GQp0CHqT_2YsWMsa)"
                        d="M32,58C17.663,58,6,46.337,6,32S17.663,6,32,6s26,11.663,26,26S46.337,58,32,58z M32,8 C18.767,8,8,18.767,8,32s10.767,24,24,24s24-10.767,24-24S45.233,8,32,8z"
                      />
                      <linearGradient
                        id="KJ7ka9GQp0CHqT_2YsWMsb"
                        x1="32"
                        x2="32"
                        y1="5.75"
                        y2="59.005"
                        gradientUnits="userSpaceOnUse"
                        spreadMethod="reflect"
                      >
                        <stop offset="0" stop-color="#1a6dff" />
                        <stop offset="1" stop-color="#c822ff" />
                      </linearGradient>
                      <path
                        fill="url(#KJ7ka9GQp0CHqT_2YsWMsb)"
                        d="M32,52c-11.028,0-20-8.972-20-20s8.972-20,20-20s20,8.972,20,20S43.028,52,32,52z M32,14 c-9.925,0-18,8.075-18,18s8.075,18,18,18s18-8.075,18-18S41.925,14,32,14z"
                      />
                      <linearGradient
                        id="KJ7ka9GQp0CHqT_2YsWMsc"
                        x1="32"
                        x2="32"
                        y1="21.75"
                        y2="42.538"
                        gradientUnits="userSpaceOnUse"
                        spreadMethod="reflect"
                      >
                        <stop offset="0" stop-color="#6dc7ff" />
                        <stop offset="1" stop-color="#e6abff" />
                      </linearGradient>
                      <path
                        fill="url(#KJ7ka9GQp0CHqT_2YsWMsc)"
                        d="M41,30h-7v-7c0-0.552-0.448-1-1-1h-2c-0.552,0-1,0.448-1,1v7h-7c-0.552,0-1,0.448-1,1v2 c0,0.552,0.448,1,1,1h7v7c0,0.552,0.448,1,1,1h2c0.552,0,1-0.448,1-1v-7h7c0.552,0,1-0.448,1-1v-2C42,30.448,41.552,30,41,30z"
                      />
                    </svg>
                    <span className="px-2">{`Create ${title}`}</span>
                  </span>
                </button>
              </div>
              <CreateShelf
                open={open}
                onCloseModal={onCloseModal}
                rackData={data}
              />
            </div>

            <form
              onSubmit={submit}
              className="w-full h-28 rounded-lg px-2 bg-white my-4 py-2 "
            >
              <div className="w-full h-full bg-white rounded-b-lg">
                {/* <h1 className="px-2 h-6 text-lg font-black font-serif">
                  Fill out the form below for edit
                </h1> */}
                <div className=" grid grid-cols-3 gap-2  ">
                  <div className="w-full h-full  p-2 select-none">
                    <table className="table-auto rounded border-collapse border  w-full h-full">
                      <tbody className="text-left">
                        <tr>
                          <th className="w-32 border border-gray-300 bg-gray-200 px-2">
                            Rack Name
                          </th>
                          <th className="border border-gray-300 px-2 text-gray-500">
                            <input
                              className="focus:outline-none"
                              placeholder={data?.name}
                              value={input?.name ? input.name : ""}
                              onChange={(e) => inputChangeHandler(e, "name")}
                            />
                          </th>
                        </tr>
                        {/* <tr>
                            <th className="w-40 border border-gray-300 bg-gray-200 px-2">
                              Device Identifier :
                            </th>
                            <th className="border border-gray-300 px-2 text-gray-500">
                              {data?.deviceIdentifier}
                            </th>
                          </tr> */}
                      </tbody>
                    </table>
                  </div>

                  <div className="w-full h-12  p-2 select-none">
                    <table className="table-auto rounded border-collapse border  w-full h-full">
                      <tbody className="text-left">
                        <tr>
                          <th className="w-32 border border-gray-300 bg-gray-200 px-2">
                            Rack ID
                          </th>
                          <th className="border border-gray-300 px-2 text-gray-500">
                            <input
                              className="focus:outline-none"
                              placeholder={data?.internalCode}
                              value={
                                input?.internalCode ? input?.internalCode : ""
                              }
                              onChange={(e) =>
                                inputChangeHandler(e, "internalCode")
                              }
                            />
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="w-92 h-12  py-2 select-none">
                    <table className="table-auto rounded border-collapse border  w-full h-full">
                      <tbody className="text-left">
                        <tr>
                          <th className="w-32 border border-gray-300 bg-gray-200 px-2">
                            Max Shelves
                          </th>
                          <th className="border border-gray-300 px-2 text-gray-500">
                            <input
                              className="focus:outline-none"
                              placeholder={data?.maxShelves}
                              value={input?.maxShelves ? input?.maxShelves : ""}
                              onChange={(e) =>
                                inputChangeHandler(e, "maxShelves")
                              }
                            />
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="flex h-10  w-full items-center mt-1 pl-2 pr-3.5 justify-between">
                  <div className="flex w-full items-center justify-start">
                    <div style={{ width: "70%" }}>
                      <Select
                        placeholder="Select Merchant"
                        maxMenuHeight={162}
                        value={orgId}
                        onChange={changeHandlerOrg}
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                        options={org?.map((item, index) => {
                          return {
                            label: item.name,
                            value: item.id,
                            key: index,
                          };
                        })}
                      />
                    </div>

                    <div style={{ width: "70%", marginLeft: "22px" }}>
                      <Select
                        placeholder="Retail Store"
                        maxMenuHeight={162}
                        value={storeId}
                        onChange={changeRetailStore}
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                        options={store?.map((item, index) => {
                          return {
                            label: item?.name,
                            value: item?.id,
                            key: index,
                          };
                        })}
                      />
                    </div>

                    <div className="flex  w-full select-none">
                      <div style={{ width: "70%", marginLeft: "22px" }}>
                        <Select
                          placeholder="Select Status"
                          maxMenuHeight={162}
                          value={status}
                          isSearchable={false}
                          onChange={changeHandlerStaus}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          // styles={customStyles}
                          options={options}
                        />
                      </div>
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="bg-green-500 rounded-sm px-5 py-1.5  focus:outline-none text-white"
                  >
                    <span className="h-full flex items-center">
                      <svg
                        class="svg-icon"
                        viewBox="0 0 20 20"
                        width="24px"
                        height="24px"
                      >
                        <path
                          fill="#8A2BE2"
                          d="M19.404,6.65l-5.998-5.996c-0.292-0.292-0.765-0.292-1.056,0l-2.22,2.22l-8.311,8.313l-0.003,0.001v0.003l-0.161,0.161c-0.114,0.112-0.187,0.258-0.21,0.417l-1.059,7.051c-0.035,0.233,0.044,0.47,0.21,0.639c0.143,0.14,0.333,0.219,0.528,0.219c0.038,0,0.073-0.003,0.111-0.009l7.054-1.055c0.158-0.025,0.306-0.098,0.417-0.211l8.478-8.476l2.22-2.22C19.695,7.414,19.695,6.941,19.404,6.65z M8.341,16.656l-0.989-0.99l7.258-7.258l0.989,0.99L8.341,16.656z M2.332,15.919l0.411-2.748l4.143,4.143l-2.748,0.41L2.332,15.919z M13.554,7.351L6.296,14.61l-0.849-0.848l7.259-7.258l0.423,0.424L13.554,7.351zM10.658,4.457l0.992,0.99l-7.259,7.258L3.4,11.715L10.658,4.457z M16.656,8.342l-1.517-1.517V6.823h-0.003l-0.951-0.951l-2.471-2.471l1.164-1.164l4.942,4.94L16.656,8.342z"
                        ></path>
                      </svg>
                      <span className="px-2 select-none cursor-pointer">
                        Save
                      </span>
                    </span>
                  </button>
                </div>
              </div>
            </form>

            <div className="w-full h-88 rounded-lg bg-white mt-4">
              <div className="ag-theme-alpine w-full h-full text-left">
                <AgGridReact
                  defaultColDef={{
                    flex: 1,
                    minWidth: 100,
                    resizable: true,
                  }}
                  suppressRowClickSelection={true}
                  rowSelection={"multiple"}
                  pagination={true}
                  paginationPageSize={10}
                  rowData={shelf?.data}
                >
                  <AgGridColumn
                    headerName="Shelf Name"
                    field="name"
                    minWidth={160}
                    sortable={true}
                    headerCheckboxSelection={true}
                    headerCheckboxSelectionFilteredOnly={true}
                    checkboxSelection={true}
                    cellRendererFramework={(params) => (
                      <DashboardRoute params={params} />
                    )}
                  />

                  <AgGridColumn
                    headerName="Shelf ID"
                    field="internalCode"
                    minWidth={150}
                    sortable={true}
                  />

                  <AgGridColumn
                    headerName="Max Scales"
                    field="maxScales"
                    minWidth={80}
                    sortable={true}
                    cellRendererFramework={(params) => {
                      return (
                        <>
                          <p className="ml-6">
                            {params?.data?.maxScales === null
                              ? 0
                              : params.data?.maxScales}
                          </p>
                        </>
                      );
                    }}
                  />

                  {/* <AgGridColumn
                    headerName="Retail Store"
                    field="retailStore"
                    minWidth={170}
                    sortable={true}
                    cellRendererFramework={(params) => {
                      return (
                        <>
                          <p>{params?.data?.rack?.retailStore?.name}</p>
                        </>
                      );
                    }}
                  />
                  <AgGridColumn
                    headerName="Retail Address"
                    field="retailStore"
                    minWidth={300}
                    cellRendererFramework={(params) => (
                      <Address params={params} />
                    )}
                  /> */}

                  <AgGridColumn
                    headerName="Location ID"
                    field="rack"
                    minWidth={100}
                    cellRendererFramework={(params) => (
                      <p>{params?.data?.rack?.locationRef}</p>
                    )}
                  />

                  <AgGridColumn
                    headerName="Position ID"
                    field="position"
                    minWidth={100}
                    cellRendererFramework={(params) => (
                      <p className="ml-6">
                        {params?.data?.position === null
                          ? "0"
                          : params?.data?.position}
                      </p>
                    )}
                  />

                  <AgGridColumn
                    field="status"
                    minWidth={100}
                    cellRendererFramework={(params) => (
                      <p>{params?.data?.status}</p>
                    )}
                  />
                  <AgGridColumn
                    field="action"
                    minWidth={100}
                    cellRendererFramework={(params) => (
                      <>
                        <div className="flex w-24 h-10 items-center">
                          <div className="border-2 border-gray-200 rounded-l-lg p-1 ">
                            <GrView
                              className="w-5 h-5 cursor-pointer "
                              onClick={() => viewFn(params?.data)}
                            />
                          </div>
                          <div className="border-2 border-gray-200 rounded-r-lg p-1">
                            <FiEdit
                              className="w-5 h-5 cursor-pointer"
                              onClick={() => editFn(params?.data)}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  />
                </AgGridReact>
              </div>

              <ViewShelf
                open={view}
                onCloseModal={onCloseView}
                data={viewData}
              />

              <EditShelf
                open={edit}
                onCloseModal={onCloseEdit}
                data={editData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RackEdit;
