import React, { useState, useMemo, useEffect } from "react";
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import Select from "react-select";
import { usePut } from "../../../../../../../../api/usePut";
import { useGetByParams } from "../../../../../../../../api/useGetByParams";
import { useGet } from "../../../../../../../../api/useGet";
import { TextField2 } from "../../../../../../../validation/TextField2";
import { TextArea } from "../../../../../../../validation/TextArea";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ClipLoader from "react-spinners/ClipLoader";
import { boolean } from "yup/lib/locale";
import { toast } from "react-toastify";
import FadeLoader from "react-spinners/FadeLoader";

const customStyles = {
  control: (base) => ({
    ...base,
    height: 55,
    minHeight: 35,
  }),
};

const options = [
  { value: "PROD", label: "PROD" },
  { value: "Dev", label: "Dev" },
];

const currencyType = [
  { value: "€", label: "Euro", code: "EUR" },
  { value: "£", label: "Pound", code: "GBP" },
  { value: "$", label: "US Dollar", code: "USD" },
];

const detectedLan = (code) => {
  return currencyType?.filter((item) => {
    return item?.code === code;
  });
};

// const tradingClassification = [
//   { value: "Class 1", label: "class 1" },
//   { value: "Class 2", label: "class 2" },
//   { value: "Class 3", label: "class 3" },
// ];

const netWeightUOM = [
  { value: "gm", label: "gm" },
  { value: "Lt", label: "Lt" },
  { value: "kg", label: "kg" },
  { value: "ml", label: "ml" },
];

const referenceUOM = [
  { value: "gm", label: "gm" },
  { value: "Lt", label: "Lt" },
  { value: "kg", label: "kg" },
  { value: "ml", label: "ml" },
];

const unitOfMeasurement = [
  { value: "MMT", label: "MMT" },
  { value: "cm", label: "cm" },
];

const skuType = [
  { value: "STANDARD_SKU", label: "STANDARD SKU" },
  { value: "NON_STANDARD_SKU", label: "NON-STANDARD SKU" },
];

const packageType = [
  { value: "Box", label: "Box" },
  { value: "Packet", label: "Packet" },
  { value: "Piece", label: "Piece" },
];

const pricingType = [
  { value: "PER_PIECE_BASIS", label: "Per Piece Basis" },
  { value: "PER_GRAM_BASIS", label: "Per Gram Basis" },
];

const findLabel = (arr, value) => {
  return arr.find((ele) => ele.value === value).label;
};

let validate;
validate = Yup.object({
  name: Yup.string().required("Required"),
  description: Yup.string().nullable(),
  title: Yup.string(),
  unitWeight: Yup.number().required("Required"),
  netWeight: Yup.number().nullable(),
  referenceWeight: Yup.number().nullable(),
  tradingClassification: Yup.string().nullable(),
  referencePrice: Yup.number().nullable(),
  depositAmount: Yup.number().nullable(),
  countryOfOrigin: Yup.string().nullable(),
  barCode: Yup.string().required("Required"),
  hsnCode: Yup.string(),
  purchasePrice: Yup.number().required("Required"),
  sellPrice: Yup.number().required("Required"),
  height: Yup.number().nullable(),
  width: Yup.number().nullable(),
  length: Yup.number().nullable(),
  depth: Yup.number().nullable(),
  unitOfMeasurement: Yup.string().nullable(),
});

function EditProduct() {
  const { t } = useTranslation();

  const history = useHistory();
  const {
    state: { data },
  } = useLocation();

  const [loading, setLoading] = useState(false);

  //post
  const { mutate: createProduct, isSuccess } = usePut(
    `${process.env.REACT_APP_BASE_URL}/products/${data?.id}`,
    "productList"
  );

  const pCatList = useGet(
    `${
      process.env.REACT_APP_BASE_URL
    }/categories?parentId.specified=false&rootOrgId.equals=${
      JSON.parse(localStorage.getItem("dashboard-data"))?.rootOrgId
    }`,
    "productCatrgoriesList"
  );

  const supplierList = useGet(
    `${
      process.env.REACT_APP_BASE_URL
    }/suppliers?page=0&sort=createdDate&rootOrgId.equals=${parseInt(
      JSON.parse(localStorage.getItem("dashboard-data"))?.id
    )}`,
    "supplierList"
  );

  // const brandList = useGet(
  //   `${process.env.REACT_APP_BASE_URL}/brands?rootOrgId.equals=${parseInt(
  //     JSON.parse(localStorage.getItem("dashboard-data"))?.id
  //   )}`,
  //   "brandList"
  // );

  const uomList = useGet(
    `${
      process.env.REACT_APP_BASE_URL
    }/unit-of-measures?sort=createdDate,desc&rootOrgId=${parseInt(
      JSON.parse(localStorage.getItem("dashboard-data"))?.id
    )}`,
    "uomList"
  );

  const id = parseInt(JSON.parse(localStorage.getItem("dashboard-data"))?.id);

  const { data: productInfoManagement, isApiLoading } = useGet(
    `${process.env.REACT_APP_BASE_URL}/_search/org-configurations?page=0&size=10&configName.equals=PRODUCT_INFO_MANAGEMENT&rootOrgId.equals=${id}`,
    "productInfoManagement"
  );

  const taxList = useGet(
    `${
      process.env.REACT_APP_BASE_URL
    }/tax-categories?sort=createdDate,desc&rootOrgId=${parseInt(
      JSON.parse(localStorage.getItem("dashboard-data"))?.id
    )}`,
    "taxList"
  );

  const { data: initialValues } = useGet(
    `${process.env.REACT_APP_BASE_URL}/products/${data.id}`,
    "productDetail"
  );

  const [status, setStatus] = useState(false);

  const [isEditable, setIsEditable] = useState(false);

  const [vegMark, setVegMark] = useState();

  const [isRefWeightForDisplayEnabled, setRefDis] = useState();

  const [subCat, setSubCat] = useState(null);

  const [select, setSelect] = useState({
    brandType: {
      value: "",
      label: "",
    },
    packageType: {
      value: "",
      label: "",
    },
    supplierType: {
      value: "",
      label: "",
    },
    taxType: {
      value: "",
      label: "",
    },
    unitType: {
      value: "",
      label: "",
    },
    netType: {
      value: "",
      label: "",
    },
    referenceType: {
      value: "",
      label: "",
    },
    tradeClass: {
      value: "",
      label: "",
    },
    productCategory: {
      value: "",
      label: "",
    },
    productSubCategory: {
      value: "",
      label: "",
    },
    skuType: {
      value: "",
      label: "",
    },
    currency: {
      value: "",
      label: "",
      code: "",
    },
    pricingType: {
      value: "",
      label: "",
    },
    unitOfMeasurement: {
      value: "",
      label: "",
    },
  });

  const [initialData, setInitialData] = useState();

  useEffect(() => {
    if (initialValues) {
      setStatus(initialValues.status === "true" ? true : false); // for string values
      //setStatus(initialValues.status);     //for boolean value

      setVegMark(initialValues?.vegMark === "true" ? true : false);
      setRefDis(initialValues?.isRefWeightForDisplayEnabled);

      setSelect({
        brandType: {
          value: initialValues?.brand?.id ? initialValues.brand?.id : "Brand",
          label: initialValues?.brand?.name
            ? initialValues.brand?.name
            : "Brand",
        },
        packageType: {
          value: initialValues?.packageType
            ? initialValues.packageType
            : "Package",
          label: initialValues?.packageType
            ? initialValues.packageType
            : "Package",
        },
        supplierType: {
          value: initialValues?.supplier?.id
            ? initialValues.supplier?.id
            : "Supplier",
          label: initialValues?.supplier?.name
            ? initialValues.supplier?.name
            : "Supplier",
        },
        taxType: {
          value: "",
          label: initialValues?.taxCategoryType
            ? initialValues.taxCategoryType
            : "Tax",
        },
        unitType: {
          value: "",
          label: initialValues?.unitOfMeasure
            ? initialValues.unitOfMeasure
            : "Unit Of Measurement",
        },
        netType: {
          value: "",
          label: initialValues?.netWeightUOM
            ? initialValues.netWeightUOM
            : "Net Weight UOM",
        },
        referenceType: {
          value: "",
          label: initialValues?.referenceUOM
            ? initialValues.referenceUOM
            : "Reference UOM",
        },
        // tradeClass: {
        //   Value: "",
        //   label: initialValues?.tradingClassification
        //     ? initialValues.tradingClassification
        //     : "Quality Class",
        // },
        productCategory: {
          value: initialValues?.productCategories[0]?.category,
          label: initialValues?.productCategories[0]?.category
            ? initialValues.productCategories[0]?.category
            : "Category",
        },
        productSubCategory: {
          value: "",
          label: initialValues?.subCategory
            ? initialValues.subCategory
            : "Sub Category",
        },
        skuType: {
          value: initialValues?.skuType
            ? initialValues?.skuType
            : "STANDARD_SKU",
          label: initialValues?.skuType
            ? findLabel(skuType, initialValues?.skuType)
            : "STANDARD SKU",
        },
        currency: {
          value: "",
          label: initialValues?.isoCurrencyCode
            ? detectedLan(initialValues.isoCurrencyCode)[0]?.label
            : "Currency",
          code: "",
        },
        pricingType: {
          value: initialValues?.pricingType
            ? initialValues?.pricingType
            : "PER_PIECE_BASIS",
          label: initialValues?.pricingType
            ? findLabel(pricingType, initialValues?.pricingType)
            : "Per Piece Basis",
        },
        unitOfMeasurement: {
          value: initialValues?.productDimension?.unitOfMeasurement,
          label: initialValues?.productDimension?.unitOfMeasurement,
        },
      });

      // console.log("initialValues: ", initialValues);
      // const {name, description, title, unitWeight, netWeight, referenceWeight} = initialValues //object

      setInitialData({
        name: initialValues.name,
        description: initialValues.description,
        title: initialValues.title,
        unitWeight: initialValues.unitWeight,
        netWeight: initialValues.netWeight,
        tradingClassification: initialValues.tradingClassification,
        referenceWeight: initialValues.referenceWeight,
        referencePrice: initialValues.referencePrice,
        depositAmount: initialValues.depositAmount,
        countryOfOrigin: initialValues.countryOfOrigin,
        barCode: initialValues.barCode,
        hsnCode: initialValues.hsnCode,
        purchasePrice: initialValues.purchasePrice,
        sellPrice: initialValues.sellPriceWithTax,
        height: initialValues?.productDimension?.height,
        depth: initialValues?.productDimension?.depth,
        length: initialValues?.productDimension?.length,
        width: initialValues?.productDimension?.width,
        unitOfMeasurement: initialValues?.productDimension?.unitOfMeasurement,
      });
    }
  }, [initialValues]);

  const handleSelect = (action, name) => {
    setSelect({
      ...select,
      [name]: { value: action.value, label: action.label, code: action.code },
    });
  };

  // const statusChecked = (e) => {
  //   setStatus(e.target.checked);
  // };

  //for subCategory

  // useEffect(() => {
  //   axios({
  //     method: "GET",
  //     url: `${process.env.REACT_APP_BASE_URL}/categories?parentId.equals=${
  //       select?.productCategory?.value
  //     }&rootOrgId.equals=${
  //       JSON.parse(localStorage.getItem("dashboard-data"))?.rootOrgId
  //     }`,
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${localStorage.getItem("user-info")}`,
  //     },
  //   }).then((res) => {
  //     setSubCat(res?.data);
  //   });
  // }, [select?.productCategory?.value]);

  useEffect(() => {
    if (
      productInfoManagement?.length > 0 &&
      productInfoManagement?.[0]?.configValue?.toLowerCase() === "external"
    ) {
      setIsEditable(true);
    } else {
      setIsEditable(false);
    }
  }, [productInfoManagement]);

  useEffect(() => {
    if (isSuccess) {
      setLoading(true);
      setTimeout(() => {
        history.goBack();
        setLoading(false);
      }, 2000);
    }
  }, [isSuccess]);

  console.log("validate", validate);

  if (loading || isApiLoading) {
    return (
      <div className="h-full w-full flex justify-center items-center">
        <FadeLoader color="#000000" size={250} />
      </div>
    );
  }

  return (
    <>
      {initialData ? (
        <div className="w-full h-screen text-left select-none flex">
          <div className="w-full h-full flex justify-center items-center  p-4 bg-gray-200 ">
            <Formik
              initialValues={initialData}
              validationSchema={validate}
              onSubmit={async (values) => {
                console.log("value", values);
                // if (values.name !== "") {
                var body = {
                  id: data?.id,
                  name: values.name,
                  description: values.description,
                  title: values.name,
                  unitWeight: values.unitWeight,
                  netWeight: values.netWeight,
                  referenceWeight: values.referenceWeight,
                  referencePrice: values.referencePrice,
                  tradingClassification: values.tradingClassification,
                  depositAmount: values.depositAmount,
                  countryOfOrigin: values.countryOfOrigin
                    ? values.countryOfOrigin
                    : "",
                  purchasePrice: values.purchasePrice,
                  sellPriceWithTax: values.sellPrice,
                  barCode: values.barCode,
                  isoCurrencyCode: select?.currency?.code,
                  currencySymbol: select?.currency?.value,
                  packageType: select.packageType.value
                    ? select.packageType.value
                    : "Box",
                  vegMark: vegMark,
                  isRefWeightForDisplayEnabled: isRefWeightForDisplayEnabled,
                  hsnCode: values.hsnCode,
                  status: status,
                  rootOrgId: parseInt(
                    JSON.parse(localStorage.getItem("dashboard-data"))?.id
                  ),
                  // productCategories: [
                  //   {
                  //     categoryId: select.productCategory.value
                  //       ? select.productCategory.value
                  //       : initialValues?.category,
                  //   },
                  // ],
                  supplier: {
                    id: select.supplierType.value
                      ? select.supplierType.value
                      : initialValues?.supplier?.id,
                  },
                  // brand: {
                  //   id: select.brandType.value
                  //     ? select.brandType.value
                  //     : initialValues?.brand?.id,
                  // },
                  imageUrl: initialValues?.imageUrl
                    ? initialValues?.imageUrl
                    : "",
                  netWeightUOM: select.netType.value
                    ? select.netType.value
                    : initialValues.netWeightUOM,
                  referenceUOM: select.referenceType.value
                    ? select.referenceType.value
                    : initialValues.referenceUOM,
                  // tradingClassification: select.tradeClass.value
                  //   ? select.tradeClass.value
                  //   : initialValues.tradingClassification,
                  unitOfMeasure: select.unitType.value
                    ? select.unitType.value
                    : initialValues.unitOfMeasure,
                  taxCategoryType: select.taxType.label
                    ? select.taxType.label
                    : initialValues.taxCategoryType,
                  taxCategoryValue: select.taxType.value
                    ? select.taxType.value
                    : initialValues.taxCategoryValue,
                  productPrice: {
                    purchasePrice: values.purchasePrice,
                    sellPrice: values.sellPrice,
                  },

                  skuType: select?.skuType?.value
                    ? select.skuType.value
                    : initialValues?.skuType,
                  pricingType: select?.pricingType?.value,
                  productDimension: {
                    height: values?.height,
                    width: values?.width,
                    length: values?.length,
                    depth: values?.depth,
                    unitOfMeasurement: select?.unitOfMeasurement?.value,
                  },
                };
                console.log("body", body);
                await createProduct(body);
                // }
              }}
            >
              {({ onSubmit, errors }) => (
                <Form className="w-full mx-auto h-auto bg-white rounded-lg shadow-lg ">
                  <div className="w-full h-12 flex bg-dash rounded-t-lg text-white">
                    <div className="w-10 p-2 h-full flex items-center">
                      <BiArrowBack
                        onClick={() => history.goBack()}
                        className="h-10 w-10 cursor-pointer"
                      />
                    </div>
                    <div className="w-full h-full px-7 flex flex-col justify-center">
                      <h2 className="font-bold text-2xl">
                        {`${t("Edit")} ${t("product")}`}
                      </h2>
                    </div>
                  </div>

                  <div className="w-full h-[83.5vh] scrollbar-thin scrollbar-thumb-gray-100 scrollbar-track-gray-300 ">
                    <div className="flex ">
                      <div className="w-full px-12 py-5">
                        <div className=" grid grid-cols-2 gap-x-4 gap-y-6 mt-1">
                          {/*  first row   */}
                          <div className="flex     w-full items-center select-none">
                            <TextField2
                              editable={isEditable}
                              label={t("productName")}
                              name="name"
                              type="text"
                            />
                          </div>

                          <div className="flex     w-full items-center select-none">
                            <TextField2
                              editable={isEditable}
                              label="Title"
                              name="title"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-1 mt-2">
                          <div className="flex w-full items-center select-none">
                            <TextArea
                              label={t("description")}
                              name="description"
                              type="text"
                              disabled={isEditable}
                            />
                          </div>
                        </div>

                        <div className="grid grid-cols-3 gap-x-4 gap-y-4 mt-3">
                          <Select
                            isDisabled={isEditable}
                            placeholder={t("Category")}
                            maxMenuHeight={162}
                            value={select.productCategory}
                            onChange={(value) =>
                              handleSelect(value, "productCategory")
                            }
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            styles={customStyles}
                            options={pCatList?.data?.map((item, index) => {
                              return {
                                label: item?.name,
                                value: item?.id,
                                key: item?.parent?.id,
                              };
                            })}
                          />

                          <Select
                            isDisabled={isEditable}
                            maxMenuHeight={162}
                            placeholder={t("subCategory")}
                            value={select.productSubCategory}
                            onChange={(value) =>
                              handleSelect(value, "productSubCategory")
                            }
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            styles={customStyles}
                            options={subCat?.map((item, index) => {
                              return {
                                label: item.name,
                                value: item.id,
                                key: item.id,
                              };
                            })}
                            di
                          />
                        </div>

                        <div className="grid grid-cols-2 gap-x-4 gap-y-3 mt-6">
                          <div className="flex  w-full items-center select-none">
                            <TextField2
                              label={t("unitWeight")}
                              name="unitWeight"
                              type="text"
                            />
                          </div>

                          <Select
                            placeholder={t("uom")}
                            maxMenuHeight={162}
                            value={select.unitType}
                            onChange={(value) =>
                              handleSelect(value, "unitType")
                            }
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            styles={customStyles}
                            options={uomList?.data?.map((item, index) => {
                              return {
                                label: item.name,
                                value: item.name,
                                key: item.id,
                              };
                            })}
                          />
                        </div>

                        <div className="grid grid-cols-2 gap-x-4 gap-y-4 mt-6">
                          <div className="flex  w-full items-center select-none">
                            <TextField2
                              label={t("netWeight")}
                              name="netWeight"
                              type="text"
                            />
                          </div>

                          <Select
                            placeholder="Netweight UOM"
                            maxMenuHeight={162}
                            value={select.netType}
                            onChange={(value) => handleSelect(value, "netType")}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            styles={customStyles}
                            options={netWeightUOM}
                          />
                        </div>

                        <div className="grid grid-cols-2 gap-x-4 gap-y-4 mt-3">
                          <div className="flex  w-full items-center select-none">
                            <TextField2
                              editable={isEditable}
                              label={t("referenceWeight")}
                              name="referenceWeight"
                              type="text"
                            />
                          </div>

                          <Select
                            isDisabled={isEditable}
                            placeholder="Reference Weight UOM"
                            maxMenuHeight={162}
                            value={select.referenceType}
                            name="referenceType"
                            onChange={(value) =>
                              handleSelect(value, "referenceType")
                            }
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            styles={customStyles}
                            options={referenceUOM}
                          />
                        </div>

                        <div className="grid grid-cols-2 gap-x-4 gap-y-4 mt-3">
                          <div className="flex  w-full items-center select-none">
                            <TextField2
                              editable={isEditable}
                              label={t("referencePrice")}
                              name="referencePrice"
                              type="text"
                            />
                          </div>
                          <div className="w-60 flex items-center rounded-lg">
                            <input
                              type="checkbox"
                              onChange={(e) => setRefDis(e.target.checked)}
                              className=" h-5 w-5"
                              checked={isRefWeightForDisplayEnabled}
                              disabled={isEditable}
                            />
                            <span className="mx-5 text-gray-500">
                              {t("Reference")}
                            </span>
                          </div>
                        </div>

                        <div className="grid grid-cols-2 gap-x-4 gap-y-4 mt-3">
                          <div className="flex   w-full items-center select-none">
                            <TextField2
                              editable={isEditable}
                              label={t("barcode")}
                              name="barCode"
                              type="text"
                            />
                          </div>

                          <div className="flex   w-full items-center select-none">
                            <TextField2
                              editable={isEditable}
                              label={t("HSNCode")}
                              name="hsnCode"
                              type="text"
                            />
                          </div>
                        </div>

                        <div className="grid grid-cols-2 gap-x-4 gap-y-4 mt-3">
                          <div className="">
                            <TextField2
                              editable={isEditable}
                              label={t("purchasePrice")}
                              name="purchasePrice"
                              type="text"
                            />
                          </div>

                          <div className="">
                            <TextField2
                              editable={isEditable}
                              label={t("Gross Price")}
                              name="sellPrice"
                              type="text"
                            />
                          </div>
                        </div>

                        <div className="grid grid-cols-3 gap-x-4 gap-y-4 mt-3">
                          <Select
                            isDisabled={isEditable}
                            placeholder="Price Type"
                            maxMenuHeight={162}
                            value={select.pricingType}
                            onChange={(value) =>
                              handleSelect(value, "pricingType")
                            }
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            styles={customStyles}
                            options={pricingType}
                          />

                          <Select
                            isDisabled={isEditable}
                            placeholder={t("SKUType")}
                            maxMenuHeight={162}
                            value={select.skuType}
                            onChange={(value) => handleSelect(value, "skuType")}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            styles={customStyles}
                            options={skuType}
                          />

                          <Select
                            isDisabled={isEditable}
                            placeholder={t("supplier")}
                            maxMenuHeight={162}
                            value={select.supplierType}
                            onChange={(value) =>
                              handleSelect(value, "supplierType")
                            }
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            styles={customStyles}
                            options={supplierList?.data?.map((item, index) => {
                              return {
                                label: item.name,
                                value: item.id,
                                key: item.id,
                              };
                            })}
                          />
                        </div>

                        <div className="grid grid-cols-3 gap-x-4 gap-y-4 mt-3">
                          <div className="flex  w-full items-center select-none">
                            <TextField2
                              editable={isEditable}
                              label={t("depositAmount")}
                              name="depositAmount"
                              type="number"
                            />
                          </div>

                          <Select
                            isDisabled={isEditable}
                            placeholder={t("currency")}
                            maxMenuHeight={162}
                            value={select.currency}
                            onChange={(value) =>
                              handleSelect(value, "currency")
                            }
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            styles={customStyles}
                            options={currencyType}
                          />

                          <Select
                            isDisabled={isEditable}
                            placeholder={t("tax")}
                            maxMenuHeight={162}
                            value={select.taxType}
                            onChange={(value) => handleSelect(value, "taxType")}
                            styles={customStyles}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            options={taxList?.data?.map((item, index) => {
                              return {
                                label: `${item?.name}`,
                                value: item.value,
                                key: item.id,
                              };
                            })}
                          />

                          <div className="flex w-full items-center select-none">
                            <TextField2
                              editable={isEditable}
                              label={t("country Of Origin")}
                              name="countryOfOrigin"
                              type="text"
                            />
                          </div>

                          <div className="flex w-full items-center select-none">
                            <TextField2
                              editable={isEditable}
                              label={t("Trading Class")}
                              name="tradingClassification"
                              type="text"
                            />
                          </div>

                          {/* <Select
                          isDisabled={isEditable}
                            placeholder="Trading Class"
                            maxMenuHeight={162}
                            value={select.tradeClass}
                            onChange={(value) =>
                              handleSelect(value, "tradeClass")
                            }
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            styles={customStyles}
                            options={tradingClassification}
                          /> */}

                          <div className="w-60 flex items-center rounded-lg">
                            <input
                              type="checkbox"
                              onChange={(e) => setVegMark(e.target.checked)}
                              className=" h-5 w-5"
                              checked={vegMark}
                              disabled={isEditable}
                            />
                            <span className="mx-5 text-gray-500">
                              {t("Veg Mark")}
                            </span>
                          </div>

                          {/* {select?.sku?.value !== "NON_STANDARD_SKU" ? (
                          <Select
                          isDisabled={isEditable}
                            placeholder={t("package")}
                            options={packageType}
                            maxMenuHeight={162}
                            value={select.packageType}
                            name="packageType"
                            onChange={handleSelect}
                            styles={customStyles}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                          />
                        ) : null} */}

                          {/* <Select
                          isDisabled={isEditable}
                          placeholder="Deposited Product"
                          maxMenuHeight={162}
                          value={select.depositType}
                          name="depositType"
                          onChange={handleSelect}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          styles={customStyles}
                          options={isDepositRequired}
                        /> */}
                        </div>

                        <div className="my-3">
                          <p className="font-bold">Product Dimensions:</p>
                        </div>

                        <div className="grid grid-cols-2 gap-x-4 gap-y-4 mt-3">
                          <div className="flex  w-full items-center select-none">
                            <TextField2
                              editable={isEditable}
                              label={t("Height")}
                              name="height"
                              type="text"
                            />
                          </div>

                          <TextField2
                            editable={isEditable}
                            label={t("Depth")}
                            name="depth"
                            type="text"
                          />
                        </div>

                        <div className="grid grid-cols-2 gap-x-4 gap-y-4 mt-3">
                          <div className="flex  w-full items-center select-none">
                            <TextField2
                              editable={isEditable}
                              label={t("Width")}
                              name="width"
                              type="text"
                            />
                          </div>

                          <TextField2
                            editable={isEditable}
                            label={t("Length")}
                            name="length"
                            type="text"
                          />
                        </div>

                        <div className="grid grid-cols-2 gap-x-4 gap-y-4 mt-3">
                          <Select
                            isDisabled={isEditable}
                            placeholder="Unit Of Measurement"
                            maxMenuHeight={162}
                            value={select.unitOfMeasurement}
                            name="unitOfMeasurement"
                            onChange={(value) =>
                              handleSelect(value, "unitOfMeasurement")
                            }
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            styles={customStyles}
                            options={unitOfMeasurement}
                          />
                        </div>
                        {/* <div className="grid grid-cols-3 gap-x-4 gap-y-3 mt-3">
                        

                        <Select
                        isDisabled={isEditable}
                          placeholder={t("Brand")}
                          maxMenuHeight={162}
                          value={select.brandType}
                          name="brandType"
                          onChange={handleSelect}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          options={brandList?.data?.map((item, index) => {
                            return {
                              label: item.name,
                              value: item.id,
                              key: item.id,
                            };
                          })}
                        />

                        <div className="w-full h-full flex items-center justify-end rounded-lg">
                          <span className=" mr-4 text-gray-500">Status</span>
                          <Toggle
                            onChange={statusChecked}
                            checked={status}
                            //icons={{ checked: null, unchecked: null }}
                            className="react-switch"
                          />
                        </div>

                        <div className="">
                          <TextField2
                          editable={isEditable}
                            label={t("purchasePrice")}
                            name="purchasePrice"
                            type="text"
                          />
                        </div>

                        <div className="">
                          <TextField2
                          editable={isEditable}
                            label={t("sellingPrice")}
                            name="sellPrice"
                            type="text"
                          />
                        </div>

                        <Select
                        isDisabled={isEditable}
                          placeholder={t("currency")}
                          maxMenuHeight={162}
                          value={select.currency}
                          name="currency"
                          onChange={handleSelect}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          styles={customStyles}
                          options={currencyType}
                        />
                      </div> */}

                        <div className="grid grid-cols-1 gap-x-4 mt-6 ">
                          <div className="w-full flex items-center justify-center">
                            <button
                              type="submit"
                              onClick={onSubmit}
                              className="bg-green-500 rounded-lg px-4 py-2  focus:outline-none text-white"
                            >
                              <span className="h-full flex items-center">
                                <svg
                                  className="svg-icon"
                                  viewBox="0 0 20 20"
                                  width="24px"
                                  height="24px"
                                >
                                  <path
                                    fill="#8A2BE2"
                                    d="M19.404,6.65l-5.998-5.996c-0.292-0.292-0.765-0.292-1.056,0l-2.22,2.22l-8.311,8.313l-0.003,0.001v0.003l-0.161,0.161c-0.114,0.112-0.187,0.258-0.21,0.417l-1.059,7.051c-0.035,0.233,0.044,0.47,0.21,0.639c0.143,0.14,0.333,0.219,0.528,0.219c0.038,0,0.073-0.003,0.111-0.009l7.054-1.055c0.158-0.025,0.306-0.098,0.417-0.211l8.478-8.476l2.22-2.22C19.695,7.414,19.695,6.941,19.404,6.65z M8.341,16.656l-0.989-0.99l7.258-7.258l0.989,0.99L8.341,16.656z M2.332,15.919l0.411-2.748l4.143,4.143l-2.748,0.41L2.332,15.919z M13.554,7.351L6.296,14.61l-0.849-0.848l7.259-7.258l0.423,0.424L13.554,7.351zM10.658,4.457l0.992,0.99l-7.259,7.258L3.4,11.715L10.658,4.457z M16.656,8.342l-1.517-1.517V6.823h-0.003l-0.951-0.951l-2.471-2.471l1.164-1.164l4.942,4.94L16.656,8.342z"
                                  ></path>
                                </svg>
                                <span className="px-4 select-none">
                                  {/* {`${t("update")}`} */}
                                  Save
                                </span>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      ) : (
        <div className="w-full h-full flex justify-center items-center">
          <ClipLoader size={150} />
        </div>
      )}
    </>
  );
}

export default EditProduct;
