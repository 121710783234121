import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TextField2 } from "../../validation/TextField2";
import { useHistory } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import Select from "react-select";
import { useAlert } from "react-alert";
import { usePost } from "../../../api/usePost";
import Toggle from "react-toggle";
import { BiSearchAlt } from "react-icons/bi";
import SearchData from "../../../components/SearchData";
import axios from "axios";
import {toast} from 'react-toastify'

const authorities = [{ value: "ROLE_EMPLOYEE_ADMIN", label: "Employee Admin" }];
let validate = Yup.object({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  login: Yup.string().required("Required"),
  email: Yup.string().email().typeError("Not a number").required("Required"),
  password: Yup.string().required("Required"),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Password must match"
  ),
  phone: Yup.number().required("Required"),
});

function CreateEmployee() {
  const [title, setTitle] = useState("Employee");
  const alert = useAlert();
  const history = useHistory();
  const [gender, setGender] = useState("");
  const [status, setStatus] = useState(false);
  let [loading, setLoading] = useState(false);

  const [select, setSelect] = useState({
    login: "",
    authoritiesName: "",
    merchant: "",
  });

  const handleSelect = (value, name) => {
    setSelect({ ...select, [name]: value });
  };

  const [input, setInput] = useState({
    merchant: "",
  });

  const [filteredData, setFilteredData] = useState(null);
  const [searchData, setSearchData] = useState("");

  const searchDataFn = (name, data) => {
    setSearchData(data?.id);
    setInput({ ...input, [name]: data?.name });
    setFilteredData(null);
  };

  const onChangeInput = (e, name) => {
    const value = e.target.value;
    setInput({ ...input, [name]: value });

    setLoading(true);

    axios({
      method: "GET",
      url: `${
        process.env.REACT_APP_BASE_URL
      }/orgs?page=0&sort=createdDate,desc&name.contains=${value.toLowerCase()}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
    }).then((res) => {
      setLoading(false);
      setFilteredData(res?.data);
    }).catch(({ message }) => {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message)
      }
    })
  };

  const statusChecked = (e) => {
    setStatus(e.target.checked);
  };

  const genderType = (e) => {
    setGender(e.target.value);
  };

  
  const createEmolyee = usePost(
    `${process.env.REACT_APP_BASE_URL}/register`,
    "employeesList"
  );

  return (
    <>
      <div className="w-full h_screen flex text-left select-none">
        <div className="w-full h-full flex justify-center items-center bg-gray-200 ">
          <Formik
            initialValues={{
              phone: "",
              firstName: "",
              lastName: "",
              email: "",
              password: "",
              login:"",
              confirmPassword: "",
            }}
            validationSchema={validate}
            onSubmit={(values) => {
              if (values.name !== "" ) {
                try {
                  var body = {
                    userInfo: {
                      phone: values.phone,
                      gender: gender,
                      rootOrgId: parseInt(3201),
                      rootOrgId: searchData,
                    },
                    login: values.login,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    password: values.password,
                    confirmPassword: values.confirmPassword,
                    activated: status,
                    authoritiesName: select.authoritiesName.value,
                    authorities: [select.authoritiesName.value],
                  };
                  createEmolyee.mutate(body);

                  //console.log("res: ");

                  if (createEmolyee.isError === false) {
                    history.goBack();
                    alert.success(
                      <div style={{ textTransform: "none" }}>
                        Successfully Created!
                      </div>
                    );
                  } else if (
                    createEmolyee?.error?.response?.data?.errorKey ===
                    "userexists"
                  ) {
                    alert.error(
                      <div style={{ textTransform: "none" }}>
                        {createEmolyee?.error?.response?.data?.title}
                      </div>
                    );
                  } else {
                    alert.error(
                      <div style={{ textTransform: "none" }}>
                        Please Check all Fields!
                      </div>
                    );
                  }
                } catch (error) {
                  alert.error(
                    <div style={{ textTransform: "none" }}>
                      you are offline!
                    </div>
                  );
                }
              }
            }}
          >
            {() => (
              <Form className="w-full h-auto  m-16 bg-white rounded-lg shadow-lg ">
                <div className="w-full h-16 flex items-center bg-nav rounded-t-lg text-white px-6 ">
                  <BiArrowBack
                    onClick={() => history.goBack()}
                    className="h-10 w-10 cursor-pointer"
                  />

                  <h2 className="font-bold text-2xl ml-4">
                    {`Create new ${title}`}
                  </h2>
                </div>

                <div className="w-11/12 mx-auto px-6 my-2">
                  <div className="w-full h-20 flex mt-4 justify-between items-center">
                    <div className="flex pl-4 pr-8 w-1/3 items-center select-none">
                      <TextField2 label="Phone No" name="phone" type="text" />
                    </div>

                    <div className="w-[290px] ml-8 ">
                    <TextField2 label="Login" name="login" type="text" />
                    </div>

                    <div className="flex pl-8 pr-4 ml-4 w-1/3  items-center select-none">
                      <h2 className="text-base pr-4">Gender: </h2>
                      <div className="flex h-10 items-center w-64 justify-between">
                        <input
                          type="radio"
                          name="gender"
                          value="MALE"
                          onChange={genderType}
                        />
                        <span>Male</span>
                        <input
                          type="radio"
                          name="gender"
                          value="FEMALE"
                          onChange={genderType}
                        />
                        <span>Female</span>
                        <input
                          type="radio"
                          name="gender"
                          value="OTHER"
                          onChange={genderType}
                        />
                        <span>Other</span>
                      </div>
                    </div>
                  </div>

                
                  <div className="w-full h-20 flex mt-1  items-center">
                    <div className="flex  px-4 mr-4   w-1/3 items-center select-none">
                      <TextField2
                        label="First Name"
                        name="firstName"
                        type="text"
                      />
                    </div>

                    <div className="flex  px-4 ml-4   w-1/3 items-center select-none">
                      <TextField2
                        label="Last Name"
                        name="lastName"
                        type="text"
                      />
                    </div>

                    <div className="flex  px-4 ml-4   w-1/3 items-center select-none">
                      <TextField2 label="email" name="email" type="text" />
                    </div>
                  </div>

                  <div className="grid grid-cols-3 gap-4 m-4 items-center">
                    <div className="w-72 flex justify-end items-center">
                      <SearchData
                        name="merchant"
                        label={"Search Merchant"}
                        input={input}
                        onChangeInput={onChangeInput}
                        filteredData={filteredData}
                        searchDataFn={searchDataFn}
                        loading={loading}
                      />
                      <BiSearchAlt className="w-12  h-5 mr-4 absolute   text-blue-500" />
                    </div>

                    <div className=" ml-5" style={{ width: "298px" }}>
                      <Select
                        options={authorities}
                        placeholder="Select Authorities"
                        maxMenuHeight={162}
                        value={select.authoritiesName}
                        onChange={(value) =>
                          handleSelect(value, "authoritiesName")
                        }
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                      />
                    </div>
                    <div className="ml-8">
                      <div className="w-full   py-4 h-full flex   items-center rounded-lg">
                        <span className=" mr-4">Status</span>
                        <Toggle
                          onChange={statusChecked}
                          checked={status}
                          //icons={{ checked: null, unchecked: null }}
                          className="react-switch"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="w-full h-20 flex items-center">
                    <div className="flex  px-4 mr-4  w-full items-center select-none">
                      <TextField2
                        label="Password"
                        name="password"
                        type="password"
                      />
                    </div>

                    <div className="flex  px-4 ml-4   w-full items-center select-none">
                      <TextField2
                        label="Confirm Password"
                        name="confirmPassword"
                        type="password"
                      />
                    </div>
                  </div>

                  <div className="w-full h-16 flex justify-end items-center px-4 -mt-2">
                    <button
                      type="submit"
                      className="bg-green-500 rounded-lg px-6 py-3  focus:outline-none text-white"
                    >
                      <span className="h-full flex items-center">
                        <svg
                          className="svg-icon"
                          viewBox="0 0 20 20"
                          width="24px"
                          height="24px"
                        >
                          <path
                            fill="#8A2BE2"
                            d="M19.404,6.65l-5.998-5.996c-0.292-0.292-0.765-0.292-1.056,0l-2.22,2.22l-8.311,8.313l-0.003,0.001v0.003l-0.161,0.161c-0.114,0.112-0.187,0.258-0.21,0.417l-1.059,7.051c-0.035,0.233,0.044,0.47,0.21,0.639c0.143,0.14,0.333,0.219,0.528,0.219c0.038,0,0.073-0.003,0.111-0.009l7.054-1.055c0.158-0.025,0.306-0.098,0.417-0.211l8.478-8.476l2.22-2.22C19.695,7.414,19.695,6.941,19.404,6.65z M8.341,16.656l-0.989-0.99l7.258-7.258l0.989,0.99L8.341,16.656z M2.332,15.919l0.411-2.748l4.143,4.143l-2.748,0.41L2.332,15.919z M13.554,7.351L6.296,14.61l-0.849-0.848l7.259-7.258l0.423,0.424L13.554,7.351zM10.658,4.457l0.992,0.99l-7.259,7.258L3.4,11.715L10.658,4.457z M16.656,8.342l-1.517-1.517V6.823h-0.003l-0.951-0.951l-2.471-2.471l1.164-1.164l4.942,4.94L16.656,8.342z"
                          ></path>
                        </svg>
                        <span className="px-2">Create {title}</span>
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default CreateEmployee;
