import React from "react";
import TableStr from "./TableStr";
import Enabled from "./options/Enabled";
import Disabled from "./options/Disabled";
import All from "./options/All";


function Category() {
  return (
    <div className="h-full w-full ">
      <TableStr
        title={"Product Category"}
        url={ "/admin/merchant/dashboard/productManagement/uom/createUom"}
        viewUrl={"/admin/location/view"}
        editUrl={"/admin/location/edit"}
        listUrl={`${process.env.REACT_APP_BASE_URL}/categories?parentId.specified=false&rootOrgId.equals=${parseInt(JSON.parse(localStorage.getItem("dashboard-data"))?.id)}`}
        getName={"categoryList"}
        Enabled={Enabled}
        Disabled={Disabled}
        All={All}
        showButton={false}
        Modal={"ModalCategory"}
        dashboard={true}
      />
    </div>
  );
}

export default Category;
