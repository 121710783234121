import React from 'react'

function Enabled() {
  return (
    <div>
      <h2>Enabled</h2>
    </div>
  )
}

export default Enabled
