// Import necessary libraries
import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const divStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  padding: "20px",
};

const imageStyle = {
  maxWidth: "100%",
  maxHeight: "100%",
  width: "auto",
  height: "auto",
};

const CarousalModal = ({ open, handleClose, data }) => {
  console.log("🚀 ~ file: CarousalModal.jsx:24 ~ CarousalModal ~ data:", data);
  const [sliderSettings] = useState({
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true, // Set centerMode to true
    centerPadding: "0",
  });

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          // height: 600,
          backgroundColor: "white",
          border: "1px solid #000",
          borderRadius: "10px",
          boxShadow: 24,
          p: 4,
        }}
      >
        <div style={{ textAlign: "center" }}>
          <Slider {...sliderSettings}>
            {/* Your carousel content goes here */}
            {data?.map((img) => (
              <div>
                <div style={divStyle}>
                  <img
                    src={img}
                    // style={imageStyle}
                    // style={{ width: "100%", height: "auto", margin: "0 auto" }}
                    alt=""
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </Modal>
  );
};

export default CarousalModal;
