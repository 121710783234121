import React from "react";
import TableStr from "./TableStr";
import Enabled from "./options/Enabled";
import Disabled from "./options/Disabled";
import All from "./options/All";
import Initialized from "./options/Initialized";
import NotInitialized from "./options/NotInitialized";


function Machine() {
  return (
    <div className="h-full w-full ">
      <TableStr
        title={"Machine"}
        url={"/admin/machine/newMachine"}
        viewUrl={"/admin/machine/view"}
        editUrl={"/admin/machine/edit"}
        allUrl={`${process.env.REACT_APP_BASE_URL}/machines?page=0&size=10&sort=createdDate,desc&rootOrgId.specified=true`}
        initializedUrl={`${process.env.REACT_APP_BASE_URL}/machines?page=0&size=10&sort=createdDate,desc&rootOrgId.specified=false`}
        getName={"machineList"}
        All={All}
        Initialized={Initialized}
        NotInitialized={NotInitialized}
        notInitializedUrl={`${process.env.REACT_APP_BASE_URL}//machines?page=0&size=10&sort=createdDate,desc&status.notEquals=INITIALIZED`}
        orgIdUrl={`${process.env.REACT_APP_BASE_URL}/orgs?page=0&sort=createdDate`}
        getOrgName={"OrgIdList"}
        retailerIdUrl={`${process.env.REACT_APP_BASE_URL}/retail-stores?page=0&sort=createdDate`}
        getRetailerName={"retailerIdList"}
      />
    </div>
  );
}

export default Machine;
