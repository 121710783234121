import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TextField2 } from "../../../../../../validation/TextField2";
import { useHistory } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { useAlert } from "react-alert";
import { usePost } from "../../../../../../../api/usePost";
import { useLocation } from "react-router-dom";
import Select from "react-select";

const customStyles = {
  control: (base) => ({
    ...base,
    height: 55,
    minHeight: 35,
  }),
};

const options = [
  { label: "Plain", value: "plain" },
  { label: "Encrypted", value: "encrypted" },
];

let validate;
validate = Yup.object({
  configName: Yup.string().required("Config name is required"),
  configValue: Yup.string().required("config value is required"),
});

function CreateMerchantconfig() {
  const alert = useAlert();

  const history = useHistory();
  const location = useLocation();
  console.log("🚀 ~ CreateMerchantconfig ~ location:", location);

  const { isConfigureRetailStore } = location?.state || {};
  console.log(
    "🚀 ~ CreateMerchantconfig ~ isConfigureRetailStore:",
    isConfigureRetailStore
  );

  const merchantId = parseInt(
    JSON.parse(localStorage.getItem("dashboard-data"))?.id
  );

  const [title, setTitle] = useState("Merchant");

  const createAPI = usePost(
    isConfigureRetailStore
      ? `${process.env.REACT_APP_BASE_URL}/retail-store-configurations`
      : `${process.env.REACT_APP_BASE_URL}/org-configurations`,
    "creatAPI"
  );

  //console.log("res: ", createMerchant);

  return (
    <>
      <div className="w-full h-screen flex">
        <div className="w-full h-full flex justify-center items-center bg-gray-200 p-12 ">
          <Formik
            initialValues={{
              configName: "",
              configValue: "",
              configType: "",
            }}
            validationSchema={validate}
            onSubmit={async (values) => {
              if (values.configName !== "") {
                try {
                  let data;
                  if (isConfigureRetailStore) {
                    data = {
                      rootOrgId: merchantId,
                      configName: values.configName,
                      configValue: values.configValue,
                      configType: values?.configType,
                      configEncryptedValue:
                        values?.configType == "plain"
                          ? null
                          : values?.configType,
                      retailStoreId: location?.state?.retailerStore?.id,
                    };
                  } else {
                    data = {
                      rootOrgId: merchantId,
                      orgId: merchantId,
                      configName: values.configName,
                      configValue: values.configValue,
                    };
                  }
                  createAPI.mutate(data);

                  if (createAPI.isIdle === true) {
                    history.goBack();
                    alert.success(
                      <div style={{ textTransform: "none" }}>
                        Successfully Added!
                      </div>
                    );
                  } else {
                    alert.error(
                      <div style={{ textTransform: "none" }}>
                        Please Check all Fields!
                      </div>
                    );
                  }
                } catch (error) {
                  alert.error(
                    <div style={{ textTransform: "none" }}>
                      you are offline!
                    </div>
                  );
                }
              }
            }}
          >
            {({ values, setFieldValue }) => (
              <Form className="w-11/12 mx-auto h-auto bg-white rounded-lg shadow-lg text-left">
                <div
                  className={`w-full h-16 flex ${
                    localStorage.getItem("role") === "support"
                      ? "bg-green-500"
                      : "bg-nav"
                  } text-white rounded-t-lg`}
                >
                  <div className="w-10 p-2 h-full flex items-center">
                    <BiArrowBack
                      onClick={() => history.goBack()}
                      className="h-10 w-10 cursor-pointer"
                    />
                  </div>
                  <div className="w-full h-full px-7 py-3 flex flex-col justify-center">
                    <h2 className="font-bold text-2xl">
                      {isConfigureRetailStore
                        ? "Create store configuration"
                        : `Create new configuration`}
                    </h2>
                  </div>
                </div>

                <div className="w-11/12 mx-auto px-6 my-2">
                  <div className="w-full h-20 flex mt-4  items-center">
                    <div className="flex  px-4 mr-4   w-full items-center select-none">
                      <TextField2
                        label="Config Name"
                        name="configName"
                        type="text"
                      />
                    </div>

                    <div className="flex  px-4 ml-4   w-full items-center select-none">
                      <TextField2
                        label="Config Value"
                        name="configValue"
                        type="text"
                      />
                    </div>

                    {isConfigureRetailStore && (
                      <div className="flex  w-full select-none">
                        <div style={{ width: "400px" }}>
                          <Select
                            placeholder="Retail Store"
                            maxMenuHeight={162}
                            value={
                              options?.find(
                                (item) => item?.value == values?.configType
                              ) || ""
                            }
                            onChange={(e) => {
                              setFieldValue("configType", e.value);
                            }}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            styles={customStyles}
                            options={options}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="w-full h-16 flex justify-end items-center px-4 mt-2 ">
                    <button
                      type="submit"
                      className="bg-green-500 rounded-md px-6 py-3  focus:outline-none text-white"
                    >
                      <span className="h-full flex items-center">
                        {/* <svg
                          className="svg-icon"
                          viewBox="0 0 20 20"
                          width="24px"
                          height="24px"
                        >
                          <path
                            fill="#8A2BE2"
                            d="M19.404,6.65l-5.998-5.996c-0.292-0.292-0.765-0.292-1.056,0l-2.22,2.22l-8.311,8.313l-0.003,0.001v0.003l-0.161,0.161c-0.114,0.112-0.187,0.258-0.21,0.417l-1.059,7.051c-0.035,0.233,0.044,0.47,0.21,0.639c0.143,0.14,0.333,0.219,0.528,0.219c0.038,0,0.073-0.003,0.111-0.009l7.054-1.055c0.158-0.025,0.306-0.098,0.417-0.211l8.478-8.476l2.22-2.22C19.695,7.414,19.695,6.941,19.404,6.65z M8.341,16.656l-0.989-0.99l7.258-7.258l0.989,0.99L8.341,16.656z M2.332,15.919l0.411-2.748l4.143,4.143l-2.748,0.41L2.332,15.919z M13.554,7.351L6.296,14.61l-0.849-0.848l7.259-7.258l0.423,0.424L13.554,7.351zM10.658,4.457l0.992,0.99l-7.259,7.258L3.4,11.715L10.658,4.457z M16.656,8.342l-1.517-1.517V6.823h-0.003l-0.951-0.951l-2.471-2.471l1.164-1.164l4.942,4.94L16.656,8.342z"
                          ></path>
                        </svg> */}
                        <span className="px-2">
                          {isConfigureRetailStore ? "Add" : `Create ${title}`}
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default CreateMerchantconfig;
