import React from "react";
import TableStr from "./TableStr";
import Enabled from "./options/Enabled";
import Disabled from "./options/Disabled";
import All from "./options/All";


function Tax() {
 

  return (
    <div className="h-full w-full ">
      <TableStr
        title={"Tax Category"}
        url={"/admin/merchant/dashboard/productManagement/supplier/CreateSupplier"}
        viewUrl={"/admin/location/view"}
        editUrl={"/admin/location/edit"}
        listUrl={`${process.env.REACT_APP_BASE_URL}/tax-categories?sort=createdDate,desc&rootOrgId=${parseInt(JSON.parse(localStorage.getItem("dashboard-data"))?.id)}`}
        getName={"taxList"}
        Enabled={Enabled}
        Disabled={Disabled}
        All={All}
        showButton={false}
        Modal = {"ModalTax"}
        dashboard={true}
      />
    </div>
  );
}

export default Tax;
