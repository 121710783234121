export const fields = {
  //order status
  PAYMENT_WAIVED: { title: "Payment Waived", color: "text-black" },
  PAYMENT_PENDING: { title: "Payment Pending", color: "text-yellow-400" },
  PAYMENT_PROCESSED: { title: "Payment Processed", color: "text-yellow-400" },
  PAYMENT_PARTIALLY_REFUNDED: {
    title: "Payment Partially Refunded",
    color: "text-green-400",
  },
  PAYMENT_REFUNDED: { title: "Payment Refunded", color: "text-green-400" },
  PAYMENT_CONFIRMATION_PENDING: {
    title: "Payment Confirmation Pending",
    color: "text-yellow-400",
  },
  PAYMENT_REJECTED: { title: "Payment Rejected", color: "text-red-500" },
  PAYMENT_FAILED: { title: "Payment Failed", color: "text-red-500" },
  PAYMENT_REQUIRES_ACTION: {
    title: "Payment Requires Action",
    color: "text-yellow-400",
  },

  //refund status
  IN_PROCESS: { title: "In-Progress", color: "text-yellow-400" },
  REFUND_PROCESSED: { title: "Refund Processed", color: "text-green-500" },
  REFUND_APPROVED: { title: "Refund Approved", color: "text-green-500" },
  REFUND_SUBMITTED: { title: "Refund Submitted", color: "text-yellow-400" },
  REFUND_FAILED: { title: "Refund Failed", color: "text-red-500" },
  REFUND_REJECTED: { title: "Refund Rejected", color: "text-red-500" },
  REFUND_POSTED: { title: "Refund Posted", color: "text-yellow-400" },
  REFUND_PAYMENT_CONFIRMATION_IN_PROCESS: {
    title: "Confirmation In-Process",
    color: "text-yellow-400",
  },
};

export const reverseFilter = {
  "Payment Waived": { key: "PAYMENT_WAIVED", color: "text-black" },
  "Payment Pending": { key: "PAYMENT_PENDING", color: "text-yellow-400" },
  "Payment Processed": { key: "PAYMENT_PROCESSED", color: "text-yellow-400" },
  "Payment Partially Refunded": {
    key: "PAYMENT_PARTIALLY_REFUNDED",
    color: "text-green-400",
  },
  "Payment Refunded": { key: "PAYMENT_REFUNDED", color: "text-green-400" },
  "Payment Confirmation Pending": {
    key: "PAYMENT_CONFIRMATION_PENDING",
    color: "text-yellow-400",
  },
  "Payment Rejected": { key: "PAYMENT_REJECTED", color: "text-red-500" },
  "Payment Failed": { key: "PAYMENT_FAILED", color: "text-red-500" },
  "Payment Requires Action": {
    key: "PAYMENT_REQUIRES_ACTION",
    color: "text-yellow-400",
  },

  //refund status
  "In-Progress": { key: "IN_PROCESS", color: "text-yellow-400" },
  "Refund Processed": { key: "REFUND_PROCESSED", color: "text-green-500" },
  "Refund Approved": { key: "REFUND_APPROVED", color: "text-green-500" },
  "Refund Submitted": { key: "REFUND_SUBMITTED", color: "text-yellow-400" },
  "Refund Failed": { key: "REFUND_FAILED", color: "text-red-500" },
  "Refund Rejected": { key: "REFUND_REJECTED", color: "text-red-500" },
  "Refund Posted": { key: "REFUND_POSTED", color: "text-yellow-400" },
  "Confirmation In-Process": {
    key: "REFUND_PAYMENT_CONFIRMATION_IN_PROCESS",
    color: "text-yellow-400",
  },
};
