import React, { useState } from "react";
import { FiLogOut } from "react-icons/fi";
import { useHistory } from "react-router-dom";

import { BroadcastChannel } from "broadcast-channel";

const logoutChannel = new BroadcastChannel("logout");

function Header() {
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(!open);
  const history = useHistory();

  const logout = () => {
    logoutChannel.postMessage({ logoutMessage: "oh!, Autonomo logout" });
    localStorage.clear();
    history.push("/login");
  };
  const profile =
    "https://images.unsplash.com/photo-1612966809559-dac0bed38b6a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80";
  return (
    <>
      <div className="h_nav flex w-full justify-end items-center bg-dashback pr-10 select-none">
        
      </div>
      {open && (
        <div className="w-32  p-3 z-10 absolute flex top-14 right-0  items-center bg-white select-none">
          <FiLogOut />
          <button className="focus:outline-none mx-2" onClick={logout}>
            Logout
          </button>
        </div>
      )}
    </>
  );
}

export default Header;

export const logoutAllTabsEventListener = () => {
  logoutChannel.onmessage = (event) => {
    logoutChannel.close();
    console.log(event.logoutMessage);
  };
};
