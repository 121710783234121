import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Draggable from "react-draggable";
import CarouselVideo from "../VideoCarousal";

const DraggablePaper = (props) => {
  return (
    <Draggable handle="#draggable-modal-title">
      <div
        {...props}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 700,
          backgroundColor: "rgba(134, 134, 134, 0.8)",
          border: "1px solid #000",
          borderRadius: "10px",
          boxShadow: 24,
          padding: 5,
          cursor: "move",
        }}
        id="draggable-modal-title"
      />
    </Draggable>
  );
};

const CarousalVideoModal = ({ open, handleClose, data }) => {
  const [sliderSettings] = useState({
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0",
  });

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DraggablePaper>
        {/* <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          backgroundColor: "rgba(134, 134, 134, 0.8)",
          border: "1px solid #000",
          borderRadius: "10px",
          boxShadow: 24,
          padding: 5,
          cursor: "move",
        }}
        id="draggable-modal-title"
      > */}
        <div style={{ textAlign: "center" }}>
          <CarouselVideo data={data} />
        </div>
        {/* </div> */}
      </DraggablePaper>
    </Modal>
  );
};

export default CarousalVideoModal;
