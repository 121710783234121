import React, { forwardRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import Slider from "@mui/material/Slider";
import Tooltip from "@material-ui/core/Tooltip";
import FullScreen from "@material-ui/icons/Fullscreen";

const useStyles = makeStyles((theme) => ({
  controlsWrapper: {
    visibility: "visible",
    position: "relative",
    height: "40px",
    backgroundColor: "yellow",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  button: {
    margin: theme.spacing(1),
  },
  controlIcons: {
    color: "#777",

    fontSize: 50,
    transform: "scale(0.9)",
    "&:hover": {
      color: "blue",
      transform: "scale(1)",
    },
  },

  bottomIcons: {
    color: "#999",
    "&:hover": {
      color: "blue",
    },
  },

  volumeSlider: {
    width: 100,
  },
}));

const PrettoSlider = withStyles({
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "blue",
    border: "2px solid currentColor",
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

const Controls = forwardRef(
  (
    {
      onSeek,
      onSeekMouseDown,
      onSeekMouseUp,
      onDuration,
      onRewind,
      onPlayPause,
      onFastForward,
      playing,
      played,
      elapsedTime,
      totalDuration,
      onMute,
      muted,
      onVolumeSeekDown,
      onChangeDispayFormat,
      playbackRate,
      onPlaybackRateChange,
      onToggleFullScreen,
      volume,
      onVolumeChange,
    },
    ref
  ) => {
    const classes = useStyles();
    const [show, setShow] = React.useState(false);
    const handleClick = () => {
      setShow(!show);
    };

    const selectedRated = (rate) => {
      onPlaybackRateChange(rate);
      setShow(!show);
    };

    return (
      <div
        ref={ref}
        className="w-full flex justify-between select-none bg-yellow-100 items-center h-full"
      >
        <div className="flex space-x-8 items-center">
          <button
            onClick={onPlayPause}
            className={`${classes.bottomIcons} focus:outline-none rounded`}
          >
            {playing && played < 1 ? (
              <PauseIcon fontSize="large" />
            ) : (
              <PlayArrowIcon fontSize="large" /> //pause
            )}
          </button>

          <button className="focus:outline-none cursor-pointer ">
            <Typography
              variant="body1"
              style={{ color: "blue", marginLeft: -8 }}
            >
              {elapsedTime}/{totalDuration}
            </Typography>
          </button>
        </div>

        <div className="w-full h-full flex items-center mx-8">
          <PrettoSlider
            min={0}
            max={100}
            ValueLabelComponent={(props) => (
              <ValueLabelComponent {...props} value={elapsedTime} />
            )}
            aria-label="custom thumb label"
            value={played * 100}
            onChange={onSeek}
            onMouseDown={onSeekMouseDown}
            onChangeCommitted={onSeekMouseUp}
            onDuration={onDuration}
          />
        </div>

        <div className="flex space-x-8 items-center">
          <button
            onClick={handleClick}
            className="focus:outline-none flex justify-end text-gray-500"
          >
            <Typography>{playbackRate}X</Typography>
          </button>

          {show && (
            <>
              <div
                direction="column-reverse"
                className="absolute bottom-2 right-10 bg-gray-200 rounded-lg  z-[110]"
              >
                {[0.5, 1, 1.5, 2, 3, 4].map((rate) => (
                  <div
                    key={rate}
                    //   onClick={() => setState({ ...state, playbackRate: rate })}
                    onClick={() => selectedRated(rate)}
                    variant="text"
                    className="focus:outline-none px-3"
                  >
                    <Typography
                      color={rate === playbackRate ? "secondary" : "inherit"}
                      className="cursor-pointer select-none"
                    >
                      {rate}X
                    </Typography>
                  </div>
                ))}
              </div>
            </>
          )}

          <button
            onClick={onToggleFullScreen}
            className={`${classes.bottomIcons} focus:outline-none  rounded`}
          >
            <FullScreen fontSize="large" />
          </button>
        </div>
      </div>
    );
  }
);

Controls.propTypes = {
  onSeek: PropTypes.func,
  onSeekMouseDown: PropTypes.func,
  onSeekMouseUp: PropTypes.func,
  onDuration: PropTypes.func,
  onRewind: PropTypes.func,
  onPlayPause: PropTypes.func,
  onFastForward: PropTypes.func,
  onVolumeSeekDown: PropTypes.func,
  onChangeDispayFormat: PropTypes.func,
  onPlaybackRateChange: PropTypes.func,
  onToggleFullScreen: PropTypes.func,
  onMute: PropTypes.func,
  playing: PropTypes.bool,
  played: PropTypes.number,
  elapsedTime: PropTypes.string,
  totalDuration: PropTypes.string,
  muted: PropTypes.bool,
  playbackRate: PropTypes.number,
};
export default Controls;
