import React from "react";
import { GrView } from "react-icons/gr";
import { FiEdit } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import axios from 'axios'
//import { useGet } from "../../../../api/useGet";
import {toast} from "react-toastify";

function Action({ params, url, viewUrl, editUrl }) {
  const history = useHistory();

  const fetchInitialValue = () => {
    let initialValues = {}

    params?.data?.addresses?.map((item)=>{


      initialValues = {
        name: params?.data?.name ? params?.data?.name : "",
        email: item?.contactEmail ? item?.contactEmail : "",
        contactNo: item?.contactNumber ? item.contactNumber: "",
        street1: item?.street1 ? item?.street1 : "",
        street2: item?.street2 ? item?.street2 : "",
        city: item?.cityOrVillage ? item?.cityOrVillage : "",
        administrativeArea: item?.administrativeArea1
          ? item?.administrativeArea1: "",

        latitude: item?.lat ? item?.lat: "",
        country: item?.country ? item?.country : "",
        longitude: item.lng ? item.lng : "",
        state: item?.state ? item?.state : "",
        contactFirstName : item?.contactFirstName ? item?.contactFirstName : "",
        contactMiddleName : item?.contactMiddleName ? item?.contactMiddleName : "",
        contactLastName : item?.contactLastName ? item?.contactLastName : "",   
        zip: item?.zipCode ? item?.zipCode : "",
        status: params?.data?.status ? params?.data?.status : null,
        brands: params?.data?.brands ? params?.data?.brands : "Select Brands",
        skuType:params?.data?.skuType ? params?.data?.skuType : "Select SKU Type"
      }

    })
    return initialValues
  }

  const fetchMerchant = async (url) => {

    try {
      const res = await axios({
        method: "GET",
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      });
     
      return res;
    } catch ({message}) {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message)
      }
    }
    
  }

  const edit = async() => {

   // const res = await fetchMerchant(`${process.env.REACT_APP_BASE_URL}/api/suppliers/${params?.data?.id}`)
 


   const initialValues = fetchInitialValue()

    history.push({
      pathname: editUrl,
      //search: "?query=abc",-
      state: { data: params.data, initialValues: initialValues },
    });
  };

  const view = () => {
    const initialValues =  fetchInitialValue()
    history.push({
      pathname: viewUrl,
      //search: "?query=abc",-
      state: { data: params.data, initialValues: initialValues},
    });
  };
  return (
    <>
      <div className="flex w-16 h-10 items-center">
        <div className="border-2 border-gray-200 rounded-l-lg p-1 ">
          <GrView className="w-5 h-5 cursor-pointer " onClick={view} />
        </div>
        <div className="border-2 border-gray-200 rounded-r-lg p-1">
          <FiEdit className="w-5 h-5 cursor-pointer" onClick={edit} />
        </div>
      </div>
    </>
  );
}

export default Action;
