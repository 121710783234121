import React from "react";
import TextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import MobileTimePicker from "@mui/lab/MobileTimePicker";

const TimePicker = ({value, onChangeTime, name, label, clearIcon, minDateTime, maxDateTime}) => {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        
          <MobileTimePicker
            label={label}
            value={value}
            clearIcon={clearIcon}
            onChange={(value)=> onChangeTime(value, name)}
            renderInput={(params) => <TextField {...params} />}
            inputFormat={"HH:mm"}
            views={['hours', 'minutes']}
          />
      
      </LocalizationProvider>
    );
  };
  
  export default TimePicker;
  