import React, { useState, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "react-toggle/style.css";
import Select from "react-select";
import DashboardRoute from "./DashboardRoute";
import NumberFormat from "react-number-format";
import Confirm from "./Confirm";
import Action from "./Action";
import { BiSearchAlt } from "react-icons/bi";
import Address from "./Address";
import { useGet } from "../../../../../../../api/useGet";
import axios from "axios";
import FadeLoader from "react-spinners/FadeLoader";
import MerchantDetails from "./MerchantDetails";
import { useQuery } from "react-query";
import Pagination from "@mui/material/Pagination";
import SearchData from "../../../../../../../components/SearchData";
import { MdClear } from "react-icons/md";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { removeEmptyStringKeys } from "../../../../../../../utils/utils";

function All({ listUrl, url, viewUrl, editUrl, sortedData }) {
  const [allCount, setAllCount] = useState(0);
  const [input, setInput] = useState({
    name: "",
    type: "",
    shelfId: "",
    internalCode: "",
  });

  const history = useHistory();

  const findUrl = (name) => {
    switch (name) {
      case "retailerStore":
        return `${listUrl}&page=${activePage - 1}&size=9`;
        break;

      default:
        return `${listUrl}&page=${activePage - 1}&size=9`;
        break;
    }
  };

  //list url
  const [activePage, setActivePage] = useState(1);

  const fetchData = async () => {
    const tempObj = {
      "name.contains": input?.name,
    };
    try {
      const res = await axios({
        method: "GET",
        url: `${listUrl}`,
        params: {
          ...removeEmptyStringKeys(tempObj),
          page: activePage - 1,
          size: 9,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      });

      setAllCount(res?.headers?.["x-total-count"]);

      return res.data;
    } catch ({ message }) {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message);
      }
    }
  };

  const { data, isLoading, isIdle, error } = useQuery(
    ["rackList", activePage, input?.name, input?.internalCode],
    async () => fetchData(),
    {
      enabled: !!activePage || !!input?.name || !!input?.internalCode,
      cacheTime: Infinity,
      staleTime: 0,
    }
  );

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  const onChangeInput = (e, name) => {
    const value = e.target.value;

    setInput({ ...input, [name]: value, type: name });
  };

  const clear = () => {
    setInput({ name: "", type: "", shelfId: "", internalCode: "" });
  };

  return (
    <div className=" w-full h_das_table">
      <div
        className={
          isLoading
            ? "bg-black bg-opacity-0 absolute inset-0 z-10 flex items-center justify-center"
            : null
        }
      >
        <FadeLoader className="absolute " loading={isLoading} size={150} />
      </div>
      <div className="flex h-10 w-full items-center mt-2 justify-between py-8 px-5">
        <div className="w-3/4 flex  h-full items-center space-x-[5px]">
          <div className="w-72 flex justify-end items-center">
            <input
              name="internalCode"
              placeholder="Search by Name"
              value={input?.name}
              onChange={(e) => onChangeInput(e, "name")}
              className="w-full h-10  focus:outline-none rounded px-4 border border-black"
            />
            {input["name"]?.length > 0 ? (
              <MdClear
                size={24}
                className="cursor-pointer -ml-[34px]"
                onClick={clear}
              />
            ) : (
              <BiSearchAlt
                size={24}
                className="cursor-pointer -ml-[34px] text-blue-500"
              />
            )}
          </div>
          {/* <div className="w-72 flex justify-end items-center">
            <input
              name="internalCode"
              placeholder="Search by Name"
              value={input?.name}
              onChange={(e) => onChangeInput(e, "internalCode")}
              className="w-full h-10  focus:outline-none rounded px-4 border border-black"
            />
            {input["internalCode"]?.length > 0 ? (
              <MdClear
                size={24}
                className="cursor-pointer -ml-[34px]"
                onClick={clear}
              />
            ) : (
              <BiSearchAlt
                size={24}
                className="cursor-pointer -ml-[34px] text-blue-500"
              />
            )}
          </div> */}
        </div>
        <button
          onClick={clear}
          className="bg-green-600 py-1.5 px-5 mr-7 rounded text-white cursor-pointer focus:outline-none"
        >
          Clear filters
        </button>
      </div>

      <div className="ag-theme-alpine  w-full h-full text-left">
        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 80,
            resizable: true,
          }}
          suppressRowClickSelection={true}
          rowSelection={"multiple"}
          rowData={data}
        >
          <AgGridColumn
            headerName="Rack Name"
            field="name"
            minWidth={100}
            sortable={true}
            headerCheckboxSelection={true}
            headerCheckboxSelectionFilteredOnly={true}
            checkboxSelection={true}
            cellRendererFramework={(params) => (
              <DashboardRoute params={params} />
            )}
          />

          <AgGridColumn
            headerName="Rack ID"
            field="internalCode"
            minWidth={80}
            sortable={true}
          />

          <AgGridColumn
            headerName="Retailer"
            field="rootOrgId"
            minWidth={180}
            sortable={true}
            cellRendererFramework={(params) => (
              <MerchantDetails id={params?.data?.rootOrgId} />
            )}
          />

          <AgGridColumn
            headerName="RetailStore"
            field="maxShelves"
            minWidth={180}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <>
                  <p className="">{params.data?.retailStore?.name}</p>
                </>
              );
            }}
          />

          <AgGridColumn
            headerName="Location ID"
            field="locationRef"
            minWidth={100}
            // cellRendererFramework={(params) => <Address params={params} />}
          />

          <AgGridColumn headerName="Status" field="status" minWidth={80} />

          <AgGridColumn
            field="action"
            minWidth={80}
            cellRendererFramework={(params) => (
              <Action
                params={params}
                url={url}
                viewUrl={viewUrl}
                editUrl={editUrl}
              />
            )}
          />
        </AgGridReact>
      </div>

      <div className="absolute bottom-8 right-12  flex justify-center item-center">
        <Pagination
          count={
            allCount % 9 === 0
              ? parseInt(allCount / 9)
              : parseInt(allCount / 9) + 1
          }
          showFirstButton
          showLastButton
          page={activePage}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
}

export default All;
