import React, {useState, useEffect} from "react";
import TableStr from "./TableStr";
import InReview from "./options/InReview";
import Abondend from "./options/Abondend";
import Approved from "./options/Approved";
import Rejected from "./options/Rejected";
import axios from 'axios'

function Match() {

 
  return (
    <div className="h-full w-full ">
      <TableStr
        title={"Match"}
        viewUrl={"/support/hawkeye/view"}
        editUrl={"/support/hawkeye/edit"}
       // listUrl={`http://api.shopper-uat.autonomo.tech/stores/carts?status.equals=APPROVED`}
        listUrl={'http://store-uat.autonomo.tech/api/user-exit-activities?status.equals=OPEN&sort=exitTime,desc'}
        getName={"matchList"}
        Rejected={Rejected}
        InReview={InReview}
        Approved={Approved}
        Abondend={Abondend}
       // data1={data}
      />
    </div>
  );
}

export default Match;
