import React from "react";
import TableStr from "./TableStr";
import Intialized from "./options/Intialized";
import All from "./options/All";

function FullfillmentRack() {
  return (
    <div className="h-full w-full ">
      <TableStr
        title={"Fullfillment"}
        viewUrl={"/admin/merchant/dashboard/rack/view"}
        editUrl={"/admin/merchant/dashboard/rack/edit"}
        listUrl={`${process.env.REACT_APP_BASE_URL}/_search/shelves`}
        getName={"rackList"}
        All={All}
        Intialized={Intialized}
        url={`/admin/merchant/dashboard/rack/newRack`}
      />
    </div>
  );
}

export default FullfillmentRack;
