import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { useAlert } from "react-alert";
import NumberFormat from "react-number-format";
// import ProductItem from "./ProductItem";
// import OrderSummaryHeader from "./OrderSummaryHeader";
import moment from "moment";
import { BsCloudDownload } from "react-icons/bs";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Action from "./Action";
import DashboardRoute from "./DashboardRoute";
import { useGet } from "../../../../../../../api/useGet";
import Address from "./Address";
import Confirm from "./Confirm";
import CreateShelf from "./CreateShelf";
import EditShelf from "./EditShelf";
import ViewShelf from "./ViewShelf";
import { GrView } from "react-icons/gr";
import { FiEdit } from "react-icons/fi";
import { FcMindMap } from "react-icons/fc";
import { AiFillSetting } from "react-icons/ai";
import { IoMdBriefcase } from "react-icons/io";
import { toast } from "react-toastify";
import axios from "axios";

function RackView() {
  const [title, setTitle] = useState("Machine");
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();

  const [input, setInput] = useState({
    shelfId: "",
  });

  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [view, setView] = useState(false);
  const onOpenView = () => setView(true);
  const onCloseView = () => setView(false);
  const [viewData, setViewData] = useState(null);

  const [edit, setEdit] = useState(false);
  const onOpenEdit = () => setEdit(true);
  const onCloseEdit = () => setEdit(false);
  const [editData, setEditData] = useState(null);

  const data = JSON.parse(localStorage.getItem("rackData"));

  const rootId = parseInt(
    JSON.parse(localStorage.getItem("dashboard-data"))?.id
  );

  const shelf = useGet(
    `${
      process.env.REACT_APP_BASE_URL
    }/shelves?rackId.equals=${localStorage.getItem("rackId")}&sort=name%2Casc`,
    "shelvesList"
  );

  const [filteredData, setFilteredData] = useState([]);

  const editFn = async (data) => {
    onOpenEdit();
    setEditData(data);
  };

  const viewFn = async (data) => {
    // console.log("VData: ", data);
    onOpenView();
    // history.push("/admin/merchant/dashboard/rack/view/shelves")
    setViewData(data);
  };

  const fullfillment = (val) => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}/scales?rootOrgId.equals=${rootId}&retailStoreId.equals=${val?.retailStoreId}&groupId.equals=${val?.internalCode}&scaleIdentifier=true&sort=column,asc`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
    })
      .then((res) => {
        if (res?.data?.length === 0) {
          alert?.show("Not Created");
        } else {
          const data = {
            shelfId: val?.id,
            rackId: val?.rack?.id,
            internalCode: val?.internalCode,
            retailStoreId: val?.retailStoreId,
          };

          history.push({
            pathname: "/admin/merchant/dashboard/rack/fullfillment",
            state: { fullfillment: data, rackData: val },
          });
        }
      })
      .catch(({ message }) => {
        if (message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          toast.error(message);
        }
      });
  };

  const createShelf = () => {
    onOpenModal();
  };

  const onChangeInput = (value, name) => {
    setInput({ ...input, [name]: value });
    if (name === "shelfId" && value !== "") {
      setFilteredData(
        shelf?.data?.filter((ele) => ele?.internalCode === value?.toString())
      );
    } else setFilteredData(shelf?.data);
  };

  const id = parseInt(JSON.parse(localStorage.getItem("dashboard-data"))?.id);

  const { data: isStockClearanceDiscount } = useGet(
    `${process.env.REACT_APP_BASE_URL}/_search/org-configurations?page=0&size=10&configName.equals=IS_STOCK_CLEARANCE_DISCOUNT&rootOrgId.equals=${id}`,
    "isStockClearanceDiscount"
  );

  return (
    <>
      <div
        className={`w-full   h-screen pt-2
         text-left flex select-none bg-gray-200`}
      >
        <div className="w-full h-full px-4  py-2">
          <div className="w-full  h-full  ">
            <div className="w-full h-8  justify-between flex bg-white rounded-lg items-center px-4">
              <div className="w-36 h-8 flex items-center">
                <BiArrowBack
                  onClick={() => history.goBack()}
                  className="h-6 w-6 cursor-pointer"
                />
                <h2 className="font-bold text-lg ml-2">View Rack</h2>
              </div>
              <div>
                <p className="font-bold text-lg">{data?.name}</p>
              </div>
              <div className="w-36 flex justify-center items-center">
                <button
                  onClick={createShelf}
                  className=" bg-gray-200 rounded px-4"
                >
                  Create Shelf
                </button>
              </div>
              <CreateShelf
                open={open}
                onCloseModal={onCloseModal}
                rackData={data}
              />
            </div>

            <div className="w-full h-20 rounded-lg px-2 bg-white my-4 py-1 ">
              {/* <h2 className="px-2 font-bold">Order Summary :</h2> */}

              <div className="w-full flex justify-between px-2 pt-2 items-center">
                <div className="w-1/3 border-l-2 border-bg-500 text-gray-500 px-2">
                  <h2>
                    {`RackName: `}
                    {data?.name}
                  </h2>
                </div>
                <div className="w-1/3 border-l-2 border-bg-500 text-gray-500 px-2">
                  <h2>
                    {`Rack ID: `} {data?.internalCode}
                  </h2>
                </div>

                <div className="w-1/3 border-l-2 border-bg-500 text-gray-500 px-2">
                  <h2>
                    {`Max Shelves:`}{" "}
                    {data?.maxShelves ? data?.maxShelves : "Nill"}
                  </h2>
                </div>
              </div>
              {/* secondLine */}

              <div className="w-full flex justify-start px-2 pt-2  ">
                <div className="w-1/3 border-l-2 border-bg-500 text-gray-500 px-2">
                  <h2>
                    {`Store Code:`} {data?.retailStore?.internalCode}
                  </h2>
                </div>
                <div className="w-1/3 border-l-2 border-bg-500 text-gray-500 px-2">
                  <h2>
                    {`Retailer store:`} {data?.retailStore?.name}
                  </h2>
                </div>
                <div className="w-1/3 border-l-2 border-bg-500 text-gray-500 px-2">
                  <h2>
                    {`Status:`} {data?.status ? data?.status : "Nill"}
                  </h2>
                </div>
              </div>

              {/* thirdLine */}
            </div>

            <div className="w-full flex justify-end">
              <input
                placeholder="Search by Shelf ID"
                onChange={(e) => onChangeInput(e.target.value, "shelfId")}
                value={input?.shelfId}
                className="w-[180px] border focus:outline-none rounded-lg h-8 px-4 border-gray-500"
              />
            </div>

            <div className="w-full h-7/10 rounded-lg bg-white mt-4">
              <div className="ag-theme-alpine w-full h-full text-left ">
                <AgGridReact
                  defaultColDef={{
                    flex: 1,
                    minWidth: 100,
                    resizable: true,
                  }}
                  suppressRowClickSelection={true}
                  pagination={true}
                  paginationPageSize={10}
                  rowData={
                    filteredData?.length > 0 ? filteredData : shelf?.data
                  }
                  rowHeight={60}
                >
                  <AgGridColumn
                    headerName="Shelf Name"
                    field="name"
                    minWidth={160}
                    sortable={true}
                    headerCheckboxSelection={true}
                    headerCheckboxSelectionFilteredOnly={true}
                    checkboxSelection={true}
                    cellRendererFramework={(params) => {
                      console.log("🚀 ~ RackView ~ params:", params);
                      return (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <p>{params?.data?.name}</p>
                          {params?.data?.isStockClearanceDiscount === true &&
                            isStockClearanceDiscount?.length > 0 &&
                            (isStockClearanceDiscount?.[0]?.configValue?.toLowerCase() ==
                              "true" ||
                              isStockClearanceDiscount?.[0]?.configValue ==
                                true) && (
                              <div
                                style={{
                                  // padding: "6px",
                                  backgroundColor: "rgba( 0, 102, 0, 0.21)",
                                  width: "150px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderRadius: "10px",
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  marginLeft: "10px",
                                }}
                              >
                                <img
                                  src="/images/discountTag.svg"
                                  alt=""
                                  style={{ marginTop: "3px" }}
                                />
                                <p style={{ marginLeft: "5px" }}>Discounted</p>
                              </div>
                            )}
                        </div>
                      );
                    }}
                  />

                  <AgGridColumn
                    headerName="Shelf ID"
                    field="internalCode"
                    minWidth={120}
                    maxWidth={120}
                    sortable={true}
                  />

                  <AgGridColumn
                    headerName="Max Scales"
                    field="maxScales"
                    minWidth={120}
                    maxWidth={120}
                    sortable={true}
                    cellRendererFramework={(params) => {
                      return (
                        <>
                          <p className="ml-8">
                            {params?.data?.maxScales === null
                              ? 0
                              : params.data?.maxScales}
                          </p>
                        </>
                      );
                    }}
                  />

                  <AgGridColumn
                    headerName="Retail Store"
                    field="retailStore"
                    minWidth={170}
                    sortable={true}
                    cellRendererFramework={(params) => {
                      return (
                        <>
                          <p>{params?.data?.rack?.retailStore?.name}</p>
                        </>
                      );
                    }}
                  />

                  <AgGridColumn
                    field="status"
                    minWidth={120}
                    maxWidth={120}
                    cellRendererFramework={(params) => (
                      <Confirm params={params} />
                    )}
                  />
                  <AgGridColumn
                    headerName="Action"
                    minWidth={100}
                    cellRendererFramework={(params) => (
                      <>
                        <div className="flex w-24 h-10 items-center">
                          <div className="ml-0 rounded-lg p-1">
                            <GrView
                              className="w-5 h-5 cursor-pointer hover:text-blue-500"
                              onClick={() => viewFn(params?.data)}
                            />
                          </div>
                          <div className="ml-4 rounded-lg p-1">
                            <FiEdit
                              className="w-5 h-5 cursor-pointer hover:text-blue-500"
                              onClick={() => editFn(params?.data)}
                            />
                          </div>

                          <div className="ml-4 rounded-lg p-1 ">
                            <IoMdBriefcase
                              className="w-5 h-5 cursor-pointer hover:text-blue-500"
                              onClick={() => fullfillment(params?.data)}
                            />
                          </div>

                          <div className="ml-4 rounded-lg p-1 ">
                            <AiFillSetting
                              className="w-5 h-5 cursor-pointer hover:text-blue-500"
                              onClick={() =>
                                history.push({
                                  pathname:
                                    "/admin/merchant/dashboard/rack/view/shelves",
                                  state: { data: params?.data },
                                })
                              }
                            />
                          </div>
                        </div>
                      </>
                    )}
                  />
                </AgGridReact>
              </div>
              <ViewShelf
                open={view}
                onCloseModal={onCloseView}
                data={viewData}
              />

              <EditShelf
                open={edit}
                onCloseModal={onCloseEdit}
                data={editData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RackView;
