import axios from "axios";
import { useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { removeEmptyStringKeys } from "../utils/utils";

export const useGet = (url, getName, isAPIcall = true) => {
  const history = useHistory();

  const [isApiLoading, setIsApiLoading] = useState(false);

  const { isLoading, error, data } = useQuery(
    getName,
    isAPIcall &&
      (async () => {
        setIsApiLoading(true);
        try {
          const res = await axios({
            method: "GET",
            url: url,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("user-info")}`,
            },
          });
          setIsApiLoading(false);
          return res.data;
        } catch ({ message }) {
          setIsApiLoading(false);
          if (message === "Request failed with status code 401") {
            history.push("/login");
          } else {
            toast.error(message);
          }
        }
      }),
    { cacheTime: Infinity, staleTime: 0 }
  );

  return { isLoading, error, data, isApiLoading };
};

export const useGetWithFilters = (url, getName, isAPIcall = true, filter) => {
  const history = useHistory();

  const [isApiLoading, setIsApiLoading] = useState(false);

  const { isLoading, error, data } = useQuery(
    getName,
    isAPIcall &&
      (async () => {
        setIsApiLoading(true);
        try {
          const res = await axios({
            method: "GET",
            url: url,
            params: removeEmptyStringKeys(filter),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("user-info")}`,
            },
          });
          setIsApiLoading(false);
          return res.data;
        } catch (err) {
          setIsApiLoading(false);
          if (err.message === "Request failed with status code 401") {
            history.push("/login");
          } else {
            toast.error(
              err?.response?.data?.title ||
                err?.message ||
                "Something went wrong. Please try again later."
            );
          }
        }
      }),
    { cacheTime: Infinity, staleTime: 0 }
  );

  return { isLoading, error, data, isApiLoading };
};
