import React, { useState, useEffect } from "react";
import Action from "./Action";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "react-toggle/style.css";
import Address from "./Address";
import DashboardRoute from "./DashboardRoute";
import Confirm from "./Confirm";
import { useQuery } from "react-query";
import Pagination from "@mui/material/Pagination";
import axios from "axios";
import SearchData from "../../../../components/SearchDataMany";
import { MdClear } from "react-icons/md";
import { BiSearchAlt } from "react-icons/bi";

function All({ url, viewUrl, editUrl, orgList, listUrl }) {
  const [allCount, setAllCount] = useState()
 
  const [loading, setLoading] = useState(false);

  const [activePage, setActivePage] = useState(1);

  const findUrl = (name) => {
    switch (name) {
      case "name":
        return `${listUrl}&page=${
          activePage - 1
        }&size=9&name.contains=${input?.name?.toLocaleLowerCase()}`;
        break;
      case "internalCode":
        return `${listUrl}&page=${
          activePage - 1
        }&size=9&internalCode.contains=${input?.internalCode?.toLocaleLowerCase()}`;
        break;
      default:
        return `${listUrl}&page=${activePage - 1}&size=9`;
        break;
    }
  };

  const [input, setInput] = useState({
    name: "",
    internalCode: "",
    type: "",
  });

  const fetchData = async () => {
    const res = await axios({
      method: "GET",
      url: findUrl(input?.type),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
    });
    setAllCount(res?.headers?.["x-total-count"])
    return res.data;
  };

  const { data } = useQuery(
    ["retailerList", activePage, input?.name, input?.internalCode],
    async () => fetchData(input?.type),
    {
      enabled: !!activePage || !!input?.name || !!input?.internalCode,
      cacheTime: Infinity,
      staleTime: 0,
    }
  );

  const onChangeInput = (e, name) => {
    const value = e.target.value;
    setInput({ ...input, [name]: value, type: name });
  };



  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  const clear = () => {
    setInput({
      name: "",
      internalCode: "",
      type: "",
    })
  }

  return (
    <div className=" w-full h_table select-none">
      <div className="flex h-10 w-full items-center mt-2 justify-between py-8 px-4">
        <div className="w-auto flex space-x-6 items-center">
          
        <div className="w-72 flex justify-end items-center">
            <input
              name="name"
              placeholder="Search by name"
              value={input?.name}
              onChange={(e) => onChangeInput(e, "name")}
              className="w-full h-10  focus:outline-none rounded px-4 border border-black"
            />
            {input["name"]?.length > 0 ? (
              <MdClear
                size={24}
                className="cursor-pointer -ml-[34px]"
                onClick={clear}
              />
            ) : (
              <BiSearchAlt
                size={24}
                className="cursor-pointer -ml-[34px] text-blue-500"
              />
            )}
          </div>

          <div className="w-72 flex justify-end items-center">
            <input
              name="internalCode"
              placeholder="Search by Store Code"
              value={input?.internalCode}
              onChange={(e) => onChangeInput(e, "internalCode")}
              className="w-full h-10  focus:outline-none rounded px-4 border border-black"
            />
            {input["internalCode"]?.length > 0 ? (
              <MdClear
                size={24}
                className="cursor-pointer -ml-[34px]"
                onClick={clear}
              />
            ) : (
              <BiSearchAlt
                size={24}
                className="cursor-pointer -ml-[34px] text-blue-500"
              />
            )}
          </div>

        </div>

        <button
        onClick={clear} 
         className="bg-green-600 py-1.5 px-5 mr-7 rounded text-white">
          Clear filters
        </button>
      </div>

      <div className="ag-theme-alpine  w-full h-full text-left">
        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            resizable: true,
          }}
          suppressRowClickSelection={true}
          rowSelection={"multiple"}
          rowData={data}
        >
          <AgGridColumn
            headerName="Name"
            field="name"
            minWidth={180}
            sortable={true}
            headerCheckboxSelection={true}
            headerCheckboxSelectionFilteredOnly={true}
            checkboxSelection={true}
            cellRendererFramework={(params) => (
              <DashboardRoute params={params} />
            )}
          />

          <AgGridColumn
            field="address"
            minWidth={500}
            cellRendererFramework={(params) => <Address params={params} />}
          />

          <AgGridColumn
            headerName="Store Code"
            field="internalCode"
            minWidth={150}
            sortable={true}
          />

          <AgGridColumn
            field="status"
            minWidth={100}
            cellRendererFramework={(params) => <Confirm params={params} />}
          />
          <AgGridColumn
            field="action"
            minWidth={100}
            cellRendererFramework={(params) => (
              <Action
                params={params}
                url={url}
                viewUrl={viewUrl}
                editUrl={editUrl}
                orgList={orgList}
              />
            )}
          />
        </AgGridReact>
      </div>
      <div className="absolute bottom-8 right-12  flex justify-center item-center">
        <Pagination
          count={allCount % 9 === 0 ? parseInt(allCount/9) : parseInt(allCount/9) + 1}
          showFirstButton
          showLastButton
          page={activePage}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
}

export default All;
