import React, { useState } from "react";
import ModalImg from "./ModalImg";
import NoImage from "../../../../../../Images/noImage.jpg";

function ImageModal({ obj, index, shopper }) {
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  return (
    <div className="flex flex-col justify-center items-center" key={index}>
      {obj && obj?.userImagePaths[0] ? (
        <img
          src={obj?.userImagePaths[0]}
          alt="event"
          onClick={obj?.id === shopper?.id && onOpenModal}
          className="w-40 h-[140px] rounded-xl cursor-pointer"
        />
      ) : (
        <img
          src={NoImage}
          alt="No Image"
          className="w-40 h-[140px] rounded-xl cursor-pointer border"
        />
      )}
      <p className="mt-1">{`Shopper ${index + 1}`}</p>
      <ModalImg
        open={open}
        onCloseModal={onCloseModal}
        img={obj?.userImagePaths[0]}
      />
    </div>
  );
}

export default ImageModal;
