import React from "react";


function DashboardRoute({ params }) {
  return (
    <>
      <button
        className="font-normal cursor-default focus:outline-none"
      >
        {params.value}
      </button>
    </>
  );
}


export default DashboardRoute;
