import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { getTime } from "../../../../../../../logic/useGetTime";
import { Tab } from "@headlessui/react";
import moment from "moment";
import { useGet } from "../../../../../../../api/useGet";
import axios from "axios";
import { toast } from "react-toastify";
import SelectTabEvents from "../dashboard/SelectTabEvents";
import { BiCheck } from "react-icons/bi";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function ProcessedDecisionView() {
  const history = useHistory();
  const {
    state: { data: sensorActivity },
  } = useLocation();

  let recommendedProduct = null;
  let recommendedShopper = null;
  let recommendedDecision = null;
  let finalProduct = null;
  let finalShopper = null;
  let finalDecision = null;

  const cartItemTaskUrl = `${process.env.REACT_APP_STORE_URL}/cart-item-tasks/store-cart/${sensorActivity?.storeCartId}`;

  const videoActivityListUrl = `${process.env.REACT_APP_STORE_URL}/cart-item-video-activities?storeCartId.equals=${sensorActivity?.storeCartId}`;

  const preImageListUrl = `${process.env.REACT_APP_STORE_URL}/cart-item-pre-image-activities?storeCartId.equals=${sensorActivity?.storeCartId}`;

  const postImageListUrl = `${process.env.REACT_APP_STORE_URL}/cart-item-post-image-activities?storeCartId.equals=${sensorActivity?.storeCartId}`;

  let [categories] = [
    { "Video Events": [], "Pre Image Events": [], "Post Image Events": [] },
  ];

  const { data: videoActivityList } = useGet(videoActivityListUrl, "videoList");
  const { data: preImageActivityList } = useGet(
    preImageListUrl,
    "preImageList"
  );
  const { data: postImageActivityList } = useGet(
    postImageListUrl,
    "postImageList"
  );
  let { data: cartItemTask } = useGet(cartItemTaskUrl, "cartItemTaskDetails");

  [categories] = [
    {
      "Video Events": videoActivityList?.length > 0 ? videoActivityList : [],
      "Pre Image Events":
        preImageActivityList?.length > 0 ? preImageActivityList : [],
      "Post Image Events":
        postImageActivityList?.length > 0 ? postImageActivityList : [],
    },
  ];

  if (cartItemTask !== null && cartItemTask !== undefined) {
    recommendedDecision = cartItemTask?.recommendedDecision;
    recommendedProduct = recommendedDecision?.product;
    recommendedShopper = recommendedDecision?.shopper;
    finalDecision = cartItemTask?.finalDecision;
    finalProduct = finalDecision?.product;
    finalShopper = finalDecision?.shopper;
  }

  return (
    <div className="h-[calc(100vh-60px)] w-full bg-gray-300">
      <div className="h-8 flex items-center ">
        <div className="absolute mx-4 flex items-center">
          <BiArrowBack
            onClick={() => history.goBack()}
            className="h-6 w-6 cursor-pointer"
          />
        </div>
        <div className="w-full flex justify-center items-center">
          <h2 className="font-bold text-2xl">Product Decision Result</h2>
        </div>
      </div>
      <div className="w-full h-full flex rounded-t-xl pt-8 bg-white space-x-[10px]">
        <div className="w-full h-full flex flex-col">
          <div className="cell_border_black">
            <div className="flex w-full font-bold ml-5 mt-5">Sensor Event</div>
            <table className="justify-left ml-35rem">
              <tr>
                <td className="font-bold">Event Timestamp</td>
                <td>&nbsp;&nbsp;:&nbsp;&nbsp;</td>
                <td>
                  &nbsp;
                  {getTime(
                    sensorActivity.eventTime,
                    sensorActivity?.storeTimeZone
                  )}
                </td>
              </tr>
              <tr>
                <td className="font-bold">Scale Code</td>
                <td>&nbsp;&nbsp;:&nbsp;&nbsp;</td>
                <td>&nbsp;{sensorActivity?.scaleInternalCode}</td>
              </tr>
            </table>
            <br />
            <br />
            <table className="justify-left ml-25rem">
              <tr>
                <td className="font-bold">Recommended Product</td>
                <td>&nbsp;&nbsp;:&nbsp;&nbsp;</td>
                <td>
                  {recommendedProduct && recommendedProduct?.length === 0 ? (
                    "NA"
                  ) : (
                    <p>
                      {recommendedProduct?.map((product, i) => (
                        <span key={i}>{`${product?.name} ${
                          recommendedProduct?.length - 1 === i ? "" : ", "
                        } `}</span>
                      ))}
                    </p>
                  )}
                </td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                <td className="font-bold">Reviewed Product</td>
                <td>&nbsp;&nbsp;:&nbsp;&nbsp;</td>
                <td>
                  {finalProduct && finalProduct?.length === 0 ? (
                    "NA"
                  ) : (
                    <p>
                      {finalProduct?.map((product, i) => (
                        <span key={i}>{`${product?.name} ${
                          finalProduct?.length - 1 === i ? "" : ", "
                        } `}</span>
                      ))}
                    </p>
                  )}
                </td>
              </tr>
              <tr>
                <td className="font-bold">Recommended Quantity</td>
                <td>&nbsp;&nbsp;:&nbsp;&nbsp;</td>
                <td>
                  &nbsp;
                  {recommendedDecision?.quantity
                    ? recommendedDecision?.quantity
                    : "NA"}
                </td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                <td className="font-bold">Reviewed Quantity</td>
                <td>&nbsp;&nbsp;:&nbsp;&nbsp;</td>
                <td>
                  &nbsp;
                  {finalDecision?.quantity ? finalDecision?.quantity : "NA"}
                </td>
              </tr>
              <tr>
                <td className="font-bold">Recommended Shopper</td>
                <td>&nbsp;&nbsp;:&nbsp;&nbsp;</td>
                <td>
                  &nbsp;
                  {recommendedShopper?.cartRef
                    ? recommendedShopper?.cartRef
                    : "NA"}
                </td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                <td className="font-bold">Reviewed Shopper</td>
                <td>&nbsp;&nbsp;:&nbsp;&nbsp;</td>
                <td>
                  &nbsp;{finalShopper?.cartRef ? finalShopper?.cartRef : "NA"}
                </td>
              </tr>
            </table>
            <br />
            <table className="justify-left ml-40rem font-bold">
              <tr>
                <td>Product</td>
                <td>&nbsp;&nbsp;:&nbsp;&nbsp;</td>
                <td>
                  {cartItemTask?.productState === "APPROVED" ? (
                    <FaCheckCircle className="text-green" size={20} />
                  ) : (
                    <FaTimesCircle className="text-red" size={20} />
                  )}
                </td>
              </tr>
              <tr>
                <td>Quantity</td>
                <td>&nbsp;&nbsp;:&nbsp;&nbsp;</td>
                <td>
                  {cartItemTask?.quantityState === "APPROVED" ? (
                    <FaCheckCircle className="text-green" size={20} />
                  ) : (
                    <FaTimesCircle className="text-red" size={20} />
                  )}
                </td>
              </tr>
              <tr>
                <td>Shopper</td>
                <td>&nbsp;&nbsp;:&nbsp;&nbsp;</td>
                <td>
                  {cartItemTask?.shopperState === "APPROVED" ? (
                    <FaCheckCircle className="text-green" size={20} />
                  ) : (
                    <FaTimesCircle className="text-red" size={20} />
                  )}
                </td>
              </tr>
            </table>
            <br />
          </div>
          <div className="h-[56vh] w-[4/5%] ml-5 pt-2 pr-6 space-y-2 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 ">
            <Tab.Group>
              <Tab.List className="flex space-x-1 rounded-xl bg-gray-200 p-1">
                {Object.keys(categories).map((category, idx) => (
                  <Tab
                    key={idx}
                    className={({ selected }) =>
                      classNames(
                        "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-black-700",
                        "ring-white ring-opacity-60 ring-offset-1 ring-offset-black focus:outline-none focus:ring-1",
                        selected
                          ? "bg-white shadow"
                          : "text-black-100 hover:bg-white/[0.12] hover:text-black"
                      )
                    }
                  >
                    {category}
                  </Tab>
                ))}
              </Tab.List>
              <Tab.Panels className="mt-2">
                {Object.values(categories).map((activityEvents, idx) => (
                  <Tab.Panel
                    key={idx}
                    className={classNames(
                      "rounded-xl bg_white p-3",
                      "ring-black ring-opacity-60 ring-offset-1 ring-offset-black focus:outline-none focus:ring-1"
                    )}
                  >
                    {activityEvents.map((inputEvent, index) => {
                      let inputPaths = "";
                      let assignedData = {};
                      if (inputEvent?.eventType === "Video Event") {
                        inputPaths = "videoPaths";
                        assignedData =
                          cartItemTask?.baseActivities?.[
                            "CartItemVideoActivity"
                          ];
                      } else if (inputEvent?.eventType === "Pre Image Event") {
                        inputPaths = "imagePaths";
                        assignedData =
                          cartItemTask?.baseActivities?.[
                            "CartItemPreImageActivity"
                          ];
                      } else if (inputEvent?.eventType === "Post Image Event") {
                        inputPaths = "imagePaths";
                        assignedData =
                          cartItemTask?.baseActivities?.[
                            "CartItemPostImageActivity"
                          ];
                      }
                      return (
                        <div
                          key={index}
                          className="border-b-4 border-gray-400 space-y-2 h-52 flex flex-col justify-between"
                        >
                          <SelectTabEvents
                            data={inputEvent}
                            name={inputPaths}
                            idName={"id"}
                            assignedData={assignedData}
                          />
                          <div className="h-10 flex justify-center items-end">
                            {inputEvent?.eventTime && (
                              <p>
                                <span className="font-bold">Event Time : </span>
                                {getTime(
                                  inputEvent?.eventTime,
                                  inputEvent?.storeTimeZone
                                )}
                              </p>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProcessedDecisionView;
