import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DatePicker } from "antd";
import Action from "./Action";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "react-toggle/style.css";
import Select from "react-select";
import DashboardRoute from "./DashboardRoute";
import axios from "axios";
import { BiSearchAlt, BiRefresh } from "react-icons/bi";
import { RiRefund2Line } from "react-icons/ri";
import { AiOutlineInteraction } from "react-icons/ai";
import { MdOutlinePending } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import FadeLoader from "react-spinners/FadeLoader";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  Container,
  IconButton,
  Pagination,
  PaginationItem,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useQuery } from "react-query";
import { usePostGet } from "../../../../../../api/usePostGet";
import { usePutGet } from "../../../../../../api/usePutGet";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { getTime } from "../../../../../../logic/useGetTime";
import { fields } from "../../../../../../statusField";
import Tippy from "@tippyjs/react";
import CustomSearch from "../../../../../../components/searchbar";
import CustomDropdown from "../../../../../../components/dropdown";
import {
  removeEmptyStringKeys,
  emptyAllValues,
  areAllValuesEmpty,
  convertToTitleCase,
} from "../../../../../../utils/utils";
import {
  CARD_TYPES,
  MerchantsDetails,
  PAYMENT_STATUS,
} from "../../../../../../utils/action_types";
import Refunded from "./Refunded";
import Iconify from "../../../../../../components/iconify/Iconify";
import "../../../../../../styles/agGridScrollBarStyle.css";
import { useOrdersTabProvider } from "../contexts/OrdersTabContex";
import { useGet, useGetWithFilters } from "../../../../../../api/useGet";
import ViewExternalLinkModal from "../modal/ViewExternalLinkModal";
import CloseIcon from "@mui/icons-material/Close";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  console.log("🚀 ~ file: All.js:45 ~ CustomTabPanel ~ children:", children);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const columns = [
  {
    id: "login",
    label: "Login",
  },
  { id: "firstName", label: "First Name" },
  { id: "lastName", label: "Last Name" },
  {
    id: "cardNetwork",
    label: "Card Brand",
  },
  {
    id: "last4Digit",
    label: "Card Number",
  },
];

function All({ listUrl, sortedData, refundUrl, merchant, orgName }) {
  const { RangePicker } = DatePicker;
  console.log([orgName]);

  const {
    allTabInput: input,
    setAllTabInput: setInput,
    allTabContainsSearch: containsSearch,
    setAllTabContainsSearch: setContainsSearch,
    activeTabValue: value,
    setActiveTabValue: setValue,
    allTabActivePage: activePage,
    setAllTabActivePage: setActivePage,
    allTabItemsPerPage: itemsPerPage,
    setAllTabItemsPerPage: setItemsPerPage,
    allTabSelectedDateRange: selectedDateRange,
    setAllTabSelectedDateRange: setSelectedDateRange,
    itemsPerPageAllTabOptions: itemsPerPageOptions,
    activeMerchant,
    setActiveMerchant,
    cardFilters,
    setCardFilters,
    setActiveCardDetails,
    activeCardDetails,
    setActivePaymentStatus,
  } = useOrdersTabProvider();
  const [openCardDetailsModal, setOpenCardDetailsModal] = useState(false);
  console.log("🚀 ~ file: All.js:110 ~ All ~ activePage:", activePage);

  const { mutate: pending } = usePostGet("orderList");

  const { mutate: verify } = usePutGet("orderList");

  const history = useHistory();

  const [status, setStatus] = useState(uuidv4());

  const [modalOpen, setModalOpen] = useState(false);

  const isShowClearIcon =
    !areAllValuesEmpty(input) || !areAllValuesEmpty(cardFilters);

  const { data: orderData, isLoading } = useQuery(
    ["orderList", activePage, itemsPerPage, input],
    async () =>
      axios.get(`${listUrl}`, {
        params: {
          ...removeEmptyStringKeys(input),
          page: activePage - 1,
          size: itemsPerPage,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      }),
    {
      enabled: true,
      cacheTime: Infinity,
      staleTime: 1000,
    }
  );
  console.log("🚀 ~ file: All.js:124 ~ All ~ orderData:", orderData);

  const { data: orgs } = useGet(
    `${process.env.REACT_APP_BASE_URL}/orgs?sort=createdDate,desc`,
    "orgs"
  );

  const { data: ordersByCard } = useGetWithFilters(
    `${process.env.REACT_APP_SHOPPER_URL}/user-payment-infos/card-info`,
    ["ordersByCard", cardFilters],
    !areAllValuesEmpty(cardFilters),
    cardFilters
  );

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  const handleCardApiInputs = (e) => {
    if (e.target.value === "" || e.value === "") {
      const name = e.target.name || e.name;
      setOpenCardDetailsModal(true);
      setCardFilters((prevData) => ({
        ...prevData,
        [name]: e.target.value,
      }));
      if (
        cardFilters?.["cardBrand.contains"] === "" ||
        cardFilters?.["cardBrand.contains"] === null
      )
        setOpenCardDetailsModal(false);
    } else {
      const input = e.target.value || e.value;
      const name = e.target.name || e.name;

      const filteredInput = input.replace(/[^0-9]/g, "").substring(0, 4);

      // Check if the input is empty or consists of up to 4 digits
      setOpenCardDetailsModal(true);
      setCardFilters((prevData) => ({
        ...prevData,
        [name]: filteredInput,
      }));
    }
  };

  const handleInput = (e) => {
    // if (e.target.value?.length >= 3) {
    handlePageChange(null, 1);
    setInput(() => ({
      ...input,
      [e.target.name || e.name]: e.target.value || e.value,
    }));
    // }
    setContainsSearch(() => ({
      ...containsSearch,
      [e.target.name || e.name]: e.target.value || e.value,
    }));
  };

  const clearAllFilters = () => {
    setInput({ ...emptyAllValues(input) });
    setCardFilters({ ...emptyAllValues(cardFilters) });
    setContainsSearch({ ...emptyAllValues(containsSearch) });
    setSelectedDateRange([null, null]);
    setActiveMerchant("");
    setActivePaymentStatus("");
    setOpenCardDetailsModal(false);
  };

  const refund = (data) => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_SHOPPER_URL}/order-refunds/${data?.id}/request`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
    })
      .then((res) => {
        history.push({
          pathname: "/support/order/refund",
          state: { orderId: data.id, data: res.data },
        });
      })
      .catch(({ message }) => {
        if (message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          toast.error(message);
        }
      });
  };

  const confirmPending = (data) => {
    toast("Your request is successfully submitted.");
    verify({
      data: {},
      url: `${process.env.REACT_APP_SHOPPER_URL}/orders/${data?.id}/verify-order-payment`,
    });

    setTimeout(() => {
      setStatus(uuidv4());
      toast("Your request is successfully submitted.");
    }, 3000);
  };

  const paymentAction = (data) => {
    toast("Your request is successfully submitted.");
    verify({
      data: {},
      url: `${process.env.REACT_APP_SHOPPER_URL}/orders/${data?.id}/verify-order-payment`,
    });

    setTimeout(() => {
      setStatus(uuidv4());
      toast("Your request is successfully submitted.");
    }, 3000);
  };

  const paymentPending = (data) => {
    pending({
      data: {},
      url: `${process.env.REACT_APP_SHOPPER_URL}/orders/${data?.id}/submit-order-payment`,
    });

    setTimeout(() => {
      setStatus(uuidv4());
      toast("Your request is successfully submitted.");
    }, 3000);
  };

  const handleDateChange = (dates) => {
    if (dates?.length === 2) {
      handlePageChange(null, 1);
      setInput({
        ...input,
        // "(range)": `createdDateFrom.greaterThanOrEqual={${dates[0]}}createdDateTo.lessThanOrEqual={${dates[1]}}`,
        // "createdDateFrom.greaterThanOrEqual": `createdDateFrom.greaterThanOrEqual=${dates[0]?.toISOString()}createdDateTo.lessThanOrEqual=${dates[1]?.toISOString()}`,
        "createdDateFrom.greaterThanOrEqual": dates[0]?.toISOString(),
        "createdDateTo.lessThanOrEqual": dates[1]?.toISOString(),
      });
      setSelectedDateRange(dates);
    }
  };

  const handlePageSizeChange = (newPageSize) => {
    setItemsPerPage(newPageSize);
    // setAc(1);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const setFilterFromCardTable = (row) => {
    setOpenCardDetailsModal(false);
    handlePageChange(null, 1);

    setInput(() => ({
      ...input,
      "firstName.contains": row?.firstName,
      "lastName.contains": row?.lastName,
      "login.contains": row?.login,
    }));

    setContainsSearch(() => ({
      ...containsSearch,
      "firstName.contains": row?.firstName,
      "lastName.contains": row?.lastName,
      "login.contains": row?.login,
    }));
  };

  return (
    <div className=" w-full h-[calc(100vh-152px)]">
      {/* <Container maxWidth="2xl">
        <Card> */}
      <div
        className={
          isLoading
            ? "bg-black bg-opacity-0 absolute inset-0 z-10 flex items-center justify-center"
            : null
        }
      >
        <FadeLoader className="absolute " loading={isLoading} size={150} />
      </div>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        <Tab label="All" {...a11yProps(0)} />
        <Tab label="Refunded" {...a11yProps(1)} />
      </Tabs>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          {/* <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="All" {...a11yProps(0)} />
            <Tab label="Refunded" {...a11yProps(1)} />
          </Tabs> */}
        </Box>
        <CustomTabPanel value={value} index={0}>
          <div>
            <div className="flex flex-col sm:flex-row w-full py-4">
              <div className="w-full ">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 relative">
                  <CustomSearch
                    label="Search by First Name"
                    name="firstName.contains"
                    value={containsSearch["firstName.contains"]}
                    onChange={(e) => handleInput(e)}
                    // onKeyDown={(e) => handleKeyDown(e)}
                  />
                  <CustomSearch
                    label="Search by Last Name"
                    name="lastName.contains"
                    value={containsSearch["lastName.contains"]}
                    onChange={(e) => handleInput(e)}
                  />
                  <CustomSearch
                    label="Search by Login"
                    name="login.contains"
                    value={containsSearch["login.contains"]}
                    onChange={(e) => handleInput(e)}
                  />
                  <CustomSearch
                    label="Search by Cart ref"
                    name="sessionRef.contains"
                    value={containsSearch["sessionRef.contains"]}
                    onChange={(e) => handleInput(e)}
                  />
                  <CustomSearch
                    label="Search by last 4 digits of card"
                    name="last4Digit.equals"
                    value={containsSearch?.["last4Digit.equals"]}
                    onChange={(e) => handleInput(e)}
                  />
                  <CustomSearch
                    label="Search by Order #"
                    name="orderNumber.equals"
                    value={input["orderNumber.equals"]}
                    onChange={(e) => {
                      handlePageChange(null, 1);
                      setInput({
                        ...input,
                        "orderNumber.equals": e.target.value,
                      });
                    }}
                  />
                  <RangePicker
                    onChange={handleDateChange}
                    value={selectedDateRange}
                    style={{ height: "40px" }}
                    clearIcon={null}
                  />
                  <CustomDropdown
                    options={PAYMENT_STATUS}
                    name="status.equals"
                    label={"Filter by Payment Status "}
                    value={input["status.equals"]}
                    onChange={(e, v) => {
                      handlePageChange(null, 1);
                      setActivePaymentStatus(v?.label);
                      setInput({ ...input, "status.equals": v?.value });
                    }}
                  />
                  <CustomDropdown
                    options={
                      merchant
                        ? [orgName]
                        : orgs?.map((item) => ({
                            value: item?.id,
                            label: item?.name,
                          })) || []
                    }
                    name="rootOrgId.equals"
                    label={"Filter by Merchats"}
                    value={merchant ? orgName : activeMerchant}
                    onChange={(e, v) => {
                      handlePageChange(null, 1);
                      setInput({ ...input, "rootOrgId.equals": v?.value });
                      setActiveMerchant(v?.label);
                    }}
                  />
                  <CustomDropdown
                    options={CARD_TYPES}
                    name="cardBrand.contains"
                    label={"Search by Card brand"}
                    value={input?.["cardBrand.contains"]}
                    onChange={(e, v) => {
                      handlePageChange(null, 1);
                      setInput({ ...input, "cardBrand.contains": v });
                    }}
                  />
                  {/* {isShowClearIcon && (
                    <Button
                      color="error"
                      size="small"
                      onClick={() => clearAllFilters()}
                      startIcon={<Iconify icon="eva:trash-2-outline" />}
                    >
                      Clear
                    </Button>
                  )} */}
                </div>
                {/* {openCardDetailsModal && (
                  <div className="absolute w-[70%] bg-slate-200 z-10 ">
                    <div className="w-[100%] relative">
                      <IconButton
                        aria-label="close"
                        onClick={() => setOpenCardDetailsModal(false)}
                        sx={{
                          position: "absolute",
                          right: 8,
                          top: 8,
                          color: (theme) => theme.palette.grey[500],
                        }}
                        className="absolute"
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                    <div className="p-5 mt-7">
                      <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {columns.map((column) => (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{ minWidth: column.minWidth }}
                                >
                                  {column.label}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {ordersByCard?.map((row) => (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.code}
                                sx={{ cursor: "pointer" }}
                                onClick={() => setFilterFromCardTable(row)}
                              >
                                {columns.map((column) => {
                                  const value = row?.[column.id];
                                  return (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                    >
                                      {value}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                )} */}
              </div>
              <div
                className="w-full sm:w-1/4 max-w-screen-xl px-4 mt-4 sm:mt-0 flex flex-col sm:flex-row justify-between"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  justifyContent: "space-between",
                }}
              >
                {isShowClearIcon && (
                  <Button
                    color="error"
                    size="small"
                    onClick={() => clearAllFilters()}
                    startIcon={<Iconify icon="eva:trash-2-outline" />}
                  >
                    Clear
                  </Button>
                )}
              </div>
            </div>
            <div className="ag-theme-alpine w-full h-full text-left">
              <AgGridReact
                defaultColDef={{
                  flex: 1,
                  minWidth: 80,
                  resizable: true,
                }}
                suppressRowClickSelection={true}
                pagination={false}
                paginationPageSize={8}
                rowData={orderData?.data || []}
                domLayout="autoHeight"
                rowHeight={50}
              >
                <AgGridColumn
                  headerName=" Order #"
                  field="orderNumber"
                  minWidth={150}
                  sortable={true}
                  headerCheckboxSelection={true}
                  headerCheckboxSelectionFilteredOnly={true}
                  checkboxSelection={true}
                  cellRendererFramework={(params) => (
                    <DashboardRoute params={params} key={params?.data?.id} />
                  )}
                />

                <AgGridColumn headerName="Login" field="login" />
                <AgGridColumn headerName="Cart Ref" field="sessionRef" />
                <AgGridColumn headerName="First Name" field="firstName" />
                <AgGridColumn headerName="Last Name" field="lastName" />
                <AgGridColumn
                  headerName="Last 4 digit of card"
                  field="last4Digit"
                />
                <AgGridColumn headerName="PAN" field="pan" />
                <AgGridColumn
                  headerName="Card brand"
                  field="cardBrand"
                  cellRendererFramework={(params) => {
                    return (
                      <div key={params?.data?.id}>
                        <p>
                          {params?.data?.cardBrand
                            ? params?.data?.cardBrand?.toUpperCase()
                            : ""}
                        </p>
                      </div>
                    );
                  }}
                />
                {/* <AgGridColumn headerName="Offer type" field="offerType" /> */}

                <AgGridColumn
                  field="status"
                  headerName="Status"
                  minWidth={160}
                  cellRendererFramework={(params) => {
                    return (
                      <div key={params?.data?.id}>
                        {params?.data?.status === "IN_PROCESS" ? (
                          <span className={fields[params?.data?.status]?.color}>
                            {fields[params?.data?.status]?.title}
                          </span>
                        ) : params?.data?.status === "PAYMENT_PENDING" ? (
                          <span className={fields[params?.data?.status]?.color}>
                            {fields[params?.data?.status]?.title}
                          </span>
                        ) : params?.data?.status ===
                          "PAYMENT_CONFIRMATION_PENDING" ? (
                          <span className={fields[params?.data?.status]?.color}>
                            {fields[params?.data?.status]?.title}
                          </span>
                        ) : params?.data?.status === "PAYMENT_PROCESSED" ? (
                          <span className={fields[params?.data?.status]?.color}>
                            {fields[params?.data?.status]?.title}
                          </span>
                        ) : params?.data?.status === "PAYMENT_REFUNDED" ? (
                          <span className={fields[params?.data?.status]?.color}>
                            {fields[params?.data?.status]?.title}
                          </span>
                        ) : params?.data?.status ===
                          "PAYMENT_PARTIALLY_REFUNDED" ? (
                          <span className={fields[params?.data?.status]?.color}>
                            {fields[params?.data?.status]?.title}
                          </span>
                        ) : params?.data?.status === "PAYMENT_WAIVED" ? (
                          <span className={fields[params?.data?.status]?.color}>
                            {fields[params?.data?.status]?.title}
                          </span>
                        ) : params?.data?.status === "PAYMENT_REJECTED" ? (
                          <span className={fields[params?.data?.status]?.color}>
                            {fields[params?.data?.status]?.title}
                          </span>
                        ) : params?.data?.status ===
                          "PAYMENT_REQUIRES_ACTION" ? (
                          <span className={fields[params?.data?.status]?.color}>
                            {fields[params?.data?.status]?.title}
                          </span>
                        ) : params?.data?.status === "PAYMENT_FAILED" ? (
                          <span className={fields[params?.data?.status]?.color}>
                            {fields[params?.data?.status]?.title}
                          </span>
                        ) : (
                          <span>
                            {convertToTitleCase(params?.data?.status)}
                          </span>
                        )}
                      </div>
                    );
                  }}
                />

                <AgGridColumn
                  headerName="Merchant"
                  field="storeInternalCode"
                  cellRendererFramework={(params) => {
                    return (
                      <div className="h-[50px] w-[50px]">
                        <img
                          src={
                            MerchantsDetails.hasOwnProperty(params?.value)
                              ? MerchantsDetails?.[params?.value]?.imgUrl
                              : "/images/noImage.jpg"
                          }
                          width="50px"
                          height="50px"
                          alt="/images/noImage.jpg"
                          // className="w-[100px] h-[100px]"
                          // className="mt-3"
                        />
                      </div>
                    );
                  }}
                />

                <AgGridColumn
                  field="orderTotal"
                  headerName={`Total`}
                  minWidth={90}
                  maxWidth={90}
                  cellStyle={{ paddingRight: 10 }}
                  cellRendererFramework={(params) => {
                    return (
                      <div key={params?.data?.id}>
                        <p className="text-left w-[50px]">
                          {params?.data?.formattedEffectiveOrderTotalCurrency}
                        </p>
                      </div>
                    );
                  }}
                />

                <AgGridColumn
                  headerName="Order Date"
                  minWidth={160}
                  maxWidth={160}
                  // sortable={true}
                  cellRendererFramework={(params) => {
                    return (
                      <div key={params?.data?.id}>
                        <p>
                          {getTime(
                            params?.data?.orderTime,
                            params?.data?.storeTimeZone
                          )}
                        </p>
                      </div>
                    );
                  }}
                />

                {/* <AgGridColumn
              headerName="Email"
              minWidth50}
              // sortable={true}
              cellRendererFramework={(params) => {
                return (
                  <>
                    <p>{params?.data?.userDTO?.email ? params.data?.userDTO?.email : "Nill"}</p>
                  </>
                );
              }}
            /> */}

                {/* <AgGridColumn
              headerName="Products"
              minWidth={100}
              // sortable={true}
              cellRendererFramework={(params) => {
                return (
                  <div className="flex">
                    {params?.data?.orderItems?.map((item, index) => {
                      return (
                        <p
                          className="ml-0.5"
                          key={index}
                        >{`${item?.productName},`}</p>
                      );
                    })}
                  </div>
                );
              }}
            /> */}

                <AgGridColumn
                  headerName="External receipt"
                  minWidth={100}
                  // sortable={true}
                  cellRendererFramework={(params) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <a
                        href={params?.data?.externalReceiptUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          color: params?.data?.externalReceiptUrl
                            ? "#3498db"
                            : "",
                          textDecoration: params?.data?.externalReceiptUrl
                            ? "underline"
                            : "none",
                        }}
                      >
                        {params?.data?.externalReceiptUrl?.split("/")?.[
                          params?.data?.externalReceiptUrl?.split("/")?.length -
                            1
                        ] || "-"}
                      </a>
                    </div>
                  )}
                />

                <AgGridColumn
                  headerName="Receipt"
                  minWidth={90}
                  maxWidth={90}
                  cellRendererFramework={(params) => (
                    <Action params={params} allSection />
                  )}
                />

                {/* <AgGridColumn
                  headerName="Action"
                  minWidth={90}
                  maxWidth={90}
                  // sortable={true}
                  cellRendererFramework={(params) => {
                    //PAYMENT_PROCESSED

                    return (
                      <div key={params?.data?.id}>
                        {params?.data?.status === "PAYMENT_PROCESSED" ? (
                          <div className="flex w-16 h-10 items-center justify-start">
                            <Tippy content="Request for refund">
                              <div className="border-2 border-gray-200 rounded-lg p-1 ">
                                <RiRefund2Line
                                  className="w-5 h-5 cursor-pointer "
                                  onClick={() => refund(params?.data)}
                                />
                              </div>
                            </Tippy>
                          </div>
                        ) : params?.data?.status ===
                          "PAYMENT_CONFIRMATION_PENDING" ? (
                          <div className="flex w-16 h-10 items-center justify-start">
                            <Tippy content="Verify for order payment">
                              <div className="border-2 border-gray-200 rounded-lg p-1 ">
                                <TiTick
                                  className="w-5 h-5 cursor-pointer "
                                  onClick={() => confirmPending(params?.data)}
                                />
                              </div>
                            </Tippy>
                          </div>
                        ) : params?.data?.status ===
                          "PAYMENT_REQUIRES_ACTION" ? (
                          <div className="flex w-16 h-10 items-center justify-start">
                            <Tippy content="Verify for order payment">
                              <div className="border-2 border-gray-200 rounded-lg p-1 ">
                                <TiTick
                                  className="w-5 h-5 cursor-pointer "
                                  onClick={() => paymentAction(params?.data)}
                                />
                              </div>
                            </Tippy>
                          </div>
                        ) : params?.data?.status === "PAYMENT_FAILED" ? (
                          <div className="flex w-16 h-10 items-center justify-start">
                            <Tippy content="Request again">
                              <div className="border-2 border-gray-200 rounded-lg p-1 ">
                                <BiRefresh className="w-5 h-5 cursor-pointer " />
                              </div>
                            </Tippy>
                          </div>
                        ) : params?.data?.status === "PAYMENT_PENDING" ? (
                          <Tippy
                            content="Submit for order payment"
                            className="z-100"
                          >
                            <div className="flex w-16 h-10 items-center justify-start">
                              <div className="border-2 border-gray-200 rounded-lg p-1 ">
                                <MdOutlinePending
                                  className="w-5 h-5 cursor-pointer "
                                  onClick={() => paymentPending(params?.data)}
                                />
                              </div>
                            </div>
                          </Tippy>
                        ) : (
                          <Tippy content="Request completed">
                            <div className="flex w-16 h-10 items-center justify-start">
                              <div className="border-2 border-gray-200 rounded-lg p-1 ">
                                <AiOutlineInteraction className="w-5 h-5 cursor-pointer" />
                              </div>
                            </div>
                          </Tippy>
                        )}
                      </div>
                    );
                  }}
                /> */}
              </AgGridReact>
              {modalOpen && (
                <ViewExternalLinkModal
                  open={modalOpen}
                  onClose={handleModalClose}
                  url="https://tegut-receipts.snabble-staging.io/44x0zmd8"
                />
              )}
            </div>
            {/* {orderData?.headers?.["x-total-count"] > 0 && ( */}
            <div className="relative mt-2 flex justify-center item-center">
              {/* <select
                onChange={(e) => handlePageSizeChange(Number(e.target.value))}
                className="mr-1"
              >
                {itemsPerPageOptions.map((option) => (
                  <option key={option} value={option}>
                    {option} per page
                  </option>
                ))}
              </select> */}
              <Pagination
                count={
                  Math.ceil(orderData?.headers["x-total-count"] / 10) || "0"
                }
                showFirstButton
                showLastButton
                page={activePage}
                onChange={handlePageChange}
                // renderItem={(item) => <PaginationItem component="div" {...item} />}
                // onLoad={}
              />
            </div>
            {/* )} */}
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Refunded listUrl={refundUrl} />
          {/* <OpenEntryDecisonsTable value={value} openEntryData={openEntryData} /> */}
        </CustomTabPanel>
      </Box>
      {/* </Card>
      </Container> */}
      {/* <div class="grid-container flex flex-col h-screen"> */}
      {/* </div> */}
    </div>
  );
}

export default All;
