import React, { useState, useEffect, useMemo } from "react";
import { TextField2 } from "../../../validation/TextField2";
import { useHistory, useLocation } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import Select from "react-select";
import { useAlert } from "react-alert";
import countryList from "react-select-country-list";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { usePut } from "../../../../api/usePut";
import MapModal from "./MapModal";

const countryLists = [
  { label: "Germany", value: "DE" },
  { label: "Hungary", value: "HU" },
  { label: "France", value: "FR" },
];

const getLabel = (value) => {
  return countryLists.find((ele) => ele?.value === value);
};

let validate;
validate = Yup.object({
  name: Yup.string().required("Required"),
  email: Yup.string().email(),
  zip: Yup.number().typeError("Not a number").required("Required"),
  street1: Yup.string().required("Required"),
  street2: Yup.string(),
  city: Yup.string().required("Required"),
  administrativeArea: Yup.string(),
  latitude: Yup.number().typeError("Not a number"),
  longitude: Yup.number().typeError("Not a number"),
  state: Yup.string().required("Required"),
  website: Yup.string(),
  description: Yup.string(),
  contactFirstName: Yup.string(),
  contactMiddleName: Yup.string(),
  contactLastName: Yup.string(),
  contactNo: Yup.number(),
});

const StoreTypes = [{ value: "Deparment Store", label: "Deparment Store" }];

function LocationEdit() {
  const [title] = useState("Retailer Store");
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const [retailerStoreData, setRetailerStoreData] = useState({
    ...location?.state?.data,
  });

  const initialValues = {
    name: retailerStoreData?.name ? retailerStoreData?.name : "",
    email: retailerStoreData?.email ? retailerStoreData?.email : "",
    website: retailerStoreData?.website ? retailerStoreData?.website : "",
    description: retailerStoreData?.description
      ? retailerStoreData?.description
      : "",
    internalCode: retailerStoreData?.internalCode
      ? retailerStoreData?.internalCode
      : "",
    street1: retailerStoreData?.address?.street1
      ? retailerStoreData?.address?.street1
      : "",
    street2: retailerStoreData?.address?.street2
      ? retailerStoreData?.address?.street2
      : "",
    city: retailerStoreData?.address?.cityOrVillage
      ? retailerStoreData?.address?.cityOrVillage
      : "",
    administrativeArea: retailerStoreData?.address?.administrativeArea1
      ? retailerStoreData?.address?.administrativeArea1
      : "",
    latitude: retailerStoreData?.address?.lat
      ? retailerStoreData?.address?.lat
      : "",
    longitude: retailerStoreData?.address?.lng
      ? retailerStoreData?.address?.lng
      : "",
    state: retailerStoreData?.address?.state
      ? retailerStoreData?.address?.state
      : "",
    zip: retailerStoreData?.address?.zipCode
      ? retailerStoreData?.address?.zipCode
      : "",
    contactNo: retailerStoreData?.address?.contactNumber
      ? retailerStoreData?.address?.contactNumber
      : "",
    contactFirstName: retailerStoreData.address.contactFirstName
      ? retailerStoreData.address.contactFirstName
      : "",
    contactMiddleName: retailerStoreData.address.contactMiddleName
      ? retailerStoreData.address.contactMiddleName
      : "",
    contactLastName: retailerStoreData.address.contactLastName
      ? retailerStoreData.address.contactLastName
      : "",
  };

  // console.log("log: ", initialValues)

  const [select, setSelect] = useState({
    country: "",
    storeType: "",
  });

  useEffect(() => {
    setSelect({
      ...select,
      country: {
        label: retailerStoreData?.address?.country
          ? getLabel(retailerStoreData?.address?.country)?.label
          : "",
        value: retailerStoreData?.address?.country,
      },
      storeType: {
        label: retailerStoreData?.storeType ? retailerStoreData?.storeType : "",
        value: retailerStoreData?.storeType ? retailerStoreData?.storeType : "",
      },
    });
  }, []);

  const onSelectHandler = (value, name) => {
    setSelect({ ...select, [name]: value });
  };

  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const editRetailer = usePut(
    `${process.env.REACT_APP_BASE_URL}/retail-stores/${location?.state?.id}`,
    ["retailerView", "retailerList"]
  );

  //console.log("editRetailer", editRetailer)

  return (
    <>
      <div className="w-full h_view select-none text-left flex">
        <div className="w-full h-full flex justify-center items-center bg-gray-200 ">
          <Formik
            initialValues={initialValues}
            validationSchema={validate}
            onSubmit={async (values) => {
              if (values.name !== "") {
                try {
                  editRetailer.mutate({
                    ...retailerStoreData,
                    name: values.name,
                    internalCode: values?.internalCode,
                    description: values.description,
                    website: values.website,
                    email: values.email,
                    rootOrgId: location?.state?.orgId,
                    storeType: select?.storeType?.value,
                    address: {
                      id: retailerStoreData?.address?.id,
                      street1: values.street1,
                      street2: values.street2,
                      neighborhoodName: values.neighborhoodName,
                      administrativeArea1: values.administrativeArea,
                      administrativeArea2: null,
                      cityOrVillage: values.city,
                      state: values.state,
                      zipCode: values.zip,
                      country: select?.country?.value,
                      contactFirstName: values.contactFirstName,
                      contactMiddleName: values.contactMiddleName,
                      contactLastName: values.contactLastName,
                      contactNumber: values.contactNo,
                      lat: parseFloat(values.latitude),
                      lng: parseFloat(values.longitude),
                    },
                  });

                  if (editRetailer.isIdle === true) {
                    history.goBack();
                    alert.success(
                      <div style={{ textTransform: "none" }}>
                        Successfully Added!
                      </div>
                    );
                  } else {
                    alert.error(
                      <div style={{ textTransform: "none" }}>
                        Please Check all Fields!
                      </div>
                    );
                  }
                } catch (error) {
                  alert.error(
                    <div style={{ textTransform: "none" }}>
                      you are offline!
                    </div>
                  );
                }
              }
            }}
          >
            {() => (
              <Form className="w-full  m-8 bg-white rounded-lg shadow-lg ">
                <div className="w-full h-10 rounded-t-lg flex bg-nav text-white">
                  <div className="w-10 p-2 h-full flex items-center">
                    <BiArrowBack
                      onClick={() => history.goBack()}
                      className="h-10 w-10 cursor-pointer"
                    />
                  </div>
                  <div className="w-full h-full px-7 flex flex-col justify-center">
                    <h2 className="font-bold text-2xl">Edit </h2>
                  </div>
                </div>

                <div className="w-11/12 mx-auto px-6 my-1 ">
                  <div className="w-full h-20 flex mt-1  items-center">
                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2 label="Name" name="name" type="text" />
                    </div>

                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2 label="Email" name="email" type="email" />
                    </div>

                    <div className="flex  px-4 ml-4 w-full items-center select-none">
                      <div style={{ width: "350px" }}>
                        <Select
                          placeholder="Store Type"
                          maxMenuHeight={162}
                          value={select?.storeType}
                          onChange={(value) =>
                            onSelectHandler(value, "storeType")
                          }
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          options={StoreTypes}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="w-full h-20 flex mt-1  items-center">
                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2
                        label="Description"
                        name="description"
                        type="text"
                      />
                    </div>

                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2 label="website" name="website" type="text" />
                    </div>

                    <div className="flex  px-4 ml-4 w-full items-center select-none">
                      <div style={{ width: "350px" }}>
                        <Select
                          placeholder="Select Country"
                          maxMenuHeight={162}
                          value={select?.country}
                          onChange={(value) =>
                            onSelectHandler(value, "country")
                          }
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          options={countryLists}
                        />
                      </div>
                    </div>
                  </div>

                  {/*  */}

                  <div className="w-full h-20 flex mt-1  items-center">
                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2 label="Street 1" name="street1" type="text" />
                    </div>

                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2 label="Street 2" name="street2" type="text" />
                    </div>

                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2
                        label="City Or Village"
                        name="city"
                        type="text"
                      />
                    </div>

                    <div className="flex  px-4   w-full items-center select-none">
                      <TextField2
                        label="Administrative Area"
                        name="administrativeArea"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="w-full h-20 flex items-center">
                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2
                        label="Contact First Name"
                        name="contactFirstName"
                        type="text"
                      />
                    </div>

                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2
                        label="Contact Middle Name"
                        name="contactMiddleName"
                        type="text"
                      />
                    </div>

                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2
                        label="Contact Last Name"
                        name="contactLastName"
                        type="text"
                      />
                    </div>

                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2
                        label="contact Number"
                        name="contactNo"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="w-full h-20 flex  items-center">
                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2 label="State" name="state" type="text" />
                    </div>

                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2 label="Zip Code" name="zip" type="text" />
                    </div>
                  </div>

                  <div className="w-full h-20 flex  items-center">
                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2
                        label="Latitude"
                        name="latitude"
                        type="text"
                      />
                    </div>

                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2
                        label="Longitude"
                        name="longitude"
                        type="text"
                      />
                    </div>

                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2
                        label="Internal Code"
                        name="internalCode"
                        type="text"
                      />
                    </div>

                    
                  </div>

                  <div className="w-full h-12 flex justify-between items-center px-4 ">
                   
                  <div className="flex w-[150px] pr-4 items-center select-none">
                      <button
                        onClick={onOpenModal}
                        className="w-full bg-gray-200 items-center flex justify-center"
                      >
                        Select on Map
                      </button>
                    </div>
                    <MapModal open={open} onCloseModal={onCloseModal} />
                   
                   
                    <button
                      type="submit"
                      className="bg-green-500 rounded-md px-6 py-2  focus:outline-none text-white"
                    >
                      <span className="h-full flex items-center">
                        <svg
                          class="svg-icon"
                          viewBox="0 0 20 20"
                          width="24px"
                          height="24px"
                        >
                          <path
                            fill="#8A2BE2"
                            d="M19.404,6.65l-5.998-5.996c-0.292-0.292-0.765-0.292-1.056,0l-2.22,2.22l-8.311,8.313l-0.003,0.001v0.003l-0.161,0.161c-0.114,0.112-0.187,0.258-0.21,0.417l-1.059,7.051c-0.035,0.233,0.044,0.47,0.21,0.639c0.143,0.14,0.333,0.219,0.528,0.219c0.038,0,0.073-0.003,0.111-0.009l7.054-1.055c0.158-0.025,0.306-0.098,0.417-0.211l8.478-8.476l2.22-2.22C19.695,7.414,19.695,6.941,19.404,6.65z M8.341,16.656l-0.989-0.99l7.258-7.258l0.989,0.99L8.341,16.656z M2.332,15.919l0.411-2.748l4.143,4.143l-2.748,0.41L2.332,15.919z M13.554,7.351L6.296,14.61l-0.849-0.848l7.259-7.258l0.423,0.424L13.554,7.351zM10.658,4.457l0.992,0.99l-7.259,7.258L3.4,11.715L10.658,4.457z M16.656,8.342l-1.517-1.517V6.823h-0.003l-0.951-0.951l-2.471-2.471l1.164-1.164l4.942,4.94L16.656,8.342z"
                          ></path>
                        </svg>
                        <span className="px-2">Save</span>
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default LocationEdit;
