import React, { useState, useEffect } from "react";
import Action from "./Action";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import Address from "./Address";
import Select from "react-select";
import DashboardRoute from "./DashboardRoute";
import Confirm from "./Confirm";
import { BiSearchAlt } from "react-icons/bi";
import FadeLoader from "react-spinners/FadeLoader";
import axios from "axios";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";

function All({ isLoading, error, data, url, viewUrl, editUrl, sortedData }) {
  let [loading, setLoading] = useState(false);

  const [selectSupplier, setSelectSupplier] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  // const [retailName, setRetailerName] = useState(null);

  const [input, setInput] = useState({
    city: "",
    zipCode: "",
  });
  const history = useHistory();

  // console.log("data: ", data)
  // const fetchRetailerName = async () => {
  //   const res = await axios({
  //     method: "GET",
  //     url: `${process.env.REACT_APP_BASE_URL}/orgs?sort=createdDate,desc`,
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${localStorage.getItem("user-info")}`,
  //     },
  //   });
  //   setRetailerName(res?.data);
  // };

  // useEffect(() => {
  //   fetchRetailerName();
  // }, []);

  const handleInput = (e, name) => {
    const query = e.target.value.toLowerCase();

    setInput({ ...input, [name]: e.target.value });

  
   
    if (name === "city") {
      setLoading(true);
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/_search/suppliers?city.contains=${query}*`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      }).then((res) => {
        setFilteredData(res?.data);
        setLoading(false);
      }).catch(({ message }) => {
        if (message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          toast.error(message)
        }
      })
    } else {
      setLoading(true);
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/_search/suppliers?zipCode.contains=${query}*`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      }).then((res) => {
        setFilteredData(res?.data);
        setLoading(false);
      }).catch(({ message }) => {
        if (message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          toast.error(message)
        }
      })
    }
  };
  
  const supplierHandler = (value) => {
    setSelectSupplier(value);
    if ((input?.login !== "", input.orderNumber !== "")) {
      setLoading(true);
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/_search/suppliers?name.equals=${value?.value}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      }).then((res) => {
        setFilteredData(res?.data);
        setLoading(false);
      }).catch(({ message }) => {
        if (message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          toast.error(message)
        }
      })
    }
  };

  const clear = () => {
    setInput({
      city: "",
      zipCode: "",
    });
    setSelectSupplier(null);
    setFilteredData(null)
  };

  return (
    <div className=" w-full h_das_table">
      <div
        className={
          loading
            ? "bg-black bg-opacity-0 absolute inset-0 z-10 flex items-center justify-center"
            : null
        }
      >
        <FadeLoader className="absolute " loading={loading} size={150} />
      </div>

      <div className="flex h-10 w-full items-center mt-3.5 mb-0.5 justify-between py-7 px-4">
        <div className="w-1/4 flex justify-end items-center">
          <input
            onChange={(e) => handleInput(e, "city")}
            className="w-full focus:outline-none py-1.5 px-2 border-2 border-gray-200 rounded-lg"
            placeholder="Search City"
            value={input?.city}
          />
          <BiSearchAlt className="w-12 h-5 absolute  text-blue-500" />
        </div>

        <div className="w-1/4 flex justify-end items-center">
          <input
            onChange={(e) => handleInput(e, "zipCode")}
            type="number"
            className="w-full focus:outline-none py-1.5 px-2 border-2 border-gray-200 rounded-lg"
            placeholder="Search Zip Code"
            value={input?.zipCode}
          />
          <BiSearchAlt className="w-12  h-5 mr-4 absolute   text-blue-500" />
        </div>

        <div style={{ width: "25%" }}>
          <Select
            placeholder="select Supplier"
            className="text-left"
            maxMenuHeight={130}
            value={selectSupplier}
            onChange={supplierHandler}
            options={data?.map((item, index) => {
              return {
                label: item?.name,
                value: item?.name,
                key: index,
              };
            })}
          />
        </div>
        <button
          onClick={clear}
          className="bg-green-600 py-1.5 px-5 mr-7 rounded text-white"
        >
          Clear filters
        </button>
      </div>



      <div className="ag-theme-alpine  w-full h-full text-left">
        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            resizable: true,
          }}
          suppressRowClickSelection={true}
          rowSelection={"multiple"}
          pagination={true}
          paginationPageSize={10}
          rowData={filteredData ? filteredData : data}
          onRowClicked={(params) =>
            history.push({
              pathname: viewUrl,
              state: { data: params.data },
            })
          }
        >
          <AgGridColumn
            headerName="Supplier Name"
            field="name"
            minWidth={200}
            sortable={true}
            headerCheckboxSelection={true}
            headerCheckboxSelectionFilteredOnly={true}
            checkboxSelection={true}
            cellRendererFramework={(params) => (
              <DashboardRoute params={params} />
            )}
          />

          <AgGridColumn
            field="address"
            minWidth={400}
            cellRendererFramework={(params) => <Address params={params} />}
          />
          <AgGridColumn
            field="status"
            minWidth={100}
            cellRendererFramework={(params) => <Confirm params={params} />}
          />
          <AgGridColumn
            field="action"
            minWidth={100}
            cellRendererFramework={(params) => (
              <Action
                params={params}
                url={url}
                viewUrl={viewUrl}
                editUrl={editUrl}
              />
            )}
          />
        </AgGridReact>
      </div>
    </div>
  );
}

export default All;
