import React, { useState, useEffect } from "react";
import Action from "./Action";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "react-toggle/style.css"
import Address from './Address'
import Select from "react-select";
import DashboardRoute from "./DashboardRoute";
import Confirm from './Confirm'

function All({ isLoading, error, data, url, viewUrl, editUrl, sortedData, orgList, retailerList}) {
  const [status, setStatus] = useState(false);
  const [value, setValue] = useState("");
  const changeHandler = (value) => {
    setValue(value);
  };

  return (
    <div className=" w-full h_das_table select-none">
      <div className="flex h-10 w-full items-center mt-2 justify-between py-8 px-4">
        <input
          className="w-2/5 focus:outline-none py-1.5 px-2 border-2 border-gray-200 rounded-lg"
          placeholder="Search"
        />

        <div style={{ width: "40%" }}>
          <Select
            placeholder="Select"
            className="text-left"
            maxMenuHeight={162}
            value={value}
            onChange={changeHandler}
            options={
              sortedData?.map((item, index) => {
                 return {
                    label: item,
                    value: item,
                    key: index
                 }
              })
           }
          />
          
        </div>
        <button className="bg-green-600 py-1.5 px-5 mr-7 rounded text-white">
          Clear filters
        </button>
      </div>

      <div className="ag-theme-alpine  w-full h-full text-left">
        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            resizable: true,
          }}
          suppressRowClickSelection={true}
          rowSelection={"multiple"}
          pagination={true}
          paginationPageSize={10}
          rowData={data}
        >
          <AgGridColumn
            headerName="Name"
            field="name"
            minWidth={180}
            sortable={true}
            headerCheckboxSelection={true}
            headerCheckboxSelectionFilteredOnly={true}
            checkboxSelection={true}
            cellRendererFramework={(params) => (
              <DashboardRoute params={params} />
            )}
          />
          <AgGridColumn field="description" minWidth={300} sortable={true} />

          <AgGridColumn field="address" minWidth={400}
              cellRendererFramework={(params) => <Address params={params} />}
          />
          
          <AgGridColumn
            field="status"
            minWidth={100}
            cellRendererFramework={(params) => <Confirm params={params} />}
          />
          <AgGridColumn
            field="action"
            minWidth={100}
            cellRendererFramework={(params) => <Action params={params} url={url} viewUrl={viewUrl} editUrl={editUrl} orgList={orgList}/>}
          />
        </AgGridReact>
      </div>
    </div>
  );
}

export default All;
