import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import axios from "axios";
import { useQuery } from "react-query";
import FadeLoader from "react-spinners/FadeLoader";

import MappingModal from "./MappingModal";

import { removeEmptyStringKeys } from "../../../../../../utils/utils";
import { useOpeneventDataProvider } from "../context/OpeneventsContext";
import ProductEvent from "./ProductEvent";

function OpenEvents({ newUI }) {
  const history = useHistory();

  const { opencartApiParams, setOpencartApiParams } =
    useOpeneventDataProvider();

  const { state } = useLocation();
  const locationData = state?.data;

  const [reload, setReload] = useState(false);

  const fetchData = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_STORE_URL}/store-carts/open-cart?page=0&size=100&eventTime.greaterThanOrEqual=${locationData.entryTime}&eventTime.lessThanOrEqual=${locationData?.checkoutDate}&storeCode.equals=${locationData?.storeCode}&syncStatus.in=INITIALIZED,CONFLICT,ERRORED,CART_REVIEWED,PROCESSED&sort=eventTime,asc&cartRef.equals=${locationData?.sessionRef}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
        params: {
          ...removeEmptyStringKeys(opencartApiParams),
        },
      });
      return res.data;
    } catch ({ message }) {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      }
    }
  };

  const { data, isLoading } = useQuery(
    ["openItemList", reload, opencartApiParams],
    async () => fetchData(),
    {
      enabled: true || reload,
      // cacheTime: 0,
      // staleTime: 0,
    }
  );

  const [seed, setSeed] = useState(1);

  const reset = () => {
    setSeed(Math.random());
  };

  // if (isLoading)
  //   return (
  //     <div className="bg-black bg-opacity-80 absolute inset-0 flex items-center justify-center">
  //       <div className="w-full h-20 absolute z-10 text-white flex flex-col items-center justify-center">
  //         <FadeLoader
  //           color="#FFFFFF"
  //           className=""
  //           loading={isLoading}
  //           size={150}
  //         />
  //       </div>
  //     </div>
  //   );

  return (
    <div className="h-[calc(100vh)] w-ful">
      {newUI ? (
        <ProductEvent locationData={locationData} key={seed} reset={reset} />
      ) : (
        <MappingModal
          locationData={locationData}
          data={data}
          reload={reload}
          isLoading={isLoading}
          setReload={setReload}
          setOpencartApiParams={setOpencartApiParams}
          opencartApiParams={opencartApiParams}
        />
      )}
    </div>
  );
}

export default OpenEvents;
