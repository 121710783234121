import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import Confirm from "./Confirm";
//import CoffeeBgAction from "./CoffeeBgAction";
import { toast } from "react-toastify";
import { usePut } from "../../../../../../../api/usePut";
import { usePost } from "../../../../../../../api/usePost";

function MachineView() {
  const [status, setStatus] = useState(false);

  const statusChecked = (e) => {
    setStatus(e.target.checked);
  };
  const {
    state: { data },
  } = useLocation();
  const history = useHistory();

  const { mutate: coffeeMutate, status: coffeeStatus } = usePut(
    `${process.env.REACT_APP_STORE_URL}/dispenser-orders/resolve-failed`
  );

  const {
    mutate: qrMutate,
    error,
    loading,
    status: qrStatus,
  } = usePost(`${process.env.REACT_APP_BASE_URL}/manage-dispenser`);

  const { mutate: unlockMutate, status: machineStatus } = usePut(
    `${process.env.REACT_APP_BASE_URL}/machines/unlock`
  );

  const dispenserFailedClear = () => {
    const payload = {
      retailStoreCode: data?.retailStore?.internalCode,
      dispenserMachineId: data?.deviceIdentifier,
    };
    coffeeMutate(payload);
    toast.success("Submit Successfully");
  };

  const qrAction = (operation) => {
    const payload = {
      deviceId: data?.deviceIdentifier,
      operation: operation,
    };
    qrMutate(payload);
    toast.success("Submit Successfully");
  };

  const unlockChiller = () => {
    const payload = {
      deviceIdentifier: data?.deviceIdentifier,
    };
    unlockMutate(payload);
  };

  return (
    <>
      <div className="w-full h-screen flex text-left select-none">
        <div className="w-full h-full flex flex-col  items-center bg-gray-200">
          <div className="w-full h-12 flex bg-gray-100  items-center">
            <BiArrowBack
              onClick={() => history.goBack()}
              className="h-6 w-6 cursor-pointer ml-5"
            />
            {/* <h2 className="font-bold px-6 text-xl">{data?.name}</h2> */}
          </div>

          <div className="w-full h_rem flex p-2">
            <div className="w-7/10 h-full bg-white rounded-lg px-2">
              <div className="w-full h-[50px] border-b-2 flex justify-between items-center">
                <h2 className="text-black text-bold text-lg select-none">
                  Information
                </h2>
                {data?.type === "dispenser" && (
                  <button
                    onClick={dispenserFailedClear}
                    className="rounded-full bg-gray-200 py-1 px-4 focus:outline-none active:bg-gray-300"
                  >
                    Dispenser failed clear
                  </button>
                )}
              </div>
              <div className="w-full flex h-[55px]  justify-between items-center">
                <Confirm />
                {data?.type === "dispenser" && (
                  <div className="flex space-x-6 items-center">
                    <button
                      onClick={() => qrAction("enable_qrcode")}
                      className="rounded-full bg-gray-200 py-1 px-4 focus:outline-none active:bg-gray-300"
                    >
                      QR Enable
                    </button>
                    <button
                      onClick={() => qrAction("disable_qrcode")}
                      className="rounded-full bg-gray-200 py-1 px-4 focus:outline-none active:bg-gray-300"
                    >
                      QR Disable
                    </button>
                  </div>
                )}
                {data?.type === "chiller" && data?.ageRestricted && (
                  <div className="flex space-x-6 items-center">
                    <button
                      onClick={() => unlockChiller()}
                      className="rounded-full bg-gray-200 py-1 px-4 focus:outline-none active:bg-gray-300"
                    >
                      Unlock Chiller
                    </button>
                  </div>
                )}
              </div>

              <div className="h-auto select-none  hover:scrollbar-thumb-gray-500 scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100">
                <div className="border-t-2 border-gray-200 py-1 px-4">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="">Name</div>
                    <div className="">{data?.name}</div>
                  </div>
                </div>

                {data?.deviceIdentifier ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">Device Identifier</div>
                      <div className="">{data?.deviceIdentifier}</div>
                    </div>
                  </div>
                ) : null}

                {data?.status ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">Status</div>
                      <div className="">{data?.status}</div>
                    </div>
                  </div>
                ) : null}

                {data?.mode ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">Mode</div>
                      <div className="">{data?.mode}</div>
                    </div>
                  </div>
                ) : null}

                {data?.macAddress ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">Mac Address</div>
                      <div className="">{data?.macAddress}</div>
                    </div>
                  </div>
                ) : null}

                {data.type === "chiller" && (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">Age Restriction</div>
                      <div className="">{data?.ageRestricted.toString()}</div>
                    </div>
                  </div>
                )}

                {data?.qrCode ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">Qr Code</div>
                      <div className="">{data?.qrCode}</div>
                    </div>
                  </div>
                ) : null}

                <h2 className="font-bold mt-2 ml-4 text-base">RetailStore </h2>

                {data?.retailStore?.name ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">Retailer Store's Name</div>
                      <div className="">{data?.retailStore?.name}</div>
                    </div>
                  </div>
                ) : null}

                {data?.retailStore?.email ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className=""> Retailer Store's Email </div>
                      <div className="">{data?.retailStore?.email}</div>
                    </div>
                  </div>
                ) : null}

                {data?.retailStore?.description ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className=""> Retailer Store's Description </div>
                      <div className="">{data?.retailStore?.description}</div>
                    </div>
                  </div>
                ) : null}

                {data?.retailStore?.website ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className=""> Retailer Store's Website </div>
                      <div className="">{data?.retailStore?.website}</div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="w-3/10 h-full ml-2 bg-white rounded-lg p-2">
              <div className="w-full border-b-2">
                <h2 className="text-black text-bold text-lg">Log</h2>
              </div>

              <div className="flex"></div>

              <div></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MachineView;
