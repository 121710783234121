import React, { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { useGet } from "../../../../../../../../api/useGet";
import ClipLoader from "react-spinners/ClipLoader";
import ModalVoucherAllocation from "../../../../../../../modal/edit/ModalVoucherAllocation";
import { getTime } from "../../../../../../../../logic/useGetTime";

function VoucherView() {
  const history = useHistory();
  const alert = useAlert();

  const [voucher, setVoucher] = useState();
  const {
    state: { data: lData },
  } = useLocation();

  const { data: voucherData } = useGet(
    `${process.env.REACT_APP_BASE_URL}/vouchers/${lData}`,
    `voucherDetail`
  );

  useEffect(() => {
    setVoucher(voucherData);
  }, [voucherData]);

  const [allocateModalStatus, setAllocateModalStatus] = useState(false);
  const onOpenModal = () => setAllocateModalStatus(true);
  const onCloseModal = () => setAllocateModalStatus(false);

  const allocateVoucher = () => {
    onOpenModal();
  };

  return (
    <>
      {voucher ? (
        <div className="w-full h-screen text-left select-none flex">
          <div className="w-full h-full flex flex-col justify-center items-center bg-gray-200">
            <div className="w-full h-12 flex bg-gray-100  items-center">
              <BiArrowBack
                onClick={() => history.goBack()}
                className="h-6 w-6 cursor-pointer ml-5"
              />
            </div>
            <div className="w-full h_dasView flex p-2">
              <div className="w-full h-full bg-white rounded-lg p-2.5">
                <div className="w-full border-b-2">
                  <h2 className="text-black text-bold text-lg select-none">
                    Voucher View
                  </h2>
                </div>
                <div className="h-3/4 mt-3 scrollbar-thin scrollbar-thumb-gray-900  scrollbar-track-gray-100">
                  <div className="border-t-2 border-gray-200 py-4 px-4">
                    <div className="grid grid-cols-4 gap-4">
                      <div className="font-bold">Voucher Name</div>
                      <div>{voucher?.name}</div>
                    </div>
                  </div>
                  <div className="border-t-2 border-gray-200 py-4 px-4">
                    <div className="grid grid-cols-4 gap-4">
                      <div className="font-bold">Voucher Code</div>
                      <div>{voucher?.voucherTemplate?.voucherCodePrefix}</div>
                    </div>
                  </div>
                  <div className="border-t-2 border-gray-200 py-4 px-4">
                    <div className="grid grid-cols-4 gap-4">
                      <div className="font-bold">Discount</div>
                      <div>
                        {voucher?.voucherPreference?.discountType ===
                        "PERCENTAGE"
                          ? `${voucher?.voucherPreference?.discountPercentage} %`
                          : voucher?.voucherPreference?.discountType ===
                            "BUY_X_GET_Y"
                          ? `Buy ${voucher?.voucherPreference?.buyQuantity} Get ${voucher?.voucherPreference?.offerQuantity}`
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="border-t-2 border-gray-200 py-4 px-4">
                    <div className="grid grid-cols-4 gap-4">
                      <div className="font-bold">Distribution Type</div>
                      <div>{voucher?.distributionType}</div>
                    </div>
                  </div>
                  <div className="border-t-2 border-gray-200 py-4 px-4">
                    <div className="grid grid-cols-4 gap-4">
                      <div className="font-bold">Applicable days</div>
                      <div>
                        {voucher?.voucherScheduleConfigs
                          ? voucher?.voucherScheduleConfigs[0]?.applicableDays?.join(
                              ", "
                            )
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="border-t-2 border-gray-200 py-4 px-4">
                    <div className="grid grid-cols-4 gap-4">
                      <div className="font-bold">Start Date</div>
                      <div>{getTime(voucher?.startDate, "UTC")}</div>
                    </div>
                  </div>
                  <div className="border-t-2 border-gray-200 py-4 px-4">
                    <div className="grid grid-cols-4 gap-4">
                      <div className="font-bold">End Date</div>
                      <div>{getTime(voucher?.endDate, "UTC")}</div>
                    </div>
                  </div>
                  <div className="border-t-2 border-gray-200 py-4 px-4">
                    <div className="grid grid-cols-4 gap-4">
                      <div className="font-bold">Status</div>
                      <div>{voucher?.status}</div>
                    </div>
                  </div>
                  <hr className="hr_style" />
                  {voucher?.distributionType === "PROMOTION" &&
                    voucher?.status === "ACTIVE" && (
                      <div className="py-4 px-4 flex">
                        <p>
                          <span className="font-bold">Actions</span>
                        </p>
                        <div className="w-3/10 h-16 flex justify-center items-center px-3 mt-5 pr-8">
                          <button
                            type="button"
                            className="bg-green-500 rounded-lg px-6 py-3  focus:outline-none text-white"
                            onClick={allocateVoucher}
                          >
                            <span className="h-full flex items-center">
                              <span className="px-2">
                                Allocate Voucher To Shopper
                              </span>
                            </span>
                          </button>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
          <ModalVoucherAllocation
            open={allocateModalStatus}
            onCloseModal={onCloseModal}
            voucher={voucher}
          />
        </div>
      ) : (
        <div className="w-full h-full flex justify-center items-center">
          <ClipLoader size={150} />
        </div>
      )}
    </>
  );
}

export default VoucherView;
