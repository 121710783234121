import React, { useState } from "react";
import Toggle from "react-toggle";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";

function ActivationShopper({ params, handleRefresh }) {
  console.log("🚀 ~ ActivationShopper ~ params:", params);
  const history = useHistory();
  const [activationStatus, setStatus] = useState(params?.data?.blocked);

  const onStatusChange = (e) => {
    console.log("🚀 ~ onStatusChange ~ exxxxxxxxxxx:", e);
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const requestUrl = `${process.env.REACT_APP_SHOPPER_URL}/users/${params?.data?.login}/status`;
            const payload = {
              login: params?.data?.login,
              blocked: e,
            };

            try {
              const { data } = await axios({
                method: "POST",
                url: requestUrl,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("user-info")}`,
                },
                data: payload,
              });
              setStatus(e.target.checked);

              if (handleRefresh) handleRefresh();
            } catch (error) {
              if (error?.response?.status === 401) {
                history.push("/login");
              } else {
                setStatus(!activationStatus);
                toast.error(error?.response?.data?.title);
                if (handleRefresh) handleRefresh();
              }
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const updateStatus = async () => {
    const requestUrl = `${process.env.REACT_APP_SHOPPER_URL}/users/${params?.data?.login}/status`;
    const payload = params?.data;
    payload["isBlocked"] = activationStatus;

    try {
      const { data } = await axios({
        method: "POST",
        url: requestUrl,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
        data: payload,
      });

      if (handleRefresh) handleRefresh();
    } catch (error) {
      if (error?.response?.status === 401) {
        history.push("/login");
      } else {
        setStatus(!activationStatus);
        toast.error(error?.response?.data?.title);
        if (handleRefresh) handleRefresh();
      }
    }
  };

  return (
    <>
      <Toggle
        onChange={(e) => onStatusChange(e.target.checked)}
        checked={activationStatus}
        className="react-switch"
      />
    </>
  );
}

export default ActivationShopper;
