import React, { useState } from "react";
import ModalImg from "./ModalImg";
import NoImage from "../../../../../../Images/noImage.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Iconify from "../../../../../../components/iconify/Iconify";

const settings = {
    // dots: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <Iconify color="black" icon="ooui:next-ltr" />,
    prevArrow: <Iconify color="black" icon="ooui:previous-ltr" />,
};

// Custom CSS styles for the slider container
const sliderStyle = {
    maxWidth: "500px", // Set the desired width
    maxHeight: "300px", // Set the desired height
    padding: "10px",
    paddingTop: "0",
    // marginLeft: "50px",
    // marginRight: "50px",
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
};

function ImageSlider ({ imageList }) {

    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    return (
        <div>
            {imageList?.length > 0 ? (
                <div style={sliderStyle}>
                    <Slider {...settings}>
                        {imageList?.map((url, index) => (
                            <div>
                                <img
                                    src={url}
                                    height="100%"
                                    width="100%"
                                    alt="No Image"
                                    className="w-28 h-44 rounded cursor-pointer border-2 border-black"
                                    onClick={onOpenModal}
                                />
                                <p className="font-bold relative bottom-6 text-blue-400 w-full text-left pl-1">{`Image# : ${
                                    index + 1
                                }`}</p>

                                {url && <ModalImg open={open} onCloseModal={onCloseModal} img={url} />}
                            </div>
                        ))}
                    </Slider>
                </div>
                ) : (
                    <div>
                        <img
                            src={NoImage}
                            height="400px"
                            width="200px"
                            alt="No image"
                        />
                    </div>
            )}
        </div>
    );
};

export default ImageSlider;
