import React, { useState, useEffect } from "react";
import ExitImages from "../../../hawkeye/dashboard/ExitImages";
import NoImage from "../../../../../../../Images/noImage.jpg";
import { getTime } from "../../../../../../../logic/useGetTime";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

function SelectEvent({ data, index, onSelect, select, name, idName, isRecommended, showImage, timeZone }) {
  const [entryImages, setEntryImages] = useState([]);

  useEffect(() => {
    const people = [];

    if (data?.[name]?.length > 0) {
      for (let i = 0; i < 3; i++) {
        people.push(data?.[name][i] ? data?.[name][i] : "");
      }
    }
    setEntryImages(people);
  }, [data]);

  return (
    <>
      {(
        <div key={index} className={`flex h-50 w-full space-x-4 ml-2 mt-2`}>
          <>
            {data ? (
              <>
                {showImage ? entryImages?.length > 0 ? (
                  entryImages?.map((url, index) => (
                    <ExitImages
                      key={index}
                      url={url}
                      index={index}
                      through="openEntry"
                    />
                  ))
                ) : (
                  <img src={NoImage} alt="" className="w-44 h-44" />
                ) : (<></>)}
              </>
            ) : (
              <div className="w-full flex items-center justify-center font-bold">
                No User Exit Activity Available
              </div>
            )}
          </>

          {data && (
            <table className="w-30 h-36 flex flex-col cursor-pointer ml-6 justify-center items-center pr-3">
                <tbody>
                    <tr>
                        <td className="text-left">
                            <span className="font-bold">Entry Time</span>
                        </td>
                        <td>: &nbsp;</td>
                        <td className="text-left">
                            {getTime(data?.entryTime, "CET")}
                        </td>
                    </tr>
                    <tr>
                        <td className="text-left">
                            <span className="font-bold">Confidence Score</span>
                        </td>
                        <td>: &nbsp;</td>
                        <td className="text-left">
                            {data?.confidenceScore > 0 ? `${data?.confidenceScore}%` : '-'}
                        </td>
                    </tr>
                    <tr>
                        <td className="text-left">
                            <span className="font-bold">Cart Ref</span>
                        </td>
                        <td>: &nbsp;</td>
                        <td className="text-left">
                            {data?.cartRef}
                        </td>
                    </tr>
                </tbody>
            </table>
          )}

          {data && (
            <div className="ml-5 w-28 h-36 flex flex-col justify-center items-center space-y-2">
              <button
                onClick={() => onSelect(data)}
                className={`${
                  select?.[idName] === data?.[idName]
                    ? "bg-gray-500"
                    : "bg-green-400"
                } w-24 rounded-full h-8 focus:outline-none text-white`}
              >
                {select?.[idName] === data?.[idName] ? "selected" : "select"}
              </button>
              {select?.[idName] === data?.[idName] && (
                <button
                  className="focus:outline-none "
                  onClick={() => onSelect("")}
                >
                  Unselect
                </button>
              )}
            </div>
          )}

          {isRecommended && (
            <div className="w-50 h-36 flex flex-col justify-center items-center space-y-2">
              {/* <p>
                <span className="font-bold text-green-500">
                  <FaCheckCircle className="text-green" size={20} />
                  <div className="">Shopper Loop Recommended</div>
                </span>
              </p> */}
              <table>
                <tr>
                  <td><FaCheckCircle className="text-green" size={20} /></td>
                  <td className="font-bold text-green-500">&nbsp; Shopper Loop Recommended</td>
                </tr>
              </table>
            </div>
          )}
        </div>
      )
      }
    </>
  );
}

export default SelectEvent;
