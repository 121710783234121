export function removeEmptyStringKeys(obj) {
  return Object.keys(obj).reduce((acc, key) => {
    if (obj[key] !== "") {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
}

export function emptyAllValues(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      obj[key] = "";
    }
  }
  return obj;
}

export function convertToTitleCase(str) {
  // Split the input string into words
  const words = str.split("_");

  // Capitalize the first letter of each word
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  // Join the words back together with a space between them
  return capitalizedWords.join(" ");
}

export function areAllValuesEmpty(obj) {
  return Object.values(obj).every(
    (value) => value === null || value === undefined || value === ""
  );
}

export function isAnyValuesEmpty(obj) {
  return Object.values(obj).some(
    (value) => value === null || value === undefined || value === ""
  );
}

export const flattenData = (data, parentKey = "", result = {}) => {
  Object.keys(data).forEach((key) => {
    const shouldPrefixParentKey = parentKey !== "params"; // Add conditions for other keys if necessary
    const fullKey = shouldPrefixParentKey
      ? `${parentKey ? `${parentKey}.` : ""}${key}`
      : key;
    if (
      typeof data[key] === "object" &&
      data[key] !== null &&
      !Array.isArray(data[key])
    ) {
      // Pass down the flag to indicate whether to prefix parent key based on current level
      flattenData(data[key], shouldPrefixParentKey ? fullKey : "", result);
    } else {
      result[fullKey] = data[key];
    }
  });
  return result;
};
