import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  videoContainer: {
    maxWidth: "90%",
    maxHeight: "90%",
  },
}));

const CameraModal = ({ open, onClose, videoData }) => {
  console.log("🚀 ~ file: CameraModal.jsx:18 ~ CameraModal ~ videoData:", videoData)
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <div className={classes.videoContainer}>
        <video controls autoPlay>
          <source src={videoData?.videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </Modal>
  );
};

export default CameraModal;
