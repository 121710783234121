// ViewExternalLinkModal.js
import React, { useState, useEffect } from "react";
import { Modal, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ViewExternalLinkModal = ({ open, onClose, url }) => {
  console.log("🚀 ~ file: ViewExternalLinkModal.jsx:7 ~ ViewExternalLinkModal ~ url:", url)
  const [content, setContent] = useState("");

  useEffect(() => {
    // Fetch content from the provided URL
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const data = await response.text();
        setContent(data);
      } catch (error) {
        console.error("Error fetching content:", error);
      }
    };

    fetchData();
  }, [url]);

  return (
    <Modal open={open} onClose={onClose}>
      <div>
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </Modal>
  );
};

export default ViewExternalLinkModal;
