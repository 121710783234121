import React from "react";

import Sidebar from "./components/Sidebar/Sidebar";
import * as s from "../../../../App.styles";
import * as Palette from "../../../../colors";
import MainView from "./components/MainView/MainView";
import { Helmet } from "react-helmet";

function MerchantDashboard() {
  const backgroundImage = "images/mountain.jpg";

  const sidebarHeader = {
    fullName: JSON.parse(localStorage.getItem("dashboard-data"))?.name,
    shortName: "Au",
  };

  const menuItems = [
    {
      name: "Welcome",
      to: "/admin/merchant/dashboard",
      icon: "/icons/home.svg",
      subMenuItems: [],
    },
    {
      name: "Employees",
      to: "/admin/merchant/dashboard/employees",
      icon: "/icons/home.svg",
      subMenuItems: [],
    },
    {
      name: "Merchant Details",
      to: "/admin/merchant/dashboard/MerchantDetails",
      icon: "/icons/home.svg",
      subMenuItems: [],
    },
    {
      name: "Product Management",
      to: "/admin/merchant/dashboard",
      icon: "/icons/destinations.svg",
      subMenuItems: [
        {
          name: "Tax Categories",
          to: "/admin/merchant/dashboard/productManagement/tax",
        },
        {
          name: "Brand",
          to: "/admin/merchant/dashboard/productManagement/brand",
        },
        {
          name: "Supplier",
          to: "/admin/merchant/dashboard/productManagement/supplier",
        },
        {
          name: "Product",
          to: "/admin/merchant/dashboard/productManagement/product",
        },
        { name: "Esl", to: "/admin/merchant/dashboard/productManagement/esl" },
        {
          name: "Product Category",
          to: "/admin/merchant/dashboard/productManagement/category",
        },
        {
          name: "Unit of Measure",
          to: "/admin/merchant/dashboard/productManagement/uom",
        },
        {
          name: "Offer",
          to: "/admin/merchant/dashboard/productManagement/offer",
        },
        {
          name: "Dynamic Price",
          to: "/admin/merchant/dashboard/productManagement/dynamicPrice",
        },
      ],
    },
    {
      name: "Voucher Management",
      to: "/admin/merchant/dashboard",
      icon: "/icons/destinations.svg",
      subMenuItems: [
        {
          name: "Voucher",
          to: "/admin/merchant/dashboard/voucherManagement/voucher",
        },
        {
          name: "Distribution",
          to: "/admin/merchant/dashboard/voucherManagement/distribution",
        },
      ],
    },

    {
      name: "Retailer store",
      to: "/admin/merchant/dashboard/retailer-store",
      icon: "/icons/home.svg",
      subMenuItems: [],
    },
    {
      name: "Notifications",
      to: "/admin/merchant/dashboard/notifications",
      icon: "/icons/home.svg",
      subMenuItems: [],
    },

    // { name: "Machine", to: "/admin/merchant/dashboard/machine", icon: "/icons/home.svg", subMenuItems: [] },
    {
      name: "Fullfillment",
      to: "/admin/merchant/dashboard/fullfillment",
      icon: "/icons/home.svg",
      subMenuItems: [],
    },
    {
      name: "Shoppers",
      to: "/admin/merchant/dashboard/shoppers",
      icon: "/icons.home.svg",
      subMenuItems: [],
    },
    {
      name: "Orders",
      to: "/admin/merchant/dashboard/orders",
      icon: "/icons/home.svg",
      subMenuItems: [],
    },
    {
      name: "Change Password",
      to: "/admin/merchant/dashboard/password",
      icon: "/icons/home.svg",
      subMenuItems: [],
    },
  ];

  const fonts = {
    header: "ZCOOL KuaiLe",
    menu: "Poppins",
  };
  return (
    <s.App>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {`Dashboard - ` +
            JSON.parse(localStorage.getItem("dashboard-data"))?.name}
        </title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <Sidebar
        backgroundImage={backgroundImage}
        sidebarHeader={sidebarHeader}
        menuItems={menuItems}
        fonts={fonts}
        colorPalette={
          localStorage.getItem("role") === "support"
            ? Palette.swampy
            : Palette.preDark
        }
      />

      <MainView />
    </s.App>
  );
}

export default MerchantDashboard;
