import React from "react";

import { Link } from "react-router-dom";

function DashboardRoute({ params }) {

  const saveData = (data) => { localStorage.setItem("orderData", JSON.stringify(data))} 
  
  return (
    <>
      <Link
        to={{
          pathname: "order/refund/refunded-summary",
          state:{id:params?.data?.id, sessionRef:params?.data?.order?.sessionRef}
        }}
        onClick={()=>saveData(params?.data)}
        className="text-blue-900 font-bold"
      >
        {params.value}
      </Link>
    </>
  );
}

export default DashboardRoute;
