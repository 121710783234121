import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Avatar,
  Button,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import axios from "axios";
import { toast } from "react-toastify";
import { getTime } from "../../../../../../../logic/useGetTime";
import { useGet } from "../../../../../../../api/useGet";
import SessionViewThumbnail from "../../../../../../../components/SessionViewThumbnail";
import VideoSwapper from "../../../../../../../components/VideoSelectableSwapper";
import { DatePicker } from "antd";
import "./Coffee.css";
import dayjs from "dayjs";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/system";
import moment from "moment-timezone";
// import "moment-timezone";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";

// Extend day.js with the necessary plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VideoModal = ({ isModalOpen, handleCloseModal, storeCartVideoPaths }) => {
  return (
    <StyledModal
      open={isModalOpen}
      onClose={handleCloseModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <VideoSwapper arr={storeCartVideoPaths} playRate={1.5} />
    </StyledModal>
  );
};

const OpenCoffeeItem = ({ coffeeData, handleRefreshAPI, cartData }) => {
  console.log(
    "coffeeData",
    coffeeData,
    cartData,
    coffeeData?.groupId,
    coffeeData?.scaleId
  );
  const {
    imageUrl,
    coffeeName,
    barcode,
    eventTime,
    productInfos,
    storeTimeZone,
    storeCartVideoPaths,
    id,
    scaleInternalCode,
  } = coffeeData || {};

  const [quantity, setQuantity] = useState(1);

  const [isOpenVideoId, setIsOpenVideoId] = useState(-1);

  const handleCloseModal = () => setIsOpenVideoId(-1);

  const handleApiCall = async (productData) => {
    // setLoading(true);
    try {
      const url = `${process.env.REACT_APP_SHOPPER_URL}/stores/modify-cart`;
      await axios({
        method: "PUT",
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
        data: productData,
      });
      // setApiCall(!apicall);
      handleRefreshAPI();
      toast.success("Item added!!");
      // setLoading(false);
    } catch (error) {
      console.log("message", error?.response);
      // setLoading(false);

      // Handle any errors
      toast.error(error?.response?.data?.title);
      // console.error(error);
    }
  };

  return (
    <Grid container spacing={1} alignItems="center" mb={1}>
      <Grid item xs>
        <Box
          component="img"
          src={productInfos?.[0]?.imageUrl}
          alt={coffeeName}
          sx={{ width: 64, height: 64 }}
        />
      </Grid>
      <Grid item xs>
        <Typography variant="body1">{productInfos?.[0]?.name}</Typography>
        <Typography variant="body2">
          Barcode: {productInfos?.[0]?.barCode}
        </Typography>
      </Grid>
      <Grid item xs>
        <Typography variant="body2">{scaleInternalCode}</Typography>
      </Grid>
      <Grid item xs>
        <div className="flex flex-row items-center">
          <Typography variant="body2">Qty: </Typography>{" "}
          <input
            placeholder="Enter"
            onChange={(e) => setQuantity(e.target.value)}
            value={quantity}
            type="number"
            className="focus:outline-none border rounded h-[35px] w-[100px] px-[12px] ml-2"
          />
        </div>
        <Typography variant="body2">
          Event time: {getTime(eventTime, storeTimeZone)}
        </Typography>
      </Grid>
      <Grid item xs>
        {/* <IconButton aria-label="Play Event Video">
          <PlayCircleOutlineIcon />
        </IconButton> */}
        {storeCartVideoPaths?.length > 0 ? (
          <IconButton
            aria-label="Play Event Video"
            onClick={() => setIsOpenVideoId(id)}
          >
            <PlayCircleOutlineIcon />
          </IconButton>
        ) : (
          <Box
            component="img"
            src="/noImage.jpg"
            alt=""
            sx={{ width: 64, height: 64 }}
          />
        )}
      </Grid>
      <Grid item xs justify="end">
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handleApiCall({
              id: cartData?.id,
              storeCartItems: [
                {
                  productId: coffeeData?.productId
                    ? coffeeData.productId
                    : coffeeData?.id,
                  quantity,
                  // transactionDate: input?.time.utc().format(dateTimeFormat),
                  activityType: "PICK",
                  storeCode: cartData?.storeCode,
                  note: "",
                  isStockClearanceDiscount:
                    coffeeData?.isStockClearanceDiscount || false,
                  scaleGroupId: coffeeData?.groupId ? coffeeData?.groupId : "",
                  scaleIdentifier: coffeeData?.scaleId
                    ? coffeeData?.scaleId
                    : "",
                  // name: searchData?.selectedName,
                  // unitPrice: searchData?.selectedPrice,
                },
              ],
            });
          }}
        >
          Add Item
        </Button>
      </Grid>
      {isOpenVideoId === id && storeCartVideoPaths?.length > 0 && (
        <VideoModal
          isModalOpen={isOpenVideoId === id}
          handleCloseModal={handleCloseModal}
          storeCartVideoPaths={storeCartVideoPaths}
        />
      )}
    </Grid>
  );
};

const AddCoffeeItem = ({ product, item, cartData, handleRefreshAPI }) => {
  const { imageUrl, barCode, productName } = product || {};
  console.log("product", product, item);

  const handleApiCall = async (productData) => {
    // setLoading(true);
    try {
      const url = `${process.env.REACT_APP_SHOPPER_URL}/stores/modify-cart`;
      await axios({
        method: "PUT",
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
        data: productData,
      });
      // setApiCall(!apicall);
      handleRefreshAPI();
      toast.success("Item added!!");
      // setLoading(false);
    } catch (error) {
      console.log("message", error?.response);
      // setLoading(false);

      // Handle any errors
      toast.error(error?.response?.data?.title);
      // console.error(error);
    }
  };

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      sx={{ bgcolor: "background.paper", p: 2, borderRadius: 1 }}
    >
      <Grid item>
        {/* <Avatar
          src={imageUrl}
          alt={coffeeName}
          variant="rounded"
          sx={{ width: 64, height: 64 }}
        /> */}
        <Box
          component="img"
          src={imageUrl || "/noImage.jpg"}
          alt=""
          sx={{ width: 64, height: 64 }}
        />
      </Grid>
      <Grid item xs>
        <Typography variant="body1">{productName}</Typography>
        <Typography variant="body2">Qty: 1</Typography>
      </Grid>
      <Grid item xs>
        <Typography variant="body2" color="text.secondary">
          Barcode: {barCode}
        </Typography>
        <Typography variant="body2" color="text.secondary" />
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            handleApiCall({
              id: cartData?.id,
              storeCartItems: [
                {
                  productId: product?.id ? product.id : product?.productId,
                  quantity: 1,
                  // transactionDate: input?.time.utc().format(dateTimeFormat),
                  activityType: "PICK",
                  storeCode: cartData?.storeCode,
                  note: "",
                  isStockClearanceDiscount:
                    product?.isStockClearanceDiscount || false,
                  scaleGroupId: cartData?.scaleGroupId
                    ? cartData?.scaleGroupId
                    : "",
                  scaleIdentifier: cartData?.scaleIdentifier
                    ? cartData?.scaleIdentifier
                    : "",
                  // name: searchData?.selectedName,
                  // unitPrice: searchData?.selectedPrice,
                },
              ],
            })
          }
        >
          Add Item
        </Button>
      </Grid>
    </Grid>
  );
};

function CoffeeEventModel({ open, handleClose, data }) {
  const theme = useTheme();

  const { RangePicker } = DatePicker;

  console.log("CoffeeEventModel", data);

  const fullScreen = useMediaQuery(theme.breakpoints.up("md"));

  const [searchFilteredData, setSearchFilteredData] = useState([]);

  const [refreshApi, setRefreshApi] = useState(false);

  const [selectedDateRange, setSelectedDateRange] = useState([
    dayjs(data?.entryTime)
      .tz(data?.storeTimeZone ? data?.storeTimeZone : "CET")
      .startOf("day"),
    dayjs(data?.checkoutDate)
      .tz(data?.storeTimeZone ? data?.storeTimeZone : "CET")
      .endOf("day"),
  ]);

  const { data: coffeeData } = useGet(
    `${
      process.env.REACT_APP_STORE_URL
    }/store-carts/coffee-open-events?storeCode.equals=${
      data?.storeCode
    }&eventTime.greaterThanOrEqual=${dayjs(
      selectedDateRange?.[0]
    ).toISOString()}&eventTime.lessThanOrEqual=${dayjs(
      selectedDateRange?.[1]
    ).toISOString()}&sort=eventTime%2Cdesc&syncStatus.in=INITIALIZED%2CCONFLICT%2CIN_REVIEW&size=20`,
    ["coffeeData", selectedDateRange, refreshApi]
  );

  console.log("coffeeData3", coffeeData);

  const onChangeInput = (value, name) => {
    axios({
      method: "GET",
      url: `${
        process.env.REACT_APP_BASE_URL
      }/scale-products/shelf?shelfName.equals=${value.toLowerCase()}&retailStoreCode.equals=${
        data?.storeCode
      }&rootOrgId.equals=${data?.rootOrgId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
    }).then((res) => {
      setSearchFilteredData(res.data);
    });
  };

  const handleDateChange = (dates) => {
    console.log(
      "🚀 ~ file: Refunded.js:260 ~ handleDateChange ~ dates:",
      dates
    );
    if (dates?.length === 2) {
      // setStartTime([
      //   moment
      //     .tz(dates?.[0], "CET")
      //     .startOf("day")
      //     .subtract(1, "d")
      //     .toISOString(),
      //   moment
      //     .tz(dates?.[1], "CET")
      //     .startOf("day")
      //     .subtract(1, "d")
      //     .toISOString(),
      // ]);
      setSelectedDateRange(dates);
    }
  };

  const handleRefreshAPI = () => setRefreshApi((flag) => !flag);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="full-screen-dialog-title"
      sx={{ margin: 5 }}
    >
      <DialogContent sx={{ padding: "20px" }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, color: "black", textAlign: "center" }}
          >
            Open Coffee Transactions
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon sx={{ color: "black" }} />
          </IconButton>
        </Box>
        <Divider sx={{ my: 3 }} />
        <Box p={4}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Box sx={{ border: "1px solid black" }}>
                <Box
                  bgcolor="#D9D9D9"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  p={2}
                >
                  <Typography textAlign="center">Open Coffee Events</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 1,
                    p: 1.5,
                  }}
                >
                  <RangePicker
                    showTime
                    value={selectedDateRange}
                    onChange={handleDateChange}
                    style={{ height: "40px", width: "50%" }}
                  />
                </Box>
                <Box sx={{ p: 1.5, height: "600px", overflowY: "auto" }}>
                  {coffeeData?.map((product, index) => (
                    <OpenCoffeeItem
                      key={index}
                      cartData={data}
                      coffeeData={product}
                      handleRefreshAPI={handleRefreshAPI}
                    />
                  ))}
                </Box>
              </Box>
            </Grid>
            {/* <Grid item md={6}>
              <Box sx={{ border: "1px solid black" }}>
                <Box
                  bgcolor="#D9D9D9"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  p={2}
                >
                  <Typography textAlign="center">
                    Add Coffee Products
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    borderRadius: 1,
                    p: 0.5,
                  }}
                >
                  <TextField
                    placeholder="Search the product by Shelf"
                    variant="outlined"
                    size="small"
                    sx={{
                      flex: 1,
                      p: 1,
                      "& .MuiOutlinedInput-root": {
                        borderRadius: 3,
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => onChangeInput(e.target.value, "rack")}
                    fullWidth
                  />
                </Box>
                <Box sx={{ p: 1.5, height: "600px", overflowY: "auto" }}>
                  {searchFilteredData?.flatMap((item) =>
                    item?.productInfos?.length > 0
                      ? item?.productInfos?.map((product, index) => (
                          <AddCoffeeItem
                            item={item}
                            product={product}
                            cartData={data}
                            handleRefreshAPI={handleRefreshAPI}
                          />
                        ))
                      : []
                  )}
                </Box>
              </Box>
            </Grid> */}
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default CoffeeEventModel;
