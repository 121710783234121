import React, { useState, useEffect, Fragment } from "react";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import VideoController from "../custom-control/VideoController";

const VideoSwipper = ({ arr, playRate }) => {
  const [index, setIndex] = useState(0);
  const [obj, setObj] = useState();

  const nextArr = () => {
    if (index > 0 && index < arr.length - 1) {
      setIndex(index + 1);
    } else if (index === 0 && arr.length !== 1) {
      setIndex(index + 1);
    }
  };
  const prevArr = () => {
    if (index > 0 && index < arr.length) {
      setIndex(index - 1);
    } else if (index === arr.length) {
      setIndex(arr.length - 1);
    }
  };

  useEffect(() => {
    if (arr?.length > 0) {
      setObj(arr[index]);
    }
  }, [index]);

  return (
    <div className="w-full h-full flex  items-center justify-between">
      {arr?.length > 1 && (
        <HiChevronLeft
          onClick={prevArr}
          size={28}
          color={index === 0 && "gray"}
        >
          next
        </HiChevronLeft>
      )}
      <div className="w-full h-full  flex justify-center items-center bg-black rounded">
        <VideoController url={obj?.videoUrl} playRate={playRate} />
      </div>
      {arr?.length > 1 && (
        <HiChevronRight
          onClick={nextArr}
          size={28}
          color={index === arr?.length - 1 && "gray"}
        >
          prev
        </HiChevronRight>
      )}
    </div>
  );
};

export default VideoSwipper;
