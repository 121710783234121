import React, { useState } from "react";
import Toggle from "react-toggle";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { usePost } from "../api/usePost";

function Confirm({ params, url, getName, title, message, field }) {
  const [status, setStatus] = useState(
    params?.data?.[field] === "active" ? true : false
  );

  const { mutate } = usePost(url, getName);

  const statusChecked = (e) => {
    const value = e.target.checked;

    confirmAlert({
      title: title,
      message: message,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            mutate({ ...params?.data, [field]: value ? "active" : "inactive" });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <div className="text-left">
      <Toggle
        onChange={statusChecked}
        checked={status}
        className="react-switch"
      />
    </div>
  );
}

export default Confirm;
