import React from "react";
import { BroadcastChannel } from "broadcast-channel";
const channel = new BroadcastChannel("dashboard");

const sendDataTabs = (params) => {
  channel.postMessage(
    localStorage.setItem("dashboard-data", JSON.stringify(params.data))
  )
  channel.onmessage = (event) => {
    console.log("sendin other tab");
    channel.close();
  }
}

var openedWindow 

function DashboardRoute({ params }) {

  const clicked = () => {

    if(!localStorage.getItem("dashboard-tab")){
      localStorage.setItem("dashboard-tab", "clicked" )
      openedWindow = window.open("/admin/merchant/dashboard");
    }
    openedWindow.location.reload()
    sendDataTabs(params);
  };
  return (
    <>
      <button onClick={clicked} className="text-blue-900 font-bold focus:outline-none">
        {params.value}
      </button>
    </>
  );
}

export default DashboardRoute;
