import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { useAlert } from "react-alert";
import NumberFormat from "react-number-format";
import RefundItem from "./RefundItem";
import RefundSummaryHeader from "./RefundSummaryHeader";
import { MdDownload } from "react-icons/md";
import { Languages1 } from "../../../../../../Languages";
import Cookies from "universal-cookie";
import { usePost } from "../../../../../../api/usePost";
import { usePut } from "../../../../../../api/usePut";
import { useGet } from "../../../../../../api/useGet";
import { fields } from "../../../../../../statusField";
import { getTime } from "../../../../../../logic/useGetTime";
import Tippy from "@tippyjs/react";
import { REFUND_REQUESTED } from "../../../../../../utils/action_types";
import { getDecryptedData } from "../../../../../../utils/localStorageUtils";
import RefundWarningModal from "../modal/RefundWarningModal";

const refundOptions = [
  { value: "FULL", label: "Full Refund" },
  { value: "PARTIAL", label: "Partial Refund" },
];

const findMaxRefundQty = (arr, id) => {
  const findValue = arr?.refundOrderItems?.find(
    (ele) => ele?.orderItemId === id
  );
  return findValue;
};

const customStyles = {
  control: (base) => ({
    ...base,
    height: 35,
    minHeight: 35,
    width: 160,
  }),
};

function Refund({ header }) {
  const history = useHistory();
  const alert = useAlert();
  const cookies = new Cookies();
  const location = useLocation();

  const { data: refundData } = useGet(
    `${process.env.REACT_APP_SHOPPER_URL}/orders/${location.state.orderId}`,
    "refund"
  );
  console.log("refundData", refundData);

  const refund = usePost(
    `${process.env.REACT_APP_SHOPPER_URL}/order-refunds`,
    "refund"
  );

  const pendingApi = usePost(
    `${process.env.REACT_APP_SHOPPER_URL}/orders/${refundData?.id}/submit-order-payment`,
    "refund"
  );
  const confirmApi = usePut(
    `${process.env.REACT_APP_SHOPPER_URL}/orders/${refundData?.id}/verify-order-payment`,
    "refund"
  );

  // const allowedrefundData = location?.state?.data;

  const [open, setOpen] = useState(false);

  const [select, setSelect] = useState({
    refundType: "",
  });

  const [partial, setPartial] = useState({
    items: [],
  });

  const [orderItems, setOrderItems] = useState();

  const [input, setInput] = useState({
    comments: "",
  });

  const onChangeInput = (value, name) => {
    setInput({ ...input, [name]: value });
  };

  const onChangeOrderItems = (value, id, name) => {
    let updatedData = [...orderItems];
    if (
      value !== "0" &&
      name === "refundQuantity" &&
      dataIsExit(updatedData, id)
    ) {
      updatedData.map((item) => {
        if (item?.orderItemId === id) {
          item.refundQuantity = value?.toString();
        }
      });
      setOrderItems(updatedData);
    } else if (
      value === "0" &&
      name === "refundQuantity" &&
      dataIsExit(updatedData, id)
    ) {
      setOrderItems(updatedData?.filter((item) => item?.orderItemId !== id));
    } else if (!dataIsExit(updatedData, id)) {
      updatedData?.push({ orderItemId: id, refundQuantity: value.toString() });
      setOrderItems(updatedData);
    }
  };

  const handleRefund = () => {
    if (refundData?.orderTotal) {
      setOpen(true);
    } else {
      refundInit();
    }
  };

  const handleConfirm = () => {
    setOpen(false);
    refundInit();
  };

  useEffect(() => {
    let temp = [];

    refundData?.orderItems?.map((item) => {
      if (
        findMaxRefundQty(location?.state?.data, item?.id)?.refundQuantity > 0
      ) {
        temp.push({ orderItemId: item?.id, refundQuantity: item?.quantity });
      }
    });
    setOrderItems(temp);
  }, [refundData]);

  const onSelect = (name, value) => {
    setSelect({ ...select, [name]: value });
  };

  const detectedLan = (code) => {
    return Languages1?.filter((item) => {
      return item?.code === code;
    });
  };

  const fetchDownload = (url, name) => {
    const res = fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      }),
    })
      .then((res) => res.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${name}.pdf`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  const download = async () => {
    fetchDownload(
      `${process.env.REACT_APP_SHOPPER_URL}/orders/${
        refundData?.id
      }/download?langKey=${detectedLan(cookies.get("i18next"))[0]?.value}`,
      `${refundData?.orderNumber}`
    );
  };

  const removeData = (array, value) => {
    const arr = array?.filter((sel) => {
      return sel !== value;
    });
    setPartial({
      ...partial,
      items: [...arr],
    });
  };

  const partialSelect = (data) => {
    const array = partial?.items;

    if (array.includes(data?.id) === false) {
      if (data?.id !== undefined) {
        setPartial({
          ...partial,
          items: [...array, data?.id],
        });
      }
    } else if (array.includes(data?.id) === true) {
      if (data?.id !== undefined) {
        removeData(array, data?.id);
      }
    }
  };

  const dataIsExit = (data, id) => {
    return data?.some((ele) => ele?.orderItemId === id);
  };

  const loginInfo = getDecryptedData("loginInfo");

  const uniqID = localStorage.getItem("uniqID");

  const refundInit = () => {
    const payload = {
      refundAmount: refundData?.orderTotal,
      refundType: "FULL",
      comments: input?.comments,
      order: { id: refundData?.id },
      refundOrderItems: orderItems,
    };
    if (input?.comments !== "") {
      refund.mutate(payload);
      handleLogs.mutate({
        actionName: REFUND_REQUESTED,
        logTime: new Date().toISOString(),
        screenLoadingTime: `-`,
        logSessionId: uniqID,
        rootOrgId: refundData?.rootOrgId,
        retailStoreId: refundData?.storeId,
      });
      const timer = setTimeout(() => {
        localStorage.setItem("navigate", "refunds");
        history.goBack();
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      alert.error(<div className="w-auto normal-case">Comments Required</div>);
    }
  };

  const pending = () => {
    pendingApi.mutate("");
    if (pendingApi.isError === false) {
      // history.goBack()
    }
  };

  const confirmPending = () => {
    confirmApi.mutate("");
    if (confirmApi.isError === false) {
      // history.goBack()
    }
  };

  const handleLogs = usePost(
    `${process.env.REACT_APP_STORE_URL}/app-logging-infos`,
    "handleCaptureLogs"
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {refundData ? (
        <div
          className={`w-full ${
            header ? "h-[100vh]" : "h-screen pt-4"
          }  text-left flex select-none bg-gray-200`}
        >
          <div className="w-full h-full px-4 py-2">
            <div className="w-full  h-full  ">
              <div className="w-full h-8 flex items-center bg-white rounded-lg">
                <BiArrowBack
                  onClick={() => history.goBack()}
                  className="h-8 w-8 cursor-pointer absolute ml-4"
                />

                <div className="w-full flex justify-center items-center">
                  <h2 className="font-bold text-xl">
                    Order No : {refundData?.orderNumber}
                  </h2>
                </div>
              </div>

              <div className="w-full  rounded-lg px-2 bg-white my-2 pb-2">
                <div className="flex justify-center items-center my-1">
                  <h2 className="px-2 font-bold">Order Summary</h2>
                </div>

                {/* firstLine */}
                <div className="w-full flex justify-between px-2 pt-1">
                  <div className="w-[300px] px-2">
                    <h2>
                      <span className="font-bold">{`Login: `}</span>
                      <NumberFormat
                        displayType={"text"}
                        value={refundData?.login}
                        format="###-###-####"
                      />
                    </h2>
                  </div>
                  <div className="w-[300px]  px-2">
                    <h2>
                      <span className="font-bold">{`Email:`}</span>{" "}
                      {refundData?.email ? refundData?.email : "-"}
                    </h2>
                  </div>

                  <div className="w-[300px]  px-2">
                    <h2>
                      <span className="font-bold">{`Retailer store:`}</span>{" "}
                      {refundData?.retailStore?.name}
                    </h2>
                  </div>
                </div>
                {/* secondLine */}

                <div className="w-full flex justify-between px-2 pt-1">
                  <div className="w-[300px]  px-2">
                    <h2 className="">
                      <span className="font-bold">{`Total :`}</span>{" "}
                      {refundData?.formattedEffectiveOrderTotalCurrency}
                    </h2>
                  </div>

                  <div className={`w-[300px]  px-2`}>
                    <h2>
                      <span className="font-bold">{`Status: `}</span>

                      <span className={fields[refundData?.status]?.color}>
                        {fields[refundData?.status]?.title}
                      </span>
                    </h2>
                  </div>

                  <div className="w-[300px] flex  px-2">
                    <div className="flex ">
                      <h2 className="mr-3 font-bold">Receipt</h2>
                      <Tippy content="Download Receipt">
                        <button onClick={download}>
                          <MdDownload className="w-6 h-6 mt-1 cursor-pointer hover:text-blue-500 " />
                        </button>
                      </Tippy>
                    </div>
                  </div>
                </div>

                {/* thirdLine */}
                <div className="w-full flex justify-between px-2 ">
                  <div className="w-[300px]  px-2">
                    <h2>
                      <span className="font-bold">{`Order Time :`}</span>{" "}
                      {getTime(
                        refundData?.orderTime,
                        refundData?.storeTimeZone
                      )}
                    </h2>
                  </div>
                </div>
              </div>

              <div className="w-full h-[76%] rounded-lg bg-white flex flex-col ">
                <div>
                  <RefundSummaryHeader status={refundData?.status} />
                  <div className="h-88 mt-2  px-1.5 scrollbar-thin  scrollbar-thumb-gray-300 scrollbar-thumb-rounded-lg scrollbar-track-gray-100">
                    {refundData?.orderItems?.map((item, index) => {
                      return (
                        <RefundItem
                          key={index}
                          data={refundData}
                          item={item}
                          index={index}
                          onChangeOrderItems={onChangeOrderItems}
                          orderItems={orderItems}
                          setOrderItems={setOrderItems}
                          partialSelect={partialSelect}
                          partial={partial}
                          maxAllowedRefunded={location?.state?.data}
                          refundType={select?.refundType?.value}
                        />
                      );
                    })}
                  </div>
                  <h2
                    className={`w-full text-2xl my-6 ${
                      refundData?.status === "PAYMENT_PROCESSED" ||
                      refundData?.status === "PAYMENT_PARTIALLY_REFUNDED"
                        ? "pr-[5rem]"
                        : "pr-[120px]"
                    } flex justify-end items-center`}
                  >
                    <span className="mr-4 font-bold">Total Order</span>
                    {refundData?.formattedOrderTotalCurrency}
                  </h2>
                </div>

                <div
                  className={`w-full flex  ${
                    refundData?.status === "PAYMENT_PROCESSED" ||
                    refundData?.status === "PAYMENT_PARTIALLY_REFUNDED"
                      ? "justify-between"
                      : "justify-end"
                  } pl-6 items-center`}
                >
                  {(refundData?.status === "PAYMENT_PROCESSED" ||
                    refundData?.status === "PAYMENT_PARTIALLY_REFUNDED") && (
                    <div className="w-1/2 flex justify-center  min-h-[30px]">
                      <textarea
                        placeHolder="Comment..."
                        onChange={(e) => {
                          onChangeInput(e.target.value, "comments");
                        }}
                        value={input?.comments}
                        className="focus:outline-none w-full border border-gray-500 rounded-lg px-2 py-1"
                      />
                    </div>
                  )}

                  {refundData?.status === "PAYMENT_PROCESSED" ||
                  refundData?.status === "PAYMENT_PARTIALLY_REFUNDED" ? (
                    <button
                      className="bg-gray-300 px-4 h-8 rounded-lg  mr-20 focus:outline-none"
                      onClick={handleRefund}
                    >
                      Submit for Refund
                    </button>
                  ) : refundData?.status === "PAYMENT_CONFIRMATION_PENDING" ? (
                    <button
                      className="bg-gray-300 px-4 h-8 rounded-lg mb-1.5 mr-20 focus:outline-none"
                      onClick={confirmPending}
                    >
                      Verify Payment
                    </button>
                  ) : refundData?.status === "PAYMENT_PENDING" ? (
                    <button
                      className="bg-gray-300 px-4 h-8 rounded-lg mb-1.5 mr-20 focus:outline-none"
                      onClick={pending}
                    >
                      submit for payment
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h_screen flex justify-center items-center space-x-2">
          <BiArrowBack
            onClick={() => history.goBack()}
            className="h-7 w-7 cursor-pointer"
          />

          <p className="font-bold">Internal Server Error</p>
        </div>
      )}
      {open && (
        <RefundWarningModal
          open={open}
          handleClose={handleClose}
          handleConfirm={handleConfirm}
        />
      )}
    </>
  );
}

export default Refund;
