import React, { useState } from "react";
import { IoIosClose } from "react-icons/io";
import { TextField2 } from "../../../../validation/TextField2";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { usePut } from "../../../../../api/usePut";
import { useAlert } from "react-alert";
import Toggle from "react-toggle";

let validate;
validate = Yup.object({
  scaleId: Yup.string().required("Required"),
  maxUnits: Yup.number(),
  minUnits: Yup.number(),
  row: Yup.number(),
  col: Yup.number(),
});

function EditShelf(props) {
  const { open, onCloseModal, data } = props;
  const history = useHistory();
  const alert = useAlert();
  const [title, setTitle] = useState("Shelf");
  const [status, setStatus] = useState(
    data?.status === "Active" ? true : false
  );

  const statusChecked = (e) => {
    setStatus(e.target.checked);
  };

  const shelf = JSON.parse(localStorage.getItem("shelf"));

  const createShelf = usePut(
    `${process.env.REACT_APP_BASE_URL}/scales/${data?.id}`,
    "scaleList"
  );

  return (
    <>
      {open && (
        <div className="bg-black bg-opacity-80 absolute inset-0 z-10 flex items-center justify-center">
          <div
            className="w-96 h-3/4 shadow-lg  bg-white "
            style={{ borderRadius: 20 }}
          >
            <div
              onClick={onCloseModal}
              className="absolute mt-4 ml-4 cursor-pointer z-10 shadow-lg w-8 h-8 justify-center items-center flex rounded-full bg-gray-200"
            >
              <IoIosClose onClick={onCloseModal} className="h-8 w-8" />
            </div>
            <div className="absolute mt-5 ml-16 h-8">
              <h2 className="ml">{`Create Scale`}</h2>
            </div>

            <div className="w-full h-72 ">
              <Formik
                initialValues={{
                  scaleId: data?.scaleId ? data?.scaleId : "",
                  //variant: data?.variant ? data?.variant : "",
                  maxUnits: data?.maxUnit ? data?.maxUnit : "",
                  minUnits: data?.minUnit ? data?.minUnit : "",
                  row: data?.row ? data?.row : "",
                  col: data?.column ? data?.column : "",
                }}
                validationSchema={validate}
                onSubmit={async (values) => {
                  if (values.scaleId !== "") {
                    try {
                      const updateData = { ...data };

                      createShelf.mutate({
                        ...updateData,
                        id: data.id,
                        column: values?.col,
                        currentWeight: 0,
                        groupId: shelf?.internalCode,
                        minUnit: values?.minUnits,
                        maxUnit: values?.maxUnits,
                        scaleId: values?.scaleId,
                        status: status === true ? "Active" : "Inactive",
                      });

                      if (createShelf.isError === false) {
                        onCloseModal();
                        alert.success(
                          <div style={{ textTransform: "none" }}>
                            Successfully Added!
                          </div>
                        );
                      }
                    } catch (error) {
                      alert.error(
                        <div style={{ textTransform: "none" }}>
                          you are offline!
                        </div>
                      );
                    }
                  }
                }}
              >
                {() => (
                  <Form className="w-11/12 mx-auto mt-20 h-108 rounded-lg  text-left  ">
                    <div className="w-full h-full flex flex-col justify-center items-between  px-6">
                      <div className="flex items-center h-8 my-4 ">
                        <TextField2
                          label="Scale ID"
                          name="scaleId"
                          type="text"
                        />
                      </div>

                      {/* <div className="flex items-center h-10 my-2">
                          <TextField2
                            label="Variant"
                            name="variant"
                            type="text"
                          />
                        </div> */}

                      <div className="flex items-center h-8 my-4">
                        <TextField2
                          label="Min Units"
                          name="minUnits"
                          type="number"
                        />
                      </div>

                      <div className="flex items-center h-8 my-4">
                        <TextField2
                          label="Max Units"
                          name="maxUnits"
                          type="number"
                        />
                      </div>

                      <div className="flex items-center h-8 my-4">
                        <TextField2 label="Row" name="row" type="number" />
                      </div>

                      <div className="flex items-center h-8 my-4">
                        <TextField2 label="Col" name="col" type="number" />
                      </div>

                      <div className="w-full h-full flex items-center justify-start rounded-lg">
                        <span className=" mr-4 text-gray-500">Status</span>
                        <Toggle
                          onChange={statusChecked}
                          checked={status}
                          //icons={{ checked: null, unchecked: null }}
                          className="react-switch"
                        />
                      </div>

                      <div className="w-full flex justify-end items-center">
                        <button
                          type="submit"
                          className="bg-green-500 rounded-lg px-2 py-1.5  mb-6 focus:outline-none text-white"
                        >
                          <span className="h-full flex items-center">
                            <svg
                              class="svg-icon"
                              viewBox="0 0 20 20"
                              width="24px"
                              height="24px"
                            >
                              <path
                                fill="#8A2BE2"
                                d="M19.404,6.65l-5.998-5.996c-0.292-0.292-0.765-0.292-1.056,0l-2.22,2.22l-8.311,8.313l-0.003,0.001v0.003l-0.161,0.161c-0.114,0.112-0.187,0.258-0.21,0.417l-1.059,7.051c-0.035,0.233,0.044,0.47,0.21,0.639c0.143,0.14,0.333,0.219,0.528,0.219c0.038,0,0.073-0.003,0.111-0.009l7.054-1.055c0.158-0.025,0.306-0.098,0.417-0.211l8.478-8.476l2.22-2.22C19.695,7.414,19.695,6.941,19.404,6.65z M8.341,16.656l-0.989-0.99l7.258-7.258l0.989,0.99L8.341,16.656z M2.332,15.919l0.411-2.748l4.143,4.143l-2.748,0.41L2.332,15.919z M13.554,7.351L6.296,14.61l-0.849-0.848l7.259-7.258l0.423,0.424L13.554,7.351zM10.658,4.457l0.992,0.99l-7.259,7.258L3.4,11.715L10.658,4.457z M16.656,8.342l-1.517-1.517V6.823h-0.003l-0.951-0.951l-2.471-2.471l1.164-1.164l4.942,4.94L16.656,8.342z"
                              ></path>
                            </svg>
                            <span className="px-2">Create {title}</span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default EditShelf;
