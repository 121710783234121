import React, { useState, useEffect } from "react";
import Action from "./Action";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "react-toggle/style.css";
import Select from "react-select";
import DashboardRoute from "./refunded/DashboardRoute";
import moment from "moment";
import NumberFormat from "react-number-format";
import axios from "axios";
import { BiSearchAlt, BiErrorCircle } from "react-icons/bi";
import { AiOutlineFileDone } from "react-icons/ai";
import FadeLoader from "react-spinners/FadeLoader";
import { useHistory } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import { TiTick } from "react-icons/ti";
import { MdCancel } from "react-icons/md";
import { useQuery } from "react-query";
import {getTime} from "../../../logic/useGetTime";
import {toast} from 'react-toastify'

function Refunded({ listUrl }) {
  const [searchValue, setSearchValue] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [retailName, setRetailerName] = useState(null);
  let [loading, setLoading] = useState(false);

  const history = useHistory();

  const [input, setInput] = useState({
    login: "",
    orderNo: "",
  });

  const fetchRetailerName = async () => {

    try {
      const res = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/orgs?sort=createdDate,desc`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      });
      setRetailerName(res?.data);
    } catch ({message}) {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message)
      }
    }
    
  };
  const [activePage, setActivePage] = useState(1);
  const [params, setParams] = useState({
    id:"",
    submitId: "",
  });

  const fetchData = async (page) => {

    try {
      const res = await axios({
        method: "GET",
        url: `${listUrl}&page=${page - 1}&size=9`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      });
      return res.data;
    } catch ({message}) {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message)
      }
    }
    
  };
  const { data, isLoading, isIdle, error } = useQuery(
    ["refundList", activePage, params?.id , params?.submitId ],
    async () => fetchData(activePage),
    {
      enabled: !!activePage || !!params?.id || !!params?.submitId,
      cacheTime: 5,
    }
  );
  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  // useEffect(() => {
  //   fetchRetailerName();
  // }, []);

  const handleInput = (e, name) => {
    const query = e.target.value.toLowerCase();

    setInput({ ...input, [name]: e.target.value });

    if (name === "login") {
      setLoading(true);
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_SHOPPER_URL}/orders?login.contains=${query}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      }).then((res) => {
        setFilteredData(res?.data);
        setLoading(false);
      }).catch(({ message }) => {
        if (message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          toast.error(message)
        }
      })
    } else {
      setLoading(true);
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_SHOPPER_URL}/orders?orderNumber.contains=${query}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      }).then((res) => {
        setFilteredData(res?.data);
        setLoading(false);
      }).catch(({ message }) => {
        if (message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          toast.error(message)
        }
      })
    }
  };

  const retailerHandler = (value) => {
    setSearchValue(value);
    if ((input?.login !== "", input.orderNumber !== "")) {
      setLoading(true);
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_SHOPPER_URL}/orders?login=${input?.login}&orderNumber=${input.orderNumber}&rootOrgId=${searchValue?.value}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      }).then((res) => {
        setFilteredData(res?.data);
        setLoading(false);
      }).catch(({ message }) => {
        if (message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          toast.error(message)
        }
      })
    }
  };

  const clear = () => {
    setInput({
      login: "",
      orderNo: "",
    });
    setSearchValue(null);
  };

  // const refund = (data) => {
  //   localStorage.setItem("orderData", JSON.stringify(data));
  //   history.push("/order/refund");
  // };

  const approved = (data) => {
    axios({
      method: "PUT",
      url: `${process.env.REACT_APP_SHOPPER_URL}/order-refunds/${data?.id}/approve-order-refund`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
      data: {
        id: data?.id,
        refundStatus: "REFUND_APPROVED",
        comments: "approved",
      },
    }).then(() => {
      setParams({...params, ["id"]: data?.id});
    }).catch(({ message }) => {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message)
      }
    })
  };

  const rejected = (data) => {
    axios({
      method: "PUT",
      url: `${process.env.REACT_APP_SHOPPER_URL}/order-refunds/${data?.id}/review-order-refund`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
      data: {
        id: data?.id,
        refundStatus: "REFUND_REJECTED",
        comments: "rejected",
      },
    }).then(() => {
    
      setParams({...params, ["id"]: data?.id});
    }).catch(({ message }) => {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message)
      }
    })
  };

  const submit = (data) => {
    axios({
      method: "PUT",
      url: `${process.env.REACT_APP_SHOPPER_URL}/order-refunds/${data?.id}/verify-order-refund`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
      data: data,
    }).then(() => {
      setParams({...params, ["submitId"]: data?.id});
    }).catch(({ message }) => {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message)
      }
    })
  };

  return (
    <div className=" w-full h_table select-none">
      <div
        className={
          loading
            ? "bg-black bg-opacity-0 absolute inset-0 z-10 flex items-center justify-center"
            : null
        }
      >
        <FadeLoader className="absolute " loading={loading} size={150} />
      </div>
      <div className="flex h-10 w-full items-center my-2 justify-between py-7 px-4">
        <div className="w-1/4 flex justify-end items-center">
          <input
            onChange={(e) => handleInput(e, "login")}
            type="number"
            className="w-full focus:outline-none py-1.5 px-2 border-2 border-gray-200 rounded-lg"
            placeholder="Search customer login"
            value={input?.login}
          />
          <BiSearchAlt className="w-12 h-5 absolute  text-blue-500" />
        </div>

        <div className="w-1/4 flex justify-end items-center">
          <input
            onChange={(e) => handleInput(e, "orderNo")}
            className="w-full focus:outline-none py-1.5 px-2 border-2 border-gray-200 rounded-lg"
            placeholder="Search Order Number"
            value={input?.orderNo}
          />
          <BiSearchAlt className="w-12  h-5 mr-4 absolute   text-blue-500" />
        </div>

        <div style={{ width: "25%" }}>
          <Select
            placeholder="Select Retailer"
            className="text-left"
            maxMenuHeight={130}
            value={searchValue}
            onChange={retailerHandler}
            options={retailName?.map((item, index) => {
              return {
                label: item?.name,
                value: item?.rootOrgId,
                key: index,
              };
            })}
          />
        </div>
        <button
          onClick={clear}
          className="bg-green-600 py-1.5 px-5 mr-7 rounded text-white"
        >
          Clear filters
        </button>
      </div>

      <div className="ag-theme-alpine w-full h-full text-left">
        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 80,
            resizable: true,
          }}
          suppressRowClickSelection={true}
          pagination={false}
          paginationPageSize={10}
          rowData={filteredData ? filteredData : data}
        >
          <AgGridColumn
            headerName="Order No."
            field="orderNumber"
            minWidth={150}
            sortable={true}
            headerCheckboxSelection={true}
            headerCheckboxSelectionFilteredOnly={true}
            checkboxSelection={true}
            cellRendererFramework={(params) => (
              <DashboardRoute params={params} />
            )}
          />
          {/* <AgGridColumn
            field="login"
            minWidth={120}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <>
                  <NumberFormat
                    displayType={"text"}
                    value={params?.data?.login}
                    format="###-###-####"
                  />
                </>
              );
            }}
          /> */}

          <AgGridColumn
            field="status"
            headerName="Status"
            minWidth={250}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <>
                  {params?.data?.refundStatus === "IN_PROCESS" ? (
                    <span className="text-yellow-400">
                      {params?.data?.refundStatus}
                    </span>
                  ) : params?.data?.refundStatus === "PAYMENT_PENDING" ? (
                    <span className="text-yellow-400">
                      {params?.data?.refundStatus}
                    </span>
                  ) : params?.data?.refundStatus ===
                    "PAYMENT_CONFIRMATION_PENDING" ? (
                    <span className="text-yellow-400">
                      {params?.data?.refundStatus}
                    </span>
                  ) : params?.data?.refundStatus === "REFUND_PROCESSED" ? (
                    <span className="text-green-400">
                      {params?.data?.refundStatus}
                    </span>
                  ): params?.data?.refundStatus === "REFUND_APPROVED" ? (
                    <span className="text-green-700">
                      {params?.data?.refundStatus}
                    </span>
                  ) : params?.data?.refundStatus === "REFUND_REJECTED" ? (
                    <span className="text-red-400">
                      {params?.data?.refundStatus}
                    </span>
                  ) : params?.data?.refundStatus === "PAYMENT_REFUNDED" ? (
                    <span className="text-green-400">
                      {params?.data?.refundStatus}
                    </span>
                  ) : params?.data?.refundStatus === "REFUND_SUBMITTED" ? (
                    <span className="text-yellow-400">
                      {params?.data?.refundStatus}
                    </span>
                  ) : params?.data?.refundStatus === "PAYMENT_REJECTED" ? (
                    <span className="text-red-500">
                      {params?.data?.refundStatus}
                    </span>
                  ) : params?.data?.refundStatus === "REFUND_FAILED" ? (
                    <span className="text-red-500">
                      {params?.data?.refundStatus}
                    </span>
                  ) : null}
                </>
              );
            }}
          />

          <AgGridColumn
            field="orderTotal"
            headerName="Total"
            minWidth={100}
            cellStyle={{ paddingRight: 10 }}
            cellRendererFramework={(params) => {
              return (
                <>
                  <p>
                    {params?.data?.order.currencySymbol}{" "}
                    {parseFloat(params?.data?.refundAmount).toFixed(2)}
                  </p>
                </>
              );
            }}
          />

          <AgGridColumn
            headerName="Date"
            minWidth={200}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <>
                  <p>
                  {getTime(params?.data?.refundOrderTime, params?.data?.storeTimeZone)}
                  </p>
                </>
              );
            }}
          />

          {/* <AgGridColumn
            headerName="Email"
            minWidth={100}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <>
                  <p>{params?.data?.userDTO?.email ? params.data?.userDTO?.email : "Nill"}</p>
                </>
              );
            }}
          /> */}

          <AgGridColumn
            headerName="Products"
            minWidth={300}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <div className="flex">
                  {params?.data?.order?.orderItems?.map((item) => {
                    return <p className="ml-0.5">{`${item?.productName},`}</p>;
                  })}
                </div>
              );
            }}
          />

          <AgGridColumn
            headerName="Receipt"
            minWidth={80}
            cellRendererFramework={(params) => <Action params={params} />}
          />

          <AgGridColumn
            headerName="Verified"
            minWidth={80}
            sortable={true}
            cellRendererFramework={(params) => {
              //PAYMENT_PROCESSED

              return (
                <>
                  {params?.data?.refundStatus === "IN_PROCESS" ? (
                    <div className="flex w-16 h-10 items-center">
                      <div className="border-2 border-gray-200 rounded-l-lg p-1 ">
                        <TiTick
                          className="w-5 h-5 cursor-pointer text-green"
                          onClick={() => approved(params?.data)}
                        />
                      </div>
                      <div className="border-2 border-gray-200 rounded-r-lg p-1">
                        <MdCancel
                          className="w-5 h-5 cursor-pointer"
                          onClick={() => rejected(params?.data)}
                        />
                      </div>
                    </div>
                  ) : params?.data?.refundStatus === "REFUND_SUBMITTED" ? (
                    <div className="flex w-16 h-10 items-center justify-center">
                      <div className="border-2 border-gray-200 rounded-lg p-1 ">
                        <AiOutlineFileDone
                          className="w-5 h-5 cursor-pointer text-green"
                          onClick={() => submit(params?.data)}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="flex w-16 h-10 items-center justify-center">
                      <div className="border-2 border-gray-200 rounded-lg p-1 ">
                        <BiErrorCircle className="w-5 h-5 cursor-pointer text-green" />
                      </div>
                    </div>
                  )}
                </>
              );
            }}
          />
        </AgGridReact>
      </div>
      <div className="absolute bottom-8 right-12  flex justify-center item-center">
      <Pagination
          count={21}
          showFirstButton
          showLastButton
          page={activePage}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
}

export default Refunded;
