import React from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

function ItemsInCart({ data }) {
  console.log("ItemsInCartItemsInCart", data);
  return (
    <>
      {data?.length > 0 ? (
        <TableContainer component={Paper}>
          <Typography variant="h6" align="center" gutterBottom>
            ITEMS IN CART
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>QTY</TableCell>
                <TableCell>X</TableCell>
                <TableCell>POSITION</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((item) => (
                <TableRow>
                  <TableCell>{item?.reviewedQuantity}</TableCell>
                  <TableCell>X</TableCell>
                  <TableCell>{item?.scaleInternalCode}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="h6" align="center" gutterBottom>
          No Data found
        </Typography>
      )}
    </>
  );
}

export default ItemsInCart;
