import React, { useState, useCallback, useMemo } from "react";
import { BiArrowBack } from "react-icons/bi";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { useHistory, useLocation } from "react-router-dom";
import SearchData from "../../../../../../components/SearchDataMany";
import axios from "axios";
import { usePut } from "../../../../../../api/usePut";
import moment from "moment-timezone";
import NoImage from "../../../../../../Images/noImage.jpg";
import { FaEdit } from "react-icons/fa";
import { getTime, getTimeOnly } from "../../../../../../logic/useGetTime";
import { MdClear } from "react-icons/md";
import { BiSearchAlt } from "react-icons/bi";
import AddItemDisclosure from "./AddItemDisclosure";
import { toast } from "react-toastify";
import MobileDateTimePicker from "../../../../../../components/MobileDateTimepicker";
import MatchEventHeader from "../header";
import BackArrowButton from "../../../../../../components/buttons/BackarrowButton";
import { MerchantsDetails } from "../../../../../../utils/action_types";
import { Box, Tooltip, Typography } from "@mui/material";

const AddItem = () => {
  const history = useHistory();
  const [filteredData, setFilteredData] = useState({
    barcode: "",
    name: "",
  });
  const [searchData, setSearchData] = useState();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  console.log(
    "🚀 ~ file: AddItem.js:29 ~ AddItem ~ location:",
    location?.state?.data?.status
  );
  const addItemApi = usePut(
    location?.state?.data?.status == "APPROVED_IN_REVIEW" ||
      location?.state?.data?.status == "REJECTED_IN_REVIEW"
      ? `${process.env.REACT_APP_SHOPPER_URL}/stores/modify-cart-approver`
      : `${process.env.REACT_APP_SHOPPER_URL}/stores/modify-cart`,
    "storesCart",
    true
  );
  const [searchFilteredData, setSearchFilteredData] = useState([]);
  console.log("🚀 ~ AddItem ~ searchFilteredData:", searchFilteredData);
  const dateTimeFormat = "yyyy-MM-DDTHH:mm:ss.SSSZ";
  const retailStoreCode = location?.state?.storeCode;

  const [input, setInput] = useState({
    storecode:
      retailStoreCode === null || retailStoreCode === undefined
        ? "ANHOODY001"
        : retailStoreCode,
    rack: "",
    name: "",
    barcode: "",
    qty: "1",
    time: moment.tz(
      location?.state?.checkoutDate,
      dateTimeFormat,
      "Europe/Berlin"
    ),
    note: "",
  });

  const [selectRow, setSelectRow] = useState();
  const [selectData, setSelectData] = useState();

  const onChangeInput = (value, name) => {
    setInput({ ...input, [name]: value });
    setSelectRow();

    if (name === "name" && value.length > 0) {
      setLoading(true);

      axios({
        method: "GET",
        url: `${
          process.env.REACT_APP_BASE_URL
        }/_search/products?name.contains=${value.toLowerCase()}*&rootOrgId.equals=${
          location?.state?.data?.rootOrgId
        }`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      }).then((res) => {
        // setFilteredData({ ...filteredData, [name]: res?.data });
        setSearchFilteredData(res.data);
        setLoading(false);
      });
    } else if (name === "barcode" && value.length > 0) {
      setLoading(true);

      axios({
        method: "GET",
        url: `${
          process.env.REACT_APP_BASE_URL
        }/_search/products?barCode.equals=${value.toLowerCase()}&rootOrgId.equals=${
          location?.state?.data?.rootOrgId
        }`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      }).then((res) => {
        setSearchFilteredData(res.data);
        setLoading(false);
      });
    } else if (
      name === "rack" &&
      input?.storecode?.length > 0 &&
      value.length > 0
    ) {
      axios({
        method: "GET",
        url: `${
          process.env.REACT_APP_BASE_URL
        }/scale-products/shelf?shelfName.equals=${value.toLowerCase()}&retailStoreCode.equals=${
          location?.state?.data?.storeCode
        }&rootOrgId.equals=${location?.state?.data?.rootOrgId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      }).then((res) => {
        setSearchFilteredData(res.data);
        setLoading(false);
      });
    } else if (name === "selectedName") {
      setSearchData({ ...searchData, [name]: value });
    } else if (name === "selectedPrice") {
      setSearchData({ ...searchData, [name]: value });
    }
  };

  const onChangeTime = (value, name) => {
    if (name === "time") {
      var m = moment.tz(value, dateTimeFormat, "Europe/Berlin");
      setInput({ ...input, [name]: m });
    }
  };

  console.log("🚀 ~ addItem ~ selectRow:", selectRow, selectData);
  const addItem = () => {
    const productId = selectRow?.id ? selectRow?.id : selectRow?.productId;

    if (input?.qty !== 0 && productId !== "" && productId !== undefined) {
      var addItem = location?.state?.data;
      const st = moment(addItem?.entryTime).add(1, "minutes").utc().format();
      const payload = {
        id: addItem.id,
        storeCartItems: [
          {
            productId: productId,
            quantity: input?.qty,
            transactionDate: input?.time.utc().format(dateTimeFormat),
            activityType: "PICK",
            storeCode: location?.state?.data?.storeCode,
            note: input?.note,
            isStockClearanceDiscount:
              selectRow?.isStockClearanceDiscount || false,
            scaleGroupId: selectData?.scaleGroupId
              ? selectData?.scaleGroupId
              : "",
            scaleIdentifier: selectData?.scaleIdentifier
              ? selectData?.scaleIdentifier
              : "",
            // name: searchData?.selectedName,
            // unitPrice: searchData?.selectedPrice,
          },
        ],
      };

      addItemApi.mutate(payload);
      // history.goBack();
      console.log(payload);
    } else {
      toast.error("Select one product or enter qty");
    }
  };

  const clear = () => {
    setInput({ ...input, rack: "", name: "", barcode: "" });
    setSearchFilteredData([]);
    setSelectRow();
  };

  const filterData = (data, name) => {
    if (data?.productInfos?.length > 0) {
      const productName = "productname"?.includes(name) ? "productName" : name;
      return data?.productInfos[0]?.[productName];
    } else {
      return data?.[name];
    }
  };

  // const rowSelected = (params) => {
  //   setSelectRow(params?.data);
  // };

  const onSelectRow = (data) => {
    console.log('onSelectRow', data)
    setSelectRow(data);
  };

  const handleBack = () => history.goBack();

  return (
    <div className="w-[82%] mx-auto h-[calc(100vh-60px)] pt-[10px]">
      {/* first row */}
      <div className="w-[98%] flex justify-between bg-gray-200 p-2">
        <div className="w-[25%] flex justify-start items-center space-x-4">
          <BackArrowButton onClick={() => handleBack()} />
          {/* <p className="font-bold text-lg">Add Item to Cart </p>
           */}
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <img
              src={
                MerchantsDetails.hasOwnProperty(
                  location?.state?.data?.storeCode
                )
                  ? MerchantsDetails?.[location?.state?.data?.storeCode]?.imgUrl
                  : "/images/noImage.jpg"
              }
              width="50px"
              height="auto"
              alt="/images/noImage.jpg"
              // className="w-[100px] h-[100px]"
              // className="mt-3"
            />
            <Typography variant="body2">
              {location?.state?.data?.storeCode}
            </Typography>
          </Box>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "25%",
          }}
        >
          <Typography variant="h6" fontWeight={700}>
            Manually add Item
          </Typography>
          <Typography variant="h6">
            {location?.state?.data?.sessionRef}
          </Typography>
        </div>
        <div className="w-[50%] flex justify-end items-center space-x-[6px]">
          <div
            className="flex flex-col justify-start"
            style={{ marginRight: "10px" }}
          >
            <div className="flex flex-row justify-between">
              <p className="font-bold mr-1">Entry Time</p>
              <Tooltip
                title={`Timezone: ${
                  location?.state?.storeTimeZone
                    ? location?.state?.storeTimeZone
                    : "CET"
                }`}
                arrow
              >
                <p>{` :  ${getTime(
                  location?.state?.entryTime,
                  location?.state?.storeTimeZone
                )} `}</p>
              </Tooltip>
            </div>
            <div className="flex flex-row justify-between">
              <p className="font-bold">Exit Time</p>
              <Tooltip
                title={`Timezone: ${
                  location?.state?.storeTimeZone
                    ? location?.state?.storeTimeZone
                    : "CET"
                }`}
                arrow
              >
                <p>{` :  ${getTime(
                  location?.state?.checkoutDate,
                  location?.state?.storeTimeZone
                )}`}</p>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
      {/* <MatchEventHeader filter={location?.sessionRef} locationData={location} /> */}

      <div className="w-[98%] border mt-[20px] flex flex-col p-2 space-y-2">
        <div className="w-full flex justify-between ">
          <div className="w-[20%] font-bold flex items-center justify-start">
            Product Search :
          </div>
          <div className="w-[80%] flex space-x-8 justify-end">
            {/* <div className="w-[350px] h-10 flex justify-between items-center px-1 border rounded border-black">
              <input
                placeholder="Search by name"
                className=" focus:outline-none  px-3 w-full h-full"
                onChange={(e) => onChangeInput(e.target.value, "name")}
                value={input?.name}
                disabled={
                  input?.rack.length <= 0 && input?.barcode.length <= 0
                    ? false
                    : true
                }
              />
              {input.name.length > 0 ? (
                <MdClear
                  onClick={clear}
                  size={24}
                  className={`cursor-pointer`}
                />
              ) : (
                <BiSearchAlt
                  size={24}
                  className={`cursor-pointer  text-blue-500`}
                />
              )}
            </div>

            <div className="w-[350px] h-10 flex justify-between items-center px-1 border rounded border-black">
              <input
                placeholder="Search by barcode"
                className=" focus:outline-none  px-3 h-full w-full"
                onChange={(e) => onChangeInput(e.target.value, "barcode")}
                value={input?.barcode}
                disabled={
                  input?.rack.length <= 0 && input?.name.length <= 0
                    ? false
                    : true
                }
              />
              {input.barcode.length > 0 ? (
                <MdClear
                  onClick={clear}
                  size={24}
                  className={`cursor-pointer`}
                />
              ) : (
                <BiSearchAlt
                  size={24}
                  className={`cursor-pointer  text-blue-500`}
                />
              )}
            </div> */}

            <div className="w-[100%] h-10 flex justify-between items-center px-1 border rounded border-black">
              <input
                placeholder="Search by rack"
                disabled={
                  input.storecode.length > 3 &&
                  input.barcode.length <= 0 &&
                  input.name.length <= 0
                    ? false
                    : true
                }
                className=" focus:outline-none  px-3 w-full h-full"
                onChange={(e) => onChangeInput(e.target.value, "rack")}
                value={input?.rack}
              />

              {input.rack.length > 0 ? (
                <MdClear
                  onClick={clear}
                  size={24}
                  className={`cursor-pointer`}
                />
              ) : (
                <BiSearchAlt
                  size={24}
                  className={`cursor-pointer  text-blue-500`}
                />
              )}
            </div>
          </div>
        </div>

        {/* <div className="w-[100%] flex">
          <div className="w-[40%] font-bold flex items-center justify-start"></div>

          <div className="w-[60%] flex space-x-8 justify-end">
            <input
              placeholder="Enter Storecode"
              className="w-[350px] h-10 h-fullfocus:outline-none rounded px-4 border border-black"
              onChange={(e) => onChangeInput(e.target.value, "storecode")}
              value={input?.storecode}
            />

            <div className="w-[350px] h-10 flex justify-between items-center px-1 border rounded border-black">
              <input
                placeholder="Search by rack"
                disabled={
                  input.storecode.length > 3 &&
                  input.barcode.length <= 0 &&
                  input.name.length <= 0
                    ? false
                    : true
                }
                className=" focus:outline-none  px-3 w-full h-full"
                onChange={(e) => onChangeInput(e.target.value, "rack")}
                value={input?.rack}
              />

              {input.rack.length > 0 ? (
                <MdClear
                  onClick={clear}
                  size={24}
                  className={`cursor-pointer`}
                />
              ) : (
                <BiSearchAlt
                  size={24}
                  className={`cursor-pointer  text-blue-500`}
                />
              )}
            </div>
          </div>
        </div> */}
      </div>

      <div className="h-[68.5vh] w-[100%] text-left scrollbar-thin scrollbar-thumb-white scrollbar-track-white">
        <div className="w-[98%]">
          <AddItemDisclosure
            allScales={searchFilteredData}
            selectRow={selectRow}
            onSelectRow={onSelectRow}
            input={input}
            setSelectData={setSelectData}
          />
        </div>
      </div>

      <div className=" w-[98%] flex justify-between items-center mt-4">
        <div className="flex space-x-12">
          <div className="w-full flex justify-start items-center space-x-4">
            <p className="w-[88px] font-bold text-left">Comment</p>
            <textarea
              className="w-[260px] h-[160] bg-white focus:outline-none rounded px-2 border border-gray-200"
              onChange={(e) => onChangeInput(e.target.value, "note")}
              value={input?.note}
            />
          </div>

          <div className="w-full flex justify-start items-center space-x-4">
            <p className="w-[88px] font-bold text-left">Quantity</p>
            <input
              placeholder="Enter"
              onChange={(e) => onChangeInput(e.target.value, "qty")}
              value={input?.qty}
              type="number"
              className="focus:outline-none border rounded h-[35px] w-[260px] px-[12px]"
            />
          </div>
          <div className="w-full flex justify-start items-center space-x-4">
            {/* <p className="w-[88px] font-bold text-left">Event Time</p> */}
            <MobileDateTimePicker
              value={input?.time}
              clearIcon={false}
              onChangeTime={onChangeTime}
              name="time"
              label={"Event Time"}
              maxDateTime={new Date()}
              format={"yyyy-MM-DD hh:mm:ss"}
            />
          </div>
        </div>

        <button
          onClick={addItem}
          className="w-[150px] h-[38px] rounded-lg bg-green-500 mt-[10px] text-white"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default AddItem;
