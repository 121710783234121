import React, { useState } from "react";
import Action from "./Action";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "react-toggle/style.css";
import Select from "react-select";
import DashboardRoute from "./DashboardRoute";
import moment from "moment";
import axios from "axios";
import { BiSearchAlt, BiRefresh } from "react-icons/bi";
import { RiRefund2Line } from "react-icons/ri";
import { AiOutlineInteraction } from "react-icons/ai";
import { MdOutlinePending } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import FadeLoader from "react-spinners/FadeLoader";
import { useHistory } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import { useQuery } from "react-query";
import { useGet } from "../../../api/useGet";
import { usePostGet } from "../../../api/usePostGet";
import { usePutGet } from "../../../api/usePutGet";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { getTime } from "../../../logic/useGetTime";
import Tippy from "@tippyjs/react";

function All({ listUrl, sortedData }) {
  const [allCount, setAllCount] = useState();

  const { mutate: pending } = usePostGet("orderList");
  const { mutate: verify } = usePutGet("orderList");

  let [loading, setLoading] = useState(false);

  const [status, setStatus] = useState(uuidv4());

  const history = useHistory();

  const [input, setInput] = useState({
    login: "",
    orderNo: "",
  });

  const [select, setSelect] = useState({
    merchant: "",
  });

  //list url
  const [activePage, setActivePage] = useState(1);

  const fetchData = async (page, login, orderNo, merchant) => {
    const rootId = merchant?.value ? merchant?.value : "";
    const res = await axios({
      method: "GET",
      url: `${listUrl}&page=${
        page - 1
      }&size=9&login.contains=${login}&orderNumber.contains=${orderNo}&rootOrgId.equals=${rootId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
    });
    setAllCount(res?.headers?.["x-total-count"]);
    return res.data;
  };

  const { data, isLoading, isIdle, error } = useQuery(
    [
      "orderList",
      activePage,
      input?.login,
      input?.orderNo,
      select?.merchant,
      status,
    ],
    async () =>
      fetchData(activePage, input?.login, input?.orderNo, select?.merchant),
    {
      enabled:
        !!activePage ||
        !!input?.login ||
        !!input?.orderNo ||
        !!select?.merchant ||
        !!status,
      cacheTime: Infinity,
      staleTime: 1000,
    }
  );

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  const handleInput = (e, name) => {
    setInput({ ...input, [name]: e.target.value.toLowerCase() });
  };

  const clear = () => {
    setInput({
      login: "",
      orderNo: "",
    });
    setSelect({ merchant: "" });
  };

  const retailerHandler = (value, name) => {
    setSelect({ ...select, [name]: value });
  };

  const refund = (data) => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_SHOPPER_URL}/order-refunds/${data?.id}/request`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
    })
      .then((res) => {
        history.push({
          pathname: "/order/refund",
          state: { orderId: data.id, data: res.data },
        });
      })
      .catch(({ message }) => {
        if (message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          toast.error(message);
        }
      });
  };

  const confirmPending = (data) => {
    toast("Your request is successfully submitted.");
    verify({
      data: {},
      url: `${process.env.REACT_APP_SHOPPER_URL}/orders/${data?.id}/verify-order-payment`,
    });

    setTimeout(() => {
      setStatus(uuidv4());
      toast("Your request is successfully submitted.");
    }, 3000);
  };

  const paymentPending = (data) => {
    pending({
      data: {},
      url: `${process.env.REACT_APP_SHOPPER_URL}/orders/${data?.id}/submit-order-payment`,
    });

    setTimeout(() => {
      setStatus(uuidv4());
      toast("Your request is successfully submitted.");
    }, 3000);
  };

  return (
    <div className=" w-full h_table select-none">
      <div
        className={
          loading
            ? "bg-black bg-opacity-0 absolute inset-0 z-10 flex items-center justify-center"
            : null
        }
      >
        <FadeLoader className="absolute " loading={loading} size={150} />
      </div>
      <div className="flex h-10 w-full items-center my-2 justify-between py-7 px-4">
        <div className="w-1/4 flex justify-end items-center">
          <input
            onChange={(e) => handleInput(e, "login")}
            type="number"
            className="w-full focus:outline-none py-1.5 px-2 border-2 border-gray-200 rounded-lg"
            placeholder="Search customer login"
            value={input?.login}
          />
          <BiSearchAlt className="w-12 h-5 absolute  text-blue-500" />
        </div>

        <div className="w-1/4 flex justify-end items-center">
          <input
            onChange={(e) => handleInput(e, "orderNo")}
            className="w-full focus:outline-none py-1.5 px-2 border-2 border-gray-200 rounded-lg"
            placeholder="Search Order Number"
            value={input?.orderNo}
          />
          <BiSearchAlt className="w-12  h-5 mr-4 absolute   text-blue-500" />
        </div>

        <div style={{ width: "25%" }}>
          <Select
            placeholder="Select Retailer"
            className="text-left"
            maxMenuHeight={130}
            isDisabled={select?.merchant === "" ? false : true}
            onClick={() => console.log("akash")}
            // menuIsOpen={select?.merchant?.value ? true : false}
            value={select?.merchant}
            onChange={(value) => retailerHandler(value, "merchant")}
            //   options={retailName?.map((item, index) => {
            //     return {
            //       label: item?.name,
            //       value: item?.rootOrgId,
            //       key: index,
            //     };
            //   })
            // }
          />
        </div>

        <button
          onClick={clear}
          className="bg-green-600 py-1.5 px-5 mr-7 rounded text-white"
        >
          Clear filters
        </button>
      </div>

      <div className="ag-theme-alpine w-full h-full text-left">
        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 80,
            resizable: true,
          }}
          suppressRowClickSelection={true}
          pagination={false}
          paginationPageSize={8}
          rowData={data}
        >
          <AgGridColumn
            headerName="Order No."
            field="orderNumber"
            minWidth={150}
            sortable={true}
            headerCheckboxSelection={true}
            headerCheckboxSelectionFilteredOnly={true}
            checkboxSelection={true}
            cellRendererFramework={(params) => (
              <DashboardRoute params={params} key={params?.data?.id} />
            )}
          />
          {/* <AgGridColumn
            field="login"
            minWidth={120}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <>
                  <NumberFormat
                    displayType={"text"}
                    value={params?.data?.login}
                    format="###-###-####"
                  />
                </>
              );
            }}
          /> */}

          <AgGridColumn
            field="status"
            headerName="Status"
            minWidth={250}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <div key={params?.data?.id}>
                  {params?.data?.status === "IN_PROCESS" ? (
                    <span className="text-yellow-400">
                      {params?.data?.status}
                    </span>
                  ) : params?.data?.status === "PAYMENT_PENDING" ? (
                    <span className="text-yellow-400">
                      {params?.data?.status}
                    </span>
                  ) : params?.data?.status ===
                    "PAYMENT_CONFIRMATION_PENDING" ? (
                    <span className="text-yellow-400">
                      {params?.data?.status}
                    </span>
                  ) : params?.data?.status === "PAYMENT_PROCESSED" ? (
                    <span className="text-yellow-400">
                      {params?.data?.status}
                    </span>
                  ) : params?.data?.status === "PAYMENT_REFUNDED" ? (
                    <span className="text-green-400">
                      {params?.data?.status}
                    </span>
                  ) : params?.data?.status === "PAYMENT_PARTIALLY_REFUNDED" ? (
                    <span className="text-green-400">
                      {params?.data?.status}
                    </span>
                  ) : params?.data?.status === "PAYMENT_WAIVED" ? (
                    <span className="">{params?.data?.status}</span>
                  ) : params?.data?.status === "PAYMENT_REJECTED" ? (
                    <span className="text-red-500">{params?.data?.status}</span>
                  ) : params?.data?.status === "PAYMENT_FAILED" ? (
                    <span className="text-red-500">{params?.data?.status}</span>
                  ) : null}
                </div>
              );
            }}
          />

          <AgGridColumn
            field="orderTotal"
            headerName={`Total`}
            minWidth={100}
            cellStyle={{ paddingRight: 10 }}
            cellRendererFramework={(params) => {
              return (
                <div key={params?.data?.id}>
                  <p className="text-right w-[50px]">
                    {params?.data?.currencySymbol}{" "}
                    {parseFloat(params?.data?.orderTotal).toFixed(2)}
                  </p>
                </div>
              );
            }}
          />

          <AgGridColumn
            headerName="Date"
            minWidth={200}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <div key={params?.data?.id}>
                  <p>
                    {getTime(
                      params?.data?.orderTime,
                      params?.data?.storeTimeZone
                    )}
                  </p>
                </div>
              );
            }}
          />

          {/* <AgGridColumn
            headerName="Email"
            minWidth={100}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <>
                  <p>{params?.data?.userDTO?.email ? params.data?.userDTO?.email : "Nill"}</p>
                </>
              );
            }}
          /> */}

          <AgGridColumn
            headerName="Products"
            minWidth={300}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <div className="flex">
                  {params?.data?.orderItems?.map((item, index) => {
                    return (
                      <p
                        className="ml-0.5"
                        key={index}
                      >{`${item?.productName},`}</p>
                    );
                  })}
                </div>
              );
            }}
          />

          <AgGridColumn
            headerName="Receipt"
            minWidth={80}
            cellRendererFramework={(params) => <Action params={params} />}
          />

          <AgGridColumn
            headerName="Action"
            minWidth={80}
            sortable={true}
            cellRendererFramework={(params) => {
              //PAYMENT_PROCESSED

              return (
                <div key={params?.data?.id}>
                  {params?.data?.status === "PAYMENT_PROCESSED" ? (
                    <Tippy content="Request for refund">
                      <div className="flex w-16 h-10 items-center justify-center">
                        <button
                          onClick={() => refund(params?.data)}
                          className="border-2 border-gray-200 rounded-lg p-1 "
                        >
                          <RiRefund2Line className="w-5 h-5 cursor-pointer " />
                        </button>
                      </div>
                    </Tippy>
                  ) : params?.data?.status ===
                    "PAYMENT_CONFIRMATION_PENDING" ? (
                    <Tippy content="Verify for order payment">
                      <div className="flex w-16 h-10 items-center justify-center">
                        <button
                          onClick={() => confirmPending(params?.data)}
                          className="border-2 border-gray-200 rounded-lg p-1 "
                        >
                          <TiTick className="w-5 h-5 cursor-pointer " />
                        </button>
                      </div>
                    </Tippy>
                  ) : params?.data?.status === "PAYMENT_FAILED" ? (
                    <Tippy content="Request again">
                      <div className="flex w-16 h-10 items-center justify-center">
                        <button className="border-2 border-gray-200 rounded-lg p-1 ">
                          <BiRefresh className="w-5 h-5 cursor-pointer " />
                        </button>
                      </div>
                    </Tippy>
                  ) : params?.data?.status === "PAYMENT_PENDING" ? (
                    <Tippy content="Submit for order payment" className="z-100">
                      <div className="flex w-16 h-10 items-center justify-center">
                        <button
                          onClick={() => paymentPending(params?.data)}
                          className="border-2 border-gray-200 rounded-lg p-1 "
                        >
                          <MdOutlinePending className="w-5 h-5 cursor-pointer " />
                        </button>
                      </div>
                    </Tippy>
                  ) : (
                    <Tippy content="Request completed">
                      <div className="flex w-16 h-10 items-center justify-center">
                        <button className="border-2 border-gray-200 rounded-lg p-1 ">
                          <AiOutlineInteraction className="w-5 h-5 cursor-pointer" />
                        </button>
                      </div>
                    </Tippy>
                  )}
                </div>
              );
            }}
          />
        </AgGridReact>
      </div>
      <div className="absolute bottom-8 right-12 flex justify-center item-center">
        <Pagination
          count={
            allCount % 9 === 0
              ? parseInt(allCount / 9) || 9
              : parseInt(allCount / 9) + 1 || 9
          }
          showFirstButton
          showLastButton
          page={activePage}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
}

export default All;
