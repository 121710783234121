import React, { useEffect, useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  StepIcon,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Skeleton,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import CloseIcon from "@mui/icons-material/Close";
import { useGet } from "../../../../../../api/useGet";
import axios from "axios";
import { convertToTitleCase } from "../../../../../../utils/utils";
import { getTime } from "../../../../../../logic/useGetTime";

const CustomStepIcon = () => {
  return (
    <StepIcon>
      <span>⭐</span>
    </StepIcon>
  );
};

const PAYMENTSTATUS_COLORS = {
  PAYMENT_INITIATED: "#838383",
  PAYMENT_CONFIRMATION_PENDING: "#FA9600",
  PAYMENT_REQUIRES_ACTION: "#FA9600",
  PAYMENT_DISPUTED: "#F30B0B",
  PAYMENT_PROCESSED: "#006600",
  PAYMENT_PARTIALLY_PROCESSED: "#FA9600",
  PAYMENT_REJECTED: "#FA9600",
};

const PaymentStepperModal = ({ open, onClose, data }) => {
  const {
    isLoading,
    error,
    data: paymentData,
  } = useGet(
    `${process.env.REACT_APP_SHOPPER_URL}/orders/${data?.id}/order-payments-info`,
    ["merchantList", data]
  );
  console.log(
    "🚀 ~ file: PaymentProcessModal.jsx:149 ~ PaymentStepperModal ~ paymentData:",
    paymentData,
    isLoading,
    error
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        textAlign="center"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography sx={{ fontWeight: 700, fontSize: "1em" }}>
          PAYMENT HISTORY
        </Typography>
        <CloseIcon sx={{ cursor: "pointer" }} onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        {(isLoading ? [1, 2, 3, 4] : paymentData)?.map((step, index) => (
          <Step key={index}>
            <StepLabel>
              <div style={{ display: "flex" }}>
                <CircleIcon />
                {isLoading ? (
                  <Skeleton sx={{ width: "200px" }} />
                ) : (
                  <Typography
                    sx={{
                      ml: "2px",
                      fontWeight: 700,
                      // fontSize: 20,
                      color:
                        PAYMENTSTATUS_COLORS[step?.status] !== undefined
                          ? PAYMENTSTATUS_COLORS[step?.status]
                          : "-",
                    }}
                  >
                    {convertToTitleCase(step?.status)
                      ? convertToTitleCase(step?.status)
                      : step?.status}
                  </Typography>
                )}
              </div>
            </StepLabel>
            <StepContent sx={{ width: "400px" }}>
              <div>
                {isLoading ? (
                  <>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                  </>
                ) : (
                  <Typography component="ul" sx={{ mt: 1 }}>
                    <li>
                      Date:{" "}
                      {getTime(step?.transactionDate, data?.storeTimeZone)}
                    </li>
                    <li>Payment Provider: {step?.paymentProvider}</li>
                    {step?.status !== "PAYMENT_PROCESSED" && (
                      <li>Reason: {step?.reason}</li>
                    )}
                    <li>
                      Status:{" "}
                      {convertToTitleCase(step?.status)
                        ? convertToTitleCase(step?.status)
                        : step?.status}
                    </li>
                  </Typography>
                )}
                {/* Display payment status information */}
              </div>
            </StepContent>
          </Step>
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default PaymentStepperModal;
