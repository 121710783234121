const Languages1 = [
  { value: 'en_GB', label: 'English', code: 'en' },
  { value: 'de_DE', label: 'Deutsch', code: 'de' },
  { value: 'fr_FR', label: 'French', code: 'fr' },
]

const Languages2 = [
  { value: 'de_DE', label: 'Deutsch', code: 'de' },
  { value: 'fr_FR', label: 'French', code: 'fr' },
]

export{
  Languages1,
  Languages2
}


