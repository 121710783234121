import React, { useState, useEffect } from "react";
import Action from "./Action";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "react-toggle/style.css";
import Address from "./Address";
import Select from "react-select";
import DashboardRoute from "./DashboardRoute";
import Confirm from "./Confirm";
import moment from "moment";
import axios from "axios";
import NumberFormat from 'react-number-format';
import {useHistory} from 'react-router-dom'
import {toast} from 'react-toastify'

function All({ data }) {

  const [value, setValue] = useState(null);


  const [retailerStore, setRetailerStore] = useState();
  const [employeeList, setSelectEmployeeList] = useState();
  const history = useHistory();

 
  // const dataFilter = (data) => {
  //   var filterData = data?.filter(
  //     (el, index, self) => index === self.findIndex((t) => t.name === el.name)
  //   );
  //   return filterData;
  // };

  useEffect(() => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}/retail-stores?sort=createdDate,desc`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
    }).then((res) => {
      // setRetailerStore(dataFilter(res?.data));
      setRetailerStore(res?.data);
      //console.log("data: ", res?.data);
    }).catch(({ message }) => {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message)
      }
    })
  }, []);


  useEffect(async () => {
    if(value){
      const res = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/users?rootOrgId.equals=${value?.value}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      });
  
      setSelectEmployeeList(res?.data);
    }
  }, [value]);

  const changeHandler = (value) => {
    setValue(value);
  };


  const clear = () => {
    setSelectEmployeeList(null);
    setValue(null);
  };

  return (
    <div className=" w-full h_das_table select-none">
      <div className="flex h-10 w-full items-center mt-2 justify-between py-8 px-4">
        <input
          className="w-2/5 focus:outline-none py-1.5 px-2 border-2 border-gray-200 rounded-lg"
          placeholder="Search"
        />

        <div style={{ width: "40%" }}>
          <Select
            placeholder="Select Retailer store"
            className="text-left"
            maxMenuHeight={130}
            value={value}
            onChange={changeHandler}
            options={retailerStore?.map((item, index) => {
              return {
                label: item?.name,
                value: item?.rootOrgId,
                key: index,
              };
            })}
          />
        </div>
        <button
          onClick={clear}
          className="bg-green-600 py-1.5 px-5 mr-7 rounded text-white"
        >
          Clear filters
        </button>
      </div>

      <div className="ag-theme-alpine  w-full h-full text-left">
        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            resizable: true,
          }}
          suppressRowClickSelection={true}
          rowSelection={"multiple"}
          pagination={true}
          paginationPageSize={50}
          rowData={employeeList ? employeeList : data}
        >
          <AgGridColumn
            headerName="Name"
            field="firstName"
            minWidth={180}
            sortable={true}
            headerCheckboxSelection={true}
            headerCheckboxSelectionFilteredOnly={true}
            checkboxSelection={true}
            cellRendererFramework={(params) => (
              <DashboardRoute data={params?.data} />
            )}
          />
          <AgGridColumn field="login" minWidth={150} sortable={true} />
          <AgGridColumn field="email" minWidth={240} sortable={true} />

          <AgGridColumn
            headerName="User's Phone"
            minWidth={100}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <>
                  {/* <p>{params?.data?.userInfo?.phone}</p> */}
                  <NumberFormat displayType={'text'} value={params?.data?.userInfo?.phone} format="###-###-####"  />
                </>
              );
            }}
          />

          {/* <AgGridColumn
            field="status"
            minWidth={100}
            cellRendererFramework={(params) => <Confirm params={params} />}
          /> */}
        </AgGridReact>
      </div>
    </div>
  );
}

export default All;
