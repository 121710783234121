import { IoIosClose } from "react-icons/io";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TextField2 } from "../validation/TextField2";
import React, { useState, useMemo } from "react";
import { usePost } from "../../api/usePost";

let validate;
validate = Yup.object({
  name: Yup.string().required("Required").typeError("Not a Valid Input"),
});

function ModalCategory({ open, onCloseModal }) {
  const [title, setTitle] = useState("Product Category");

  const createModal = usePost(
    `${process.env.REACT_APP_BASE_URL}/categories`,
    "categoryList"
  );

  return (
    <>
      {open && (
        <div className="w-1/2 text-left select-none z-10 absolute top-1/2 left-1/2 ml-28 mt-4 transform -translate-x-1/2 -translate-y-1/2 ">
          <Formik
            initialValues={{
              name: "",
            }}
            validationSchema={validate}
            onSubmit={async (values) => {
              //console.log(values);
              onCloseModal();
              if (values.name !== "") {
                try {
                  createModal.mutate({
                    name: values.name,
                    rootOrgId: JSON.parse(
                      localStorage.getItem("dashboard-data")
                    )?.id,
                  });

                  if (createModal.isError === false) {
                    onCloseModal();
                  } else {
                    alert.error(
                      <div style={{ textTransform: "none" }}>
                        Please Check all Fields!
                      </div>
                    );
                  }
                } catch (error) {
                  alert.error(
                    <div style={{ textTransform: "none" }}>
                      you are offline!
                    </div>
                  );
                }
              }
            }}
          >
            {() => (
              <Form className="w-full bg-gray-100 border-2 border-dash rounded-lg shadow-lg ">
                <div className="w-full h-16 flex bg-dash text-white">
                  <div className="w-full h-full px-7 flex flex-col justify-center">
                    <h2 className="font-bold text-2xl">{`Create ${title}`}</h2>
                  </div>
                  <div className="w-16 px-4 h-full flex items-center">
                    <div className="w-full flex justify-end  ">
                      <IoIosClose
                        onClick={onCloseModal}
                        className="h-12 w-12 cursor-pointer"
                      />
                    </div>
                  </div>
                </div>

                <div className="w-11/12 mx-auto  my-2">
                  <div className="w-full h-20 flex mt-4 px-4 items-center">
                    <div className="flex   w-full items-center select-none">
                      <TextField2
                        label="Product Category..."
                        name="name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="w-full h-16 flex justify-end items-center pr-3.5 mt-2">
                    <button
                      type="submit"
                      className="bg-green-500 rounded-lg px-3 py-2  focus:outline-none text-white"
                    >
                      <span className="h-full flex items-center">
                        <svg
                          class="svg-icon"
                          viewBox="0 0 20 20"
                          width="24px"
                          height="24px"
                        >
                          <path
                            fill="#8A2BE2"
                            d="M19.404,6.65l-5.998-5.996c-0.292-0.292-0.765-0.292-1.056,0l-2.22,2.22l-8.311,8.313l-0.003,0.001v0.003l-0.161,0.161c-0.114,0.112-0.187,0.258-0.21,0.417l-1.059,7.051c-0.035,0.233,0.044,0.47,0.21,0.639c0.143,0.14,0.333,0.219,0.528,0.219c0.038,0,0.073-0.003,0.111-0.009l7.054-1.055c0.158-0.025,0.306-0.098,0.417-0.211l8.478-8.476l2.22-2.22C19.695,7.414,19.695,6.941,19.404,6.65z M8.341,16.656l-0.989-0.99l7.258-7.258l0.989,0.99L8.341,16.656z M2.332,15.919l0.411-2.748l4.143,4.143l-2.748,0.41L2.332,15.919z M13.554,7.351L6.296,14.61l-0.849-0.848l7.259-7.258l0.423,0.424L13.554,7.351zM10.658,4.457l0.992,0.99l-7.259,7.258L3.4,11.715L10.658,4.457z M16.656,8.342l-1.517-1.517V6.823h-0.003l-0.951-0.951l-2.471-2.471l1.164-1.164l4.942,4.94L16.656,8.342z"
                          ></path>
                        </svg>
                        <span className="px-2">Create {title}</span>
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
}

export default ModalCategory;
