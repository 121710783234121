import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";

import BackArrowButton from "../../../../../../components/buttons/BackarrowButton";

import { useGet } from "../../../../../../api/useGet";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { MerchantsDetails } from "../../../../../../utils/action_types";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./header.css";
import { getTime, getTimeOnly } from "../../../../../../logic/useGetTime";
import CarousalModal from "../dashboard/modal/CarousalModal";
import useStyles from "../../../../../../theme/variants/Button";
import CustomSearch from "../../../../../../components/searchbar";
import ProductEventSummaryModal from "../../shopperActivity/modal/ProductEventSummaryModal";
import { useOpeneventDataProvider } from "../context/OpeneventsContext";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#39393D" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const MatchEventHeader = ({
  filter,
  locationData,
  handleOpenModal,
  onChangeTabs,
  handleRefresh,
  opencartApiParams,
  setOpencartApiParams,
  selectedIndex,
  data,
  itemIndex,
  productEventSummary,
  openData,
}) => {
  console.log(
    "🚀 ~ file: index.jsx:94 ~ opencartApiParams:",
    opencartApiParams
  );
  const history = useHistory();

  const classes = useStyles();

  const { checkedRack, setCheckedRack, flag, setFlag } =
    useOpeneventDataProvider();

  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    width: 100,
    height: 100,
  };

  // Custom CSS styles for the slider container
  const sliderStyle = {
    width: "100px", // Set the desired width
    height: "90px", // Set the desired height
    // padding: "10px",
    // paddingTop: "0",
    border: "1px solid black",
  };

  const [openEntryCarousal, setOpenEntryCarousal] = useState(false);

  const [openExitCarousal, setOpenExitCarousal] = useState(false);

  const [open, setOpen] = useState(false);

  const entryData = useGet(
    `${process.env.REACT_APP_STORE_URL}/user-activities?sort=entryTime%2Casc&cartRef.equals=${filter}`,
    "entryData"
  );

  const exitData = useGet(
    `${process.env.REACT_APP_STORE_URL}/user-exit-activities?sort=exitTime%2Casc&cartRef.equals=${filter}`,
    "exitData"
  );

  useEffect(() => {
    // handleAllChecked();
    console.log("🚀 ~ file: index.jsx:145 ~ useEffect ~ flagOutSideIf:", flag);

    if (flag && productEventSummary) {
      console.log("🚀 ~ file: index.jsx:145 ~ useEffect ~ flagInsideIf:", flag);
      setFlag(false);
      let tempArr = [];
      productEventSummary?.shelfSummary?.forEach(
        (item) => (tempArr = [...tempArr, item?.scaleCode])
      );
      const tempData = opencartApiParams;
      tempData["scaleInternalCode.in"] = [...tempArr].join(",");
      setOpencartApiParams({ ...tempData });
      setCheckedRack([...tempArr]);
    }
  }, [productEventSummary]);

  const combineUserImagePaths = (data) =>
    data.reduce((combined, obj) => {
      if (obj.userImagePaths && Array.isArray(obj.userImagePaths)) {
        combined.push(...obj.userImagePaths);
      }
      return combined;
    }, []);

  const handleOpenEntryCarousalModal = () => setOpenEntryCarousal(true);
  const handleCloseEntryCarousalModal = () => setOpenEntryCarousal(false);

  const handleOpenExitCarousalModal = () => setOpenExitCarousal(true);
  const handleCloseExitCarousalModal = () => setOpenExitCarousal(false);

  function removeElementFromArray(arr, elementToRemove) {
    return arr.filter((item) => item !== elementToRemove);
  }

  const handleAllChecked = () => {
    let tempArr = [];
    productEventSummary?.shelfSummary?.forEach(
      (item) => (tempArr = [...tempArr, item?.scaleCode])
    );
    // const tempData = opencartApiParams;
    // tempData["scaleInternalCode.in"] = [...tempArr].join(",");
    // console.log(
    //   "🚀 ~ file: ProductEventSummaryTable.jsx:82 ~ handleFilter ~ tempData:",
    //   tempData
    // );
    // setOpencartApiParams({ ...tempArr });
    setCheckedRack([...tempArr]);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // console.log('')
    setOpen(false);
  };

  const handleOnChange = (e, row) => {
    let tempData = checkedRack;
    if (e.target.checked) {
      tempData.push(row?.scaleCode);
    } else {
      tempData = removeElementFromArray(tempData, row?.scaleCode);
    }
    setCheckedRack([...tempData]);
  };

  const handleFilter = () => {
    const tempData = opencartApiParams;
    const tempArr = [...new Set(checkedRack)];
    tempData["scaleInternalCode.in"] = tempArr.join(",");
    console.log(
      "🚀 ~ file: ProductEventSummaryTable.jsx:82 ~ handleFilter ~ tempData:",
      tempData
    );
    setOpencartApiParams({ ...tempData });
    setOpen(false);
  };

  const handleBack = () => history.goBack();

  return (
    <div className="w-full pl-8 pr-8">
      <div className="h-full bg-gray-200 rounded-lg p-2">
        <Grid container>
          {/* <Grid item xs={1}></Grid> */}
          <Grid item xs={1}>
            <Box
              display="flex"
              alignContent="center"
              height="100%"
              // justifyContent="space-between"
            >
              <Box
                display="flex"
                alignContent="center"
                // justifyContent="space-between"
              >
                <BackArrowButton onClick={() => handleBack()} />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignContent="center"
                justifyContent="center"
              >
                <img
                  src={
                    MerchantsDetails.hasOwnProperty(locationData?.storeCode)
                      ? MerchantsDetails?.[locationData?.storeCode]?.imgUrl
                      : "/images/noImage.jpg"
                  }
                  width="80px"
                  height="auto"
                  alt="/images/noImage.jpg"
                  // className="w-[100px] h-[100px]"
                  // className="mt-3"
                />
                <Typography variant="body2">
                  {entryData?.data?.[0]?.retailStoreCode}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3} padding="10px 20px" pl={4}>
            <div
              style={{
                display: "flex",
                // alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Typography variant="h5" mb={1}>
                <b>{entryData?.data?.[0]?.cartRef?.split("_")?.[1]}</b>
              </Typography>
              <div>
                <Tooltip
                  title={`Timezone: ${entryData?.data?.[0]?.storeTimeZone}`}
                  arrow
                >
                  <p>
                    <b>Entry : </b>
                    {` ${getTime(
                      entryData?.data?.[0]?.entryTime,
                      entryData?.data?.[0]?.storeTimeZone
                    )} (${entryData?.data?.[0]?.storeTimeZone})`}
                  </p>
                </Tooltip>
                <Tooltip
                  title={`Timezone: ${exitData?.data?.[0]?.storeTimeZone}`}
                  arrow
                  marginTop={2}
                >
                  <p>
                    <b>Exit : </b>
                    {` ${getTime(
                      exitData?.data?.[0]?.exitTime,
                      exitData?.data?.[0]?.storeTimeZone
                    )} (${exitData?.data?.[0]?.storeTimeZone})`}
                  </p>
                </Tooltip>
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <Grid
              container
              alignItems="center"
              justify="center"
              sx={{ height: "100%" }}
            >
              <Grid item xs={6} sx={{ height: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  {/* <Tooltip
                    title={`Timezone: ${entryData?.data?.[0]?.storeTimeZone}`}
                    arrow
                  > */}
                  <b>{`Entry`}</b>
                  {/* </Tooltip> */}

                  <div style={sliderStyle}>
                    {combineUserImagePaths(entryData?.data || [])?.length >
                    0 ? (
                      <Slider {...settings}>
                        {combineUserImagePaths(entryData?.data || [])?.map(
                          (image, j) => (
                            <div
                              key={j}
                              style={{ border: "1px solid black !important" }}
                              onClick={handleOpenEntryCarousalModal}
                            >
                              <img
                                src={"/images/noImage.jpg"}
                                alt=""
                                // height="80px"
                                // width="90px"
                                style={{
                                  width: "100px",
                                  height: "88px",
                                  // marginTop: "5px",
                                }}
                              />
                            </div>
                          )
                        )}
                      </Slider>
                    ) : (
                      <img
                        src="/images/noImage.jpg"
                        alt=""
                        height="70px"
                        width="80px"
                        // style={{ marginTop: "10px" }}
                        style={{
                          width: "100px",
                          height: "88px",
                          // marginTop: "5px",
                        }}
                      />
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item xs={6} sx={{ height: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  {/* <Tooltip
                    title={`Timezone: ${exitData?.data?.[0]?.storeTimeZone}`}
                    arrow
                  > */}
                  <b>{`Exit`}</b>
                  {/* </Tooltip> */}

                  <div style={sliderStyle}>
                    {combineUserImagePaths(exitData?.data || [])?.length > 0 ? (
                      <Slider {...settings}>
                        {combineUserImagePaths(exitData?.data || [])?.map(
                          (image, i) => (
                            <div key={i} onClick={handleOpenExitCarousalModal}>
                              <img
                                src={image}
                                alt=""
                                // height="70px"
                                // width="80px"
                                style={{
                                  width: "100px",
                                  height: "88px",
                                  // marginTop: "5px",
                                }}
                              />
                            </div>
                          )
                        )}
                      </Slider>
                    ) : (
                      <img
                        src="/images/noImage.jpg"
                        alt=""
                        height="70px"
                        width="80px"
                        // style={{ marginTop: "10px" }}
                        style={{
                          width: "100px",
                          height: "88px",
                          // marginTop: "5px",
                        }}
                      />
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} padding={1}>
            <div className="flex h-[100%] flex-col justify-between items-center">
              <Stack direction="row" spacing={1} alignItems="center">
                <IOSSwitch
                  defaultChecked
                  sx={{ m: 1 }}
                  checked={selectedIndex == 0}
                  onChange={(e) => {
                    // if (!e.target.checked) setSe
                    selectedIndex === 1 ? onChangeTabs(0) : onChangeTabs(1);
                  }}
                />
                <Typography>{`${
                  selectedIndex === 1 ? "Pending" : "Assigned"
                }`}</Typography>
              </Stack>
              <ProductEventSummaryModal
                productEventSummary={productEventSummary}
                opencartApiParams={opencartApiParams}
                setOpencartApiParams={setOpencartApiParams}
                open={open}
                handleOpen={handleOpen}
                handleOnChange={handleOnChange}
                handleFilter={handleFilter}
                checkedRack={checkedRack}
                setCheckedRack={setCheckedRack}
                handleClose={handleClose}
                handleAllChecked={handleAllChecked}
              />
            </div>
          </Grid>
          <Grid item xs={2} p={1}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // alignItems: "center",
                height: "100%",
                flexDirection: "column",
                // padding: "8px",
              }}
            >
              <Button
                variant="contained"
                className={classes.blueButton}
                onClick={handleOpenModal}
              >
                Product Recommendation
              </Button>
              <CustomSearch
                label="Search by Rack"
                name="scaleInternalCode.contains"
                value={opencartApiParams?.["scaleInternalCode.contains"]}
                sx={{ borderRadius: "8px" }}
                onChange={(e) =>
                  setOpencartApiParams({
                    ...opencartApiParams,
                    "scaleInternalCode.contains": e.target.value,
                  })
                }
                inputPropsStyle={{ borderRadius: "8px", background: "#fff" }}
              />
            </div>
          </Grid>
          <Grid item xs={2} p={2} pr={0}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // alignItems: "center",
                height: "100%",
                flexDirection: "column",
              }}
            >
              <Typography variant="body1">
                <b>Event Time: </b>
                {`${getTimeOnly(
                  locationData?.entryTime,
                  locationData?.storeTimeZone
                )} - ${getTimeOnly(
                  locationData?.checkoutDate,
                  locationData?.storeTimeZone
                )} - ${
                  locationData?.storeTimeZone
                    ? locationData?.storeTimeZone
                    : "CET"
                }`}{" "}
              </Typography>
              <Grid container>
                <Grid item xs={6}>
                  <div
                    style={{
                      background: "#fff",
                      width: "75px",
                      height: "35px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "10px",
                    }}
                  >
                    <p>{`${itemIndex + 1} / ${
                      openData?.length ? openData?.length : 0
                    }`}</p>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    className={classes.greyButton}
                    onClick={() => handleRefresh()}
                  >
                    Refresh
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
      {combineUserImagePaths(entryData?.data || [])?.length > 0 &&
        openEntryCarousal && (
          <CarousalModal
            open={openEntryCarousal}
            handleClose={handleCloseEntryCarousalModal}
            data={combineUserImagePaths(entryData?.data || [])}
          />
        )}
      {combineUserImagePaths(exitData?.data || [])?.length > 0 &&
        openExitCarousal && (
          <CarousalModal
            open={openExitCarousal}
            handleClose={handleCloseExitCarousalModal}
            data={combineUserImagePaths(exitData?.data || [])}
          />
        )}
    </div>
  );
};

export default MatchEventHeader;
