export const PAYMENT_STATUS = [
  { value: "PAYMENT_WAIVED", label: "Payment Waived" },
  { value: "PAYMENT_PENDING", label: "Payment Pending" },
  { value: "PAYMENT_PROCESSED", label: "Payment Processed" },
  { value: "PAYMENT_PARTIALLY_REFUNDED", label: "Payment Partially Refunded" },
  { value: "PAYMENT_REFUNDED", label: "Payment Refunded" },
  {
    value: "PAYMENT_CONFIRMATION_PENDING",
    label: "Payment Confirmation Pending",
  },
  { value: "PAYMENT_REJECTED", label: "Payment Rejected" },
  { value: "PAYMENT_FAILED", label: "Payment Failed" },
  { value: "PAYMENT_REQUIRES_ACTION", label: "Payment Requires Action" },
  // { value: "IN_PROCESS", label: "In-Progress" },
  // { value: "REFUND_PROCESSED", label: "Refund Processed" },
  // { value: "REFUND_APPROVED", label: "Refund Approved" },
  // { value: "REFUND_SUBMITTED", label: "Refund Submitted" },
  // { value: "REFUND_FAILED", label: "Refund Failed" },
  // { value: "REFUND_REJECTED", label: "Refund Rejected" },
  // { value: "REFUND_POSTED", label: "Refund Posted" },
  // { value: "REFUND_PAYMENT_CONFIRMATION_IN_PROCESS", label: "Confirmation In-Process" }
];

export const PAYMENT_STATUS2 = [
  "APPROVED",
  "IN_PROCESS",
  "PAYMENT_PENDING",
  "PAYMENT_CONFIRMATION_PENDING",
  "PAYMENT_REQUIRES_ACTION",
  "PAYMENT_REJECTED",
  "PAYMENT_PROCESSED",
  "PAYMENT_REFUND_INITIATED",
  "PAYMENT_REFUNDED",
  "PAYMENT_PARTIALLY_REFUNDED",
  "REFUND_PAYMENT_REJECTED",
  "PAYMENT_WAIVED",
  "PAYMENT_POSTED",
  "PAYMENT_DISPUTED",
  "PAYMENT_INITIATED",
  "PAYMENT_SUBMITTED",
  "PAYMENT_PARTIALLY_PROCESSED",
];

export const CARD_TYPES = [
  "AMERICAN EXPRESS",
  "COMDIRECT VISA",
  "DEBIT MASTERCARD",
  "DEBIT MASTERCARDA",
  "DINERS",
  "DKB VISA DEBIT",
  "GIROCARD",
  "MAESTRO",
  "MASTERCARD",
  "MCSTANDARDDEBIT",
  "VISA",
  "VISA CARD",
  "VISA CREDIT",
  "VISA DEBIT",
  "VISASTANDARDCREDIT",
  "VISASTANDARDDEBIT",
  "VPAY",
  "PAYPAL",
];

export const authorities = [
  "ROLE_SUPPORT_ADMIN",
  "ROLE_SYSTEM_SUPER_ADMIN",
  "ROLE_SYSTEM_ADMIN",
];

export const MerchantsDetails = {
  ANBAKO001: {
    imgUrl: "/images/bakoLogo.png",
  },
  ANHOODY001: {
    imgUrl: "/images/hoddyLogo.png",
  },
  ANTEGUT001: {
    imgUrl: "/images/teoLogo.png",
  },
  ANTEGUT002: {
    imgUrl: "/images/teoLogo.png",
  },
  ANQ1ENERGIE: {
    imgUrl: "/images/q1Logo.png",
  },
  ANQ1001: {
    imgUrl: "/images/q1Logo.png",
  },
  ANQ1ENERGIE001: {
    imgUrl: "/images/q1Logo.png",
  },
  "ATN-BAKO-001": {
    imgUrl: "/images/syltBacker.png",
  },
};

export const EXIT_RESONS = [
  "Wrong Entry Image",
  "Wrong exit image",
  "Exit Image  not available",
  "No Entry image was available",
  "Entry & Exit recommendation not available",
  "Entry Image Not Clear",
  "Exit Image Not Clear",
  "Others",
];

export const CART_PROCESSING_STARTED = "CART_PROCESSING_STARTED";
export const OPEN_ENTRY_EVENT_STARTED = "OPEN_ENTRY_EVENT_STARTED";
export const OPEN_ENTRY_EVENT_CONFIRMATION = "OPEN_ENTRY_EVENT_CONFIRMATION";
export const CART_PROCESSING_REJECTED = "CART_PROCESSING_REJECTED";
export const CART_PROCESSING_APPROVED = "CART_PROCESSING_APPROVED";
export const MENUAL_EXIT = "MANUAL_EXIT";
export const REFUND_REQUESTED = "REFUND_REQUESTED";
export const REFUND_APPROVED = "REFUND_APPROVED";
