import React, { useState } from "react";
import { GrView } from "react-icons/gr";
import { FiEdit } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { useGet } from "../../../api/useGet";
import axios from "axios";
import { useQuery } from "react-query";
import {toast} from 'react-toastify'

function Action({ params, viewUrl, editUrl }) {
  const history = useHistory();

  const edit = () => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}/users/${params?.data?.login}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
    }).then((res) => {
      history.push({
        pathname: editUrl,
        state: { data: res?.data },
      });
    }).catch(({ message }) => {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message)
      }
    })
  };

  const view = async () => {
    history.push({
      pathname: viewUrl,
      state: { data: params.data },
    });
  };

  return (
    <>
      <div className="flex w-16 h-10 items-center">
        <div className="border-2 border-gray-200 rounded-l-lg p-1 ">
          <GrView className="w-5 h-5 cursor-pointer " onClick={view} />
        </div>
        <div className="border-2 border-gray-200 rounded-r-lg p-1">
          <FiEdit className="w-5 h-5 cursor-pointer" onClick={edit} />
        </div>
      </div>
    </>
  );
}

export default Action;
