import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import Confirm from "./Confirm";
import ModalImg from "../../../../../../../ModalImg";
import UploadImage from "../../../../components/UploadImage";
import { usePost } from "../../../../../../../../api/usePost";
import { useGet } from "../../../../../../../../api/useGet";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import ModalMapEsl from "../../../../../../../modal/edit/ModalMapEsl";
import { useAlert } from "react-alert";
import { usePut } from "../../../../../../../../api/usePut";

function EslView() {
  const [status, setStatus] = useState(false);

  const statusChecked = (e) => {
    setStatus(e.target.checked);
  };
  const {
    state: { data: lData },
  } = useLocation();
  const history = useHistory();
  const alert = useAlert();

  const [data, setData] = useState();

  const { data: productViewData } = useGet(
    `${process.env.REACT_APP_BASE_URL}/esl/${lData?.id}`,
    `eslDetail`
  );

  useEffect(() => {
    setData(productViewData);
  }, [productViewData]);

  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [priceSummary, setPriceSummary] = useState(false);
  const onOpenPrice = () => setPriceSummary(true);
  const onClosePrice = () => setPriceSummary(false);

  const [updatePrice, setUpdatePrice] = useState(false);
  const onOpenUpdate = () => setUpdatePrice(true);
  const onCloseUpdate = () => setUpdatePrice(false);

  // const [pushEsl, setPushEsl] = useState(false);
  // const onPushEsl = () => setPushEsl(true);
  // const onCloseEsl = () => setPushEsl(false);

  const [addLocal, setAddLocal] = useState(false);
  const onOpenLocal = () => setAddLocal(true);
  const onCloseLocal = () => setAddLocal(false);

  const [edit, setEdit] = useState(false);
  const onOpenEdit = () => setEdit(true);
  const onCloseEdit = () => setEdit(false);

  const [image, setImage] = useState(false);
  const onOpenImage = () => setImage(true);
  const onCloseImage = () => setImage(false);

  const mapEsl = () => {
    onOpenEdit();
  };

  const unmapProductFromEsl = usePut(
    `${process.env.REACT_APP_BASE_URL}/esl/${data?.id}/unmap-scale`,
    "esl"
  );

  const unmapEsl = () => {
    try {
      if (data?.productId === null || data?.productId === undefined) {
        alert.error(
          <div style={{ textTransform: "none" }}>No produts are mapped.</div>
        );
      } else {
        unmapProductFromEsl.mutate(data);
        if (unmapProductFromEsl.isError === false) {
          alert.success(
            <div style={{ textTransform: "none" }}>Successfully Unmapped!</div>
          );
          history.goBack();
        } else {
          alert.error(
            <div style={{ textTransform: "none" }}>Unable to unmap.</div>
          );
        }
      }
    } catch (error) {
      alert.error(
        <div style={{ textTransform: "none" }}>you are offline!</div>
      );
    }
    setEdit(false);
  };

  const pushProductToEsl = usePost(
    `${process.env.REACT_APP_BASE_URL}/esl/${data?.id}/esl-push`,
    "product"
  );
  const pushToEsl = () => {
    try {
      if (data?.productId === null || data?.productId === undefined) {
        alert.error(
          <div style={{ textTransform: "none" }}>
            Product is not mapped. Please map product and then push
          </div>
        );
      } else {
        pushProductToEsl.mutate();
        if (pushProductToEsl.isError === false) {
          alert.success(
            <div style={{ textTransform: "none" }}>Successfully Pushed!</div>
          );
        } else {
          alert.error(
            <div style={{ textTransform: "none" }}>Unable to push to ESL</div>
          );
        }
      }
    } catch (error) {
      alert.error(
        <div style={{ textTransform: "none" }}>you are offline!</div>
      );
    }
    setEdit(false);
  };

  return (
    <>
      {data ? (
        <div className="w-full h-screen text-left select-none flex">
          <div className="w-full h-full flex flex-col justify-center items-center bg-gray-200">
            <div className="w-full h-12 flex bg-gray-100  items-center">
              <BiArrowBack
                onClick={() => history.goBack()}
                className="h-6 w-6 cursor-pointer ml-5"
              />
              {/* <h2 className="font-bold px-6 text-xl">{data?.name}</h2> */}
            </div>

            <div className="w-full h_dasView flex p-2">
              <div className="w-full h-full bg-white rounded-lg p-2.5">
                <div className="w-full border-b-2">
                  <h2 className="text-black text-bold text-lg select-none">
                    ESL View
                  </h2>
                </div>

                {/* <div className="w-full flex justify-between">
                  <div className="w-48 flex h-20 mt-1 items-center select-none ">
                    <button
                      onClick={editFn}
                      className="focus:outline-none bg-gray-200 border-2 border-black ml-1 hover:bg-grey text-grey-darkest font-bold py-1 px-4 rounded inline-flex items-center"
                    >
                      <FaEdit className="w-4 h-4" />
                      <span className="mx-2">Edit</span>
                    </button>

                  </div>
                  
                </div> */}

                {/* <PushEsl
                  open={pushEsl}
                  onCloseModal={onCloseEsl}
                  productViewData={productViewData}
                /> */}

                <div className="h-3/4 mt-3  scrollbar-thin scrollbar-thumb-gray-900  scrollbar-track-gray-100">
                  <div className="border-t-2 border-gray-200 py-4 px-4">
                    <div className="grid grid-cols-4 gap-4">
                      <div className="font-bold">Esl Barcode</div>
                      <div>{data?.eslBarCode}</div>
                    </div>
                  </div>
                  <div className="border-t-2 border-gray-200 py-4 px-4">
                    <div className="grid grid-cols-4 gap-4">
                      <div className="font-bold">Retailer Store</div>
                      <div>{data?.storeName}</div>
                    </div>
                  </div>

                  <div className="border-t-2 border-gray-200 py-4 px-4">
                    <div className="grid grid-cols-4 gap-4">
                      <div className="font-bold">TemplateType </div>
                      <div>{data?.templateType}</div>
                    </div>
                  </div>

                  <div className="border-t-2 border-gray-200 py-4 px-4">
                    <div className="grid grid-cols-4 gap-4">
                      <div className="font-bold">Status </div>
                      <div>
                        {data?.activeStatus === true ? "Active" : "InActive"}
                      </div>
                    </div>
                  </div>

                  <hr className="hr_style" />
                  <div className="py-4 px-4">
                    <div className="font-bold">Mapped Product</div>
                    <div className="mt-2">
                      <table>
                        <tr>
                          <td className="font-bold">Product Name</td>
                          <td>&nbsp;:&nbsp;</td>
                          <td>{data?.productName}</td>
                        </tr>
                        <tr>
                          <td className="font-bold">Product BarCode</td>
                          <td>&nbsp;:&nbsp;</td>
                          <td>{data?.productBarcode}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <hr className="hr_style" />
                  <div className="py-4 px-4 flex">
                    <p>
                      <span className="font-bold">Actions</span>
                    </p>
                    <div className="w-3/10 h-16 flex justify-center items-center px-3 mt-5 pr-8">
                      <button
                        type="button"
                        className="bg-green-500 rounded-lg px-6 py-3  focus:outline-none text-white"
                        onClick={mapEsl}
                      >
                        <span className="h-full flex items-center">
                          <span className="px-2">Map ESL</span>
                        </span>
                      </button>
                    </div>
                    <div className="w-3/10 h-16 flex justify-center items-center px-3 mt-5 pr-8">
                      <button
                        type="button"
                        className="bg-green-500 rounded-lg px-6 py-3  focus:outline-none text-white"
                        onClick={unmapEsl}
                      >
                        <span className="h-full flex items-center">
                          <span className="px-2">Unmap ESL</span>
                        </span>
                      </button>
                    </div>
                    <div className="w-3/10 h-16 flex justify-center items-center px-3 mt-5 pr-8">
                      <button
                        type="button"
                        className="bg-green-500 rounded-lg px-6 py-3  focus:outline-none text-white"
                        onClick={pushToEsl}
                      >
                        <span className="h-full flex items-center">
                          <span className="px-2">Push to ESL</span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ModalMapEsl open={edit} onCloseModal={onCloseEdit} esl={data} />
        </div>
      ) : (
        <div className="w-full h-full flex justify-center items-center">
          <ClipLoader size={150} />
        </div>
      )}
    </>
  );
}

export default EslView;
