import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Checkbox } from "@mui/material";
import TableNoData from "../../../../../../components/Table/TableNoData";
import { useState } from "react";

const columns = [
  { id: "rack", label: "Rack", align: "left" },
  { id: "shelf", label: "Shelf", align: "left" },
  {
    id: "noOfEvents",
    label: "# of Product events",
    align: "left",
  },
  {
    id: "scaleCode",
    label: "Select",
    align: "center",
  },
];

export default function ProductEventSummaryTable({
  productEventSummary,
  opencartApiParams,
  setOpencartApiParams,
  handleOnChange,
  checkedRack,
  setCheckedRack,
  handleAllChecked,
}) {
  React.useEffect(() => {
    const tempCheck = opencartApiParams["scaleInternalCode.in"]?.split(",");
    setCheckedRack([...new Set(tempCheck)]);
  }, [opencartApiParams]);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 500, minWidth: "500px" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  // align={column.align}
                  // style={{ minWidth: column.minWidth }}
                  sx={{ bgcolor: "#CCCCCC", fontWeight: 800 }}
                >
                  <div className="flex items-center">
                    {column.label === "Select" && (
                      <Checkbox
                        sx={{ p: 0, mr: 1 }}
                        defaultChecked={true}
                        checked={
                          productEventSummary?.shelfSummary?.length ===
                          checkedRack?.length
                        }
                        onChange={(e) => {
                          if (
                            productEventSummary?.shelfSummary?.length ===
                            checkedRack?.length
                          ) {
                            setCheckedRack([]);
                          } else {
                            handleAllChecked();
                          }
                        }}
                      />
                    )}
                    <p>{column.label}</p>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {productEventSummary?.shelfSummary?.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  <TableCell>{row?.rack}</TableCell>
                  <TableCell>{row?.shelf}</TableCell>
                  <TableCell align="center">{row?.noOfEvents}</TableCell>
                  <TableCell>
                    <Checkbox
                      sx={{ p: 0 }}
                      checked={checkedRack.includes(row?.scaleCode)}
                      onChange={(e) => handleOnChange(e, row)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
            <TableNoData
              isNotFound={productEventSummary?.shelfSummary?.length === 0}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
