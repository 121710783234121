import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useGet } from "../../../../../../api/useGet";
import ModalUom from "../../../../../modal/ModalUom";
import ModalTax from "../../../../../modal/ModalTax";
import ModalBrand from "../../../../../modal/ModalBrand";
import ModalCategory from "../../../../../modal/ModalCategory";
import axios from "axios";
import { BiArrowBack } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";
import useStyles from "../../../../../../theme/variants/Button";

function TableStr({
  title,
  url,
  viewUrl,
  editUrl,
  listUrl,
  getName,
  Enabled,
  All,
  Disabled,
  // disabledUrl,
  Modal,
  dashboard,
  reatilerList,
}) {
  const [click, setClicked] = useState("all");
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  function findField(filterData) {
    var tempArray = uniq(filterData);
    return tempArray.filter(
      (value, index) => tempArray.indexOf(value) === index
    );
  }

  function uniq(rawData) {
    var arr = [];
    rawData?.map((item) => {
      reatilerList ? arr.push(item?.retailStore?.name) : arr.push(item.name);
    });
    return arr;
  }

  const options = (e) => {
    const type = e.target.name;
    switch (type) {
      case "all":
        setClicked(type);
        break;
      case "enabled":
        setClicked(type);
        break;
      case "disabled":
        setClicked(type);
        break;
      case "initialized":
        setClicked(type);

      case "notInitialized":
        setClicked(type);
      default:
    }
  };

  const classes = useStyles();

  return (
    <div className="w-full h-full shadow-lg select-none">
      <div
        className={`w-full ${
          dashboard ? "h-16" : "h-12"
        } flex justify-between p-6 items-center bg-gray-100`}
      >
        <div className="w-full h-12 flex bg-gray-100  items-center justify-between">
          <div className="flex items-center">
            <BiArrowBack
              onClick={() => history.goBack()}
              className="h-6 w-6 cursor-pointer"
            />
            <h2 className="font-bold text-2xl ml-3">{title}</h2>
          </div>
          <Button
            className={classes.greenButton}
            onClick={() => history.push("machine/create")}
          >
            Create Machine
          </Button>
        </div>
      </div>

      {Modal === "ModalUom" ? (
        <ModalUom open={open} onCloseModal={onCloseModal} />
      ) : Modal === "ModalTax" ? (
        <ModalTax open={open} onCloseModal={onCloseModal} />
      ) : Modal === "ModalBrand" ? (
        <ModalBrand open={open} onCloseModal={onCloseModal} />
      ) : Modal === "ModalCategory" ? (
        <ModalCategory open={open} onCloseModal={onCloseModal} />
      ) : null}

      <div className="w-full h-10 p-2  -mt-2 ">
        <div className="h-10 w-full  flex justify-start ">
          <button
            onClick={options}
            name="all"
            className={`mx-4 border-b-2 ${
              click === "all" ? "border-blue-500" : null
            } focus:outline-none`}
          >
            All
          </button>

          <button
            onClick={options}
            name="enabled"
            className={`mx-4 border-b-2 ${
              click === "enabled" ? "border-blue-500" : null
            } focus:outline-none`}
          >
            Enabled
          </button>

          <button
            onClick={options}
            name="disabled"
            className={`mx-4 border-b-2 ${
              click === "disabled" ? "border-blue-500" : null
            } focus:outline-none`}
          >
            Disabled
          </button>
        </div>
      </div>

      {click === "enabled" ? (
        <Enabled />
      ) : click === "disabled" ? (
        <Disabled />
      ) : click === "all" ? (
        <All listUrl={listUrl} viewUrl={viewUrl} editUrl={editUrl} />
      ) : null}
    </div>
  );
}

export default TableStr;
