import React, { useState } from "react";
import { FiLogOut, FiGlobe } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Cookies from "universal-cookie";

import { BroadcastChannel } from "broadcast-channel";
import { Languages1 } from "../Languages";

const logoutChannel = new BroadcastChannel("logout");

function Header() {
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(!open);
  const history = useHistory();
  const cookies = new Cookies();
  const { t } = useTranslation();

  const detectedLan = (code) => {
    return Languages1?.filter((item) => {
      return item?.code === code;
    });
  };

  //console.log("lan: ", detectedLan(cookies.get("i18next"))[0])

  const [select, setSelect] = useState({
    lng: {
      value: cookies.get("i18next"),
      label: cookies.get("i18next") ? detectedLan(cookies.get("i18next"))[0]?.label : detectedLan("en")[0]?.label,
    },
  });

  const onSelectChange = (value) => {
    i18next.changeLanguage(value?.code);
    setSelect(value);
  };

  const logout = () => {
    logoutChannel.postMessage({ logoutMessage: "oh!, Autonomo logout" });
    localStorage.clear();
    history.push("/login");
  };
  const profile =
    "https://images.unsplash.com/photo-1612966809559-dac0bed38b6a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80";


  
  return (
    <>
      <div className="h-[60px] flex w-full justify-end items-center bg-nav pr-10 select-none">
        <div className="flex w-auto  h-12 items-center justify-between ">
          <div style={{ width: '120px' }}>
            <Select
              value={select && select.lng}
              onChange={onSelectChange}
              options={Languages1} />
          </div>

          <span className="text-white capitalize font-bold text-lg mx-4">
            {localStorage.getItem("user-name")}
          </span>

          <img
            src={profile}
            onClick={onOpenModal}
            className="h-10 w-10  rounded-full cursor-pointer"
            alt="profile"
          />
        </div>
      </div>
      {open && (
        <div className="w-32  p-3 z-10 absolute flex top-14 right-0  items-center bg-white select-none">
          <FiLogOut />
          <button className="focus:outline-none mx-2" onClick={logout}>
            Logout
          </button>
        </div>
      )}
    </>
  );
}

export default Header;

export const logoutAllTabsEventListener = () => {
  logoutChannel.onmessage = (event) => {
    logoutChannel.close();
    console.log(event.logoutMessage);
  };
};
