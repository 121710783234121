import React, { useState, useMemo } from "react";
import { IoIosClose } from "react-icons/io";
import VideoController from "../custom-control/VideoController";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";

function VideoModal({ open, onCloseModal, videoUrl }) {
  const [title, setTitle] = useState("Brand");

  return (
    <>
      {open && (
        // <div className="w-[100vw] h-[100vh]  bg-black bg-opacity-80 absolute inset-0 z-10 flex items-center justify-center">
        //   <div className="w-full h-full text-left select-none z-10 absolute top-0 left-0 transform">
        //     <div className="w-[50%] h-[94%] bg-gray-100 border-2 rounded-lg shadow-lg flex justify-center">
        //       <div className=" absolute w-[50%] h-8 flex text-gray-500 px-4 py-4  justify-end">
        //         <IoIosClose
        //           onClick={onCloseModal}
        //           className="h-10 w-10 cursor-pointer"
        //         />
        //       </div>

        //       <div className="w-[82%] h-[82%]">
        //         <VideoController url={videoUrl} />
        //       </div>
        //     </div>
        //   </div>
        // </div>
        <Dialog open={open} onClose={onCloseModal}>
          {/* <DialogTitle>Modal Title</DialogTitle> */}
          <DialogContent>
            <VideoController url={videoUrl} />
          </DialogContent>
          <DialogActions>
            <Button onClick={onCloseModal} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default VideoModal;
