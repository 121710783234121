import React from "react";

function Address({ params }) {
  // console.log("pa: ", params);
  return (
    <div>
      <h2>{params?.data?.retailStore?.name}</h2>
    </div>
  );
}

export default Address;
