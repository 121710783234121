import React from "react";
import { useGet } from "../../../../../../../api/useGet";

function MerchantDetails({ id }) {
  const { data } = useGet(
    `${process.env.REACT_APP_BASE_URL}/orgs/${id}`,
    "mechantDetails"
  );

  return <p>{data?.name}</p>;
}

export default MerchantDetails;
