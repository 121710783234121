import React from "react";
import { GrView } from "react-icons/gr";
import { FiEdit } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {toast} from 'react-toastify'

function Action({ params, url, viewUrl, editUrl, orgList }) {
  const history = useHistory();

  const fetchMerchant = async (url) => {

    try {
      const res = await axios({
        method: "GET",
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      });
  
      return res;
    } catch ({message}) {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message)
      }
    }
    
  };

  const edit = async () => {

    // const res = await fetchMerchant(
    //   `${process.env.REACT_APP_BASE_URL}/api/retail-stores/${params?.data?.id}`
    // );


    // let initialValues = {
    //   name: res?.data?.name ? res?.data?.name : "",
    //   email: res?.data?.email ? res?.data?.email : "",
    //   contactNo: res?.data?.address?.contactNumber
    //     ? res?.data?.address?.contactNumber
    //     : "",
    //   street1: res?.data?.address?.street1 ? res?.data?.address?.street1 : "",
    //   street2: res?.data?.address?.street2 ? res?.data?.address?.street2 : "",
    //   city: res?.data?.address?.cityOrVillage
    //     ? res?.data?.address?.cityOrVillage
    //     : "",
    //   administrativeArea: res?.data?.address?.administrativeArea1
    //     ? res?.data?.address?.administrativeArea1
    //     : "",

    //   description: res?.data?.description ? res?.data?.description : "",
    //   website: res?.data?.website ? res?.data?.website : "",
    //   latitude: res?.data?.address?.lat ? res?.data?.address?.lat : "",
    //   country: res?.data?.address?.country ? res?.data?.address?.country : "",
    //   longitude: res?.data?.address?.lng ? res?.data?.address?.lng : "",
    //   state: res?.data?.address?.state ? res?.data?.address?.state : "",
    //   contactFirstName: res?.data?.address?.contactFirstName
    //     ? res?.data?.address?.contactFirstName
    //     : "",
    //   contactMiddleName: res?.data?.address?.contactMiddleName
    //     ? res?.data?.address?.contactMiddleName
    //     : "",
    //   contactLastName: res?.data?.address?.contactLastName
    //     ? res?.data?.address?.contactLastName
    //     : "",
    //   zip: res?.data?.address?.zipCode ? res?.data?.address?.zipCode : "",
    //   storeType: res?.data?.storeType ? res?.data?.storeType : "",
    // };

    history.push({
      pathname: editUrl,
      data: params.data,
     //id: params?.data?.id,
    });
  };

  const view = async () => {
    const res = await fetchMerchant(
      `${process.env.REACT_APP_BASE_URL}/api/retail-stores/${params?.data?.id}`
    );
    const merchant = await fetchMerchant(
      `${process.env.REACT_APP_BASE_URL}/api/orgs/${res?.data?.rootOrgId}`
    );
    history.push({
      pathname: viewUrl,
      //search: "?query=abc",-
      state: { data: res.data, merchant: merchant?.data?.name },
    });
  };
  return (
    <div className="flex w-16 h-10 items-center">
      <div className="border-2 border-gray-200 rounded-l-lg p-1 ">
        <GrView className="w-5 h-5 cursor-pointer " onClick={view} />
      </div>
      <div className="border-2 border-gray-200 rounded-r-lg p-1">
        <FiEdit className="w-5 h-5 cursor-pointer" onClick={edit} />
      </div>
    </div>
  );
}

export default Action;
