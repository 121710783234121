import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { getTime } from "../../../../../../../logic/useGetTime";
import { Tab } from "@headlessui/react";
import moment from "moment";
import { useGet } from "../../../../../../../api/useGet";
import axios from "axios";
import { toast } from "react-toastify";
import SelectTabEvents from "../dashboard/SelectTabEvents";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function EntryDecision() {
  const history = useHistory();
  const {
    state: { data: entryEvent },
  } = useLocation();
  const [select, setSelect] = useState();
  const [unselect, setUnSelect] = useState();
  const [decisionComment, setDecisionComment] = useState();
  let isIRAssigned = false;
  let isCameraAssigned = false;

  const onChangeInput = (value, name) => {
    if (name === "decisionComment") {
      setDecisionComment(value);
    }
  };

  const entryTaskUrl = `${process.env.REACT_APP_STORE_URL}/entry-tasks/user-activity/${entryEvent?.id}`;

  const irListUrl = `${
    process.env.REACT_APP_STORE_URL
  }/ir-activities?startTime.greaterThanOrEqual=${moment(entryEvent?.entryTime)
    .utc()
    .subtract(20, "seconds")
    .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")}&startTime.lessThanOrEqual=${moment(
    entryEvent?.entryTime
  )
    .utc()
    .add(30, "seconds")
    .format(
      "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
    )}&sort=startTime,asc&eventStatus.in=DETECTED,ASSIGNED`;

  const cameraListUrl = `${
    process.env.REACT_APP_STORE_URL
  }/camera-activities?reportedTime.greaterThanOrEqual=${moment(
    entryEvent?.entryTime
  )
    .utc()
    .subtract(2, "minutes")
    .format("YYYY-MM-DDTHH:mm:ss.S[Z]")}&reportedTime.lessThanOrEqual=${moment(
    entryEvent?.entryTime
  )
    .utc()
    .add(5, "minutes")
    .format(
      "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
    )}&sort=reportedTime,asc&eventStatus.in=DETECTED,ASSIGNED&type.equals=USER_ENTRY_ACTIVITY`;

  let [categories] = [{ "IR Events": [], "Camera Events": [] }];

  const { data: irEventList } = useGet(irListUrl, "irList");
  const { data: cameraEventList } = useGet(cameraListUrl, "cameraList");
  let { data: entryTask } = useGet(entryTaskUrl, "entryTaskDetails");

  for (let key in entryTask?.baseActivities) {
    if (key === "CameraActivity") {
      isCameraAssigned = true;
    }

    if (key === "IRActivity") {
      isIRAssigned = true;
    }
  }

  [categories] = [
    {
      "IR Events": irEventList?.length > 0 ? irEventList : [],
      "Camera Events": cameraEventList?.length > 0 ? cameraEventList : [],
    },
  ];

  const onSelect = (data) => {
    setSelect(data);

    let payload = {
      ...entryTask,
    };

    if (data !== null && data !== "" && data?.eventType.includes("IR")) {
      const baseActivities = entryTask?.baseActivities;
      baseActivities["IRActivity"] = data?.id;
      payload = {
        ...entryTask,
        baseActivities,
      };
    } else if (
      data !== null &&
      data !== "" &&
      data?.eventType.includes("Camera")
    ) {
      const baseActivities = entryTask?.baseActivities;
      baseActivities["CameraActivity"] = data?.id;
      payload = {
        ...entryTask,
        baseActivities,
      };
    }

    axios({
      method: "PUT",
      url: `${process.env.REACT_APP_STORE_URL}/entry-tasks/${entryTask?.id}/assign-event`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
      data: payload,
    })
      .then((res) => {
        entryTask = res;
        for (let key in entryTask?.baseActivities) {
          if (key === "CameraActivity") {
            isCameraAssigned = true;
          }

          if (key === "IRActivity") {
            isIRAssigned = true;
          }
        }
      })
      .catch(({ message }) => {
        if (message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          toast.error(message);
        }
      });
  };

  const onUnSelect = (data) => {
    setUnSelect(data);
    let payload = {
      ...entryTask,
    };

    if (data !== null && data?.eventType.includes("IR")) {
      const baseActivities = entryTask?.baseActivities;
      baseActivities["IRActivity"] = null;
      payload = {
        ...entryTask,
        baseActivities,
      };
    } else if (data !== null && data?.eventType.includes("Camera")) {
      const baseActivities = entryTask?.baseActivities;
      baseActivities["CameraActivity"] = null;
      payload = {
        ...entryTask,
        baseActivities,
      };
    }

    axios({
      method: "PUT",
      url: `${process.env.REACT_APP_STORE_URL}/entry-tasks/${entryTask?.id}/un-assign-event`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
      data: payload,
    })
      .then((res) => {
        entryTask = res;
        for (let key in entryTask?.baseActivities) {
          if (key === "CameraActivity") {
            isCameraAssigned = true;
          }

          if (key === "IRActivity") {
            isIRAssigned = true;
          }
        }
      })
      .catch(({ message }) => {
        if (message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          toast.error(message);
        }
      });
  };

  const onApprove = () => {
    const payload = {
      ...entryTask,
      decisionComment: decisionComment,
    };

    axios({
      method: "PUT",
      url: `${process.env.REACT_APP_STORE_URL}/entry-tasks/${entryTask?.id}/approve-task`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
      data: payload,
    })
      .then((res) => {
        entryTask = res;
        history.goBack();
      })
      .catch(({ message }) => {
        if (message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          toast.error(message);
        }
      });
  };

  return (
    <div className="h-[calc(100vh-60px)] w-full bg-gray-300">
      <div className="h-8 flex items-center ">
        <div className="absolute mx-4 flex items-center">
          <BiArrowBack
            onClick={() => history.goBack()}
            className="h-6 w-6 cursor-pointer"
          />
        </div>
        <div className="w-full flex justify-center items-center">
          <h2 className="font-bold text-2xl">Review Entry Decision</h2>
        </div>
      </div>
      <div className="w-full h-[calc(100vh-57px-35px)] flex rounded-t-xl pt-8 bg-white space-x-[10px]">
        <div className="w-[73%] h-full flex flex-col">
          <div className="cell_border_black">
            <div className="flex w-full font-bold ml-5 mt-5">Gate Event</div>
            <p>
              <span className="font-bold mt-1">Gate Timestamp : </span>{" "}
              {getTime(entryEvent.entryTime, entryEvent?.storeTimeZone)}
            </p>
            <p className="mb-5">
              <span className="font-bold mt-1">Cart Ref : </span>{" "}
              {entryEvent?.cartRef}
            </p>
          </div>
          <div className="h-[56vh] w-[4/5%] ml-5 pt-2 pr-6 space-y-2 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 ">
            <Tab.Group>
              <Tab.List className="flex space-x-1 rounded-xl bg-gray-200 p-1">
                {Object.keys(categories).map((category, idx) => (
                  <Tab
                    key={idx}
                    className={({ selected }) =>
                      classNames(
                        "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-black-700",
                        "ring-white ring-opacity-60 ring-offset-1 ring-offset-black focus:outline-none focus:ring-1",
                        selected
                          ? "bg-white shadow"
                          : "text-black-100 hover:bg-white/[0.12] hover:text-black"
                      )
                    }
                  >
                    {category}
                  </Tab>
                ))}
              </Tab.List>
              <Tab.Panels className="mt-2">
                {Object.values(categories).map((activityEvents, idx) => (
                  <Tab.Panel
                    key={idx}
                    className={classNames(
                      "rounded-xl bg_white p-3",
                      "ring-black ring-opacity-60 ring-offset-1 ring-offset-black focus:outline-none focus:ring-1"
                    )}
                  >
                    {activityEvents.map((inputEvent, index) => {
                      return (
                        <div
                          key={index}
                          className="border-b-4 border-gray-400 space-y-2 h-52 flex flex-col justify-between"
                        >
                          <SelectTabEvents
                            data={inputEvent}
                            onSelect={onSelect}
                            select={select}
                            onUnSelect={onUnSelect}
                            name={
                              inputEvent?.eventType.includes("IR")
                                ? "irimagePaths"
                                : "userImagePaths"
                            }
                            idName={"id"}
                            assignedData={
                              inputEvent?.eventType.includes("IR")
                                ? entryTask?.baseActivities?.["IRActivity"]
                                : entryTask?.baseActivities?.["CameraActivity"]
                            }
                          />
                          <div className="h-10 flex justify-center items-end">
                            {inputEvent?.eventTime && (
                              <p>
                                <span className="font-bold">Event Time : </span>
                                {getTime(
                                  inputEvent?.eventTime,
                                  inputEvent?.storeTimeZone
                                )}
                              </p>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
        <div className="w-[25.5%]">
          <div className="cell_border_black">
            <div className="w-full mt-3">
              <p>
                <span className="font-bold mt-1">Final Review</span>
              </p>
              <hr className="hr_style" />
              <p>
                <span className="font-bold mt-1">Entry Decision</span>
              </p>
              <br />
              <p>
                <span className="font-bold mr-5">Gate TimeStamp: </span>
                {getTime(entryEvent?.eventTime, entryEvent?.storeTimeZone)}
              </p>
              <br />
              {isIRAssigned === true ? (
                <p>
                  <span className="font-bold mr-5">IR Event Assigned</span>
                </p>
              ) : (
                ""
              )}
              <br />
              {isCameraAssigned === true ? (
                <p>
                  <span className="font-bold mr-5">Camera Event Assigned</span>
                </p>
              ) : (
                ""
              )}
              <br />
              <textarea
                className="font-bold w-80 ml-2 focus:outline-none border-2"
                onChange={(e) => {
                  onChangeInput(e.target.value, "decisionComment");
                }}
                placeholder="Enter comments"
                maxLength={250}
              />
              <br />
              <br />
              <button
                className={
                  "bg-green-400 w-80 rounded-full font-bold h-8 focus:outline-none text-white mb-5"
                }
                onClick={() => onApprove()}
              >
                Approve Task
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EntryDecision;
