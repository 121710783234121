import React from "react";
import TableStr from "./TableStr";
import Enabled from "./options/Enabled";
import Disabled from "./options/Disabled";
import All from "./options/All";

function Merchant() {
  return (
    <div className="h-full w-full select-none">
      <TableStr
        title={"Merchant"}
        url={"/admin/merchant/newMerchant"}
        viewUrl={"/admin/merchant/view"}
        editUrl={"/admin/merchant/edit"}
        listUrl={`${process.env.REACT_APP_BASE_URL}/orgs?sort=createdDate,desc`}
        getName={"merchantList"}
        Enabled={Enabled}
        Disabled={Disabled}
        All={All}
      />
    </div>
  );
}

export default Merchant;
