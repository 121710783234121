import React from "react";
import { GrView } from "react-icons/gr";
import { FiEdit } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { MdDelete } from "react-icons/md";

function Action({ params, viewUrl, editUrl }) {
  const history = useHistory();

  const edit = () => {
    history.push({
      pathname: editUrl,
      state: { data: params.data },
    });
  };

  const view = () => {
    history.push({
      pathname: viewUrl,
      state: { data: params.data },
    });
  };

  const deleteFn = () => {};
  
  return (
    <>
      <div className="flex w-16 h-10 items-center">
        <div className="border-2 border-gray-200 rounded-l-lg p-1 ">
          <GrView className="w-5 h-5 cursor-pointer " onClick={view} />
        </div>
        <div className="border-2 border-gray-200 rounded-r-lg p-1">
          <FiEdit className="w-5 h-5 cursor-pointer" onClick={edit} />
        </div>
        {/* <div className="border-2 border-gray-200 rounded-r-lg p-1">
          <MdDelete className="w-5 h-5 cursor-pointer" onClick={deleteFn} />
        </div> */}
      </div>
    </>
  );
}

export default Action;
