import React from "react";
import TableStr from "./TableStr";
import Enabled from "./options/Enabled";
import Disabled from "./options/Disabled";
import All from "./options/All";
import { useTranslation } from 'react-i18next'

function Product() {
  const { t } = useTranslation()
  return (
    <div className="h-full w-full ">
    
      <TableStr
        title={t('product')}
        url={"/admin/merchant/dashboard/productManagement/product/CreateProduct"}
        viewUrl={"/admin/merchant/dashboard/productManagement/product/view"}
        editUrl={"/admin/merchant/dashboard/productManagement/product/edit"}
        listUrl={`${process.env.REACT_APP_BASE_URL}/_search/products?rootOrgId.equals=${parseInt(
          JSON.parse(localStorage.getItem("dashboard-data"))?.id)}`}  // &sort=createdDate,desc
        getName={"productList"}
        Enabled={Enabled}
        Disabled={Disabled}
        All={All}
        showButton={true}
        dashboard={true}
      />
    </div>
  );
}

export default Product;
