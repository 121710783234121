import React from "react";
import { GrView } from "react-icons/gr";
import { FiEdit } from "react-icons/fi";
import {MdEdit} from "react-icons/md";
import { useHistory } from "react-router-dom";

function Action({ params, url, viewUrl, editUrl }) {
  const history = useHistory();

  const edit = async () => {
    history.push({
      pathname: editUrl,
      state: { data: params.data },
    });
  };

 
    const view = () => {
      history.push({
        pathname: viewUrl,
        state: { data: params.data },
      });
    };
  
  return (
    <>
      <div className="flex w-16 h-10 items-center">
        {/* <div className="border-2 border-gray-200 rounded-lg p-1 ">
          <GrView className="w-5 h-5 cursor-pointer " onClick={view} />
        </div> */}
        <div className=" p-1">
          <MdEdit size={26} className="cursor-pointer" onClick={edit} />
        </div>
      </div>
    </>
  )
}

export default Action;
