import React from "react";

function Address({ params }) {
 // console.log("pa: ", params);
  return (
    <div>
      {params?.data?.addresses?.map((data) => {
        return(
          <h2>{`${data?.street1 ? `${data?.street1},` : ""}
          ${data?.street2 ? `${data?.street2},` : ""}
          ${data?.administrativeArea ? `${data?.administrativeArea},` : ""}
          ${data?.city ? `${data?.city},` : ""}
          ${data?.state ? `${data?.state},` : ""}
          ${data?.country ? `${data?.country}` : ""}
          ${data?.zipCode ? `- ${data?.zipCode}` : ""}`}</h2>
        )
      })}
    </div>
  );
}

export default Address;
