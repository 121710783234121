import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TextField2 } from "../../../../../../../validation/TextField2";
import { TextField3 } from "../../../../../../../validation/TextField3";
import { useHistory } from "react-router-dom";
import { MdCancel } from "react-icons/md";
import Select from "react-select";
import { useAlert } from "react-alert";
import { usePost } from "../../../../../../../../api/usePost";
import Toggle from "react-toggle";
import { TextArea } from "../../../../../../../validation/TextArea";
import { TextArea2 } from "../../../../../../../validation/TextArea2";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { useGet } from "../../../../../../../../api/useGet";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const statusVouch = [
  { label: "Draft", value: "DRAFT" },
  { label: "Active", value: "ACTIVE" },
  { label: "Closed", value: "CLOSED" },
  { label: "Suspended", value: "SUSPENDED" },
];

let validate;
validate = Yup.object({
  name: Yup.string().required("Required"),
  voucherCode: Yup.string().required("Required"),
  description: Yup.string(),
  discountPercentage: Yup.number(),
});

function CreateVoucher() {
  const { t } = useTranslation();
  const alert = useAlert();
  const history = useHistory();

  const [input, setInput] = useState({
    startDate: null,
    endDate: null,
  });

  const changeInputHandler = (value, name) => {
    setInput({ ...input, [name]: value });
  };

  const [select, setSelect] = useState({
    products: [],
    status: "",
    retailStore: "",
  });

  const handleSelect = (value, name) => {
    console.log("v: ", value);
    setSelect({ ...select, [name]: value });
  };

  const { data: retailerList } = useGet(
    `${
      process.env.REACT_APP_BASE_URL
    }/retail-stores?rootOrgId.equals=${parseInt(
      JSON.parse(localStorage.getItem("dashboard-data"))?.id
    )}`,
    "retailerList"
  );

  const createVoucher = usePost(
    `${process.env.REACT_APP_BASE_URL}/vouchers`,
    "voucherList"
  );

  return (
    <>
      <div className="w-full h-screen text-left select-none flex">
        <div className="w-full h-full flex justify-center items-center p-2  ">
          <Formik
            initialValues={{
              name: "",
              description: "",
              voucherCode: "",
              discountPercentage: "",
            }}
            validationSchema={validate}
            onSubmit={async (values) => {
              console.log("v: ", select);
              if (values.name !== "" && select?.currency?.code !== "") {
                var body = {
                  name: values.name,
                  description: values.description,
                  voucherCode: values.voucherCode,
                  discountPercentage: values.discountPercentage,
                  startDate: moment(input?.startDate).format("YYYY-MM-DD"),
                  endDate: moment(input?.endDate).format("YYYY-MM-DD"),

                  storeId: {
                    id: select.referenceType.value,
                  },

                  rootOrgId: parseInt(
                    JSON.parse(localStorage.getItem("dashboard-data"))?.id
                  ),
                };

                createVoucher.mutate(body);

                if (createVoucher.isIdle === true) {
                  history.goBack();
                  alert.success(
                    <div style={{ textTransform: "none" }}>
                      Successfully Added!
                    </div>
                  );
                } else {
                  alert.error(
                    <div style={{ textTransform: "none" }}>
                      Please Check all Fields!
                    </div>
                  );
                }
              }
            }}
          >
            {({ onSubmit }) => (
              <Form className="w-full mx-auto h-full bg-white rounded-lg shadow-lg border border-black ">
                <div className="w-full h-12 flex border-b border-black justify-center items-center ">
                  <h2 className="font-bold text-xl">
                    {`${t("createNew")} ${t("Voucher")}`}
                  </h2>

                  <div className="absolute right-8">
                    <MdCancel
                      onClick={() => history.goBack()}
                      className="cursor-pointer"
                      size={28}
                    />
                  </div>
                </div>

                <div className="w-full h-[78vh] scrollbar-thin scrollbar-thumb-gray-100 scrollbar-track-gray-300 ">
                  <div className="w-full  px-12 py-10 space-y-4">
                    <div className=" grid grid-cols-2 gap-x-12 gap-y-6 mt-1">
                      {/*  first row   */}

                      <TextField3
                        label={t("Voucher Name")}
                        placeholder="Enter Voucher Name"
                        name="name"
                        type="text"
                      />

                      <TextField3
                        label="Voucher Code"
                        placeholder="Enter Voucher Code"
                        name="voucherCode"
                        type="text"
                      />
                    </div>

                    <div className="grid grid-cols-1 gap-y-6 mt-1">
                      <div className="flex w-full items-center select-none">
                        <TextArea2
                          label={t("description")}
                          placeholder="Enter the Voucher Description"
                          name="description"
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-2 gap-x-12 gap-y-6 mt-1">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <div className="flex items-center space-x-4">
                          <label className="w-[30%]" for="Start Date">
                            Start Date
                          </label>
                          <DatePicker
                            label="Start Date"
                            mask="____/__/__"
                            value={input?.startDate}
                            onChange={(newValue) =>
                              changeInputHandler(newValue, "startDate")
                            }
                            // renderInput={(params) => (
                            //   <TextField {...params} className="w-full" />
                            // )}
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <div className="flex justify-center items-center w-[70%] bg-[#D9D9D9] py-2 px-6">
                                <input
                                  ref={inputRef}
                                  {...inputProps}
                                  className="focus:outline-none bg-transparent"
                                />
                                {InputProps?.endAdornment}
                              </div>
                            )}
                            //disableOpenPicker={true}
                          />
                        </div>

                        <div className="flex items-center space-x-4">
                          <label className="w-[30%]" for="Start Date">
                            End Date
                          </label>
                          <DatePicker
                            label="End Date"
                            mask="____/__/__"
                            value={input?.endDate}
                            onChange={(newValue) =>
                              changeInputHandler(newValue, "endDate")
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <div className="flex justify-center items-center w-[70%] bg-[#D9D9D9] py-2 px-6">
                                <input
                                  ref={inputRef}
                                  {...inputProps}
                                  className="focus:outline-none bg-transparent"
                                />
                                {InputProps?.endAdornment}
                              </div>
                            )}
                            //disableOpenPicker={true}
                          />
                        </div>
                      </LocalizationProvider>
                    </div>

                    <div className="grid grid-cols-2 gap-x-12 gap-y-6 mt-1">
                      <div className="flex items-center space-x-4">
                        <label className="w-[30%]" for="Start Date">
                          {t("Products")}
                        </label>
                        <Select
                          className="w-[70%]"
                          placeholder="Enter Product Barcode"
                          maxMenuHeight={162}
                          value={select.products}
                          isMulti
                          onChange={(value) => handleSelect(value, "products")}
                          styles={{
                            control: (style) => ({
                              ...style,
                              backgroundColor: "#D9D9D9",
                            }),
                          }}
                          options={retailerList?.map((item, index) => {
                            return {
                              label: item.name,
                              value: item.id,
                            };
                          })}
                        />
                      </div>

                      <TextField3
                        label="Discount Percentage"
                        placeholder="Enter Discount Percentage"
                        name="discountPercentage"
                        type="number"
                      />
                    </div>

                    <div className="grid grid-cols-2 gap-x-12 gap-y-6 mt-1">
                      <div className="flex items-center space-x-4">
                        <label className="w-[30%]" for="Start Date">
                          {t("retailer store")}
                        </label>
                        <Select
                          className="w-[70%]"
                          placeholder="Select the Retailer Store"
                          maxMenuHeight={162}
                          value={select.retailerStore}
                          onChange={(value) =>
                            handleSelect(value, "retailerStore")
                          }
                          styles={{
                            control: (style) => ({
                              ...style,
                              backgroundColor: "#D9D9D9",
                            }),
                          }}
                          options={retailerList?.map((item, index) => {
                            return {
                              label: item.name,
                              value: item.id,
                            };
                          })}
                        />
                      </div>

                      <div className="flex items-center space-x-4">
                        <label className="w-[30%]" for="Start Date">
                          Status
                        </label>
                        <Select
                          className="w-[70%]"
                          placeholder="Select the Voucher Status"
                          maxMenuHeight={162}
                          value={select.status}
                          onChange={(value) => handleSelect(value, "status")}
                          styles={{
                            control: (style) => ({
                              ...style,
                              backgroundColor: "#D9D9D9",
                            }),
                          }}
                          options={statusVouch}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center items-center mt-6">
                    <button
                      type="submit"
                      onClick={onSubmit}
                      className="bg-green-600 rounded-lg px-10 py-1  focus:outline-none text-white"
                    >
                      {`${t("create")}`}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default CreateVoucher;
