import React, { useState } from "react";
import ModalImg from "./ModalImg";
import NoImage from "../../../../../../Images/noImage.jpg";

function ProductEventImages({ url, index, allImages }) {
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  var preImageUrl = ""
  var postImageUrl = ""

  if (allImages?.length > 0) {
    const preImage = allImages.filter(ele => ele.tag === 'PRE')
    if (preImage !== null && preImage !== undefined && preImage.length > 0) {
        preImageUrl = preImage[0]?.imageUrl
    }

    const postImage = allImages.filter(ele => ele.tag === 'POST')
    if (postImage !== null && postImage !== undefined && postImage.length > 0) {
        postImageUrl = postImage[0]?.imageUrl
    }
  }


  return (
    <div className="mx-1.5 w-40 h-44" key={index}>
      {url ? (
        <img
          src={url}
          alt="event"
          className="w-28 h-44 rounded cursor-pointer border-2 border-black"
          onClick={onOpenModal}
        />
      ) : (
        <img
          src={NoImage}
          alt="event"
          className="w-28 h-44 rounded cursor-pointer border-2 border-black"
         // onClick={onOpenModal}
        />
      )}

      <p className="font-bold relative bottom-6 text-blue-400 w-full text-left pl-1">{`Image# : ${
        index + 1
      }`}</p>

      {url && <ModalImg open={open} onCloseModal={onCloseModal} preImg={preImageUrl} postImg={postImageUrl} />}
      
    </div>
  );
}

export default ProductEventImages;
