import React from "react";
import moment1 from "moment-timezone";

export const getTime = (time, format) => {
  if (format === "CET") {
    return moment1(time).tz("CET").format("YYYY-MM-DD HH:mm:ss ");
  } else if (format === "IST") {
    return moment1(time).format("YYYY-MM-DD HH:mm:ss ");
  } else if (format === "UTC") {
    return moment1(time).tz("UTC").format("YYYY-MM-DD HH:mm:ss ");
  } else {
    return moment1(time).tz("CET").format("YYYY-MM-DD HH:mm:ss ");
  }
};

export const getUtcTime = (time, format) => {
  if (format === "CET") {
    return moment1(time).tz("CET").format("YYYY-MM-DDTHH:mm:ss");
  } else if (format === "IST") {
    return moment1(time).utc().format("YYYY-MM-DDTHH:mm:ss");
  } else {
    return moment1(time).tz("CET").format("YYYY-MM-DDTHH:mm:ss");
  }
};

export const getTimeOnly = (time, format) => {
  if (format === "CET") {
    return moment1(time).tz("CET").format("HH:mm:ss ");
  } else if (format === "IST") {
    return moment1(time).format("HH:mm:ss ");
  } else {
    return moment1(time).tz("CET").format("HH:mm:ss ");
  }
};


export const getTimeOnlyWithMs = (time, format) => {
  if (format === "CET") {
    return moment1(time).tz("CET").format("HH:mm:ss.SSS");
  } else if (format === "IST") {
    return moment1(time).format("HH:mm:ss.SSS");
  } else {
    return moment1(time).tz("CET").format("HH:mm:ss.SSS");
  }
};

export const getTimeWithoutYear = (time, format) => {
  if (format === "CET") {
    return moment1(time).tz("CET").format("DD MMM, HH:mm:ss");
  } else if (format === "IST") {
    return moment1(time).format("DD MMM, HH:mm:ss");
  } else {
    return moment1(time).tz("CET").format("DD MMM, HH:mm:ss");
  }
};