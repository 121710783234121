import React, { useState, useMemo } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TextField2 } from "../../../../../../../validation/TextField2";
import { useHistory } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import Select from "react-select";
import { usePost } from "../../../../../../../../api/usePost";
import { toast } from "react-toastify";
import Toggle from "react-toggle";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import moment from "moment";
import AddDynamically from "../../../../../../../../components/AddDynamically";

let validate;
validate = Yup.object({
  campaignName: Yup.string().required("Required"),
  productName: Yup.string().required("Required"),
  barcode: Yup.number().required("Required"),
  discountPercent: Yup.number().required("Required"),
  category: Yup.string().required("Required"),
});

const campaignType = [
  { label: "SURGE", value: "SURGE" },
  { label: "PURGE", value: "PURGE" },
];

const customStyles = {
  control: (base) => ({
    ...base,
    height: 55,
    minHeight: 35,
  }),
};

function CreateSchedule() {
  const [title, setTitle] = useState("Offer");

  const history = useHistory();

  const [status, setStatus] = useState(false);

  const [select, setSelect] = useState({
    campaignType: "",
  });

  const [schedule, setSchedule] = useState([
    {
      applicableDays: [],
      startTime: null,
      endTime: null,
    },
  ]);

  const [input, setInput] = useState({
    startDate: null,
    endDate: null,
  });

  const changeInputHandler = (value, name) => {
    setInput({ ...input, [name]: value });
  };

  const onSelectChange = (value, name) => {
    setSelect({ ...select, [name]: value });
  };

  const statusChecked = (e) => {
    setStatus(e.target.checked);
  };

  const {
    mutate: createOffer,
    isError,
    isSuccess,
  } = usePost(
    `${process.env.REACT_APP_BASE_URL}/price-engine-configurations`,
    "offerList"
  );

  return (
    <>
      <div className="w-full h-[100vh] flex">
        <div className="w-full h-full flex justify-center items-center bg-gray-200 ">
          <Formik
            initialValues={{
              campaignName: "",
              productName: "",
              barcode: "",
              discountPercent: "",
            }}
            validationSchema={validate}
            onSubmit={(values) => {
              try {
                const payload = {
                  rootOrgId: parseInt(
                    JSON.parse(localStorage.getItem("dashboard-data"))?.id
                  ),
                  status: status,
                  campaignName: values.campaignName,
                  barCode: values.barcode,
                  productName: values.productName,
                  category: values.category,
                  campaignType: select.campaignType.value,
                  discountPercent: values.discountPercent,
                  startDate: moment(input?.startDate).format("YYYY-MM-DD"),
                  endDate: moment(input?.endDate).format("YYYY-MM-DD"),
                  priceEngineSchedules: schedule.map((el) => {
                    return {
                      startTime: moment(el?.startTime).format("HH:mm"),
                      endTime: moment(el?.endTime).format("HH:mm"),
                      applicableDays: el.applicableDays.map((day) => day.value),
                    };
                  }),
                };
                createOffer(payload);

                if (isError === false) {
                  history.goBack();
                }

                if (isError) {
                  toast.error("Something went wrong");
                }
              } catch (error) {
                toast.error(error.message);
              }
            }}
          >
            {({ onSubmit }) => (
              <Form className="w-11/12 mx-auto h-[94%] bg-white rounded-lg shadow-lg ">
                <div className="w-full h-[55px] flex bg-dash rounded-t-lg text-white ">
                  <div className="w-10 p-2 h-full flex items-center">
                    <BiArrowBack
                      onClick={() => history.goBack()}
                      className="h-10 w-10 cursor-pointer"
                    />
                  </div>
                  <div className="w-full h-full px-7 flex flex-col justify-center">
                    <h2 className="font-bold text-2xl">{`Create ${title}`}</h2>
                  </div>
                </div>

                <div className="w-11/12 h-[calc(94%-55px)] py-2 mx-auto my-8 px-6 space-y-4 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
                  <div className="w-full grid grid-cols-2 gap-x-12 gap-y-12">
                    <div className="">
                      <TextField2
                        label="Campaign Name"
                        name="campaignName"
                        type="text"
                      />
                    </div>

                    <div>
                      <Select
                        options={campaignType}
                        placeholder="Campaign Type"
                        maxMenuHeight={162}
                        value={select.campaignType}
                        styles={customStyles}
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                        onChange={(value) =>
                          onSelectChange(value, "campaignType")
                        }
                      />
                    </div>
                  </div>

                  <div className="w-full grid grid-cols-2 gap-x-12 gap-y-12">
                    <div className="">
                      <TextField2
                        label="Product Name"
                        name="productName"
                        type="text"
                      />
                    </div>

                    <div>
                      <TextField2
                        label="Product Barcode"
                        name="barcode"
                        type="number"
                      />
                    </div>
                  </div>

                  <div className="w-full grid grid-cols-2 gap-x-12 gap-y-12">
                    <div className="">
                      <TextField2
                        label="Category"
                        name="category"
                        type="text"
                      />
                    </div>

                    <div>
                      <TextField2
                        label="Discount Percent"
                        name="discountPercent"
                        type="number"
                      />
                    </div>
                  </div>

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <div className="w-full grid grid-cols-2 gap-x-12 gap-y-12">
                      <div>
                        <DatePicker
                          label="Start Date"
                          mask="____/__/__"
                          value={input?.startDate}
                          onChange={(newValue) =>
                            changeInputHandler(newValue, "startDate")
                          }
                          renderInput={(params) => (
                            <TextField {...params} className="w-full" />
                          )}
                          disableOpenPicker={true}
                        />
                      </div>
                      <div>
                        <DatePicker
                          label="End Date"
                          mask="____/__/__"
                          value={input?.endDate}
                          onChange={(newValue) =>
                            changeInputHandler(newValue, "endDate")
                          }
                          renderInput={(params) => (
                            <TextField {...params} className="w-full" />
                          )}
                          disableOpenPicker={true}
                        />
                      </div>
                    </div>
                  </LocalizationProvider>

                  <div className="w-full flex justify-start">
                    <p className="font-bold">Create Mutiple Schedule : </p>
                  </div>

                  <AddDynamically
                    schedule={schedule}
                    setSchedule={setSchedule}
                  />

                  <div className="w-full grid grid-cols-2 gap-x-12 gap-y-12">
                    <div className="flex justify-start items-center">
                      <span className=" mr-4 text-gray-500">Status</span>
                      <Toggle
                        onChange={statusChecked}
                        checked={status}
                        //icons={{ checked: null, unchecked: null }}
                        className="react-switch"
                      />
                    </div>

                    <div className="flex justify-end items-center -mt-4">
                      <button
                        type="submit"
                        onClick={onSubmit}
                        className="bg-green-500 rounded-lg px-6 py-2 focus:outline-none text-white"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default CreateSchedule;
