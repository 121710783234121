import React, { useState, useMemo, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import Select from "react-select";
import { useAlert } from "react-alert";
import Toggle from "react-toggle";
import { usePost } from "../../../../../../../../api/usePost";
import { useGet } from "../../../../../../../../api/useGet";
import { TextField2 } from "../../../../../../../validation/TextField2";
import { TextArea } from "../../../../../../../validation/TextArea";
import ImageUploader from "react-images-upload";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const customStyles = {
  control: (base) => ({
    ...base,
    height: 55,
    minHeight: 35,
  }),
};

const options = [
  { value: "PROD", label: "PROD" },
  { value: "Dev", label: "Dev" },
];

const isDepositRequired = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

const netWeightUOM = [
  { value: "gm", label: "gm" },
  { value: "Lt", label: "Lt" },
  { value: "kg", label: "kg" },
  { value: "ml", label: "ml" },
];

const referenceUOM = [
  { value: "gm", label: "gm" },
  { value: "Lt", label: "Lt" },
  { value: "kg", label: "kg" },
  { value: "ml", label: "ml" },
];

const unitOfMeasurement = [
  { value: "MMT", label: "MMT" },
  { value: "cm", label: "cm" },
];

const pricingType = [
  { value: "PER_PIECE_BASIS", label: "Per Piece Basis" },
  { value: "PER_GRAM_BASIS", label: "Per  Gram Basis" },
];
const currencyType = [
  { value: "€", label: "Euro", code: "EUR" },
  { value: "£", label: "Pound", code: "GBP" },
  { value: "$", label: "US Dollar", code: "USD" },
];

const skuType = [
  { value: "STANDARD_SKU", label: "STANDARD_SKU" },
  { value: "NON_STANDARD_SKU", label: "NON-STANDARD_SKU" },
];

const packageType = [
  { value: "Box", label: "Box" },
  { value: "Packet", label: "Packet" },
];

let validate;
validate = Yup.object({
  name: Yup.string().required("Required"),
  description: Yup.string(),
  // title: Yup.string().required("Required"),
  unitWeight: Yup.number().required("Required"),
  netWeight: Yup.number(),
  referenceWeight: Yup.number(),
  referencePrice: Yup.number(),
  depositAmount: Yup.number(),
  countryOfOrigin: Yup.string(),
  tradingClassification: Yup.string(),
  barCode: Yup.string().required("Required"),
  hsnCode: Yup.string(),
  purchasePrice: Yup.number().required("Required"),
  sellPrice: Yup.number().required("Required"),
  height: Yup.number(),
  width: Yup.number(),
  length: Yup.number(),
  depth: Yup.number(),
  unitOfMeasurement: Yup.string()
});

function CreateProduct(props) {
  const { t } = useTranslation();
  const alert = useAlert();
  const history = useHistory();
  const [status, setStatus] = useState(false);
  const [vegMark, setVegMark] = useState(false);
  const [isRefWeightForDisplayEnabled, setReferenceWeight] = useState(false);

  const [select, setSelect] = useState({
    brandType: "",
    packageType: "",
    supplierType: "",
    taxType: "",
    depositType: "",
    tradeClass: "",
    unitType: "",
    netType: "",
    referenceType: "",
    priceType: "",
    productCategory: "",
    productSubCategory: "",
    sku: { value: "STANDARD_SKU", label: "STANDARD_SKU" },
    currency: "",
    unitOfMeasurement: ""
  });

  //select
  const statusChecked = (e) => {
    setStatus(e.target.checked);
  };

  const handleSelect = (action, e) => {
    const name = e.name;

    setSelect({
      ...select,
      [name]: { value: action.value, label: action.label, code: action.code },
    });
  };

  //post
  const createMerchant = usePost(
    `${process.env.REACT_APP_BASE_URL}/products`,
    "productList"
  );

  const pCatList = useGet(
    `${
      process.env.REACT_APP_BASE_URL
    }/categories?parentId.specified=false&rootOrgId.equals=${
      JSON.parse(localStorage.getItem("dashboard-data"))?.rootOrgId
    }`,
    "productCatrgoriesList"
  );

  const supplierList = useGet(
    `${
      process.env.REACT_APP_BASE_URL
    }/suppliers?page=0&sort=createdDate&rootOrgId.equals=${parseInt(
      JSON.parse(localStorage.getItem("dashboard-data"))?.id
    )}`,
    "supplierList"
  );

  const brandList = useGet(
    `${process.env.REACT_APP_BASE_URL}/brands?rootOrgId.equals=${parseInt(
      JSON.parse(localStorage.getItem("dashboard-data"))?.id
    )}`,
    "brandList"
  );

  const uomList = useGet(
    `${
      process.env.REACT_APP_BASE_URL
    }/unit-of-measures?sort=createdDate,desc&rootOrgId=${parseInt(
      JSON.parse(localStorage.getItem("dashboard-data"))?.id
    )}`,
    "uomList"
  );

  const taxList = useGet(
    `${
      process.env.REACT_APP_BASE_URL
    }/tax-categories?sort=createdDate,desc&rootOrgId=${parseInt(
      JSON.parse(localStorage.getItem("dashboard-data"))?.id
    )}`,
    "taxList"
  );

  const [subCat, setSubCat] = useState(null);

  useEffect(() => {
    if (select?.productCategory?.value) {
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/categories?parentId.equals=${
          select?.productCategory?.value
        }&rootOrgId.equals=${
          JSON.parse(localStorage.getItem("dashboard-data"))?.rootOrgId
        }`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      })
        .then((res) => {
          setSubCat(res?.data);
        })
        .catch(({ message }) => {
          if (message === "Request failed with status code 401") {
            history.push("/login");
          } else {
            toast.error(message);
          }
        });
    }
  }, [select?.productCategory?.value]);

  const id = parseInt(JSON.parse(localStorage.getItem("dashboard-data"))?.id);

  const { data: productInfoManagement } = useGet(
    `${process.env.REACT_APP_BASE_URL}/_search/org-configurations?page=0&size=10&configName.equals=PRODUCT_INFO_MANAGEMENT&rootOrgId.equals=${id}`,
    "productInfoManagement"
  );

  useEffect(() => {
    if (productInfoManagement?.length > 0 && productInfoManagement?.[0]?.configValue?.toLowerCase() === 'external') history.goBack()
  }, [productInfoManagement])

  return (
    <>
      <div className="w-full h-screen text-left select-none flex ">
        <div className="w-full h-full flex justify-center items-center p-4 bg-gray-200 ">
          <Formik
            initialValues={{
              name: "",
              description: "",
              // title: "",
              unitWeight: "",
              referenceWeight: "",
              referencePrice: "",
              depositAmount: 0,
              countryOfOrigin: "",
              tradingClassification: "",
              netWeight: "",
              barCode: "",
              hsnCode: "",
              purchasePrice: "",
              sellPrice: "",
              height: "",
              width: "",
              depth: "",
              length: "",
              unitOfMeasurement: "",
            }}
            validationSchema={validate}
            onSubmit={async (values) => {
              if (values.name !== "" && select?.currency?.code !== "") {
                var body = {
                  name: values.name,
                  description: values.description,
                  title: values.name,
                  referenceWeight: values.referenceWeight,
                  referencePrice: values.referencePrice,
                  unitWeight: values.unitWeight,
                  depositAmount: values.depositAmount,
                  countryOfOrigin: values.countryOfOrigin,
                  tradingClassification: values.tradingClassification,
                  netWeight: values.netWeight,
                  barCode: values.barCode,
                  isoCurrencyCode: select?.currency?.code
                    ? select?.currency?.code
                    : "",
                  currencySymbol: select?.currency?.value,
                  packageType: select.packageType.value
                    ? select.packageType.value
                    : "BOX",
                  vegMark: vegMark,
                  isRefWeightForDisplayEnabled: isRefWeightForDisplayEnabled,
                  hsnCode: values.hsnCode,
                  status: status,
                  rootOrgId: parseInt(
                    JSON.parse(localStorage.getItem("dashboard-data"))?.id
                  ),
                  productCategories: [
                    {
                      categoryId: select?.productSubCategory
                        ? select?.productSubCategory.value
                        : select.productCategory.value,
                    },
                  ],
                  supplier: {
                    id: select.supplierType.value,
                  },
                  brand: {
                    id: select.brandType.value,
                  },
                  imageUrl: "",
                  unitOfMeasure: select.unitType.value,
                  netWeightUOM: select.netType.value,
                  referenceUOM: select.referenceType.value,
                  isDepositRequired: select.depositType.value,
                  pricingType: select.priceType.value,
                  taxCategoryType: select.taxType.value,
                  taxCategoryValue: select.taxType.value,
                  productPrice: {
                    purchasePrice: values.purchasePrice,
                    sellPrice: values.sellPrice,
                  },
                  skuType: select?.sku?.value,
                  productDimension : {
                    height: values?.height,
                    width: values?.width,
                    length: values?.length,
                    depth: values?.depth,
                    unitOfMeasurement: select?.unitOfMeasurement?.value || ""
                   }
                };

                createMerchant.mutate(body);

                if (createMerchant.isIdle === true) {
                  history.goBack();
                  alert.success(
                    <div style={{ textTransform: "none" }}>
                      Successfully Added!
                    </div>
                  );
                } else {
                  alert.error(
                    <div style={{ textTransform: "none" }}>
                      Please Check all Fields!
                    </div>
                  );
                }
              }
            }}
          >
            {({ onSubmit }) => (
              <Form className="w-full mx-auto h-auto bg-white rounded-lg shadow-lg  ">
                <div className="w-full h-12 flex bg-dash rounded-t-lg text-white ">
                  <div className="w-10 p-2 h-full flex items-center">
                    <BiArrowBack
                      onClick={() => history.goBack()}
                      className="h-10 w-10 cursor-pointer"
                    />
                  </div>
                  <div className="w-full h-full px-7 flex flex-col justify-center">
                    <h2 className="font-bold text-2xl">
                      {`${t("createNew")} ${t("product")}`}
                    </h2>
                  </div>
                </div>

                <div className="w-full h-[78vh] scrollbar-thin scrollbar-thumb-gray-100 scrollbar-track-gray-300 ">
                  <div className="flex ">
                    <div className="w-full px-12 py-10 ">
                      <div className=" grid grid-cols-2 gap-x-4 gap-y-6 mt-1">
                        {/*  first row   */}
                        <div className="flex     w-full items-center select-none">
                          <TextField2
                            label={t("productName")}
                            name="name"
                            type="text"
                          />
                        </div>

                        <div className="flex    w-full items-center select-none">
                          <TextField2 label="Title" name="title" type="text" />
                        </div>
                      </div>
                      <div className="grid grid-cols-1 mt-3">
                        <div className="flex w-full items-center select-none">
                          <TextArea
                            label={t("description")}
                            name="description"
                            type="text"
                          />
                        </div>
                      </div>

                      <div className="grid grid-cols-2 gap-x-4 gap-y-3 mt-3">
                        <Select
                          placeholder={t("Category")}
                          maxMenuHeight={162}
                          value={select.productCategory}
                          name="productCategory"
                          onChange={handleSelect}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          styles={customStyles}
                          options={pCatList?.data?.map((item, index) => {
                            return {
                              label: item?.name,
                              value: item?.id,
                              key: item?.parent?.id,
                            };
                          })}
                        />

                        <Select
                          maxMenuHeight={162}
                          placeholder={t("subCategory")}
                          value={select.productSubCategory}
                          name="productSubCategory"
                          onChange={handleSelect}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          styles={customStyles}
                          options={subCat?.map((item, index) => {
                            return {
                              label: item.name,
                              value: item.id,
                              key: item.id,
                            };
                          })}
                        />
                      </div>

                      <div className="grid grid-cols-2 gap-x-4 gap-y-3 mt-3">
                        <div className="flex  w-full items-center select-none">
                          <TextField2
                            label={t("unitWeight")}
                            name="unitWeight"
                            type="text"
                          />
                        </div>

                        <Select
                          placeholder={t("uom")}
                          maxMenuHeight={162}
                          value={select.unitType}
                          name="unitType"
                          onChange={handleSelect}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          styles={customStyles}
                          options={uomList?.data?.map((item, index) => {
                            return {
                              label: item.name,
                              value: item.name,
                              key: item.id,
                            };
                          })}
                        />
                      </div>

                      <div className="grid grid-cols-2 gap-x-4 gap-y-4 mt-3">
                        <div className="flex  w-full items-center select-none">
                          <TextField2
                            label={t("Net Weight")}
                            name="netWeight"
                            type="text"
                          />
                        </div>

                        <Select
                          placeholder="Net Weight UOM"
                          maxMenuHeight={162}
                          value={select.netType}
                          name="netType"
                          onChange={handleSelect}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          styles={customStyles}
                          options={netWeightUOM}
                        />
                      </div>

                      <div className="grid grid-cols-2 gap-x-4 gap-y-4 mt-3">
                        <div className="flex  w-full items-center select-none">
                          <TextField2
                            label={t("Reference Weight")}
                            name="referenceWeight"
                            type="text"
                          />
                        </div>
                        <Select
                          placeholder="Reference Weight UOM"
                          maxMenuHeight={162}
                          value={select.referenceType}
                          name="referenceType"
                          onChange={handleSelect}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          styles={customStyles}
                          options={referenceUOM}
                        />
                      </div>

                      <div className="grid grid-cols-2 gap-x-4 gap-y-4 mt-3">
                        <div className="flex  w-full items-center select-none">
                          <TextField2
                            label={t("Reference Price")}
                            name="referencePrice"
                            type="text"
                          />
                        </div>

                        <div className="w-60 flex items-center rounded-lg">
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              setReferenceWeight(e.target.checked)
                            }
                            className=" h-5 w-5"
                          />
                          <span className="mx-5 text-gray-500">
                            {t("Reference")}
                          </span>
                        </div>
                      </div>

                      <div className="grid grid-cols-2 gap-x-4 gap-y-4 mt-3">
                        <div className="flex   w-full items-center select-none">
                          <TextField2
                            label={t("barcode")}
                            name="barCode"
                            type="text"
                          />
                        </div>

                        <div className="flex   w-full items-center select-none">
                          <TextField2
                            label={t("HSNCode")}
                            name="hsnCode"
                            type="text"
                          />
                        </div>
                      </div>

                      <div className="grid grid-cols-2 gap-x-4 gap-y-4 mt-3">
                        <div className="">
                          <TextField2
                            label={t("purchasePrice")}
                            name="purchasePrice"
                            type="text"
                          />
                        </div>

                        <div className="">
                          <TextField2
                            label={t("Gross Price")}
                            name="sellPrice"
                            type="text"
                          />
                        </div>
                      </div>

                      <div className="grid grid-cols-3 gap-x-4 gap-y-4 mt-3">
                        <Select
                          placeholder="Price Type"
                          maxMenuHeight={162}
                          value={select.priceType}
                          name="priceType"
                          onChange={handleSelect}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          styles={customStyles}
                          options={pricingType}
                        />

                        <Select
                          placeholder={t("SKUType")}
                          maxMenuHeight={162}
                          value={select.sku}
                          name="sku"
                          onChange={handleSelect}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          styles={customStyles}
                          options={skuType}
                        />

                        <Select
                          placeholder={t("supplier")}
                          maxMenuHeight={162}
                          value={select.supplierType}
                          name="supplierType"
                          onChange={handleSelect}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          styles={customStyles}
                          options={supplierList?.data?.map((item, index) => {
                            return {
                              label: item.name,
                              value: item.id,
                              key: item.id,
                            };
                          })}
                        />
                      </div>

                      <div className="grid grid-cols-3 gap-x-4 gap-y-4 mt-3">
                        <div className="flex  w-full items-center select-none">
                          <TextField2
                            label={t("Country Of Origin")}
                            name="countryOfOrigin"
                            type="text"
                          />
                        </div>

                        <div className="flex  w-full items-center select-none">
                          <TextField2
                            label={t("Trading Class")}
                            name="tradingClassification"
                            type="text"
                          />
                        </div>

                        <div className="w-60 flex items-center rounded-lg">
                          <input
                            type="checkbox"
                            onChange={(e) => setVegMark(e.target.checked)}
                            className=" h-5 w-5"
                          />
                          <span className="mx-5 text-gray-500">
                            {t("vegMark")}
                          </span>
                        </div>

                        {/* <Select
                          placeholder="Trading Class"
                          maxMenuHeight={162}
                          value={select.tradeClass}
                          name="tradeClass"
                          onChange={handleSelect}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          styles={customStyles}
                          options={tradingClassification}
                        /> */}

                        {/* {select?.sku?.value !== "NON_STANDARD_SKU" ? (
                          <Select
                            placeholder={t("package")}
                            options={packageType}
                            maxMenuHeight={162}
                            value={select.packageType}
                            name="packageType"
                            onChange={handleSelect}
                            styles={customStyles}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                          />
                        ) : null} */}

                        {/* <Select
                          placeholder="Deposited Product"
                          maxMenuHeight={162}
                          value={select.depositType}
                          name="depositType"
                          onChange={handleSelect}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          styles={customStyles}
                          options={isDepositRequired}
                        /> */}
                      </div>

                      <div className="grid grid-cols-3 gap-x-4 gap-y-4 mt-3">
                        <div className="flex  w-full items-center select-none">
                          <TextField2
                            label={t("depositAmount")}
                            name="depositAmount"
                            type="number"
                          />
                        </div>

                        <Select
                          placeholder={t("currency")}
                          maxMenuHeight={162}
                          value={select.currency}
                          name="currency"
                          onChange={handleSelect}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          styles={customStyles}
                          options={currencyType}
                        />

                        <Select
                          placeholder={t("tax")}
                          maxMenuHeight={162}
                          value={select.taxType}
                          name="taxType"
                          onChange={handleSelect}
                          styles={customStyles}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          options={taxList?.data?.map((item, index) => {
                            return {
                              label: `${item?.name}`,
                              value: item.value,
                              key: item.id,
                            };
                          })}
                        />
                      </div>

                      <div className="my-3">
                          <p className="font-bold">Product Dimensions:</p>
                        </div>

                      <div className="grid grid-cols-2 gap-x-4 gap-y-4 mt-3">
                          <div className="flex  w-full items-center select-none">
                            <TextField2
                            // editable={isEditable}
                              label={t("Height")}
                              name="height"
                              type="text"
                            />
                          </div>

                          <TextField2
                              // editable={isEditable}
                              label={t("Depth")}
                              name="depth"
                              type="text"
                            />
                        </div>

                        <div className="grid grid-cols-2 gap-x-4 gap-y-4 mt-3">
                          <div className="flex  w-full items-center select-none">
                            <TextField2
                              // editable={isEditable}
                              label={t("Length")}
                              name="length"
                              type="text"
                            />
                          </div>

                          <div className="flex  w-full items-center select-none">
                            <TextField2
                              // editable={isEditable}
                              label={t("Width")}
                              name="width"
                              type="text"
                            />
                          </div>
                        </div>

                        <div className="grid grid-cols-2 gap-x-4 gap-y-4 mt-3">

                          <Select
                            // isDisabled={isEditable}
                            placeholder="Unit of measurement"
                            maxMenuHeight={162}
                            value={select.unitOfMeasurement}
                            name="unitOfMeasurement"
                            onChange={handleSelect}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            styles={customStyles}
                            options={unitOfMeasurement}
                          />
                        </div>


                      {/* <div className="grid grid-cols-3 gap-x-4 gap-y-3 mt-3">
                        

                        <Select
                          placeholder={t("Brand")}
                          maxMenuHeight={162}
                          value={select.brandType}
                          name="brandType"
                          onChange={handleSelect}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          options={brandList?.data?.map((item, index) => {
                            return {
                              label: item.name,
                              value: item.id,
                              key: item.id,
                            };
                          })}
                        />

                        <div className="w-full h-full flex items-center justify-end rounded-lg">
                          <span className=" mr-4 text-gray-500">Status</span>
                          <Toggle
                            onChange={statusChecked}
                            checked={status}
                            //icons={{ checked: null, unchecked: null }}
                            className="react-switch"
                          />
                        </div>

                        <div className="">
                          <TextField2
                            label={t("purchasePrice")}
                            name="purchasePrice"
                            type="text"
                          />
                        </div>

                        <div className="">
                          <TextField2
                            label={t("sellingPrice")}
                            name="sellPrice"
                            type="text"
                          />
                        </div>

                        <Select
                          placeholder={t("currency")}
                          maxMenuHeight={162}
                          value={select.currency}
                          name="currency"
                          onChange={handleSelect}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          styles={customStyles}
                          options={currencyType}
                        />
                      </div> */}

                      <div className="grid grid-cols-1 gap-x-4 mt-5 ">
                        <div className="flex justify-end items-center">
                          <button
                            type="submit"
                            onClick={onSubmit}
                            className="bg-green-500 rounded-lg px-4 py-2  focus:outline-none text-white"
                          >
                            <span className="h-full flex items-center">
                              <svg
                                class="svg-icon"
                                viewBox="0 0 20 20"
                                width="24px"
                                height="24px"
                              >
                                <path
                                  fill="#8A2BE2"
                                  d="M19.404,6.65l-5.998-5.996c-0.292-0.292-0.765-0.292-1.056,0l-2.22,2.22l-8.311,8.313l-0.003,0.001v0.003l-0.161,0.161c-0.114,0.112-0.187,0.258-0.21,0.417l-1.059,7.051c-0.035,0.233,0.044,0.47,0.21,0.639c0.143,0.14,0.333,0.219,0.528,0.219c0.038,0,0.073-0.003,0.111-0.009l7.054-1.055c0.158-0.025,0.306-0.098,0.417-0.211l8.478-8.476l2.22-2.22C19.695,7.414,19.695,6.941,19.404,6.65z M8.341,16.656l-0.989-0.99l7.258-7.258l0.989,0.99L8.341,16.656z M2.332,15.919l0.411-2.748l4.143,4.143l-2.748,0.41L2.332,15.919z M13.554,7.351L6.296,14.61l-0.849-0.848l7.259-7.258l0.423,0.424L13.554,7.351zM10.658,4.457l0.992,0.99l-7.259,7.258L3.4,11.715L10.658,4.457z M16.656,8.342l-1.517-1.517V6.823h-0.003l-0.951-0.951l-2.471-2.471l1.164-1.164l4.942,4.94L16.656,8.342z"
                                ></path>
                              </svg>
                              <span className="px-2 select-none">
                                {`${t("create")} ${t("product")}`}
                              </span>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default CreateProduct;
