import React, { useState, useEffect } from "react";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";

const SwipperText = ({ arr, selectedData, setSelectedData }) => {
  const [index, setIndex] = useState(0);

  const nextArr = () => {
    if (index > 0 && index < arr.length - 1) {
      setIndex(index + 1);
    } else if (index === 0 && arr.length !== 1) {
      setIndex(index + 1);
    }
  };
  const prevArr = () => {
    if (index > 0 && index < arr.length) {
      setIndex(index - 1);
    } else if (index === arr.length) {
      setIndex(arr.length - 1);
    }
  };

  useEffect(() => {
    if (arr !== null && arr !== undefined) {
      setSelectedData(arr[index]);
    } else {
      setSelectedData(null)
    }
    
  }, [index]);

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex justify-center items-center space-x-0">
        {selectedData ? (
          <>
          <HiChevronLeft
          onClick={prevArr}
          size={28}
          color={index === 0 && "gray"}
        >
          next
        </HiChevronLeft>
        <div className="w-full h-[40px] flex justify-center items-center">
          {selectedData && (
            <div>
              <img
                src={selectedData?.imageUrl || "/noImage.jpg"}
                className={`w-[30px] h-full justify-center items-center bg-cover ${selectedData?.imageUrl === "" && "border"} `}
              />
              <p><span className="font-bold"></span> {selectedData?.name} - {selectedData?.barCode}</p>
              {/* <p><span className="font-bold"></span>{selectedData?.barCode}</p> */}
            </div>
          )}
        </div>
        <HiChevronRight
          onClick={nextArr}
          size={28}
          color={index === arr?.length - 1 && "gray"}
        >
          prev
        </HiChevronRight>
        </>
        ) : (
        <p>No Recommendation</p>
        )}
      </div>
      {/* <div>
        <p>{(selectedData?.productName != 'undefined' || selectedData?.productName != 'null')
        ? selectedData?.productName
        : selectedData?.name}</p>
      </div> */}
    </div>
  );
};

export default SwipperText;
