import React, { useState, useEffect } from "react";
import TableStr from "./TableStr";
import Enabled from "./options/Enabled";
import Disabled from "./options/Disabled";
import Refunded from "./options/Refunded";
import All from "./options/All";


function Order() {

  return (
    <div className="h-full w-full ">
      <TableStr
        title={"Orders"}
        listUrl={`${process.env.REACT_APP_SHOPPER_URL}/orders?sort=createdDate,desc`}
        getName={"orderList"}
        All={All}
        Refunded={Refunded}
        refundUrl={`${process.env.REACT_APP_SHOPPER_URL}/order-refunds?projection=OrderListDTO&sort=createdDate,desc`}
      />
    </div>
  );
}

export default Order;
