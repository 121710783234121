import React, { useState, useMemo } from "react";
import { IoIosClose } from "react-icons/io";

function ModalImg({ open, onCloseModal, img }) {
  const [title, setTitle] = useState("Brand");
  //console.log("supplier: ", brand)
  return (
    <>
      {open && (
        <div className="w-[100vw] h-[100vh]  bg-black bg-opacity-80 absolute inset-0 z-10 flex items-center justify-center">
          <div className="w-2/5 h-[75%]  text-left select-none z-10 absolute top-1/2 left-1/2   transform -translate-x-1/2 -translate-y-1/2 ">
            <div className="w-full h-full bg-gray-100 border-2  rounded-lg shadow-lg flex justify-center items-center ">
              <div className=" absolute w-full h-8 flex text-gray-500 px-1 top-0 justify-end">
                <IoIosClose
                  onClick={onCloseModal}
                  className="h-10 w-10 cursor-pointer"
                />
              </div>

              <div className="w-[80%] h-[80%] flex items-center justify-center">
                <img src={img} style={{ height: "100%" }} alt="Product img" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ModalImg;
