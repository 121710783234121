import React from 'react'
import { MdArrowUpward, MdArrowDownward } from "react-icons/md";
import{isInteger} from '../../../../hook/useType'

function SelectProduct({ item, index, product, selectProduct, onChangeInput, editedData, input }) {
    return (
        <table
            key={index}
            onClick={() => selectProduct(item)}
            className="table-auto rounded w-full h-auto text-sm cursor-pointer mt-1 hover:bg-gray-200">
            <tbody className="text-left">

                <tr>
                    <th className=" w-1/4  px-4">
                        <img src={item?.imageUrl} alt="item" className="w-8 h-8" />
                    </th>
                    <th className="w-1/4  px-6 text-gray-500">
                        {editedData?.eventType === "PICK" ? (
                            <MdArrowUpward className="w-5 h-5 text-green-500 " />
                        ) : (
                            <MdArrowDownward className="w-5 h-5 text-red-500 " />
                        )}
                    </th>
                    <th className="w-1/4  px-2 text-gray-500">
                        <input
                            type="number"
                            name={item?.productId}
                            placeholder={isInteger(editedData?.quantity) && editedData?.quantity !==0 ? parseInt(editedData?.quantity) : parseInt(editedData?.quantity)+1}
                            value={input?.qty}
                            onChange={(e) => onChangeInput(e, item?.productId)}
                            className="focus:outline-none border rounded-sm w-12 px-4"
                        />
                    </th>
                    <th className="w-1/4  px-2  py-2">
                        {"SKU"}
                        <span className="ml-4">{product?.productId === item?.productId && (`✔️`)}</span>
                    </th>
                    {/* <th className="border border-gray-300 px-2 text-gray-500">
                              {storeData?.sessionRef}
                          </th> */}
                </tr>

            </tbody>
        </table>


    )
}

export default SelectProduct
