import React, { useState, useEffect } from "react";
import Action from "./Action";
import Health from "./Health";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import Address from "./Address";
import Select from "react-select";
import DashboardRoute from "./DashboardRoute";
import Confirm from "./Confirm";
import { useQuery } from "react-query";
import Pagination from "@mui/material/Pagination";
import SearchData from "../../../../../../../components/SearchData";
import { BiSearchAlt } from "react-icons/bi";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

function All({ listUrl, url, viewUrl, editUrl }) {
  const [checked, setChecked] = useState(false);

  const [filteredData, setFilteredData] = useState(null);
  const [searchData, setSearchData] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const [input, setInput] = useState({
    retailerStore: "",
    type: "",
  });

  const findUrl = (name) => {
    switch (name) {
      case "retailerStore":
        return `${listUrl}&page=${
          activePage - 1
        }&size=9&retailerStoreId.equals=${searchData}`;

      default:
        return `${listUrl}&page=${activePage - 1}&size=9`;
    }
  };

  //list url
  const [activePage, setActivePage] = useState(1);

  const fetchData = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: findUrl(input?.type),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      });
      return res.data;
    } catch ({ message }) {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message);
      }
    }
  };

  const { data, isLoading, isIdle, error } = useQuery(
    ["rackList", activePage, searchData],
    async () => fetchData(input?.type),
    {
      enabled: !!activePage || !!searchData,
      cacheTime: Infinity,
      staleTime: 0,
    }
  );

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  const searchDataFn = (name, data) => {
    setSearchData(data?.id);
    setInput({ ...input, [name]: data?.name });
    setFilteredData(null);
  };

  const onChangeInput = (e, name) => {
    const value = e.target.value;

    setInput({ ...input, [name]: value, type: name });

    if (true) {
      setLoading(true);
      axios({
        method: "GET",
        url: `${
          process.env.REACT_APP_BASE_URL
        }/_search/retail-stores?name.contains=${value.toLowerCase()}*`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      }).then((res) => {
        setFilteredData(res?.data);
        setLoading(false);
      });
    }
  };

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };

  const clear = () => {
    setInput({
      retailerStore: "",
      type: "",
    });
    setSearchData(null);
    setFilteredData(null);
  };

  return (
    <div className=" w-full h_das_table ">
      <div className="flex h-10 w-full mt-2 items-center justify-between py-8 px-4">
        <div className="w-72 flex justify-end items-center">
          <SearchData
            name="retailerStore"
            label={"Retailer Store"}
            input={input}
            setSearchData={setSearchData}
            onChangeInput={onChangeInput}
            filteredData={filteredData}
            searchDataFn={searchDataFn}
            loading={loading}
          />
          <BiSearchAlt className="w-12  h-5 mr-4 absolute   text-blue-500" />
        </div>

        <button
          onClick={clear}
          className="bg-green-600 py-1.5 px-5 mr-7 rounded text-white"
        >
          Clear filters
        </button>
      </div>

      <div className="ag-theme-alpine w-full h-full text-left">
        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            resizable: true,
          }}
          suppressRowClickSelection={true}
          rowSelection={"multiple"}
          rowData={data}
        >
          <AgGridColumn
            headerName="Machine Name"
            field="name"
            minWidth={180}
            sortable={true}
            headerCheckboxSelection={true}
            headerCheckboxSelectionFilteredOnly={true}
            checkboxSelection={true}
            cellRendererFramework={(params) => (
              <DashboardRoute params={params} />
            )}
          />

          <AgGridColumn
            headerName="Device Identifier"
            field="deviceIdentifier"
            minWidth={150}
            sortable={true}
          />

          <AgGridColumn
            headerName="Store Code"
            field="macAddress"
            minWidth={150}
            sortable={true}
            cellRendererFramework={(params) => (
              <p>{params?.data?.retailStore?.internalCode}</p>
            )}
          />

          <AgGridColumn
            headerName="Retail Store"
            field="retailStore"
            minWidth={150}
            cellRendererFramework={(params) => <Address params={params} />}
          />

          <AgGridColumn
            field="Status"
            minWidth={100}
            cellRendererFramework={(params) => (
              <Toggle
                onChange={handleChange}
                defaultChecked={checked}
                //icons={{ checked: null, unchecked: null }}
                className="react-switch"
              />
            )}
          />

          <AgGridColumn
            headerName="Health"
            field="health"
            minWidth={100}
            cellRendererFramework={(params) => (
              <Health
                params={params}
                url={url}
                viewUrl={viewUrl}
                editUrl={editUrl}
              />
            )}
          />

          <AgGridColumn
            headerName="Action"
            field="action"
            minWidth={100}
            cellRendererFramework={(params) => (
              <Action
                params={params}
                url={url}
                viewUrl={viewUrl}
                editUrl={editUrl}
              />
            )}
          />
        </AgGridReact>
      </div>
      <div className="absolute bottom-8 right-12  flex justify-center item-center">
        <Pagination
          count={3}
          showFirstButton
          showLastButton
          page={activePage}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
}

export default All;
