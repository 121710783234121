import React, { useState, useMemo, useEffect } from "react";
import { TextField2 } from "../../../validation/TextField2";
import { useHistory } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import Select from "react-select";
import { useAlert } from "react-alert";
import countryList from "react-select-country-list";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { usePut } from "../../../../api/usePut";
import { useLocation } from "react-router-dom";

let validate;
validate = Yup.object({
  name: Yup.string().required("Required"),
  internalCode: Yup.string(),
  description: Yup.string(),
  email: Yup.string().email(),
  zip: Yup.number().typeError("Not a number").required("Required"),
  street1: Yup.string().required("Required"),
  street2: Yup.string(),
  city: Yup.string().required("Required"),
  administrativeArea: Yup.string(),
  latitude: Yup.number().typeError("Not a number"),
  longitude: Yup.number().typeError("Not a number"),
  state: Yup.string().required("Required"),
  website: Yup.string(),
  description: Yup.string(),
  phone: Yup.number(),
});

const countryLists = [
  { label: "Germany", value: "DE" },
  { label: "Hungary", value: "HU" },
  { label: "France", value: "FR" },
];

const getLabel = (value) => {

  return countryLists.find((ele) => ele?.value === value);
};

function MerchantEdit() {
  const location = useLocation();
  const [merchantData, setMerchant] = useState({ ...location?.state?.data });

  const initialValues = {
    name: merchantData.name ? merchantData.name : "",
    email: merchantData.email ? merchantData.email : "",
    street1: merchantData?.addresses[0]?.street1
      ? merchantData?.addresses[0]?.street1
      : "",
    street2: merchantData?.addresses[0]?.street2
      ? merchantData?.addresses[0]?.street2
      : "",
    city: merchantData?.addresses[0]?.cityOrVillage
      ? merchantData?.addresses[0]?.cityOrVillage
      : "",
    administrativeArea: merchantData?.addresses[0]?.administrativeArea1
      ? merchantData?.addresses[0]?.administrativeArea1
      : "",
    latitude: merchantData?.addresses[0]?.lat
      ? merchantData?.addresses[0]?.lat
      : "",
    longitude: merchantData?.addresses[0]?.lng
      ? merchantData?.addresses[0]?.lng
      : "",
    state: merchantData?.addresses[0]?.state
      ? merchantData?.addresses[0]?.state
      : "",
    zip: merchantData?.addresses[0]?.zipCode
      ? merchantData?.addresses[0]?.zipCode
      : "",
    website: merchantData?.website ? merchantData?.website : "",
    description: merchantData?.description ? merchantData?.description : "",
    internalCode: merchantData?.internalCode ? merchantData?.internalCode : "",
    phone: merchantData?.phone ? merchantData?.phone : "",
  };


  const alert = useAlert();
  const history = useHistory();
  const [select, setSelect] = useState({
    country: "",
  });

  useEffect(() => {
    setSelect({
      ...select,
      ["country"]: {
        label: merchantData?.addresses[0]?.country
          ? getLabel(merchantData?.addresses[0]?.country)?.label
          : "",
        value: merchantData?.addresses[0]?.country,
      },
    });
  }, []);

  const onSelectHandler = (value, name) => {
    setSelect({ ...select, [name]: value });
  };

  const editMerchant = usePut(
    `${process.env.REACT_APP_BASE_URL}/orgs/${location?.state?.id}`,
    location?.state?.getName
  );

  return (
    <>
      <div className="w-full h_screen select-none text-left flex">
        <div className="w-full h-full flex justify-center items-center bg-gray-200 ">
          <Formik
            initialValues={initialValues}
            validationSchema={validate}
            onSubmit={async (values) => {
              if (values.name !== "") {
                try {
              
                  editMerchant.mutate({...merchantData, id: location?.state?.id,
                    name: values.name,
                    internalCode: values.internalCode,
                    externalCode: null,
                    description: values.description,
                    website: values.website,
                    email: values.email,
                    phone: values.phone,
                    defaultLang: "en",
                    defaultLocale: "UK",
                    slug: null,
                    emailDomains: null,
                    rootOrgId: location?.state?.id,
                    orgType: "Retailer",
                    addresses: [
                      {
                        id: merchantData?.addresses[0]?.id,
                        street1: values.street1,
                        street2: values.street2,
                        neighborhoodName: null,
                        administrativeArea1: values.administrativeArea,
                        administrativeArea2: null,
                        cityOrVillage: values.city,
                        state: values.state,
                        country: select?.country?.value ? select?.country?.value : merchantData?.addresses[0]?.country,
                        zipCode: values.zip,
                        lat: values.latitude,
                        lng: values.longitude,
                      },
                    ],
                    rootOfHierarchy: true,});

                  if (editMerchant.isIdle === true) {
                    history.goBack();
                    alert.success(
                      <div style={{ textTransform: "none" }}>
                        Successfully Added!
                      </div>
                    );
                  } else {
                    alert.error(
                      <div style={{ textTransform: "none" }}>
                        Please Check all Fields!
                      </div>
                    );
                  }
                } catch (error) {
                  alert.error(
                    <div style={{ textTransform: "none" }}>
                      you are offline!
                    </div>
                  );
                }
              }
            }}
          >
            {() => (
              <Form className="w-11/12 mx-auto h-auto bg-white rounded-lg shadow-lg">
                <div className="w-full h-16 flex bg-nav text-white rounded-t-lg">
                  <div className="w-10 p-2 h-full flex items-center">
                    <BiArrowBack
                      onClick={() => history.goBack()}
                      className="h-10 w-10 cursor-pointer"
                    />
                  </div>
                  <div className="w-full h-full px-7  flex flex-col justify-center">
                    <h2 className="font-bold text-2xl">Edit Merchant</h2>
                  </div>
                </div>

                <div className="w-11/12 mx-auto px-6 my-2">
                  <div className="w-full h-20 flex mt-4  items-center">
                    <div className="flex  px-4 mr-4   w-full items-center select-none">
                      <TextField2 label="Name" name="name" type="text" />
                    </div>

                    <div className="flex  px-4 ml-4   w-full items-center select-none">
                      <TextField2
                        label="Description"
                        name="description"
                        type="text"
                      />
                    </div>

                    <div className="flex  px-4 ml-4   w-full items-center select-none">
                      <TextField2
                        label="Internal Code"
                        name="internalCode"
                        type="text"
                      />
                    </div>

                    <div className="flex  px-4 ml-4   w-full items-center select-none">
                      <TextField2 label="Email" name="email" type="email" />
                    </div>
                  </div>

                  <h2 className="px-5 my-2">Address : </h2>

                  <div className="w-full h-20 flex mt-2  items-center">
                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2 label="Street 1" name="street1" type="text" />
                    </div>

                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2 label="Street 2" name="street2" type="text" />
                    </div>

                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2
                        label="City Or Village"
                        name="city"
                        type="text"
                      />
                    </div>

                    <div className="flex  px-4   w-full items-center select-none">
                      <TextField2
                        label="Administrative Area"
                        name="administrativeArea"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="w-full h-20 flex  items-center">
                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2
                        label="Latitude"
                        name="latitude"
                        type="text"
                      />
                    </div>

                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2
                        label="Longitude"
                        name="longitude"
                        type="text"
                      />
                    </div>

                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2 label="State" name="state" type="text" />
                    </div>

                    <div className="flex  px-4 w-full items-center select-none">
                      <div style={{ width: "222px" }}>
                        <Select
                          options={countryLists}
                          placeholder="Select country"
                          maxMenuHeight={162}
                          value={select?.country}
                          onChange={(value) =>
                            onSelectHandler(value, "country")
                          }
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="w-full h-20 flex items-center">
                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2 label="Zip Code" name="zip" type="text" />
                    </div>

                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2 label="Website" name="website" type="text" />
                    </div>

                    <div className="flex  px-4    w-full items-center select-none">
                      <TextField2
                        label="contact Number"
                        name="phone"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="w-full h-16 flex justify-end items-center px-4 -mt-2">
                    <button
                      type="submit"
                      className="bg-green-500 rounded-lg px-6 py-3  focus:outline-none text-white"
                    >
                      <span className="h-full flex items-center">
                        <svg
                          className="svg-icon"
                          viewBox="0 0 20 20"
                          width="24px"
                          height="24px"
                        >
                          <path
                            fill="#8A2BE2"
                            d="M19.404,6.65l-5.998-5.996c-0.292-0.292-0.765-0.292-1.056,0l-2.22,2.22l-8.311,8.313l-0.003,0.001v0.003l-0.161,0.161c-0.114,0.112-0.187,0.258-0.21,0.417l-1.059,7.051c-0.035,0.233,0.044,0.47,0.21,0.639c0.143,0.14,0.333,0.219,0.528,0.219c0.038,0,0.073-0.003,0.111-0.009l7.054-1.055c0.158-0.025,0.306-0.098,0.417-0.211l8.478-8.476l2.22-2.22C19.695,7.414,19.695,6.941,19.404,6.65z M8.341,16.656l-0.989-0.99l7.258-7.258l0.989,0.99L8.341,16.656z M2.332,15.919l0.411-2.748l4.143,4.143l-2.748,0.41L2.332,15.919z M13.554,7.351L6.296,14.61l-0.849-0.848l7.259-7.258l0.423,0.424L13.554,7.351zM10.658,4.457l0.992,0.99l-7.259,7.258L3.4,11.715L10.658,4.457z M16.656,8.342l-1.517-1.517V6.823h-0.003l-0.951-0.951l-2.471-2.471l1.164-1.164l4.942,4.94L16.656,8.342z"
                          ></path>
                        </svg>
                        <span className="px-2">Save</span>
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default MerchantEdit;
