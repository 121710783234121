import React, { useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import DashboardRoute from "./DashboardRoute";
import FadeLoader from "react-spinners/FadeLoader";
import Pagination from "@mui/material/Pagination";
import OnHoldShopper from "./OnHoldShopper";
import ActivationShopper from "./ActivationShopper";
import { BiSearchAlt } from "react-icons/bi";

function All({ listUrl, getName }) {
  let [loading, setLoading] = useState(false);
  const [allCount, setAllCount] = useState();
  const history = useHistory();

  const [filteredData, setFilteredData] = useState(null);

  const [refresh, setRefresh] = useState(false);

  const [search, setSearch] = useState({
    firstName: "",
    phone: "",
    email: "",
    login: "",
    filterBy: "",
  });

  const handleInput = (value, name) => {
    setSearch({ ...search, [name]: value, filterby: name });
  };

  const clear = () => {
    setSearch({
      firstName: "",
      phone: "",
      email: "",
      login: "",
      filterBy: "",
    });
    // setSelectProduct(null);
    setFilteredData(null);
  };

  const [activePage, setActivePage] = useState(1);

  const findUrl = (filterName) => {
    switch (filterName) {
      case "firstName":
        return search?.firstName?.length > 0
          ? `${listUrl}&firstName.equals=${search?.firstName}`
          : listUrl;

      case "phone":
        return search?.phone?.length > 0
          ? `${listUrl}&phone.equals=${search?.phone}`
          : listUrl;

      case "login":
        return search?.login?.length > 0
          ? `${listUrl}&login.equals=${search?.login}`
          : listUrl;

      default:
        return listUrl;
    }
  };

  const fetchData = async (page) => {
    try {
      const res = await axios({
        method: "GET",
        url: `${findUrl(search?.filterby)}&page=${
          page - 1
        }&size=10&sort=createdDate,desc`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      });
      setAllCount(res?.headers?.["x-total-count"]);
      return res.data;
    } catch ({ message }) {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message);
      }
    }
  };

  const { data, isLoading, isIdle, error } = useQuery(
    [
      "productList",
      activePage,
      search?.firstName,
      search?.login,
      search?.phone,
      search?.email,
      refresh,
    ],
    async () => fetchData(activePage),
    {
      enabled:
        !!activePage ||
        !!search?.firstName ||
        !search?.login ||
        !search?.phone ||
        !search?.email,
      cacheTime: Infinity,
      staleTime: 0,
    }
  );

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  return (
    <div className=" w-full h_das_table select-text">
      <div className="my-5">
        <div
          className={
            loading
              ? "bg-black bg-opacity-0 absolute inset-0 z-10 flex items-center justify-center"
              : null
          }
        >
          <FadeLoader className="absolute " loading={loading} size={150} />
        </div>
        <div className="flex h-10 w-full mt-2 items-center justify-between py-8 px-4">
          <div className="w-[25%] flex justify-end items-center">
            <input
              className="w-full focus:outline-none py-1.5 mr-2 px-2 border-2 border-gray-200 rounded-lg"
              placeholder="Search by Login"
              onChange={(e) => handleInput(e.target.value, "login")}
              value={search?.login}
            />
            <BiSearchAlt className="w-12 h-5 absolute  text-blue-500" />
          </div>
          <div className="w-[25%] flex justify-end items-center">
            <input
              className="w-full focus:outline-none py-1.5 px-2 mr-2 border-2 border-gray-200 rounded-lg"
              placeholder="Search by First Name"
              onChange={(e) => handleInput(e.target.value, "firstName")}
              value={search?.firstName}
            />
            <BiSearchAlt className="w-12 h-5 absolute  text-blue-500" />
          </div>
          <div className="w-[25%] flex justify-end items-center">
            <input
              className="w-full focus:outline-none py-1.5 px-2 mr-2 border-2 border-gray-200 rounded-lg"
              placeholder="Search by Phone"
              onChange={(e) => handleInput(e.target.value, "phone")}
              value={search?.phone}
            />
            <BiSearchAlt className="w-12 h-5 absolute  text-blue-500" />
          </div>
          <div className="w-[25%] flex justify-center items-center">
            <button
              onClick={clear}
              className="bg-green-600 py-1.5 px-5 rounded text-white"
            >
              Clear
            </button>
          </div>
        </div>
      </div>
      <div className="ag-theme-alpine w-full h-full text-left select-text">
        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            resizable: true,
          }}
          suppressRowClickSelection={true}
          pagination={false}
          rowData={filteredData ? filteredData : data}
        >
          <AgGridColumn
            headerName={"Login"}
            field="login"
            sortable={true}
            cellRendererFramework={(params) => (
              <DashboardRoute params={params} />
            )}
          />
          <AgGridColumn
            headerName={"First Name"}
            field="firstName"
            sortable={true}
            cellRendererFramework={(params) => (
              <DashboardRoute params={params} />
            )}
          />
          <AgGridColumn
            headerName={"Last Name"}
            field="lastName"
            sortable={true}
            cellRendererFramework={(params) => (
              <DashboardRoute params={params} />
            )}
          />
          <AgGridColumn
            headerName={"Phone"}
            field="phone"
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <>
                  <p className="ml-4">
                    {"+"}
                    {params?.data?.userInfoDTO?.isdCode}{" "}
                    {params?.data?.userInfoDTO?.phone}
                  </p>
                </>
              );
            }}
          />
          <AgGridColumn
            field="onHoldStatus"
            headerName="Is On-Hold?"
            minWidth={100}
            cellRendererFramework={(params) => (
              <OnHoldShopper
                params={params}
                handleRefresh={() => setRefresh((item) => !item)}
              />
            )}
          />
          <AgGridColumn
            field="activationStatus"
            headerName="Is Blocked?"
            minWidth={100}
            cellRendererFramework={(params) => (
              <ActivationShopper
                params={params}
                handleRefresh={() => setRefresh((item) => !item)}
              />
            )}
          />
          <AgGridColumn
            field="idVerifyStatus"
            headerName="ID Verification Status"
            cellRendererFramework={(params) => {
              return (
                <>
                  <p className="ml-4">
                    {params?.data?.userInfoDTO?.identityVerifiedStatus
                      ? "Yes"
                      : "No"}
                  </p>
                </>
              );
            }}
          />
        </AgGridReact>
      </div>
      <div className="  absolute bottom-8 right-12  flex justify-center item-center ">
        <Pagination
          count={
            allCount % 10 === 0
              ? parseInt(allCount / 10)
              : parseInt(allCount / 10) + 1
          }
          showFirstButton
          showLastButton
          page={activePage}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
}

export default All;
