import React, { useState, useMemo, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import Select from "react-select";
import { usePut } from "../../../../../../../../api/usePut";
import { useGetByParams } from "../../../../../../../../api/useGetByParams";
import { useGet } from "../../../../../../../../api/useGet";
import { TextField2 } from "../../../../../../../validation/TextField2";
import { TextArea } from "../../../../../../../validation/TextArea";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ClipLoader from "react-spinners/ClipLoader";
import { boolean } from "yup/lib/locale";
import { toast } from "react-toastify";
import FadeLoader from "react-spinners/FadeLoader";

const customStyles = {
  control: (base) => ({
    ...base,
    height: 55,
    minHeight: 35,
  }),
};

const currencyType = [
  { value: "€", label: "Euro", code: "EUR" },
  { value: "£", label: "Pound", code: "GBP" },
  { value: "$", label: "US Dollar", code: "USD" },
];

const detectedLan = (code) => {
  return currencyType?.filter((item) => {
    return item?.code === code;
  });
};

const templateType = [
  { value: "amber", label: "amber" },
  { value: "Default", label: "Default" },
];

const findLabel = (arr, value) => {
  return arr.find((ele) => ele.value === value).label;
};

let validate;
validate = Yup.object({
  title: Yup.string(),

  eslBarCode: Yup.number().required("Required"),
});

function EslUpdate() {
  const { t } = useTranslation();

  const history = useHistory();
  const {
    state: { data },
  } = useLocation();

  const [loading, setLoading] = useState(false);

  //post
  const { mutate: createProduct, isSuccess } = usePut(
    `${process.env.REACT_APP_BASE_URL}/esl/${data?.id}`,
    "eslList"
  );

  const retailerList = useGet(
    `${
      process.env.REACT_APP_BASE_URL
    }/retail-stores?page=0&size=10&sort=createdDate,desc&rootOrgId.equals=${parseInt(
      JSON.parse(localStorage.getItem("dashboard-data"))?.id
    )}`,
    "retailerList"
  );

  const uomList = useGet(
    `${
      process.env.REACT_APP_BASE_URL
    }/unit-of-measures?sort=createdDate,desc&rootOrgId=${parseInt(
      JSON.parse(localStorage.getItem("dashboard-data"))?.id
    )}`,
    "uomList"
  );

  const { data: initialValues } = useGet(
    `${process.env.REACT_APP_BASE_URL}/esl/${data.id}`,
    "eslDetail"
  );

  const [status, setStatus] = useState(false);

  const [select, setSelect] = useState({
    templateTypes: {
      values: "",
      label: "",
    },
  });

  const [initialData, setInitialData] = useState();

  useEffect(() => {
    if (initialValues) {
      setStatus(initialValues.status === "true" ? true : false); // for string values
      //setStatus(initialValues.status);     //for boolean value

      setSelect({
        templateTypes: {
          Value: "",
          label: initialValues?.templateType
            ? initialValues.templateType
            : "Quality Class",
        },
      });

      // console.log("initialValues: ", initialValues);
      // const {name, description, title, unitWeight, netWeight, referenceWeight} = initialValues //object

      setInitialData({
        eslBarCode: initialValues.eslBarCode,
      });
    }
  }, [initialValues]);

  const handleSelect = (action, name) => {
    setSelect({
      ...select,
      [name]: { value: action.value, label: action.label, code: action.code },
    });
  };

  // const statusChecked = (e) => {
  //   setStatus(e.target.checked);
  // };

  //for subCategory

  // useEffect(() => {
  //   axios({
  //     method: "GET",
  //     url: `${process.env.REACT_APP_BASE_URL}/categories?parentId.equals=${
  //       select?.productCategory?.value
  //     }&rootOrgId.equals=${
  //       JSON.parse(localStorage.getItem("dashboard-data"))?.rootOrgId
  //     }`,
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${localStorage.getItem("user-info")}`,
  //     },
  //   }).then((res) => {
  //     setSubCat(res?.data);
  //   });
  // }, [select?.productCategory?.value]);

  useEffect(() => {
    if (isSuccess) {
      setLoading(true);
      setTimeout(() => {
        history.goBack();
        setLoading(false);
      }, 2000);
    }
  }, [isSuccess]);

  if (loading) {
    return (
      <div className="h-full w-full flex justify-center items-center">
        <FadeLoader color="#000000" size={250} />
      </div>
    );
  }

  return (
    <>
      {initialData ? (
        <div className="w-full h-screen text-left select-none flex">
          <div className="w-full h-full flex justify-center items-center  p-4 bg-gray-200 ">
            <Formik
              initialValues={initialData}
              validationSchema={validate}
              onSubmit={async (values) => {
                if (values.name !== "") {
                  var body = {
                    id: data?.id,

                    eslBarCode: values.eslBarCode,

                    status: status,
                    rootOrgId: parseInt(
                      JSON.parse(localStorage.getItem("dashboard-data"))?.id
                    ),

                    templateType: select.templateTypes.value
                      ? select.templateTypes.value
                      : initialValues.templateType,
                    unitOfMeasure: select.unitType.value
                      ? select.unitType.value
                      : initialValues.unitOfMeasure,
                  };
                  await createProduct(body);
                }
              }}
            >
              {({ handleSubmit }) => (
                <Form className="w-full mx-auto h-auto bg-white rounded-lg shadow-lg ">
                  <div className="w-full h-12 flex bg-dash rounded-t-lg text-white">
                    <div className="w-10 p-2 h-full flex items-center">
                      <BiArrowBack
                        onClick={() => history.goBack()}
                        className="h-10 w-10 cursor-pointer"
                      />
                    </div>
                    <div className="w-full h-full px-7 flex flex-col justify-center">
                      <h2 className="font-bold text-2xl">
                        {`${t("Edit")} ${t("Esl")}`}
                      </h2>
                    </div>
                  </div>

                  <div className="w-full h-[83.5vh] scrollbar-thin scrollbar-thumb-gray-100 scrollbar-track-gray-300 ">
                    <div className="flex ">
                      <div className="w-full px-12 py-5">
                        <div className=" grid grid-cols-2 gap-x-4 gap-y-6 mt-1">
                          {/*  first row   */}

                          <div className="flex     w-full items-center select-none">
                            <TextField2
                              label="eslBarCode"
                              name="eslBarCode"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-1 mt-2">
                          <div className="flex w-full items-center select-none">
                            <TextArea
                              label={t("description")}
                              name="description"
                              type="text"
                            />
                          </div>
                        </div>

                        <div className="grid grid-cols-2 gap-x-4 gap-y-3 mt-2">
                          <Select
                            placeholder="Templete Type"
                            maxMenuHeight={162}
                            value={select.templateTypes}
                            onChange={(value) =>
                              handleSelect(value, "templateTypes")
                            }
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            styles={customStyles}
                            options={templateType}
                          />
                        </div>

                        <div className="grid grid-cols-1 gap-x-4 mt-6 ">
                          <div className="w-full flex items-center justify-center">
                            <button
                              type="button"
                              onClick={handleSubmit}
                              className="bg-green-500 rounded-lg px-4 py-2  focus:outline-none text-white"
                            >
                              <span className="h-full flex items-center">
                                <svg
                                  className="svg-icon"
                                  viewBox="0 0 20 20"
                                  width="24px"
                                  height="24px"
                                >
                                  <path
                                    fill="#8A2BE2"
                                    d="M19.404,6.65l-5.998-5.996c-0.292-0.292-0.765-0.292-1.056,0l-2.22,2.22l-8.311,8.313l-0.003,0.001v0.003l-0.161,0.161c-0.114,0.112-0.187,0.258-0.21,0.417l-1.059,7.051c-0.035,0.233,0.044,0.47,0.21,0.639c0.143,0.14,0.333,0.219,0.528,0.219c0.038,0,0.073-0.003,0.111-0.009l7.054-1.055c0.158-0.025,0.306-0.098,0.417-0.211l8.478-8.476l2.22-2.22C19.695,7.414,19.695,6.941,19.404,6.65z M8.341,16.656l-0.989-0.99l7.258-7.258l0.989,0.99L8.341,16.656z M2.332,15.919l0.411-2.748l4.143,4.143l-2.748,0.41L2.332,15.919z M13.554,7.351L6.296,14.61l-0.849-0.848l7.259-7.258l0.423,0.424L13.554,7.351zM10.658,4.457l0.992,0.99l-7.259,7.258L3.4,11.715L10.658,4.457z M16.656,8.342l-1.517-1.517V6.823h-0.003l-0.951-0.951l-2.471-2.471l1.164-1.164l4.942,4.94L16.656,8.342z"
                                  ></path>
                                </svg>
                                <span className="px-4 select-none">
                                  {/* {`${t("update")}`} */}
                                  Save
                                </span>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      ) : (
        <div className="w-full h-full flex justify-center items-center">
          <ClipLoader size={150} />
        </div>
      )}
    </>
  );
}

export default EslUpdate;
