import React, { useState, useEffect } from "react";
import NoImage from "../../../../../../Images/noImage.jpg";
import {
  MdArrowUpward,
  MdArrowDownward,
  MdBackspace,
  MdAssignmentTurnedIn,
  MdOutlineAssignmentReturned,
} from "react-icons/md";
import { VscRefresh } from "react-icons/vsc";
import { confirmAlert } from "react-confirm-alert";
import { FaBackward, FaForward, FaFastForward } from "react-icons/fa";
import { getTimeOnly } from "../../../../../../logic/useGetTime";
import VideoSwapper from "../../../../../../components/VideoSwapper";
import axios from "axios";
import ReactHover, { Trigger, Hover } from "react-hover";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Tab } from "@headlessui/react";
import moment from "moment";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import DisclosureCmp from "./DisclosureCmp";
import Tippy from "@tippyjs/react";
import EntryImages from "../../hawkeye/dashboard/EntryImages";
import SwipperText from "../../../../../../components/SwipperText";
import ProductEventImages from "./ProductEventImages";
import Select from "react-select";
import MatchEventHeader from "../header";
import ProductRecognizationModal from "./expressCheckout/modal/ProductRecognizationModal";
import { Button, Checkbox } from "@mui/material";
import useStyles from "../../../../../../theme/variants/Button";
import { useGet } from "../../../../../../api/useGet";
import { useOpeneventDataProvider } from "../context/OpeneventsContext";

const customStyles = {
  control: (base) => ({
    ...base,
    height: 50,
    minHeight: 35,
    fontWeight: "bold",
  }),
  container: (base) => ({
    ...base,
    height: 48,
  }),

  valueContainer: (base) => ({
    ...base,
    height: 48,
  }),
};

const findQty = (data) => {
  let qty = 0;

  if (data?.estimatedQuantity === data?.reviewedQuantity) {
    qty = data?.reviewedQuantity;
  } else if (data?.reviewedQuantity === 0) {
    qty = data?.estimatedQuantity;
  } else {
    qty = data?.reviewedQuantity;
  }

  return qty;
};

const MappingModal = ({
  locationData,
  data,
  reload,
  setReload,
  isLoading,
  setOpencartApiParams,
  opencartApiParams,
}) => {
  const { selectedIndex, setSelectedIndex } = useOpeneventDataProvider();

  const classes = useStyles();

  const history = useHistory();

  const [allScales, setAllScales] = useState();

  const [selectedShopper, setSelectedShopper] = useState();

  const [preImageList, setPreImageList] = useState([]);

  const [postImageList, setPostImageList] = useState([]);

  const [allImageList, setAllImageList] = useState([]);

  const [reviewQty, setReviewQty] = useState(0);

  const [openData, setOpenData] = useState([]);

  const [item, setItem] = useState();

  const [shoppersData, setShoppersData] = useState([]);

  const [photoGrouping, setPhotoGrouping] = useState([]);

  const [selectedRow, setSelectedRow] = useState();

  const [cartItemTask, setCartItemTask] = useState();

  const [selectedData, setSelectedData] = useState();

  const [recommendedProductList, setRecommendedProductList] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [shopperAckStatus, setShopperAckStatus] = useState("");

  const [isShopperPresent, setIsShopperPresent] = useState(false);

  const [selectAckStatus, setSelectAckStatus] = useState({
    label: "Select",
    value: "",
  });

  useEffect(() => {
    setReload((item) => !item);
  }, []);

  // useEffect(() => {
  //   if (data?.length === 0) setShoppersData([]);
  // }, [data]);

  //assign index from localStorage if available otherwise assign zero
  const [itemIndex, setItemIndex] = useState(
    localStorage.getItem("mIndex")
      ? parseInt(localStorage.getItem("mIndex"))
      : 0
  );

  const shopperAckOptions = [
    { label: "Shopper Present", value: true },
    { label: "Shopper Not Present", value: false },
  ];

  const onSelectRow = (data) => {
    var qty = 0;
    setSelectedRow(data);
    if (item?.productInfos?.length > 0) {
      const selectedProductInfo = item?.productInfos?.filter(
        (productInfo) => productInfo?.barCode === data?.barCode
      );
      if (selectedProductInfo?.length > 0) {
        // if (selectedProductInfo[0]["estimatedQuantity"] > 0) {
        qty = selectedProductInfo[0]["estimatedQuantity"];
        // }
      }
    }
    setReviewQty(qty);
  };

  //scale api
  const apiCall = (item) => {
    console.log("🚀 ~ file: MappingModal.js:117 ~ apiCall ~ item:", item);
    if (
      item &&
      item.scaleInternalCode !== "" &&
      item.scaleInternalCode !== null
    ) {
      //scale api
      console.log("🚀 ~ INSIDE_SCALEAPI", item);
      const temp = item?.scaleInternalCode?.split("-");
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/scale-products/shelf?sort=column,asc&retailStoreCode.equals=${item?.storeCode}&shelfName.equals=${temp[0]}-${temp[1]}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      }).then(({ data }) => {
        console.log("🚀 ~ file: MappingModal.js:145 ~ apiCall ~ data:", data);
        setAllScales(data);
      });

      // cart item task api
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_STORE_URL}/cart-item-tasks/store-cart/${item?.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      }).then(({ data }) => {
        setCartItemTask(data);
        setRecommendedProductList(data?.recommendedDecision?.product);

        if (data?.recommendedDecision?.product.length > 0) {
          setSelectedData(data?.recommendedDecision?.product[0]);
        }
      });

      //users api call
      const entryTime = moment(item?.eventTime)
        .subtract(60, "minutes")
        .utc()
        .format();

      axios({
        method: "GET",
        url: `${process.env.REACT_APP_STORE_URL}/user-activities/shoppers?entryTime.greaterThanOrEqual=${entryTime}&exitTime.greaterThanOrEqual=${item?.eventTime}&sort=entryTime,asc&size=100&page=0&productEventId.equals=${item?.id}&rootOrgId.equals=${item?.rootOrgId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      }).then((res) => {
        // storeFilteredStoreCode = res?.data?.filter((ele)=> ele?.retailStoreCode === item?.storeCode);
        const tempData = data.filter((ele) =>
          selectedIndex === 0
            ? ele.syncStatus === "CART_REVIEWED"
            : ele.syncStatus === "IN_REVIEW"
        );
        console.log(
          "🚀 ~ file: MappingModal.js:211 ~ apiCall ~ tempData:",
          tempData
        );
        const filterUser = res?.data?.filter(
          (ele) => ele?.retailStoreCode === item?.storeCode
        );
        console.log(
          "🚀 ~ file: MappingModal.js:213 ~ apiCall ~ filterUser:",
          filterUser
        );
        if (tempData?.length > 0) {
          const foundUsers = tempData
            ?.map((data) => {
              return filterUser?.find((user) => {
                return (
                  user?.cartRef === item?.userInfos?.[0]?.cartRef &&
                  user?.id === item?.userInfos?.[0]?.id
                );
              });
            })
            ?.filter((item) => item !== undefined);
          console.log(
            "🚀 ~ file: MappingModal.js:254 ~ foundUsers ~ foundUsers:",
            foundUsers
          );
          setSelectedShopper(foundUsers?.[0] ? foundUsers?.[0] : null);
        }
        setShoppersData(
          res?.data?.filter((ele) => ele?.retailStoreCode === item?.storeCode)
        );
      });
    }
  };
  //index decrease by 1
  const previous = () => {
    setSelectedShopper(null);
    if (itemIndex > 0) {
      setItemIndex(itemIndex - 1);
      localStorage.setItem("mIndex", parseInt(itemIndex - 1));
    }
  };

  //index increase by 1
  const next = () => {
    setSelectedShopper(null);
    if (itemIndex < openData?.length - 1) {
      setItemIndex(itemIndex + 1);
      localStorage.setItem("mIndex", parseInt(itemIndex + 1));
    } else {
      processCart();
    }
  };

  //set index as last
  const last = () => {
    setSelectedShopper(null);
    if (itemIndex < openData?.length) {
      setItemIndex(openData?.length - 1);
      localStorage.setItem("mIndex", parseInt(openData?.length - 1));
    }
  };

  //assign value in row-select
  useEffect(() => {
    if (item?.productId === null) {
      setSelectedRow();
    } else {
      const prod = item?.productInfos?.find(
        (ele) => ele?.productId === item?.productId
      );
      setSelectedRow(prod);
    }
  }, [item]);

  //shopper selection function
  const shopperSelection = (data) => {
    setSelectedShopper(data);
  };

  //find shopper
  const findShopper = (assignedId) => {
    return shoppersData.find((ele) => ele.id == assignedId);
  };

  //find confidence score
  const findConfidence = (userActivityId) => {
    return item?.recommendedShoppers?.find(
      (ele) => ele.userActivityId === userActivityId
    )?.confidenceScore;
  };

  //setInitial Value
  useEffect(() => {
    // setSelectedShopper();
    setReviewQty();
    setSelectedRow();
  }, []);

  const assignFn = (item, selectedShopper) => {
    if (selectedRow === undefined || selectedRow === "") {
      toast.error("No product selected");
    } else if (reviewQty === undefined) {
      toast.error("Enter valid qty");
    } else if (selectedShopper === undefined || selectedShopper === "") {
      toast.error("Select shopper");
    } else {
      const payload = {
        ...item,
        reviewedQuantity: reviewQty,
        productId: selectedRow?.productId,
        productInfos: [selectedRow],
        syncStatus: "APPROVED",
        userInfos: [
          {
            id: selectedShopper?.id,
            name: selectedShopper?.name,
            login: selectedShopper?.login,
            userRole: selectedShopper?.userType,
            cartRef: selectedShopper?.cartRef,
          },
        ],
      };

      axios({
        method: "PUT",
        url: `${process.env.REACT_APP_STORE_URL}/store-carts/${item?.id}/review-event`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
        data: payload,
      })
        .then(() => {
          setReload(!reload);
          setSelectedShopper();
          setReviewQty();
          setSelectedRow();
          // setSelectedIndex(1);
        })
        .catch(({ message }) => {
          if (message === "Request failed with status code 401") {
            history.push("/login");
          } else {
            // setSelectedIndex(1);
            setReload(!reload);
            toast.error(message);
          }
        });
    }
  };

  const unassignFn = (item) => {
    if (item) {
      confirmAlert({
        title: "Confirm to submit",
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              const payload = {
                id: item?.id,
                storeCode: item?.storeCode,
                cartItemRef: item?.cartItemRef,
                cartRef: item?.cartRef,
                rootOrgId: item?.rootOrgId,
                versionNumber: item?.versionNumber,
              };

              axios({
                method: "PUT",
                url: `${process.env.REACT_APP_STORE_URL}/store-carts/${item?.id}/unassign-event`,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("user-info")}`,
                },
                data: payload,
              })
                .then(() => {
                  setReload(!reload);
                  setSelectedShopper();
                  setReviewQty();
                  setSelectedRow();
                  setSelectedIndex(1);
                })
                .catch(({ message }) => {
                  if (message === "Request failed with status code 401") {
                    history.push("/login");
                  } else {
                    setSelectedIndex(1);
                    setReload(!reload);
                    toast.error(message);
                  }
                });
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    }
  };

  //process cart
  const processCart = async () => {
    const payload = {
      sessionRef: locationData?.sessionRef,
      rootOrgId: locationData?.rootOrgId
        ? locationData?.rootOrgId
        : locationData?.storeCartTokens[0]?.rootOrgId,
    };

    axios({
      method: "PUT",
      url: `${process.env.REACT_APP_SHOPPER_URL}/stores/cart-review`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
      data: payload,
    })
      .then(({ data }) => {
        localStorage.setItem("way", "indirect");
        history.push({
          pathname: "/support/hawkeye/cartSummary",
          state: { data: data },
        });
      })
      .catch(({ message }) => {
        if (message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          toast.error(message);
        }
      });
  };

  //at first filter all unassign items
  useEffect(() => {
    setOpenData([]);
    setItemIndex(0);
    localStorage.setItem("mIndex", parseInt(0));
    if (data !== undefined && data.length > 0) {
      console.log("inside IFFFF");
      setOpenData(data.filter((ele) => ele.syncStatus !== "CART_REVIEWED"));
    }
  }, [data]);

  const onChangeTabs = (value) => {
    setSelectedIndex(value);
  };

  //set Index
  useEffect(() => {
    if (selectedIndex === 0) {
      setOpenData(
        data !== undefined &&
          data.filter((ele) => ele.syncStatus === "CART_REVIEWED")
      );
      setItemIndex(0);
      localStorage.setItem("mIndex", parseInt(0));
    } else {
      setOpenData(
        data !== undefined &&
          data.filter((ele) => ele.syncStatus !== "CART_REVIEWED")
      );
      setItemIndex(0);
      localStorage.setItem("mIndex", parseInt(0));
    }
  }, [selectedIndex, data]);

  //setItem
  useEffect(() => {
    if (openData?.length > 0 && itemIndex >= 0) {
      console.log("useEffect setItem INSIDE IFF");
      apiCall(openData?.[itemIndex]);
      setItem("");
      setTimeout(() => {
        setItem(openData?.[itemIndex]);
      }, 3);
    }
  }, [itemIndex, openData]);

  //all users during shopping times and set quantity
  useEffect(() => {
    if (item) {
      if (item.syncStatus === "CART_REVIEWED") {
        setReviewQty(item?.productInfos[0]?.estimatedQuantity);
      } else {
        if (item?.productInfos?.length === 1) {
          setReviewQty(item?.productInfos[0]?.estimatedQuantity);
        }
      }
    }
  }, [item]);

  useEffect(() => {
    var preImageList = [];
    var postImageList = [];
    if (item) {
      preImageList = item.productEventImageDTOS.filter(
        (ele) => ele.tag === "PRE"
      );
      postImageList = item.productEventImageDTOS.filter(
        (ele) => ele.tag === "POST"
      );
    }
    var allImageList = [];
    if (preImageList.length > 0) {
      allImageList.push(preImageList[preImageList.length - 1]);
    }
    if (postImageList.length > 0) {
      allImageList.push(postImageList[postImageList.length - 1]);
    }

    setPreImageList(preImageList);
    setPostImageList(postImageList);
    setAllImageList(allImageList);

    if (item?.shopperAcknowledgedStatus === "PRESENT") {
      setShopperAckStatus("PRESENT");
      setIsShopperPresent(true);
      setSelectAckStatus({ label: "Shopper Present", value: true });
    } else {
      setShopperAckStatus("NOT_PRESENT");
      setIsShopperPresent(false);
      setSelectAckStatus({ label: "Shopper Not Present", value: false });
    }
  }, [item]);

  const shopperAckResponse = (e) => {
    setShopperAckStatus(e.target.value);
  };

  const handleSelect = (action, e) => {
    setIsShopperPresent(action.value);
    if (action.label === "Shopper Present") {
      setShopperAckStatus("PRESENT");
    } else {
      setShopperAckStatus("NOT_PRESENT");
    }
    setSelectAckStatus(action);
  };

  const rejectItem = () => {
    const payload = cartItemTask;
    payload["isShopperPresenceAcknowledgedByReviewer"] = true;
    payload["isShopperPresent"] = isShopperPresent;
    axios({
      method: "PUT",
      url: `${process.env.REACT_APP_STORE_URL}/cart-item-tasks/${cartItemTask?.id}/reject-task`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
      data: payload,
    })
      .then(() => {
        setReload(!reload);
        setSelectedShopper();
        setReviewQty();
        setSelectedRow();
      })
      .catch(({ message }) => {
        if (message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          setReload(!reload);
          toast.error(message);
        }
      });
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleRefresh = () => setReload(!reload);

  useEffect(() => {
    const result = {};

    shoppersData.forEach((item) => {
      const cartRef = item.cartRef;
      if (!result[cartRef]) {
        result[cartRef] = [];
      }
      result[cartRef].push(item);
    });

    const finalResult = Object.values(result);

    console.log("finalResult", finalResult);
    setPhotoGrouping(finalResult);
  }, [shoppersData]);

  useEffect(() => {
    if (openData?.length === 0) {
      setAllScales([]);
      setItemIndex(-1);
      setShoppersData([]);
    }
  }, [openData]);

  const productEventSummaryUrl = `${process.env.REACT_APP_STORE_URL}/store-carts/event-summary?sort=eventTime%2Casc&syncStatus.in=INITIALIZED%2CCONFLICT%2CERRORED%2CCART_REVIEWED%2CPROCESSED&page=0&size=100&storeCode.equals=${locationData?.storeCode}&eventTime.greaterThanOrEqual=${locationData.entryTime}&eventTime.lessThanOrEqual=${locationData?.checkoutDate}&cartRef.equals=${locationData?.sessionRef}`;

  const { data: productEventSummary } = useGet(
    productEventSummaryUrl,
    "productEventSummary"
  );

  // console.log(
  //   "openData...",
  //   openData?.[itemIndex],
  //   openData?.[itemIndex]?.groupId,
  //   openData?.[itemIndex]?.rootOrgId,
  //   allScales?.[0]?.retailStoreId
  // );

  const { data: shelveByID } = useGet(
    `${process.env.REACT_APP_BASE_URL}/shelves?internalCode.equals=${openData?.[itemIndex]?.groupId}&retailStoreId.equals=${allScales?.[0]?.retailStoreId}&sort=name%2Casc&rootOrgId.equals=${openData?.[itemIndex]?.rootOrgId}`,
    ["shelveByID", allScales],
    allScales?.length > 0
  );
  console.log("🚀 ~ file: MappingModal.js:647 ~ shelveByID:", shelveByID);

  const markDirtyApiCall = () => {
    const { id, name } = shelveByID?.[0] || {};
    const payload = {
      id,
      name,
      status: "DIRTY",
      rootOrgId: openData?.[itemIndex]?.rootOrgId,
    };

    axios({
      method: "PATCH",
      url: `${process.env.REACT_APP_BASE_URL}/shelves/${id}/status`,
      headers: {
        "Content-Type": "application/merge-patch+json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
      data: payload,
    })
      .then(() => {
        setReload(!reload);
        toast.success(`Shelf-${name} is marked as dirty`);
        setSelectedShopper();
        setReviewQty();
        setSelectedRow();
      })
      .catch(({ message }) => {
        if (message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          setReload(!reload);
          toast.error(message);
        }
      });
  };

  // useEffect(() => {
  //   if (selectedIndex === 1) {
  //     setSelectedShopper();
  //   }
  // }, [selectedIndex]);

  return (
    <div className="w-[100vw] h-[100vh]  text-left  z-50 absolute top-1/2 left-1/2   transform -translate-x-1/2 -translate-y-1/2">
      <div className="w-full h-full bg-white py-1 flex flex-col justify-between items-center">
        <MatchEventHeader
          filter={locationData?.sessionRef}
          locationData={locationData}
          handleOpenModal={handleOpenModal}
          onChangeTabs={onChangeTabs}
          handleRefresh={handleRefresh}
          opencartApiParams={opencartApiParams}
          setOpencartApiParams={setOpencartApiParams}
          selectedIndex={selectedIndex}
          data={data}
          itemIndex={itemIndex}
          productEventSummary={productEventSummary}
          openData={openData}
        />
        {/* Swipper videos */}
        <div className="w-full h-[45vh] flex justify-between items-center pl-8 pr-4 space-x-6">
          <div className="w-full h-full flex justify-center items-center">
            {item?.storeCartVideoPaths?.length > 0 && openData?.length > 0 ? (
              <VideoSwapper arr={item?.storeCartVideoPaths} playRate={1.5} />
            ) : (
              <div className="w-full h-[43vh] flex justify-center items-center bg-gray-200 shadow-lg rounded-lg">
                No video available
              </div>
            )}
          </div>

          {/* scales map */}
          {/* <div className="w-full h-full flex flex-col space-y-2"> */}
          {/* <div className="w-[97%] h-[40px] flex justify-between items-center bg-gray-200 rounded px-2"> */}
          {/* session strip */}
          {/* <div className="flex space-x-4">
                <p className="text-[17px] font-bold">
                  {item?.cartRef ? item?.cartRef : ""}
                </p>
                <p className="text-[17px] font-bold">{`ST : ${getTimeOnly(
                  locationData?.entryTime,
                  locationData?.storeTimeZone
                )} - ${getTimeOnly(
                  locationData?.checkoutDate,
                  locationData?.storeTimeZone
                )} - ${
                  locationData?.storeTimeZone
                    ? locationData?.storeTimeZone
                    : "CET"
                }`}</p>
                <p className="text-[17px] font-bold">{`ET : ${getTimeOnly(
                  item?.eventTime,
                  item?.storeTimeZone
                )} - ${item?.storeTimeZone ? item?.storeTimeZone : "CET"}`}</p>
              </div> */}

          {/* filter btn */}
          {/* <div className="flex space-x-4 items-center">
                <Tab.Group
                  selectedIndex={selectedIndex}
                  onChange={onChangeTabs}
                  className="flex items-center"
                >
                  <Tab.List>
                    <Tippy content="Assigned">
                      <Tab className="focus:outline-none">
                        {({ selected }) => (
                          <div>
                            <MdAssignmentTurnedIn
                              size={28}
                              className={`focus:outline-none ${
                                selected && "text-green-500"
                              }`}
                            />
                          </div>
                        )}
                      </Tab>
                    </Tippy>
                    <Tippy content="Not Assigned">
                      <Tab className="focus:outline-none">
                        {({ selected }) => (
                          <div>
                            <MdOutlineAssignmentReturned
                              size={28}
                              className={`focus:outline-none ${
                                selected && "text-green-500"
                              }`}
                            />
                          </div>
                        )}
                      </Tab>
                    </Tippy>
                  </Tab.List>
                </Tab.Group>

                <div className="w-8 h-8 flex text-[14px] font-bold text-white justify-center items-center rounded-full bg-pink-500">
                  {openData?.length}
                </div>

                <VscRefresh
                  className="cursor-pointer"
                  size={28}
                  onClick={() => setReload(!reload)}
                />
                <MdBackspace
                  className="cursor-pointer"
                  size={28}
                  onClick={() => history.goBack()}
                />
              </div> */}
          {/* </div> */}

          {/* tabel */}
          <div className="w-full text-left h-full scrollbar-thin scrollbar-thumb-white scrollbar-track-white">
            <div className="h-full w-[97%]">
              {allScales?.length > 0 ? (
                <DisclosureCmp
                  allScales={allScales}
                  item={item}
                  selectedRow={selectedRow}
                  onSelectRow={onSelectRow}
                />
              ) : (
                <div className="w-full h-full flex justify-center items-center font-bold">
                  Invalid item
                </div>
              )}
            </div>
          </div>
          {/* <div className="w-full text-left h-[55%]">
              <div className="mt-2 ml-1 h-full w-[97%] border_black">
                <table className="table_style">
                  <tr className="mb-2">
                    <th className="cell_style text-center">Pre Images</th>
                    <th className="cell_style text-center">Post Images</th>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full  justify-center items-center">
                        {preImageList?.length > 0 ? (
                          preImageList?.map((preImage, index) => (
                            <ProductEventImages
                              url={preImage.imageUrl}
                              index={index}
                              key={index}
                              allImages={allImageList}
                            />
                          ))
                        ) : (
                          <img
                            src={NoImage}
                            className="w-28 h-44"
                            alt="entryimage"
                          />
                        )}
                      </div>
                    </td>
                    <td>
                      <div className="flex w-full  justify-center items-center">
                        {postImageList?.length > 0 ? (
                          postImageList?.map((postImage, index) => (
                            <ProductEventImages
                              url={postImage.imageUrl}
                              index={index}
                              key={index}
                              allImages={allImageList}
                            />
                          ))
                        ) : (
                          <img
                            src={NoImage}
                            alt="NoImage"
                            className="w-28 h-40 rounded cursor-pointer"
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                </table>
                <hr />
                <div className="mt-2 ml-2 items-center">
                  <p className="font-bold mb-2">Recommended Product</p>
                  <SwipperText
                    arr={recommendedProductList}
                    selectedData={selectedData}
                    setSelectedData={setSelectedData}
                  />
                </div>
              </div>
            </div> */}
          {/* </div> */}
        </div>

        {/* shopper loop */}
        <div className="w-full px-10 flex justify-center items-center mt-[4px]">
          <div className="w-full h-full flex justify-between">
            <div className="h-full overflow-x-auto w-full flex justify-between items-center">
              <div className="w-[100%] h-full flex space-x-4 z-[100] justify-start  items-start">
                {photoGrouping?.length > 0 ? (
                  photoGrouping?.map((item, i) => {
                    return (
                      <div
                        style={{
                          border:
                            item?.length > 1
                              ? "1px solid red"
                              : "1px solid blue",
                          display: "flex",
                        }}
                      >
                        {item.map((obj, index) => {
                          return (
                            <div
                              className="w-29 cursor-pointer "
                              onClick={() =>
                                item?.syncStatus === "CART_REVIEWED" ||
                                item?.syncStatus === "PROCESSED"
                                  ? void 0
                                  : shopperSelection(obj)
                              }
                            >
                              <ReactHover
                                options={{
                                  followCursor: true,
                                  shiftX: 0,
                                  shiftY: -300,
                                }}
                              >
                                <Trigger type="trigger">
                                  <div
                                    className={`w-29 flex flex-col justify-center  items-center 
                                    ${
                                      obj.id === selectedShopper?.id &&
                                      "border-4 border-[#558b2f] bg-[#c5e1a5]"
                                    }
                                    
                                    ${
                                      (item?.syncStatus === "CART_REVIEWED" ||
                                        item?.syncStatus === "PROCESSED") &&
                                      openData?.length > 0 &&
                                      findShopper(item?.userInfos[0]?.id)
                                        ?.id === obj?.id &&
                                      "border-4 border-[#558b2f] bg-[#c5e1a5]"
                                    }`}
                                    key={index}
                                  >
                                    {obj.id === selectedShopper?.id && (
                                      <b>Selected</b>
                                    )}
                                    {obj && obj?.userImagePaths[0] ? (
                                      <img
                                        src={obj?.userImagePaths[0]}
                                        alt="event"
                                        className="w-40 h-40 rounded cursor-pointer"
                                      />
                                    ) : (
                                      <img
                                        src={NoImage}
                                        alt="No Image"
                                        className="w-29 h-40 rounded cursor-pointer border"
                                      />
                                    )}

                                    <p className="text-[15px] font-bold">
                                      {obj?.confidenceScore}
                                    </p>

                                    <p className="text-[15px] font-bold">
                                      {obj.cartRef?.split("_")?.[1]}
                                    </p>
                                    <p className="text-[15px] font-bold text-green">
                                      {obj?.isShopperLoopRecommended
                                        ? "Recommended"
                                        : ""}
                                    </p>
                                  </div>
                                </Trigger>
                                <Hover type="hover">
                                  {obj && obj?.userImagePaths[0] ? (
                                    <div className="p-1 bg-gray-900 shadow-lg z-50">
                                      <img
                                        src={obj?.userImagePaths[0]}
                                        alt="event"
                                        className="w-[100%] h-[100%] rounded cursor-pointer"
                                      />
                                    </div>
                                  ) : (
                                    <img
                                      src={NoImage}
                                      alt="No Image"
                                      className="w-29 h-40 rounded cursor-pointer border"
                                    />
                                  )}
                                </Hover>
                              </ReactHover>
                            </div>
                          );
                        })}
                      </div>
                    );
                    // return (
                    //   <div
                    //     className="w-28 cursor-pointer "
                    //     onClick={() =>
                    //       item?.syncStatus === "CART_REVIEWED" ||
                    //       item?.syncStatus === "PROCESSED"
                    //         ? void 0
                    //         : shopperSelection(obj)
                    //     }
                    //   >
                    //     <ReactHover
                    //       options={{
                    //         followCursor: true,
                    //         shiftX: 0,
                    //         shiftY: -300,
                    //       }}
                    //     >
                    //       <Trigger type="trigger">
                    //         <div
                    //           className={`w-28 flex flex-col justify-center  items-center
                    //                   ${
                    //                     obj.id === selectedShopper?.id &&
                    //                     "border-4 border-[#558b2f]"
                    //                   }

                    //                   ${
                    //                     (item?.syncStatus === "CART_REVIEWED" ||
                    //                       item?.syncStatus === "PROCESSED") &&
                    //                     openData?.length > 0 &&
                    //                     findShopper(item?.userInfos[0]?.id)
                    //                       ?.id === obj?.id &&
                    //                     "border-4 border-[#558b2f]"
                    //                   }`}
                    //           key={index}
                    //         >
                    //           {obj && obj?.userImagePaths[0] ? (
                    //             <img
                    //               src={obj?.userImagePaths[0]}
                    //               alt="event"
                    //               className="w-28 h-40 rounded cursor-pointer"
                    //             />
                    //           ) : (
                    //             <img
                    //               src={NoImage}
                    //               alt="No Image"
                    //               className="w-28 h-40 rounded cursor-pointer border"
                    //             />
                    //           )}

                    //           <p className="text-[15px] font-bold">
                    //             {obj?.confidenceScore}
                    //           </p>

                    //           <p className="text-[15px] font-bold">
                    //             {obj.cartRef?.split("_")?.[1]}
                    //           </p>
                    //           <p className="text-[15px] font-bold text-green">
                    //             {obj?.isShopperLoopRecommended
                    //               ? "Recommended"
                    //               : ""}
                    //           </p>
                    //         </div>
                    //       </Trigger>
                    //       <Hover type="hover">
                    //         {obj && obj?.userImagePaths[0] ? (
                    //           <div className="p-1 bg-gray-900 shadow-lg z-50">
                    //             <img
                    //               src={obj?.userImagePaths[0]}
                    //               alt="event"
                    //               className="w-[100%] h-[100%] rounded cursor-pointer"
                    //             />
                    //           </div>
                    //         ) : (
                    //           <img
                    //             src={NoImage}
                    //             alt="No Image"
                    //             className="w-28 h-40 rounded cursor-pointer border"
                    //           />
                    //         )}
                    //       </Hover>
                    //     </ReactHover>
                    //   </div>
                    // );
                  })
                ) : (
                  <img
                    src={NoImage}
                    alt="NoImage"
                    className="w-28 h-40 rounded cursor-pointer"
                  />
                )}
              </div>
              {/* <div className="w-[20%] h-full flex space-x-4 z-[100] justify-center items-end">
                <div className="flex items-right justify-right space-x-2">
                  <div className=" h-full flex flex-col justify-center items-center">
                    <table>
                      <tr height="40px">
                        <td width="30px">
                          <input
                            type="radio"
                            name="shopperAck"
                            value="PRESENT"
                            onChange={shopperAckResponse}
                            className="h-full flex w-full"
                            checked={shopperAckStatus === 'PRESENT' ? true : false}
                          />
                        </td>
                        <td>
                        <span>Shopper Present</span>
                        </td>
                      </tr>
                      <tr height="40px">
                        <td width="30px">
                          <input
                            type="radio"
                            name="shopperAck"
                            value="NOT_PRESENT"
                            onChange={shopperAckResponse}
                            className="h-full flex w-full"
                            checked={shopperAckStatus === 'NOT_PRESENT' ? true : false}
                          />
                        </td>
                        <td>
                        <span>Shopper Not Present</span>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <button
                            className="text-[20px] cursor-poiner bg-gray-500 active:bg-gray-600
                              text-white rounded px-4 py-0.2 focus:outline-none mt-2"
                            onClick={rejectItem}
                          >
                            Mark As Noise
                          </button>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        {/* next stripe */}
        {item && openData?.length > 0 ? (
          <div className="h-[12vh] w-full  px-8 mt-2">
            <div
              className={`w-full h-full flex justify-between items-center  ${
                selectedShopper ||
                item?.syncStatus === "CART_REVIEWED" ||
                item?.syncStatus === "PROCESSED"
                  ? "bg-[#c5e1a5] text-white"
                  : "bg-gray-200"
              } rounded-lg px-4`}
            >
              <div className="w-[150px] flex flex-col items-center justify-between">
                <p className="text-[25px] font-bold">
                  {item?.scaleInternalCode}
                </p>
                <p className="text-[17px] font-bold">
                  {item?.eventTime
                    ? getTimeOnly(item?.eventTime, item?.storeTimeZone)
                    : "Null"}
                </p>
              </div>

              {/* {selectedRow && item?.scaleInternalCode !== null && ( */}
              <>
                <div className="h-full flex items-center space-x-4 z-[100]">
                  {/* product img hover */}
                  {selectedRow && item?.scaleInternalCode !== null && (
                    <div className="w-full z-[100] h-full space-x-4  flex  items-center justify-center my-[10px] ">
                      <ReactHover
                        options={{
                          followCursor: true,
                          shiftX: 20,
                          shiftY: -300,
                        }}
                      >
                        {/* to show initial image */}
                        <Trigger type="trigger">
                          <div className="w-[8vh] h-[8vh]">
                            <img
                              src={selectedRow?.imageUrl}
                              className="w-full h-full p-0.5 rounded"
                              alt="image"
                            />
                          </div>
                        </Trigger>
                        {/* to show the hover image */}
                        <Hover type="hover">
                          <img
                            src={selectedRow?.imageUrl}
                            className="w-[60%] h-[60%]"
                            alt="image"
                          />
                        </Hover>
                      </ReactHover>

                      <div className="flex flex-col justify-between">
                        <p className="text-[20px] font-bold  ">
                          {selectedRow?.productName || selectedRow?.name}
                        </p>

                        <p className="text-[21px] font-bold text-yellow-500 ">{` ${parseFloat(
                          selectedRow?.unitWeight
                        )?.toFixed(3)} ${selectedRow?.unitOfMeasure}`}</p>
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex items-center ">
                  {/* input and kept-put code only for length === 1*/}
                  {item?.productInfos.length === 1 ? (
                    <div className="flex items-center text-left space-x-4">
                      <p className="text-[21px] font-bold">{` ${parseFloat(
                        item?.productInfos[0]?.estimatedChangeInWeight
                      )?.toFixed(3)} ${
                        item?.productInfos[0]?.unitOfMeasure
                      }`}</p>

                      <input
                        className="text-[25px] rounded text-center text-black focus:outline-none border-2 w-[100px] font-bold"
                        value={reviewQty}
                        disabled={
                          item?.syncStatus === "CART_REVIEWED" ||
                          item?.syncStatus === "PROCESSED"
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          setReviewQty(e.target.value);
                        }}
                      />

                      {item?.eventType === "PICK" ? (
                        <MdArrowUpward size={28} className=" text-green-500 " />
                      ) : (
                        <MdArrowDownward size={28} className=" text-red-500 " />
                      )}
                    </div>
                  ) : (
                    selectedRow &&
                    item?.scaleInternalCode !== null && (
                      <div className="flex items-center text-left space-x-4">
                        {/* input and kept-put code only for length > 1*/}

                        <p className="text-[21px] font-bold">{` ${parseFloat(
                          item?.productInfos[0]?.estimatedChangeInWeight
                        )?.toFixed(3)} ${
                          item?.productInfos[0]?.unitOfMeasure
                        }`}</p>

                        <div className="flex items-center text-left ">
                          <input
                            className="text-[25px] rounded text-center text-black focus:outline-none border-2 font-bold w-[100px]"
                            value={reviewQty}
                            disabled={
                              item?.syncStatus === "CART_REVIEWED" ||
                              item?.syncStatus === "PROCESSED"
                                ? true
                                : false
                            }
                            onChange={(e) => setReviewQty(e.target.value)}
                          />
                        </div>

                        {item?.eventType === "PICK" ? (
                          <MdArrowUpward
                            size={28}
                            className=" text-green-500 "
                          />
                        ) : (
                          <MdArrowDownward
                            size={28}
                            className=" text-red-500 "
                          />
                        )}
                      </div>
                    )
                  )}
                </div>
                {/* <div className="flex space-x-4"> */}
                <div className="h-[96%] rounded-lg flex flex-col bg-white px-2">
                  {/* <Select
                      maxMenuHeight={162}
                      value={selectAckStatus}
                      name="shopperAckStatus"
                      onChange={handleSelect}
                      options={shopperAckOptions}
                    /> */}
                  <b className="text-center" style={{ color: "#000" }}>
                    Shopper Presence
                  </b>
                  <div className="flex items-center">
                    <Checkbox
                      label="Shopper is present"
                      onChange={(e) =>
                        handleSelect(
                          { label: "Shopper Present", value: true },
                          e
                        )
                      }
                      checked={shopperAckStatus === "PRESENT"}
                    />
                    <p style={{ color: "#000" }}>Shopper is present</p>
                  </div>
                  <div className="flex items-center">
                    <Checkbox
                      label="Shopper is not present"
                      onChange={(e) =>
                        handleSelect(
                          { label: "Shopper Not Present", value: false },
                          e
                        )
                      }
                      checked={shopperAckStatus === "NOT_PRESENT"}
                    />
                    <p style={{ color: "#000" }}>Shopper is not present</p>
                  </div>
                </div>
                {/* </div> */}
                <div className="h-full w-[225px] flex justify-between flex-col py-4">
                  {/* <Button className={classes.greyButton} onClick={rejectItem}>
                    Mark Noise
                  </Button> */}
                  <div className="flex w-[100%] justify-between items-center">
                    <Button className={classes.greyButton} onClick={rejectItem}>
                      Mark Noise
                    </Button>
                    <Button
                      className={classes.greyButton}
                      onClick={markDirtyApiCall}
                    >
                      Mark Dirty
                    </Button>
                  </div>
                  <Button
                    className={classes.greenButton}
                    onClick={() => {
                      if (
                        item?.syncStatus === "CART_REVIEWED" ||
                        item?.syncStatus === "PROCESSED"
                      ) {
                        unassignFn(item);
                      } else {
                        assignFn(item, selectedShopper);
                      }
                    }}
                  >
                    {item?.syncStatus === "CART_REVIEWED" ||
                    item?.syncStatus === "PROCESSED"
                      ? "Unassign"
                      : "Assign"}
                  </Button>
                </div>
              </>
              {/* )} */}

              {/* <div className="flex space-x-4"> */}
              {/* assign */}
              {/* <div className=" h-full flex flex-col justify-center items-center">
                  {!isLoading && (
                    <button
                      onClick={() => {
                        if (
                          item?.syncStatus === "CART_REVIEWED" ||
                          item?.syncStatus === "PROCESSED"
                        ) {
                          unassignFn(item);
                        } else {
                          assignFn(item, selectedShopper);
                        }
                      }}
                      className={`text-[25px] cursor-poiner ${
                        item?.syncStatus === "CART_REVIEWED" ||
                        item?.syncStatus === "PROCESSED"
                          ? "bg-red-500 active:bg-red-600"
                          : "bg-gray-500 active:bg-gray-600"
                      }  text-white rounded px-4 py-0.2 focus:outline-none`}
                    >
                      {item?.syncStatus === "CART_REVIEWED" ||
                      item?.syncStatus === "PROCESSED"
                        ? "Unassign"
                        : "Assign"}
                    </button>
                  )}
                </div> */}

              {/* next - forward btn */}
              {/* <div className="flex justify-between items-center px-4 space-x-4">
                  <FaBackward
                    size={28}
                    onClick={() => previous()}
                    className={`${
                      itemIndex === 0
                        ? "text-gray-400"
                        : "text-blue-400  active:text-blue-700"
                    } cursor-pointer`}
                  />

                  {itemIndex === openData.length - 1 ? (
                    <FaFastForward
                      size={28}
                      onClick={() => next()}
                      className={`${
                        itemIndex === openData.length - 1
                          ? "text-red-500"
                          : "text-blue-400 active:text-blue-700"
                      } cursor-pointer`}
                    />
                  ) : (
                    <>
                      <FaForward
                        size={28}
                        onClick={() => next()}
                        className="text-blue-400 active:text-blue-700 cursor-pointer"
                      />

                      <FaFastForward
                        size={28}
                        onClick={() => last()}
                        className={`${
                          itemIndex === openData.length - 1
                            ? "text-red-500"
                            : "text-blue-400 active:text-blue-700 cursor-pointer"
                        } `}
                      />
                    </>
                  )}
                </div> */}
              {/* </div> */}
              <div className="h-full w-[225px] flex justify-between flex-col py-4">
                <div className="flex justify-between items-center">
                  <Button
                    className={classes.greyButton}
                    onClick={() => previous()}
                    sx={{ width: "48%" }}
                  >
                    Previous
                  </Button>
                  <Button
                    className={classes.greyButton}
                    sx={{ width: "48%" }}
                    onClick={() => next()}
                  >
                    Next
                  </Button>
                </div>
                <Button
                  className={classes.greenButton}
                  onClick={() => processCart()}
                >
                  Final Cart Review
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full h-[12vh] flex justify-end px-10 items-center my-6">
            {/* <FaFastForward
              size={28}
              onClick={() => processCart()}
              className="text-red-500 cursor-pointer"
            /> */}
            <Button
              className={classes.greenButton}
              onClick={() => processCart()}
            >
              Final Cart Review
            </Button>
          </div>
        )}
      </div>
      {isModalOpen && (
        <ProductRecognizationModal
          handleOpenModal={handleOpenModal}
          isModalOpen={isModalOpen}
          handleCloseModal={handleCloseModal}
          preImageList={preImageList}
          postImageList={postImageList}
          allImageList={allImageList}
          recommendedProductList={recommendedProductList}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
        />
      )}
    </div>
  );
};

export default MappingModal;
