import React from "react";
import { useHistory } from "react-router-dom";


function Protected(props) {
  let Cmp = props.Cmp;
  const history = useHistory();

  if (!localStorage.getItem("user-info")) {
    history.push("/login");
  }

  if (localStorage.getItem("role") === "admin") {
    history.push("/admin");
  }

  if (localStorage.getItem("role") === "merchant") {
    history.push("/admin/merchant/dashboard");
  }

  if (localStorage.getItem("role") === "support") {
    history.push("/support");
  }

  return <Cmp />
}

export default Protected;
