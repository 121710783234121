import React, {lazy, Suspense} from "react";
import { Route, Switch } from "react-router-dom";

import Login from "./pages/Login"
import NeedHelp from "./pages/NeedHelp"
import MerchantDashboard from "./pages/admin/merchant/dashboard/MerchantDashboard"
import SupportDashboard from "./pages/support/dashboard/SupportDashboard"
import Protected from "./pages/Protected"
import index from "./pages/index"

const App = () => {
  return (
    <>
      <Switch>
        <Route path="/login" exact component={Login} />
        <Route path="/need" exact component={NeedHelp} />
        <Route path="/admin/merchant/dashboard/">
          <MerchantDashboard />
        </Route>
        <Route path="/support">
          <SupportDashboard />
        </Route>
        <Route path="/">
          <Protected Cmp={index} />
        </Route>
      </Switch>  
    </>
  );
};

export default App;
