import React, { useState } from "react";
import VideoModal from "./VideoModal";
import { IoPlayOutline } from "react-icons/io5";

function StoreOpenCardThumbnail({ imageUrl, videoUrl, index, camera }) {

  const [video, setVideo] = useState(false);
  const onOpenVideo = () => setVideo(true);
  const onCloseVideo = () => setVideo(false);
  return (
    <div key={index}>
      <div
        className="w-full  h-full flex justify-center items-center cursor-pointer relative"
        onClick={onOpenVideo}
      >
        <img src={imageUrl} className="w-32 h-32 rounded" />

        <IoPlayOutline size={28} color="white" className="absolute" />
      </div>

      <VideoModal
        open={video}
        onCloseModal={onCloseVideo}
        videoUrl={videoUrl}
        camera={camera}
      />
    </div>
  );
}

export default StoreOpenCardThumbnail;
