import React from "react";
import FadeLoader from "react-spinners/FadeLoader";

function SearchData({
  input,
  onChangeInput,
  filteredData,
  searchDataFn,
  setSearchData,
  loading,
  label,
  name,
}) {
  return (
    <div className=" w-full my-1 ">
      <div className="flex w-full">
        <input
          placeholder={label}
          value={input[name]}
          onChange={(e) => onChangeInput(e, name)}
          onKeyDown={(e) => { if (e.key === 'Backspace') {setSearchData(null)}}}
          className="w-full h-10  focus:outline-none rounded px-4 border border-black"
        />
      </div>
      {filteredData && input[name].length !== 0 && (
        <div
          className={`bg-gray-200  w-72 min-h-[20px] max-h-[160px] rounded-b-lg flex  flex-col border-t border-white absolute z-10 scrollbar-thin scrollbar-thumb-gray-900 scrollbar-track-gray-100`}
        >
          <>
            <div
              className={
                loading
                  ? "bg-black bg-opacity-0 absolute inset-0 z-10 flex items-center justify-center"
                  : null
              }
            >
              <FadeLoader className="absolute " loading={loading} size={150} />
            </div>
            {filteredData?.length ? (
              <>{filteredData?.map((data, index) => {
                return (
                  <div
                    key={index}
                    className="cursor-pointer border-b border-white py-2"
                    onClick={() => searchDataFn(name, data)}
                  >
                    <p className="text-left px-4 text-xs">{data?.name}</p>
                    {/* <img src={product?.imageUrl} className="w-12 h-12"/> */}
                  </div>
                );
              })}</>
            ):(
              <div className="w-full h-full flex justify-center items-center">
                <p>No data found !</p>
              </div>
            )}
          </>
        </div>
      )}
    </div>
  );
}

export default SearchData;


