import React from "react";
import * as s from "./MainView.styles";
import Routes from "../../Routes";
import Header from "../../components/Header";

const MainView = () => {
  return (
    <s.MainViewContainer>
      <div>
        <Header />
        <Routes />
      </div>
    </s.MainViewContainer>
  );
};

export default MainView;
