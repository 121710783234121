import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TextField2 } from "../../validation/TextField2";
import { useHistory, useLocation } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { useAlert } from "react-alert";
import { usePut } from "../../../api/usePut";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import { useDisplayImage } from "../../../hook/useDisplayImage";
import Toggle from "react-toggle";
import { toast } from 'react-toastify';

const getLabel = (arr, value) => {
  return arr?.find((item) => item?.value === value);
};

let validate = Yup.object({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  login: Yup.string().required("Required"),
  email: Yup.string().email().typeError("Not a number"),
  password: Yup.string(),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Password must match"
  ),
  phone: Yup.number().required("Required"),
});

const useStyles = makeStyles((theme) => ({
  root: {
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  large: {
    width: theme.spacing(25),
    height: theme.spacing(25),
  },
}));

function EmployeeEdit(props) {
  const [title, setTitle] = useState("Employee");
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const employeeData = location?.state?.data;
  const [gender, setGender] = useState(employeeData?.userInfo?.gender);
  const classes = useStyles();
  const { result, uploader } = useDisplayImage();
  const [status, setStatus] = useState(employeeData?.activated);

  const [updateEmployee, setUpdatedEmployee] = useState(employeeData);

  const statusChecked = (e) => {
    setStatus(e.target.checked);
    setUpdatedEmployee({ ...updateEmployee, ["activated"]: e.target.checked });
  };

  const genderType = (e) => {
    setGender(e.target.value);

    setUpdatedEmployee({
      ...updateEmployee,
      ["userInfo"]: { ...updateEmployee?.userInfo, gender: e.target.value },
    });
  };

  const editEmployee = usePut(
    `${process.env.REACT_APP_BASE_URL}/users`,
    "employeesList"
  );

  return (
    <>
      <div className="w-full h_screen flex text-left select-none">
        <div className="w-full h-full flex justify-center items-center bg-gray-200 ">
          <Formik
            initialValues={{
              phone: updateEmployee?.userInfo?.phone,
              firstName: updateEmployee?.firstName,
              login: updateEmployee?.login,
              lastName: updateEmployee?.lastName,
              email: updateEmployee?.email,
            }}
            validationSchema={validate}
            onSubmit={(values) => {
              if (values.name !== "") {
                try {
                  const payload = {
                    ...updateEmployee,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    login: values.login,
                    email: values.email,
                    langKey: "en_US",
                    authorities: [],
                    ["userInfo"]: {
                      ...updateEmployee?.userInfo,
                      phone: values.phone,
                      password: values.password,
                      confirmPassword: values.confirmPassword,
                    },
                  }
                  editEmployee.mutate(payload);
                  toast.success("Updated Successfully")
                  history.goBack()
                } catch (error) {
                  toast.error(error.message)
                }
              }
            }}
          >
            {() => (
              <Form className="w-full h-auto m-16 bg-white rounded-lg shadow-lg">
                <div className="w-full h-16 flex bg-nav text-white rounded-t-lg">
                  <div className="w-10 p-2 h-full flex items-center">
                    <BiArrowBack
                      onClick={() => {
                        history.goBack();
                      }}
                      className="h-10 w-10 cursor-pointer"
                    />
                  </div>
                  <div className="w-full h-full px-7 flex flex-col justify-center">
                    <h2 className="font-bold text-2xl">{`Edit ${title}`}</h2>
                  </div>
                </div>

                <div className="w-full h-[88%] flex my-8">
                  <div className="w-4/6  px-6">
                    <div className="w-full h-20 flex items-center">
                      <div className="flex  px-4 mr-4 w-1/2 items-center select-none ">
                        <TextField2 label="Phone No" name="phone" type="text" />
                      </div>

                      <div className="w-1/2 flex px-4 ml-4 justify-between  items-center ">
                        <h2 className="text-base pr-4">Gender: </h2>
                        <div className="flex h-10 items-center w-[225px]  justify-between">
                          <input
                            type="radio"
                            name="gender"
                            value="MALE"
                            checked={gender === "MALE" ? true : false}
                            onChange={genderType}
                          />
                          <span>Male</span>
                          <input
                            type="radio"
                            name="gender"
                            value="FEMALE"
                            checked={gender === "FEMALE" ? true : false}
                            onChange={genderType}
                          />
                          <span>Female</span>
                          <input
                            type="radio"
                            name="gender"
                            value="OTHER"
                            checked={gender === "OTHER" ? true : false}
                            onChange={genderType}
                          />
                          <span>Other</span>
                        </div>
                      </div>
                    </div>

                    {/* <div className="w-full flex justify-between items-center my-2">
                      <div className="w-1/2 flex justify-end items-center px-4 mr-4 ">
                        <SearchData
                          name="merchant"
                          label={"Search Merchant"}
                          input={input}
                          onChangeInput={onChangeInput}
                          filteredData={filteredData}
                          searchDataFn={searchDataFn}
                          loading={loading}
                        />
                        <BiSearchAlt className="w-12  h-5 mr-4 absolute   text-blue-500" />
                      </div>                     
                    </div> */}

                    <div className="w-full h-20 flex mt-1  items-center">
                      <div className="flex  px-4 mr-4   w-full items-center select-none">
                        <TextField2
                          label="First Name"
                          name="firstName"
                          type="text"
                        />
                      </div>

                      <div className="flex  px-4 ml-4   w-full items-center select-none">
                        <TextField2
                          label="Last Name"
                          name="lastName"
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="w-full h-20 flex mt-1  items-center">
                      <div className="flex  px-4 mr-4   w-full items-center select-none">
                        <TextField2 label="Login" name="login" type="text" />
                      </div>

                      <div className="flex  px-4 ml-4   w-full items-center select-none">
                        <TextField2 label="Email" name="email" type="text" />
                      </div>
                    </div>

                    {/* <div className="w-full h-20 flex items-center">
                      <div className="flex  px-4 mr-4  w-full items-center select-none">
                        <TextField2
                          label="Password"
                          name="password"
                          type="password"
                        />
                      </div>

                      <div className="flex  px-4 ml-4   w-full items-center select-none">
                        <TextField2
                          label="Confirm Password"
                          name="confirmPassword"
                          type="password"
                        />
                      </div>
                    </div> */}
                  </div>

                  <div className="w-1/3 my-4 mr-8 flex flex-col justify-between items-center">
                    {/* Image uploading */}
                    <div className="w-full h-52  rounded-lg  flex justify-center items-center">
                      <div className={classes.root}>
                        <input
                          accept="image/*"
                          className={classes.input}
                          id="icon-button-file"
                          type="file"
                          onChange={(e) => {
                            uploader(e);
                          }}
                        />
                        <label htmlFor="icon-button-file">
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                          >
                            <Avatar
                              src={result}
                              className={`${classes.large}`}
                            />
                          </IconButton>
                        </label>
                      </div>
                    </div>

                    <div className="ml-2">
                      <div className="w-full   py-4 h-full flex   items-center rounded-lg">
                        <span className=" mr-4">Activated</span>
                        <Toggle
                          onChange={statusChecked}
                          checked={status}
                          //icons={{ checked: null, unchecked: null }}
                          className="react-switch"
                        />
                      </div>
                    </div>

                    <div className="w-full flex justify-center">
                      <button
                        type="submit"
                        className="bg-green-500 rounded-lg w-[120px] h-12 focus:outline-none text-white"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default EmployeeEdit;
