import React, { useState } from "react";

function TableStr({ listUrl, getName, dashboard, title, All }) {
  const [click, setClicked] = useState("all");
  // const { isLoading, error, data } = useGet(listUrl, getName);
  const options = (e) => {
    const type = e.target.name;
    switch (type) {
      case "all":
        setClicked(type);
        break;
      default:
    }
  };

  return (
    <div className="w-full h-full shadow-lg select-none">
      <div
        className={`w-full ${
          dashboard ? "h-16" : "h-12"
        } flex justify-between p-6 items-center bg-gray-100`}
      >
        <div>
          <h2 className="font-bold text-2xl">{title}</h2>
        </div>
      </div>

      {/* <div className="w-full h-10 p-2 mt-2 ">
                <div className="h-10 w-full  flex justify-start ">
                    <button
                        onClick={options}
                        name="all"
                        className={`mx-4 border-b-2 ${
                        click === "all" ? "border-blue-500" : null
                        } focus:outline-none`}
                    >
                        All
                    </button>
                </div>
            </div> */}
      {click === "all" ? (
        <All
          // data={data}
          // isLoading={isLoading}
          // error={error}
          // viewUrl={viewUrl}
          // editUrl={editUrl}
          // orgList={orgList}
          // retailerList={retailerList}
          listUrl={listUrl}
          getName={getName}
        />
      ) : null}
    </div>
  );
}

export default TableStr;
