import React from 'react'

function welcome() {
    return (
        <div className="h-full w-full ">
            <div className="w-full h-full flex items-center justify-center">
                  <h2 className="font-bold text-4xl text-dashback">{JSON.parse(localStorage.getItem("dashboard-data"))?.name}</h2>
            </div>
        </div>
    )
}

export default welcome
