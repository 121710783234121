import React, { useState, useEffect } from "react";
import Action from "./Action";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import Address from "./Address";
import Select from "react-select";
import DashboardRoute from "./DashboardRoute";
import Confirm from "./Confirm";
import ModalEdit from "../../../../../../../modal/edit/ModalEsl";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { FiEdit } from "react-icons/fi";
import { useGet } from "../../../../../../../../api/useGet";
import Pagination from "@mui/material/Pagination";
import { useQuery } from "react-query";
import { GrView } from "react-icons/gr";

function All({
  url,
  viewUrl,
  editUrl,
  sortedData,
  allRetailerStore,
  listUrl,
  eslTemplateTypeList,
}) {
  const [checked, setChecked] = useState(false);
  const history = useHistory();
  const [value, setValue] = useState("");
  const [esl, setEsl] = useState({});
  const [selectedData, setSelectedData] = useState({});
  const [status, setStatus] = useState();
  const [allCount, setAllCount] = useState();

  const [edit, setEdit] = useState(false);
  const onOpenEdit = () => setEdit(true);
  const onCloseEdit = () => setEdit(false);
  const [activePage, setActivePage] = useState(1);

  const storeList = [];
  allRetailerStore?.forEach((store) => {
    const storeData = {
      label: store?.name,
      value: store?.id,
    };
    storeList.push(storeData);
  });

  const [search, setSearch] = useState({
    eslBarCode: "",
    storeId: "",
  });

  const handleInput = (value, name) => {
    setSearch({ ...search, [name]: value });
  };

  const findUrl = () => {
    let queryUrl = listUrl;
    if (search?.eslBarCode !== "") {
      setActivePage(1);
      queryUrl = `${queryUrl}&eslBarCode.contains=${search?.eslBarCode}`;
    }

    if (search?.storeId !== "") {
      queryUrl = `${queryUrl}&storeId.equals=${search?.storeId}`;
    }

    return queryUrl;
  };

  const fetchData = async (page) => {
    try {
      const res = await axios({
        method: "GET",
        url: `${findUrl()}&page=${page - 1}&size=10`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      });
      setAllCount(res?.headers?.["x-total-count"]);
      return res.data;
    } catch ({ message }) {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message);
      }
    }
  };

  const { data, isLoading, isIdle, error } = useQuery(
    ["eslList", activePage, search?.eslBarCode, search?.storeId],
    async () => fetchData(activePage),
    {
      enabled: !!activePage || !!search?.eslBarCode || !search?.storeId,
      cacheTime: Infinity,
      staleTime: 0,
    }
  );

  // const fetchOrgConfig = async () => {
  //   try {
  //     const rootOrgId = JSON.parse(localStorage.getItem("dashboard-data")).id;
  //     const orgConfigQueryUrl = `${process.env.REACT_APP_BASE_URL}/org-configurations/${rootOrgId}`;
  //     const res = await axios({
  //       method: "GET",
  //       url: orgConfigQueryUrl,
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${localStorage.getItem("user-info")}`,
  //       },
  //     });
  //     return res.data;
  //   } catch ({ message }) {
  //     if (message === "Request failed with status code 401") {
  //       history.push("/login");
  //     } else {
  //       toast.error(message)
  //     }
  //   }
  // }

  // useEffect(() => {
  //   fetchOrgConfig()
  //   .then((data) => {
  //     setOrgConfigList(data);
  //   })
  // }, []);

  // useEffect(() => {
  //   if (orgConfigList?.length > 0) {
  //     const templateTypeConfig = orgConfigList?.filter(config => config?.configName === "ESL_TEMPLATE_TYPES");
  //     if (templateTypeConfig !== null && templateTypeConfig !== undefined && templateTypeConfig.length > 0) {
  //       setEslTemplateTypeConfig(JSON.parse(templateTypeConfig[0]?.configValue));
  //     }
  //   }
  // }, [orgConfigList]);

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  const fetchMerchant = async (url) => {
    try {
      const res = await axios({
        method: "GET",
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      });
      setAllCount(res?.headers?.["x-total-count"]);
      return res;
    } catch ({ message }) {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message);
      }
    }
  };

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };

  const changeHandler = (store) => {
    console.log(store);
    setValue(store);
    setSearch({ ...search, storeId: store?.value });
  };

  const editFn = async (data) => {
    setSelectedData(data);

    const res = await fetchMerchant(
      `${process.env.REACT_APP_BASE_URL}/esl/${data?.id}`
    );
    setEsl(res?.data);

    // console.log("data: ", res?.data)

    onOpenEdit();
  };

  const view = async (data) => {
    const res = await fetchMerchant(
      `${process.env.REACT_APP_BASE_URL}/esl/${data?.id}`
    );
    history.push({
      pathname: viewUrl,
      state: { data: res?.data },
    });
  };

  const statusChecked = (e) => {
    setStatus(e.target.checked);
  };

  const clear = () => {
    setSearch({
      eslBarCode: "",
      storeId: "",
    });
    setValue("");
  };

  return (
    <div className=" w-full h_das_table">
      <div className="flex h-10 w-full items-center mt-2 justify-between py-8 px-4">
        <input
          className="w-2/5 focus:outline-none py-1.5 px-2 border-2 border-gray-200 rounded-lg"
          placeholder="Search by ESL Barcode"
          onChange={(e) => handleInput(e.target.value, "eslBarCode")}
          value={search?.eslBarCode}
        />

        <div style={{ width: "40%" }}>
          <Select
            className="text-left"
            placeholder="Filter by Store"
            maxMenuHeight={162}
            value={value}
            onChange={changeHandler}
            options={storeList}
          />
        </div>
        <button
          className="bg-green-600 py-1.5 px-5 mr-7 rounded text-white"
          onClick={clear}
        >
          Clear filters
        </button>
      </div>

      <div className="ag-theme-alpine  w-full h-full text-left">
        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            resizable: true,
          }}
          suppressRowClickSelection={true}
          rowSelection={"multiple"}
          pagination={false}
          paginationPageSize={10}
          rowData={data}
        >
          <AgGridColumn
            headerName="ESL BarCode"
            field="eslBarCode"
            minWidth={180}
            sortable={true}
            headerCheckboxSelection={true}
            headerCheckboxSelectionFilteredOnly={true}
            checkboxSelection={true}
            cellRendererFramework={(params) => (
              <DashboardRoute params={params} />
            )}
          />

          <AgGridColumn
            field="templateType"
            minWidth={200}
            sortable={true}
            cellRendererFramework={(params) => (
              <p className="ml-6">
                {params?.data.templateType
                  ? params.data.templateType
                  : "default"}
              </p>
            )}
          />
          <AgGridColumn
            field="Retailer Store"
            minWidth={200}
            cellRendererFramework={(params) => {
              // console.log("allRetai", allRetailerStore)
              return (
                <p>
                  {
                    allRetailerStore?.find(
                      (ele) => ele.id === params.data.storeId
                    )?.internalCode
                  }
                </p>
              );
            }}
          />
          <AgGridColumn
            field="activeStatus"
            minWidth={100}
            cellRendererFramework={(params) => <Confirm params={params} />}
          />
          <AgGridColumn
            field="action"
            minWidth={100}
            cellRendererFramework={(params) => (
              <>
                <div className="flex w-16 h-10 items-center">
                  <div className="border-2 border-gray-200 rounded-lg p-1 mr-1">
                    <GrView
                      className="w-5 h-5 cursor-pointer "
                      onClick={() => view(params?.data)}
                    />
                  </div>
                  <div className="border-2 border-gray-200 rounded-lg p-1 ">
                    <FiEdit
                      className="w-5 h-5 cursor-pointer"
                      onClick={() => editFn(params?.data)}
                    />
                  </div>
                </div>
              </>
            )}
          />
        </AgGridReact>
      </div>
      <div className="  absolute bottom-8 right-12  flex justify-center item-center ">
        <Pagination
          count={
            allCount % 10 === 0
              ? parseInt(allCount / 10)
              : parseInt(allCount / 10) + 1
          }
          showFirstButton
          showLastButton
          page={activePage}
          onChange={handlePageChange}
        />
      </div>
      <ModalEdit
        open={edit}
        onCloseModal={onCloseEdit}
        data={selectedData}
        esl={esl}
        allRetailerStore={allRetailerStore}
        eslTemplateTypeList={eslTemplateTypeList}
      />
    </div>
  );
}

export default All;
