import React, { useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { usePost } from "../../../../api/usePost";
import { getTime } from "../../../../logic/useGetTime";
import { MdOutlineArrowDropDown } from "react-icons/md";
import Datetime from "react-datetime";
import { useAlert } from "react-alert";
import { MENUAL_EXIT } from "../../../../utils/action_types";
import { getDecryptedData } from "../../../../utils/localStorageUtils";

const ManualExit = ({ userId, timeZone, input, cartRef, rootOrgId }) => {
  const alert = useAlert();

  const [state, setState] = useState(false);
  const [time, setTime] = React.useState(new Date());
  const history = useHistory();

  const exitRes = usePost(
    `${process.env.REACT_APP_STORE_URL}/user-exit-activities/manual`,
    "openEntryEvents"
  );

  const handleLogs = usePost(
    `${process.env.REACT_APP_STORE_URL}/app-logging-infos`,
    "handleCaptureLogs"
  );

  const loginInfo = getDecryptedData("loginInfo");

  const manualExit = () => {
    if (input?.note == "") {
      alert.error("Please select the Select reason for manual exit");
      return;
    }
    const selectedTime = time ? time : new Date();
    const exitTime = moment
      .utc(selectedTime)
      .format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");

    const data = {
      exitTime: exitTime,
      status: "OPEN",
      userImagePath: [],
      userActivityId: userId,
      notes: input?.note,
    };

    exitRes?.mutate(data);
    handleLogs.mutate({
      actionName: MENUAL_EXIT,
      logTime: new Date().toISOString(),
      screenLoadingTime: `-`,
      logSessionId: loginInfo?.id,
      userActivityId: userId,
      cartRef,
      rootOrgId
    });
    history.push("/support/shopper-activity");
  };

  return (
    <div className="w-56 relative">
      <div
        className={`absolute w-56  -mt-[18px] ${
          state ? "bg-[#D3D3D3]" : "transparent"
        } rounded-t-3xl rounded-b-2xl`}
      >
        <button
          className={` ${
            state ? "bg-white border border-orange-500" : "bg-[#D3D3D3]"
          }  h-[40px] w-56 rounded-full focus:outline-none`}
          onClick={() => setState(!state)}
        >
          Perform Manual Exit
        </button>
        {state && (
          <div className="mt-3 mb-4">
            <Datetime
              onChange={(value) => setTime(value)}
              initialValue={getTime(time, timeZone)}
              value={getTime(time, timeZone)}
              input={true}
              open={false}
              renderInput={(props) => (
                <div className="w-full h-8 flex justify-between items-center cursor-pointer px-[5px]">
                  <input
                    {...props}
                    className=" h-8 bg-[#D3D3D3] cursor-pointer pl-[5px]"
                  />
                  <MdOutlineArrowDropDown size={24} />
                </div>
              )}
            />

            <button
              onClick={() => manualExit()}
              className="w-[120px] h-[30px] bg-green-500 rounded-full mt-[12px] text-white"
            >
              Submit
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ManualExit;
