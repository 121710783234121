import React from "react";
import TableStr from "./TableStr";
import InReview from "./options/InReview";
import Inflight from "./options/Inflight";
import Approved from "./options/Approved";
import Rejected from "./options/Rejected";
import OpenItems from "./options/OpenItems";


function Hawkeye() {
  return (
    <div className="h-full w-full ">
      <TableStr
        title={"Hawkeye"}
        viewUrl={"/support/hawkeye/view"}
        editUrl={"/support/hawkeye/edit"}
        listUrl={`${process.env.REACT_APP_SHOPPER_URL}/stores/carts?sort=createdDate,desc`}
        getName={"hawkeyeList"}
        Rejected={Rejected}
        InReview={InReview}
        Approved={Approved}
        Inflight={Inflight}
        OpenItems={OpenItems}
      />
    </div>
  );
}

export default Hawkeye;
