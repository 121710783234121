import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useGet } from "../../../../../../../api/useGet";
import ModalUom from "../../../../../../modal/ModalUom";
import ModalTax from "../../../../../../modal/ModalTax";
import ModalBrand from "../../../../../../modal/ModalBrand";
import ModalCategory from "../../../../../../modal/ModalCategory";
import ModalEsl from "../../../../../../modal/ModalEsl";
import axios from "axios";
import { useHistory } from "react-router-dom";

function TableStr({
  title,
  url,
  viewUrl,
  editUrl,
  listUrl,
  orgIdUrl,
  getOrgName,
  retailerIdUrl,
  getRetailerName,
  getName,
  Enabled,
  All,
  Rejected,
  Approved,
  Abondend,
  showInitialized,
  Disabled,
  Initialized,
  NotInitialized,
  notInitializedUrl,
  allUrl,
  // disabledUrl,
  initializedUrl,
  showButton,
  Modal,
  dashboard,
  reatilerList,
  data1,
}) {
  const [click, setClicked] = useState("all");
  const history = useHistory();
  const { isLoading, error, data } = useGet(listUrl, getName);
  const orgList = useGet(orgIdUrl, getOrgName);
  const retailerList = useGet(retailerIdUrl, getRetailerName);

  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const options = (e) => {
    const type = e.target.name;
    switch (type) {
      case "all":
        setClicked(type);
        break;
      case "enabled":
        setClicked(type);
        break;
      case "disabled":
        setClicked(type);
        break;
      case "initialized":
        setClicked(type);

      case "notInitialized":
        setClicked(type);
      default:
    }
  };

  return (
    <div className="w-full h-full shadow-lg select-none">
      <div
        className={`w-full ${
          dashboard ? "h-16" : "h-12"
        } flex justify-between p-6 items-center bg-gray-100`}
      >
        <div>
          <h2 className="font-bold text-2xl">{title}</h2>
        </div>

        <div>
          <button
            onClick={() => history.push(url)}
            className={`bg-green-500 rounded-lg ${
              dashboard ? "py-2 px-4" : "py-1 px-3"
            } mx-4 focus:outline-none text-white`}
          >
            <span className="h-full flex items-center">
              {`Create ${title}`}
            </span>
          </button>
        </div>
      </div>

      {Modal === "ModalUom" ? (
        <ModalUom open={open} onCloseModal={onCloseModal} />
      ) : Modal === "ModalTax" ? (
        <ModalTax open={open} onCloseModal={onCloseModal} />
      ) : Modal === "ModalBrand" ? (
        <ModalBrand open={open} onCloseModal={onCloseModal} />
      ) : Modal === "ModalCategory" ? (
        <ModalCategory open={open} onCloseModal={onCloseModal} />
      ) : Modal === "ModalEsl" ? (
        <ModalEsl open={open} onCloseModal={onCloseModal} />
      ) : null}

      <div className="w-full h-10 p-2  -mt-2 ">
        <div className="h-10 w-full  flex justify-start ">
          <button
            onClick={options}
            name="all"
            className={`mx-4 border-b-2 ${
              click === "all" ? "border-blue-500" : null
            } focus:outline-none`}
          >
            All
          </button>

          <button
            onClick={options}
            name="enabled"
            className={`mx-4 border-b-2 ${
              click === "enabled" ? "border-blue-500" : null
            } focus:outline-none`}
          >
            Enabled
          </button>

          <button
            onClick={options}
            name="disabled"
            className={`mx-4 border-b-2 ${
              click === "disabled" ? "border-blue-500" : null
            } focus:outline-none`}
          >
            Disabled
          </button>
        </div>
      </div>

      {click === "enabled" ? (
        <Enabled />
      ) : click === "disabled" ? (
        <Disabled />
      ) : click === "all" ? (
        <All
          data={data}
          isLoading={isLoading}
          error={error}
          viewUrl={viewUrl}
          editUrl={editUrl}
          orgList={orgList}
          retailerList={retailerList}
        />
      ) : null}
    </div>
  );
}

export default TableStr;
