import React from "react";
import Skeleton from "@mui/material/Skeleton";

const CustomSkeleton = ({ count }) => {
  const skeletons = Array.from({ length: count }, (_, index) => (
    <Skeleton
      key={index}
      sx={{ borderRadius: "0.75rem" }}
      variant="rectangular"
      height={120}
      animation="wave"
    />
  ));

  return <>{skeletons}</>;
};

export default CustomSkeleton;
