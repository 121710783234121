import React from "react";
import { GrView } from "react-icons/gr";
import { FiEdit } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {toast} from 'react-toastify'

function Action({ params, url, viewUrl, editUrl, orgList }) {
  const history = useHistory();

  const fetchMerchant = async (url) => {
    try {
      const res = await axios({
        method: "GET",
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      });
     
      return res;
    } catch ({message}) {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message)
      }
    }
    
  }

  const fetchInitialValue = () => {

    let initialValues = {
      name: params?.data?.name ? params?.data?.name : "",
      email: params?.data?.email ? params?.data?.email : "",
      contactNo: params?.data?.address?.contactNumber ? params?.data?.address?.contactNumber: "",
      street1: params?.data?.address?.street1 ? params?.data?.address?.street1 : "",
      street2: params?.data?.address?.street2 ? params?.data?.address?.street2 : "",
      city: params?.data?.address?.cityOrVillage ? params?.data?.address?.cityOrVillage : "",
      administrativeArea: params?.data?.address?.administrativeArea1
        ? params?.data?.address?.administrativeArea1: "",

      description: params?.data?.description ? params?.data?.description : "",
      website : params?.data?.website ? params?.data?.website : "",
      latitude: params?.data?.address?.lat ? params?.data?.address?.lat: "",
      country: params?.data?.address?.country ? params?.data?.address?.country : "",
      longitude: params?.data?.address?.lng ? params?.data?.address?.lng : "",
      state: params?.data?.address?.state ? params?.data?.address?.state : "",
      contactFirstName : params?.data?.address?.contactFirstName ? params?.data?.address?.contactFirstName : "",
      contactMiddleName : params?.data?.address?.contactMiddleName ? params?.data?.address?.contactMiddleName : "",
      contactLastName : params?.data?.address?.contactLastName ? params?.data?.address?.contactLastName : "",   
      zip: params?.data?.address?.zipCode ? params?.data?.address?.zipCode : "",
      storeType: params?.data?.storeType ? params?.data?.storeType : ""
    }

    return initialValues

  }

  const edit = async () => {

   // const res = await fetchMerchant(`${process.env.REACT_APP_BASE_URL}/api/retail-stores/${params?.data?.id}`)
 
    //console.log("Id: ", res?.data?.rootOrgId)

    
    const initialValues = fetchInitialValue()

    history.push({
      pathname: editUrl,
      //search: "?query=abc",-
      state: { data: initialValues, orgId : params?.data?.rootOrgId, id:params?.data?.id},
    });
  };

  const view = async () => {
    const initialValues = fetchInitialValue()
    //const res = await fetchMerchant(`${process.env.REACT_APP_BASE_URL}/api/retail-stores/${params?.data?.id}`)
    const merchant = await fetchMerchant(`${process.env.REACT_APP_BASE_URL}/api/orgs/${params?.data?.rootOrgId}`)
    history.push({
      pathname: viewUrl,
      //search: "?query=abc",-
      state: { data: params.data, merchant: merchant?.data?.name , initialValues: initialValues},
    })
  }
  return (
    <>
      <div className="flex w-16 h-10 items-center">
        <div className="border-2 border-gray-200 rounded-l-lg p-1 ">
          <GrView className="w-5 h-5 cursor-pointer " onClick={view} />
        </div>
        <div className="border-2 border-gray-200 rounded-r-lg p-1">
          <FiEdit className="w-5 h-5 cursor-pointer" onClick={edit} />
        </div>
      </div>
    </>
  );
}

export default Action;
