import axios from "axios";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const usePasswordChange = (payload) => {
  const history = useHistory();

  function isEmbeded(str) {
    // Check if the string is empty or has only one character
    if (str.length <= 1) {
      return false;
    }

    // Regular expression to match uppercase letters, special characters, and numbers
    const pattern = /^[A-Z0-9~`!@#$%^&*()_+-={}\[\]:;"'<>,.?/\\]+$/;

    // Check if the first and last characters do not match the pattern
    if (pattern.test(str[0]) || pattern.test(str[str.length - 1])) {
      return false;
    }

    return true;
  }

  // const validatePassword = (password) => {
  //   console.log("hasmiddlechar", password.substring(1));
  //   const validations = {
  //     hasLowercase: /[a-z]/.test(password),
  //     hasUppercase: /[A-Z]/.test(password),
  //     hasNumber: /[0-9]/.test(password),
  //     hasSpecialChar: /[~`!@#$%^&*()_+={}[\]|;:"<>,./?]/.test(password),
  //     hasMiddleChar: isEmbeded(password),
  //     isLengthValid: password.length >= 10,
  //     isUnique: true,
  //   };

  //   console.log(
  //     "every",
  //     Object.keys(validations)?.every((key) => validations[key] === true)
  //   );

  //   return validations;
  // };

  const passwordChange = async () => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/account/change-password`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
      data: payload,
    })
      .then(() => {
        if (localStorage.getItem("role") === "admin") {
          toast("Password changed successfully");
          history.push("/");
        } else if (localStorage.getItem("role") === "merchant") {
          toast("Password changed successfully");
          history.push("/admin/merchant/dashboard");
        } else if (localStorage.getItem("role") === "support") {
          toast("Password changed successfully");
          history.push("/support");
        }
      })
      .catch((error) => {
        console.log("error.response", error.response);
        toast.error(
          error?.response?.data?.title || "Please follow all the rules"
        );
        // if (error.response && error.response.status === 401) {
        //   toast.error("Incorrect password");
        // } else {
        //   toast.error("An error occurred while changing the password");
        // }
      });
  };

  const passwordValidations = async (input) => {
    const res = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/account/check-password-rule-status`,
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
      data: input,
    });
    return res;
  };

  return {
    passwordChange,
    // validatePassword,
    passwordValidations,
  };
};

export default usePasswordChange;
