import React from "react";
import { Switch, Route } from "react-router-dom";

// Components
import Welcome from "./pages/welcome/Welcome";

//***************************Admin**************************************//

//Admin --> Merchant
import Merchant from "./pages/admin/merchant/Merchant";
import CreateMerchant from "./pages/admin/merchant/create/CreateMerchant";
import MerchantView from "./pages/admin/merchant/view/MerchantView";
import MerchantEdit from "./pages/admin/merchant/edit/MerchantEdit";

//Support
import Support from "./pages/support/Support";

//***************************PasswordChange**************************************//
import PasswordChange from "./pages/passwordChange";

//Admin --> Location
import Location from "./pages/admin/location/Location";
import CreateLocation from "./pages/admin/location/create/CreateLocation";
import LocationView from "./pages/admin/location/view/LocationView";
import LocationEdit from "./pages/admin/location/edit/LocationEdit";

// Order
import Order from "./pages/order/Order";
import OrderSummary from "./pages/order/dashboard/OrderSummary";
import RefundedSummary from "./pages/order/options/refunded/RefundedSummary";
import CreateOrder from "./pages/order/create/CreateOrder";
import RefundAction from "./pages/order/dashboard/RefundAction";
import OrderView from "./pages/order/view/OrderView";
import OrderEdit from "./pages/order/edit/OrderEdit";

// Employee
import Employee from "./pages/employee/Employee";
import CreateEmployee from "./pages/employee/create/CreateEmployee";
import EmployeeView from "./pages/employee/view/EmployeeView";
import EmployeeEdit from "./pages/employee/edit/EmployeeEdit";

//Admin --> Machine
import Machine from "./pages/admin/machine/Machine";
import CreateMachine from "./pages/admin/machine/create/CreateMachine";
import MachineView from "./pages/admin/machine/view/MachineView";
import MachineViewEdit from "./pages/admin/machine/view/MachineEdit";
import MachineEdit from "./pages/admin/machine/edit/MachineEdit";

//Admin --> Hawkeye
import Hawkeye from "./pages/admin/hawkeye/Hawkeye";
import CreateHawkeye from "./pages/admin/hawkeye/create/CreateHawkeye";
import HawkeyeView from "./pages/admin/hawkeye/view/HawkeyeView";
import HawkeyeEdit from "./pages/admin/hawkeye/edit/HawkeyeEdit";
import ProductReview from "./pages/admin/hawkeye/dashboard/ProductReview";
import User from "./pages/admin/hawkeye/dashboard/user-details/User";
import FinalReview from "./pages/admin/hawkeye/dashboard/FinalReview";
import OpenCartItem from "./pages/admin/hawkeye/dashboard/OpenCartItem";


//Admin --> Match
import Match from "./pages/admin/match/Match";
import MatchEvent from "./pages/admin/match/dashboard/MatchEvent";
import OpenEvents from "./pages/admin/match/dashboard/OpenEvents";
import AddItem from "./pages/admin/match/dashboard/AddItem";
import ProcessedEvents from "./pages/admin/match/dashboard/ProcessedEvents";
import AllSessionVideos from "./pages/admin/match/dashboard/AllSessionVideos"

//Admin --> Rack
import Rack from "./pages/admin/rack/Rack";
import CreateRack from "./pages/admin/rack/create/CreateRack";
import RackView from "./pages/admin/rack/view/RackView";
import ShelvesView from "./pages/admin/rack/view/ShelveView";
import RackEdit from "./pages/admin/rack/edit/RackEdit";
import RackDashboard from "./pages/admin/rack/dashboard/RackDashboard";

//Admin --> Shopper Activity
import ShopperActivity from "./pages/admin/shopperActivity/ShopperActivity";
import ShopperMatchingEntryEvent from "./pages/admin/shopperActivity/dashboard/MatchEvent";
import ShopperMatchingExitEvent from "./pages/admin/shopperActivity/dashboard/MatchOpenEvent";
import ShopperOpenEvents from "./pages/admin/shopperActivity/dashboard/OpenEvents";
import ShopperView from "./pages/admin/shopperActivity/view/ShopperView";
import ShopperExit from "./pages/admin/shopperActivity/view/ShopperExit";
import GroupShopper from "./pages/admin/shopperActivity/dashboard/GroupShopper";

//*****************************Location************************************//

const Routes = () => {
  return (
    <Switch>
      <Route path="/" exact>
        <Welcome />
      </Route>

      {/*****************************Merchant(Admin)************************************/}
      <Route path="/admin/merchant" exact>
        <Merchant />
      </Route>

      <Route path="/admin/merchant/view" exact>
        <MerchantView />
      </Route>

      <Route path="/admin/merchant/edit" exact>
        <MerchantEdit />
      </Route>

      <Route path="/admin/merchant/newMerchant" exact>
        <CreateMerchant />
      </Route>

      {/*****************************Support Dashboard************************************/}
      <Route path="/admin/support" exact>
        <Support />
      </Route>

       {/*****************************Password Change************************************/}
       <Route path="/password" exact>
        <PasswordChange/>
      </Route>

      {/*****************************Location(Admin)************************************/}
      <Route path="/admin/retailer-store" exact>
        <Location />
      </Route>

      <Route path="/admin/retailer-store/view" exact>
        <LocationView />
      </Route>

      <Route path="/admin/retailer-store/edit" exact>
        <LocationEdit />
      </Route>

      <Route path="/admin/retailer-store/newLocation" exact>
        <CreateLocation />
      </Route>


       {/*****************************employee************************************/}
     

       <Route path="/employee/view" >
        <EmployeeView />
      </Route>

      <Route path="/employee/edit" >
        <EmployeeEdit />
      </Route>

      <Route path="/employee/newEmployee">
        <CreateEmployee />
      </Route>

      <Route path="/employee" exact>
        <Employee />
      </Route>

      {/*****************************Order************************************/}
      <Route path="/order" exact>
        <Order />
      </Route>

      <Route path="/order/order-summary" exact>
        <OrderSummary header={true} />
      </Route>

      <Route path="/order/view" exact>
        <OrderView />
      </Route>

      <Route path="/order/edit" exact>
        <OrderEdit />
      </Route>

      <Route path="/order/newOrder" exact>
        <CreateOrder />
      </Route>

      <Route path="/order/refund" exact>
        <RefundAction header={true} />
      </Route>

      <Route path="/order/refund/refunded-summary" exact>
        <RefundedSummary header={true} />
      </Route>

     

      {/*****************************Machine(Admin)************************************/}

      <Route path="/admin/machine" exact>
        <Machine />
      </Route>

      <Route path="/admin/machine/view" exact>
        <MachineView />
      </Route>

      <Route path="/admin/machine/view/viewEdit" exact>
        <MachineViewEdit />
      </Route>

      <Route path="/admin/machine/edit" exact>
        <MachineEdit />
      </Route>

      <Route path="/admin/machine/newMachine" exact>
        <CreateMachine />
      </Route>

      {/*****************************Rack(Admin)************************************/}
      <Route path="/admin/rack" exact>
        <Rack />
      </Route>

      <Route path="/admin/rack/view" exact>
        <RackView />
      </Route>

      <Route path="/admin/rack/view/shelves" exact>
        <ShelvesView />
      </Route>

      <Route path="/admin/rack/edit" exact>
        <RackEdit />
      </Route>

      <Route path="/admin/rack/newRack" exact>
        <CreateRack />
      </Route>

      {/* <Route path="/admin/rack/monitoring">
        <RackDashboard />
      </Route>

      <Route path="/admin/hawkeye/monitoring/user" exact>
        <User />
      </Route>

      <Route path="/admin/hawkeye/monitoring/mapped" exact>
        <Mapped />
      </Route> */}

      {/*****************************Hawkeye(Admin)************************************/}
      <Route path="/admin/hawkeye" exact>
        <Hawkeye />
      </Route>

      <Route path="/admin/hawkeye/view" exact>
        <HawkeyeView />
      </Route>

      <Route path="/admin/hawkeye/edit" exact>
        <HawkeyeEdit />
      </Route>

      <Route path="/admin/hawkeye/newHawkeye" exact>
        <CreateHawkeye />
      </Route>

      <Route path="/admin/hawkeye/openCartItem" exact>
        <OpenCartItem />
      </Route>


      <Route path="/admin/hawkeye/cartSummary" exact>
        <FinalReview />
      </Route>

      <Route path="/admin/hawkeye/cartSummary/productReview" exact>
        <ProductReview />
      </Route>

      {/* <Route path="/admin/hawkeye/monitoring/review/openCartItems" exact>
        <OpenCartItem />
      </Route> 

      <Route path="/admin/hawkeye/monitoring/user" exact>
        <User />
      </Route>

      <Route path="/admin/hawkeye/monitoring/mapped" exact>
        <Mapped />
    </Route> */}

      {/*****************************Match(Admin)************************************/}
      <Route path="/admin/match" exact>
        <Match />
      </Route>

      {/* <Route path="/admin/match/view" exact>
        <StoreOpenCardView />
      </Route>

      <Route path="/admin/match/edit" exact>
        <StoreOpenCardEdit />
      </Route>

      <Route path="/admin/storeOpenCard/newStoreOpenCard" exact>
        <CreateStoreOpenCard />
      </Route> */}

      <Route path="/admin/match/matchEvent">
        <MatchEvent />
      </Route>

      <Route path="/admin/match/storeOpenCard/monitoring/user" exact>
        <User />
      </Route>

      <Route path="/admin/match/openEvents" exact>
        <OpenEvents />
      </Route>

      <Route path="/admin/match/openEvents/AddItem" exact>
        <AddItem />
      </Route>

      <Route path="/admin/match/processedEvents/">
         <ProcessedEvents/>
      </Route>
      
      <Route path="/admin/match/openEvents/session-videos">
        <AllSessionVideos/>
      </Route>

      {/*****************************Shopper(Admin)************************************/}

      <Route path="/admin/shopper-activity" exact>
        <ShopperActivity />
      </Route>

      <Route path="/admin/shopper-activity/shopperEntryEvent" exact>
        <ShopperMatchingEntryEvent />
      </Route>

      <Route path="/admin/shopper-activity/shopperExitEvent" exact>
        <ShopperMatchingExitEvent />
      </Route>

      <Route path="/admin/shopper-activity/openEvents" exact>
        <ShopperOpenEvents />
      </Route>

      <Route path="/admin/shopper-activity/view" exact>
        <ShopperView />
      </Route>

      <Route path="/admin/shopper-activity/exit" exact>
        <ShopperExit />
      </Route>

      <Route path="/admin/shopper-activity/shopperEvent/group-checkedout" exact>
        <GroupShopper />
      </Route>

    
    </Switch>
  );
};

export default Routes;
