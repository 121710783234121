import { useState } from "react";
import { Formik, Form } from "formik";
import { usePut } from "../../../api/usePut";
import { IoIosClose } from "react-icons/io";
import axios from "axios";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

function ModalMapEsl({ open, esl, onCloseModal }) {
  const history = useHistory();
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [enableMap, setEnableMap] = useState(false);
  const [isSearch, setIsSearch] = useState(false);

  const handleInput = (value, name) => {
    setSearch({ ...search, [name]: value, filterby: name });
    setIsSearch(true);
  };

  const onClose = () => {
    setSearch({
      barCode: "",
      name: "",
      filterby: "",
    });
    setProducts([]);
    setSelectedProduct(null);
    onCloseModal();
    setEnableMap(false);
    history.goBack();
  };

  const [search, setSearch] = useState({
    barCode: "",
    name: "",
    filterby: "",
  });

  const findUrl = (name) => {
    const listUrl = `${
      process.env.REACT_APP_BASE_URL
    }/_search/products?rootOrgId.equals=${parseInt(
      JSON.parse(localStorage.getItem("dashboard-data"))?.id
    )}`;
    switch (name) {
      case "name":
        return `${listUrl}&name.contains=${search?.name}*`;

      case "barCode":
        return `${listUrl}&barCode.contains=${search?.barCode}*`;

      default:
        return `${listUrl}`;
    }
  };

  const fetchData = async () => {
    if (search?.barCode !== "") {
      try {
        const res = await axios({
          method: "GET",
          url: `${findUrl(search?.filterby)}&page=${0}&size=10`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("user-info")}`,
          },
        });
        setProducts(res?.data);
        return res.data;
      } catch ({ message }) {
        if (message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          toast.error(message);
        }
      }
    }
  };

  const { productList } = useQuery(
    ["productList", search?.barCode, search?.name],
    async () => fetchData(),
    {
      enabled: !!search?.barCode || !search?.name,
      cacheTime: Infinity,
      staleTime: 0,
    }
  );

  const onProductSelect = (data) => {
    setSelectedProduct(data);
    setEnableMap(true);
    setIsSearch(false);
  };

  const mapProductToEsl = usePut(
    `${process.env.REACT_APP_BASE_URL}/esl/pair-product`,
    "mapEsl"
  );

  const mapEsl = async () => {
    try {
      mapProductToEsl.mutate({
        storeId: esl?.storeId,
        barCode: selectedProduct?.barCode,
        rootOrgId: esl?.rootOrgId,
        eslBarCodes: [esl?.eslBarCode],
      });

      if (mapProductToEsl.isError === false) {
        onClose();
      } else {
        alert.error(
          <div style={{ textTransform: "none" }}>Please Check all Fields!</div>
        );
      }
    } catch (error) {
      alert.error(
        <div style={{ textTransform: "none" }}>you are offline!</div>
      );
    }
  };

  return (
    <>
      {open && (
        <div className="w-1/2 text-left select-none  z-10 absolute top-1/2 left-1/2  ml-32 transform -translate-x-1/2 -translate-y-1/2 ">
          <Formik>
            {() => (
              <Form className="w-full  bg-gray-100 border-2 border-dash rounded-lg shadow-lg ">
                <div className="w-full h-16 flex bg-dash text-white">
                  <div className="w-full h-full px-7 flex flex-col justify-center">
                    <h2 className="font-bold text-2xl">Map ESL - Product</h2>
                  </div>
                  <div className="w-16 px-4 h-full flex items-center">
                    <div className="w-full flex justify-end  ">
                      <IoIosClose
                        onClick={onClose}
                        className="h-12 w-12 cursor-pointer"
                      />
                    </div>
                  </div>
                </div>
                <div className="w-11/12 mx-auto px-6 my-2">
                  <div className="w-full mt-4  items-center">
                    <div className="flex px-4 mr-4 w-full items-center select-none">
                      <input
                        className="w-4/5 focus:outline-none py-1.5 px-2 border-2 border-gray-200 rounded-lg"
                        placeholder="Search by Product Barcode"
                        onChange={(e) => handleInput(e.target.value, "barCode")}
                        value={search?.barCode}
                        type="number"
                      />
                    </div>
                    {isSearch && (
                      <div className="w-[75%] focus:outline-none py-1.5 px-2 ml-5 mt-2 rounded-lg">
                        {products &&
                          products?.length > 0 &&
                          products?.map((productView, index) => (
                            <div
                              className="w-[100%] flex justify-between items-center border border-gray-300 px-[20px] cursor-pointer bg-white mt-2"
                              key={`product-${index}`}
                              onClick={() => onProductSelect(productView)}
                            >
                              <div className="w-[10%] border-r border-gray-300 flex items-center">
                                <img
                                  src={productView?.imageUrl || "/noImage.jpg"}
                                  className="rounded"
                                />
                              </div>
                              <div className="w-[90%]">
                                <p>{productView?.name}</p>
                              </div>
                              <div className="w-[90%]">
                                <p>{productView?.barCode}</p>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-col w-8/12 ml-10 pl-10 my-2">
                  {!isSearch && selectedProduct !== null && (
                    <div className="flex bg-white justify-left">
                      <div className="w-[90%]">
                        <p>{selectedProduct?.name}</p>
                      </div>
                      <div className="w-[90%]">
                        <p>{selectedProduct?.barCode}</p>
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex w-11/12 justify-center">
                  <button
                    onClick={mapEsl}
                    className="bg-green-500 rounded-lg px-6 py-3 mb-2 focus:outline-none text-white"
                    disabled={!enableMap}
                  >
                    <span className="px-2">Map ESL</span>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
}

export default ModalMapEsl;
