import React from "react";
import { useField } from "formik";
import { TextField } from "@material-ui/core";

export const TextField2 = ({ label, errors, touched, editable, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="w-full  flex flex-col justify-center ">
      <TextField
        label={label}
        variant="outlined"
        {...field}
        {...props}
        autoComplete="off"
        disabled={
          editable == null || editable == "false" || editable == false
            ? editable
            : "true"
        }
      />

      {meta.touched && meta.error ? (
        <p className="w-full text-right text-red-500">{meta.error}</p>
      ) : null}
    </div>
  );
};
