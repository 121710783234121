import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { FaStop, FaEdit, FaCopy, FaShareAlt, FaFileCsv } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { GrPowerReset } from "react-icons/gr";
import Confirm from "./Confirm";
import { useGet } from "../../../../../../../api/useGet";

function LocationView() {
  const location = useLocation();
  const history = useHistory();

  const id = location?.state?.data?.id;

  const { isLoading, error, data } = useGet(
    `${process.env.REACT_APP_BASE_URL}/retail-stores/${id}`,
    "locationView"
  );

  return (
    <>
      <div className="w-full h-screen text-left select-none flex">
        <div className="w-full h-full flex flex-col items-center bg-gray-200">
          <div className="w-full h-12 flex bg-gray-100  items-center">
            <BiArrowBack
              onClick={() => history.goBack()}
              className="h-6 w-6 cursor-pointer ml-5"
            />
            {/* <h2 className="font-bold px-6 text-xl">{data?.name}</h2> */}
          </div>

          <div className="w-full h-full flex p-2">
            <div className="w-7/10 h-full bg-white rounded-lg p-2">
              <div className="w-full border-b-2">
                <h2 className="text-black text-bold text-lg select-none">
                  Information
                </h2>
              </div>

              <div className="flex h-12 mt-1 flex-wrap space-y-1 items-center justify-between select-none">
                <div className="ml-3">
                  <button
                    onClick={() =>
                      history.push({
                        pathname: "/admin/merchant/dashboard/rack",
                        state: { reatilerId: id },
                      })
                    }
                    className="focus:outline-none w-20 h-8 bg-green-500 text-white rounded-lg"
                  >
                    Racks
                  </button>
                  <button
                    onClick={() =>
                      history.push({
                        pathname: "/admin/merchant/dashboard/machine",
                        state: { reatilerId: id },
                      })
                    }
                    className="focus:outline-none w-24 h-8 bg-green-500 text-white rounded-lg ml-6"
                  >
                    Machines
                  </button>
                  <button
                    onClick={() =>
                      history.push({
                        pathname:
                          "/admin/merchant/dashboard/retailer-store/storelimit",
                        state: { data: data, id: data?.id },
                      })
                    }
                    className="focus:outline-none w-24 h-8 bg-green-500 text-white rounded-lg ml-6"
                  >
                    Store Limit
                  </button>
                  <button
                    onClick={() =>
                      history.push({
                        pathname:
                          "/admin/merchant/dashboard/retailer-store/configure-store",
                        state: { data: data, id: data?.id },
                      })
                    }
                    className="focus:outline-none h-8 bg-green-500 text-white rounded-lg ml-6 px-2"
                  >
                    Configure retail store
                  </button>
                </div>

                <Confirm data={data} />
              </div>

              <div className="h-3/4 mt-3 select-none scrollbar-thin scrollbar-thumb-dash scrollbar-track-gray-100">
                <div className="border-t-2 border-gray-200 py-1 px-4">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="">Name</div>
                    <div className="">{data?.name}</div>
                  </div>
                </div>

                <div className="border-t-2 border-gray-200 py-1 px-4">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="">Email</div>
                    <div className="">{data?.email}</div>
                  </div>
                </div>

                {data?.description ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">Description</div>
                      <div className="">{data?.description}</div>
                    </div>
                  </div>
                ) : null}

                {data?.internalCode ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">Store Code</div>
                      <div className="">{data?.internalCode}</div>
                    </div>
                  </div>
                ) : null}

                <h2 className="font-bold mt-2 ml-4 text-base">Address: </h2>

                {data?.address?.street1 ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">Street</div>
                      <div className="">{data?.address?.street1}</div>
                    </div>
                  </div>
                ) : null}

                {data?.address?.street2 ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">street ..</div>
                      <div className="">{data?.address?.street2}</div>
                    </div>
                  </div>
                ) : null}

                {data?.address?.cityOrVillage ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">City / Village</div>
                      <div className="">{data?.address?.cityOrVillage}</div>
                    </div>
                  </div>
                ) : null}

                {data?.address?.administrativeArea1 ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">Administrative Area</div>
                      <div className="">
                        {data?.address?.administrativeArea1}
                      </div>
                    </div>
                  </div>
                ) : null}

                {data?.address?.administrativeArea2 ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">Administrative Area ..</div>
                      <div className="">
                        {data?.address?.administrativeArea2}
                      </div>
                    </div>
                  </div>
                ) : null}

                {data?.address?.neighborhoodName ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">Neighborhood Name</div>
                      <div className="">{data?.address?.neighborhoodName}</div>
                    </div>
                  </div>
                ) : null}

                {data?.address?.state ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">State ..</div>
                      <div className="">{data?.address?.state}</div>
                    </div>
                  </div>
                ) : null}

                {data?.address?.lat ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">Latitude</div>
                      <div className="">{data?.address?.lat}</div>
                    </div>
                  </div>
                ) : null}

                {data?.address?.lng ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">Longitude</div>
                      <div className="">{data?.address?.lng}</div>
                    </div>
                  </div>
                ) : null}

                {data?.address?.contactFirstName ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">Contact Name</div>
                      <div className="">{`${data?.address?.contactFirstName} ${
                        data?.address?.contactMiddleName
                          ? data?.address?.contactMiddleName
                          : null
                      }
                      ${
                        data?.address?.contactLastName
                          ? data?.address?.contactLastName
                          : null
                      } `}</div>
                    </div>
                  </div>
                ) : null}

                {data?.address?.contactNumber ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">Contact Number</div>
                      <div className="">{data?.address?.contactNumber}</div>
                    </div>
                  </div>
                ) : null}

                {data?.address?.country ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">Country</div>
                      <div className="">{data?.address?.country}</div>
                    </div>
                  </div>
                ) : null}

                {data?.address?.zipCode ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">Zip Code</div>
                      <div className="">{data?.address?.zipCode}</div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="w-3/10 h-full ml-2 bg-white rounded-lg p-2">
              <div className="w-full border-b-2">
                <h2 className="text-black text-bold text-lg">Log</h2>
              </div>

              <div className="flex"></div>

              <div></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LocationView;
