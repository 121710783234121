import React from "react";

import {Link } from "react-router-dom";

function DashboardRoute({ params }) {
  const clicked = () => {
        localStorage.setItem("dashboard-data", JSON.stringify(params.data))
  }
  return (
    <>
      <Link
        //to="/admin/merchant/dashboard"
        //target="_blank"
        //onClick={clicked}
        className="font-medium cursor-default"
      >
        {params.value}
      </Link>
    </>
  );
}


export default DashboardRoute;
