import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useGet } from "../../../../../api/useGet";
import { usePost } from "../../../../../api/usePost";
import moment from "moment";

function TableStr({
  title,
  viewUrl,
  editUrl,
  listUrl,
  getName,
  Rejected,
  InReview,
  Approved,
  Abondend,
  data1,
}) {
  const [click, setClicked] = useState("inReview");

  const { data } = useGet(listUrl, getName);

  const exitRes = usePost(
    `${process.env.REACT_APP_STORE_URL}/user-exit-activities`,
    getName
  );

  const exit = () => {
    const exitTime = moment.utc(new Date()).format();

    const data = {
      exitTime: exitTime,
      status: "OPEN",
      userImagePath: "/home/kls",
    };

    exitRes.mutate(data);
  };

  return (
    <div className="w-full h-full shadow-lg select-none">
      <div
        className={`w-full h-12 flex justify-between p-6 items-center bg-gray-100`}
      >
        <div>
          <h2 className="font-bold text-2xl">{title}</h2>
        </div>
        <div>
          <button
            onClick={exit}
            className="bg-red-500 rounded px-4 text-white focus:outline-none"
          >
            Exit
          </button>
        </div>
      </div>

      <div className="w-full flex h-10 p-2  -mt-2 ">
        <div className="h-10 w-full  flex justify-start ">
          <button
            //onClick={options}
            name="inReview"
            className={`mx-4 border-b-2 ${
              click === "inReview" ? "border-blue-500" : null
            } focus:outline-none`}
          >
            Exit Listview
          </button>

          {/* <button
            onClick={options}
            name="approved"
            className={`mx-4 border-b-2 ${click === "approved" ? "border-blue-500" : null
              } focus:outline-none`}
          >
            Approved
          </button>

         

          <button
            onClick={options}
            name="rejected"
            className={`mx-4 border-b-2 ${click === "rejected" ? "border-blue-500" : null
              } focus:outline-none`}
          >
            Rejected
          </button> */}
        </div>
      </div>

      {click === "approved" ? (
        <Approved
          data={data}
          //isLoading={isLoading}
          // error={error}
          viewUrl={viewUrl}
          editUrl={editUrl}
          //sortedData={findField(dataFilter(data, "APPROVED"))}
        />
      ) : click === "inReview" ? (
        <InReview
          data={data}
          // isLoading={isLoading}
          // error={error}
          viewUrl={viewUrl}
          editUrl={editUrl}
          // sortedData={findField(dataFilter(data, "IN_REVIEW"))}
        />
      ) : click === "rejected" ? (
        <Rejected
          data={data}
          // isLoading={isLoading}
          // error={error}
          viewUrl={viewUrl}
          editUrl={editUrl}
          //sortedData={findField(dataFilter(data, "REJECTED"))}
        />
      ) : null}
    </div>
  );
}

export default TableStr;
