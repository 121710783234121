import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

function TableStr({
  title,
  url,
  viewUrl,
  editUrl,
  listUrl,
  Enabled,
  All,
  Disabled,
  dashboard,
}) {
  const [click, setClicked] = useState("all");
  const history = useHistory();

  const options = (e) => {
    const type = e.target.name;
    switch (type) {
      case "all":
        setClicked(type);
        break;
      case "enabled":
        setClicked(type);
        break;
      case "disabled":
        setClicked(type);
        break;

      default:
    }
  };

  return (
    <div className="w-full h-full shadow-lg select-none">
      <div
        className={`w-full ${
          dashboard ? "h-16" : "h-12"
        } flex justify-between p-6 items-center bg-gray-100`}
      >
        <div>
          <h2 className="font-bold text-2xl">{title}</h2>
        </div>

        <div>
          <button
            onClick={() => history.push(url)}
            className={`bg-green-500 rounded-sm ${
              dashboard ? "py-2 px-4" : "py-1 px-3"
            } mx-4 focus:outline-none text-white`}
          >
            Create Offer
          </button>
        </div>
      </div>

      <div className="w-full h-10 p-2  -mt-2 ">
        <div className="h-10 w-full  flex justify-start ">
          <button
            onClick={options}
            name="all"
            className={`mx-4 border-b-2 ${
              click === "all" ? "border-blue-500" : null
            } focus:outline-none`}
          >
            All
          </button>

          <button
            onClick={options}
            name="notInitialized"
            className={`mx-4 border-b-2 ${
              click === "notInitialized" ? "border-blue-500" : null
            } focus:outline-none`}
          >
            Enable
          </button>

          <button
            onClick={options}
            name="notInitialized"
            className={`mx-4 border-b-2 ${
              click === "notInitialized" ? "border-blue-500" : null
            } focus:outline-none`}
          >
            Disable
          </button>
        </div>
      </div>

      {click === "enabled" ? (
        <Enabled />
      ) : click === "disabled" ? (
        <Disabled />
      ) : click === "all" ? (
        <All listUrl={listUrl} viewUrl={viewUrl} editUrl={editUrl} />
      ) : null}
    </div>
  );
}

export default TableStr;
