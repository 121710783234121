import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { FaStop, FaEdit, FaCopy, FaShareAlt, FaFileCsv } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { GrPowerReset } from "react-icons/gr";
import Toggle from "react-toggle";
import Confirm from "./Confirm";
import { useGet } from "../../../../../../../../api/useGet";

function SupplierView() {
  const [status, setStatus] = useState(false);

  const statusChecked = (e) => {
    setStatus(e.target.checked);
  };
  const location = useLocation();
  const history = useHistory();

  // https://portal-uat.autonomo.tech/autonomo/api/suppliers?sort=createdDate,desc&rootOrgId.equals=3201
  // const data = location?.state?.data;
  //console.log(data);
  const { data, isLoading } = useGet(
    `${process.env.REACT_APP_BASE_URL}/suppliers/${location?.state?.data?.id}`,
    "supplierDetails"
  );
  console.log("data", data);

  const fetchInitialValue = () => {
    let initialValues = {};

    data?.addresses?.map((item) => {
      initialValues = {
        name: data?.name ? data?.name : "",
        email: item?.contactEmail ? item?.contactEmail : "",
        contactNo: item?.contactNumber ? item.contactNumber : "",
        street1: item?.street1 ? item?.street1 : "",
        street2: item?.street2 ? item?.street2 : "",
        city: item?.cityOrVillage ? item?.cityOrVillage : "",
        administrativeArea: item?.administrativeArea1
          ? item?.administrativeArea1
          : "",

        latitude: item?.lat ? item?.lat : "",
        country: item?.country ? item?.country : "",
        longitude: item.lng ? item.lng : "",
        state: item?.state ? item?.state : "",
        contactFirstName: item?.contactFirstName ? item?.contactFirstName : "",
        contactMiddleName: item?.contactMiddleName
          ? item?.contactMiddleName
          : "",
        contactLastName: item?.contactLastName ? item?.contactLastName : "",
        zip: item?.zipCode ? item?.zipCode : "",
        status: data?.status ? data?.status : null,
        brands: data?.brands ? data?.brands : "Select Brands",
        skuType: data?.skuType ? data?.skuType : "Select SKU Type",
      };
    });
    return initialValues;
  };

  const edit = () => {
    history.push({
      pathname: "/admin/merchant/dashboard/productManagement/supplier/edit",
      //search: "?query=abc",-
      state: { initialValues: fetchInitialValue(), data: data },
    });
  };

  return (
    <>
      <div className="w-full h-screen text-left select-none flex">
        <div className="w-full h-full flex flex-col justify-center items-center bg-gray-200">
          <div className="w-full h-12 flex bg-gray-100  items-center">
            <BiArrowBack
              onClick={() => history.goBack()}
              className="h-6 w-6 cursor-pointer ml-5"
            />
            {/* <h2 className="font-bold px-6 text-xl">{data?.name}</h2> */}
          </div>

          <div className="w-full h-full flex p-2">
            <div className="w-7/10 h-full bg-white rounded-lg p-2">
              <div className="w-full border-b-2">
                <h2 className="text-black text-bold text-lg select-none">
                  Information
                </h2>
              </div>

              <div className="flex h-20 mt-1 flex-wrap space-y-1 items-center select-none ">
                <button
                  onClick={edit}
                  className="focus:outline-none bg-gray-200 border-2 border-black ml-1 hover:bg-grey text-grey-darkest font-bold py-1 px-4 rounded inline-flex items-center"
                >
                  <FaEdit className="w-4 h-4" />
                  <span className="mx-2">Edit</span>
                </button>

                <Confirm />
              </div>

              <div className="h-3/4 mt-3 select-none scrollbar-thin scrollbar-thumb-dash scrollbar-track-gray-100">
                <div className="border-t-2 border-gray-200 py-1 px-4">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="">Name</div>
                    <div className="">{data?.name}</div>
                  </div>
                </div>

                {data?.brand ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">Brand</div>
                      <div className="">{data?.brand}</div>
                    </div>
                  </div>
                ) : null}

                {data?.status ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">Status</div>
                      <div className="">{data?.status}</div>
                    </div>
                  </div>
                ) : null}

                <h2 className="font-bold mt-2 ml-4 text-base">Address: </h2>

                {data?.addresses?.map((address) => {
                  return (
                    <>
                      {address?.street1 ? (
                        <div className="border-t-2 border-gray-200 py-1 px-4">
                          <div className="grid grid-cols-2 gap-4">
                            <div className="">Street</div>
                            <div className="">{address?.street1}</div>
                          </div>
                        </div>
                      ) : null}

                      {address?.street2 ? (
                        <div className="border-t-2 border-gray-200 py-1 px-4">
                          <div className="grid grid-cols-2 gap-4">
                            <div className="">street ..</div>
                            <div className="">{address?.street2}</div>
                          </div>
                        </div>
                      ) : null}

                      {address?.cityOrVillage ? (
                        <div className="border-t-2 border-gray-200 py-1 px-4">
                          <div className="grid grid-cols-2 gap-4">
                            <div className="">City / Village</div>
                            <div className="">{address?.cityOrVillage}</div>
                          </div>
                        </div>
                      ) : null}

                      {address?.administrativeArea1 ? (
                        <div className="border-t-2 border-gray-200 py-1 px-4">
                          <div className="grid grid-cols-2 gap-4">
                            <div className="">Administrative Area</div>
                            <div className="">
                              {address?.administrativeArea1}
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {address?.administrativeArea2 ? (
                        <div className="border-t-2 border-gray-200 py-1 px-4">
                          <div className="grid grid-cols-2 gap-4">
                            <div className="">Administrative Area ..</div>
                            <div className="">
                              {address?.administrativeArea2}
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {address?.neighborhoodName ? (
                        <div className="border-t-2 border-gray-200 py-1 px-4">
                          <div className="grid grid-cols-2 gap-4">
                            <div className="">Neighborhood Name</div>
                            <div className="">{address?.neighborhoodName}</div>
                          </div>
                        </div>
                      ) : null}

                      {address?.state ? (
                        <div className="border-t-2 border-gray-200 py-1 px-4">
                          <div className="grid grid-cols-2 gap-4">
                            <div className="">State</div>
                            <div className="">{address?.state}</div>
                          </div>
                        </div>
                      ) : null}

                      {address?.lat ? (
                        <div className="border-t-2 border-gray-200 py-1 px-4">
                          <div className="grid grid-cols-2 gap-4">
                            <div className="">Latitude</div>
                            <div className="">{address?.lat}</div>
                          </div>
                        </div>
                      ) : null}

                      {address?.lng ? (
                        <div className="border-t-2 border-gray-200 py-1 px-4">
                          <div className="grid grid-cols-2 gap-4">
                            <div className="">Longitude</div>
                            <div className="">{address?.lng}</div>
                          </div>
                        </div>
                      ) : null}

                      {address?.contactFirstName ? (
                        <div className="border-t-2 border-gray-200 py-1 px-4">
                          <div className="grid grid-cols-2 gap-4">
                            <div className="">Contact Name</div>
                            <div className="">{`${
                              address?.contactFirstName
                            } + ${
                              address?.contactMiddleName
                                ? address?.contactMiddleName
                                : null
                            }
                      + ${
                        address?.contactLastName
                          ? address?.contactLastName
                          : null
                      } `}</div>
                          </div>
                        </div>
                      ) : null}

                      {address?.contactNumber ? (
                        <div className="border-t-2 border-gray-200 py-1 px-4">
                          <div className="grid grid-cols-2 gap-4">
                            <div className="">Contact Number</div>
                            <div className="">{address?.contactNumber}</div>
                          </div>
                        </div>
                      ) : null}

                      {address?.country ? (
                        <div className="border-t-2 border-gray-200 py-1 px-4">
                          <div className="grid grid-cols-2 gap-4">
                            <div className="">Country</div>
                            <div className="">{address?.country}</div>
                          </div>
                        </div>
                      ) : null}

                      {address?.zipCode ? (
                        <div className="border-t-2 border-gray-200 py-1 px-4">
                          <div className="grid grid-cols-2 gap-4">
                            <div className="">Zip Code</div>
                            <div className="">{address?.zipCode}</div>
                          </div>
                        </div>
                      ) : null}
                    </>
                  );
                })}
              </div>
            </div>

            <div className="w-3/10 h-full ml-2 bg-white rounded-lg p-2">
              <div className="w-full border-b-2">
                <h2 className="text-black text-bold text-lg">Log</h2>
              </div>

              <div className="flex"></div>

              <div></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SupplierView;
