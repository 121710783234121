import React from "react";
import TableStr from "./TableStr";
import Enabled from "./options/Enabled";
import Disabled from "./options/Disabled";
import All from "./options/All";
function Esl() {
 const rootId= parseInt(JSON.parse(localStorage.getItem("dashboard-data"))?.id)
  return (
    <div className="h-full w-full ">
      <TableStr
        title={"Esl"}
        url={"/admin/merchant/dashboard/productManagement/esl/create"}
        viewUrl={"/admin/merchant/dashboard/productManagement/esl/view"}
        editUrl={"/admin/merchant/dashboard/productManagement/esl/edit"}
        listUrl={`${process.env.REACT_APP_BASE_URL}/esl?rootOrgId.equals=${rootId}`}
        getName={"eslList"}
        Enabled={Enabled}
        Disabled={Disabled}
        Modal={"ModalEsl"}
        All={All}
        showButton={true}
        dashboard={true}
      />
    </div>
  );
}

export default Esl;
