import React, { useState, useEffect } from "react";
import AllAction from "./AllAction";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { toast } from 'react-toastify'
import "react-toggle/style.css";
import RetailerStore from "./RetailerStore";
import Select from "react-select";
import DashboardRoute from "./DashboardRoute";
import Confirm from "./Confirm";
import { BiSearchAlt } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";
import SearchData from "../../../../../../components/SearchData";
import axios from "axios";

function All({ isLoading, error, data, url, viewUrl, editUrl, sortedData }) {
  const [filteredData, setFilteredData] = useState(null);
  const [searchData, setSearchData] = useState("");
  const [loading, setLoading] = useState(false);
  const [retailerStore, setRetailerStore] = useState(null);
  const [retailerStoreId, setRetailerStoreId] = useState(null);
  const [searchFilteredData, setSearchFilteredData] = useState("");

  const history = useHistory();

  
  const [input, setInput] = useState({
    retailer: "",
  });

  const searchDataFn = (name, data) => {
    setSearchData(data?.rootOrgId);
    setInput({ ...input, [name]: data?.name });
    setFilteredData(null);
  };

  const onChangeInput = (e, name) => {
    const value = e.target.value;

    setInput({ ...input, [name]: value });

    if (true) {
      setLoading(true);
      axios({
        method: "GET",
        url: `${
          process.env.REACT_APP_BASE_URL
        }/orgs?page=0&sort=createdDate,desc&name.contains=${value.toLowerCase()}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      }).then((res) => {
        setFilteredData(res?.data);
        setLoading(false);
      }).catch(({ message }) => {
        if (message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          toast.error(message)
        }
      })
    }
  };

  useEffect(() => {
    if (searchData) {
      setLoading(true);
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/retail-stores?page=0&size=10&sort=createdDate,desc&rootOrgId.equals=${searchData}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      }).then((res) => {
        console.log("data: ", res?.data);
        setRetailerStore(res?.data);
        setLoading(false);
      }).catch(({ message }) => {
        if (message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          toast.error(message)
        }
      })
    }
  }, [searchData]);

  const selectRetailerStore = (value) => {
    setRetailerStoreId(value);

    setLoading(true);
    const res = axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}/machines?retailStoreId.equals=${value?.value}&rootOrgId.specified=true`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
    }).then((res) => {
      setSearchFilteredData(res?.data)
      setLoading(false);
    }).catch(({ message }) => {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message)
      }
    })
  };

  const clear = () => {
    setInput({retailer: ""});
    setRetailerStoreId(null);
    setFilteredData(null);
    setSearchFilteredData(null);
  };
 

 


  return (
    <div className=" w-full h_das_table select-none">
     
     <div
        className={
          loading
            ? "bg-black bg-opacity-0 absolute inset-0 z-10 flex items-center justify-center"
            : null
        }
      >
        <FadeLoader className="absolute " loading={loading} size={150} />
      </div>
      <div className="flex h-10 w-full items-center mt-2 justify-between py-8 px-5">

        <div className="w-3/4 flex justify-start h-full items-center">
          <div className="w-72 flex justify-end items-center">
            <SearchData
              name="retailer"
              label={"Retailer"}
              input={input}
              onChangeInput={onChangeInput}
              filteredData={filteredData}
              searchDataFn={searchDataFn}
              loading={loading}
            />
            <BiSearchAlt className="w-12  h-5 mr-4 absolute   text-blue-500" />
          </div>
          <div style={{ width: "40%", marginLeft: "28px" }}>
            <Select
              className="text-left"
              placeholder="Retailer Store"
              maxMenuHeight={162}
              value={retailerStoreId}
              onChange={selectRetailerStore}
              options={retailerStore?.map((item, index) => {
                return {
                  label: item?.name,
                  value: item?.id,
                  key: index,
                };
              })}
            />
          </div>
        </div>
        <button
          onClick={clear}
          className="bg-green-600 py-1.5 px-5 mr-7 rounded text-white cursor-pointer focus:outline-none"
        >
          Clear filters
        </button>
      </div>

      <div className="ag-theme-alpine  w-full h-full text-left">
        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            resizable: true,
          }}
          suppressRowClickSelection={true}
          rowSelection={"multiple"}
          pagination={true}
          paginationPageSize={10}
          rowData={searchFilteredData ? searchFilteredData : data}
        >
          <AgGridColumn
            headerName="Name"
            field="name"
            minWidth={200}
            sortable={true}
            headerCheckboxSelection={true}
            headerCheckboxSelectionFilteredOnly={true}
            checkboxSelection={true}
            cellRendererFramework={(params) => (
              <DashboardRoute params={params} />
            )}
          />
          <AgGridColumn
            field="deviceIdentifier"
            minWidth={100}
            sortable={true}
          />
          <AgGridColumn field="mode" minWidth={100} sortable={true} />
          <AgGridColumn field="macAddress" minWidth={180} sortable={true} />
          <AgGridColumn
            field="retailStore"
            minWidth={180}
            cellRendererFramework={(params) => (
              <RetailerStore params={params} />
            )}
          />
          <AgGridColumn
            field="status"
            minWidth={100}
            cellRendererFramework={(params) => (
              <Confirm params={params} name={"all"} />
            )}
          />
          <AgGridColumn
            field="action"
            minWidth={100}
            cellRendererFramework={(params) => (
              <AllAction
                params={params}
                url={url}
                viewUrl={viewUrl}
                editUrl={editUrl}
              />
            )}
          />
        </AgGridReact>
      </div>
    </div>
  );
}

export default All;
