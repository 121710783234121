import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { useAlert } from "react-alert";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import DashboardRoute from "./DashboardRoute";
import { useGet } from "../../../../api/useGet";
import Address from "./Address";
import Confirm from "./Confirm";
import CreateShelf from "./CreateShelf";
import EditShelf from "./EditShelf";
import ViewShelf from "./ViewShelf";
import { GrView } from "react-icons/gr";
import { FiEdit } from "react-icons/fi";

function RackView() {
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();


  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [view, setView] = useState(false);
  const onOpenView = () => setView(true);
  const onCloseView = () => setView(false);
  const [viewData, setViewData] = useState(null);

  const [edit, setEdit] = useState(false);
  const onOpenEdit = () => setEdit(true);
  const onCloseEdit = () => setEdit(false);
  const [editData, setEditData] = useState(null);


  const data = location?.data;

 // console.log("data: ", data);

  const shelf = useGet(
    `${process.env.REACT_APP_BASE_URL}/shelves?rackId.equals=${data?.id}`,
    "shelvesList"
  );

  const editFn = async (data) => {
    console.log("EData: ", data);
    onOpenEdit();
    setEditData(data);
  };

  const viewFn = async (data) => {
    console.log("VData: ", data);
    onOpenView();
    setViewData(data);
  };

  return (
    <>
      <div
        className={`w-full h_screen pt-2
         text-left flex select-none bg-gray-200`}
      >
        <div className="w-full h-full px-4  py-2">
          <div className="w-full  h-full  ">
            <div className="w-full h-8  justify-between flex bg-white rounded-lg items-center px-4">
              <div className="w-full h-8 flex items-center">
                <BiArrowBack
                  onClick={() => history.goBack()}
                  className="h-6 w-6 cursor-pointer"
                />
                <h2 className="font-bold text-lg ml-2">View Rack</h2>
              </div>
              <div className="w-36 flex justify-center items-center">
                <button  onClick={onOpenModal} className=" bg-gray-200 rounded px-4">
                  Create Shelf
                </button>
              </div>
              <CreateShelf
                open={open}
                onCloseModal={onCloseModal}
                rackData={data}
              />
            </div>

            <div className="w-full h-20 rounded-lg px-2 bg-white my-4 py-1 ">
              {/* <h2 className="px-2 font-bold">Order Summary :</h2> */}

              <div className="w-full flex justify-between px-2 pt-2 items-center">
                <div className="w-1/3 border-l-2 border-bg-500 text-gray-500 px-2">
                  <h2>
                    {`RackName: `}
                    {data?.name}
                  </h2>
                </div>
                <div className="w-1/3 border-l-2 border-bg-500 text-gray-500 px-2">
                  <h2>
                    {`Rack ID: `} {data?.internalCode}
                  </h2>
                </div>

                <div className="w-1/3 border-l-2 border-bg-500 text-gray-500 px-2">
                  <h2>
                    {`Max Shelves:`}{" "}
                    {data?.maxShelves ? data?.maxShelves : "Nill"}
                  </h2>
                </div>
              </div>
              {/* secondLine */}

              <div className="w-full flex justify-start px-2 pt-2  ">
                <div className="w-1/3 border-l-2 border-bg-500 text-gray-500 px-2">
                  <h2>
                    {`Retailer:`} {data?.retailer?.name}
                  </h2>
                </div>
                <div className="w-1/3 border-l-2 border-bg-500 text-gray-500 px-2">
                  <h2>
                    {`Retailer store:`} {data?.retailStore?.name}
                  </h2>
                </div>
                <div className="w-1/3 border-l-2 border-bg-500 text-gray-500 px-2">
                  <h2>
                    {`Status:`} {data?.status ? data?.status : "Nill"}
                  </h2>
                </div>
              </div>

              {/* thirdLine */}
            </div>

            <div className="w-full h-7/10 rounded-lg bg-white mt-4">
              <div className="ag-theme-alpine w-full h-full text-left">
                <AgGridReact
                  defaultColDef={{
                    flex: 1,
                    minWidth: 100,
                    resizable: true,
                  }}
                  suppressRowClickSelection={true}
                  rowSelection={"multiple"}
                  pagination={true}
                  paginationPageSize={10}
                  rowData={shelf?.data}
                >
                  <AgGridColumn
                    headerName="Shelf Name"
                    field="name"
                    minWidth={160}
                    sortable={true}
                    headerCheckboxSelection={true}
                    headerCheckboxSelectionFilteredOnly={true}
                    checkboxSelection={true}
                    cellRendererFramework={(params) => (
                      <DashboardRoute params={params} />
                    )}
                  />

                  <AgGridColumn
                    headerName="Shelf ID"
                    field="internalCode"
                    minWidth={80}
                    sortable={true}
                  />

                  <AgGridColumn
                    headerName="Max Scales"
                    field="maxScales"
                    minWidth={80}
                    sortable={true}
                    cellRendererFramework={(params) => {
                      return (
                        <>
                          <p className="ml-8">
                            {params?.data?.maxScales === null
                              ? 0
                              : params.data?.maxScales}
                          </p>
                        </>
                      );
                    }}
                  />

                  <AgGridColumn
                    headerName="Retail Store"
                    field="retailStore"
                    minWidth={170}
                    sortable={true}
                    cellRendererFramework={(params) => {
                      return (
                        <>
                          <p>{params?.data?.rack?.retailStore?.name}</p>
                        </>
                      );
                    }}
                  />
                  <AgGridColumn
                    headerName="Retail Address"
                    field="retailStore"
                    minWidth={300}
                    cellRendererFramework={(params) => (
                      <Address params={params} />
                    )}
                  />
                  <AgGridColumn
                    field="status"
                    minWidth={100}
                    cellRendererFramework={(params) => (
                      <Confirm params={params} />
                    )}
                  />
                  <AgGridColumn
                    headerName="action"
                    minWidth={100}
                    cellRendererFramework={(params) => (
                      <>
                        <div className="flex w-24 h-10 items-center">
                          <div className="border-2 border-gray-200 rounded-l-lg p-1 ">
                            <GrView
                              className="w-5 h-5 cursor-pointer "
                              onClick={() => console.log("data: ", params?.data)}
                            />
                          </div>
                          <div className="border-2 border-gray-200 rounded-r-lg p-1">
                            <FiEdit
                              className="w-5 h-5 cursor-pointer"
                              onClick={() => editFn(params?.data)}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  />
                </AgGridReact>
              </div>
              <ViewShelf
                open={view}
                onCloseModal={onCloseView}
                data={viewData}
              />

              <EditShelf
                open={edit}
                onCloseModal={onCloseEdit}
                data={editData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RackView;
