import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Typography, Divider } from "@mui/material";

import BackArrowButton from "../../../../../components/buttons/BackarrowButton";

function TableStr({
  title,
  viewUrl,
  editUrl,
  listUrl,
  getName,
  Enabled,
  Refunded,
  refundUrl,
  All,
  Pending,
  Disabled,
  merchant,
  orgName,
}) {
  const history = useHistory();

  const handleBack = () => history.goBack();

  return (
    <div className="shadow-lg select-none">
      <div class="flex flex-row m-4">
        <BackArrowButton onClick={() => handleBack()} />
        <Typography
          variant="h4"
          sx={{ textAlign: "center", fontWeight: 500, width: "100%" }}
        >
          Order Management
        </Typography>
      </div>
      <Divider sx={{ borderBottomWidth: 1, borderBottomColor: "black" }} />
      <All
        // data={data}
        viewUrl={viewUrl}
        editUrl={editUrl}
        listUrl={listUrl}
        refundUrl={refundUrl}
        merchant={merchant}
        orgName={orgName}
      />
    </div>
  );
}

export default TableStr;
