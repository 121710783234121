import React, { useState } from "react";
import { IoIosClose } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";


function ViewShelf(props) {
  const { open, onCloseModal, data } = props;
 
  return (
    <>
      {open && (
        <div className="bg-black bg-opacity-80 absolute inset-0 z-10 flex items-center justify-center">
          <div
            className="w-96 h-7/10 shadow-lg  bg-white"
            style={{ borderRadius: 20 }}
          >
            <div
              onClick={onCloseModal}
              className="absolute mt-4 ml-4 cursor-pointer z-10 shadow-lg w-8 h-8 justify-center items-center flex rounded-full bg-gray-200"
            >
              <IoIosClose onClick={onCloseModal} className="h-8 w-8" />
            </div>

            <div className="w-full h-96">
              <div class="grid h-full  grid-cols-1 gap-x-12 mt-12 p-4  ">
                <table className="table-auto rounded-lg border-collapse border  w-full h-8 ">
                  <tbody className="text-left">
                    <tr>
                      <th className="w-32 border border-gray-300 bg-gray-200 px-2">
                        Scale ID
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        {data?.scaleId}
                      </th>
                    </tr>
                    {/* <tr>
                      <th className="w-40 border border-gray-300 bg-gray-200 px-2">
                        Product ID:
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        {data?.internalCode}
                      </th>
                    </tr> */}

                    <tr>
                      <th className="w-40 border border-gray-300 bg-gray-200 px-2">
                        Min Unit
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        {data?.minUnit === null ? 0 : data.minUnit}
                      </th>
                    </tr>
                    <tr>
                      <th className="w-40 border border-gray-300 bg-gray-200 px-2">
                        Max Unit 
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        {data?.maxUnit === null ? 0 : data.maxUnit}
                      </th>
                    </tr>
                    <tr>
                      <th className="w-40 border border-gray-300 bg-gray-200 px-2">
                        Shelf ID 
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        {data?.groupId === null ? 0 : data.groupId}
                      </th>
                    </tr>
                    <tr>
                      <th className="w-40 border border-gray-300 bg-gray-200 px-2">
                        Row Number
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        {data?.row === null ? 0 : data.row}
                      </th>
                    </tr>
                    <tr>
                      <th className="w-40 border border-gray-300 bg-gray-200 px-2">
                        Status 
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        {data?.status}
                      </th>
                    </tr>
                  </tbody>
                </table>

                <h2 className="mt-4">Retail Store</h2>

                <table className="table-auto rounded-lg border-collapse border  w-full h-8 ">
                  <tbody className="text-left">
                  <tr>
                      <th className="w-32 border border-gray-300 bg-gray-200 px-2">
                        Store Type
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        {data?.retailStore?.name}
                      </th>
                    </tr>
                    <tr>
                      <th className="w-32 border border-gray-300 bg-gray-200 px-2">
                        Store Type
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        {data?.retailStore?.storeType}
                      </th>
                    </tr>
                    <tr>
                      <th className="w-40 border border-gray-300 bg-gray-200 px-2">
                        Max Allowed Active Shopping 
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        {data?.retailStore?.maxAllowedActiveShopping}
                      </th>
                    </tr>

                    <tr>
                      <th className="w-40 border border-gray-300 bg-gray-200 px-2">
                        Max Allowed Group Shopping Users 
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        {data?.retailStore?.maxAllowedGroupShoppingUsers}
                      </th>
                    </tr>
                   
                  
                   
                   
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ViewShelf;
