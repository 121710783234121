import React, { useState } from "react";
import { useGet } from "../../../../../../../api/useGet";

function TableStr({
  deviceId,
  listUrl,
  getName,
  Scales,
  Promotion,
  viewUrl,
  editUrl,
  getOrgName,
  orgIdUrl,
}) {
  const [click, setClicked] = useState("scales");
  const { isLoading, error, data } = useGet(listUrl, getName);

  const orgList = useGet(orgIdUrl, getOrgName);

  function findField(filterData) {
    // return uniq(filterData)?.filter((value, index) => console.log(value));
  }

  function uniq(rawData) {
    var arr = [];
    rawData?.map((item) => {
      item.name ? arr.push(item.name) : arr.push(item.login);
    });
    return arr;
  }

  const options = (e) => {
    const type = e.target.name;
    switch (type) {
      case "scales":
        setClicked(type);
        break;
      case "promotion":
        setClicked(type);
        break;

      default:
    }
  };

  return (
    <div className="w-full h-full ">
      <div className="w-full h-10 ">
        <div className="h-6 w-full  flex justify-start ">
          <button
            onClick={options}
            name="scales"
            className={`px-2 rounded-t-sm ${
              click === "scales" ? "bg-white " : null
            } focus:outline-none`}
          >
            Scales
          </button>
          {/* <button
            onClick={options}
            name="promotion"
            className={`mx-4 px-2 rounded-t-sm  ${
              click === "promotion" ? "bg-white" : null
            } focus:outline-none`}
          >
           Promotion
          </button> */}
        </div>
      </div>

      {click === "promotion" ? (
        <Promotion />
      ) : click === "scales" ? (
        <Scales
          data={data}
          deviceId={deviceId}
          isLoading={isLoading}
          error={error}
          viewUrl={viewUrl}
          editUrl={editUrl}
          retailerIdUrl={`${process.env.REACT_APP_BASE_URL}/api/retail-stores?page=0&sort=createdDate`}
          getRetailerName={"retailerIdList"}
          orgList={orgList}
          // sortedData={findField(data)}
        />
      ) : null}
    </div>
  );
}

export default TableStr;
