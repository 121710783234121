import React, { useState, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "react-toggle/style.css";
import Select from "react-select";
import DashboardRoute from "./DashboardRoute";
import NumberFormat from "react-number-format";
import moment from "moment";

function InReview({
  isLoading,
  error,
  data,
  url,
  viewUrl,
  editUrl,
  sortedData,
}) {
 // console.log("sortedData: ", sortedData);
  const [checked, setChecked] = useState(false);
  const [value, setValue] = useState("");

  useEffect(() => {
    localStorage.setItem("navigate", "inReview");
  }, []);

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };

  const changeHandler = (value) => {
    setValue(value);
  };

  return (
    <div className=" w-full h_table">
      <div className="flex h-10 w-full items-center mt-2 justify-between py-8 px-4">
      <div style={{ width: "40%" }}>
          <Select
            className="text-left"
            placeholder="Search Mearchant"
            maxMenuHeight={162}
            value={value}
            onChange={changeHandler}
            options={sortedData?.map((item, index) => {
              return {
                // label: item.retailerName,
                // value: item.retailerName,
                label: item,
                value: item,
                key: index,
              };
            })}
          />
        </div>
        <div style={{ width: "40%" }}>
          <Select
            className="text-left"
            placeholder="Search RetailerStore"
            maxMenuHeight={162}
            value={value}
            onChange={changeHandler}
            options={sortedData?.map((item, index) => {
              return {
                // label: item.retailerName,
                // value: item.retailerName,
                label: item,
                value: item,
                key: index,
              };
            })}
          />
        </div>
        <button className="bg-green-600 py-1.5 px-5 mr-7 rounded text-white">
          Clear filters
        </button>
      </div>

      <div className="ag-theme-alpine  w-full h-full text-left">
      <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            resizable: true,
          }}
          suppressRowClickSelection={true}
          rowSelection={"multiple"}
          pagination={true}
          paginationPageSize={10}
          rowData={data}
        >
          <AgGridColumn
            headerName="Exit Time"
            field="exitTime"
            minWidth={150}
            sortable={true}
            headerCheckboxSelection={true}
            headerCheckboxSelectionFilteredOnly={true}
            checkboxSelection={true}
            cellRendererFramework={(params) => (
              <DashboardRoute params={params} />
            )}
          />

          {/* <AgGridColumn
            headerName="Exit Timestamp"
            field="checkoutDate"
            minWidth={170}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <>
                  <p>
                    {moment(params?.data?.checkoutDate).format(
                      "MMM Do YYYY h:mm A"
                    )}
                  </p>
                </>
              );
            }}
          /> */}

          <AgGridColumn
            headerName="Store Code"
            field="retailStoreCode"
            minWidth={120}
            sortable={true}
          />

      
        </AgGridReact>

      </div>
    </div>
  );
}

export default InReview;
