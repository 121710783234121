import React from "react";
import { GrView } from "react-icons/gr";
import { FiEdit } from "react-icons/fi";
import { useHistory } from "react-router-dom";

function Action({ params, url, viewUrl, editUrl }) {
  const history = useHistory();

  const edit = () => {
    localStorage.setItem("rackData",JSON.stringify( params?.data))
    history.push({
      pathname: editUrl,
      data: params.data,
    });
  };

  const view = (params) => {
     localStorage.setItem("rackData", JSON.stringify(params?.data))
    localStorage.setItem("rackId", params?.data?.id)
    history.push({
      pathname: viewUrl,
      data: params.data,
    });
  };
  return (
    <>
      <div className="flex w-16 h-10 items-center">
        <div className="border-2 border-gray-200 rounded-l-lg p-1 ">
          <GrView className="w-5 h-5 cursor-pointer " onClick={()=>view(params)} />
        </div>
        <div className="border-2 border-gray-200 rounded-r-lg p-1">
          <FiEdit className="w-5 h-5 cursor-pointer" onClick={()=>edit(params)} />
        </div>
      </div>
    </>
  );
}

export default Action;
