import React, { useState } from "react";
import { Disclosure } from "@headlessui/react";

const DisclosureCmp = ({ allScales, onSelectRow, selectedRow, item }) => {
  console.log("🚀 ~ file: DisclosureCmp.js:5 ~ item:", item);
  // console.log("🚀 ~ file: DisclosureCmp.js:5 ~ allScales:", allScales);
  const [img, setImg] = useState("");

  const sharedProduct = (product) => {
    const matched = item?.productInfos?.some(
      (ele) =>
        ele?.productId === product?.productId &&
        item.scaleId === product?.scaleId
    );

    return matched;
  };

  const findProductByBarcode = (barcode) =>
    item?.productInfos?.find((prod) => prod?.barCode == barcode);

  return (
    <div className="space-y-1">
      {allScales?.map((scale) => {
        return (
          <Disclosure defaultOpen={true}>
            {({ open }) => (
              <>
                {open && scale?.productInfos?.length > 0 && (
                  <Disclosure.Panel
                    open={true}
                    className="text-sm text-gray-500 border-6 rounded border-gray-100"
                  >
                    <div className="w-full flex justify-between items-center">
                      <span className="">{`Scale No : ${scale?.scaleIdentifier}`}</span>
                      <span>{`product mapped : ${scale?.productInfos?.length}`}</span>
                    </div>
                    <table className="w-full">
                      <thead className="bg-gray-200 h-[40px]">
                        <tr className="text-sm font-bold divide-y divide-gray-100">
                          <th className="w-[5%]"></th>
                          <th className="w-[15%]">Product Image</th>
                          <th className="w-[15%]">Estimated Qty</th>
                          <th className="w-[45%]">Product Name</th>
                          <th className="w-[20%]">Barcode</th>
                        </tr>
                      </thead>
                      <tbody className="text-sm divide-y divide-gray-100">
                        {scale?.productInfos?.map((product, index) => {
                          return (
                            <>
                              {product.isPrimary ? (
                                <tr
                                  onClick={() => {
                                    if (item?.syncStatus !== "CART_REVIEWED") {
                                      if (
                                        selectedRow?.scaleProductId ===
                                        product?.scaleProductId
                                      ) {
                                        onSelectRow("");
                                      } else {
                                        onSelectRow(product);
                                      }
                                    }
                                  }}
                                  key={index}
                                  // className={`${
                                  //   sharedProduct(product) ? "bg-green-100" : ""
                                  // }`}
                                >
                                  <td
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      height: "70px",
                                      width: "80px",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <p className="text-gray-800 text-[16px] font-bold mb-1">
                                      {scale?.scaleType === "SURFBOARD"
                                        ? `${scale?.scaleGroupName}-${product?.productPosition}`
                                        : `${scale?.scaleGroupName}-${product?.scaleIdentifier}`}
                                    </p>
                                    <input
                                      type="checkbox"
                                      className="w-6 h-6"
                                      //disabled={selectedRow?.scaleProductId === product?.scaleProductId}
                                      checked={
                                        selectedRow?.scaleProductId ===
                                          product?.scaleProductId ||
                                        (item?.scaleId ===
                                          scale?.scaleIdentifier &&
                                          selectedRow?.productId ===
                                            product?.productId)
                                          ? true
                                          : false
                                      }
                                    />
                                  </td>

                                  <td className="w-[60px] h-[70px]">
                                    <img
                                      className="w-[60px] h-full"
                                      onMouseOver={() =>
                                        setImg({
                                          id: product.scaleProductId,
                                          imageUrl: product.imageUrl,
                                        })
                                      }
                                      onMouseOut={() => setImg("")}
                                      src={
                                        product?.imageUrl ||
                                        "/images/noImage.jpg"
                                      }
                                      alt="product image"
                                    />

                                    {img &&
                                      img.id === product.scaleProductId && (
                                        <div className=" w-[350px] h-[350px] shadow-lg rounded-lg fixed top-[58px] right-[42px]">
                                          <img
                                            src={img?.imageUrl}
                                            alt="product image"
                                            className="rounded-lg w-full h-full"
                                          />
                                        </div>
                                      )}
                                  </td>

                                  <td>
                                    <div className="w-full flex flex-col space-y-1 justify-start items-start font-medium text-gray-800">
                                      <div
                                        className="text-[18px] font-bold"
                                        key={index}
                                      >
                                        <p>
                                          {
                                            findProductByBarcode(
                                              product?.barCode
                                            )?.estimatedQuantity
                                          }
                                        </p>
                                      </div>
                                    </div>
                                  </td>

                                  <td className="w-[300px]">
                                    <div className="w-full flex flex-col space-y-1 justify-start items-start font-medium text-gray-800">
                                      <div
                                        className="text-[18px] font-bold"
                                        key={index}
                                      >
                                        <p>{product?.productName}</p>
                                      </div>
                                    </div>
                                  </td>

                                  <td className="">
                                    <div className="w-full flex flex-col space-y-1 justify-center items-start font-medium text-gray-800">
                                      <div
                                        className="text-center text-[18px] font-bold"
                                        key={index}
                                      >
                                        <p>{product?.barCode}</p>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ) : (
                                <div className="w-full h-full flex justify-center items-center">
                                  <p>Not Mapped</p>
                                </div>
                              )}
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </Disclosure.Panel>
                )}
              </>
            )}
          </Disclosure>
        );
      })}
    </div>
  );
};

export default DisclosureCmp;
