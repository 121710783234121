import React, { useEffect, useState } from "react";
import { IoMdRefresh } from "react-icons/io";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import moment from "moment";
import SendAlertModal from "./modal/SendAlertModal";
import { Button } from "@mui/material";
import RequestGhostShopperVideo from "./modal/RequestGhostShopperVideo";

const initialRoute = (value) => {
  const type = value;

  switch (type) {
    case "openEntryEvents":
      return "openEntryEvents";

    case "matchedExit":
      return "matchedExit";

    case "fullfillmentEvents":
      return "fullfillmentEvents";

    case "openExitEvents":
      return "openExitEvents";

    case "entryDecisionEvents":
      return "entryDecisionEvents";

    case "processed":
      return "processed";

    default:
      return "openEntryEvents";
  }
};

function TableStr({
  title,
  viewUrl,
  editUrl,
  listUrl,
  entryDecisionListUrl,
  getName,
  OpenEntryEvents,
  Processed,
  OpenExitEvents,
  FullfillmentEvents,
  EntryDecisionEvents,
  entryDecisionViewUrl,
  MatchedExit,
  exitDecisionListUrl,
  ExitDecisionEvents,
  exitDecisionViewUrl,
}) {
  const history = useHistory();

  const [click, setClicked] = useState(
    initialRoute(localStorage.getItem("tab"))
  );

  const [refresh, setRefresh] = useState(false);

  const [apisCalled, setApisCalled] = useState(false);

  const [reviewExitCount, setReviewExitCount] = useState(0);

  const [reviewEntryCount, setReviewEntryCount] = useState(0);

  const [openEntryEventCount, setOpenEntryEventCount] = useState(0);

  const [checkedOutEntryCount, setCheckedoutEntryCount] = useState(0);

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const options = (e) => {
    const type = e.target.name;
    switch (type) {
      case "openEntryEvents":
        setClicked(type);
        localStorage.setItem("tab", type);
        break;

      case "matchedExit":
        setClicked(type);
        localStorage.setItem("tab", type);
        break;

      case "openExitEvents":
        setClicked(type);
        localStorage.setItem("tab", type);
        break;

      case "fullfillmentEvents":
        setClicked(type);
        localStorage.setItem("tab", type);
        break;

      case "processed":
        setClicked(type);
        localStorage.setItem("tab", type);
        break;

      case "entryDecisionEvents":
        setClicked(type);
        localStorage.setItem("tab", type);
        break;
      case "exitDecisionEvents":
        setClicked(type);
        localStorage.setItem("tab", type);
        break;

      default:
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!apisCalled) {
          // Call the match exit API
          const result1 = await axios({
            method: "GET",
            url: `${listUrl}?sort=entryTime,desc&eventStatus.in=CHECKEDOUT&userType.equals=SHOPPER`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("user-info")}`,
            },
          });
          setCheckedoutEntryCount(result1?.headers?.["x-total-count"]);

          // Call the open entry events  API
          const result2 = await axios({
            method: "GET",
            url: `${listUrl}?sort=entryTime,desc&eventStatus.in=APPROVED`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("user-info")}`,
            },
          });
          setOpenEntryEventCount(result2?.headers?.["x-total-count"]);

          // Call the review exit events API
          // const result3 = await axios({
          //   method: "GET",
          //   url: `${listUrl}?sort=createdDate,desc&page=0&size=10&status.equals=OPEN`,
          //   headers: {
          //     "Content-Type": "application/json",
          //     Authorization: `Bearer ${localStorage.getItem("user-info")}`,
          //   },
          // });
          // setReviewExitCount(result3?.headers?.["x-total-count"]);

          // Call the review entry events API
          // const result4 = await axios({
          //   method: "GET",
          //   url: `${listUrl}?sort=createdDate,asc&eventTime.greaterThanOrEqual=${moment()
          //     .utc()
          //     .subtract(3, "hours")
          //     .format("YYYY-MM-DDTHH:mm:ss.S[Z]")}`,
          //   headers: {
          //     "Content-Type": "application/json",
          //     Authorization: `Bearer ${localStorage.getItem("user-info")}`,
          //   },
          // });
          // setReviewEntryCount(result4?.headers?.["x-total-count"]);

          // Set the flag indicating that APIs have been called
          setApisCalled(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [apisCalled]);

  return (
    <div className="w-full h-full shadow-lg select-none">
      <div
        className={` h-12 flex justify-between p-6 items-center bg-gray-100`}
      >
        <h2 className="font-bold text-2xl">{title}</h2>
        <div className="flex items-center">
          <Button
            // size={28}
            variant="outlined"
            style={{ marginRight: "12px" }}
            onClick={handleOpen}
          >
            Ghost Shopper Video request
          </Button>
          <SendAlertModal />
          <Button
            // size={28}
            variant="contained"
            style={{ marginLeft: "12px" }}
            onClick={() => setRefresh(!refresh)}
          >
            Refresh
          </Button>
        </div>
      </div>

      <div className="w-full flex h-10 p-2  -mt-2 ">
        <div className="h-10 w-full  flex justify-start ">
          {/* <button
            onClick={options}
            name="openExitEvents"
            className={`mx-4 border-b-2 ${click === "openExitEvents" ? "border-blue-500" : null
              } focus:outline-none`}
          >
            Open Exit Events
          </button> */}
          <button
            onClick={options}
            name="entryDecisionEvents"
            className={`mx-4 border-b-2 ${
              click === "entryDecisionEvents" ? "border-blue-500" : null
            } focus:outline-none`}
          >
            Review Entry Events ({reviewEntryCount})
          </button>
          <button
            onClick={options}
            name="exitDecisionEvents"
            className={`mx-4 border-b-2 ${
              click === "exitDecisionEvents" ? "border-blue-500" : null
            } focus:outline-none`}
          >
            Review Exit Events ({reviewExitCount})
          </button>

          <button
            onClick={options}
            name="openEntryEvents"
            className={`mx-4 border-b-2 ${
              click === "openEntryEvents" ? "border-blue-500" : null
            } focus:outline-none`}
          >
            Open Entry Events ({openEntryEventCount})
          </button>

          <button
            onClick={options}
            name="fullfillmentEvents"
            className={`mx-4 border-b-2 ${
              click === "fullfillmentEvents" ? "border-blue-500" : null
            } focus:outline-none`}
          >
            Partner Entries
          </button>

          <button
            onClick={options}
            name="matchedExit"
            className={`mx-4 border-b-2 ${
              click === "matchedExit" ? "border-blue-500" : null
            } focus:outline-none`}
          >
            Matched Exit ({checkedOutEntryCount})
          </button>

          <button
            onClick={options}
            name="processed"
            className={`mx-4 border-b-2 ${
              click === "processed" ? "border-blue-500" : null
            } focus:outline-none`}
          >
            Processed
          </button>
        </div>
      </div>

      {click === "openEntryEvents" ? (
        <OpenEntryEvents
          listUrl={listUrl}
          viewUrl={viewUrl}
          editUrl={editUrl}
          refresh={refresh}
          setRefresh={setRefresh}
          setCheckedoutEntryCount={setOpenEntryEventCount}
        />
      ) : click === "entryDecisionEvents" ? (
        <EntryDecisionEvents
          listUrl={entryDecisionListUrl}
          viewUrl={entryDecisionViewUrl}
          refresh={refresh}
          setRefresh={setRefresh}
          setCheckedoutEntryCount={setReviewEntryCount}
        />
      ) : click === "fullfillmentEvents" ? (
        <FullfillmentEvents
          listUrl={listUrl}
          viewUrl={viewUrl}
          editUrl={editUrl}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      ) : click === "matchedExit" ? (
        <MatchedExit
          listUrl={listUrl}
          viewUrl={viewUrl}
          editUrl={editUrl}
          refresh={refresh}
          setRefresh={setRefresh}
          setCheckedoutEntryCount={setCheckedoutEntryCount}
        />
      ) : click === "openExitEvents" ? (
        <OpenExitEvents listUrl={listUrl} viewUrl={viewUrl} editUrl={editUrl} />
      ) : click === "processed" ? (
        <Processed
          listUrl={`${process.env.REACT_APP_SHOPPER_URL}/stores/carts?sort=createdDate,desc`}
          viewUrl={viewUrl}
          editUrl={editUrl}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      ) : click === "exitDecisionEvents" ? (
        <ExitDecisionEvents
          listUrl={exitDecisionListUrl}
          viewUrl={exitDecisionViewUrl}
          refresh={refresh}
          setRefresh={setRefresh}
          setCheckedoutEntryCount={setReviewExitCount}
        />
      ) : null}
      {open && <RequestGhostShopperVideo open={open} onClose={handleClose} />}
    </div>
  );
}

export default TableStr;
