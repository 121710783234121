import React, { useState, useEffect } from "react";
import AllAction from "./AllAction";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "react-toggle/style.css";
import RetailerStore from "./RetailerStore";
import Select from "react-select";
import DashboardRoute from "./DashboardRoute";
import Confirm from "./Confirm";
import { BiSearchAlt } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";
import SearchData from "../../../../components/SearchData";
import axios from "axios";
import { useQuery } from "react-query";
import Pagination from "@mui/material/Pagination";
import { MdClear } from "react-icons/md";

function All({ listUrl, url, viewUrl, editUrl, sortedData, allUrl }) {
  const [allCount, setAllCount] = useState();
  const [filteredData, setFilteredData] = useState(null);
  const [searchData, setSearchData] = useState("");
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const [input, setInput] = useState({
    name: "",
    retailerStore: "",
    type: "",
  });

  const findUrl = (name) => {
    switch (name) {
      case "name":
        return `${listUrl}&page=${
          activePage - 1
        }&size=9&name.equals=${input?.name?.toLocaleLowerCase()}`;
        break;
      case "retailerStore":
        return `${listUrl}&page=${
          activePage - 1
        }&size=9&retailStoreId.equals=${searchData}`;
        break;

      default:
        return `${listUrl}&page=${activePage - 1}&size=9`;
        break;
    }
  };

  const [activePage, setActivePage] = useState(1);

  const fetchData = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: findUrl(input?.type),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      });
      setAllCount(res?.headers?.["x-total-count"]);
      return res.data;
    } catch ({ message }) {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      }
    }
  };

  const { data, isLoading, isIdle, error } = useQuery(
    ["machineList", activePage, searchData, input?.name],
    async () => fetchData(),
    {
      enabled: !!activePage || !!searchData || input?.name,
      cacheTime: Infinity,
      staleTime: 0,
    }
  );

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  const searchDataFn = (name, data) => {
    setSearchData(data?.id);
    setInput({ ...input, [name]: data?.name });
    setFilteredData(null);
  };

  const onChangeInput = (e, name) => {
    const value = e.target.value;

    setInput({ ...input, [name]: value, type: name });

    if (name === "retailerStore") {
      setLoading(true);
      axios({
        method: "GET",
        url: `${
          process.env.REACT_APP_BASE_URL
        }/_search/retail-stores?name.contains=${value.toLowerCase()}*`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      }).then((res) => {
        setFilteredData(res?.data);
        setLoading(false);
      });
    }
  };

  const clear = () => {
    setInput({ retailerStore: "", name: "", type: "" });
    setFilteredData(null);
    setSearchData(null);
  };

  return (
    <div className=" w-full h_table select-none">
      <div
        className={
          loading
            ? "bg-black bg-opacity-0 absolute inset-0 z-10 flex items-center justify-center"
            : null
        }
      >
        <FadeLoader className="absolute " loading={loading} size={150} />
      </div>
      <div className="flex h-10 w-full items-center mt-2 justify-between py-8 px-3">
        <div className="w-auto flex space-x-8 items-center">
          <div className="w-72 flex justify-end items-center">
            <input
              name="name"
              placeholder="Search by Name"
              value={input?.name}
              onChange={(e) => onChangeInput(e, "name")}
              className="w-full h-10  focus:outline-none rounded px-4 border border-black"
            />
            {input["name"]?.length > 0 ? (
              <MdClear
                size={24}
                className="cursor-pointer -ml-[34px]"
                onClick={clear}
              />
            ) : (
              <BiSearchAlt
                size={24}
                className="cursor-pointer -ml-[34px] text-blue-500"
              />
            )}
          </div>

          <div className="w-72 flex justify-end items-center">
            <SearchData
              name="retailerStore"
              label={"Retailer Store"}
              input={input}
              onChangeInput={onChangeInput}
              setSearchData={setSearchData}
              filteredData={filteredData}
              searchDataFn={searchDataFn}
              loading={loading}
            />
            <BiSearchAlt className="w-12  h-5 mr-4 absolute   text-blue-500" />
          </div>
        </div>
        <button
          onClick={clear}
          className="bg-green-600 py-1.5 px-5 mr-7 rounded text-white cursor-pointer focus:outline-none"
        >
          Clear filters
        </button>
      </div>

      <div className="ag-theme-alpine  w-full h-full text-left">
        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            resizable: true,
          }}
          suppressRowClickSelection={true}
          rowSelection={"multiple"}
          rowData={data}
        >
          <AgGridColumn
            headerName="Name"
            field="name"
            minWidth={200}
            sortable={true}
            headerCheckboxSelection={true}
            headerCheckboxSelectionFilteredOnly={true}
            checkboxSelection={true}
            cellRendererFramework={(params) => (
              <DashboardRoute params={params} />
            )}
          />
          <AgGridColumn
            field="deviceIdentifier"
            minWidth={160}
            maxWidth={160}
            sortable={true}
          />
          <AgGridColumn
            field="mode"
            minWidth={130}
            maxWidth={130}
            sortable={true}
          />
          <AgGridColumn
            field="macAddress"
            minWidth={150}
            maxWidth={150}
            sortable={true}
          />
          <AgGridColumn
            field="retailStore"
            minWidth={180}
            cellRendererFramework={(params) => (
              <RetailerStore params={params} />
            )}
          />
          <AgGridColumn
            field="status"
            minWidth={120}
            maxWidth={120}
            cellRendererFramework={(params) => (
              <Confirm params={params} name={"all"} />
            )}
          />
          <AgGridColumn
            field="action"
            minWidth={100}
            cellRendererFramework={(params) => (
              <AllAction
                params={params}
                url={url}
                viewUrl={viewUrl}
                editUrl={editUrl}
              />
            )}
          />
        </AgGridReact>
      </div>
      <div className="absolute bottom-8 right-12  flex justify-center item-center">
        <Pagination
          count={
            allCount % 9 === 0
              ? parseInt(allCount / 9)
              : parseInt(allCount / 9) + 1
          }
          showFirstButton
          showLastButton
          page={activePage}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
}

export default All;
