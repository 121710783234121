import React, { useState, useMemo } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TextField2 } from "../../../validation/TextField2";
import { useHistory } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import countryList from "react-select-country-list";
import Select from "react-select";
import { useAlert } from "react-alert";
import { usePost } from "../../../../api/usePost";

let validate;
validate = Yup.object({
  name: Yup.string().required("Required"),
  email: Yup.string().email().required("Required"),
  zip: Yup.number().typeError("Not a number").required("Required"),
  street1: Yup.string().required("Required"),
  street2: Yup.string(),
  city: Yup.string().required("Required"),
  administrativeArea: Yup.string().required("Required"),
  latitude: Yup.number().typeError("Not a number").required("Required"),
  longitude: Yup.number().typeError("Not a number").required("Required"),
  state: Yup.string().required("Required"),
  contactFirstName: Yup.string().required("Required"),
  contactLastName: Yup.string().required("Required"),
  contactNo: Yup.number().required("Required"),
});

function CreateHawkeye() {
  const [title, setTitle] = useState("Hawkeye");
  const [addressType, setAdressType] = useState();
  const alert = useAlert();
  const history = useHistory();

  const [value, setValue] = useState("");
  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (value) => {
    setValue(value);
  };

  const createMerchant = usePost(
    `${process.env.REACT_APP_BASE_URL}/orgs`,
    "hawkeyeList"
  );

  return (
    <>
      <div className="w-full h_page flex">
        <div className="w-full h-full flex justify-center items-center bg-gray-200 ">
          <Formik
            initialValues={{
              name: "",
              email: "",
              street1: "",
              street2: "",
              city: "",
              administrativeArea: "",
              latitude: "",
              longitude: "",
              state: "",
              zip: "",
              contactFirstName: "",
              contactLastName: "",
              contactNo: "",
            }}
            validationSchema={validate}
            onSubmit={async (values) => {
              //console.log(values);
              if (values.name !== "" && values.email !== "") {
                try {
                  createMerchant.mutate({
                    orgType: "Merchant",
                    internalCode: "MMARK001",
                    name: values.name,
                    email: values.email,
                    addresses: [
                      {
                        zipCode: values.zip,
                        street1: values.street1,
                        street2: values.street2,
                        cityOrVillage: values.city,
                        administrativeArea1: values.administrativeArea,
                        state: values.state,
                        country: value.value,
                        lat: parseFloat(values.latitude),
                        lng: parseFloat(values.longitude),
                        addressType: addressType ? "PRIMARY" : "OTHER",
                        contactPersonName: values.contactFirstName,
                        contactPersonLastName: values.contactLastName,
                        contactPersonPhone: values.contactNo,
                      },
                    ],
                  });

                  if (
                    !createMerchant.isError &&
                    createMerchant.error === null &&
                    !createMerchant.isSuccess &&
                    createMerchant.status === "idle"
                  ) {
                    history.goBack();
                    alert.success(
                      <div style={{ textTransform: "none" }}>
                        Successfully Added!
                      </div>
                    );
                  } else {
                    alert.error(
                      <div style={{ textTransform: "none" }}>
                        Please Check all Fields!
                      </div>
                    );
                  }
                } catch (error) {
                  alert.error(
                    <div style={{ textTransform: "none" }}>
                      you are offline!
                    </div>
                  );
                }
              }
            }}
          >
            {() => (
              <Form className="w-11/12 mx-auto h-4/5 bg-white rounded-lg shadow-lg ">
               
                  <div className="w-full h-20 flex bg-blue-100">
                    <div className="w-10 p-2 h-full flex items-center">
                      <BiArrowBack
                        onClick={() => history.goBack()}
                        className="h-10 w-10 cursor-pointer"
                      />
                    </div>
                    <div className="w-full h-full px-7 py-3">
                      <h2 className="font-bold text-2xl">
                        {`Create new ${title}`}
                      </h2>
                      <p className="">
                        {`Fill out the form below for a ${title}.`}
                      </p>
                    </div>
                  </div>

                  <div className="w-11/12 mx-auto px-6 my-2">
                    <div className="w-full h-20 flex mt-4  items-center">
                      <div className="flex  px-4 mr-4   w-full items-center select-none">
                        <TextField2 label="Name" name="name" type="text" />
                      </div>

                      <div className="flex  px-4 ml-4   w-full items-center select-none">
                        <TextField2 label="Email" name="email" type="email" />
                      </div>
                    </div>

                    <h2 className="px-7">Address : </h2>

                    <div className="w-full h-20 flex mt-4  items-center">
                      <div className="flex  px-4 ml-4   w-full items-center select-none">
                        <TextField2
                          label="Street 1"
                          name="street1"
                          type="text"
                        />
                      </div>

                      <div className="flex  px-4 ml-4   w-full items-center select-none">
                        <TextField2
                          label="Street 2"
                          name="street2"
                          type="text"
                        />
                      </div>

                      <div className="flex  px-4 ml-4   w-full items-center select-none">
                        <TextField2
                          label="City Or Village"
                          name="city"
                          type="text"
                        />
                      </div>

                      <div className="flex  px-4 ml-4   w-full items-center select-none">
                        <TextField2
                          label="Administrative Area"
                          name="administrativeArea"
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="w-full h-20 flex items-center">
                      <div className="flex  px-4 ml-4  w-full items-center select-none">
                        <TextField2
                          label="Latitude"
                          name="latitude"
                          type="text"
                        />
                      </div>

                      <div className="flex  px-4 ml-4   w-full items-center select-none">
                        <TextField2
                          label="Longitude"
                          name="longitude"
                          type="text"
                        />
                      </div>

                      <div className="flex  px-4 ml-4   w-full items-center select-none">
                        <TextField2 label="State" name="state" type="text" />
                      </div>

                      <div className="flex  px-4 ml-4 h-10 -mt-6  w-full items-center select-none">
                        <div style={{ width: "198px" }}>
                          <Select
                            options={options}
                            placeholder="Select country ..."
                            maxMenuHeight={162}
                            value={value}
                            onChange={changeHandler}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="w-full h-20 flex items-center">
                      <div className="flex  px-4 ml-4   w-full items-center select-none">
                        <TextField2 label="Zip Code" name="zip" type="text" />
                      </div>

                      <div className="flex  px-4 ml-4   w-full items-center select-none">
                        <TextField2
                          label="contact First Name"
                          name="contactFirstName"
                          type="text"
                        />
                      </div>

                      <div className="flex  px-4 ml-4   w-full items-center select-none">
                        <TextField2
                          label="contact Last Name"
                          name="contactLastName"
                          type="text"
                        />
                      </div>

                      <div className="flex  px-4 ml-4   w-full items-center select-none">
                        <TextField2
                          label="contact Number"
                          name="contactNo"
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="w-full h-16 flex justify-between items-center px-4 -mt-2">
                      <div className="flex items-center h-6 pl-5">
                        <input
                          type="checkbox"
                          onChange={(e) => setAdressType(e.target.checked)}
                          class="form-checkbox h-5 w-5 text-green-600"
                        />
                        <span className="mx-1 -mt-0.5 ">
                          Save as Primary Address
                        </span>
                      </div>

                      <button
                        type="submit"
                        className="bg-green-500 rounded-lg px-6 py-3  focus:outline-none text-white"
                      >
                        <span className="h-full flex items-center">
                          <svg
                            class="svg-icon"
                            viewBox="0 0 20 20"
                            width="28px"
                            height="28px"
                          >
                            <path
                              fill="#ffffff"
                              d="M13.388,9.624h-3.011v-3.01c0-0.208-0.168-0.377-0.376-0.377S9.624,6.405,9.624,6.613v3.01H6.613c-0.208,0-0.376,0.168-0.376,0.376s0.168,0.376,0.376,0.376h3.011v3.01c0,0.208,0.168,0.378,0.376,0.378s0.376-0.17,0.376-0.378v-3.01h3.011c0.207,0,0.377-0.168,0.377-0.376S13.595,9.624,13.388,9.624z M10,1.344c-4.781,0-8.656,3.875-8.656,8.656c0,4.781,3.875,8.656,8.656,8.656c4.781,0,8.656-3.875,8.656-8.656C18.656,5.219,14.781,1.344,10,1.344z M10,17.903c-4.365,0-7.904-3.538-7.904-7.903S5.635,2.096,10,2.096S17.903,5.635,17.903,10S14.365,17.903,10,17.903z"
                            ></path>
                          </svg>
                          <span className="px-2">Create {title}</span>
                        </span>
                      </button>
                    </div>
                  </div>
            
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default CreateHawkeye;
