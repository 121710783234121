import React, { useState, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "react-toggle/style.css";
import DashboardRoute from "./DashboardRoute";
import moment from "moment";
import { useHistory } from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";
import SearchData from "../../../../components/SearchDataMany";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import { useQuery } from "react-query";
import {toast} from 'react-toastify'

function OpenItems({ listUrl }) {
  const [filteredData, setFilteredData] = useState({
    retailerStore: "",
    session: "",
  });

  const [searchData, setSearchData] = useState({
    retailerStore: "",
    session: "",
  });

  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    localStorage.setItem("navigate", "inReview");
  }, []);

  const review = (data) => {
    history.push({
      pathname: "/admin/match/openEvents",
      state: { data: data },
    });
  };

  const [input, setInput] = useState({
    retailerStore: "",
    session: "",
    type: "",
  });

  

  const findUrl = (name) => {
    const now = moment().utc().format()
    const time = moment(now).subtract(120, "minutes");
    console.log("time: " + time)
    

    switch (name) {
      case "retailerStore":
        return `${listUrl}&page=${activePage - 1}&size=9&storeId.equals=${
          searchData.retailerStore
        }&status.equals=IN_REVIEW`;

      case "session":
        return `${listUrl}&page=${activePage - 1}&size=9&sessionRef.equals=${
          searchData.session
        }&status.equals=IN_REVIEW`;

      default:
        return null;
        // return `${process.env.REACT_APP_STORE_URL}/store-carts/open-cart?page=${
        //   activePage - 1
        // }&size=50&eventTime.greaterThanOrEqual=${
        //   entryExitTime?.entryTime
        // }syncStatus.in=INITIALIZED,CONFLICT,ERRORED,CART_REVIEWED,PROCESSED&sort=eventTime,asc`;
    }
  };

  const searchDataFn = (name, data) => {
    setSearchData({ ...searchData, [name]: data?.id });
    setInput({ ...input, [name]: data?.name });
    setFilteredData({
      retailerStore: "",
      session: "",
    });
  };

  const onChangeInput = (e, name) => {
    const value = e.target.value;

    setInput({ ...input, [name]: value, type: name });

    if (name === "retailerStore") {
      setLoading(true);
      axios({
        method: "GET",
        url: `${
          process.env.REACT_APP_BASE_URL
        }/_search/retail-stores?name.contains=${value.toLowerCase()}*`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      }).then((res) => {
        setFilteredData({ ...filteredData, [name]: res?.data });
        setLoading(false);
      }).catch(({ message }) => {
        if (message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          toast.error(message)
        }
      })
    } else if (name === "session") {
      setLoading(false);
    }
  };

  const [activePage, setActivePage] = useState(1);

  const fetchData = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: findUrl(input?.type),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      });
      return res.data;
    } catch ({message}) {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message)
      }
    }
   
  };

  const { data } = useQuery(
    ["hawkeyeList", activePage, searchData?.retailerStore, searchData?.session],
    async () => fetchData(activePage),
    {
      enabled:
        !!activePage || !!searchData?.retailerStore || !!searchData?.session,
      cacheTime: Infinity,
      staleTime: 0,
    }
  );

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  const clear = () => {
    setInput({ retailerStore: "", session: "" });
    setFilteredData({ retailerStore: "", session: "" });
    setSearchData({ retailerStore: "", session: "" });
  };

  return (
    <div className=" w-full h_table">
      <div
        className={
          loading
            ? "bg-black bg-opacity-0 absolute inset-0 z-10 flex items-center justify-center"
            : null
        }
      >
        <FadeLoader className="absolute " loading={loading} size={150} />
      </div>
      <div className="flex h-10 w-full items-center mt-2 justify-between py-8 px-5">
        <div className="w-auto flex space-x-6 items-center">
          <div className="w-72 flex justify-end items-center">
            <SearchData
              name="retailerStore"
              label={"Retailer Store"}
              input={input}
              setInput={setInput}
              onChangeInput={onChangeInput}
              filteredData={filteredData}
              searchDataFn={searchDataFn}
              setSearchData={setSearchData}
              loading={loading}
            />
          </div>

          <div className="w-72 flex justify-end items-center">
            <SearchData
              name="session"
              label={"Session"}
              input={input}
              setInput={setInput}
              onChangeInput={onChangeInput}
              filteredData={filteredData}
              searchDataFn={searchDataFn}
              setSearchData={setSearchData}
              loading={loading}
            />
          </div>
        </div>

        <button
          onClick={clear}
          className="bg-green-600 py-1.5 px-5 mr-7 rounded text-white cursor-pointer focus:outline-none"
        >
          Clear filters
        </button>
      </div>

      <div className="ag-theme-alpine  w-full h-full text-left">
        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            resizable: true,
          }}
          suppressRowClickSelection={true}
          rowSelection={"multiple"}
          rowData={data}
        >
          <AgGridColumn
            headerName="Session Ref"
            field="sessionRef"
            minWidth={150}
            maxWidth={200}
            sortable={true}
            headerCheckboxSelection={true}
            headerCheckboxSelectionFilteredOnly={true}
            checkboxSelection={true}
            cellRendererFramework={(params) => (
              <DashboardRoute params={params} tab="inReview" />
            )}
          />

          <AgGridColumn
            headerName="Mobile"
            field="formattedPhone"
            minWidth={150}
            maxWidth={130}
            sortable={true}
          />

          <AgGridColumn
            headerName="Entry Time"
            field="checkoutDate"
            minWidth={180}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <>
                  <p>
                    {moment(params?.data?.entryTime).format(
                      "YYYY-MM-DD hh:mm:ss A"
                    )}
                  </p>
                </>
              );
            }}
          />

          <AgGridColumn
            headerName="Checkout Time"
            field="checkoutDate"
            minWidth={180}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <>
                  <p>
                    {moment(params?.data?.checkoutDate).format(
                      "YYYY-MM-DD hh:mm:ss A"
                    )}
                  </p>
                </>
              );
            }}
          />

          <AgGridColumn
            headerName="Store Name"
            field="storeName"
            minWidth={170}
            sortable={true}
          />

          <AgGridColumn
            headerName="Store Code"
            field="storeCode"
            minWidth={130}
            maxWidth={130}
            sortable={true}
          />

          {/* <AgGridColumn
            headerName="Store Address"
            minWidth={150}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <>
                  <p className="flex">
                    {params?.data?.cityOrVillage
                      ? `${params?.data?.cityOrVillage}, `
                      : null}
                    {params?.data?.zipCode
                      ? `${params?.data?.zipCode}, `
                      : null}
                    {params?.data?.country ? `${params?.data?.country} ` : null}
                  </p>
                </>
              );
            }}
          /> */}

          <AgGridColumn
            headerName="Action"
            minWidth={40}
            cellRendererFramework={(params) => {
              return (
                <div className="flex w-20 h-full items-center">
                  <div className="w-full border-2 text-xs bg-green-400 border-gray-200 rounded-lg h-8 flex justify-center items-center my-1 ">
                    <button
                      onClick={() => review(params?.data)}
                      className="focus:outline-none"
                    >
                      <span className="text-white">Review Cart</span>
                    </button>
                  </div>
                </div>
              );
            }}
          />
        </AgGridReact>
      </div>
      <div className="  absolute bottom-8 right-12  flex justify-center item-center ">
        <Pagination
          count={446}
          showFirstButton
          showLastButton
          page={activePage}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
}

export default OpenItems;
