import React, { useState, useEffect } from "react";
import { BiImages, BiArrowBack } from "react-icons/bi";
import { useHistory, useLocation } from "react-router-dom";
import { useGet } from "../../../../../../api/useGet";
import OpenItem from "./OpenItem";
import moment from "moment";
import axios from "axios";
import ImageModal from "./ImageModal";
import NoImage from "../../../../../../Images/noImage.jpg";
import Pagination from "@mui/material/Pagination";
import { useQuery } from "react-query";
import FadeLoader from "react-spinners/FadeLoader";
import { getTime } from "../../../../../../logic/useGetTime";
import { toast } from "react-toastify";
import VideoSelectableSwapper from "../../../../../../components/VideoSelectableSwapper";
import SessionAllVideos from "../../../../../../components/SessionAllVideos";
import CustomSearch from "../../../../../../components/searchbar";
import { Button } from "@mui/material";
import Iconify from "../../../../../../components/iconify/Iconify";
import {
  areAllValuesEmpty,
  emptyAllValues,
  removeEmptyStringKeys,
} from "../../../../../../utils/utils";
import CustomSkeleton from "../../../../../../components/customSkeleton/CustomSkeleton";

function ProcessedEvents() {
  const history = useHistory();
  const {
    state: { data: locationData, navigateFrom },
  } = useLocation();
  console.log(
    "🚀 ~ file: ProcessedEvents.js:22 ~ ProcessedEvents ~ navigateFrom:",
    navigateFrom
  );
  const [loading, setLoading] = useState(true);
  const [shopper, setShopper] = useState();
  const [selectedVideo, setSelectedVideo] = useState();

  const [allCount, setAllCount] = useState();
  const [input, setInput] = useState({
    scaleId: "",
    shelfId: "",
  });

  const { data: images, isLoading: imageLoading } = useGet(
    `${process.env.REACT_APP_STORE_URL}/user-activities?eventStatus.equals=${
      navigateFrom && navigateFrom === "rejectedState"
        ? "REJECTED"
        : "PROCESSED"
    }&sort=entryTime,asc&cartRef.equals=${
      locationData?.sessionRef
        ? locationData?.sessionRef
        : locationData?.cartRef
    }`,
    "images"
  );
  console.log(
    "🚀 ~ file: ProcessedEvents.js:38 ~ ProcessedEvents ~ images:",
    images
  );

  const [activePage, setActivePage] = useState(1);

  const [cartSelected, setCartSelected] = useState("ProcessedCart");

  const [cumulativeItems, setCumulativeItems] = useState([]);

  const [cumulativeWeight, setCumulativeWeight] = useState(0);

  const [notAssignedFilters, setNotAssignedFilters] = useState({
    "scaleInternalCode.contains": "",
  });

  const [assignedFilters, setAssignedFilters] = useState({
    "scaleInternalCode.contains": "",
  });

  useEffect(() => {
    const tempData = cumulativeItems?.map((item) => item);
  }, [cumulativeItems]);

  const findUrl = (name) => {
    switch (name) {
      case "ProcessedCart":
        return `${process.env.REACT_APP_STORE_URL}/store-carts?cartRef.equals=${
          locationData?.sessionRef
            ? locationData?.sessionRef
            : locationData?.cartRef
        }&storeCode.equals=${
          locationData?.storeCode
            ? locationData?.storeCode
            : locationData?.retailStoreCode
        }&page=${activePage - 1}&size=25&sort=eventTime,asc`;

      case "OpenCart":
        return `${
          process.env.REACT_APP_STORE_URL
        }/store-carts/open-events?cartRef.equals=${
          locationData?.sessionRef
            ? locationData?.sessionRef
            : locationData?.cartRef
        }&page=${activePage - 1}&size=25&storeCode.equals=${
          locationData?.storeCode
            ? locationData?.storeCode
            : locationData?.retailStoreCode
        }&sort=eventTime,asc`;

      default:
        return `${process.env.REACT_APP_STORE_URL}/store-carts?cartRef.equals=${
          locationData?.sessionRef
            ? locationData?.sessionRef
            : locationData?.cartRef
        }&storeCode.equals=${
          locationData?.storeCode
            ? locationData?.storeCode
            : locationData?.retailStoreCode
        }&page=${activePage - 1}&size=25&sort=eventTime,asc`;
    }
  };

  useEffect(() => {
    if (images?.length > 0) {
      setShopper({ ...images[0], index: 1 });
    }
  }, [images]);

  const fetchData = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: findUrl(cartSelected),
        params: {
          ...removeEmptyStringKeys(
            cartSelected == "ProcessedCart"
              ? assignedFilters
              : notAssignedFilters
          ),
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      });
      setAllCount(res?.headers?.["x-total-count"]);
      setLoading(false);
      return res.data;
    } catch ({ message }) {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message);
      }
    }
  };

  const { data: openData, isLoading } = useQuery(
    [
      "openItemList",
      activePage,
      cartSelected,
      assignedFilters,
      notAssignedFilters,
    ],
    async () => fetchData(),
    {
      enabled: true,
      cacheTime: 0,
      staleTime: 0,
    }
  );

  const handlePageChange = (event, value) => {
    // setLoading(true)
    setActivePage(value);
  };

  const [filteredData, setFilteredData] = useState(openData);

  // useEffect(() => {
  //   var urlCont = [];
  //   if (images?.length !== 0) {
  //     images?.map((img) => {
  //       urlCont.push(img?.userImagePaths[0]);
  //     });
  //   }
  //   setShopperUrl(urlCont);
  // }, [images]);

  let [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const onChangeInput = (value, name) => {
    if (name === "scaleId" && value !== "") {
      setInput({
        scaleId: value,
        shelfId: "",
      });

      setFilteredData(
        openData?.filter((item) => item?.scaleId === value.toString())
      );
    } else if (name === "shelfId" && value !== "") {
      setInput({
        scaleId: "",
        shelfId: value,
      });

      setFilteredData(
        openData?.filter((item) => item?.groupId === value.toString())
      );
    } else if (name === "shelfId" && name === "groupId") {
    } else {
      setInput({
        scaleId: "",
        shelfId: "",
      });
      setFilteredData(openData);
    }
  };

  const tabSelection = (state) => {
    if (cartSelected !== state) {
      setCumulativeItems([]);
      setCumulativeWeight(0);
      setCartSelected(state);
      setLoading(true);
    }
  };

  const selectedShopper = (shopper) => {
    setShopper("");
    setTimeout(() => {
      setShopper(shopper);
    }, 10);
  };

  const handleInput = (e) => {
    handlePageChange(null, 1);
    if (cartSelected == "ProcessedCart") {
      setAssignedFilters(() => ({
        ...input,
        [e.target.name || e.name]: e.target.value || e.value,
      }));
    } else {
      setNotAssignedFilters(() => ({
        ...input,
        [e.target.name || e.name]: e.target.value || e.value,
      }));
    }
  };

  const isShowClearIcon = !areAllValuesEmpty(
    cartSelected == "ProcessedCart" ? assignedFilters : notAssignedFilters
  );

  const clearAllFilters = () => {
    handlePageChange(null, 1);
    if (cartSelected == "ProcessedCart") {
      setAssignedFilters({ ...emptyAllValues(assignedFilters) });
    } else {
      setNotAssignedFilters({ ...emptyAllValues(notAssignedFilters) });
    }
  };

  // if (loading)
  //   return (
  //     <div className="bg-black bg-opacity-80 absolute inset-0 flex items-center justify-center">
  //       <div className="w-full h-20 absolute z-10 text-white flex flex-col items-center justify-center">
  //         <FadeLoader
  //           color="#FFFFFF"
  //           className=""
  //           loading={loading}
  //           size={150}
  //         />
  //       </div>
  //     </div>
  //   );

  if (imageLoading)
    return (
      <div className="bg-black bg-opacity-80 absolute inset-0 flex items-center justify-center">
        <div className="w-full h-20 absolute z-10 text-white flex flex-col items-center justify-center">
          <FadeLoader color="#FFFFFF" className="" loading={true} size={150} />
        </div>
      </div>
    );

  return (
    <div className="h-[calc(100vh)] w-full bg-gray-300">
      <div className="p-2 flex items-center">
        <div className="absolute mx-4 flex items-center">
          <BiArrowBack
            onClick={() => history.goBack()}
            className="h-6 w-6 cursor-pointer"
          />
        </div>

        <div className="w-full flex justify-center items-center flex-col">
          <h2 className="font-bold text-xl">Store Cart - Summary</h2>
          <h2 className="text-xl">
            {locationData?.sessionRef
              ? locationData?.sessionRef
              : locationData?.cartRef}
          </h2>
        </div>
      </div>

      <div className="h-[calc(100%-76px)] w-full bg-white rounded-xl flex p-2">
        <div className="w-[97%] flex flex-col">
          <div
            className={`w-full h-44 flex items-center py-2 mx-5 justify-between`}
          >
            <div className="w-[calc(100vw-(610px))]  flex justify-between items-center">
              <div className="w-[calc(100vw-(610px)-(200px))] flex space-x-2">
                {images?.length > 0 ? (
                  images?.map((obj, index) => (
                    <div
                      onClick={() =>
                        selectedShopper({ ...obj, index: index + 1 })
                      }
                      key={index}
                      className={`${
                        shopper?.id === obj?.id &&
                        "border-2 border-blue-500 rounded-xl"
                      }`}
                    >
                      <ImageModal obj={obj} index={index} shopper={shopper} />
                    </div>
                  ))
                ) : (
                  <img
                    src={NoImage}
                    alt="NoImage"
                    className="w-40 h-40 rounded-xl cursor-pointer"
                  />
                )}
              </div>

              <div>
                {shopper?.shopperVideoPaths?.length > 0 && (
                  <VideoSelectableSwapper
                    arr={shopper?.shopperVideoPaths}
                    selectedVideo={selectedVideo}
                    setSelectedVideo={setSelectedVideo}
                    shNo={shopper?.index}
                  />
                )}
              </div>
            </div>

            <SessionAllVideos
              shopperVideoPaths={shopper?.shopperVideoPaths}
              isOpen={isOpen}
              closeModal={closeModal}
            />

            <div className="w-[300px] bg-gray-200 rounded-xl text-left p-3">
              <h2 className="font-bold text-lg text-center mb-1">
                Cart Summary
              </h2>

              {/* <div className="flex items-center text-left ">
                <h2 className="w-32 font-bold">{`Cart Ref`}</h2>
                <h2 className="">{`: ${
                  images[0]?.cartRef ? images[0]?.cartRef : "-"
                }`}</h2>
              </div> */}

              <div className="flex items-center text-left">
                <h2 className="w-32 font-bold">Store Code</h2>
                <p className="">{`: ${
                  images[0]?.retailStoreCode
                    ? images[0]?.retailStoreCode
                    : images[0]?.storeCode
                    ? images[0]?.storeCode
                    : "-"
                }`}</p>
              </div>

              <div className="flex items-center text-left ">
                <h2 className="w-32 font-bold">{`Mobile No`}</h2>
                <h2 className="">{`: ${
                  images[0]?.formattedPhone ? images[0]?.formattedPhone : "-"
                }`}</h2>
              </div>

              <div className="flex items-center text-left ">
                <h2 className="w-32 font-bold">{`# of shoppers`}</h2>
                <h2 className="">{`: ${
                  images[0]?.noOfGroupShoppers
                    ? images[0]?.noOfGroupShoppers
                    : "-"
                }`}</h2>
              </div>
            </div>
          </div>

          <div className="w-full border-b-2 my-3 ml-5" />

          <div className="w-full flex justify-between items-center ml-5">
            <div className="flex flex-col justify-start">
              <div className="h-8 flex  justify-between items-center space-x-[5px]">
                <h2 className="font-bold text-lg"> Entry Time </h2>
                {images[0]?.entryTime ? (
                  <p className="text-lg font-bold">
                    {`: ${getTime(
                      images[0]?.entryTime,
                      images[0]?.storeTimeZone
                    )}`}
                  </p>
                ) : (
                  "-"
                )}
              </div>

              <div className="h-8 flex  justify-between items-center space-x-[5px]">
                <h2 className="font-bold text-lg"> Checkout Time </h2>
                <p className="font-bold text-lg">
                  {`: ${getTime(
                    images[0]?.exitTime,
                    images[0]?.storeTimeZone
                  )}`}
                </p>
              </div>
            </div>

            {cumulativeItems?.length > 0 && (
              <div>
                <p className="text-lg">
                  {`Cumulative Weight `}(
                  <b>{` ${cumulativeItems?.[0]?.scaleInternalCode} `}</b>)
                </p>
                <p className="text-base sm:text-lg md:text-xl font-bold">{`${cumulativeWeight.toFixed(
                  3
                )}`}</p>
              </div>
            )}

            <div className="flex justify-end space-x-[20px]">
              {/* <div className="w-auto h-8 flex items-center space-x-[10px]">
                <input
                  placeholder="Search by Scale ID"
                  onChange={(e) => onChangeInput(e.target.value, "scaleId")}
                  value={input?.scaleId}
                  className="w-[180px] border focus:outline-none rounded-lg h-8 px-4 border-gray-500"
                />

                <input
                  placeholder="Search by Shelf ID"
                  onChange={(e) => onChangeInput(e.target.value, "shelfId")}
                  value={input?.shelfId}
                  className="w-[180px] border focus:outline-none rounded-lg h-8 px-4 border-gray-500"
                />
              </div> */}

              <div className="w-[300px] flex flex-col-reverse justify-between">
                {/* <button onClick={openModal} className="text-blue-500 mt-1">
                  See all Session videos
                </button> */}
                <div>
                  <button
                    onClick={() => tabSelection("OpenCart")}
                    className={`${
                      cartSelected === "OpenCart"
                        ? "bg-blue-500"
                        : "bg-gray-200"
                    } ${
                      cartSelected === "OpenCart" ? "text-white" : "text-black"
                    } w-[150px] h-[30px] rounded-l-xl focus:outline-none`}
                  >
                    Not Assign
                  </button>
                  <button
                    onClick={() => tabSelection("ProcessedCart")}
                    className={`${
                      cartSelected === "ProcessedCart"
                        ? "bg-blue-500"
                        : "bg-gray-200"
                    } ${
                      cartSelected === "ProcessedCart"
                        ? "text-white"
                        : "text-black"
                    } w-[150px] h-[30px] rounded-r-xl`}
                  >
                    Assigned
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full border-b-2 my-3 ml-5" />

          <div className="w-full flex items-center justify-between ml-5">
            <div className="flex items-center justify-between">
              <CustomSearch
                label="Search by rack"
                name="scaleInternalCode.contains"
                inputPropsStyle={{ borderRadius: "0.75rem" }}
                value={
                  cartSelected == "ProcessedCart"
                    ? assignedFilters?.["scaleInternalCode.contains"]
                    : notAssignedFilters?.["scaleInternalCode.contains"]
                }
                onChange={(e) => handleInput(e)}
              />
              {isShowClearIcon && (
                <Button
                  color="error"
                  size="small"
                  sx={{ ml: 2, borderRadius: "0.75rem" }}
                  onClick={() => clearAllFilters()}
                  startIcon={<Iconify icon="eva:trash-2-outline" />}
                >
                  Clear
                </Button>
              )}
            </div>
            <Button
              onClick={openModal}
              variant="outlined"
              sx={{
                borderRadius: "0.75rem",
              }}
            >
              See all Session videos
            </Button>
          </div>

          {/* <div className="w-full border-b-2 my-3 ml-5" /> */}

          <div
            className={`h-full mt-3 w-full ml-5 space-y-3 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100`}
          >
            {isLoading ? (
              <CustomSkeleton count={3} />
            ) : openData?.length > 0 ? (
              <>
                {openData?.map((item, index) => (
                  <OpenItem
                    key={index}
                    item={item}
                    index={index}
                    tab="approved"
                    userActivity={images}
                    cumulativeItems={cumulativeItems}
                    setCumulativeItems={setCumulativeItems}
                    cumulativeWeight={cumulativeWeight}
                    setCumulativeWeight={setCumulativeWeight}
                  />
                ))}
              </>
            ) : (
              <div className="w-full h-full flex justify-center items-center ">
                No Rows To Show
              </div>
            )}
          </div>

          <div className=" w-full ml-5 p-2 flex justify-between item-center my-1 items-center border border-gray-400 rounded-xl">
            <Pagination
              count={
                allCount % 25 === 0
                  ? parseInt(allCount / 25)
                  : parseInt(allCount / 25) + 1
              }
              showFirstButton
              showLastButton
              page={activePage}
              onChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProcessedEvents;
