import { LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const onKeyDown = (e) => {
  e.preventDefault();
};

const DesktopDateTimePicker = ({
  name,
  label,
  value,
  format,
  onChange,
  timeZone,
  slotProps,
  ampm = true,
}) => {
  const inputValue = dayjs(value).tz(timeZone);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer
        components={[
          "DatePicker",
          "DateTimePicker",
          "TimePicker",
          "DateRangePicker",
        ]}
      >
        <DemoItem label="">
          <DateTimePicker
            onChange={onChange}
            renderInput={(params) => (
              <TextField sx={{ backgroundColor: "white" }} {...params} />
            )}
            timezone={timeZone}
            label={label && label}
            value={inputValue}
            inputFormat="YYYY-MM-DD HH:mm:ss"
            name={name}
            views={["year", "month", "day", "hours", "minutes", "seconds"]}
            slotProps={slotProps && slotProps}
            ampm={ampm}
          />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default DesktopDateTimePicker;
