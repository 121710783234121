import React from "react";

function DashboardRoute({ data }) {
  // const clicked = () => {
  //       localStorage.setItem("dashboard-data", JSON.stringify(params.data))
  // }
  return (
    <>
      <button
        //to="/admin/merchant/dashboard"
        //target="_blank"
        //onClick={clicked}
        className="font-medium cursor-default focus:outline-none"
      >
        {data?.firstName} {data?.lastName}
      </button>
    </>
  );
}


export default DashboardRoute;
