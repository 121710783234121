import React, { useState, useEffect } from "react";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import VideoModal from "./VideoModal";
import { IoPlayOutline } from "react-icons/io5";

const Swipper = ({ shNo, arr, selectedVideo, setSelectedVideo }) => {
  const [index, setIndex] = useState(0);

  const nextArr = () => {
    if (index > 0 && index < arr.length - 1) {
      setIndex(index + 1);
    } else if (index === 0 && arr.length !== 1) {
      setIndex(index + 1);
    }
  };
  const prevArr = () => {
    if (index > 0 && index < arr.length) {
      setIndex(index - 1);
    } else if (index === arr.length) {
      setIndex(arr.length - 1);
    }
  };

  useEffect(() => {
    if (arr?.length > 0) {
      setSelectedVideo(arr[index]);
    }
  }, [index]);

  const [video, setVideo] = useState(false);
  const onOpenVideo = () => setVideo(true);
  const onCloseVideo = () => setVideo(false);

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex justify-center items-center space-x-0">
        <HiChevronLeft
          onClick={prevArr}
          size={28}
          color={index === 0 && "gray"}
        >
          next
        </HiChevronLeft>
        <div className="w-[150px] rounded-lg  flex justify-center items-center">
          {selectedVideo && (
            <>
              <div
                key={index}
                className="w-full h-full flex flex-col justify-center items-center cursor-pointer relative"
                onClick={onOpenVideo}
              >
                <img
                  src={selectedVideo.thumbnail}
                  className="w-36 h-36 rounded"
                />

                <IoPlayOutline size={28} color="white" className="absolute" />
                <p className="mt-1">{`Shopper ${shNo}`}</p>
              </div>

              <VideoModal
                open={video}
                onCloseModal={onCloseVideo}
                videoUrl={selectedVideo.videoUrl}
              />
            </>
          )}
        </div>
        <HiChevronRight
          onClick={nextArr}
          size={28}
          color={index === arr?.length - 1 && "gray"}
        >
          prev
        </HiChevronRight>
      </div>
    </div>
  );
};

export default Swipper;
