import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { FaStop, FaEdit, FaCopy, FaShareAlt, FaFileCsv } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { GrPowerReset } from "react-icons/gr";
import Toggle from "react-toggle";
import Confirm from "./Confirm";

function MangementView() {
  const [status, setStatus] = useState(false);

  const statusChecked = (e) => {
    setStatus(e.target.checked);
  };
  const location = useLocation();
  const history = useHistory();

  const data = location?.state?.data;
  //console.log(data);

  return (
    <>
      <div className="w-full h_view flex text-left select-none">
        <div className="w-full h-full flex flex-col  items-center bg-gray-200">
          <div className="w-full h-12 flex bg-gray-100  items-center">
            <BiArrowBack
              onClick={() => history.goBack()}
              className="h-6 w-6 cursor-pointer ml-5"
            />
            {/* <h2 className="font-bold px-6 text-xl">{data?.name}</h2> */}
          </div>

          <div className="w-full h_rem flex p-2">
            <div className="w-7/10 h-full bg-white rounded-lg p-2">
              <div className="w-full border-b-2">
                <h2 className="text-black text-bold text-lg select-none">
                  Information
                </h2>
              </div>

              <div className="flex h-12 mt-1 flex-wrap space-y-1 items-center select-none">
                <Confirm />
              </div>

              <div className="h-auto mt-1 select-none  hover:scrollbar-thumb-gray-500 scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100">
                <div className="border-t-2 border-gray-200 py-1 px-4">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="">Name</div>
                    <div className="">{data?.name}</div>
                  </div>
                </div>

                {data?.deviceIdentifier ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">Device Identifier</div>
                      <div className="">{data?.deviceIdentifier}</div>
                    </div>
                  </div>
                ) : null}

                {data?.status ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">Status</div>
                      <div className="">{data?.status}</div>
                    </div>
                  </div>
                ) : null}

                {data?.mode ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">Mode</div>
                      <div className="">{data?.mode}</div>
                    </div>
                  </div>
                ) : null}

                {data?.macAddress ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">Mac Address</div>
                      <div className="">{data?.macAddress}</div>
                    </div>
                  </div>
                ) : null}

                {data?.qrCode ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">Qr Code</div>
                      <div className="">{data?.qrCode}</div>
                    </div>
                  </div>
                ) : null}

                <h2 className="font-bold mt-2 ml-4 text-base">RetailStore </h2>

                {data?.retailStore?.name ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">Retailer Store's Name</div>
                      <div className="">{data?.retailStore?.name}</div>
                    </div>
                  </div>
                ) : null}

                {data?.retailStore?.email ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className=""> Retailer Store's Email </div>
                      <div className="">{data?.retailStore?.email}</div>
                    </div>
                  </div>
                ) : null}

                {data?.retailStore?.description ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className=""> Retailer Store's Description </div>
                      <div className="">{data?.retailStore?.description}</div>
                    </div>
                  </div>
                ) : null}

                {data?.retailStore?.website ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className=""> Retailer Store's Website </div>
                      <div className="">{data?.retailStore?.website}</div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="w-3/10 h-full ml-2 bg-white rounded-lg p-2">
              <div className="w-full border-b-2">
                <h2 className="text-black text-bold text-lg">Log</h2>
              </div>

              <div className="flex"></div>

              <div></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MangementView;
