import React from "react";

function Address({ params }) {
  //console.log("pa1: ", params?.data?.add);
  return (
    <div>
      <h2>
        {`${
          params?.data?.userInfo?.nickname
            ? `${params.data?.userInfo?.nickname},`
            : ""
        } ${
          params?.data?.userInfo?.gender
            ? `${params.data?.userInfo?.gender},`
            : ""
        } ${
          params?.data?.userInfo?.qualification
            ? `${params.data?.userInfo?.qualification},`
            : ""
        } ${
          params?.data?.userInfo?.lastLogin
            ? `${params.data?.userInfo?.lastLogin},`
            : ""
        } ${
          params?.data?.userInfo?.loginFailureCount
            ? `${params.data?.userInfo?.loginFailureCount}`
            : ""
        } ${
          params?.data?.userInfo?.loginCount
            ? `${params.data?.userInfo?.loginCount}`
            : ""
        }
        ${
          params?.data?.userInfo?.phone
            ? `${params.data?.userInfo?.phone}`
            : ""
        }`}
      </h2>
    </div>
  );
}

export default Address;
