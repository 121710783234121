import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Pagination from "@mui/material/Pagination";
import Action from "./Action";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import DashboardRoute from "./DashboardRoute";

function All({ url, viewUrl, editUrl, listUrl, getName }) {
  let [loading, setLoading] = useState(false);
  const [allCount, setAllCount] = useState();
  const history = useHistory();

  const { t } = useTranslation();

  const [activePage, setActivePage] = useState(1);

  const fetchData = async (page) => {

    try {
      const res = await axios({
        method: "GET",
        url: `${listUrl}&page=${page - 1}&size=10&sort=createdDate,desc`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      });

      // setAllCount(res?.headers?.["x-total-count"] === '10' ? 60 : res?.headers?.["x-total-count"]);
      setAllCount(150);
      return res.data;
    } catch ({ message }) {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message)
      }
    }
  };

  const { data, isLoading, isIdle, error } = useQuery(
    [
      "dynamicPriceList",
      activePage
    ],
    async () => fetchData(activePage),
    {
      enabled:
        !!activePage,
      cacheTime: Infinity,
      staleTime: 0,
    }
  );

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  return (
    
    <div className="w-full h_das_table select-text">
        <div className="ag-theme-alpine w-full h-full text-left select-text">
            <AgGridReact
                defaultColDef={{
                    flex: 1,
                    minWidth: 100,
                    resizable: true,
                }}
                suppressRowClickSelection={true}
                pagination={false}
                rowData={data}
                onRowClicked={(params) =>
                    history.push({
                    pathname: viewUrl,
                    state: { data: params.data },
                    })
            }
            >
                <AgGridColumn
                  headerName={t("productName")}
                  field="productName"
                  // minWidth={400}
                  sortable={true}
                  cellRendererFramework={(params) => (
                  <DashboardRoute params={params} />
                  )}
                />
                <AgGridColumn
                  headerName={t("barcode")}
                  field="barCode"
                  // minWidth={400}
                  sortable={true}
                  cellRendererFramework={(params) => (
                    <DashboardRoute params={params} />
                  )}
                />
                <AgGridColumn
                  headerName="Start Date"
                  field="startDate"
                  // minWidth={400}
                  sortable={true}
                  cellRendererFramework={(params) => (
                    <DashboardRoute params={params} />
                  )}
                />
                <AgGridColumn
                  headerName="End Date"
                  field="endDate"
                  // minWidth={400}
                  sortable={true}
                  cellRendererFramework={(params) => (
                    <DashboardRoute params={params} />
                  )}
                />
                <AgGridColumn
                  headerName="Campaign Type"
                  field="campaignType"
                  // minWidth={400}
                  sortable={true}
                  cellRendererFramework={(params) => (
                    <DashboardRoute params={params} />
                  )}
                />
                <AgGridColumn
                  headerName="Percent"
                  field="discountPercent"
                  // minWidth={400}
                  sortable={true}
                  cellRendererFramework={(params) => (
                    <DashboardRoute params={params} />
                  )}
                />
                <AgGridColumn
                  headerName={t("Action")}
                  field="action"
                  minWidth={100}
                  maxWidth={200}
                  cellRendererFramework={(params) => (
                    <Action
                      params={params}
                      url={url}
                      viewUrl={viewUrl}
                      editUrl={editUrl}
                    />
                  )}
                />
            </AgGridReact>
        </div>
        <div className="  absolute bottom-8 right-12  flex justify-center item-center ">
          <Pagination
            count={
              allCount % 10 === 0
                ? parseInt(allCount / 10)
                : parseInt(allCount / 10) + 1
            }
            showFirstButton
            showLastButton
            page={activePage}
            onChange={handlePageChange}
          />
        </div>
    </div>
  );
}

export default All;