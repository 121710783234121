import React from "react";
import TableStr from "./TableStr";
import Enabled from "./options/Enabled";
import Disabled from "./options/Disabled";
import All from "./options/All";

function Location() {
  return (
    <div className="h-full w-full ">
      <TableStr
        title={"Retailer Store"}
        viewUrl={"/support/retailer-store/view"}
        listUrl={`${process.env.REACT_APP_BASE_URL}/retail-stores?page=0&sort=createdDate`}
        getName={"retailList"}
        Enabled={Enabled}
        Disabled={Disabled}
        All={All}
        showButton={true}
        orgIdUrl={`${process.env.REACT_APP_BASE_URL}/orgs?page=0&sort=createdDate`}
        getOrgName={"OrgIdList"}
        retailerIdUrl={`${process.env.REACT_APP_BASE_URL}/retail-stores?page=0&sort=createdDate`}
        getRetailerName={"retailerIdList"}
      />
    </div>
  );
}

export default Location;
