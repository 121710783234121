import React from "react";
import { GrView } from "react-icons/gr";
import { FiEdit } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

function Action({ params, url, viewUrl, editUrl }) {
  const history = useHistory();

  // const Edit = async () => {
  //   axios({
  //     method: "GET",
  //     url: `${process.env.REACT_APP_BASE_URL}/org-configurations/${params?.data?.id}`,
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${localStorage.getItem("user-info")}`,
  //     },
  //   }).then((res) => {
  //     history.push({
  //       pathname: editUrl,
  //       state: { data: res?.data, id: params?.data?.id, getName: "merchantConfig" },
  //     });
  //   }).catch(({ message }) => {
  //     if (message === "Request failed with status code 401") {
  //       history.push("/login");
  //     } else {
  //       toast.error(message)
  //     }
  //   })
  // };

  return (
    <>
      <div className="flex w-16 h-10 items-center">
        <div className="border-2 border-gray-200 rounded-r-lg p-1">
          <FiEdit
            className="w-5 h-5 cursor-pointer"
            onClick={() =>
              history.push({
                pathname: editUrl,
                state: { data: params.data },
              })
            }
          />
        </div>
      </div>
    </>
  );
}

export default Action;
