import React, { useState, useMemo } from "react";
import { IoIosClose } from "react-icons/io";
import VideoController from "../custom-control/VideoController";

function VideoModal({ open, onCloseModal, videoUrl }) {
    const [title, setTitle] = useState("Brand");

   
    return (
        <>
            {open && (
                <div className="w-[100vw] h-[100vh]  bg-black bg-opacity-80 absolute inset-0 z-10 flex items-center justify-center">
                    <div className="w-2/5 h-[75%]  text-left select-none z-10 absolute top-1/2 left-1/2   transform -translate-x-1/2 -translate-y-1/2 ">
                        <div className="w-full h-full bg-gray-100 border-2  rounded-lg shadow-lg ">

                            <div className=" absolute w-full h-8 flex text-gray-500 px-1  justify-end">

                                <IoIosClose
                                    onClick={onCloseModal}
                                    className="h-10 w-10 cursor-pointer"
                                />

                            </div>


                            <div className="w-[80%] mx-auto h-[80%]">
                                <VideoController url={videoUrl} />
                            </div>


                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default VideoModal;
