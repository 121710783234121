import React, { useState } from "react";
import Toggle from "react-toggle";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import { useHistory } from "react-router-dom";

function Confirm({ data }) {


  const history = useHistory();

  const [status, setStatus] = useState(data?.status === "true" ? true : false);

  // console.log("status: ", status)

  const statusChecked = (e) => {
    const value = e.target.checked;

    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            var body = {
              id: data?.id,
              name: data?.name,
              status: value,
            };

            const res = await axios({
              method: "PUT",
              url: `${process.env.REACT_APP_BASE_URL}/api/machines/${data?.id}`,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("user-info")}`,
              },
              data: body,
            });

            if (res.data.status) {
              setStatus(res.data?.status);
              // window.location.reload();
              // history.push("/admin/machine")
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  //console.log("use: ", status);

  return (
    <div className="font-bold py-2 px-4 rounded inline-flex items-center">
      <span className="mx-2">Status</span>
      <Toggle
        onChange={statusChecked}
        checked={status}
        //icons={{ checked: null, unchecked: null }}
        className="react-switch"
      />
    </div>
  );
}

export default Confirm;
