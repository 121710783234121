import React, { useState, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "react-toggle/style.css";
import DashboardRoute from "./DashboardRoute";
import moment from "moment";
import FadeLoader from "react-spinners/FadeLoader";
import SearchData from "../../../../../../components/SearchDataMany";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import { useQuery } from "react-query";
import Action from "./Action";
import { getTime } from "../../../../../../logic/useGetTime";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { MerchantsDetails } from "../../../../../../utils/action_types";
import CustomDropdown from "../../../../../../components/dropdown";
import { useGet } from "../../../../../../api/useGet";
import CommonFilterSection from "./CommonFilterSection";
import {
  areAllValuesEmpty,
  emptyAllValues,
  removeEmptyStringKeys,
} from "../../../../../../utils/utils";

function Inflight({ listUrl }) {
  const history = useHistory();

  const [inputValue, setInputValue] = useState({
    "storeId.equals": "",
    "sessionRef.contains": "",
  });

  const [selectedValue, setSelectedValue] = useState(null);

  const [retailerStoreOptions, setRetailerStoreOptions] = useState([]);
  const [reload, setReload] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    localStorage.setItem("navigate", "rejected");
  }, []);

  const [activePage, setActivePage] = useState(1);

  const fetchData = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: `${listUrl}`,
        params: {
          ...removeEmptyStringKeys(inputValue),
          page: activePage - 1,
          size: 10,
          "status.equals": "PENDING",
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      });
      return res.data;
    } catch ({ message }) {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message);
      }
    }
  };

  const { data } = useQuery(
    ["hawkeyeInflightList", reload, activePage, inputValue],
    async () => fetchData(),
    {
      enabled: true,
      cacheTime: Infinity,
      staleTime: 1000,
    }
  );

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  const onChangeInput = (value) => {
    setLoading(true);
    axios({
      method: "GET",
      url: `${
        process.env.REACT_APP_BASE_URL
      }/_search/retail-stores?name.contains=${value.toLowerCase()}*`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
    })
      .then((res) => {
        console.log(
          "🚀 ~ file: InReview.js:118 ~ .then ~ res:",
          res?.data.map((item) => ({
            ...item,
            label: item?.name,
          }))
        );
        const tempData = res?.data.map((item) => ({
          ...item,
          label: item?.name,
        }));
        setRetailerStoreOptions(tempData);
        setLoading(false);
      })
      .catch(({ message }) => {
        if (message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          toast.error(message);
        }
      });
  };

  const handleInputChange = (event, newInputValue) => {
    // Call your API with the new input value
    // Example: fetchData(newInputValue);
    setSelectedValue(newInputValue);
    onChangeInput(newInputValue);
  };

  const handleSelectChange = (event, newValue) => {
    // Call another API with the selected value
    // Example: fetchAdditionalData(newValue);
    setActivePage(1);
    setInputValue({
      ...inputValue,
      "storeId.equals": newValue?.id,
    });
  };

  const isShowClearIcon = !areAllValuesEmpty(inputValue);

  const clearAllFilters = () => {
    setActivePage(1);
    setInputValue({ ...emptyAllValues(inputValue) });
    setSelectedValue(null);
  };

  const handleOnchangeSearch = (e) => {
    setActivePage(1);
    setInputValue(() => ({
      ...inputValue,
      [e.target.name || e.name]: e.target.value || e.value,
    }));
  };

  return (
    <div className=" w-full  h-[calc(100vh-152px)]">
      <div
        className={
          loading
            ? "bg-black bg-opacity-0 absolute inset-0 z-10 flex items-center justify-center"
            : null
        }
      >
        <FadeLoader className="absolute " loading={loading} size={150} />
      </div>
      {/* <div className="flex h-10 w-full items-center mt-2 justify-between py-8 px-5">
        <div className="w-3/4 flex justify-between h-full items-center">
          <div className="w-auto flex space-x-6 items-center">
            <div className="w-72 flex justify-end items-center">
              <SearchData
                name="retailerStore"
                label={"Retailer Store"}
                input={input}
                setInput={setInput}
                onChangeInput={onChangeInput}
                filteredData={filteredData}
                searchDataFn={searchDataFn}
                setSearchData={setSearchData}
                loading={loading}
                margin="ml-[250px]"
              />
            </div>

            <div className="w-72 flex justify-end items-center">
              <SearchData
                name="session"
                label={"Session"}
                input={input}
                setInput={setInput}
                onChangeInput={onChangeInput}
                filteredData={filteredData}
                searchDataFn={searchDataFn}
                setSearchData={setSearchData}
                loading={loading}
                margin="ml-[250px]"
              />
            </div>

            <div className="flex justify-end items-center">
              <CustomDropdown
                options={
                  orgs?.map((item) => ({
                    value: item?.id,
                    label: item?.name,
                  })) || []
                }
                name="rootOrgId.equals"
                label={"Filter by Merchats"}
                value={activeMerchant}
                onChange={(e, v) => {
                  setInput({ ...input, "rootOrgId.equals": v?.value });
                  setActiveMerchant(v?.label);
                }}
                sx={{
                  width: "250px",
                }}
              />
            </div>
          </div>
        </div>
        <button
          onClick={clear}
          className="bg-green-600 py-1.5 px-5 mr-7 rounded text-white cursor-pointer focus:outline-none"
        >
          Clear filters
        </button>
      </div> */}

      <CommonFilterSection
        selectedValue={selectedValue}
        handleSelectChange={handleSelectChange}
        handleInputChange={handleInputChange}
        retailerStoreOptions={retailerStoreOptions}
        inputValue={inputValue}
        handleOnchangeSearch={handleOnchangeSearch}
        clearAllFilters={clearAllFilters}
        isShowClearIcon={isShowClearIcon}
      />

      <div className="ag-theme-alpine  w-full h-full text-left">
        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            resizable: true,
          }}
          suppressRowClickSelection={true}
          rowSelection={"multiple"}
          rowData={data}
          rowHeight={50}
        >
          <AgGridColumn
            headerName="Session Ref"
            field="sessionRef"
            minWidth={155}
            maxWidth={250}
            sortable={true}
            headerCheckboxSelection={true}
            headerCheckboxSelectionFilteredOnly={true}
            checkboxSelection={true}
            cellRendererFramework={(params) => (
              <DashboardRoute params={params} tab="inflight" />
            )}
          />

          <AgGridColumn
            headerName="Merchant"
            field="storeCode"
            cellRendererFramework={(params) => {
              return (
                <div className="h-[50px] w-[50px]">
                  <img
                    src={
                      MerchantsDetails.hasOwnProperty(params?.value)
                        ? MerchantsDetails?.[params?.value]?.imgUrl
                        : "/images/noImage.jpg"
                    }
                    width="50px"
                    height="50px"
                    alt="/images/noImage.jpg"
                    // className="w-[100px] h-[100px]"
                    // className="mt-3"
                  />
                </div>
              );
            }}
          />
          {/* <AgGridColumn
            headerName="Mobile"
            field="formattedPhone"
             minWidth={130}
            maxWidth={130}
            sortable={true}
          /> */}

          <AgGridColumn
            headerName="Entry Time"
            field="checkoutDate"
            minWidth={170}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <>
                  <p>
                    {getTime(
                      params?.data?.entryTime,
                      params?.data?.storeTimeZone
                    )}
                  </p>
                </>
              );
            }}
          />

          <AgGridColumn
            headerName="Exit Time"
            field="checkoutDate"
            minWidth={170}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <>
                  <p>
                    {getTime(
                      params?.data?.checkoutDate,
                      params?.data?.storeTimeZone
                    )}
                  </p>
                </>
              );
            }}
          />

          {/* <AgGridColumn
            headerName="Retailer Name"
            field="retailerName"
            minWidth={170}
            sortable={true}
          />

          <AgGridColumn
            headerName="Retailer Code"
            field="retailerCode"
            minWidth={170}
            sortable={true}
          /> */}

          <AgGridColumn
            headerName="Store Name"
            field="storeName"
            minWidth={170}
            sortable={true}
          />

          <AgGridColumn
            headerName="store Code"
            field="storeCode"
            minWidth={100}
            sortable={true}
          />

          {/* <AgGridColumn
            headerName="Store Address"
            minWidth={150}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <>
                  <p className="flex">
                    {params?.data?.cityOrVillage
                      ? `${params?.data?.cityOrVillage}, `
                      : null}
                    {params?.data?.zipCode
                      ? `${params?.data?.zipCode}, `
                      : null}
                    {params?.data?.country ? `${params?.data?.country} ` : null}
                  </p>
                </>
              );
            }}
          /> */}

          <AgGridColumn
            headerName="Action"
            minWidth={40}
            cellRendererFramework={(params) => (
              <Action params={params} setReload={setReload} reload={reload} />
            )}
          />
        </AgGridReact>
      </div>
      <div className="  absolute bottom-8 right-12  flex justify-center item-center ">
        <Pagination
          count={1}
          showFirstButton
          showLastButton
          page={activePage}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
}

export default Inflight;
