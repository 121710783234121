import React, { useState, useEffect } from "react";
import Select from "react-select";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import ScrollImg from "./ScrollImg";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import VideoModal from "./VideoModal";
import moment from "moment";
import axios from "axios";
import { MdArrowUpward, MdArrowDownward } from "react-icons/md";
import { useHistory } from "react-router-dom";
import {toast} from 'react-toastify'


const confusion = [
  { value: "Vandalisation", label: "Vandalisation" },
  {
    value: "Product Consume within stores",
    label: "Product Consume within store",
  },
  {
    value: "Product kept in a wrong place",
    label: "Product kept in a wrong place",
  },
];

const images = [
  {
    image:
      "https://images.unsplash.com/photo-1546768292-fb12f6c92568?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80",
  },
  {
    image:
      "https://images.unsplash.com/photo-1501446529957-6226bd447c46?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1489&q=80",
  },
  {
    image:
      "https://images.unsplash.com/photo-1483729558449-99ef09a8c325?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80",
  },
  {
    image:
      "https://images.unsplash.com/photo-1475189778702-5ec9941484ae?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1351&q=80",
  },
  {
    image:
      "https://images.unsplash.com/photo-1503177119275-0aa32b3a9368?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80",
  },
];

function OpenItem({ item, index }) {
  console.log("qty: ", item);
  const [assign, setAssign] = useState(false);

  const [select, setSelect] = useState({
    product: { value: {}, label: "Pick" },
  });

  console.log("select: ", select);

  const history = useHistory();

  const chunk = (myArray, chunk_size) => {
    var index = 0;
    var arrayLength = myArray?.length;
    var tempArray = [];

    for (index = 0; index < arrayLength; index += chunk_size) {
      const myChunk = myArray.slice(index, index + chunk_size);

      tempArray.push(myChunk);
    }
    //console.log("[StoreGallery.js] tempArray ", tempArray)
    return tempArray;
  };

  const [updateData, setUpdateData] = useState(item);

  const onSelect = (value, name) => {
    setUpdateData({
      ...updateData,
      productId: value?.value?.productId,
      productInfos: [value?.value],
    });
    setSelect({ ...select, [name]: value });
  };

  const approval = (id, status) => {
    const sessionRef = JSON.parse(
      localStorage?.getItem("eventTime")
    )?.sessionRef;
  
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_STORE_URL}/user-activities?cartRef.equals=${sessionRef}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
    }).then((res) => {
      axios({
        method: "PUT",
        url: `${process.env.REACT_APP_STORE_URL}/store-carts/${id}/review-event`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
        data: {
          ...updateData,
          syncStatus: status,
          userInfos: [
            {
              id: res?.data[0]?.id,
              name: res?.data[0]?.name,
              login: res?.data[0]?.login,
              userRole: res?.data[0]?.userType,
              cartRef: res?.data[0]?.cartRef,
            },
          ],
        },
      }).catch(({ message }) => {
        if (message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          toast.error(message)
        }
      })
    }).catch(({ message }) => {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message)
      }
    })
  };

  const assignFn = (id) => {
    approval(id, "APPROVED");
    setAssign(!assign);
  };

  const unassignFn = (id) => {
    approval(id, "REJECTED");
    setAssign(!assign);
  };

  return (
    <div className="w-full h-28 flex px-3 justify-between mb-6 border border-gray-200 rounded items-center py-4">
      <div className="w-3 h-20 bg-green-500 my-2"></div>

      <div className="h-full flex flex-col items-center justify-center">
        {item?.productInfos.length === 1 ? (
          <div style={{ width: "100px" }}>
            <Select
              value={{
                label: (
                  <img
                    src={item?.productInfos[0]?.imageUrl}
                    height="25px"
                    width="25px"
                  />
                ),
                value: "",
              }}
              isDisabled={true}
            />
          </div>
        ) : (
          <div style={{ width: "100px" }}>
            <Select
              value={select?.product}
              onChange={(value) => onSelect(value, "product")}
              options={item?.productInfos?.map((prod, index) => {
                return {
                  label: (
                    <img src={prod?.imageUrl} height="30px" width="30px" />
                  ),
                  value: prod,
                };
              })}
            />
          </div>
        )}

        {item?.productInfos.length === 1 ? (
          <h2 className="mt-1.5 text-xs">{item?.productInfos[0]?.name}</h2>
        ) : (
          <h2 className="mt-2 text-xs">{select?.product?.value?.name}</h2>
        )}
      </div>

      <div className="w-36 flex flex-col my-4 items-start ml-2">
        <>
          <div className="flex items-center w-36">
            {item?.productInfos.length === 1 ? (
              <>
                <h2 className="text-xs">{`Quantity : ${item?.productInfos[0]?.estimatedQuantity}`}</h2>

                {item?.eventType === "PICK" ? (
                  <MdArrowUpward className="w-4 h-4 ml-2 text-green-500 " />
                ) : (
                  <MdArrowDownward className="w-4 h-4 ml-2 text-red-500 " />
                )}
              </>
            ) : (
              <>
                {select?.product?.label !== "Pick" && (
                  <>
                    <h2 className="text-xs">{`Quantity : ${select?.product?.value?.estimatedQuantity}`}</h2>

                    {item?.eventType === "PICK" ? (
                      <MdArrowUpward className="w-4 h-4 ml-2 text-green-500 " />
                    ) : (
                      <MdArrowDownward className="w-4 h-4 ml-2 text-red-500 " />
                    )}
                  </>
                )}
              </>
            )}
          </div>
          {item?.productInfos.length === 1 ? (
            <h2 className="text-xs">{`Weight : ${item?.productInfos[0]?.estimatedChangeInWeight?.toFixed(
              3
            )} ${item?.productInfos[0]?.unitOfMeasure}`}</h2>
          ) : (
            <>
              {select?.product?.label !== "Pick" && (
                <>
                  <h2 className="text-xs">{`Weight : ${select?.product?.value?.estimatedChangeInWeight?.toFixed(
                    3
                  )} ${select?.product?.value?.unitOfMeasure}`}</h2>
                </>
              )}
            </>
          )}
          {item?.productInfos.length === 1 ? (
            <h2 className="text-xs">{`Unit Weight : ${item?.productInfos[0]?.unitWeight}`}</h2>
          ) : (
            <>
              {select?.product?.label !== "Pick" && (
                <>
                  <h2 className="text-xs">{`Unit Weight : ${select?.product?.value?.unitWeight}`}</h2>
                </>
              )}
            </>
          )}
        </>

        <h2 className="text-xs">{`Shelf ID : ${
          item?.groupId ? item?.groupId : "Null"
        }`}</h2>
        <h2 className="text-xs">{`Scale ID : ${
          item?.scaleId ? item?.scaleId : "Null"
        }`}</h2>
        <h2 className="text-xs">{`Event Time : ${
          item?.eventTime ? moment(item?.eventTime).format("h:mm:ss A") : "Null"
        }`}</h2>
      </div>

      <div className="border-l border-r w-108  border-gray-200  h-20 flex items-center px-1 flex-col">
        <Carousel
          useKeyboardArrows={false}
          showThumbs={false}
          showIndicators={false}
          showStatus={false}
          emulateTouch={true}
          //centerMode={true}
          // onClickItem={openVideo}
          autoPlay={false}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <div className="absolute top-8 -left-1 z-10">
                <GrFormPrevious onClick={onClickHandler} />
              </div>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <div className="absolute top-8 -right-1 z-10">
                <GrFormNext onClick={onClickHandler} />
              </div>
            )
          }
        >
          {chunk(images, 4)?.map((rowImg, index) => {
            return (
              <div className="flex mt-2">
                {rowImg?.map((img, index) => {
                  return <ScrollImg img={img} index={index} />;
                })}
              </div>
            );
          })}
        </Carousel>

        {/* <div>
    <Select
     options={confusion} />
  </div> */}
      </div>

      <div className="w-20 h-full flex flex-col justify-center items-center ">
        {assign ? (
          <button
            onClick={() => unassignFn(item?.id)}
            className=" w-20 bg-red-500 text-white rounded px-2 focus:outline-none"
          >
            Unassign
          </button>
        ) : (
          <button
            onClick={() => assignFn(item?.id)}
            className=" w-20 bg-green-500 text-white rounded px-2 focus:outline-none"
          >
            Assign
          </button>
        )}
      </div>
    </div>
  );
}

export default OpenItem;
