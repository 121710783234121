import React from "react";
import { GrView } from "react-icons/gr";
import { BsCloudDownload } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import { Languages1 } from "../../../Languages";
import Tippy from "@tippyjs/react";

function Action({ params }) {
  const history = useHistory();
  const cookies = new Cookies();

  const fetchDownload = (url, name) => {
    fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      }),
    })
      .then((res) => res.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${name}.pdf`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  const detectedLan = (code) => {
    return Languages1?.filter((item) => {
      return item?.code === code;
    });
  };

  const download = async () => {
    fetchDownload(
      `${process.env.REACT_APP_SHOPPER_URL}/orders/${
        params?.data?.id
      }/download?langKey=${detectedLan(cookies.get("i18next"))[0]?.value}`,
      `${params?.data?.orderNumber}_${
        detectedLan(cookies.get("i18next"))[0]?.code
      }`
    );
  };

  return (
    <Tippy content="Download receipt">
      <div className="flex w-16 h-10 items-center justify-center">
        <button
          onClick={download}
          className="border-2 border-gray-200 rounded-lg p-1 "
        >
          <BsCloudDownload className="w-5 h-5 cursor-pointer " />
        </button>
      </div>
    </Tippy>
  );
}

export default Action;
