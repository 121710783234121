import React from "react";
import { useTranslation } from 'react-i18next'


const Welcome = () => {
  const { t } = useTranslation()

  return (
    <div className="h_screen w-full ">
       <div className="w-full h-full flex justify-center items-center ">
         <h2 className="font-bold text-4xl text-dashback">{`Welcome to Autonomo Support Dashboard `}</h2>
       </div>
    </div>
  );
};

export default Welcome;
