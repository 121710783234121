import React, { useState, useEffect } from "react";
import axios from "axios";
import SuperviserApproval from "./options/SuperviserApproval";
import { getDecryptedData } from "../../../../../utils/localStorageUtils";
import { authorities } from "../../../../../utils/action_types";

function TableStr({
  title,
  viewUrl,
  editUrl,
  listUrl,
  getName,
  Rejected,
  InReview,
  Approved,
  Inflight,
  OpenItems,
}) {
  const loginInfo = getDecryptedData("loginInfo");
  const hasCommonAuthority = loginInfo?.authorities?.some((authority) =>
    authorities.includes(authority)
  );

  const initialRoute = (value) => {
    const type = value;

    switch (type) {
      case "inReview":
        return "inReview";

      case "rejected":
        return "rejected";

      case "approved":
        return "approved";

      case "inflight":
        return "inflight";

      case "openItems":
        return "openItems";

      default:
        return "inReview";
    }
  };

  const [click, setClicked] = useState(
    initialRoute(localStorage.getItem("navigate"))
  );

  const options = (name) => {
    const type = name;

    switch (type) {
      case "inReview":
        setClicked(type);
        localStorage.setItem("navigate", type);
        break;

      case "rejected":
        setClicked(type);
        localStorage.setItem("navigate", type);
        break;

      case "approved":
        setClicked(type);
        localStorage.setItem("navigate", type);
        break;

      case "inflight":
        setClicked(type);
        localStorage.setItem("navigate", type);
        break;

      case "openItems":
        setClicked(type);
        localStorage.setItem("navigate", type);
        break;

      case "superviserApproval":
        setClicked(type);
        localStorage.setItem("navigate", type);
        break;

      default:
    }
  };

  return (
    <div className="w-full h-full shadow-lg select-none">
      <div
        className={`w-full h-12 flex justify-between p-6 items-center bg-gray-100`}
      >
        <div>
          <h2 className="font-bold text-2xl">{title}</h2>
        </div>
      </div>

      <div className="w-full flex h-10 p-2  -mt-2 ">
        <div className="h-10 w-full  flex justify-start ">
          <button
            onClick={(e) => options(e.target.name)}
            name="inReview"
            className={`mx-4 border-b-2 ${
              click === "inReview" ? "border-blue-500" : null
            } focus:outline-none`}
          >
            In Review
          </button>

          <button
            onClick={(e) => options(e.target.name)}
            name="approved"
            className={`mx-4 border-b-2 ${
              click === "approved" ? "border-blue-500" : null
            } focus:outline-none`}
          >
            Approved
          </button>

          <button
            onClick={(e) => options(e.target.name)}
            name="rejected"
            className={`mx-4 border-b-2 ${
              click === "rejected" ? "border-blue-500" : null
            } focus:outline-none`}
          >
            Rejected
          </button>

          <button
            onClick={(e) => options(e.target.name)}
            name="inflight"
            className={`mx-4 border-b-2 ${
              click === "inflight" ? "border-blue-500" : null
            } focus:outline-none`}
          >
            Inflight
          </button>

          {hasCommonAuthority && (
            <button
              onClick={(e) => options(e.target.name)}
              name="superviserApproval"
              className={`mx-4 border-b-2 ${
                click === "superviserApproval" ? "border-blue-500" : null
              } focus:outline-none`}
            >
              Superviser Approval
            </button>
          )}

          {/* <button
            onClick={(e) => options(e.target.name)}
            name="openItems"
            className={`mx-4 border-b-2 ${
              click === "openItems" ? "border-blue-500" : null
            } focus:outline-none`}
          >
            Open Items
          </button> */}
        </div>
      </div>

      {click === "approved" ? (
        <Approved listUrl={listUrl} viewUrl={viewUrl} editUrl={editUrl} />
      ) : click === "inReview" ? (
        <InReview listUrl={listUrl} viewUrl={viewUrl} editUrl={editUrl} />
      ) : click === "rejected" ? (
        <Rejected listUrl={listUrl} viewUrl={viewUrl} editUrl={editUrl} />
      ) : click === "inflight" ? (
        <Inflight listUrl={listUrl} viewUrl={viewUrl} editUrl={editUrl} />
      ) : click === "openItems" ? (
        <OpenItems listUrl={listUrl} viewUrl={viewUrl} editUrl={editUrl} />
      ) : click === "superviserApproval" ? (
        <SuperviserApproval
          listUrl={`${process.env.REACT_APP_SHOPPER_URL}/stores/carts?status.in=APPROVED_IN_REVIEW%2CREJECTED_IN_REVIEW&sort=createdDate%2Cdesc`}
          viewUrl={viewUrl}
          editUrl={editUrl}
        />
      ) : null}
    </div>
  );
}

export default TableStr;
