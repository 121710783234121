/* eslint-disable jsx-a11y/img-redundant-alt */
import {
  Container,
  Card,
  CardHeader,
  Button,
  CardActions,
  Divider,
  Box,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { toast } from "react-toastify";

import SearchableDropdown from "../../../../../../../components/dropdown/index";
import { useGet } from "../../../../../../../api/useGet";
import { getTime } from "../../../../../../../logic/useGetTime";

const useStyles = makeStyles({
  scrollableDiv: {
    "&::-webkit-scrollbar": {
      display: "none", // Hide the scrollbar for Webkit browsers (e.g., Chrome and Safari)
    },
    "-ms-overflow-style": "none", // Hide the scrollbar for IE and Edge
    "scrollbar-width": "none", // Hide the scrollbar for Firefox
    overflowY: "scroll", // Ensure that the container is scrollable if needed
  },
});

const styles = {
  cardHeader: {
    fontWeight: 500, // Set your desired font weight
    fontSize: "18px",
  },
};

const ReviewEntryEvents = () => {
  const params = useParams();

  const classes = useStyles();

  const [IRoptions, setIRoptions] = useState([]);

  const [defaultIRvalue, setDefaultIRValue] = useState({
    value: "",
    label: "",
    eventStatus: "",
  });

  const [assignedCameraItem, setAssignedCameraItem] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [fetch, setFetch] = useState(null);

  const { data: gateEventData } = useGet(
    `${process.env.REACT_APP_STORE_URL}/user-activities/${params?.gateEventId}`,
    ["reviewGateEvent", params?.gateEventId, fetch]
  );

  const { data: entryTaskInfo } = useGet(
    `${process.env.REACT_APP_STORE_URL}/entry-tasks/user-activity/${params?.gateEventId}`,
    ["entryTaskInfo", params?.gateEventId, fetch]
  );

  const { data: IRevent } = useGet(
    `${
      process.env.REACT_APP_STORE_URL
    }/ir-activities?sort=startTime%2Cdesc&startTime.greaterThanOrEqual=${
      gateEventData?.entryTime
    }&eventStatus.in=DETECTED%2CASSIGNED&startTime.lessThanOrEqual=${moment(
      gateEventData?.entryTime
    )
      .add(5, "minutes")
      .toISOString()}`,
    ["IRevent", gateEventData],
    gateEventData !== undefined
  );

  const { data: frontCameraEvent } = useGet(
    `${
      process.env.REACT_APP_STORE_URL
    }/camera-activities?sort=reportedTime%2Casc&reportedTime.greaterThanOrEqual=${
      gateEventData?.entryTime
    }&reportedTime.lessThanOrEqual=${moment(gateEventData?.entryTime)
      .add(5, "minutes")
      .toISOString()}&type.equals=USER_ENTRY_ACTIVITY&eventStatus.in=DETECTED%2CASSIGNED`,
    ["cameraEvent", gateEventData],
    gateEventData !== undefined
  );

  useEffect(() => {
    const tempData = IRevent?.map((item) => ({
      value: item?.id,
      label: `${getTime(item?.eventTime, item?.storeTimeZone)}`,
      eventStatus: item?.eventStatus,
    }));
    const tempData2 = tempData?.find(
      (item) => item?.eventStatus === "ASSIGNED"
    );
    console.log("🚀 ~ file: index.jsx:101 ~ useEffect ~ tempData2:", tempData2);
    setIRoptions(tempData);
    setDefaultIRValue(tempData2);
  }, [IRevent]);

  useEffect(() => {
    const tempData = frontCameraEvent?.filter(
      (item) => item?.eventStatus === "ASSIGNED"
    );
    setAssignedCameraItem(tempData);
  }, [frontCameraEvent]);

  const handleApproveAPI = () => {
    setIsLoading(true);
    const apiUrl = `https://portal-uat.autonomo.tech/store-mgmt/api/entry-tasks/${entryTaskInfo?.id}/approve-task`;

    const payload = {
      ...entryTaskInfo,
      baseActivities: {
        UserActivity: entryTaskInfo?.baseActivities?.UserActivity,
        IRActivity:
          defaultIRvalue?.value !== "" && defaultIRvalue?.value !== undefined
            ? defaultIRvalue?.value
            : null,
        CameraActivity:
          assignedCameraItem?.length > 0 &&
          assignedCameraItem?.[0]?.id !== undefined
            ? assignedCameraItem?.[0]?.id
            : null,
      },
    };

    delete payload?.associatedIRActivities;

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("user-info")}`,
    };

    axios
      .put(apiUrl, payload, { headers })
      .then((response) => {
        // Handle the response here, e.g., show a success message
        setIsLoading(false);
        setFetch(Date.now());
        toast.success("Data Updated successfully");
      })
      .catch((error) => {
        // Handle errors, e.g., show an error message
        setIsLoading(false);
        setFetch(Date.now());
        toast.error("Something went wrong");
      });
  };

  return (
    <>
      <Container maxWidth="2xl">
        <Card sx={{ minHeight: "97vh" }}>
          <CardHeader
            title={`Cart Reference: ${gateEventData?.cartRef}`}
            sx={{ alignItems: "center", fontWeight: 700 }}
            titleTypographyProps={{
              style: styles.cardHeader, // Apply the styles here
            }}
          />

          <Divider />

          <Grid container p={2}>
            {/* First part with 75% width */}
            <Grid
              item
              xs={9}
              sx={{ height: "90vh" }}
              className={classes.scrollableDiv}
            >
              {/* Your content for the 70% width part */}
              {/* <Accordion
                sx={{
                  borderRadius: "10px",
                  border: "1px solid black",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    height: 100,
                    // backgroundColor: "#D9D9D9",
                  }}
                >
                  <Typography sx={{ fontSize: "1.25em", fontWeight: 700 }}>
                    Gate Event
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Card variant="outlined">
                    <Grid container spacing={2} alignItems="center">
                      
                      <Grid item xs={4}>
                        <Grid container spacing={2}>
                          <Grid item xs={4} spacing={1}>
                            <img
                              src="image1.jpg"
                              alt="Image 1"
                              width="100"
                              height="100"
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <img
                              src="image2.jpg"
                              alt="Image 2"
                              width="100"
                              height="100"
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <img
                              src="image3.jpg"
                              alt="Image 3"
                              width="100"
                              height="100"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      
                      <Grid item xs={4}>
                        <Typography variant="body1">
                          Entry Timestamp: 2022-11-19 13:14:25
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Button variant="contained" color="primary">
                          Selected
                        </Button>
                      </Grid>
                    </Grid>
                  </Card>
                </AccordionDetails>
              </Accordion> */}
              <Accordion
                sx={{
                  borderRadius: "10px",
                  border: "1px solid black",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    height: 100,
                    // backgroundColor: "#D9D9D9",
                  }}
                >
                  <Typography sx={{ fontSize: "1.25em", fontWeight: 700 }}>
                    Gate Event
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Card
                    // variant="outlined"
                    sx={{
                      boxShadow:
                        "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px",
                    }}
                  >
                    <Grid
                      container
                      minHeight={150}
                      spacing={2}
                      alignItems="center"
                    >
                      {/* First Grid */}
                      <Grid item xs={4}>
                        <Grid container spacing={2}>
                          {gateEventData?.userImagePaths?.map((item) => (
                            <Grid item xs={4}>
                              <img
                                src={item}
                                alt=""
                                // width="100"
                                // height="100"
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>

                      {/* Second Grid */}
                      <Grid item xs={4}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={6}>
                            <Typography variant="body1" textAlign="end">
                              {`Gate Timestamp`}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body1" textAlign="start">
                              {`: ${getTime(
                                gateEventData?.entryTime,
                                gateEventData?.storeTimeZone
                              )}`}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body1" textAlign="end">
                              {`Cart Reference`}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body1" textAlign="start">
                              {`: ${gateEventData?.cartRef}`}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Third Grid */}
                      <Grid item xs={4}>
                        {/* <Button variant="contained" color="primary">
                          Selected
                        </Button> */}
                      </Grid>
                    </Grid>
                  </Card>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  borderRadius: "10px",
                  border: "1px solid black",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    height: 100,
                    // backgroundColor: "#D9D9D9",
                  }}
                >
                  <Typography sx={{ fontSize: "1.25em", fontWeight: 700 }}>
                    IR Event
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Card
                    variant="outlined"
                    sx={{
                      boxShadow:
                        "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px",
                    }}
                  >
                    <Grid
                      container
                      spacing={2}
                      minHeight={150}
                      alignItems="center"
                    >
                      {/* First Grid */}
                      <Grid item xs={4}></Grid>

                      {/* Second Grid */}
                      <Grid item xs={4}>
                        <SearchableDropdown
                          placeholder=""
                          label="IR Timestamp"
                          // defaultValue={}
                          value={defaultIRvalue?.label}
                          options={IRoptions || []}
                          onChange={(e, value) => {
                            setDefaultIRValue(value);
                            // handleRetailarStoreOnChange(e, value)
                          }}
                          // sx={{ width: 300 }}
                        />
                        {/* <SearchableDropdown
                          placeholder=""
                          label="IR2 Timestamp"
                          // defaultValue={}
                          value={defaultIRvalue?.label}
                          options={IRoptions || []}
                          onChange={(e, value) => {
                            setDefaultIRValue(value);
                            // handleRetailarStoreOnChange(e, value)
                          }}
                          sx={{ mt: 2 }}
                        /> */}
                      </Grid>

                      {/* Third Grid */}
                      <Grid item xs={4}>
                        {/* <Button variant="contained" color="primary">
                          Selected
                        </Button> */}
                      </Grid>
                    </Grid>
                  </Card>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  borderRadius: "10px",
                  border: "1px solid black",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    height: 100,
                    // backgroundColor: "#D9D9D9",
                  }}
                >
                  <Typography sx={{ fontSize: "1.25em", fontWeight: 700 }}>
                    Camera Front View
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {assignedCameraItem?.length > 0 && (
                    <>
                      <Card
                        variant="outlined"
                        sx={{
                          mb: 1,
                          boxShadow:
                            "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px",
                        }}
                      >
                        <Grid container spacing={2} alignItems="center">
                          {/* First Grid */}
                          <Grid item xs={4}>
                            <Grid container spacing={2}>
                              {assignedCameraItem?.[0]?.userImagePaths?.map(
                                (item, key) => (
                                  <Grid item xs={4} spacing={1} key={key}>
                                    <img
                                      src={item}
                                      alt=""
                                      width="100"
                                      height="100"
                                    />
                                  </Grid>
                                )
                              )}
                            </Grid>
                          </Grid>

                          {/* Second Grid */}
                          <Grid item xs={4}>
                            <Typography variant="body1">
                              Entry Timestamp{" "}
                              {`: ${getTime(
                                assignedCameraItem?.[0]?.eventTime,
                                assignedCameraItem?.[0]?.storeTimeZone
                              )}`}
                            </Typography>
                          </Grid>

                          {/* Third Grid */}
                          <Grid item xs={4}>
                            {/* <Button
                              variant={
                                assignedCameraItem?.[0]?.eventStatus ===
                                "ASSIGNED"
                                  ? "contained"
                                  : "outlined"
                              }
                              color="primary"
                            >
                              {assignedCameraItem?.[0]?.eventStatus ===
                              "ASSIGNED"
                                ? "Selected"
                                : "select"}
                            </Button> */}
                          </Grid>
                        </Grid>
                      </Card>
                      <Box sx={{ my: 2, border: "1px solid black" }} />
                    </>
                  )}
                  <Box
                    sx={{ maxHeight: 250 }}
                    className={classes.scrollableDiv}
                  >
                    {frontCameraEvent?.map((item) => (
                      <Card
                        variant="outlined"
                        sx={{
                          mb: 1,
                          boxShadow:
                            "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px",
                        }}
                      >
                        <Grid container spacing={2} alignItems="center">
                          {/* First Grid */}
                          <Grid item xs={4}>
                            <Grid container spacing={2}>
                              {item?.userImagePaths?.map((item, key) => (
                                <Grid item xs={4} spacing={1} key={key}>
                                  <img
                                    src={item}
                                    alt=""
                                    width="100"
                                    height="100"
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>

                          {/* Second Grid */}
                          <Grid item xs={4}>
                            <Typography variant="body1">
                              Entry Timestamp{" "}
                              {`: ${getTime(
                                item?.eventTime,
                                item?.storeTimeZone
                              )}`}
                            </Typography>
                          </Grid>

                          {/* Third Grid */}
                          <Grid item xs={4}>
                            <Button
                              variant={
                                item?.id === assignedCameraItem?.[0]?.id
                                  ? "contained"
                                  : "outlined"
                              }
                              color="primary"
                              onClick={() =>
                                item?.id === assignedCameraItem?.[0]?.id
                                  ? setAssignedCameraItem([])
                                  : setAssignedCameraItem([item])
                              }
                            >
                              {item?.id === assignedCameraItem?.[0]?.id
                                ? "Selected"
                                : "select"}
                            </Button>
                          </Grid>
                        </Grid>
                      </Card>
                    ))}
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Grid>

            {/* Second part with 25% width */}
            <Grid item xs={3}>
              <Box
                sx={{
                  border: "1px solid black",
                  borderRadius: "10px",
                  marginLeft: "10px",
                  height: "100%",
                  position: "relative",
                }}
              >
                <Typography variant="h6">Final Review</Typography>
                <Divider
                  sx={{
                    width: "50%", // Set the width to make it half the container width
                    margin: "0 auto",
                  }}
                />
                <Typography variant="h6" marginBottom={2}>
                  Entry Decision
                </Typography>
                <Box
                  sx={{
                    margin: 1,
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    minHeight: 150,
                    p: 1,
                  }}
                >
                  <Typography variant="body1">Gate Event</Typography>
                  <Box sx={{ pt: 2 }}>
                    <Grid container>
                      <Grid item xs={12}>
                        {entryTaskInfo?.baseActivities?.hasOwnProperty(
                          "UserActivity"
                        ) && (
                          <Grid container spacing={2}>
                            {/* IR Timestamp and Cart Reference */}
                            <Grid item xs={5}>
                              <Typography variant="subtitle2" textAlign="end">
                                Gate Timestamp
                              </Typography>
                            </Grid>
                            <Grid item xs={7}>
                              <Typography variant="subtitle2" textAlign="start">
                                {`: ${getTime(
                                  gateEventData?.entryTime,
                                  gateEventData?.storeTimeZone
                                )}`}
                              </Typography>
                            </Grid>
                            <Grid item xs={5}>
                              <Typography variant="subtitle2" textAlign="end">
                                Cart Reference
                              </Typography>
                            </Grid>
                            <Grid item xs={7}>
                              <Typography variant="subtitle2" textAlign="start">
                                {`: ${gateEventData?.cartRef}`}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box
                  sx={{
                    margin: 1,
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    minHeight: 150,
                    p: 1,
                  }}
                >
                  <Typography variant="body1">IR Event</Typography>
                  <Box sx={{ pt: 2 }}>
                    <Grid container>
                      <Grid item xs={12}>
                        {defaultIRvalue !== "" &&
                          defaultIRvalue?.label !== undefined && (
                            <Grid container spacing={2}>
                              {/* IR Timestamp and Cart Reference */}
                              <Grid item xs={5}>
                                <Typography variant="subtitle2" textAlign="end">
                                  IR Timestamp
                                </Typography>
                              </Grid>
                              <Grid item xs={7}>
                                <Typography variant="subtitle2">
                                  : {defaultIRvalue?.label}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box
                  sx={{
                    margin: 1,
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    minHeight: 150,
                    p: 1,
                  }}
                >
                  <Typography variant="body1">Camera Event</Typography>
                  <Box sx={{ pt: 2 }}>
                    {assignedCameraItem?.length > 0 &&
                      assignedCameraItem !== undefined && (
                        <Grid container>
                          {/* Left Side */}
                          <Grid item xs={3} spacing={1}>
                            {/* Image for the left side */}
                            <img
                              src={assignedCameraItem?.[0]?.userImagePaths?.[0]}
                              alt=""
                              style={{ width: "100%" }}
                            />
                          </Grid>

                          {/* Right Side */}
                          <Grid item xs={9}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Typography
                                  variant="subtitle2"
                                  textAlign="start"
                                >
                                  Entry Timestamp:{" "}
                                  {`${getTime(
                                    assignedCameraItem?.[0]?.eventTime,
                                    assignedCameraItem?.[0]?.storeTimeZone
                                  )}`}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "20px",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    loading={isLoading}
                    onClick={() => handleApproveAPI()}
                  >
                    Approve
                  </LoadingButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </>
  );
};

export default ReviewEntryEvents;
