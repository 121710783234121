import React, { useState, useEffect } from "react";
import { AiOutlineDropbox } from "react-icons/ai";
import { BiArrowBack } from "react-icons/bi";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import Mapped from "./user-details/Mapped";
import moment from "moment";
import HawkeyeThumbnail from "./HawkeyeThumbnail";
import { GrView } from "react-icons/gr";
import FadeLoader from "react-spinners/FadeLoader";
import axios from "axios";
import { useAlert } from "react-alert";
import { toast } from 'react-toastify'
import SelectProduct from "./SelectProduct";
import { isInteger } from "../../../../../../hook/useType";
import Pagination from "@mui/material/Pagination";

const options = [
  { value: "Created", label: "Created" },
  { value: "In progress", label: "In progress" },
  { value: "Resolved", label: "Resolved" },
];

const users = [
  { value: "Akash", label: "Akash" },
  { value: "PD", label: "PD" },
];

function OpenCartItem() {
  const history = useHistory();

  const alert = useAlert();

  const [openCart, setOpenCart] = useState(
    JSON.parse(localStorage?.getItem("openCart"))[0]
  );
  const [map, setMap] = useState(false);
  const onOpenMap = () => setMap(true);
  const onCloseMap = () => setMap(false);

  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [next, setNext] = useState(false);

  const [checkbox, setCheckbox] = useState({
    vandalisation: false,
    wrongPlace: false,
    consumeStore: false,
  });

  const [input, setInput] = useState({});
  const [select, setSelect] = useState({ user: "" });
  const storeData = JSON.parse(localStorage.getItem("storeCart"));

  const countPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [shopperList, setShopperList] = useState([]);
  const [editedData, setEditedData] = useState(openCart);

  const onChangeCheckbox = (value, name) => {
    setCheckbox({
      ...checkbox,
      [name]: value,
    });
  };

  const onChangeInput = (e, name) => {
    const value = e.target.value;
    setInput({ ...input, [name]: value });
    setEditedData({ ...editedData, reviewedQuantity: parseInt(value) });
  };

  useEffect(() => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_STORE_URL}/user-activities?eventStatus.notIn=CLOSED,ABANDONED&entryTime.greaterThanOrEqual=${storeData?.entryTime}&exitTime.lessThanOrEqual=${storeData?.checkoutDate}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
    }).then((res) => {
      setShopperList(res?.data);
    }).catch(({ message }) => {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message)
      }
    })
  }, []);

  const selectPage = (event, page) => {
    const len = JSON.parse(localStorage.getItem("openCart"))?.length;
    const data = JSON.parse(localStorage.getItem("openCart"))[page - 1];

    setOpenCart(data);
    setEditedData(data);
    setCurrentPage(page);

    if (page < len) {
      setNext(false);
    }
    
    if (data?.userInfos.length === 0) {
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_STORE_URL}/user-activities?eventStatus.notIn=CLOSED,ABANDONED&entryTime.greaterThanOrEqual=${storeData?.entryTime}&exitTime.lessThanOrEqual=${storeData?.checkoutDate}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      }).then((res) => {
        setShopperList(res?.data);
      }).catch(({ message }) => {
        if (message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          toast.error(message)
        }
      })
    }
  };

  useEffect(() => {
    if (openCart?.productInfos?.length === 1) {
      setProduct(openCart?.productInfos[0]);
    } else {
      setProduct(null);
    }
  }, [openCart]);

  const proceed = () => {
    try {
      axios({
        method: "PUT",
        url: `${process.env.REACT_APP_SHOPPER_URL}/stores/cart-review`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
        data: {
          sessionRef: storeData?.sessionRef,
          rootOrgId: storeData?.rootOrgId,
        },
      }).then((res) => {
        localStorage.setItem("cartReview", JSON.stringify(res?.data));
        localStorage.setItem("way", "indirect");
        // localStorage.setItem("storeCart", JSON.stringify(res?.data))
        history.push("/support/hawkeye/cartSummary");
      });
    } catch (err) {
      alert.error("Empty Cart");
    }
  };

  const selectProduct = (prod) => {
    if (openCart?.productInfos?.length > 1) {
      setProduct(prod);
    }
  };

  useEffect(() => {
    setEditedData({
      ...editedData,
      productInfos: [product],
      productId: product?.productId,
    });
  }, [product]);

  const changeHandler = (value, name) => {
    setSelect({ ...select, [name]: value });
    setEditedData({
      ...editedData,
      userInfos: [
        {
          id: value?.id,
          name: value?.name,
          login: value?.login,
          userRole: value?.userRole,
          cartRef: value?.cartRef,
        },
      ],
    });
  };

  const approval = (status) => {
    const len = JSON.parse(localStorage.getItem("openCart"))?.length;

    if (currentPage <= len) {
      if (product && select?.user?.name) {
        axios({
          method: "PUT",
          url: `${process.env.REACT_APP_STORE_URL}/store-carts/${openCart?.id}/review-event`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("user-info")}`,
          },
          data: {
            ...editedData,
            syncStatus: status,
            reviewedQuantity:
              Object.keys(input).length === 0
                ? isInteger(editedData?.quantity) && editedData?.quantity !== 0
                  ? parseInt(editedData?.quantity)
                  : parseInt(editedData?.quantity) + 1
                : null,
          },
        }).then(() => {
          setSelect({ user: "" });
          selectPage(currentPage + 1);
        });
        if (currentPage === len) {
          selectPage(currentPage);

          if (currentPage < len) {
            setNext(false);
          } else if (currentPage === len) {
            setNext(true);
          }
        }
      } else {
        console.log("product: ", product);
        alert.error(
          <div className="w-96 normal-case">
            {!product ? `Select Non-Conflict product` : `Select user`}
          </div>
        );
      }
    }
  };

  const approve = () => {
    approval("APPROVED");
  };

  const reject = () => {
    approval("REJECTED");
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  });

  const goBack = () => {
    localStorage.setItem("way", "back");
    history.goBack();
  };

  if (loading && localStorage.getItem("way") !== "reviewback")
    return (
      <div className="bg-black bg-opacity-80 absolute inset-0 flex items-center justify-center">
        <div className="w-64 h-20 absolute z-10 text-white flex flex-col items-center justify-center">
          <h2 className="">Verifying open items in the store.</h2>
          <FadeLoader
            color="#FFFFFF"
            className=""
            loading={loading}
            size={150}
          />
        </div>
      </div>
    );

  return (
    <div className="w-full h_screen  flex select-none">
      <div className="w-full h-full flex justify-center items-center bg-gray-100 p-4">
        <div className="w-7/10 h-full  ">
          <div className=" w-full h-full flex  bg-white rounded-lg mr-2 shadow-lg px-2 pt-3">
            <div className="absolute w-16 h-10 px-3">
              <BiArrowBack
                onClick={goBack}
                className="h-8 w-8 cursor-pointer px-1 mt-1 "
              />
            </div>

            <div className="w-4/5  mx-auto h-4/5 flex flex-col px-2 ">
              <div className="w-full mx-auto h-10 flex items-center  justify-center">
                <div className="w-full flex justify-between pt-2">
                  <div className="w-[30%] flex justify-start">
                    <h2 className="text-xl font-bold">Store Open Items</h2>
                  </div>

                  <div className="w-[70%]">
                    <Pagination
                      count={
                        JSON.parse(localStorage.getItem("openCart"))?.length
                      }
                      showFirstButton
                      showLastButton
                      page={currentPage}
                      onChange={selectPage}
                    />
                  </div>

                  {/* <div className="w-full flex justify-start">
                    <Link
                      onClick={() => onOpenMap()}
                      className="text-blue-600 cursor-pointer"
                    >
                      {sessionCart?.machine?.deviceIdentifier}
                    </Link>
                  </div> */}
                </div>
              </div>

              <div className="grid grid-flow-col grid-cols-2 grid-rows-2 gap-4 my-8">
                {openCart?.storeCartVideoPaths?.length > 0
                  ? openCart?.storeCartVideoPaths?.map((obj, index) => (
                      <div
                        key={index}
                        className="w-full rounded-lg border-2 border-gray-200 shadow-lg"
                      >
                        <HawkeyeThumbnail
                          img={obj?.thumbnail}
                          index={index}
                          videoUrl={obj?.videoUrl}
                          key={index}
                        />
                      </div>
                    ))
                  : [1, 2, 3, 4]?.map((ele) => (
                      <div className="w-full rounded-lg border-2 border-gray-200 shadow-lg">
                        <HawkeyeThumbnail
                          imageUrl={`https://images.unsplash.com/photo-1528821128474-27f963b062bf?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80`}
                          videoUrl={
                            "https://www.youtube.com/watch?v=LesnixX76YY&t=16s"
                          }
                        />
                      </div>
                    ))}
              </div>

              <div>
                <div className="flex items-center h-6">
                  <input
                    type="checkbox"
                    name="vandalisation"
                    onChange={(e) =>
                      onChangeCheckbox(e.target.checked, "Vandalisation")
                    }
                    className="form-checkbox h-5 w-5 text-green-600"
                  />
                  <span className="mx-1 -mt-0.5 px-2 ">Vandalisation</span>
                </div>

                <div className="flex items-center h-6">
                  <input
                    type="checkbox"
                    name="consumeStore"
                    onChange={(e) =>
                      onChangeCheckbox(e.target.checked, "consumeStore")
                    }
                    className="form-checkbox h-5 w-5 text-green-600"
                  />
                  <span className="mx-1 -mt-0.5 px-2 ">
                    Product Consume within store
                  </span>
                </div>

                <div className="flex items-center h-6">
                  <input
                    type="checkbox"
                    name="wrongPlace"
                    onChange={(e) =>
                      onChangeCheckbox(e.target.checked, "wrongPlace")
                    }
                    className="form-checkbox h-5 w-5 text-green-600"
                  />
                  <span className="mx-1 -mt-0.5 px-2 ">
                    Product kept in a wrong place
                  </span>
                </div>
              </div>
            </div>
            <Mapped open={map} onCloseMap={onCloseMap} />
          </div>
        </div>

        <div className="w-3/10 h-full rounded-lg ml-2  scrollbar-thin px-2 scrollbar-thumb-gray-500 scrollbar-track-gray-200">
          <div className="w-full grid grid-flow-row  gap-2">
            {/* cart */}

            <div className="w-full h-auto pb-2 bg-white px-2 rounded-l-lg">
              <div className="w-full h-10 flex items-center justify-between ">
                <div className="w-full flex justify-between">
                  <div className="flex h-full items-center">
                    <h2 className=" text-md mr-1">Summary</h2>
                    <GrView className="w-4 h-4" />
                  </div>
                  <h2>
                    User:{" "}
                    <span className="capitalize">
                      {localStorage.getItem("user-name")}
                    </span>
                  </h2>
                </div>

                {/* <CartView
                  data={sessionCart}
                  open={open}
                  onCloseModal={onCloseModal}
                /> */}
              </div>

              <div className="w-full h-auto  border-t-2  border-gray-900 flex justify-between items-center">
                <div className="w-full h-full grid grid-cols-1 gap-2">
                  <div className="w-full h-full  py-2 select-none">
                    <table className="table-auto rounded border-collapse border  w-full h-full text-sm">
                      <tbody className="text-left">
                        <tr>
                          <th className="border border-gray-300 bg-gray-200 px-2">
                            Session Ref
                          </th>
                          <th className="border border-gray-300 px-2 text-gray-500">
                            {storeData?.sessionRef}
                          </th>
                        </tr>

                        <tr>
                          <th className="w-24 border border-gray-300 bg-gray-200 px-2">
                            Entry Time
                          </th>
                          <th className="border border-gray-300 px-2 text-gray-500">
                            {moment(storeData?.entryTime).format(
                              "MMM Do YYYY, h:mm A"
                            )}
                          </th>
                        </tr>

                        <tr>
                          <th className="w-28 border border-gray-300 bg-gray-200 px-2">
                            Exit Time
                          </th>
                          <th className="border border-gray-300 px-2 text-gray-500">
                            {moment(storeData?.checkoutDate).format(
                              "MMM Do YYYY, h:mm A"
                            )}
                          </th>
                        </tr>
                        <tr>
                          <th className="w-24 border border-gray-300 bg-gray-200 px-2">
                            Store Name
                          </th>
                          <th className="border border-gray-300 px-2 text-gray-500">
                            {storeData?.storeName}
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {!next && (
              <div className="w-full h-auto pb-2 bg-white px-2 rounded-l-lg">
                <div className="w-full h-10 flex items-center justify-between ">
                  <div className="w-full flex justify-between">
                    <div className="flex h-full items-center">
                      <h2 className=" text-md mr-1">Event Details</h2>
                      <GrView className="w-4 h-4" />
                    </div>
                    {/* <h2 >User: <span className="capitalize">{localStorage.getItem("user-name")}</span></h2> */}
                  </div>

                  {/* <CartView
                  data={sessionCart}
                  open={open}
                  onCloseModal={onCloseModal}
                /> */}
                </div>

                <div className="w-full h-auto  border-t-2  border-gray-900 flex justify-between items-center">
                  <div className="w-full h-full grid grid-cols-1 gap-2">
                    <div className="w-full h-full  py-2 select-none">
                      <table className="table-auto rounded border-collapse border  w-full h-full text-sm">
                        <tbody className="text-left">
                          <tr>
                            <th className="border border-gray-300 bg-gray-200 px-2">
                              CartItem Id
                            </th>
                            <th className="border border-gray-300 px-2 text-gray-500">
                              {openCart?.id}
                            </th>
                          </tr>

                          <tr>
                            <th className="w-24 border border-gray-300 bg-gray-200 px-2">
                              Entry Time
                            </th>
                            <th className="border border-gray-300 px-2 text-gray-500">
                              {moment(openCart?.entryTime).format(
                                "MMM Do YYYY, h:mm A"
                              )}
                            </th>
                          </tr>

                          <tr>
                            <th className="w-28 border border-gray-300 bg-gray-200 px-2">
                              Event Time
                            </th>
                            <th className="border border-gray-300 px-2 text-gray-500">
                              {moment(openCart?.syncTime).format(
                                "MMM Do YYYY, h:mm A"
                              )}
                            </th>
                          </tr>

                          <tr>
                            <th className="w-24 border border-gray-300 bg-gray-200 px-2">
                              Shelf Id
                            </th>
                            <th className="border border-gray-300 px-2 text-gray-500">
                              {openCart?.groupId}
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* session cart */}

            {!next && (
              <div className="w-full h-auto bg-white p-2 rounded-l-lg">
                <div className="w-full h-8 flex items-center text-left">
                  <div className="w-full flex justify-between border-b-2 border-black">
                    <h2 className=" text-md ">Product Infos</h2>
                  </div>
                </div>

                {/* sessionCart */}

                <table className="table-auto rounded w-full h-auto text-sm mt-2">
                  <tbody className="text-left">
                    <tr>
                      <th className=" w-1/4  px-2">Product</th>
                      <th className="w-1/4  px-2 ">Activity</th>
                      <th className="w-1/4  px-4 ">Qty</th>
                      <th className="w-1/4 px-2">
                        <AiOutlineDropbox className="w-5 h-5" />
                      </th>
                    </tr>
                  </tbody>
                </table>

                {openCart?.productInfos?.map((item, index) => {
                  return (
                    <SelectProduct
                      item={item}
                      index={index}
                      selectProduct={selectProduct}
                      product={product}
                      onChangeInput={onChangeInput}
                      editedData={editedData}
                      input={input}
                      key={index}
                    />
                  );
                })}
              </div>
            )}
            {/* order */}

            {!next && (
              <div className="w-full h-auto bg-white p-2 rounded-lg">
                {/* <h2 className="text-left ">Tagging Shopper</h2> */}

                <div className="w-full h-16 items-center flex justify-between border-t-2 border-gray-900 mt-1 py-4 ">
                  <div className="w-full h-full flex items-center justify-start">
                    <h2>Shopper : </h2>
                  </div>

                  <div className="w-full h-full flex items-center justify-end">
                    <div style={{ width: "260px" }}>
                      <Select
                        placeholder="Name"
                        maxMenuHeight={162}
                        value={select?.user}
                        onChange={(value) => changeHandler(value, "user")}
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                        options={shopperList?.map((item, index) => {
                          return {
                            label: `${item?.name} (${item?.cartRef})`,
                            id: item?.id,
                            name: item?.name,
                            userRole: item?.userType,
                            cartRef: item?.cartRef,
                            login: item?.login,
                          };
                        })}
                      />
                    </div>
                  </div>
                </div>

                {!next && (
                  <div className="w-full flex justify-end">
                    <button
                      onClick={approve}
                      className="bg-green-600 py-1.5 px-5  rounded-full text-white focus:outline-none"
                    >
                      Approve
                    </button>
                    <button
                      onClick={reject}
                      className="bg-red-600 py-1.5 px-5 ml-5 rounded-full text-white focus:outline-none"
                    >
                      Reject
                    </button>
                  </div>
                )}
              </div>
            )}

            {next && (
              <div className="my-2">
                <button
                  onClick={proceed}
                  className="bg-gray-400 w-full h-10  rounded-full text-white focus:outline-none"
                >
                  Proceed to review
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OpenCartItem;
