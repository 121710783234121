import React from "react";
import TableStr from "./TableStr";
import Enabled from "./options/Enabled";
import Disabled from "./options/Disabled";
import All from "./options/All";
function Voucher() {
 const rootId= parseInt(JSON.parse(localStorage.getItem("dashboard-data"))?.id)
  return (
    <div className="h-full w-full ">
      <TableStr
        title={"Voucher"}
        url={"/admin/merchant/dashboard/voucherManagement/voucher/create"}
        viewUrl={"/admin/merchant/dashboard/voucherManagement/voucher/view"}
        editUrl={"/admin/merchant/dashboard/voucherManagement/voucher/edit"}
        listUrl={`${process.env.REACT_APP_BASE_URL}/vouchers?rootOrgId.equals=${rootId}`}
        getName={"voucherList"}
        Enabled={Enabled}
        Disabled={Disabled}
        
        All={All}
        showButton={true}
        dashboard={true}
      />
    </div>
  );
}

export default Voucher;
