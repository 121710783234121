import React from "react";
import TableStr from "./TableStr";
import Enabled from "./options/Enabled";
import Disabled from "./options/Disabled";
import All from "./options/All";


function Uom() {
  return (
    <div className="h-full w-full ">
      <TableStr
        title={"UOM"}
        url={ "/admin/merchant/dashboard/productManagement/uom/createUom"}
        viewUrl={"/admin/location/view"}
        editUrl={"/admin/location/edit"} 
        listUrl={`${process.env.REACT_APP_BASE_URL}/unit-of-measures?sort=createdDate,desc&rootOrgId=${parseInt(JSON.parse(localStorage.getItem("dashboard-data"))?.id)}`}
        getName={"uomList"}
        Enabled={Enabled}
        Disabled={Disabled}
        All={All}
        showButton={false}
        Modal = {"ModalUom"}
        dashboard={true}
      />
    </div>
  );
}

export default Uom;
