import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import CustomSearch from "../../../../../../components/searchbar";
import Iconify from "../../../../../../components/iconify/Iconify";

const CommonFilterSection = ({
  selectedValue,
  handleSelectChange,
  handleInputChange,
  retailerStoreOptions,
  inputValue,
  handleOnchangeSearch,
  clearAllFilters,
  isShowClearIcon,
}) => {
  return (
    <div className="p-4 flex">
      <Grid container gap={2}>
        <Grid item lg={4}>
          <Autocomplete
            value={selectedValue}
            sx={{ width: "100%" }}
            onChange={handleSelectChange}
            inputValue={selectedValue}
            onInputChange={handleInputChange}
            options={retailerStoreOptions} // Your options here
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search by retailer store"
                variant="outlined"
                size="small"
              />
            )}
          />
        </Grid>

        <Grid lg={4}>
          <CustomSearch
            label="Search by session"
            name="sessionRef.contains"
            value={inputValue["sessionRef.contains"]}
            onChange={(e) => handleOnchangeSearch(e)}
            sx={{ width: "100%" }}

            // onKeyDown={(e) => handleKeyDown(e)}
          />
        </Grid>

        <Grid lg={2}>
          <div className="w-[100%] h-[100%] flex items-center justify-start">
            {isShowClearIcon && (
              <Button
                color="error"
                size="small"
                onClick={() => clearAllFilters()}
                startIcon={<Iconify icon="eva:trash-2-outline" />}
              >
                Clear
              </Button>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default CommonFilterSection;
