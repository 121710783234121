import React, { useState, useMemo, useEffect } from "react";
import { Formik, Form } from "formik";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { BiArrowBack } from "react-icons/bi";
import countryList from "react-select-country-list";
import Select from "react-select";
import { useAlert } from "react-alert";
import { usePost } from "../../../../../../../../api/usePost";
import { TextField2 } from "../../../../../../../validation/TextField2";
import { useGet } from "../../../../../../../../api/useGet";
import CircularProgressLoader from "../../../../../../../../components/loader/CircularProgressLoader";
import { usePut } from "../../../../../../../../api/usePut";

const countryLists = [
  { label: "Germany", value: "DE" },
  { label: "Hungary", value: "HU" },
  { label: "France", value: "FR" },
];

const EditForm = ({ editData }) => {
  const {
    id,
    address,
    name,
    email,
    internalCode,
    externalCode,
    maxAllowedActiveShopping,
    maxAllowedGroupShoppingUsers,
  } = editData || {};

  const {
    street1,
    street2,
    cityOrVillage,
    administrativeArea1,
    lat,
    lan,
    state,
    country: tempCountry,
    zipCode,
    contactFirstName,
    contactLastName,
    contactNumber,
  } = address || {};

  const initialValue = {
    name: name ? name : "",
    email: email ? email : "",
    website: "",
    street1: street1 ? street1 : "",
    street2: street2 ? street2 : "",
    city: cityOrVillage ? cityOrVillage : "",
    administrativeArea: administrativeArea1 ? administrativeArea1 : "",
    latitude: lat ? lat : "",
    longitude: lan ? lan : "",
    state: state ? state : "",
    zip: zipCode ? zipCode : "",
    internalCode: internalCode ? internalCode : "",
    contactFirstName: contactFirstName ? contactFirstName : "",
    contactLastName: contactLastName ? contactLastName : "",
    contactNo: contactNumber ? contactNumber : "",
    country: tempCountry ? tempCountry : "",
    maxAllowedActiveShopping: maxAllowedActiveShopping
      ? maxAllowedActiveShopping
      : "",
    maxAllowedGroupShoppingUsers: maxAllowedGroupShoppingUsers
      ? maxAllowedGroupShoppingUsers
      : "",
    externalCode: externalCode ? externalCode : "",
  };

  const alert = useAlert();

  const history = useHistory();

  const [country, setCountry] = useState(tempCountry);

  const isCreateRetailerStore = true;

  const validate = Yup.object({
    name: Yup.string().required("Required"),
    email: Yup.string().email(),
    zip: Yup.number().typeError("Not a number").required("Required"),
    street1: Yup.string().required("Required"),
    street2: Yup.string(),
    city: Yup.string().required("Required"),
    internalCode: Yup.string().required(),
    administrativeArea: Yup.string(),
    latitude: Yup.number(),
    longitude: Yup.number(),
    state: Yup.string().required("Required"),
    contactFirstName: isCreateRetailerStore
      ? Yup.string().required("Required")
      : Yup.string(),
    contactLastName: Yup.string(),
    contactNo: Yup.number(),
    website: Yup.string(),
    country: Yup.string().required(),
    externalCode: Yup.string(),
    maxAllowedActiveShopping: Yup.string(),
    maxAllowedGroupShoppingUsers: Yup.string(),
  });

  const createMerchant = usePost(
    `${process.env.REACT_APP_BASE_URL}/orgs`,
    "merchantList"
  );

  const editRetailStore = usePut(
    `${process.env.REACT_APP_BASE_URL}/retail-stores/${id}`,
    "editRetailStore"
  );

  const changeHandler = (value, setFieldValue, setErrors) => {
    setCountry(value);
    setFieldValue("country", value?.value);
    setErrors("country", "");
  };

  return (
    <div className="w-full h-[100vh] flex">
      <div className="w-full h-full flex justify-center items-center bg-gray-200 p-12 ">
        <Formik
          initialValues={initialValue}
          validationSchema={validate}
          onSubmit={async (values) => {
            if (values.name !== "") {
              try {
                const rootOrgId = parseInt(
                  JSON.parse(localStorage.getItem("dashboard-data"))?.id
                );

                const tempData = {
                  ...editData,
                  name: values?.name || "",
                  internalCode: values?.internalCode || "",
                  externalCode: values?.externalCode || "",
                  description: null,
                  website: null,
                  email: values?.email || "",
                  defaultLang: null,
                  defaultLocale: null,
                  slug: null,
                  rootOrgId,
                  // storeType: "Department Store",
                  // cartTimeout: 600,
                  maxAllowedActiveShopping:
                    values?.maxAllowedActiveShopping || 1,
                  maxAllowedGroupShoppingUsers:
                    values?.maxAllowedGroupShoppingUsers || 1,
                  // timeZone: null,
                  address: {
                    ...editData?.address,
                    street1: values?.street1 || "",
                    street2: values?.street2 || "",
                    // neighborhoodName: null,
                    administrativeArea1: values?.administrativeArea || "",
                    // administrativeArea2: null,
                    cityOrVillage: values?.city || "",
                    state: values?.state || "",
                    zipCode: values?.zip || "",
                    country: values?.country || "",
                    contactFirstName: values?.contactFirstName || "",
                    // contactMiddleName: null|| "",
                    contactLastName: values?.contactLastName || "",
                    contactNumber: values?.contactNumber || "",
                    lat: Number(values?.latitude) || "",
                    lng: Number(values?.longitude) || "",
                  },
                  status: "STORE_OPEN",
                };

                console.log("tempData", tempData);

                editRetailStore.mutate(tempData);

                if (createMerchant.isIdle === true) {
                  history.goBack();
                  alert.success(
                    <div style={{ textTransform: "none" }}>
                      Successfully Added!
                    </div>
                  );
                }
              } catch (error) {
                alert.error(
                  <div style={{ textTransform: "none" }}>
                    Something Went Wrong!!!
                  </div>
                );
              }
            }
          }}
        >
          {({ errors, touched, setFieldValue, setErrors }) => (
            <Form className="w-11/12 mx-auto h-auto bg-white rounded-lg shadow-lg text-left">
              <div className="w-full h-16 flex rounded-t-lg bg-support text-white">
                <div className="w-10 p-2 h-full flex items-center">
                  <BiArrowBack
                    onClick={() => history.goBack()}
                    className="h-10 w-10 cursor-pointer"
                  />
                </div>
                <div className="w-full h-full px-7 py-3 flex flex-col justify-center">
                  <h2 className="font-bold text-2xl">Edit retailer store</h2>
                </div>
              </div>

              <div className="w-11/12 mx-auto px-6 mb-2">
                <div className="w-full h-20 flex mt-4 justify-between items-center">
                  <div className="flex  px-4 mr-4 w-full items-center select-none">
                    <TextField2 label="Name" name="name" type="text" />
                  </div>

                  <div className="flex  px-4 ml-4 w-full items-center select-none">
                    <TextField2 label="Email" name="email" type="email" />
                  </div>

                  <div className="flex  px-4 ml-4 w-full items-center select-none">
                    <TextField2
                      label={
                        isCreateRetailerStore ? "Store Code" : "Internal Code"
                      }
                      name="internalCode"
                      type="internalCode"
                    />
                  </div>

                  {!isCreateRetailerStore && (
                    <div className="flex  px-4 ml-4   w-full items-center select-none">
                      <TextField2 label="Website" name="website" type="text" />
                    </div>
                  )}
                </div>

                {isCreateRetailerStore && (
                  <div className="w-full h-20 flex mt-4 justify-between items-center">
                    <div className="flex  px-4 mr-4 w-full items-center select-none">
                      <TextField2
                        label="Max shoppers"
                        name="maxAllowedActiveShopping"
                        type="text"
                      />
                    </div>

                    <div className="flex  px-4 ml-4 w-full items-center select-none">
                      <TextField2
                        label="Max group shoppers"
                        name="maxAllowedGroupShoppingUsers"
                        type="text"
                      />
                    </div>

                    <div className="flex  px-4 ml-4 w-full items-center select-none">
                      <TextField2
                        label="External code"
                        name="externalCode"
                        type="text"
                      />
                    </div>
                  </div>
                )}

                <h2 className="px-5 my-2 text-left">Address : </h2>

                <div className="w-full h-20 flex mt-2  items-center">
                  <div className="flex  px-4    w-full items-center select-none">
                    <TextField2 label="Street 1" name="street1" type="text" />
                  </div>

                  <div className="flex  px-4    w-full items-center select-none">
                    <TextField2 label="Street 2" name="street2" type="text" />
                  </div>

                  <div className="flex  px-4    w-full items-center select-none">
                    <TextField2
                      label="City Or Village"
                      name="city"
                      type="text"
                    />
                  </div>

                  <div className="flex  px-4   w-full items-center select-none">
                    <TextField2
                      label="Administrative Area"
                      name="administrativeArea"
                      type="text"
                    />
                  </div>
                </div>

                <div className="w-full h-20 flex  items-center">
                  <div className="flex  px-4    w-full items-center select-none">
                    <TextField2 label="Latitude" name="latitude" type="text" />
                  </div>

                  <div className="flex  px-4    w-full items-center select-none">
                    <TextField2
                      label="Longitude"
                      name="longitude"
                      type="text"
                    />
                  </div>

                  <div className="flex  px-4    w-full items-center select-none">
                    <TextField2 label="State" name="state" type="text" />
                  </div>

                  <div className="flex  px-4 w-full items-center select-none">
                    <div style={{ width: "200px" }}>
                      <Select
                        options={countryLists}
                        placeholder="Select country"
                        maxMenuHeight={162}
                        value={country}
                        onChange={(e, value) =>
                          changeHandler(e, setFieldValue, setErrors)
                        }
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                        name="country"
                      />
                      {errors.country && touched.country ? (
                        <p className="w-full text-right text-red-500">
                          Required
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div
                  className={`${
                    isCreateRetailerStore ? "w-full" : "w-[50%]"
                  } h-20 flex items-center`}
                >
                  <div className="flex  px-4    w-full items-center select-none">
                    <TextField2 label="Zip Code" name="zip" type="text" />
                  </div>

                  {isCreateRetailerStore && (
                    <>
                      <div className="flex  px-4    w-full items-center select-none">
                        <TextField2
                          label="Contact First Name"
                          name="contactFirstName"
                          type="text"
                        />
                      </div>
                      <div className="flex  px-4    w-full items-center select-none">
                        <TextField2
                          label="Contact Last Name"
                          name="contactLastName"
                          type="text"
                        />
                      </div>
                    </>
                  )}

                  <div className="flex  px-4    w-full items-center select-none">
                    <TextField2
                      label="Contact Number"
                      name="contactNo"
                      type="text"
                    />
                  </div>
                </div>

                <div className="w-full h-16 flex justify-end items-center px-4 mt-2 ">
                  {/* <div className="flex items-center h-6">
                      <input
                        type="checkbox"
                        onChange={(e) => setAdressType(e.target.checked)}
                        className="form-checkbox h-5 w-5 text-green-600"
                      />
                      <span className="mx-1 -mt-0.5 px-2 ">
                        Save as Primary Address
                      </span>
                    </div> */}

                  <button
                    type="submit"
                    className="bg-green-500 rounded-md px-6 py-3  focus:outline-none text-white"
                  >
                    <span className="h-full flex items-center">
                      <svg
                        className="svg-icon"
                        viewBox="0 0 20 20"
                        width="24px"
                        height="24px"
                      >
                        <path
                          fill="#8A2BE2"
                          d="M19.404,6.65l-5.998-5.996c-0.292-0.292-0.765-0.292-1.056,0l-2.22,2.22l-8.311,8.313l-0.003,0.001v0.003l-0.161,0.161c-0.114,0.112-0.187,0.258-0.21,0.417l-1.059,7.051c-0.035,0.233,0.044,0.47,0.21,0.639c0.143,0.14,0.333,0.219,0.528,0.219c0.038,0,0.073-0.003,0.111-0.009l7.054-1.055c0.158-0.025,0.306-0.098,0.417-0.211l8.478-8.476l2.22-2.22C19.695,7.414,19.695,6.941,19.404,6.65z M8.341,16.656l-0.989-0.99l7.258-7.258l0.989,0.99L8.341,16.656z M2.332,15.919l0.411-2.748l4.143,4.143l-2.748,0.41L2.332,15.919z M13.554,7.351L6.296,14.61l-0.849-0.848l7.259-7.258l0.423,0.424L13.554,7.351zM10.658,4.457l0.992,0.99l-7.259,7.258L3.4,11.715L10.658,4.457z M16.656,8.342l-1.517-1.517V6.823h-0.003l-0.951-0.951l-2.471-2.471l1.164-1.164l4.942,4.94L16.656,8.342z"
                        ></path>
                      </svg>
                      <span className="px-2">Edit store</span>
                    </span>
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

function EditRetailStore() {
  const location = useLocation();
  const [loader, setLoader] = useState(false);

  const { data, isApiLoading } = useGet(
    `${process.env.REACT_APP_BASE_URL}/retail-stores/${location?.state?.data?.id}`,
    ["retailStoreById", location]
  );

  useEffect(() => {
    let timeout;
    if (isApiLoading) {
      setLoader(isApiLoading);
    } else {
      timeout = setTimeout(() => {
        setLoader(isApiLoading);
      }, 500);
    }

    return () => clearTimeout(timeout);
  }, [isApiLoading]);

  return (
    <>
      {loader ? (
        <CircularProgressLoader open={true} />
      ) : (
        <EditForm editData={data} />
      )}
    </>
  );
}

export default EditRetailStore;
