import React, { useState } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "react-toggle/style.css";
import DashboardRoute from "./DashboardRoute";
import moment from "moment";
import Action from "./Action";
import axios from "axios";
import FadeLoader from "react-spinners/FadeLoader";
import SearchData from "../../../../components/SearchDataMany";
import MobileDatetimePicker from "../../../../components/MobileDateTimepicker";
import Pagination from "@mui/material/Pagination";
import { useQuery } from "react-query";
import { MdClear } from "react-icons/md";
import { BiSearchAlt } from "react-icons/bi";
import { getTime } from "../../../../logic/useGetTime";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

function OpenEntryEvents({
  url,
  viewUrl,
  editUrl,
  listUrl,
  refresh,
}) {
  const history = useHistory();

  const [allCount, setAllCount] = useState();
  const [filteredData, setFilteredData] = useState({
    retailerStore: "",
    cartref: "",
  });
  const [searchData, setSearchData] = useState({
    retailerStore: "",
    cartref: "",
  });
  const [loading, setLoading] = useState(false);

  const [selectedTime, setSelectedTime] = useState({
    to: "",
    from: "",
  });

  const [input, setInput] = useState({
    retailerStore: "",
    cartref: "",
    to: moment().add(-24, "hours"),
    from: new Date(),
    type: "",
  });

  const findUrl = (name) => {
    switch (name) {
      case "cartref":
        return `${listUrl}?sort=entryTime,desc&page=${
          activePage - 1
        }&size=9&storeCode.equals=${
          searchData?.retailerStore?.internalCode
        }&cartRef.equals=${input?.cartref}&eventStatus.in=AUTHORIZED,TRACKED`;

      case "retailerStore":
        return `${listUrl}?sort=entryTime,desc&page=${
          activePage - 1
        }&size=9&storeCode.equals=${
          searchData?.retailerStore?.internalCode
        }&eventStatus.in=AUTHORIZED,TRACKED`;

      case "from":
        return `${listUrl}?sort=entryTime,desc&page=${
          activePage - 1
        }&size=9&entryTime.lessThanOrEqual=${moment(input?.to)
          .utc()
          .format()}&entryTime.greaterThanOrEqual=${moment(input?.from)
          .utc()
          .format()}&eventStatus.in=AUTHORIZED,TRACKED`;

      default:
        return `${listUrl}?sort=entryTime,desc&page=${
          activePage - 1
        }&size=9&eventStatus.in=AUTHORIZED,TRACKED`;
    }
  };

  const searchDataFn = (name, data) => {
    setSearchData({ ...searchData, [name]: data });
    setInput({ ...input, [name]: data?.name });
    setFilteredData({
      cartref: "",
      retailerStore: "",
    });
  };

  const [activePage, setActivePage] = useState(1);

  const fetchData = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: findUrl(input?.type),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      });
      setAllCount(res?.headers?.["x-total-count"]);
      return res.data;
    } catch ({ message }) {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message);
      }
    }
  };

  const { data, isLoading } = useQuery(
    [
      "openEntryEvents",
      activePage,
      input?.cartref,
      searchData?.retailerStore,
      selectedTime,
      refresh,
    ],
    async () => fetchData(),
    {
      enabled:
        !!activePage ||
        !!input?.cartref ||
        !!searchData?.retailerStore ||
        !!selectedTime ||
        refresh,
      cacheTime: 0,
      staleTime: 0,
    }
  );

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  const onChangeInput = (e, name) => {
    const value = e.target.value;
    setInput({ ...input, [name]: value, type: name });

    if (name === "retailerStore") {
      setLoading(true);

      axios({
        method: "GET",
        url: `${
          process.env.REACT_APP_BASE_URL
        }/_search/retail-stores?name.contains=${value.toLowerCase()}*`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      })
        .then((res) => {
          setFilteredData({ ...filteredData, [name]: res?.data });

          setLoading(false);
        })
        .catch(({ message }) => {
          if (message === "Request failed with status code 401") {
            history.push("/login");
          } else {
            toast.error(message);
          }
        });
    } else if (name === "barcode") {
      console.log("barcode");
      setLoading(true);

      axios({
        method: "GET",
        url: `${
          process.env.REACT_APP_BASE_URL
        }/_search/products?barcode.contains=${value.toLowerCase()}*`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      })
        .then((res) => {
          setFilteredData({ ...filteredData, [name]: res?.data });
          setLoading(false);
        })
        .catch(({ message }) => {
          if (message === "Request failed with status code 401") {
            history.push("/login");
          } else {
            toast.error(message);
          }
        });
    }
  };

  const onChangeTime = (value, name) => {
    setInput({ ...input, [name]: value });
  };

  const setTime = () => {
    setSelectedTime({
      to: input?.to,
      from: input?.from,
    });
    setInput({ ...input, type: "from" });
  };

  const clear = () => {
    setInput({
      retailerStore: "",
      cartref: "",
      type: "",
      to: moment().add(-24, "hours"),
      from: new Date(),
    });
    setFilteredData({ retailerStore: "", cartref: "" });
    setSearchData({
      retailerStore: "",
      cartRef: "",
    });
    setSelectedTime({
      to: "",
      from: "",
    });
  };

  if (isLoading)
    return (
      <div className="bg-black bg-opacity-80 absolute inset-0 flex items-center justify-center">
        <div className="w-full h-20 absolute z-10 text-white flex flex-col items-center justify-center">
          <FadeLoader
            color="#FFFFFF"
            className=""
            loading={isLoading}
            size={150}
          />
        </div>
      </div>
    );

  return (
    <div className=" w-full h-[calc(100vh-152px)]">
      <div className="flex h-10 w-full items-center mt-2 justify-between py-8 px-4">
        <div className="w-auto flex space-x-6 items-center">
          <div className="w-72 flex justify-end items-center">
            <SearchData
              name="retailerStore"
              label={"Retail Store"}
              input={input}
              setInput={setInput}
              onChangeInput={onChangeInput}
              filteredData={filteredData}
              searchDataFn={searchDataFn}
              setSearchData={setSearchData}
              loading={loading}
              margin="ml-[250px]"
            />
          </div>

          <div className="w-72 flex justify-end items-center">
            <input
              name="cartref"
              placeholder="CartRef"
              value={input?.cartref}
              onChange={(e) => onChangeInput(e, "cartref")}
              className="w-full h-10  focus:outline-none rounded px-4 border border-black"
            />
            {input["cartref"]?.length > 0 ? (
              <MdClear
                size={24}
                className="cursor-pointer -ml-[34px]"
                onClick={clear}
              />
            ) : (
              <BiSearchAlt
                size={24}
                className="cursor-pointer -ml-[34px] text-blue-500"
              />
            )}
          </div>
        </div>

        <div className="w-[510px] flex space-x-6 items-center -mt-4 pr-[20px]">
          <MobileDatetimePicker
            value={input?.to}
            clearIcon={false}
            onChangeTime={onChangeTime}
            name="to"
            label={"To"}
            // minDateTime={new Date()}
            maxDateTime={new Date()}
          />

          <MobileDatetimePicker
            value={input?.from}
            clearIcon={false}
            onChangeTime={onChangeTime}
            name="from"
            label={"From"}
            // minDateTime={new Date()}
            maxDateTime={new Date()}
          />
          <BiSearchAlt size={32} onClick={setTime} className="cursor-pointer" />
          {/* <GrRefresh size={28} onClick={clear} className="cursor-pointer" /> */}
        </div>
      </div>

      <div className="ag-theme-alpine  w-full h-full text-left">
        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            resizable: true,
          }}
          rowHeight={200}
          suppressRowClickSelection={true}
          rowSelection={"multiple"}
          rowData={data}
          onRowClicked={(params) =>
            history.push({
              pathname: viewUrl,
              state: { data: params.data },
            })
          }
        >
          <AgGridColumn
            headerName="Name"
            field="name"
            minWidth={250}
            sortable={true}
            headerCheckboxSelection={true}
            headerCheckboxSelectionFilteredOnly={true}
            checkboxSelection={false}
            cellRendererFramework={(params) => (
              <DashboardRoute params={params} name="name" />
            )}
          />

          <AgGridColumn
            headerName="Image"
            field="image"
            cellRendererFramework={(params) => (
              <div className="h-full w-full">
                <img
                  src={params?.data?.userImagePaths[0]}
                  alt="shopper img"
                  className="w-full h-[200px]"
                />
              </div>
            )}
          />

          <AgGridColumn
            headerName="Mobile"
            field="formattedPhone"
            minWidth={130}
            maxWidth={130}
            sortable={true}
          />

          <AgGridColumn
            headerName="Login"
            field="login"
            minWidth={100}
            sortable={true}
          />

          <AgGridColumn
            headerName="Entry Time"
            field="entryTime"
            minWidth={200}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <p className="">
                  {getTime(
                    params?.data?.entryTime,
                    params?.data?.storeTimeZone
                  )}
                </p>
              );
            }}
          />

          <AgGridColumn
            headerName="Cart Ref"
            field="cartRef"
            minWidth={80}
            sortable={true}
          />

          <AgGridColumn
            headerName="Shopper #"
            field="noOfGroupShoppers"
            minWidth={100}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <p className="px-6">{`${params?.data?.noOfGroupShoppers}`}</p>
              );
            }}
          />

          <AgGridColumn
            headerName="Store Code"
            field="retailStoreCode"
            minWidth={120}
            sortable={true}
          />
          <AgGridColumn
            headerName="Action"
            field="action"
            minWidth={80}
            cellRendererFramework={(params) => (
              <Action
                params={params}
                url={url}
                viewUrl={viewUrl}
                editUrl={editUrl}
                through={"openEntry"}
              />
            )}
          />
        </AgGridReact>
      </div>
      <div className="absolute bottom-8 right-12  flex justify-center item-center">
        <Pagination
          count={
            allCount % 9 === 0
              ? parseInt(allCount / 9)
              : parseInt(allCount / 9) + 1
          }
          showFirstButton
          showLastButton
          page={activePage}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
}

export default OpenEntryEvents;
