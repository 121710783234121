import React, { useState } from "react";
import ModalImg from "./ModalImg";

function ShopperImages({ url, index }) {
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  return (
    <div className="mx-2" key={index}>
      <img src={url} alt="img" className="h-48 w-48 rounded-lg cursor-pointer" onClick={onOpenModal} />
      <ModalImg open={open} onCloseModal={onCloseModal} img={url} />
    </div>
  );
}

export default ShopperImages;
