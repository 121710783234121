import React, { useState } from "react";
import NoImage from "../../../../Images/noImage.jpg";

function ImageModal({ obj, index }) {
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  return (
    <div className="flex flex-col justify-center items-center" key={index}>
      {obj && obj?.userImagePaths[0] ? (
        <img
          src={obj?.userImagePaths[0]}
          alt="event"
          className="w-44 h-44 rounded cursor-pointer"
        />
      ) : (
        <img
          src={NoImage}
          alt="No Image"
          className="w-44 h-44 rounded cursor-pointer border"
        />
      )}
     
    </div>
  );
}

export default ImageModal;
