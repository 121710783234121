import React from "react";
import TableStr from "./TableStr";
import Enabled from "./options/Enabled";
import Disabled from "./options/Disabled";
import All from "./options/All";

function Location() {
  return (
    <div className="h-full w-full ">
      <TableStr
        title={"Retailer Store"}
        url={"/admin/retailer-store/newLocation"}
        viewUrl={"/admin/retailer-store/view"}
        editUrl={"/admin/retailer-store/edit"}
        listUrl={`${process.env.REACT_APP_BASE_URL}/retail-stores?sort=createdDate,desc`}
        getName={"retailerList"}
        Enabled={Enabled}
        Disabled={Disabled}
        All={All}
        showButton={true}   
      />
    </div>
  );
}

export default Location;
