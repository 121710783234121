import React from "react";
import TableStr from "./TableStr";
import Enabled from "./options/Enabled";
import Disabled from "./options/Disabled";
import All from "./options/All";
function Employees() {
 const rootId= parseInt(JSON.parse(localStorage.getItem("dashboard-data"))?.id)
  return (
    <div className="h-full w-full ">
      <TableStr
        title={"Employees"}
        url={"/admin/merchant/dashboard/employees/CreateEmployee"}
        viewUrl={"/admin/merchant/dashboard/employees/view"}
        editUrl={"/admin/merchant/dashboard/employees/edit"}
        listUrl={`${process.env.REACT_APP_BASE_URL}/users?rootOrgId.equals=${rootId}&sort=createdDate,desc`}
        getName={"employeesList"}
        Enabled={Enabled}
        Disabled={Disabled}
        All={All}
        showButton={true}
        dashboard={true}
      />
    </div>
  );
}

export default Employees;
