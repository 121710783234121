import React from "react";
import { Route, Switch } from "react-router-dom";
import Protected from "./Protected";

//***************************welcome****************************************//
import Welcome from "./pages/welcome/welcome";

//***************************Employees**************************************//
import Employees from "./pages/employees/Employees";
import CreateEmployee from "./pages/employees/create/CreateEmolyee";
import EmployeeView from "./pages/employees/view/EmployeeView";
import EmployeeEdit from "./pages/employees/edit/EmployeeEdit";

//***************************PasswordChange**************************************//
import PasswordChange from "../../../passwordChange";
/*****************************MerchantDetails***********************************/
import MerchantDetails from "./pages/merchantDetails/MerchantDetails";
/*****************************Product Management************************************/

/***************************** Brand *****************************/
import Brand from "./pages/productManagement/brand/Brand";

/*****************************Tax Categories*****************************/
import Tax from "./pages/productManagement/tax/Tax";

/*****************************Uom*****************************/
import Uom from "./pages/productManagement/uom/Uom";

/*****************************schedule*****************************/
import Schedule from "./pages/productManagement/schedule/Schedule";
import CreateSchedule from "./pages/productManagement/schedule/create/CreateSchedule";
import ScheduleEdit from "./pages/productManagement/schedule/edit/ScheduleEdit";
import ScheduleView from "./pages/productManagement/schedule/view/ScheduleView";

/*****************************Product*****************************/
import Product from "./pages/productManagement/product/Product";
import CreateProduct from "./pages/productManagement/product/create/CreateProduct";
import {
  BulkProductUpload,
  BulkProductDownload,
} from "./pages/productManagement/product/bulkProduct";

import ProductView from "./pages/productManagement/product/view/ProductView";
import ProductEdit from "./pages/productManagement/product/edit/ProductEdit";

/*****************************Dynamic Price*******************/
import DynamicPrice from "./pages/productManagement/dynamicPrice/DynamicPrice";
import CreateDynamicPrice from "./pages/productManagement/dynamicPrice/create/CreateDynamicPrice";
import DynamicPriceView from "./pages/productManagement/dynamicPrice/view/DynamicPriceView";
import DynamicPriceEdit from "./pages/productManagement/dynamicPrice/edit/DynamicPriceEdit";

/*****************************Esl*****************************/
import Esl from "./pages/productManagement/esl/Esl";
import EslView from "./pages/productManagement/esl/view/EslView";
import CreateEsl from "./pages/productManagement/esl/create/CreateEsl";
import EslUpdate from "./pages/productManagement/esl/edit/EslUpdate";

/*****************************Supplier*****************************/
import Supplier from "./pages/productManagement/supplier/Supplier";
import CreateSupplier from "./pages/productManagement/supplier/create/CreateSupplier";

import SupplierView from "./pages/productManagement/supplier/view/SupplierView";
import SupplierEdit from "./pages/productManagement/supplier/edit/SupplierEdit";

/*****************************Category*****************************/
import Category from "./pages/productManagement/category/Category";
//import CreateCategory from "./pages/productManagement/category/CreateCategory";

/*****************************Sub Category*****************************/
import SubCategory from "./pages/productManagement/category/subcategory/SubCategory";

//***************************************Voucher Management*****************************************//
import Voucher from "./pages/voucherManagement/voucher/Voucher";
import CreateVoucher from "./pages/voucherManagement/voucher/create/CreateVoucher";
import Distribution from "./pages/voucherManagement/distribution/Distribution";
import VoucherUpdate from "./pages/voucherManagement/voucher/edit/VoucherUpdate";
import VoucherView from "./pages/voucherManagement/voucher/view/VoucherView";

/*****************************Location************************************/
import RetailerStore from "./pages/location/Location";
import RetailerView from "./pages/location/view/RetailerView";
import StoreLimit from "./pages/location/view/StoreLimit";

/*****************************Rack************************************/
import Rack from "./pages/rack/Rack";
import CreateRack from "./pages/rack/create/CreateRack";
import RackView from "./pages/rack/view/RackView";
import RackEdit from "./pages/rack/edit/RackEdit";
import ShelveView from "./pages/rack/view/ShelveView";

/*****************************Merchant config************************************/
import MerchantConfig from "./pages/merchantConfig/MerchantConfig";
import CreateMerchantconfig from "./pages/merchantConfig/create/CreateMerchantconfig";
import MerchantconfigEdit from "./pages/merchantConfig/edit/MerchantconfigEdit";

/*****************************Machine************************************/
import Machine from "./pages/machine/Machine";
import MachineDashboard from "./pages/machine/dashboard/MachineDashboard";
import MachineView from "./pages/machine/view/MachineView";
import MachineEdit from "./pages/machine/edit/MachineEdit";
import ScaleView from "./pages/machine/dashboard/view/ScaleView";
import ScaleEdit from "./pages/machine/dashboard/edit/ScaleEdit";

/*****************************Orders************************************/
import Orders from "./pages/order/Order";
import OrderSummary from "./pages/order/dashboard/OrderSummary";
import Refund from "./pages/order/dashboard/Refund";

/*****************************Fullfillment*****************************/
import Fullfillment from "./pages/fullfillment/Fullfillment";
import FullfillmentRack from "./pages/fullfillment/FullfillmentRack";
import Shoppers from "./pages/shoppers/Shoppers";
import CreateMerchant from "../../../../pages/support/dashboard/pages/merchant/create/CreateMerchant";
import EditRetailStore from "./pages/location/options/model/EditRetailStore";
import CreateMachine from "./pages/machine/create/CreateMachine";
import ConfigureStore from "./pages/location/options/ConfigureStore";

// ============================  Notifications ========================/
import Notifications from "./pages/notification";
import CreateTemplate from "./pages/notification/createTemplate/CreateTemplates";
import ViewTemplate from "./pages/notification/viewTemplate/ViewTemplate";

window.addEventListener("unload", function (event) {
  localStorage.removeItem("dashboard-tab");
});

function Index() {
  return (
    <Switch>
      {/*****************************MerchantDetails************************************/}
      <Route path="/admin/merchant/dashboard/MerchantDetails" exact>
        <Protected Cmp={MerchantDetails} />
      </Route>
      {/*****************************ProductManagement************************************/}
      <Route path="/admin/merchant/dashboard/productManagement/tax" exact>
        <Protected Cmp={Tax} />
      </Route>

      <Route path="/admin/merchant/dashboard/productManagement/brand" exact>
        <Protected Cmp={Brand} />
      </Route>

      <Route path="/admin/merchant/dashboard/productManagement/offer" exact>
        <Protected Cmp={Schedule} />
      </Route>

      <Route
        path="/admin/merchant/dashboard/productManagement/offer/createOffer"
        exact
      >
        <Protected Cmp={CreateSchedule} />
      </Route>

      <Route
        path="/admin/merchant/dashboard/productManagement/offer/edit"
        exact
      >
        <Protected Cmp={ScheduleEdit} />
      </Route>

      <Route
        path="/admin/merchant/dashboard/productManagement/offer/view"
        exact
      >
        <Protected Cmp={ScheduleView} />
      </Route>

      <Route path="/admin/merchant/dashboard/productManagement/uom" exact>
        <Protected Cmp={Uom} />
      </Route>

      <Route path="/admin/merchant/dashboard/productManagement/esl" exact>
        <Protected Cmp={Esl} />
      </Route>

      <Route path="/admin/merchant/dashboard/productManagement/esl/view" exact>
        <Protected Cmp={EslView} />
      </Route>

      <Route
        path="/admin/merchant/dashboard/productManagement/esl/create"
        exact
      >
        <Protected Cmp={CreateEsl} />
      </Route>

      <Route path="/admin/merchant/dashboard/productManagement/esl/edit" exact>
        <Protected Cmp={EslUpdate} />
      </Route>

      <Route path="/admin/merchant/dashboard/productManagement/supplier" exact>
        <Protected Cmp={Supplier} />
      </Route>

      <Route
        path="/admin/merchant/dashboard/productManagement/supplier/view"
        exact
      >
        <SupplierView />
      </Route>

      <Route
        path="/admin/merchant/dashboard/productManagement/supplier/edit"
        exact
      >
        <SupplierEdit />
      </Route>

      <Route
        path="/admin/merchant/dashboard/productManagement/supplier/createSupplier"
        exact
      >
        <Protected Cmp={CreateSupplier} />
      </Route>

      <Route path="/admin/merchant/dashboard/productManagement/product" exact>
        <Protected Cmp={Product} />
      </Route>

      <Route
        path="/admin/merchant/dashboard/productManagement/product/CreateProduct"
        exact
      >
        <Protected Cmp={CreateProduct} />
      </Route>

      <Route
        path="/admin/merchant/dashboard/productManagement/product/productbulkupload"
        exact
      >
        <Protected Cmp={BulkProductUpload} />
      </Route>

      <Route
        path="/admin/merchant/dashboard/productManagement/product/productbulkdownload"
        exact
      >
        <Protected Cmp={BulkProductDownload} />
      </Route>

      <Route
        path="/admin/merchant/dashboard/productManagement/product/view"
        exact
      >
        <ProductView />
      </Route>

      <Route
        path="/admin/merchant/dashboard/productManagement/product/edit"
        exact
      >
        <ProductEdit />
      </Route>

      <Route path="/admin/merchant/dashboard/productManagement/category" exact>
        <Protected Cmp={Category} />
      </Route>

      <Route
        path="/admin/merchant/dashboard/productManagement/sub-category"
        exact
      >
        <Protected Cmp={SubCategory} />
      </Route>

      <Route
        path="/admin/merchant/dashboard/productManagement/dynamicPrice"
        exact
      >
        <Protected Cmp={DynamicPrice} />
      </Route>

      <Route
        path="/admin/merchant/dashboard/productManagement/dynamicPrice/create"
        exact
      >
        <Protected Cmp={CreateDynamicPrice} />
      </Route>

      <Route
        path="/admin/merchant/dashboard/productManagement/dynamicPrice/view"
        exact
      >
        <Protected Cmp={DynamicPriceView} />
      </Route>

      <Route
        path="/admin/merchant/dashboard/productManagement/dynamicPrice/edit"
        exact
      >
        <Protected Cmp={DynamicPriceEdit} />
      </Route>

      {/* <Route path="/admin/merchant/dashboard/productManagement/category/create" exact>
        <Protected Cmp={CreateCategory} />
      </Route> */}

      {/*********************************Voucher Management*********************************/}
      <Route path="/admin/merchant/dashboard/voucherManagement/voucher" exact>
        <Protected Cmp={Voucher} />
      </Route>

      <Route
        path="/admin/merchant/dashboard/voucherManagement/voucher/create"
        exact
      >
        <Protected Cmp={CreateVoucher} />
      </Route>

      <Route
        path="/admin/merchant/dashboard/voucherManagement/voucher/edit"
        exact
      >
        <Protected Cmp={VoucherUpdate} />
      </Route>

      <Route
        path="/admin/merchant/dashboard/voucherManagement/distribution"
        exact
      >
        <Protected Cmp={Distribution} />
      </Route>

      <Route
        path="/admin/merchant/dashboard/voucherManagement/voucher/view"
        exact
      >
        <Protected Cmp={VoucherView} />
      </Route>

      {/*****************************Password Change************************************/}
      <Route path="/admin/merchant/dashboard/password" exact>
        <Protected Cmp={PasswordChange} />
      </Route>

      {/*****************************Machine************************************/}
      <Route path="/admin/merchant/dashboard/machine" exact>
        <Protected Cmp={Machine} />
      </Route>

      <Route path="/admin/merchant/dashboard/machine/create" exact>
        <Protected Cmp={CreateMachine} />
      </Route>

      <Route path="/admin/merchant/dashboard/machine/view" exact>
        <Protected Cmp={MachineView} />
      </Route>

      <Route path="/admin/merchant/dashboard/machine/edit" exact>
        <Protected Cmp={MachineEdit} />
      </Route>

      <Route path="/admin/merchant/dashboard/machine/management" exact>
        <Protected Cmp={MachineDashboard} />
      </Route>

      <Route
        path="/admin/merchant/dashboard/machine/management/scale/view"
        exact
      >
        <Protected Cmp={ScaleView} />
      </Route>

      <Route
        path="/admin/merchant/dashboard/machine/management/scale/edit"
        exact
      >
        <Protected Cmp={ScaleEdit} />
      </Route>

      {/*****************************Retailer-Store************************************/}

      <Route path="/admin/merchant/dashboard/retailer-store" exact>
        <Protected Cmp={RetailerStore} />
      </Route>

      <Route
        path="/admin/merchant/dashboard/retailer-store/configure-store"
        exact
      >
        <Protected Cmp={ConfigureStore} />
      </Route>

      <Route
        path="/admin/merchant/dashboard/retailer-store/configure-store/create"
        exact
      >
        <Protected Cmp={CreateMerchantconfig} />
      </Route>

      <Route
        path="/admin/merchant/dashboard/retailer-store/configure-store/edit"
        exact
      >
        <Protected Cmp={MerchantconfigEdit} />
      </Route>

      <Route path="/admin/merchant/dashboard/retailer-store/edit" exact>
        <Protected Cmp={EditRetailStore} />
      </Route>

      <Route path="/admin/merchant/dashboard/retailer-store/create-store" exact>
        <Protected CreateMerchant Cmp={CreateMerchant} />
      </Route>

      <Route path="/admin/merchant/dashboard/retailer-store/view" exact>
        <Protected Cmp={RetailerView} />
      </Route>

      <Route path="/admin/merchant/dashboard/retailer-store/storelimit" exact>
        <Protected Cmp={StoreLimit} />
      </Route>

      {/*============================ Notifications =================================== */}

      <Route path="/admin/merchant/dashboard/notifications" exact>
        <Protected Cmp={Notifications} />
      </Route>
      <Route
        path="/admin/merchant/dashboard/notifications/create-template"
        exact
      >
        <Protected Cmp={CreateTemplate} />
      </Route>
      <Route
        path="/admin/merchant/dashboard/notifications/update-template"
        exact
      >
        <Protected Cmp={CreateTemplate} />
      </Route>
      <Route path="/admin/merchant/dashboard/notifications/view-template" exact>
        <Protected Cmp={ViewTemplate} />
      </Route>

      {/*****************************Merchant config************************************/}
      <Route path="/admin/merchant/dashboard/merchantconfig" exact>
        <Protected Cmp={MerchantConfig} />
      </Route>

      <Route
        path="/admin/merchant/dashboard/merchantconfig/CreateMerchantconfig"
        exact
      >
        <Protected Cmp={CreateMerchantconfig} />
      </Route>

      <Route
        path="/admin/merchant/dashboard/merchantconfig/MerchantconfigEdit"
        exact
      >
        <Protected Cmp={MerchantconfigEdit} />
      </Route>
      {/*****************************Rack************************************/}

      <Route path="/admin/merchant/dashboard/rack" exact>
        <Protected Cmp={Rack} />
      </Route>

      <Route path="/admin/merchant/dashboard/rack/newRack" exact>
        <Protected Cmp={CreateRack} />
      </Route>

      <Route path="/admin/merchant/dashboard/rack/view" exact>
        <Protected Cmp={RackView} />
      </Route>

      <Route path="/admin/merchant/dashboard/rack/edit" exact>
        <Protected Cmp={RackEdit} />
      </Route>

      <Route path="/admin/merchant/dashboard/rack/view/shelves" exact>
        <Protected Cmp={ShelveView} />
      </Route>

      {/*****************************Orders************************************/}
      <Route path="/admin/merchant/dashboard/orders" exact>
        <Protected Cmp={Orders} />
      </Route>

      <Route path="/admin/merchant/dashboard/orders/order-summary" exact>
        <Protected Cmp={OrderSummary} />
      </Route>

      <Route path="/admin/merchant/dashboard/orders/refund" exact>
        <Protected Cmp={Refund} />
      </Route>

      {/*****************************Welcome************************************/}
      <Route path="/admin/merchant/dashboard" exact>
        <Protected Cmp={Welcome} />
      </Route>

      {/*****************************Employees************************************/}
      <Route path="/admin/merchant/dashboard/employees" exact>
        <Protected Cmp={Employees} />
      </Route>

      <Route path="/admin/merchant/dashboard/employees/CreateEmployee" exact>
        <Protected Cmp={CreateEmployee} />
      </Route>

      <Route path="/admin/merchant/dashboard/employees/view" exact>
        <EmployeeView />
      </Route>

      <Route path="/admin/merchant/dashboard/employees/edit" exact>
        <EmployeeEdit />
      </Route>

      {/*****************************Fullfillment************************************/}

      <Route path="/admin/merchant/dashboard/rack/fullfillment" exact>
        <Fullfillment />
      </Route>

      <Route path="/admin/merchant/dashboard/fullfillment" exact>
        <FullfillmentRack />
      </Route>

      {/*****************************Shoppers****************************************/}

      <Route path="/admin/merchant/dashboard/shoppers" exact>
        <Protected Cmp={Shoppers} />
      </Route>
    </Switch>
  );
}

export default Index;
