import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import Confirm from "./Confirm";
import {useGet} from "../../../../api/useGet"

function MerchantView() {
  const [status, setStatus] = useState(false);
  const location = useLocation();
  const history = useHistory();
  

  const {data} = useGet(`${process.env.REACT_APP_BASE_URL}/orgs/${location?.state?.id}`,"merchantView")

 

  const statusChecked = (e) => {
    setStatus(e.target.checked);
  };

  const edit = () => {
    history.push({
      pathname: "/admin/merchant/edit",
      state: { data: data, id: data?.id, getName: "merchantView" },
    });
  };

  return (
    <>
      <div className="w-full h_view flex select-none">
        <div className="w-full h-full flex flex-col justify-center items-center  bg-gray-200">
          <div className="w-full h-12 flex bg-gray-100  items-center">
            <BiArrowBack
              onClick={() => history.goBack()}
              className="h-6 w-6 cursor-pointer ml-5"
            />
            {/* <h2 className="font-bold px-6 text-xl">{data?.name}</h2> */}
          </div>

          <div className="w-full h_rem flex p-2 text-left ">
            <div className="w-7/10 h-full bg-white rounded-lg p-2 ">
              <div className="w-full border-b-2">
                <h2 className="text-black text-bold text-lg select-none">
                  Information
                </h2>
              </div>

              <div className="flex  h-20 mt-1 flex-wrap space-y-1 items-center select-none">
                <button
                  onClick={edit}
                  className="focus:outline-none bg-grey-100 border-2 border-black ml-1 hover:bg-grey text-grey-darkest font-bold py-1 px-4 rounded inline-flex items-center"
                >
                  <FaEdit className="w-4 h-4" />
                  <span className="mx-2">Edit</span>
                </button>

                <Confirm />
              </div>

              <div className="mt-1 h-96 select-none scrollbar-thin hover:scrollbar-thumb-gray-500 scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100">
                <div className="border-t-2 border-gray-200 py-1 px-4">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="">Name</div>
                    <div className="">{data?.name}</div>
                  </div>
                </div>

                {data?.email ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">Email</div>
                      <div className="">{data?.email}</div>
                    </div>
                  </div>
                ) : null}

                {data?.description ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">Description</div>
                      <div className="">{data?.description}</div>
                    </div>
                  </div>
                ) : null}

                {data?.internalCode ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">Internal Code</div>
                      <div className="">{data?.internalCode}</div>
                    </div>
                  </div>
                ) : null}

                {data?.website ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">Website</div>
                      <div className="">{data?.website}</div>
                    </div>
                  </div>
                ) : null}

                {data?.phone ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">Phone</div>
                      <div className="">{data?.phone}</div>
                    </div>
                  </div>
                ) : null}

                {data?.orgType ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">OrgType</div>
                      <div className="">{data?.orgType}</div>
                    </div>
                  </div>
                ) : null}

                <h2 className="font-bold mt-2 ml-4 text-base ">Address: </h2>

                {data?.addresses?.map((address) => {
                  return (
                    <>
                      {address?.street1 ? (
                        <div className="border-t-2 border-gray-200 py-1 px-4">
                          <div className="grid grid-cols-2 gap-4">
                            <div className="">Street</div>
                            <div className="">{address?.street1}</div>
                          </div>
                        </div>
                      ) : null}

                      {address?.street2 ? (
                        <div className="border-t-2 border-gray-200 py-1 px-4">
                          <div className="grid grid-cols-2 gap-4">
                            <div className="">street ..</div>
                            <div className="">{address?.street2}</div>
                          </div>
                        </div>
                      ) : null}

                      {address?.cityOrVillage ? (
                        <div className="border-t-2 border-gray-200 py-1 px-4">
                          <div className="grid grid-cols-2 gap-4">
                            <div className="">City / Village</div>
                            <div className="">{address?.cityOrVillage}</div>
                          </div>
                        </div>
                      ) : null}

                      {address?.administrativeArea1 ? (
                        <div className="border-t-2 border-gray-200 py-1 px-4">
                          <div className="grid grid-cols-2 gap-4">
                            <div className="">Administrative Area</div>
                            <div className="">
                              {address?.administrativeArea1}
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {address?.administrativeArea2 ? (
                        <div className="border-t-2 border-gray-200 py-1 px-4">
                          <div className="grid grid-cols-2 gap-4">
                            <div className="">Administrative Area ..</div>
                            <div className="">
                              {address?.administrativeArea2}
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {address?.neighborhoodName ? (
                        <div className="border-t-2 border-gray-200 py-1 px-4">
                          <div className="grid grid-cols-2 gap-4">
                            <div className="">Neighborhood Name</div>
                            <div className="">{address?.neighborhoodName}</div>
                          </div>
                        </div>
                      ) : null}

                      {address?.state ? (
                        <div className="border-t-2 border-gray-200 py-1 px-4">
                          <div className="grid grid-cols-2 gap-4">
                            <div className="">State</div>
                            <div className="">{address?.state}</div>
                          </div>
                        </div>
                      ) : null}

                      {address?.lat ? (
                        <div className="border-t-2 border-gray-200 py-1 px-4">
                          <div className="grid grid-cols-2 gap-4">
                            <div className="">Latitude</div>
                            <div className="">{address?.lat}</div>
                          </div>
                        </div>
                      ) : null}

                      {address?.lng ? (
                        <div className="border-t-2 border-gray-200 py-1 px-4">
                          <div className="grid grid-cols-2 gap-4">
                            <div className="">Longitude</div>
                            <div className="">{address?.lng}</div>
                          </div>
                        </div>
                      ) : null}

                      {address?.contactFirstName ? (
                        <div className="border-t-2 border-gray-200 py-1 px-4">
                          <div className="grid grid-cols-2 gap-4">
                            <div className="">Contact Name</div>
                            <div className="">{`${
                              address?.contactFirstName
                            } + ${
                              address?.contactMiddleName
                                ? address?.contactMiddleName
                                : null
                            }
                      + ${
                        address?.contactLastName
                          ? address?.contactLastName
                          : null
                      } `}</div>
                          </div>
                        </div>
                      ) : null}

                      {address?.contactNumber ? (
                        <div className="border-t-2 border-gray-200 py-1 px-4">
                          <div className="grid grid-cols-2 gap-4">
                            <div className="">Contact Number</div>
                            <div className="">{address?.contactNumber}</div>
                          </div>
                        </div>
                      ) : null}

                      {address?.country ? (
                        <div className="border-t-2 border-gray-200 py-1 px-4">
                          <div className="grid grid-cols-2 gap-4">
                            <div className="">Country</div>
                            <div className="">{address?.country}</div>
                          </div>
                        </div>
                      ) : null}

                      {address?.zipCode ? (
                        <div className="border-t-2 border-gray-200 py-1 px-4">
                          <div className="grid grid-cols-2 gap-4">
                            <div className="">Zip Code</div>
                            <div className="">{address?.zipCode}</div>
                          </div>
                        </div>
                      ) : null}
                    </>
                  );
                })}
              </div>
            </div>

            <div className="w-3/10 h-full ml-2 bg-white rounded-lg p-2 ">
              <div className="w-full border-b-2">
                <h2 className="text-black text-bold text-lg">Log</h2>
              </div>

              <div className="flex"></div>

              <div></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MerchantView;
