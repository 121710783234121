import React, { useState, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "react-toggle/style.css";
import DashboardRoute from "./DashboardRoute";
import FadeLoader from "react-spinners/FadeLoader";
import SearchData from "../../../../components/SearchDataMany";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import { useQuery } from "react-query";
import Action from "./Action";
import {getTime} from "../../../../logic/useGetTime";
import {useHistory} from 'react-router-dom'
import {toast} from 'react-toastify'

function Inflight({ listUrl }) {
  const [filteredData, setFilteredData] = useState({
    retailerStore: "",
    session: "",
  });

  const[reload, setReload] = useState(false)

  const [searchData, setSearchData] = useState({
    retailerStore: "",
    session: "",
  });

  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    localStorage.setItem("navigate", "rejected");
  }, []);

  const [input, setInput] = useState({
    retailerStore: "",
    session: "",
    type: "",
  });

  const findUrl = (name) => {
    switch (name) {
      case "retailerStore":
        return `${listUrl}&page=${activePage - 1}&size=9&storeId.equals=${
          searchData.retailerStore
        }&status.equals=PENDING`;

      case "session":
        return `${listUrl}&page=${
          activePage - 1
        }&size=9&retailStoreId.equals=${searchData}&status.equals=PENDING`;

      default:
        return `${listUrl}&page=${activePage - 1}&size=9&status.equals=PENDING`;
    }
  };

  const searchDataFn = (name, data) => {
    setSearchData(data?.rootOrgId);
    setInput({ ...input, [name]: data?.name });
    setFilteredData({
      session: "",
      retailerStore: "",
    });
  };

  const onChangeInput = (e, name) => {
    const value = e.target.value;

    setInput({ ...input, [name]: value, type: name });

    if (name === "retailerStore") {
      setLoading(true);
      axios({
        method: "GET",
        url: `${
          process.env.REACT_APP_BASE_URL
        }/_search/retail-stores?name.contains=${value.toLowerCase()}*`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      }).then((res) => {
        setFilteredData({ ...filteredData, [name]: res?.data });
        setLoading(false);
      }).catch(({ message }) => {
        if (message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          toast.error(message)
        }
      })
    } else if (name === "session") {
      setLoading(true);
      axios({
        method: "GET",
        url: `${
          process.env.REACT_APP_BASE_URL
        }/_search/retail-stores?name.contains=${value.toLowerCase()}*`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      }).then((res) => {
        setFilteredData({ ...filteredData, [name]: res?.data });
        setLoading(false);
      }).catch(({ message }) => {
        if (message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          toast.error(message)
        }
      })
    }
  };

  const [activePage, setActivePage] = useState(1);

  const fetchData = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: findUrl(input?.type),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      });
      return res.data;
    } catch ({message}) {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message)
      }
    }
    
  };

  const { data } = useQuery(["hawkeyeInflightList", reload, activePage, searchData?.retailerStore, searchData?.session],
    async () => fetchData(),
    {
      enabled: !!reload || !!activePage || !!searchData?.retailerStore || !!searchData?.session,
      cacheTime: Infinity,
      staleTime: 1000
    }
  );

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  const clear = () => {
    setInput({
      retailerStore: "",
      session: "",
    });

    setFilteredData({
      retailerStore: "",
      session: "",
    });

    setSearchData({
      retailerStore: "",
      session: "",
    });
  };

  // const view = () => {
  //   axios({
  //     method: "GET",
  //     url: `${process.env.REACT_APP_STORE_URL}/store-carts?cartRef.equals=${data?.sessionRef}&storeCode.equals=${data?.storeCode}&sort=eventTime,desc`,
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${localStorage.getItem("user-info")}`,
  //     },
  //   }).then((res) => {
  //     history.push({
  //       pathname: "/admin/match/processedEvents",
  //       state: { data: res?.data },
  //     });
  //   });
  // };

  return (
    <div className=" w-full  h-[calc(100vh-216px)]">
      <div
        className={
          loading
            ? "bg-black bg-opacity-0 absolute inset-0 z-10 flex items-center justify-center"
            : null
        }
      >
        <FadeLoader className="absolute " loading={loading} size={150} />
      </div>
      <div className="flex h-10 w-full items-center mt-2 justify-between py-8 px-5">
        <div className="w-3/4 flex justify-between h-full items-center">
          <div className="w-auto flex space-x-6 items-center">
            <div className="w-72 flex justify-end items-center">
              <SearchData
                name="retailerStore"
                label={"Retailer Store"}
                input={input}
                setInput={setInput}
                onChangeInput={onChangeInput}
                filteredData={filteredData}
                searchDataFn={searchDataFn}
                setSearchData={setSearchData}
                loading={loading}
                margin="ml-[250px]"
              />
            </div>

            <div className="w-72 flex justify-end items-center">
              <SearchData
                name="session"
                label={"Session"}
                input={input}
                setInput={setInput}
                onChangeInput={onChangeInput}
                filteredData={filteredData}
                searchDataFn={searchDataFn}
                setSearchData={setSearchData}
                loading={loading}
                margin="ml-[250px]"
              />
            </div>
          </div>
        </div>
        <button
          onClick={clear}
          className="bg-green-600 py-1.5 px-5 mr-7 rounded text-white cursor-pointer focus:outline-none"
        >
          Clear filters
        </button>
      </div>

      <div className="ag-theme-alpine  w-full h-full text-left">
        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            resizable: true,
          }}
          suppressRowClickSelection={true}
          rowSelection={"multiple"}
          rowData={data}
        >
          <AgGridColumn
            headerName="Session Ref"
            field="sessionRef"
            minWidth={155}
            maxWidth={250}
            sortable={true}
            headerCheckboxSelection={true}
            headerCheckboxSelectionFilteredOnly={true}
            checkboxSelection={true}
            cellRendererFramework={(params) => (
              <DashboardRoute params={params} tab="inflight" />
            )}
          />

          {/* <AgGridColumn
            headerName="Mobile"
            field="formattedPhone"
             minWidth={130}
            maxWidth={130}
            sortable={true}
          /> */}

          <AgGridColumn
            headerName="Entry Time"
            field="checkoutDate"
            minWidth={170}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <>
                  <p>
                  {getTime(params?.data?.entryTime, params?.data?.storeTimeZone)}
                  </p>
                </>
              );
            }}
          />

          <AgGridColumn
            headerName="Checkout Time"
            field="checkoutDate"
            minWidth={170}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <>
                  <p>
                  {getTime(params?.data?.checkoutDate, params?.data?.storeTimeZone)}
                  </p>
                </>
              );
            }}
          />

          

          {/* <AgGridColumn
            headerName="Retailer Name"
            field="retailerName"
            minWidth={170}
            sortable={true}
          />

          <AgGridColumn
            headerName="Retailer Code"
            field="retailerCode"
            minWidth={170}
            sortable={true}
          /> */}

          <AgGridColumn
            headerName="Store Name"
            field="storeName"
            minWidth={170}
            sortable={true}
          />

          <AgGridColumn
            headerName="store Code"
            field="storeCode"
            minWidth={100}
            sortable={true}
          />

          {/* <AgGridColumn
            headerName="Store Address"
            minWidth={150}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <>
                  <p className="flex">
                    {params?.data?.cityOrVillage
                      ? `${params?.data?.cityOrVillage}, `
                      : null}
                    {params?.data?.zipCode
                      ? `${params?.data?.zipCode}, `
                      : null}
                    {params?.data?.country ? `${params?.data?.country} ` : null}
                  </p>
                </>
              );
            }}
          /> */}

          <AgGridColumn
            headerName="Action"
            minWidth={40}
            cellRendererFramework={(params) => <Action params={params} setReload={setReload} reload={reload} />}
          />
        </AgGridReact>
      </div>
      <div className="  absolute bottom-8 right-12  flex justify-center item-center ">
        <Pagination
          count={1}
          showFirstButton
          showLastButton
          page={activePage}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
}

export default Inflight;
