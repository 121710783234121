import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { useAlert } from "react-alert";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import DashboardShelf from "./DashboardShelf";
import { useGet } from "../../../../../../../api/useGet";
import Confirm from "./Confirm";
import { GrView } from "react-icons/gr";
import { FiEdit } from "react-icons/fi";
import { FaWeight } from "react-icons/fa";
import { MdCompassCalibration } from "react-icons/md";
import axios from "axios";
import { CreateScale, EditScale, ViewScale } from "./scale";
import CircularProgressLoader from "../../../../../../../components/loader/CircularProgressLoader";

function ShelvesView() {
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [view, setView] = useState(false);
  const onOpenView = () => setView(true);
  const onCloseView = () => setView(false);
  const [viewData, setViewData] = useState(null);

  const [edit, setEdit] = useState(false);
  const onOpenEdit = () => setEdit(true);
  const onCloseEdit = () => setEdit(false);
  const [editData, setEditData] = useState(null);

  //const data = JSON.parse(localStorage.getItem("shelf"));
  const {
    state: { data },
  } = location;

  const [input, setInput] = useState({
    maxScales: data?.maxScales,
  });

  const shelf = useGet(
    `${process.env.REACT_APP_BASE_URL}/scales?sort=column,asc&groupId.equals=${data?.internalCode}&rootOrgId.equals=${data?.rootOrgId}&retailStoreId.equals=${data?.retailStoreId}`,
    "scaleList"
  );
  console.log("🚀 ~ file: ShelveView.js:50 ~ ShelvesView ~ shelf:", shelf);

  const editFn = async (data) => {
    if (localStorage.getItem("role") === "direct") {
      alert.error("Contact Admin");
    } else {
      onOpenEdit();
      setEditData(data);
    }
  };

  const viewFn = async (data) => {
    onOpenView();
    setViewData(data);
  };

  const onChangeInput = (value, name) => {
    setInput({ ...input, [name]: value });
  };

  const calibrateShelf = () => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/shelves/${data?.id}/calibration`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
      data: {
        maxScales: input?.maxScales,
      },
    });
  };
  const refweightShelf = () => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/shelves/${data?.id}/ref-weight`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
      data: {
        maxScales: input?.maxScales,
      },
    });
  };

  const enable = (data) => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/scales/${data?.id}/enable`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
      data: {},
    });
  };

  const disable = (data) => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/scales/${data?.id}/disable`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
      data: {},
    });
  };

  const calibrate = (data) => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/scales/${data?.id}/calibration`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
      data: {},
    });
  };

  const refweight = (data) => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/scales/${data?.id}/ref-weight`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
      data: {},
    });
  };

  const ping = () => {};

  const createShelf = () => {
    onOpenModal();
  };

  return (
    <>
      {shelf?.isApiLoading ? (
        <CircularProgressLoader open={true} />
      ) : (
        <div
          className={`w-full h-screen pt-2
         text-left flex select-none bg-gray-200`}
        >
          <div className="w-full h-full px-4  py-2">
            <div className="w-full  h-full  ">
              <div className="w-full h-[40px]  justify-between flex bg-white rounded-lg items-center px-4">
                <div className="w-full h-8 flex items-center">
                  <BiArrowBack
                    onClick={() => history.goBack()}
                    className="h-6 w-6 cursor-pointer"
                  />
                  <h2 className="font-bold text-lg ml-2">View Shelf</h2>
                </div>
                <div className="w-[140px] h-[27px] flex justify-center items-center">
                  <button
                    onClick={createShelf}
                    className="h-full bg-gray-200 rounded-[6px] px-3 "
                  >
                    Create Scale
                  </button>
                </div>
                <CreateScale
                  open={open}
                  onCloseModal={onCloseModal}
                  shelfData={data}
                />
              </div>

              <div className="w-full h-24 rounded-lg px-2 bg-white my-2">
                {/* <h2 className="px-2 font-bold">Order Summary :</h2> */}

                <div className="w-full flex justify-between px-2 pt-2 items-center">
                  <div className="w-1/4 border-l-2 border-bg-500 text-gray-500 px-2">
                    <h2>
                      {`Shelf Name: `}
                      {data?.name}
                    </h2>
                  </div>
                  <div className="w-1/4 border-l-2 border-bg-500 text-gray-500 px-2">
                    <h2>
                      {`Group Id : `} {data?.internalCode}
                    </h2>
                  </div>

                  <div className="w-1/4 border-l-2 border-bg-500 text-gray-500 px-2">
                    {`Max Scales : `}
                    <input
                      value={input?.maxScales}
                      className="focus:outline-none rounded-lg h-6 border border-gray-400 w-[40px] pl-2"
                      onChange={(e) =>
                        onChangeInput(e.target.value, "maxScales")
                      }
                    />
                  </div>

                  <div className="w-1/4 flex  space-y-1 items-center select-none justify-end mr-[8px]">
                    <button
                      onClick={calibrateShelf}
                      className="focus:outline-none bg-blue-500 border-2 active:border-[#FB7813]  ml-1 text-grey-darkest font-bold py-1 px-4 rounded inline-flex items-center"
                    >
                      <MdCompassCalibration className="w-4 h-4 text-white" />
                      <span className="mx-2 text-white">Callibrate Shelf</span>
                    </button>
                  </div>
                </div>

                {/* secondLine */}

                <div className="w-full flex justify-between px-2 pt-2 items-center ">
                  <div className="w-1/4 border-l-2 border-bg-500 text-gray-500 px-2">
                    <h2>
                      {`Rack Name:`} {data?.rack?.name}
                    </h2>
                  </div>
                  <div className="w-1/4 border-l-2 border-bg-500 text-gray-500 px-2">
                    <h2>
                      {`Retailer store:`} {data?.rack?.retailStore?.name}
                    </h2>
                  </div>
                  <div className="w-1/4 border-l-2 border-bg-500 text-gray-500 px-2">
                    <h2>
                      {`Store Code:`}{" "}
                      {data?.rack?.retailStore?.internalCode
                        ? data?.rack?.retailStore?.internalCode
                        : ""}
                    </h2>
                  </div>
                  <div className="w-1/4 flex  space-y-1 items-center select-none justify-end mr-[8px]">
                    <button
                      onClick={refweightShelf}
                      className="focus:outline-none bg-blue-500 border-2 active:border-[#FB7813] ml-1  text-grey-darkest font-bold py-1 px-4 rounded inline-flex items-center"
                    >
                      <FaWeight className="w-4 h-4 text-white" />
                      <span className="ml-[8px] mr-[4px] text-white">
                        Refweight Shelf
                      </span>
                    </button>
                  </div>
                </div>

                {/* thirdLine */}
              </div>

              <div className="w-full h-[73%] rounded-lg bg-white ">
                <div className="ag-theme-alpine w-full h-full text-left">
                  <AgGridReact
                    defaultColDef={{
                      flex: 1,
                      minWidth: 60,
                      resizable: true,
                    }}
                    suppressRowClickSelection={true}
                    rowSelection={"multiple"}
                    domLayout="autoHeight"
                    pagination={true}
                    paginationPageSize={10}
                    rowData={shelf?.data}
                  >
                    <AgGridColumn
                      headerName="Scale ID"
                      field="scaleId"
                      //minWidth={80}
                      maxWidth={200}
                      sortable={true}
                      headerCheckboxSelection={true}
                      headerCheckboxSelectionFilteredOnly={true}
                      checkboxSelection={true}
                      cellRendererFramework={(params) => (
                        <DashboardShelf params={params} />
                      )}
                    />

                    {/* <AgGridColumn
                    headerName="Product ID"
                    field="productId"
                    // minWidth={80}
                    sortable={true}
                  /> */}

                    <AgGridColumn
                      headerName="Min Unit"
                      field="minUnit"
                      // minWidth={80}
                      maxWidth={150}
                      sortable={true}
                    />

                    <AgGridColumn
                      headerName="Max Unit"
                      field="maxUnit"
                      // minWidth={80}
                      maxWidth={150}
                      sortable={true}
                    />

                    {/* <AgGridColumn
                    headerName="Retail Store"
                    // minWidth={150}
                    sortable={true}
                    cellRendererFramework={(params) => {
                      return (
                        <>
                          <p>{params?.data?.retailStore?.name}</p>
                        </>
                      );
                    }}
                  /> */}

                    <AgGridColumn
                      headerName="Status"
                      //minWidth={80}
                      maxWidth={160}
                      cellRendererFramework={(params) => (
                        <Confirm params={params} />
                      )}
                    />
                    <AgGridColumn
                      headerName="action"
                      minWidth={80}
                      width={100}
                      maxWidth={140}
                      cellRendererFramework={(params) => (
                        <>
                          <div className="flex w-24 h-10 items-center">
                            <div className="border-2 border-gray-200 rounded-l-lg p-1 ">
                              <GrView
                                className="w-5 h-5 cursor-pointer "
                                onClick={() => viewFn(params?.data)}
                              />
                            </div>
                            <div className="border-2 border-gray-200 rounded-r-lg p-1">
                              <FiEdit
                                className="w-5 h-5 cursor-pointer"
                                onClick={() => editFn(params?.data)}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    />
                    <AgGridColumn
                      headerName="Scale Operational Action"
                      minWidth={80}
                      maxWidth={700}
                      cellRendererFramework={(params) => (
                        <>
                          <div className="flex  h-10 items-center">
                            <div className="border-2 h-8 flex justify-center items-center bg-green-500 active:border-[#FB7813] border-gray-200 rounded-l-lg p-2">
                              <button
                                className=" h-5 cursor-pointer flex  justify-center items-center text-white font-bold"
                                onClick={() => enable(params?.data)}
                              >
                                Enable
                              </button>
                            </div>
                            <div className="border-2 h-8 flex justify-center items-center bg-green-500 active:border-[#FB7813] border-gray-200 p-2">
                              <button
                                className=" h-5 cursor-pointer flex text-white  justify-center items-center font-bold"
                                onClick={() => disable(params?.data)}
                              >
                                Disable
                              </button>
                            </div>
                            <div className="border-2 h-8 flex justify-center items-center bg-green-500 active:border-[#FB7813] border-gray-200 p-2">
                              <button
                                className="h-5 cursor-pointer text-white flex justify-center  items-center font-bold"
                                onClick={() => calibrate(params?.data)}
                              >
                                Callibrate
                              </button>
                            </div>
                            <div className="border-2 h-8 flex justify-center items-center bg-green-500 active:border-[#FB7813] border-gray-200 p-2">
                              <button
                                className="h-5 cursor-pointer flex justify-center text-white items-center font-bold"
                                onClick={() => refweight(params?.data)}
                              >
                                Refweight
                              </button>
                            </div>
                            <div className="border-2 h-8 flex justify-center items-center bg-green-500 active:border-[#FB7813] border-gray-200 rounded-r-lg p-2">
                              <button
                                className="h-5 text-white cursor-pointer flex justify-center  items-center font-bold"
                                onClick={() => ping(params?.data)}
                              >
                                Ping
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    />
                  </AgGridReact>
                </div>
                <ViewScale
                  open={view}
                  onCloseModal={onCloseView}
                  data={viewData}
                />

                <EditScale
                  open={edit}
                  onCloseModal={onCloseEdit}
                  data={editData}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ShelvesView;
