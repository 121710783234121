import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {useHistory} from "react-router-dom";

export const usePostGet = (getName) => {
  const history = useHistory()
 
  const queryClient = useQueryClient();

  return useMutation(
    async ({url, data}) => 
      await axios({
        method: "POST",
        url: url,      
        headers: {
          "Content-Type": "application/json",
           Authorization:  `Bearer ${localStorage.getItem("user-info")}`  
        },
        data: data
      }),
    {

      xonSuccess: (newEntry) => {
        // queryCache.refetchQueries("sightings");
        // console.log("fullfillment data: ---> ", newEntry)
        queryClient.setQueryData(getName, (prev) => [...prev, newEntry]);
      },
      onMutate: async newTodo => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries([getName, newTodo.id])
    
        // Snapshot the previous value
        const previousTodo = queryClient.getQueryData([getName, newTodo.id])
    
        // Optimistically update to the new value
        queryClient.setQueryData([getName, newTodo.id], newTodo)
    
        // Return a context with the previous and new todo
        return { previousTodo, newTodo }
      },
      // If the mutation fails, use the context we returned above
      onError: (err, newTodo, context) => {
        queryClient.setQueryData(
          [getName, context.newTodo.id],
          context.previousTodo
        )
        if (err.message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          toast.error(err.message);
        }
      },
      // Always refetch after error or success:
      onSettled: newTodo => {
        queryClient.invalidateQueries([getName, newTodo.id])
      },
    }
    
  );
};
