import React, { useState, useEffect } from "react";
import ExitImages from "../../../shopperActivity/dashboard/ExitImages";
import NoImage from "../../../../../../../Images/noImage.jpg";
import ShopperThumbnail from "../../../../../../../components/StoreOpenCardThumbnail";

function SelectTabEvents({ data, index, onSelect, select, onUnSelect, name, idName, assignedData }) {
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const people = [];

    if (data?.[name]?.length > 0) {
      for (let i = 0; i < 3; i++) {
        people.push(data?.[name][i] ? data?.[name][i] : null);
      }
    }
    if (name === 'videoPaths') {
      setVideos(people);
    } else if (name === 'imagePaths') {
      setImages(people);
    }
  }, [data]);

  return (
    <>
      <div key={index} className={`flex h-40 w-full space-x-4`}>
        <>
          {data ? (
            <>
              {name === 'imagePaths' ? images?.length > 0 ? (
                images?.map((image, index) => (
                  <ExitImages url={image?.imageUrl} index={index} through="openEntry" />
                ))
              ) : (
                <img src={NoImage} alt="" className="w-84 h-44" />
              ) : videos?.length > 0 ? (
                videos?.map((videoEvent, index) => videoEvent !== null ? (
                  <ShopperThumbnail
                    imageUrl={videoEvent?.thumbnail}
                    videoUrl={videoEvent?.videoUrl}
                    index={index}
                  />
                ) : <></>)
              ) : (
                <img src={NoImage} alt="" className="w-84 h-44" />
              )}
            </>
          ) : (
            <div className="w-full flex items-center justify-center font-bold">
              No Events Available
            </div>
          )}
        </>

        {/* {data && (
          <div className="w-28 h-45 flex flex-col justify-center  space-y-2">
            <button
              onClick={() => onSelect(data)}
              className={`${
                ((select?.[idName] === data?.[idName]) || (assignedData === data?.[idName]) )
                  ? "bg-gray-500"
                  : "bg-green-400"
              } w-24 rounded-full h-10 focus:outline-none text-white font-bold`}
            >
              {((select?.[idName] === data?.[idName]) || (assignedData === data?.[idName]) ) ? "Assigned" : "Assign"}
            </button>
            {((select?.[idName] === data?.[idName]) || (assignedData === data?.[idName]) ) && (
              <button
                className="rounded-full w-24 h-10 button-style font-bold"
                onClick={() => {onSelect(""); onUnSelect(data);}}
              >
                Un-Assign
              </button>
            )}
          </div>
        )} */}
      </div>
    </>
  );
}

export default SelectTabEvents;
