import React, { useState, useMemo, useEffect } from "react";
import { IoIosClose } from "react-icons/io";
import { TextField2 } from "../../../../../../../validation/TextField2";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { BiArrowBack } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { useGet } from "../../../../../../../../api/useGet";
import { useAlert } from "react-alert";
import Select from "react-select";
import moment from "moment";
import axios from "axios";

function PriceSummary(props) {
  const { open, onCloseModal, priceData, data } = props;
  const history = useHistory();
  const alert = useAlert();

  const [priceSummary, setPriceSummary] = useState();

  useEffect(() => {
    if (priceData?.id) {
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/products/${priceData?.id}/price-history`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      }).then((res) => {
        setPriceSummary(res);
      });
    }
  }, [priceData]);

  return (
    <>
      {open && (
        <div className="bg-black bg-opacity-80 absolute inset-0 z-10 flex items-center justify-center">
          <div
            className="w-3/5 h-4/5 shadow-lg  bg-gray-100 "
            style={{ borderRadius: 20 }}
          >
            <div
              onClick={onCloseModal}
              className="absolute mt-4 ml-4 cursor-pointer z-10 shadow-lg w-8 h-8 justify-center items-center flex rounded-full bg-white"
            >
              <IoIosClose onClick={onCloseModal} className="h-8 w-8" />
            </div>

            {priceSummary?.data ? (
              <div className="w-full h-full px-4  py-16 rounded-l-lg">
                <div className="w-full h-8 flex items-center text-left pl-1">
                  <div className="w-full flex justify-between pt-3 pb-4">
                    <h2 className=" text-md ">Price History</h2>

                    {/* <h2 className=" text-md  ">
                      <span>Status :</span>{" "}
                      <span className="normal-case text-blue-500">
                        {sessionCart?.status}
                      </span>
                    </h2> */}
                  </div>

                  {/* <div className="w-full flex justify-end ">
                  <div className="flex cursor-pointer mr-2">
                    <BsEyeFill className="w-6 h-6 text-blue-400" />
                    <h2 className=" text-md ml-1">Review</h2>
                  </div>

                  <div className="flex cursor-pointer">
                    <FiEdit className="w-5 h-5 text-blue-400" />
                    <h2 className=" text-md mx-0.5">Edit</h2>
                  </div>
                </div> */}
                </div>

                {/* sessionCart */}
                <div className="flex justify-between">
                  <div className="w-3/10">
                    <p>{`Effective From Date`}</p>
                  </div>
                  <div className="w-3/10">
                    <p>{`Effective To Date`}</p>
                  </div>
                  <div className="w-32">
                    <p>{`Purchase Price`}</p>
                  </div>
                  <div className="w-24">
                    <p>{`Sell Price`}</p>
                  </div>
                  <div className="w-16">
                    <p>{`Status`}</p>
                  </div>
                </div>

                {priceSummary?.data?.map((item, index) => {
                  return (
                    <div className="w-full h-auto mb-1 py-1  border-t-2  border-gray-900 flex  justify-between items-center">
                      <div className="w-3/10">
                        <p>
                          {item?.effectiveFromDate
                            ? moment(item?.effectiveFromDate).format(
                                "MMMM Do YYYY h:mm A"
                              )
                            : null}
                        </p>
                      </div>
                      <div className="w-3/10">
                        <p>
                          {item?.effectiveToDate
                            ? moment(item?.effectiveToDate).format(
                                "MMMM Do YYYY h:mm A"
                              )
                            : null}
                        </p>
                      </div>
                      <div className="w-32">
                        <p className="ml-9">
                          {parseFloat(item?.purchasePrice).toFixed(2)}
                        </p>
                      </div>
                      <div className="w-24">
                        <p className="ml-5">
                          {parseFloat(item?.sellPrice).toFixed(2)}
                        </p>
                      </div>
                      <div className="w-16">
                        <p>{item?.status}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="w-full h-full flex justify-center items-center">
                No Data Found
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default PriceSummary;
