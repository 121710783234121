import React, { useState, useEffect } from "react";
import ModalImg from "../../../../../ModalImg";
import { BsCheckAll } from "react-icons/bs";
import { BiErrorCircle } from "react-icons/bi";
import { set } from "date-fns";

const findMaxRefundQty = (arr, id) => {
  return arr?.refundOrderItems?.find((ele) => ele?.orderItemId === id);
};

const ProductItem = ({
  data,
  item,
  index,
  partial,
  partialSelect,
  refundType,
  onChangeOrderItems,
  path,
  maxAllowedRefunded,
}) => {
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [input, setInput] = useState({
    refundQuantity: "",
    comments: "",
  });

  const onChangeInput = (value, id, name) => {
    setInput({ ...input, [name]: value });
    onChangeOrderItems(value, id, name);
  };

  // const itemCheck = (id) => {
  //   const find = partial?.items?.find(function (ele) {
  //     return ele.scaleIdentifier === id;
  //   });
  //   return find?.parentScaleIdentifier;
  // };

  useEffect(() => {
    const refundedQty = findMaxRefundQty(
      maxAllowedRefunded,
      item?.id
    )?.refundQuantity;
    //console.log("refund: ", refundedQty)
    setInput({ ...input, ["refundQuantity"]: refundedQty ? refundedQty : 0 });
  }, [item?.id]);

  return (
    <div
      key={index}
      onClick={() => refundType === "PARTIAL" && partialSelect(item)}
      className="w-full rounded-lg bg-white mb-2 flex items-center h-20  px-4  cursor-pointer"
    >
      <div className="w-32">
        {item?.imageUrl ? (
          <img
            onClick={onOpenModal}
            src={item?.imageUrl}
            alt={item?.productName}
            className="w-20 h-20 object-fill cursor-pointer rounded-lg p-1 bg-gray-200"
          />
        ) : null}
        <ModalImg
          open={open}
          onCloseModal={onCloseModal}
          img={item?.imageUrl}
        />
      </div>

      <div className="w-full h-full items-center flex justify-between border-b-2 border-gray-200 ml-10">

        <div className="w-1/5">
          <p>{item?.productName}</p>
          <p>{item?.depositAmount > 0 && (
            "Deposit"
          )}</p>
        </div>

        <div className="w-1/5 flex flex-col justify-center items-center text-center">
          <p className="w-[4px]">{item?.taxType}</p>
          <p className="w-[4px]">{item?.depositAmount > 0 && (
            `${item?.taxType}*`
          )}</p>
        </div>

        <div className="w-1/5 flex flex-col justify-center text-center">
          <p>
            {`${item?.formattedUnitCostCurrency}/Piece`}
          </p>
          <p>{item?.depositAmount > 0 && (
             <>{`${item?.formattedDepositAmountCurrency}/Piece`}</>
          )}</p>
        </div>
        <div className="w-1/5 flex flex-col justify-center text-center">
          <p>{item?.quantity}</p>
          <p>{item?.depositAmount > 0 && (
            <>{item?.quantity}</>
          )}</p>
        </div>
        <div className="w-1/5 flex flex-col justify-center text-center">
          <p className="ml-4">
           {item?.formattedItemCostCurrency}
          </p >
          <p className="ml-4">{item?.depositAmount > 0 && (
            <>{item?.formattedDepositCostCurrency}</>
          )}</p>
        </div>

        <div className="absolute right-20">
          {partial?.items.includes(item?.id) && refundType === "PARTIAL" && (
            <BsCheckAll size={20} color="green" />
          )}
        </div>

      </div>
    </div>
  );
};

export default ProductItem;
