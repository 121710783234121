import React, { useState, useMemo } from "react";
import { IoIosClose } from "react-icons/io";
import * as Yup from "yup";
import { BiArrowBack } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { usePost } from "../../../../../../api/usePost";
import { useAlert } from "react-alert";
import Select from "react-select";

let validate;
validate = Yup.object({
  name: Yup.string().required("Required"),
  internalCode: Yup.string().matches(
    /^(?=.*[A-Z])(?=.*[0-9])[A-Z0-9]*$/,
    "not have any special or lower characters"
  ),
  maxScales: Yup.number(),
});

const customStyles = {
  control: (base) => ({
    ...base,
    height: 55,
    minHeight: 35,
    backgroundColor: "#e5e7eb",
    zIndex: 50,
    position: "relative",
    width: "100%",
  }),
};

function ViewShelf(props) {
  const { open, onCloseModal, data } = props;
  const history = useHistory();
  const alert = useAlert();
  const [title, setTitle] = useState("Shelf");
  const [status, setStatus] = useState(null);
  const rootId = parseInt(
    JSON.parse(localStorage.getItem("dashboard-data"))?.id
  );

  const options = [
    { value: "INSTALLED", label: "INSTALLED" },
    { value: "LIVE", label: "LIVE" },
    { value: "DAMAGED", label: "DAMAGED" },
  ];

  const changeHandlerStaus = (value) => {
    setStatus(value);
  };

  const createShelf = usePost(
    `${process.env.REACT_APP_BASE_URL}/shelves`,
    "shelvesList"
  );

  console.log("datafgfd: ", data?.rack);

  return (
    <>
      {open && (
        <div className="bg-black bg-opacity-80 absolute inset-0 z-10 flex items-center justify-center">
          <div
            className="w-96 h-7/10 shadow-lg  bg-white"
            style={{ borderRadius: 20 }}
          >
            <div
              onClick={onCloseModal}
              className="absolute mt-4 ml-4 cursor-pointer z-10 shadow-lg w-8 h-8 justify-center items-center flex rounded-full bg-gray-200"
            >
              <IoIosClose onClick={onCloseModal} className="h-8 w-8" />
            </div>

            <div className="w-full h-96">
              <div class="grid h-full  grid-cols-1 gap-x-12 mt-12 p-4  ">
                <table className="table-auto rounded-lg border-collapse border  w-full h-8 ">
                  <tbody className="text-left">
                    <tr>
                      <th className="w-32 border border-gray-300 bg-gray-200 px-2">
                        Shelf Name
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        {data?.name}
                      </th>
                    </tr>
                    <tr>
                      <th className="w-40 border border-gray-300 bg-gray-200 px-2">
                        Shelf ID :
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        {data?.internalCode}
                      </th>
                    </tr>

                    <tr>
                      <th className="w-40 border border-gray-300 bg-gray-200 px-2">
                        Position ID :
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        {data?.position === null ? 0 : data.position}
                      </th>
                    </tr>
                    <tr>
                      <th className="w-40 border border-gray-300 bg-gray-200 px-2">
                        Max Scales :
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        {data?.maxScales === null ? 0 : data.maxScales}
                      </th>
                    </tr>
                    <tr>
                      <th className="w-40 border border-gray-300 bg-gray-200 px-2">
                        Status :
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        {data?.status}
                      </th>
                    </tr>
                  </tbody>
                </table>

                <h2 className="mt-4">Rack</h2>

                <table className="table-auto rounded-lg border-collapse border  w-full h-8 ">
                  <tbody className="text-left">
                    <tr>
                      <th className="w-32 border border-gray-300 bg-gray-200 px-2">
                        Rack Name
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        {data?.rack?.name}
                      </th>
                    </tr>
                    <tr>
                      <th className="w-40 border border-gray-300 bg-gray-200 px-2">
                        Rack ID :
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        {data?.rack?.internalCode}
                      </th>
                    </tr>

                    <tr>
                      <th className="w-40 border border-gray-300 bg-gray-200 px-2">
                        Position ID :
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        {data?.rack?.locationRef}
                      </th>
                    </tr>
                    <tr>
                      <th className="w-40 border border-gray-300 bg-gray-200 px-2">
                        Max Shelves :
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        {data?.rack?.maxShelves === null
                          ? 0
                          : data?.rack?.maxShelves}
                      </th>
                    </tr>
                    <tr>
                      <th className="w-40 border border-gray-300 bg-gray-200 px-2">
                        Status :
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        {data?.rack?.status}
                      </th>
                    </tr>
                    {data?.rack?.retailer !== null &&
                    data?.rack?.retailer !== undefined ? (
                      <tr>
                        <th className="w-40 border border-gray-300 bg-gray-200 px-2">
                          Retailer Name:
                        </th>
                        <th className="border border-gray-300 px-2 text-gray-500">
                          {data?.rack?.retailer}
                        </th>
                      </tr>
                    ) : null}
                    <tr>
                      <th className="w-40 border border-gray-300 bg-gray-200 px-2">
                        Retailerstore Name:
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        {data?.rack?.retailStore?.name}
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ViewShelf;
