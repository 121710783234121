import React, { useState, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "react-toggle/style.css";
import Select from "react-select";
import DashboardRoute from "./DashboardRoute";
import NumberFormat from "react-number-format";
import Confirm from "./Confirm";
import Action from "./Action";
import Address from "./Address";
import { useGet } from "../../../../../../../api/useGet";
import axios from "axios";
import FadeLoader from "react-spinners/FadeLoader";
import MerchantDetails from "./MerchantDetails";
import { BiSearchAlt } from "react-icons/bi";
import { FcMindMap } from "react-icons/fc";
import { useHistory, useLocation } from "react-router-dom";
import SearchData from "../../../../../../../components/SearchData";
import { toast } from "react-toastify";

function All({ isLoading, error, data, url, viewUrl, editUrl, sortedData }) {
  let [loading, setLoading] = useState(false);
  const history = useHistory();
  const [shelvesData, setShelvesData] = useState(null);

  const [input, setInput] = useState({
    rackSearch: "",
    retailerStore: "",
  });
  const [filteredData, setFilteredData] = useState(null);
  const [searchData, setSearchData] = useState("");

  const searchDataFn = (name, data) => {
    setSearchData(data?.id);
    setInput({ ...input, [name]: data?.name });
    setFilteredData(null);
  };

  const onChangeInput = (e, name) => {
    const value = e.target.value;
    setInput({ ...input, [name]: value });

    if (name === "retailerStore") {
      setLoading(true);
      axios({
        method: "GET",
        url: `${
          process.env.REACT_APP_BASE_URL
        }/_search/retail-stores?name.contains=${value.toLowerCase()}*`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      })
        .then((res) => {
          setLoading(false);
          setFilteredData(res?.data);
        })
        .catch(({ message }) => {
          if (message === "Request failed with status code 401") {
            history.push("/login");
          } else {
            toast.error(message);
          }
        });
    } else {
      if (searchData) {
        axios({
          url: `${
            process.env.REACT_APP_BASE_URL
          }/_search/shelves?retailStoreId.equals=${searchData}&rackInternalCode.equals=${value?.toLowerCase()}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("user-info")}`,
          },
        }).then((res) => {
          setShelvesData(res?.data);
        });
      }
    }
  };

  const fullfillment = (val) => {
    localStorage.setItem("rackData", JSON.stringify(val));
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}/scales?groupId.equals=${
        val?.internalCode
      }&rootOrgId.equals=${parseInt(
        JSON.parse(localStorage.getItem("dashboard-data"))?.id
      )}&retailStoreId.equals=${
        val?.retailStoreId
      }&scaleIdentifier=true&sort=column,asc`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
    }).then((res) => {
      if (res?.data?.length === 0) {
        alert.show("Not Created");
      } else {
        const data = {
          shelfId: val?.id,
          rackId: val?.rack?.id,
          internalCode: val?.internalCode,
          retailStoreId: val?.retailStoreId,
        };

        localStorage.setItem("scales", JSON.stringify(res?.data));
        localStorage.setItem("fullfillment", JSON.stringify(data));
        history.push("/admin/merchant/dashboard/rack/fullfillment");
      }
    });
  };

  const clearAll = () => {
    setInput({
      rackSearch: "",
      retailerStore: "",
    });
    setSearchData("");
    setShelvesData(null);
  };

  return (
    <div className=" w-full h_das_table">
      <div
        className={
          loading
            ? "bg-black bg-opacity-0 absolute inset-0 z-10 flex items-center justify-center"
            : null
        }
      >
        <FadeLoader className="absolute " loading={loading} size={150} />
      </div>

      <div className="flex h-10 w-full items-center my-2 justify-between py-7 px-4">
        <div className="w-4/5 flex justify-start">
          <div className="w-72 flex justify-end items-center">
            <SearchData
              name="retailerStore"
              label={"Search Retailer Store"}
              input={input}
              onChangeInput={onChangeInput}
              filteredData={filteredData}
              searchDataFn={searchDataFn}
              loading={loading}
            />
            <BiSearchAlt className="w-12  h-5 mr-4 absolute   text-blue-500" />
          </div>
          {searchData && (
            <div className="w-96 flex justify-end items-center ml-8">
              <input
                onChange={(e) => onChangeInput(e, "rackSearch")}
                className="w-full focus:outline-none py-1.5 px-2 border-2 border-gray-200 rounded-lg"
                placeholder="Search Rack"
                value={input?.rackSearch}
              />
              <BiSearchAlt className="w-12 h-5 absolute  text-blue-500" />
            </div>
          )}
        </div>

        <button
          onClick={clearAll}
          className="bg-green-600 py-1.5 px-5 mr-7 rounded text-white focus:outline-none"
        >
          Clear filters
        </button>
      </div>

      <div className="ag-theme-alpine  w-full h-full text-left">
        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            resizable: true,
          }}
          suppressRowClickSelection={true}
          rowSelection={"multiple"}
          pagination={true}
          paginationPageSize={10}
          rowData={shelvesData ? shelvesData : data}
        >
          <AgGridColumn
            headerName="Shelf Name"
            field="name"
            minWidth={160}
            sortable={true}
            headerCheckboxSelection={true}
            headerCheckboxSelectionFilteredOnly={true}
            checkboxSelection={true}
            cellRendererFramework={(params) => (
              <DashboardRoute params={params} />
            )}
          />

          <AgGridColumn
            headerName="Shelf ID"
            field="internalCode"
            minWidth={80}
            sortable={true}
          />

          <AgGridColumn
            headerName="Max Scales"
            field="maxScales"
            minWidth={80}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <>
                  <p className="ml-8">
                    {params?.data?.maxScales === null
                      ? 0
                      : params.data?.maxScales}
                  </p>
                </>
              );
            }}
          />

          {/* <AgGridColumn
            headerName="Retail Store"
            field="retailStore"
            minWidth={170}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <>
                  <p>{params?.data?.rack?.retailStore?.name}</p>
                </>
              );
            }}
          />

          <AgGridColumn
            headerName="Retail Address"
            field="retailStore"
            minWidth={300}
            cellRendererFramework={(params) => <Address params={params} />}
          /> */}

          <AgGridColumn
            field="status"
            minWidth={100}
            cellRendererFramework={(params) => <Confirm params={params} />}
          />

          <AgGridColumn
            headerName="Fullfillment"
            minWidth={100}
            cellRendererFramework={(params) => (
              <>
                <div className="ml-4 rounded-lg p-1 ">
                  <FcMindMap
                    className="w-5 h-5 cursor-pointer "
                    onClick={() => fullfillment(params?.data)}
                  />
                </div>
              </>
            )}
          />
        </AgGridReact>
      </div>
    </div>
  );
}

export default All;
