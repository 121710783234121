import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { FaStop, FaEdit, FaCopy, FaShareAlt, FaFileCsv } from "react-icons/fa";
import Confirm from "./Confirm";

function ScheduleView() {
  const [status, setStatus] = useState(false);

  const statusChecked = (e) => {
    setStatus(e.target.checked);
  };
  const {
    state: { data, initialValues },
  } = useLocation();
  const history = useHistory();

  const edit = () => {
    history.push({
      pathname: "/admin/merchant/dashboard/productManagement/offer/edit",
      state: { initialValues: initialValues, data: data },
    });
  };

  return (
    <>
      <div className="w-full h-screen text-left select-none flex">
        <div className="w-full h-full flex flex-col justify-center items-center bg-gray-200">
          <div className="w-full h-12 flex bg-gray-100  items-center">
            <BiArrowBack
              onClick={() => history.goBack()}
              className="h-6 w-6 cursor-pointer ml-5"
            />
            {/* <h2 className="font-bold px-6 text-xl">{data?.name}</h2> */}
          </div>

          <div className="w-full h-full flex p-2">
            <div className="w-7/10 h-full bg-white rounded-lg p-2">
              <div className="w-full border-b-2">
                <h2 className="text-black text-bold text-lg select-none">
                  Information
                </h2>
              </div>

              {/* <div className="flex h-20 mt-1 flex-wrap space-y-1 items-center select-none ">
                <button
                  onClick={edit}
                  className="focus:outline-none bg-gray-200 border-2 border-black ml-1 hover:bg-grey text-grey-darkest font-bold py-1 px-4 rounded inline-flex items-center"
                >
                  <FaEdit className="w-4 h-4" />
                  <span className="mx-2">Edit</span>
                </button>

                <Confirm data={data} />
              </div> */}

              <div className="h-[80vh] mt-6 scrollbar-thin scrollbar-thumb-dash scrollbar-track-gray-100">
                <div className="py-1 px-4">
                  <div className="grid grid-cols-4 gap-4">
                    <div className="">Campaign Name : </div>
                    <div className="">{data?.campaignName}</div>
                    <div className="">Campaign Type : </div>
                    <div className="">{data?.campaignType}</div>
                  </div>
                </div>

                <div className="border-t-2 border-gray-200 py-1 px-4">
                  <div className="grid grid-cols-4 gap-4">
                    <div className="">product Name : </div>
                    <div className="">{data?.productName}</div>
                    <div className="">Product Barcode :</div>
                    <div className="">{data?.barCode}</div>
                  </div>
                </div>

                <div className="border-t-2 border-gray-200 py-1 px-4">
                  <div className="grid grid-cols-4 gap-4">
                    <div className="">category : </div>
                    <div className="">{data?.category}</div>
                    <div className="">Discount Percent :</div>
                    <div className="">{`${data?.discountPercent}`}</div>
                  </div>
                </div>

                <div className="border-t-2 border-gray-200 py-1 px-4">
                  <div className="grid grid-cols-4 gap-4">
                    <div className="">Start Date : </div>
                    <div className="">{data?.startDate}</div>
                    <div className="">End Date :</div>
                    <div className="">{data?.endDate}</div>
                  </div>
                </div>

                <div className="w-full flex justify-start px-4 my-4">
                  <p className="font-bold">All schedule offers : </p>
                </div>

                {data?.priceEngineSchedules?.map((el) => {
                  return (
                    <div className="border-t-2 border-gray-200 py-1 space-y-2 px-4">

                      <div className="grid grid-cols-1 gap-4">
                        <div className="flex space-x-24">
                          <div>Applicable Days</div>
                          <div className="flex space-x-2">
                            {el?.applicableDays?.map((ele) => (
                              <p>{`${ele},`}</p>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-cols-4 gap-4">
                        <div className="">Start Time : </div>
                        <div className="">{el?.startTime}</div>
                        <div className="">End Time : </div>
                        <div className="">{el?.endTime}</div>
                      </div>

                    </div>
                  );
                })}
              </div>
            </div>

            <div className="w-3/10 h-full ml-2 bg-white rounded-lg p-2">
              <div className="w-full border-b-2">
                <h2 className="text-black text-bold text-lg">Log</h2>
              </div>

              <div className="flex"></div>

              <div></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ScheduleView;
