import React, { useState, useEffect, Fragment } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useHistory, useLocation } from "react-router-dom";
import SelectEvent from "./SelectEvent";
import { useGet } from "../../../../api/useGet";
import axios from "axios";
import { useAlert } from "react-alert";
import EntryImages from "./EntryImages";
import StoreOpenCardThumbnail from "./StoreOpenCardThumbnail";
import NoVideo from "../../../../Images/novideo.jpg";
import NoImage from "../../../../Images/noImage.jpg";
import { getTime } from "../../../../logic/useGetTime";
import ManualExit from "../../../../components/ManualExit";
import ShopperThumbnail from "../../../../components/StoreOpenCardThumbnail";
import { IoMdRefresh } from "react-icons/io";
import { Dialog, Transition, Tab } from "@headlessui/react";
import { IoIosClose } from "react-icons/io";
import { toast } from "react-toastify";

function MatchEvent() {
  const history = useHistory();
  const alert = useAlert();

  let [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState("suggested");

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const [input, setInput] = useState({
    note: "",
  });

  const {
    state: { data: exit },
  } = useLocation();
  const [exitImages, setExitImages] = useState([]);
  const [shopperList, setShopperList] = useState([]);

  //const time = moment(exit?.exitTime).subtract(120, "minutes");

  // const url = `${
  //         process.env.REACT_APP_STORE_URL
  //       }/user-exit-activities?exitTime.greaterThanOrEqual=${moment(time)
  //         .utc()
  //         .format()}&exitTime.lesserThanOrEqual=${
  //         exit?.exitTime
  //       }&status.equals=OPEN&sort=entryTime,desc`;

  const url = `${process.env.REACT_APP_STORE_URL}/user-activities?sort=entryTime,desc&eventStatus.in=AUTHORIZED,TRACKED&retailStoreCode.equals=${exit?.retailStoreCode}&size=10&page=0`;

  const { data: userEntries } = useGet(url, "shopperList");

  const { data: exitData } = useGet(
    `${process.env.REACT_APP_STORE_URL}/user-exit-activities/${exit?.id}`,
    "exitImages"
  );

  useEffect(() => {
    if (exitData) {
      setShopperList(exitData.suggestedUserActivities);
    }
  }, [exitData]);

  const [select, setSelect] = useState();

  const onChangeTextArea = (e, name) => {
    setInput({ ...input, [name]: e.target.value });
  };

  const goNext = () => {
    if (select) {
      const id = select?.id;

      const payload = {
        id,
        exitTime: exit?.exitTime,
        userActivityId: select.userActivityId ? select.userActivityId : id,
        status: "OPEN",
        retailStoreCode: exit?.retailStoreCode,
      };

      axios({
        method: "PUT",
        url: `${process.env.REACT_APP_STORE_URL}/user-exit-activities/${id}/assign-user`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
        data: payload,
      })
        .then((res) => {
          history.push({
            pathname: "/admin/match/openEvents",
            state: {
              data: {
                entryTime: select?.entryTime,
                checkoutDate: exit?.exitTime,
                storeCode: exit?.retailStoreCode,
                sessionRef: select?.cartRef,
                rootOrgId: exit?.rootOrgId,
              },
            },
          });
        })
        .catch(({ message }) => {
          if (message === "Request failed with status code 401") {
            history.push("/login");
          } else {
            toast.error(message);
          }
        });
    } else {
      alert.error(<div className="w-96">Match the correct event.</div>);
    }
  };

  useEffect(() => {
    const people = [];

    for (let i = 0; i < 5; i++) {
      people.push(
        exitData?.userImagePaths?.[i] ? exitData?.userImagePaths?.[i] : ""
      );
    }

    setExitImages(people);
  }, [exitData]);

  const shopperLoop = exit?.shopperVideoPaths?.find(
    (ele) => ele.name === "ShopperLoop"
  );

  const trackingVideo = exit?.shopperVideoPaths?.filter(
    (ele) => ele.name !== "ShopperLoop"
  );

  const selectAction = (payload) => {
    setAction(payload);
    setSelect("");

    if (payload === "suggested") {
      setShopperList(exitData.suggestedUserActivities);
    } else {
      setShopperList(userEntries);
    }
  };

  const onSelect = (data) => {
    const { storeTimeZone, rootOrgId, id } = exitData;
    if (data !== "") {
      setSelect({
        ...data,
        rootOrgId: rootOrgId ? rootOrgId : 3201,
        storeTimeZone,
        id,
      });
    } else {
      setSelect("");
    }
  };

  return (
    <div className="h-[calc(100vh-60px)] w-full bg-gray-300">
      <div className="h-8 flex justify-between items-center mx-4">
        <BiArrowBack
          onClick={() => history.goBack()}
          className="h-6 w-6 cursor-pointer"
        />

        <h2 className="font-bold text-2xl">
          {exit?.through === "processedEntry"
            ? "User Session"
            : "Review User Session"}
        </h2>

        <IoMdRefresh
          size={28}
          className="cursor-pointer"
          onClick={() => window.location.reload()}
        />
      </div>

      <div className="w-full h-[calc(100vh-57px-35px)] flex rounded-t-xl pt-8 bg-white space-x-[10px]">
        {/* entry images */}
        <div className="w-[73%] h-full flex flex-col">
          <div className="flex w-full  justify-center items-center">
            {exitImages?.length > 0 ? (
              exitImages?.map((url, index) => (
                <EntryImages url={url} index={index} key={index} />
              ))
            ) : (
              <img src={NoImage} className="w-40 h-44" alt="entryimage" />
            )}
          </div>

          <p>
            <span className="font-bold">Exit Time : </span>{" "}
            {getTime(exit?.exitTime, exit?.storeTimeZone)}
          </p>

          <div className="mt-3 ml-4 h-[63%]">
            <div className="flex justify-between items-center mr-2 h-[50px]">
              <h2 className="font-bold text-lg">
                {exit?.through === "processedEntry"
                  ? `Matched`
                  : `Choose the matching exit`}
              </h2>

              <Tab.Group>
                <Tab.List className="bg-gray-200 rounded-full">
                  <Tab
                    onClick={() => selectAction("suggested")}
                    className={`${
                      action === "suggested" && "bg-green-500 rounded-l-full"
                    } py-1 px-3`}
                  >
                    Suggested Shopping Sessions
                  </Tab>
                  <Tab
                    onClick={() => selectAction("open")}
                    className={`${
                      action === "open" && "bg-green-500 rounded-r-full"
                    } py-1 px-2`}
                  >
                    Open Shopping Sessions
                  </Tab>
                </Tab.List>
              </Tab.Group>
            </div>

            <div className="h-[82%] w-[4/5%] my-2 pr-6 space-y-2 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
              {shopperList?.length > 0 && action === "open"
                ? shopperList?.map((rowData, index) => {
                    return (
                      <div
                        key={index}
                        className="border-b-4 border-gray-400 space-x-4"
                      >
                        <SelectEvent
                          data={rowData}
                          index={index}
                          onSelect={onSelect}
                          select={select}
                          key={index}
                          name={"userImagePaths"}
                          idName={"id"}
                        />

                        {rowData?.entryTime && (
                          <p>
                            <span className="font-bold">Exit Time : </span>
                            {getTime(
                              rowData?.entryTime,
                              rowData?.storeTimeZone
                            )}
                          </p>
                        )}
                      </div>
                    );
                  })
                : shopperList?.map((rowData, index) => {
                    return (
                      <div key={index} className="border-b-4 border-gray-400 ">
                        <SelectEvent
                          data={rowData}
                          index={index}
                          onSelect={onSelect}
                          select={select}
                          key={index}
                          name={"userActivityImagePaths"}
                          idName={"userActivityId"}
                        />

                        {rowData?.entryTime && (
                          <div className="grid grid-rows-2">
                            <div className="flex w-full justify-center">
                              <p>
                                <span className="font-bold">Entry Time : </span>
                                {getTime(
                                  rowData?.entryTime,
                                  rowData?.storeTimeZone
                                )}
                              </p>
                            </div>

                            <div className="flex w-full justify-between space-y-1">
                              <p>
                                <span className="font-bold">Login: </span>
                                {rowData?.login}
                              </p>
                              <p>
                                <span className="font-bold">Cart Ref: </span>

                                {rowData?.cartRef}
                              </p>
                              <p>
                                <span className="font-bold">Confidence : </span>
                                {`${rowData?.confidenceScore} %`}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}

              {/* video exit session links passed in modal */}

              <div className="w-full flex justify-between items-center">
                {shopperLoop ? (
                  <ShopperThumbnail
                    imageUrl={shopperLoop?.thumbnail}
                    videoUrl={shopperLoop?.videoUrl}
                    index={1}
                  />
                ) : (
                  <img src={NoVideo} className="w-64 h-48" alt="exitimage" />
                )}

                <div className="w-[58%] flex items-center mt-24 justify-end ">
                  <button
                    type="button"
                    onClick={openModal}
                    className="cursor-pointer rounded-md bg-green-500 bg-opacity-80 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                  >
                    See all session videos
                  </button>
                </div>
              </div>

              <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-black bg-opacity-60" />
                  </Transition.Child>

                  <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <Dialog.Panel className="w-full max-w-[48rem] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900 flex justify-between items-center"
                          >
                            <p>Session Videos</p>

                            <IoIosClose
                              onClick={closeModal}
                              size={28}
                              className="bg-gray-500 rounded-full bg-opacity-20 shadow-lg cursor-pointer"
                            />
                          </Dialog.Title>

                          {exit?.through === "processedEntry" && (
                            <div className="mt-6 grid grid-cols-4  gap-y-12">
                              {trackingVideo?.length > 0 ? (
                                trackingVideo?.map((ele, index) => {
                                  return (
                                    <div className="flex flex-col items-center justify-center">
                                      <StoreOpenCardThumbnail
                                        imageUrl={ele?.thumbnail}
                                        videoUrl={ele?.videoUrl}
                                        camera={ele?.name}
                                        index={index}
                                      />
                                      <p>{ele.name}</p>
                                    </div>
                                  );
                                })
                              ) : (
                                <img
                                  src={NoVideo}
                                  className="w-64 h-48"
                                  alt="exitimage"
                                />
                              )}
                            </div>
                          )}
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition>
            </div>
          </div>
        </div>

        {/* right  */}
        <div className={`w-[25.5%] h-[80%] space-y-[72px]`}>
          <div className="flex justify-end mt-5">
            {exit?.through !== "processedEntry" && (
              <ManualExit userId={exit?.id} timeZone={exit?.storeTimeZone} />
            )}
          </div>
          <div className="bg-gray-200 w-full h-full flex flex-col space-y-8 rounded-lg p-3">
            <div className="w-full flex flex-col items-start">
              <div className="w-full flex text-left text-[0.94rem]">
                <p className="w-[118px] font-bold">Store ID</p>
                <p>{` :  ${exit?.retailStoreCode}`}</p>
              </div>

              <div className="w-full flex text-left text-[0.94rem]">
                <p className="w-[118px] font-bold">Login: </p>
                <p>{` :  ${select?.login ? select.login : " - "}`}</p>
              </div>

              <div className=" w-full flex text-left text-[0.94rem]">
                <p className="w-[118px] font-bold">Cart Ref</p>
                <p>{` :  ${select?.cartRef ? select?.cartRef : " - "}`}</p>
              </div>
            </div>
            {select ? (
              <div className="w-full flex flex-col px-4 py-2 bg-white rounded justify-center items-center mt-[20px] ">
                <div className="my-2 w-full flex justify-center items-center">
                  {action === "open" ? (
                    select?.userImagePaths?.length > 0 ? (
                      <img
                        src={select?.userImagePaths[0]}
                        alt="event"
                        className="w-56 h-40 rounded"
                      />
                    ) : (
                      <img
                        src={NoImage}
                        className="w-56 h-40 rounded"
                        alt="selectimage"
                      />
                    )
                  ) : select?.userActivityImagePaths?.length > 0 ? (
                    <img
                      src={select?.userActivityImagePaths[0]}
                      alt="event"
                      className="w-56 h-40 rounded"
                    />
                  ) : (
                    <img
                      src={NoImage}
                      className="w-56 h-40 rounded"
                      alt="selectimage"
                    />
                  )}
                </div>

                <div className="flex w-[300px] justify-center mt-2 text-xs space-x-[1px]">
                  <h2 className="w-[60px] text-left">Entry Time </h2>
                  <h2 className="">{`:  ${
                    select?.entryTime
                      ? getTime(select?.entryTime, select?.storeTimeZone)
                      : " - "
                  }`}</h2>
                </div>
              </div>
            ) : (
              <div className="w-full  flex flex-col px-4 py-2 bg-white rounded">
                <p>Select One Match Entry.</p>
              </div>
            )}

            <textarea
              placeholder="Comment"
              className="rounded-lg px-4 py-2 focus:outline-none"
              value={input?.note}
              onChange={(e) => onChangeTextArea(e, "note")}
            />

            <div className="">
              <button
                onClick={goNext}
                className="bg-green-600 text-white w-24 h-8 rounded"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MatchEvent;
