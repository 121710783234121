import React from "react";
import ReactDOM from "react-dom";

import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import "react-datetime/css/react-datetime.css";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import AlertTemplate from "react-alert-template-basic";
import { transitions, positions, Provider as AlertProvider } from "react-alert";

import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "tippy.js/dist/tippy.css";
import { SearchDataProvider } from "./contexts/SearchingContext";
import { OrdersTabProvider } from "./pages/support/dashboard/pages/order/contexts/OrdersTabContex";
import { OpenEventshDataProvider } from "./pages/support/dashboard/pages/match/context/OpeneventsContext";
import { ProcessedEventshDataProvider } from "./pages/support/dashboard/pages/match/context/ProcessedEventsContext";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 30,
    },
  },
});

const options = {
  // you can also just use 'bottom center'
  position: positions.MIDDLE,
  timeout: 5000,
  offset: "30px",

  // you can also just use 'scale'
  transition: transitions.SCALE,
};

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "de"],
    fallbackLng: "en",
    debug: false,
    // Options for language detector
    detection: {
      order: ["path", "cookie", "htmlTag"],
      caches: ["cookie"],
    },
    react: { useSuspense: false },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
  });

ReactDOM.render(
  <BrowserRouter>
    <ProcessedEventshDataProvider>
      <OpenEventshDataProvider>
        <SearchDataProvider>
          <OrdersTabProvider>
            <AlertProvider template={AlertTemplate} {...options}>
              <QueryClientProvider client={queryClient}>
                <App />
                <ToastContainer />
              </QueryClientProvider>
            </AlertProvider>
          </OrdersTabProvider>
        </SearchDataProvider>
      </OpenEventshDataProvider>
    </ProcessedEventshDataProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
