import React, { useState } from "react";
import ModalImg from "./ModalImg";
import NoImage from "../../../../../../Images/noImage.jpg";

function ExitImages({ url, index, through }) {
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  return (
    <div
      key={index}
      className={`${
        through === "processedEntry" ? "w-40 h-44" : "w-36 h-44"
      }`}
    >
      {url ? (
        <img
          src={url}
          className={`${
            through === "processedEntry" ? "w-28 h-44" : "w-36 h-44"
          } rounded-lg cursor-pointer border-2 border-black`}
          onClick={onOpenModal}
        />
      ) : (
        <img
          src={NoImage}
          className={`${
            through === "processedEntry" ? "w-28 h-44" : "w-36 h-44"
          } rounded-lg cursor-pointer border-2 border-black`}
         // onClick={onOpenModal}
        />
      )}

      <p className="font-bold relative bottom-6 text-brown-400 w-full text-left pl-1">{`Image# : ${
        index + 1
      }`}</p>
      {url && <ModalImg open={open} onCloseModal={onCloseModal} img={url} />}
    </div>
  );
}

export default ExitImages;
