import React, { useState, useMemo, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import Select from "react-select";
import { usePut } from "../../../../../../../../api/usePut";
import { useGetByParams } from "../../../../../../../../api/useGetByParams";
import { useGet } from "../../../../../../../../api/useGet";
import { TextField2 } from "../../../../../../../validation/TextField2";
import { TextArea } from "../../../../../../../validation/TextArea";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ClipLoader from "react-spinners/ClipLoader";
import { boolean } from "yup/lib/locale";
import { toast } from "react-toastify";
import FadeLoader from "react-spinners/FadeLoader";
import { TextField3 } from "../../../../../../../validation/TextField3";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TextArea2 } from "../../../../../../../validation/TextArea2";
import { MdCancel } from "react-icons/md";
import { useAlert } from "react-alert";
import moment from "moment";

const customStyles = {
  control: (base) => ({
    ...base,
    height: 55,
    minHeight: 35,
  }),
};

const status = [
  { label: "Draft", value: "DRAFT" },
  { label: "Active", value: "ACTIVE" },
  { label: "Closed", value: "CLOSED" },
  { label: "Suspended", value: "SUSPENDED" },
];

let validate;
validate = Yup.object({
  name: Yup.string().required("Required"),
  voucherCode: Yup.string().required("Required"),
  description: Yup.string(),
  discountPercentage: Yup.number(),
});

function VoucherUpdate() {
  const { t } = useTranslation();
  const alert = useAlert();
  const history = useHistory();
  const {
    state: { data },
  } = useLocation();

  const [loading, setLoading] = useState(false);

  const [input, setInput] = useState({
    startDate: data?.startDate,
    endDate: data?.endDate,
  });

  const changeInputHandler = (value, name) => {
    setInput({ ...input, [name]: value });
  };

  const [select, setSelect] = useState({
    products: [],
    statusVouch: {
      value: "",
      label: "",
    },
    retailStore: "",
  });

  //post
  const { mutate: createVoucher, isSuccess } = usePut(
    `${process.env.REACT_APP_BASE_URL}/vouchers/${data?.id}`,
    "voucherList"
  );

  const { data: retailerList } = useGet(
    `${
      process.env.REACT_APP_BASE_URL
    }/retail-stores?page=0&size=10&sort=createdDate,desc&rootOrgId.equals=${parseInt(
      JSON.parse(localStorage.getItem("dashboard-data"))?.id
    )}`,
    "retailerList"
  );

  const { data: initialValues } = useGet(
    `${process.env.REACT_APP_BASE_URL}/vouchers/${data.id}`,
    "voucherDetail"
  );

  const [initialData, setInitialData] = useState();

  useEffect(() => {
    if (initialValues) {
      //setStatus(initialValues.status);     //for boolean value

      setSelect({
        statusVouch: {
          value: "",
          label: initialValues?.status ? initialValues.status : "Status",
        },
      });

      // console.log("initialValues: ", initialValues);
      // const {name, description, title, unitWeight, netWeight, referenceWeight} = initialValues //object

      setInitialData({
        description: initialValues.description,
        voucherCode: initialValues.voucherCode,
        eslBarCode: initialValues.eslBarCode,
        discountPercentage: initialValues.discountPercentage,
        name: initialValues.name,
      });
    }
  }, [initialValues]);

  const handleSelect = (action, name) => {
    setSelect({
      ...select,
      [name]: { value: action.value, label: action.label, code: action.code },
    });
  };

  // const statusChecked = (e) => {
  //   setStatus(e.target.checked);
  // };

  //for subCategory

  // useEffect(() => {
  //   axios({
  //     method: "GET",
  //     url: `${process.env.REACT_APP_BASE_URL}/categories?parentId.equals=${
  //       select?.productCategory?.value
  //     }&rootOrgId.equals=${
  //       JSON.parse(localStorage.getItem("dashboard-data"))?.rootOrgId
  //     }`,
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${localStorage.getItem("user-info")}`,
  //     },
  //   }).then((res) => {
  //     setSubCat(res?.data);
  //   });
  // }, [select?.productCategory?.value]);

  useEffect(() => {
    if (isSuccess) {
      setLoading(true);
      setTimeout(() => {
        history.goBack();
        setLoading(false);
      }, 2000);
    }
  }, [isSuccess]);

  if (loading) {
    return (
      <div className="h-full w-full flex justify-center items-center">
        <FadeLoader color="#000000" size={250} />
      </div>
    );
  }

  return (
    <>
      {initialData ? (
        <div className="w-full h-screen text-left select-none flex">
          <div className="w-full h-full flex justify-center items-center  p-4 bg-gray-200 ">
            <Formik
              initialValues={initialData}
              validationSchema={validate}
              onSubmit={async (values) => {
                if (values.name !== "" && select?.currency?.code !== "") {
                  var body = {
                    name: values.name,
                    description: values.description,
                    voucherCode: values.voucherCode,
                    discountPercentage: values.discountPercentage,
                    startDate: moment(input?.startDate).format("YYYY-MM-DD"),
                    endDate: moment(input?.endDate).format("YYYY-MM-DD"),

                    status: select.statusVouch.value
                      ? select.statusVouch.value
                      : initialValues.status,

                    storeId: {
                      id: select.referenceType.value,
                    },

                    rootOrgId: parseInt(
                      JSON.parse(localStorage.getItem("dashboard-data"))?.id
                    ),
                  };
                  await createVoucher(body);

                  if (createVoucher.isIdle === true) {
                    history.goBack();
                    alert.success(
                      <div style={{ textTransform: "none" }}>
                        Successfully Added!
                      </div>
                    );
                  } else {
                    alert.error(
                      <div style={{ textTransform: "none" }}>
                        Please Check all Fields!
                      </div>
                    );
                  }
                }
              }}
            >
              {({ handleSubmit }) => (
                <Form className="w-full mx-auto h-full bg-white rounded-lg shadow-lg border border-black ">
                  <div className="w-full h-12 flex border-b border-black justify-center items-center ">
                    <h2 className="font-bold text-xl">
                      {`${t("Edit")} ${t("Voucher")}`}
                    </h2>

                    <div className="absolute right-8">
                      <MdCancel
                        onClick={() => history.goBack()}
                        className="cursor-pointer"
                        size={28}
                      />
                    </div>
                  </div>

                  <div className="w-full h-[78vh] scrollbar-thin scrollbar-thumb-gray-100 scrollbar-track-gray-300 ">
                    <div className="w-full  px-12 py-10 space-y-4">
                      <div className=" grid grid-cols-2 gap-x-12 gap-y-6 mt-1">
                        {/*  first row   */}

                        <TextField3
                          label={t("Voucher Name")}
                          placeholder="Enter Voucher Name"
                          name="name"
                          type="text"
                        />

                        <TextField3
                          label="Voucher Code"
                          placeholder="Enter Voucher Code"
                          name="voucherCode"
                          type="text"
                        />
                      </div>

                      <div className="grid grid-cols-1 gap-y-6 mt-1">
                        <div className="flex w-full items-center select-none">
                          <TextArea2
                            label={t("description")}
                            placeholder="Enter the Voucher Description"
                            name="description"
                            type="text"
                          />
                        </div>
                      </div>

                      <div className="grid grid-cols-2 gap-x-12 gap-y-6 mt-1">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <div className="flex items-center space-x-4">
                            <label className="w-[30%]" for="Start Date">
                              Start Date
                            </label>
                            <DatePicker
                              label="Start Date"
                              mask="____/__/__"
                              value={input?.startDate}
                              onChange={(newValue) =>
                                changeInputHandler(newValue, "startDate")
                              }
                              // renderInput={(params) => (
                              //   <TextField {...params} className="w-full" />
                              // )}
                              renderInput={({
                                inputRef,
                                inputProps,
                                InputProps,
                              }) => (
                                <div className="flex justify-center items-center w-[70%] bg-[#D9D9D9] py-2 px-6">
                                  <input
                                    ref={inputRef}
                                    {...inputProps}
                                    className="focus:outline-none bg-transparent"
                                  />
                                  {InputProps?.endAdornment}
                                </div>
                              )}
                              //disableOpenPicker={true}
                            />
                          </div>

                          <div className="flex items-center space-x-4">
                            <label className="w-[30%]" for="Start Date">
                              End Date
                            </label>
                            <DatePicker
                              label="End Date"
                              mask="____/__/__"
                              value={input?.endDate}
                              onChange={(newValue) =>
                                changeInputHandler(newValue, "endDate")
                              }
                              renderInput={({
                                inputRef,
                                inputProps,
                                InputProps,
                              }) => (
                                <div className="flex justify-center items-center w-[70%] bg-[#D9D9D9] py-2 px-6">
                                  <input
                                    ref={inputRef}
                                    {...inputProps}
                                    className="focus:outline-none bg-transparent"
                                  />
                                  {InputProps?.endAdornment}
                                </div>
                              )}
                              //disableOpenPicker={true}
                            />
                          </div>
                        </LocalizationProvider>
                      </div>

                      <div className="grid grid-cols-2 gap-x-12 gap-y-6 mt-1">
                        <div className="flex items-center space-x-4">
                          <label className="w-[30%]" for="Start Date">
                            {t("Products")}
                          </label>
                          <Select
                            className="w-[70%]"
                            placeholder="Enter Product Barcode"
                            maxMenuHeight={162}
                            value={select.products}
                            isMulti
                            onChange={(value) =>
                              handleSelect(value, "products")
                            }
                            styles={{
                              control: (style) => ({
                                ...style,
                                backgroundColor: "#D9D9D9",
                              }),
                            }}
                            options={retailerList?.map((item, index) => {
                              return {
                                label: item.name,
                                value: item.id,
                              };
                            })}
                          />
                        </div>

                        <TextField3
                          label="Discount Percentage"
                          placeholder="Enter Discount Percentage"
                          name="discountPercentage"
                          type="number"
                        />
                      </div>

                      <div className="grid grid-cols-2 gap-x-12 gap-y-6 mt-1">
                        <div className="flex items-center space-x-4">
                          <label className="w-[30%]" for="Start Date">
                            {t("retailer store")}
                          </label>
                          <Select
                            className="w-[70%]"
                            placeholder="Select the Retailer Store"
                            maxMenuHeight={162}
                            value={select.retailerStore}
                            onChange={(value) =>
                              handleSelect(value, "retailerStore")
                            }
                            styles={{
                              control: (style) => ({
                                ...style,
                                backgroundColor: "#D9D9D9",
                              }),
                            }}
                            options={retailerList?.map((item, index) => {
                              return {
                                label: item.name,
                                value: item.id,
                              };
                            })}
                          />
                        </div>

                        <div className="flex items-center space-x-4">
                          <label className="w-[30%]" for="Start Date">
                            Status
                          </label>
                          <Select
                            className="w-[70%]"
                            placeholder="Select the Voucher Status"
                            maxMenuHeight={162}
                            value={select.statusVouch}
                            onChange={(value) =>
                              handleSelect(value, "statusVouch")
                            }
                            styles={{
                              control: (style) => ({
                                ...style,
                                backgroundColor: "#D9D9D9",
                              }),
                            }}
                            options={status}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-center items-center mt-6">
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        className="bg-green-600 rounded-lg px-10 py-1  focus:outline-none text-white"
                      >
                        {`${t("Edit")}`}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      ) : (
        <div className="w-full h-full flex justify-center items-center">
          <ClipLoader size={150} />
        </div>
      )}
    </>
  );
}

export default VoucherUpdate;
