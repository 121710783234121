import React, { useState, useEffect } from "react";
import ExitImages from "./ExitImages";
import NoImage from "../../../../../../Images/noImage.jpg";

function SelectEvent({ data, index, onSelect, select, name, idName }) {
  const [exitImages, setExitImages] = useState([]);

  

  useEffect(() => {
    const people = [];

    if (data?.[name]?.length > 0) {
      for (let i = 0; i < 5; i++) {
        people.push(data?.[name][i] ? data?.[name][i] : "");
      }
    }
    setExitImages(people);
  }, [data]);

  return (
    <>
      {Array.isArray(data) ? (
        <div key={index} className={`flex h-44 w-full space-x-4 `}>
          <>
            {data?.length > 0 ? (
              <>
                {exitImages?.length > 0 ? (
                  exitImages?.map((url, index) => (
                    <ExitImages url={url} index={index} through="openEntry" />
                  ))
                ) : (
                  <img src={NoImage} alt="" className="w-44 h-44" />
                )}
              </>
            ) : (
              <div className="w-full flex items-center justify-center font-bold">
                No User Exit Activity Available
              </div>
            )}
          </>

          {data?.length > 0 && (
            <div className="w-28 h-36 flex flex-col justify-center items-center">
              <button
                onClick={() => onSelect(data)}
                className={`${
                  select?.[idName] === data?.[idName]
                    ? "bg-gray-500"
                    : "bg-green-400"
                } w-24 rounded-full h-8 focus:outline-none text-white`}
              >
                {select?.[idName] === data?.[idName] ? "selected" : "select"}
              </button>

              <button
                className="focus:outline-none "
                onClick={() => onSelect("")}
              >
                Unselect
              </button>
            </div>
          )}
        </div>
      ) : (
        <div key={index} className={`flex h-40 w-full space-x-4`}>
          <>
            {data ? (
              <>
                {exitImages?.length > 0 ? (
                  exitImages?.map((url, index) => (
                    <ExitImages
                      key={index}
                      url={url}
                      index={index}
                      through="openEntry"
                    />
                  ))
                ) : (
                  <img src={NoImage} alt="" className="w-44 h-44" />
                )}
              </>
            ) : (
              <div className="w-full flex items-center justify-center font-bold">
                No User Exit Activity Available
              </div>
            )}
          </>

          {data && (
            <div className="w-28 h-36 flex flex-col justify-center items-center space-y-2">
              <button
                onClick={() => onSelect(data)}
                className={`${
                  select?.[idName] === data?.[idName]
                    ? "bg-gray-500"
                    : "bg-green-400"
                } w-24 rounded-full h-8 focus:outline-none text-white`}
              >
                {select?.[idName] === data?.[idName] ? "selected" : "select"}
              </button>
              {select?.[idName] === data?.[idName] && (
                <button
                  className="focus:outline-none "
                  onClick={() => onSelect("")}
                >
                  Unselect
                </button>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default SelectEvent;
