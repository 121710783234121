import React from "react";

const MappingTabel = ({ prod, isStockClearanceDiscount }) => {
  return (
    <>
      {prod.status !== "disabled" && (
        <tbody className="h-[75px]">
          <tr className="border-b border-gray-400">
            <td className="w-[10%] border-r border-gray-400">
              {prod.scaleIdentifier}
            </td>
            <td className="w-[10%] border-r border-gray-400">
              <div className="flex flex-wrap space-x-4  justify-center items-center">
                {prod.productInfos.map((product, index) => {
                  return (
                    <div key={index}>
                      {product.isPrimary && (
                        <div className="p-[2px]">
                          <img
                            src={product?.imageUrl || "/noImage.jpg"}
                            className="h-[100px] w-[100px] rounded "
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </td>
            <td className="w-[10%] border-r border-gray-400 ">
              <div className="w-full flex flex-col space-y-4 justify-center items-center px-[20px]">
                {prod.productInfos.length > 0 &&
                  prod.productInfos.map((product, index) => {
                    console.log(
                      "🚀 ~ prod.productInfos.map ~ product:",
                      product
                    );
                    return (
                      <div
                        className="flex items-center justify-center flex-col"
                        key={index}
                      >
                        {product.isPrimary && (
                          <p>{`${index + 1}. ${product?.productName}`}</p>
                        )}
                        {product.isPrimary && (
                          <p>
                            <span className="font-bold">BarCode:</span>{" "}
                            {` ${product?.barCode}`}
                          </p>
                        )}
                        {product.isPrimary && (
                          <p>
                            <span className="font-bold">ESL Barcode:</span>{" "}
                            {`${prod?.eslBarCode}`}
                          </p>
                        )}
                        {product.isPrimary &&
                          isStockClearanceDiscount?.length > 0 &&
                          (isStockClearanceDiscount?.[0]?.configValue?.toLowerCase() ==
                            "true" ||
                            isStockClearanceDiscount?.[0]?.configValue ==
                              true) &&
                          product?.isStockClearanceDiscount === true && (
                            <div
                              style={{
                                padding: "6px",
                                backgroundColor: "rgba( 0, 102, 0, 0.21)",
                                width: "150px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "10px",
                                marginTop: "6px",
                                marginBottom: "10px",
                                marginLeft: "10px",
                              }}
                            >
                              <img
                                src="/images/discountTag.svg"
                                alt=""
                                style={{ marginTop: "3px" }}
                              />
                              <p style={{ marginLeft: "5px" }}>Discounted</p>
                            </div>
                          )}
                      </div>
                    );
                  })}
                {prod.productInfos.length === 0 && prod.eslBarCode !== null ? (
                  <p>
                    <span className="font-bold">ESL BarCode:</span>{" "}
                    {prod?.eslBarCode}
                  </p>
                ) : (
                  <></>
                )}
              </div>
            </td>
          </tr>
        </tbody>
      )}
    </>
  );
};

export default MappingTabel;
