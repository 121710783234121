import React from "react";
import { BiArrowBack } from "react-icons/bi";
import { Link, useHistory } from "react-router-dom";

function NeedHelp() {
  const history = useHistory()
  const reset = () => {
    alert("Email sent to you.");
  };
  return (
    <div className="bg-nav w-screen h-screen flex  items-center select-none p-2">
      <div className="w-full lg:w-2/5 mx-auto h-3/5 rounded-lg bg-white shadow-lg p-4 lg:p-8 flex flex-col ">
        <div className="w-full lg:w-11/12 mx-auto ">
          <div className="flex   items-center">
            <BiArrowBack
              onClick={() => history.goBack()}
              className="h-4 w-4 lg:h-8 lg:w-8 mr-4 cursor-pointer"
            />
            <h2 className="text-lg lg:text-2xl font-bold">Forgot Email / Password</h2>
          </div>
          <div className="mx-0">
          <h2 className="mt-8 text-md lg:text-xl">
            How would you like to reset your password ?
          </h2>

          <p className="my-6 lg:my-2">
            We will send you an email with instructions on how to reset your
            password.
          </p>
          </div>
        </div>

        <form
          onSubmit={reset}
          className="w-full flex my-2 flex-col items-center justify-center"
        >
          <input
            placeholder="name@gmail.com"
            type="email"
            className="w-11/12 mx-auto rounded-full border-2 border-blue-500 px-4 py-3 focus:outline-none"
          />
          <button
            type="submit"
            className="bg-nav rounded-full px-6 py-3 mx-4 my-8 focus:outline-none "
          >
            <span className="h-full flex items-center">
              <span className="px-2 text-2xl text-white font-bold">Reset</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                fill="#fff"
                viewBox="0 0 24 24"
              >
                <path d="M16 8v-4l8 8-8 8v-4h-8.929c-9.059 0-7.134-9.521-6.335-11.418.789 2.445 2.465 3.418 5.372 3.418h9.892z" />
              </svg>
            </span>
          </button>
        </form>
      </div>
    </div>
  );
}

export default NeedHelp;
