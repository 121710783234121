import React, { useState, useEffect } from "react";
import { getTime } from "../../../../../../../logic/useGetTime";

function SelectIREvents({ data, index, onSelect, select, name, idName, storeTimeZone }) {

    return (
        <>
            {
                (
                    <div key={index} className={`flex h-20 w-full space-x-4 mt-3 ml-2`}>
                        <>
                            {data && (
                                <p>
                                    <span className="font-bold">IR Event Time: &nbsp;</span>
                                    {getTime(data?.startTime, storeTimeZone)}
                                </p>
                            )}
                        </>
                        {data && (
                            <div className="w-28 h-20 flex flex-col space-y-2">
                                <button
                                    onClick={() => onSelect(data)}
                                    className={`${
                                    select?.[idName] === data?.[idName]
                                        ? "bg-gray-500"
                                        : "bg-green-400"
                                    } w-24 rounded-full h-8 focus:outline-none text-white`}
                                >
                                    {select?.[idName] === data?.[idName] ? "selected" : "select"}
                                </button>
                                {select?.[idName] === data?.[idName] && (
                                    <button
                                    className="focus:outline-none "
                                    onClick={() => onSelect("")}
                                    >
                                    Unselect
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                )
            }
        </>
    )
};

export default SelectIREvents;