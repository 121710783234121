import React from "react";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useHistory } from "react-router-dom";

function BackArrowButton({ onClick, sx }) {
  const history = useHistory();

  const handleBack = () => history.goBack();
  return (
    <IconButton
      sx={{
        "&:hover": {
          backgroundColor: "transparent", // Remove the hover effect
        },
      }}
      onClick={onClick ? onClick : handleBack}
    >
      <ArrowBackIosIcon sx={{ color: "black" }} />
    </IconButton>
  );
}

export default BackArrowButton;
