import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TextField2 } from "../../../../../../../validation/TextField2";
import { useHistory } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import Select from "react-select";
import { useAlert } from "react-alert";
import { usePost } from "../../../../../../../../api/usePost";
import Toggle from "react-toggle";

const login = [{ value: "system", label: "system" }];

const authorities = [
  { value: "ROLE_ADMIN", label: "ROLE_ADMIN" },
  { value: "system_user", label: "system_user" },
];

let validate;

validate = Yup.object({
  eslBarCode: Yup.number().required("Required"),
});

function CreateEsl() {
  const [title, setTitle] = useState("Employee");
  const alert = useAlert();
  const history = useHistory();
  const [gender, setGender] = useState("");
  const [status, setStatus] = useState(false);

  const [select, setSelect] = useState({
    login: { value: "", label: "Select System" },
    authoritiesName: { value: "", label: "Select Authorizaties" },
  });

  const handleSelect = (action, e) => {
    const name = e.name;
    const value = action.value;
    setSelect({ ...select, [name]: { value: value, label: value } });
  };

  const statusChecked = (e) => {
    setStatus(e.target.checked);
  };

  const genderType = (e) => {
    setGender(e.target.value);
  };

  const createEmolyee = usePost(
    `${process.env.REACT_APP_BASE_URL}/esl`,
    "employeesList"
  );

  return (
    <>
      <div className="w-full h-full flex text-left select-none">
        <div className="w-full h-full flex justify-center items-center bg-gray-200 ">
          <Formik
            initialValues={{
              phone: "",
              firstName: "",
              lastName: "",
              email: "",
              password: "",
              confirmPassword: "",
            }}
            validationSchema={validate}
            onSubmit={(values) => {
              if (values.name !== "" && values.email !== "") {
                try {
                  var body = {
                    userInfo: {
                      phone: values.phone,
                      gender: gender,
                      //rootOrgId: parseInt(JSON.parse(localStorage.getItem("dashboard-data"))?.id),
                      rootOrgId: null,
                    },
                    login: select.login.value,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    password: values.password,
                    confirmPassword: values.confirmPassword,
                    activated: status,
                    authoritiesName: select.authoritiesName.value,
                    authorities: [select.authoritiesName.value],
                  };
                  createEmolyee.mutate(body);

                  //console.log("res: ");

                  if (createEmolyee.isError === false) {
                    //history.goBack();
                    alert.success(
                      <div style={{ textTransform: "none" }}>
                        Successfully Created!
                      </div>
                    );
                  } else if (
                    createEmolyee?.error?.response?.data?.errorKey ===
                    "userexists"
                  ) {
                    alert.error(
                      <div style={{ textTransform: "none" }}>
                        {createEmolyee?.error?.response?.data?.title}
                      </div>
                    );
                  } else {
                    alert.error(
                      <div style={{ textTransform: "none" }}>
                        Please Check all Fields!
                      </div>
                    );
                  }
                } catch (error) {
                  alert.error(
                    <div style={{ textTransform: "none" }}>
                      you are offline!
                    </div>
                  );
                }
              }
            }}
          >
            {() => (
              <Form className="w-11/12 mx-auto h-4/5 bg-white rounded-lg shadow-lg ">
                <div className="w-full h-16 flex bg-dash rounded-t-lg text-white">
                  <div className="w-10 p-2 h-full flex items-center">
                    <BiArrowBack
                      onClick={() => history.goBack()}
                      className="h-10 w-10 cursor-pointer"
                    />
                  </div>
                  <div className="w-full h-full px-7 flex flex-col justify-center">
                    <h2 className="font-bold text-2xl">
                      {`Create new ${title}`}
                    </h2>
                    <p className="">
                      {`Fill out the form below for a ${title}.`}
                    </p>
                  </div>
                </div>

                <div className="w-11/12 mx-auto px-6 my-2">
                  <div className="w-full h-20 flex mt-4  items-center">
                    <div className="flex  px-4 mr-4   w-full items-center select-none">
                      <TextField2 label="Phone No" name="phone" type="text" />
                    </div>

                    <div className="flex  px-4 ml-4   w-full items-center select-none">
                      <h2 className="text-base pr-4">Gender: </h2>
                      <div className="flex h-10 items-center w-64 justify-between">
                        <input
                          type="radio"
                          name="gender"
                          value="MALE"
                          onChange={genderType}
                        />
                        <span>Male</span>
                        <input
                          type="radio"
                          name="gender"
                          value="FEMALE"
                          onChange={genderType}
                        />
                        <span>Female</span>
                        <input
                          type="radio"
                          name="gender"
                          value="OTHER"
                          onChange={genderType}
                        />
                        <span>Other</span>
                      </div>
                    </div>
                  </div>

                  <h2 className="ml-4 mt-4">Basic Details : </h2>

                  <div className="w-full h-20 flex mt-1  items-center">
                    <div className="flex  px-4 mr-4   w-full items-center select-none">
                      <TextField2
                        label="First Name"
                        name="firstName"
                        type="text"
                      />
                    </div>

                    <div className="flex  px-4 ml-4   w-full items-center select-none">
                      <TextField2
                        label="Last Name"
                        name="lastName"
                        type="text"
                      />
                    </div>

                    <div className="flex  px-4 ml-4   w-full items-center select-none">
                      <TextField2 label="email" name="email" type="text" />
                    </div>
                  </div>

                  <div class="grid grid-cols-3 gap-4 m-4 items-center">
                    <div className="" style={{ width: "298px" }}>
                      <Select
                        options={login}
                        maxMenuHeight={162}
                        value={select.login}
                        name="login"
                        onChange={handleSelect}
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                      />
                    </div>
                    <div className=" ml-5" style={{ width: "298px" }}>
                      <Select
                        options={authorities}
                        maxMenuHeight={162}
                        value={select.authoritiesName}
                        name="authoritiesName"
                        onChange={handleSelect}
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                      />
                    </div>
                    <div className="ml-8">
                      <div className="w-full   py-4 h-full flex   items-center rounded-lg">
                        <span className=" mr-4">Status</span>
                        <Toggle
                          onChange={statusChecked}
                          checked={status}
                          //icons={{ checked: null, unchecked: null }}
                          className="react-switch"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="w-full h-20 flex items-center">
                    <div className="flex  px-4 mr-4  w-full items-center select-none">
                      <TextField2
                        label="Password"
                        name="password"
                        type="password"
                      />
                    </div>

                    <div className="flex  px-4 ml-4   w-full items-center select-none">
                      <TextField2
                        label="Confirm Password"
                        name="confirmPassword"
                        type="password"
                      />
                    </div>
                  </div>

                  <div className="w-full h-16 flex justify-end items-center px-4 -mt-2">
                    <button
                      type="submit"
                      className="bg-green-500 rounded-lg px-6 py-3  focus:outline-none text-white"
                    >
                      <span className="h-full flex items-center">
                        <svg
                          class="svg-icon"
                          viewBox="0 0 20 20"
                          width="24px"
                          height="24px"
                        >
                          <path
                            fill="#8A2BE2"
                            d="M19.404,6.65l-5.998-5.996c-0.292-0.292-0.765-0.292-1.056,0l-2.22,2.22l-8.311,8.313l-0.003,0.001v0.003l-0.161,0.161c-0.114,0.112-0.187,0.258-0.21,0.417l-1.059,7.051c-0.035,0.233,0.044,0.47,0.21,0.639c0.143,0.14,0.333,0.219,0.528,0.219c0.038,0,0.073-0.003,0.111-0.009l7.054-1.055c0.158-0.025,0.306-0.098,0.417-0.211l8.478-8.476l2.22-2.22C19.695,7.414,19.695,6.941,19.404,6.65z M8.341,16.656l-0.989-0.99l7.258-7.258l0.989,0.99L8.341,16.656z M2.332,15.919l0.411-2.748l4.143,4.143l-2.748,0.41L2.332,15.919z M13.554,7.351L6.296,14.61l-0.849-0.848l7.259-7.258l0.423,0.424L13.554,7.351zM10.658,4.457l0.992,0.99l-7.259,7.258L3.4,11.715L10.658,4.457z M16.656,8.342l-1.517-1.517V6.823h-0.003l-0.951-0.951l-2.471-2.471l1.164-1.164l4.942,4.94L16.656,8.342z"
                          ></path>
                        </svg>
                        <span className="px-2">Create {title}</span>
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default CreateEsl;
