import React, { useState, useEffect } from "react";
import Action from "./Action";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import Address from "./Address";
import Select from "react-select";
import DashboardRoute from "./DashboardRoute";
import Confirm from "./Confirm";
import ModalEdit from "../../../../../../../modal/edit/ModalEsl";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { FiEdit } from "react-icons/fi";
import { useGet } from "../../../../../../../../api/useGet";
import { getTime } from "../../../../../../../../logic/useGetTime";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { GrView } from "react-icons/gr";

function All({ isLoading, error, data, url, viewUrl, editUrl, sortedData }) {
  const [checked, setChecked] = useState(false);
  const history = useHistory();
  const [value, setValue] = useState("");
  const [esl, setEsl] = useState({});
  const [selectedData, setSelectedData] = useState({});
  const [input, setInput] = useState({
    startDate: null,
    endDate: null,
  });

  const changeInputHandler = (value, name) => {
    setInput({ ...input, [name]: value });
  };

  const fetchMerchant = async (url) => {
    try {
      const res = await axios({
        method: "GET",
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      });

      return res;
    } catch ({ message }) {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message);
      }
    }
  };

  const { data: allRetailerStore } = useGet(
    `${
      process.env.REACT_APP_BASE_URL
    }/retail-stores?rootOrgId.equals=${parseInt(
      JSON.parse(localStorage.getItem("dashboard-data"))?.id
    )}`,
    ""
  );

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };

  const changeHandler = (value) => {
    setValue(value);
  };

  const view = async (data) => {
    // const res = await fetchMerchant(
    //   `${process.env.REACT_APP_BASE_URL}/vouchers/${data?.id}`
    // );
    history.push({
      pathname: viewUrl,
      state: { data: data?.id },
    });
  };

  return (
    <div className=" w-full h_das_table">
      <div className="flex h-10 w-full items-center mt-2 justify-between py-8 px-4 space-x-6">
        <input
          className="focus:outline-none py-1 px-2  rounded-lg bg-[#D9D9D9]"
          placeholder="Search by User Name"
        />

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div className="grid grid-cols-2 gap-y-4 gap-x-6">
            <div>
              <DatePicker
                label="Start Date"
                mask="____/__/__"
                value={input?.startDate}
                onChange={(newValue) =>
                  changeInputHandler(newValue, "startDate")
                }
                // renderInput={(params) => (
                //   <TextField {...params} className="w-full" />
                // )}
                renderInput={({ inputRef, inputProps, InputProps }) => (
                  <div className="flex justify-center items-center bg-[#D9D9D9] rounded-lg py-1 px-2">
                    <input
                      ref={inputRef}
                      {...inputProps}
                      className="focus:outline-none bg-transparent"
                    />
                    {InputProps?.endAdornment}
                  </div>
                )}
                //disableOpenPicker={true}
              />
            </div>
            <div>
              <DatePicker
                label="End Date"
                mask="____/__/__"
                value={input?.endDate}
                onChange={(newValue) => changeInputHandler(newValue, "endDate")}
                renderInput={({ inputRef, inputProps, InputProps }) => (
                  <div className="flex justify-center items-center bg-[#D9D9D9] rounded-lg py-1 px-2">
                    <input
                      ref={inputRef}
                      {...inputProps}
                      className="focus:outline-none bg-transparent"
                    />
                    {InputProps?.endAdornment}
                  </div>
                )}
                //disableOpenPicker={true}
              />
            </div>
          </div>
        </LocalizationProvider>

        <div style={{ width: "20%" }}>
          <Select
            className="text-left"
            placeholder="Filter by store"
            maxMenuHeight={162}
            styles={{
              control: (style) => ({ ...style, backgroundColor: "#D9D9D9" }),
            }}
            value={value}
            onChange={changeHandler}
            options={sortedData?.map((item, index) => {
              return {
                label: item,
                value: item,
                key: index,
              };
            })}
          />
        </div>
        <div className="flex justify-center items-center space-x-2">
          <button className="bg-green-600 py-1.5 px-4 rounded-lg text-white">
            Search
          </button>
          <button className="bg-[#D9D9D9] py-1.5 px-3  rounded-lg text-black ">
            Clear filters
          </button>
        </div>
      </div>

      <div className="ag-theme-alpine w-full h-full text-left">
        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            resizable: true,
          }}
          suppressRowClickSelection={true}
          rowSelection={"multiple"}
          pagination={true}
          paginationPageSize={10}
          rowData={data}
        >
          <AgGridColumn
            headerName="Voucher Name"
            field="name"
            minWidth={180}
            sortable={true}
            headerCheckboxSelection={true}
            headerCheckboxSelectionFilteredOnly={true}
            checkboxSelection={true}
            cellRendererFramework={(params) => (
              <DashboardRoute params={params} />
            )}
          />

          <AgGridColumn
            field="Retailer Store"
            minWidth={200}
            cellRendererFramework={(params) => (
              <p>
                {
                  allRetailerStore.find((ele) => ele.id === params.data.storeId)
                    ?.internalCode
                }
              </p>
            )}
          />

          <AgGridColumn
            headerName="Start Date"
            minWidth={160}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <div key={params?.data?.id}>
                  <p>
                    {getTime(
                      params?.data?.startDate,
                      params?.data?.storeTimeZone
                    )}
                  </p>
                </div>
              );
            }}
          />

          <AgGridColumn
            headerName="End Date"
            minWidth={160}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <div key={params?.data?.id}>
                  <p>
                    {getTime(
                      params?.data?.endDate,
                      params?.data?.storeTimeZone
                    )}
                  </p>
                </div>
              );
            }}
          />

          <AgGridColumn
            headerName="Status"
            minWidth={100}
            cellRendererFramework={(params) => <Confirm params={params} />}
          />

          <AgGridColumn
            headerName="Update"
            minWidth={100}
            cellRendererFramework={(params) => (
              <>
                <div className="flex w-16 h-10 items-center">
                  <div className="border-gray-200 rounded-lg p-1 mr-1">
                    <GrView
                      className="w-5 h-5 cursor-pointer "
                      onClick={() => view(params?.data)}
                    />
                  </div>
                  <div className="border-gray-200 rounded-lg p-1 ">
                    <Action
                      params={params}
                      url={url}
                      viewUrl={viewUrl}
                      editUrl={editUrl}
                    />
                  </div>
                </div>
              </>
            )}
          />
        </AgGridReact>
      </div>
    </div>
  );
}

export default All;
