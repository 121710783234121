import React from "react";
import TableStr from "./TableStr";
import Enabled from "./options/Enabled";
import Disabled from "./options/Disabled";
import All from "./options/All";


function Supplier() {
 

  return (
    <div className="h-full w-full ">
      <TableStr
        title={"Supplier"}
        url={"/admin/merchant/dashboard/productManagement/supplier/createSupplier"}
        viewUrl={"/admin/merchant/dashboard/productManagement/supplier/view"}
        editUrl={"/admin/merchant/dashboard/productManagement/supplier/edit"}
        listUrl={`${process.env.REACT_APP_BASE_URL}/suppliers?sort=createdDate,desc&rootOrgId.equals=${parseInt(JSON.parse(localStorage.getItem("dashboard-data"))?.id)}`}
        getName={"supplierList"}
        Enabled={Enabled}
        Disabled={Disabled}
        All={All}
        showButton={true}
        dashboard={true}
      />
    </div>
  );
}

export default Supplier;
