import React from "react";
import { Switch, Route } from "react-router-dom";

// Components
import Welcome from "./pages/welcome/Welcome";

//**********admin/support/dashboard*************//

//admin/support/dashboard --> Merchant
import Merchant from "./pages/merchant/Merchant";
import CreateMerchant from "./pages/merchant/create/CreateMerchant";
import MerchantView from "./pages/merchant/view/MerchantView";
import MerchantEdit from "./pages/merchant/edit/MerchantEdit";

//**********PasswordChange*************//
import PasswordChange from "../../passwordChange";

//admin/support/dashboard --> Location
import Location from "./pages/location/Location";
import CreateLocation from "./pages/location/create/CreateLocation";
import LocationView from "./pages/location/view/LocationView";
import LocationEdit from "./pages/location/edit/LocationEdit";

// Order
import Order from "./pages/order/Order";
import OrderSummary from "./pages/order/dashboard/OrderSummary";
import RefundedSummary from "./pages/order/options/refunded/RefundedSummary";
import CreateOrder from "./pages/order/create/CreateOrder";
import RefundAction from "./pages/order/dashboard/RefundAction";
import OrderView from "./pages/order/view/OrderView";
import OrderEdit from "./pages/order/edit/OrderEdit";

// Employee
import Employee from "./pages/employee/Employee";
import CreateEmployee from "./pages/employee/create/CreateEmployee";
import EmployeeView from "./pages/employee/view/EmployeeView";
import EmployeeEdit from "./pages/employee/edit/EmployeeEdit";

// Shopper
import Shopper from "./pages/shoppers/Shopper";

//admin/support/dashboard --> Machine
import Machine from "./pages/machine/Machine";
import CreateMachine from "./pages/machine/create/CreateMachine";
import MachineView from "./pages/machine/view/MachineView";
import MachineViewEdit from "./pages/machine/view/MachineEdit";
import MachineEdit from "./pages/machine/edit/MachineEdit";

//admin/support/dashboard --> Hawkeye
import Hawkeye from "./pages/hawkeye/Hawkeye";
import CreateHawkeye from "./pages/hawkeye/create/CreateHawkeye";
import HawkeyeView from "./pages/hawkeye/view/HawkeyeView";
import HawkeyeEdit from "./pages/hawkeye/edit/HawkeyeEdit";
import ProductReview from "./pages/hawkeye/dashboard/ProductReview";
import User from "./pages/hawkeye/dashboard/user-details/User";
import FinalReview from "./pages/hawkeye/dashboard/FinalReview";
import OpenCartItem from "./pages/hawkeye/dashboard/OpenCartItem";
import EntryEvents from "./pages/hawkeye/dashboard/EntryEvents";

//admin/support/dashboard --> Match
import Match from "./pages/match/Match";
import MatchEvent from "./pages/match/dashboard/MatchEvent";
import OpenEvents from "./pages/match/dashboard/OpenEvents";
import AddItem from "./pages/match/dashboard/AddItem";
import ProcessedEvents from "./pages/match/dashboard/ProcessedEvents";
import AllSessionVideos from "./pages/match/dashboard/AllSessionVideos";

//admin/support/dashboard --> Rack
import Rack from "./pages/rack/Rack";
import CreateRack from "./pages/rack/create/CreateRack";
import RackView from "./pages/rack/view/RackView";
import RackEdit from "./pages/rack/edit/RackEdit";
//import ShelvesView from "./pages/admin/rack/view/ShelveView";
import RackDashboard from "./pages/rack/dashboard/RackDashboard";

//admin/support/dashboard --> Shopper Activity
import ShopperActivity from "./pages/shopperActivity/ShopperActivity";
import ShopperMatchingEntryEvent from "./pages/shopperActivity/dashboard/MatchEvent";
import ShopperMatchingExitEvent from "./pages/shopperActivity/dashboard/MatchOpenEvent";
import ShopperOpenEvents from "./pages/shopperActivity/dashboard/OpenEvents";
import ShopperView from "./pages/shopperActivity/view/ShopperView";
import ShopperExit from "./pages/shopperActivity/view/ShopperExit";
import GroupShopper from "./pages/shopperActivity/dashboard/GroupShopper";
// import EntryDecision from "./pages/shopperActivity/view/EntryDecision";

// support/dashboard --> Entry Decision
import DecisionEvent from "./pages/decisionEvents/entryDecision/DecisionEvent";
import ReviewEntryEvents from "./pages/decisionEvents/entryDecision/review/index";
import EntryDecision from "./pages/decisionEvents/entryDecision/view/EntryDecision";
import ExitDecision from "./pages/decisionEvents/exitDecision/view/ExitDecision";

// support/dashboard --> Product Decision
import ProductDecision from "./pages/decisionEvents/productDecision/ProductDecision";
import ProductionDecisionView from "./pages/decisionEvents/productDecision/view/ProductDecisionView";
import ProcessedDecisionView from "./pages/decisionEvents/productDecision/view/ProcessedDecisionView";
import ExpressCheckOut from "./pages/match/dashboard/expressCheckout/ExpressCheckout";
import ProductEvent from "./pages/match/dashboard/ProductEvent";
//**********Location*************//

const Routes = () => {
  return (
    <Switch>
      <Route path="/support" exact>
        <Welcome />
      </Route>

      {/**********Merchant(support/dashboard)*************/}
      <Route path="/support/merchant" exact>
        <Merchant />
      </Route>

      <Route path="/support/merchant/view" exact>
        <MerchantView />
      </Route>

      <Route path="/support/merchant/edit" exact>
        <MerchantEdit />
      </Route>

      <Route path="/support/merchant/newMerchant" exact>
        <CreateMerchant />
      </Route>

      {/**********Password Change*************/}
      <Route path="/support/password" exact>
        <PasswordChange />
      </Route>

      {/**********Location(admin/support/dashboard)*************/}
      <Route path="/support/retailer-store" exact>
        <Location />
      </Route>

      <Route path="/support/retailer-store/view" exact>
        <LocationView />
      </Route>

      <Route path="/support/retailer-store/edit" exact>
        <LocationEdit />
      </Route>

      <Route path="/support/retailer-store/newLocation" exact>
        <CreateLocation />
      </Route>

      {/**********Order*************/}
      <Route path="/support/orders" exact>
        <Order />
      </Route>

      <Route path="/support/order/order-summary" exact>
        <OrderSummary header={true} />
      </Route>

      <Route path="/support/order/view" exact>
        <OrderView />
      </Route>

      <Route path="/support/order/edit" exact>
        <OrderEdit />
      </Route>

      <Route path="/support/order/newOrder" exact>
        <CreateOrder />
      </Route>

      <Route path="/support/order/refund" exact>
        <RefundAction header={true} />
      </Route>

      <Route path="/support/order/refund/refunded-summary" exact>
        <RefundedSummary header={true} />
      </Route>

      {/**********employee*************/}
      <Route path="/support/employee" exact>
        <Employee />
      </Route>

      <Route path="/support/employee/view" exact>
        <EmployeeView />
      </Route>

      <Route path="/support/employee/edit" exact>
        <EmployeeEdit />
      </Route>

      <Route path="/support/employee/newOrder" exact>
        <CreateEmployee />
      </Route>

      {/**********shopper*************/}
      <Route path="/Support/shopper" exact>
        <Shopper />
      </Route>
      {/**********Machine(admin/support/dashboard)*************/}

      <Route path="/support/machine" exact>
        <Machine />
      </Route>

      <Route path="/support/machine/view" exact>
        <MachineView />
      </Route>

      <Route path="/support/machine/view/viewEdit" exact>
        <MachineViewEdit />
      </Route>

      <Route path="/support/machine/edit" exact>
        <MachineEdit />
      </Route>

      <Route path="/support/machine/newMachine" exact>
        <CreateMachine />
      </Route>

      {/**********Rack(admin/support/dashboard)*************/}
      <Route path="/support/rack" exact>
        <Rack />
      </Route>

      <Route path="/support/rack/view" exact>
        <RackView />
      </Route>

      <Route path="/support/rack/edit" exact>
        <RackEdit />
      </Route>

      <Route path="/support/rack/newRack" exact>
        <CreateRack />
      </Route>

      {/* <Route path="/admin/support/dashboard/rack/monitoring">
        <RackDashboard />
      </Route>

      <Route path="/admin/support/dashboard/hawkeye/monitoring/user" exact>
        <User />
      </Route>

      <Route path="/admin/support/dashboard/hawkeye/monitoring/mapped" exact>
        <Mapped />
      </Route> */}

      {/**********Hawkeye(admin/support/dashboard)*************/}
      <Route path="/support/hawkeye" exact>
        <Hawkeye />
      </Route>

      <Route path="/support/hawkeye/view" exact>
        <HawkeyeView />
      </Route>

      <Route path="/support/hawkeye/edit" exact>
        <HawkeyeEdit />
      </Route>

      <Route path="/support/hawkeye/newHawkeye" exact>
        <CreateHawkeye />
      </Route>

      <Route path="/support/hawkeye/openCartItem" exact>
        <OpenCartItem />
      </Route>

      <Route path="/support/hawkeye/cartSummary" exact>
        <FinalReview />
      </Route>

      <Route path="/support/hawkeye/cartSummary/productReview" exact>
        <ProductReview />
      </Route>

      <Route path="/support/hawkeye/entryEvents" exact>
        <EntryEvents />
      </Route>

      {/* <Route path="/admin/support/dashboard/hawkeye/monitoring/review/openCartItems" exact>
        <OpenCartItem />
      </Route> 

      <Route path="/admin/support/dashboard/hawkeye/monitoring/user" exact>
        <User />
      </Route>

      <Route path="/admin/support/dashboard/hawkeye/monitoring/mapped" exact>
        <Mapped />
    </Route> */}

      {/**********Match(admin/support/dashboard)*************/}
      <Route path="/support/match" exact>
        <Match />
      </Route>

      {/* <Route path="/admin/support/dashboard/match/view" exact>
        <StoreOpenCardView />
      </Route>

      <Route path="/admin/support/dashboard/match/edit" exact>
        <StoreOpenCardEdit />
      </Route>

      <Route path="/admin/support/dashboard/storeOpenCard/newStoreOpenCard" exact>
        <CreateStoreOpenCard />
      </Route> */}

      <Route path="/support/match/matchEvent">
        <MatchEvent />
      </Route>

      <Route path="/support/match/storeOpenCard/monitoring/user" exact>
        <User />
      </Route>

      <Route path="/support/match/openEvents" exact>
        <OpenEvents />
      </Route>

      <Route path="/support/match/openEvents/beta" exact>
        <OpenEvents newUI />
      </Route>

      <Route path="/support/match/express-checkout" exact>
        <ExpressCheckOut />
      </Route>

      <Route path="/support/match/openEvents/AddItem" exact>
        <AddItem />
      </Route>

      <Route path="/support/match/processedEvents/">
        <ProcessedEvents />
      </Route>

      <Route path="/support/match/openEvents/session-videos">
        <AllSessionVideos />
      </Route>

      <Route path="/support/match/development" exact>
        <ProductEvent />
      </Route>

      {/**********Shopper(admin/support/dashboard)*************/}

      <Route path="/support/shopper-activity" exact>
        <ShopperActivity />
      </Route>

      <Route path="/support/shopper-activity/shopperEntryEvent" exact>
        <ShopperMatchingEntryEvent />
      </Route>

      <Route path="/support/shopper-activity/shopperExitEvent" exact>
        <ShopperMatchingExitEvent />
      </Route>

      <Route path="/support/shopper-activity/openEvents" exact>
        <ShopperOpenEvents />
      </Route>

      <Route path="/support/shopper-activity/view" exact>
        <ShopperView />
      </Route>

      <Route path="/support/shopper-activity/exit" exact>
        <ShopperExit />
      </Route>
      <Route path="/support/shopper-activity/entry-decision" exact>
        <EntryDecision />
      </Route>
      <Route path="/support/shopper-activity/exit-decision" exact>
        <ExitDecision />
      </Route>

      <Route
        path="/support/shopper-activity/shopperEvent/group-checkedout"
        exact
      >
        <GroupShopper />
      </Route>

      {/***************Decision Events **************/}

      <Route path="/support/decision-events/entry-decision" exact>
        <DecisionEvent />
      </Route>
      <Route path="/support/decision-events/entry-decision/:gateEventId" exact>
        <ReviewEntryEvents />
      </Route>
      <Route path="/support/decision-events/entry-decision/view" exact>
        <EntryDecision />
      </Route>
      <Route path="/support/decision-events/product-decision" exact>
        <ProductDecision />
      </Route>
      <Route path="/support/decision-events/product-decision/view" exact>
        <ProductionDecisionView />
      </Route>
      <Route
        path="/support/decision-events/product-decision/processed-view"
        exact
      >
        <ProcessedDecisionView />
      </Route>
    </Switch>
  );
};

export default Routes;
