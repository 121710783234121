import React from "react";
import { ErrorMessage, useField } from "formik";
import {TextField} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';

export const TextField1 = ({ label, Icon,  ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <Grid container spacing={1} alignItems="flex-end">
        <Grid item>
           {Icon}
        </Grid>
        <Grid item>
          <TextField
             error={meta.touched && meta.error ? meta.error : ""}
             label={meta.touched && meta.error ? meta.error : label}
            className={`px-3 py-2 w-64 text-base placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500  sm:text-sm ${
              meta.touched && meta.error && "is-invalid"
            }`}
            {...field}
            {...props}
            autoComplete="off"
          />
        </Grid>
      </Grid>
    </>
  );
};
