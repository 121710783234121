import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import moment from "moment";
import ShopperImages from "./ShopperImages";


function ShopperView() {
  const [status, setStatus] = useState(false);

  

  const statusChecked = (e) => {
    setStatus(e.target.checked);
  };
  const location = useLocation();
  const history = useHistory();

  const shopper = JSON.parse(localStorage?.getItem("exitData"));

  return (
    <>
      <div className="w-full h_page flex">
        <div className="w-full h-full flex flex-col justify-center items-center bg-white">
          <div className="w-full h-12 flex bg-gray-100  items-center">
            <BiArrowBack
              onClick={() => history.goBack()}
              className="h-6 w-6 cursor-pointer ml-5"
            />
            <h2 className="font-bold px-6 text-xl">{shopper?.name}</h2>
          </div>

          <div className="w-full h-64 flex flex-col justify-center items-center">
            <div className="flex">
              {shopper?.userImagePaths.map((url, index) => <ShopperImages url={url} index={index} />)}
            </div>
          </div>


          {shopper?.qrToken ? (
              <p><span className="text-blue-500">Qr Token: </span> {shopper?.qrToken}</p>
            ) : null}

          <div className="w-full h-72 flex flex-col justify-center items-center">
            {shopper?.name ? (
              <div className="border-b-2 border-white py-1 px-8 w-2/5 flex justify-between text-left">
                <div className="w-32">Name</div>
                <div className="w-48">{shopper?.name}</div>
              </div>
            ) : null}

            {shopper?.email ? (
              <div className="border-b-2 border-white py-1 px-8 w-2/5 flex justify-between text-left">
                <div className="w-32">Email</div>
                <div className="w-48">{shopper?.email}</div>
              </div>
            ) : null}

            {shopper?.entryTime ? (
              <div className="border-b-2 border-white py-1 px-8 w-2/5 flex justify-between text-left">
                <div className="w-32">Entry Time</div>
                <div className="w-48">
             
                  {moment(shopper?.entryTime).format(
                    "MM-D-YYYY, hh:mm:ss A"
                  )}
                 
                </div>
              </div>
            ) : null}

            {shopper?.cartRef ? (
              <div className="border-b-2 border-white py-1 px-8 w-2/5 flex justify-between text-left">
                <div className="w-32">Cart Ref</div>
                <div className="w-48">{shopper?.cartRef}</div>
              </div>
            ) : null}

           

            {shopper?.phone ? (
              <div className="border-b-2 border-white py-1 px-8 w-2/5 flex justify-between text-left">
                <div className="w-32">Phone</div>
                <div className="w-48">{shopper?.phone}</div>
              </div>
            ) : null}

            {shopper?.noOfGroupShoppers ? (
              <div className="border-b-2 border-white py-1 px-8 w-2/5 flex justify-between text-left">
                <div className="w-32">Shoppers</div>
                <div className="w-48"># {shopper?.noOfGroupShoppers}</div>
              </div>
            ) : null}

            {shopper?.retailStoreCode ? (
              <div className="border-b-2 border-white py-1 px-8 w-2/5 flex justify-between text-left">
                <div className="w-32 ">Store Code</div>
                <div className="w-48">{shopper?.retailStoreCode}</div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default ShopperView;
