import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Button } from "@mui/material";
import { usePut } from "../../../../../../../api/usePut";
import { toast } from "react-toastify";
// import { getTime } from "../../../../../../../logic/useGetTime";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#D9D9D9",
    color: theme.palette.common.black,
    fontWeight: 700,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

export default function SelectedProduct({
  storeData,
  setStoreData,
  storeCartsWithRackInfo,
}) {
  console.log(
    "🚀 ~ file: SelectedProductTable.jsx:47 ~ SelectedProduct ~ storeData:",
    storeData
  );

  const findRack = (extRef, storeCartsWithRackInfo) => {
    if (extRef && storeCartsWithRackInfo.length > 0) {
      let find = storeCartsWithRackInfo?.find((ele) =>
        extRef.includes(ele.cartItemRef)
      );

      return find?.scaleInternalCode;
    }
  };

  const handleQuantity = (index, operation) => {
    if (
      storeData?.storeCartItems[index].reviewedQuantity == 0 &&
      operation == "-"
    )
      return;
    const tempData = { ...storeData };

    const updatedCartItems = [...tempData?.storeCartItems];

    if (updatedCartItems[index]) {
      updatedCartItems[index].reviewedQuantity =
        operation === "-"
          ? updatedCartItems[index].reviewedQuantity - 1
          : updatedCartItems[index].reviewedQuantity + 1;
    }

    tempData.storeCartItems = updatedCartItems;

    setStoreData(tempData);
  };

  const { mutate } = usePut(
    `${process.env.REACT_APP_SHOPPER_URL}/stores/modify-cart-item`,
    "updateCartItem"
  );

  const onUpdate = (item) => {
    try {
      const paylaod = {
        id: storeData?.id,
        storeCartItems: [
          {
            id: item?.id,
            quantity: item?.reviewedQuantity,
          },
        ],
      };

      mutate(paylaod);
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <TableContainer
      component={Paper}
      sx={{ height: "100%", border: "1px solid black", resize: "vertical" }}
    >
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Rack</StyledTableCell>
            <StyledTableCell>Product Image </StyledTableCell>
            <StyledTableCell>Product Name</StyledTableCell>
            <StyledTableCell>Qty</StyledTableCell>
            <StyledTableCell>Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {storeData?.storeCartItems
            ?.filter((item) => item?.status === "APPROVED")
            ?.map((item, i) => (
              <StyledTableRow key={item?.scaleInternalCode}>
                <StyledTableCell>{item?.scaleInternalCode}</StyledTableCell>
                <StyledTableCell>
                  {item?.imageUrl ? (
                    <div className="w-10 h-10  flex items-center justify-center">
                      <img src={item?.imageUrl} alt="productImg" />
                    </div>
                  ) : (
                    <div className="w-[46px] h-[46px]  flex items-center justify-center">
                      {/* <img src={NoImage} alt="No Image" /> */}
                    </div>
                  )}
                </StyledTableCell>
                <StyledTableCell>{item?.name}</StyledTableCell>
                <StyledTableCell>
                  <RemoveIcon
                    sx={{ cursor: "pointer", mr: 2, background: "#D9D9D9" }}
                    onClick={() => handleQuantity(i, "-")}
                  />
                  {item?.reviewedQuantity}
                  <AddIcon
                    sx={{ cursor: "pointer", ml: 2, background: "#D9D9D9" }}
                    onClick={() => handleQuantity(i, "+")}
                    // onClick={() =>
                    //   params?.setValue(params?.data?.reviewedQuantity + 1)
                    // }
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Button
                    variant="contained"
                    color="success"
                    sx={{ ml: 2 }}
                    onClick={() => onUpdate(item)}
                  >
                    Update
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
