import React from "react";
import TableStr from "./TableStr";
import Enabled from "./options/Enabled";
import Disabled from "./options/Disabled";
import All from "./options/All";

function Location() {
  return (
    <div className="h-full w-full ">
      <TableStr
        title={"Retailer Store"}
        url={"/admin/merchant/newMerchant"}
        viewUrl={"/admin/merchant/dashboard/retailer-store/view"}
        editUrl={"/admin/merchant/edit"}
        listUrl={`${process.env.REACT_APP_BASE_URL}/retail-stores?page=0&sort=createdDate,desc&rootOrgId.equals=${parseInt(
          JSON.parse(localStorage.getItem("dashboard-data"))?.id)}`}
        getName={"merchantList"}
        Enabled={Enabled}
        Disabled={Disabled}
        All={All}
        showButton={null}
        dashboard={true}
      />
    </div>
  );
}

export default Location;