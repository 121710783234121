import React, { useState, useEffect } from "react";
import TableStr from "./TableStr";
import Enabled from "./options/Enabled";
import Disabled from "./options/Disabled";
import All from "./options/All";
import axios from "axios";

function Shopper() {
  // const [data, setData] = useState();

  // useEffect(async () => {
  //   const res = await axios({
  //     method: "GET",
  //     url: `${process.env.REACT_APP_BASE_URL}/users`,
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${localStorage.getItem("user-info")}`,
  //     },
  //   });

  //   setData(res?.data);
  //   console.log("res: ", res?.data);
  // }, []);
  

  return (
    <div className="h-full w-full ">
      <TableStr
        title={"Shoppers"}
        listUrl={`${process.env.REACT_APP_SHOPPER_URL}/users?page=0&size=300&sort=createdDate,desc`}
        getName={"ShoppersList"}
        Enabled={Enabled}
        Disabled={Disabled}
        All={All}
        
      
      />
    </div>
  );
}

export default Shopper;
