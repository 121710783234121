import React, { useState, useEffect } from "react";
import ModalImg from "../../ModalImg";
import { BsCheckAll } from "react-icons/bs";
import {toast} from 'react-toastify'

const findMaxRefundQty = (arr, id) => {
  return arr?.refundOrderItems?.find((ele) => ele?.orderItemId === id);
};

const RefundItem = ({
  data,
  item,
  index,
  partial,
  partialSelect,
  refundType,
  onChangeOrderItems,
  path,
  maxAllowedRefunded,
}) => {
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [input, setInput] = useState({
    refundQuantity: "",
    comments: "",
  });

  const onChangeInput = (value, id, name) => {
    const maxQty = findMaxRefundQty(maxAllowedRefunded, id)?.refundQuantity;
   
    if (value <= maxQty) {
    
      setInput({ ...input, [name]: value });
      onChangeOrderItems(value, id, name);
    } else {
     
      setInput({ ...input, [name]: value });
      toast.error("Refund qty is less than allowed qty.");
    }
  };

  useEffect(() => {
    const refundedQty = findMaxRefundQty(
      maxAllowedRefunded,
      item?.id
    )?.refundQuantity;
    setInput({ ...input, ["refundQuantity"]: refundedQty ? refundedQty : 0 });
  }, [item?.id]);

  return (
    <div
      key={index}
      onClick={() => refundType === "PARTIAL" && partialSelect(item)}
      className="w-9.8/10 rounded-lg bg-white mb-2 flex items-center h-20  px-4  cursor-pointer"
    >
      <div className="w-32">
        {item?.imageUrl ? (
          <img
            onClick={onOpenModal}
            src={item?.imageUrl}
            alt={item?.productName}
            className="w-20 h-20 object-fill cursor-pointer rounded-lg p-1 bg-gray-200"
          />
        ) : null}
        <ModalImg
          open={open}
          onCloseModal={onCloseModal}
          img={item?.imageUrl}
        />
      </div>

      <div className="w-full h-full items-center flex justify-between border-b-2 border-gray-200">
        <div className="w-1/4 pl-8">
          <h2>{item?.productName}</h2>
        </div>

        {(data?.status === "PAYMENT_PROCESSED" ||
          data?.status === "PAYMENT_PARTIALLY_REFUNDED") && (
          <>
            <div className="w-1/4 flex justify-center">
              <input
                onChange={(e) => {
                  onChangeInput(e.target.value, item?.id, "refundQuantity");
                }}
                value={input?.refundQuantity}
                className="focus:outline-none w-16 border rounded-lg text-center"
              />
            </div>

            <div className="w-1/4 flex justify-center">
              <p>
                {findMaxRefundQty(maxAllowedRefunded, item?.id)?.refundQuantity}
              </p>
            </div>
          </>
        )}

        <div className="w-1/4 flex justify-center">
          <p>{item?.quantity}</p>
        </div>

        <div className="w-[220px] flex justify-center text-center">
          <h2>
            {data?.currencySymbol} {parseFloat(item?.unitCost).toFixed(2)}
          </h2>
        </div>
        <div className="w-1/4 flex justify-center text-center">
          <h2>
            {data?.currencySymbol} {parseFloat(item?.itemCost).toFixed(2)}
          </h2>
        </div>

        <div className="absolute right-20">
          {partial?.items.includes(item?.id) && refundType === "PARTIAL" && (
            <BsCheckAll size={20} color="green" />
          )}
        </div>
      </div>
    </div>
  );
};

export default RefundItem;
