import React, { useState, useEffect, Fragment } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useHistory, useLocation } from "react-router-dom";
import SelectEvent from "./SelectEvent";
import AlreadySelected from "./AlreadySelected";
import { useGet } from "../../../../../../api/useGet";
import moment from "moment";
import axios from "axios";
import { useAlert } from "react-alert";
import EntryImages from "./EntryImages";
import StoreOpenCardThumbnail from "./StoreOpenCardThumbnail";
import NoVideo from "../../../../../../Images/novideo.jpg";
import NoImage from "../../../../../../Images/noImage.jpg";
import { getTime } from "../../../../../../logic/useGetTime";
import ManualExit from "../../../components/ManualExit";
import ShopperThumbnail from "../../../../../../components/StoreOpenCardThumbnail";
import { IoMdRefresh } from "react-icons/io";
import { Dialog, Transition } from "@headlessui/react";
import { IoIosClose } from "react-icons/io";
import { toast } from "react-toastify";
import CircularProgressLoader from "../../../../../../components/loader/CircularProgressLoader";

function EntryEvents() {
  const history = useHistory();

  const {
    state: { data, navigateFrom, prevTabData },
  } = useLocation();
  console.log(
    "🚀 ~ file: EntryEvents.js:27 ~ EntryEvents ~ navigateFrom:",
    prevTabData?.status
  );

  let index = 0;
  const [shopperNo, setShopperNo] = useState(0);
  const [exit, setExit] = useState({
    ...data[shopperNo],
    through: "processedEntry",
  });

  const alert = useAlert();

  let [isOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const [input, setInput] = useState({
    note: "",
  });

  const [entryImages, setEntryImages] = useState([]);

  const url =
    exit?.through === "processedEntry"
      ? `${process.env.REACT_APP_STORE_URL}/user-exit-activities?userActivityId.equals=${exit?.id}`
      : `${
          process.env.REACT_APP_STORE_URL
        }/user-exit-activities?exitTime.greaterThanOrEqual=${
          exit?.entryTime
        }&exitTime.lesserThanOrEqual=${moment()
          .endOf("day")
          .format(
            "YYYY-MM-DDT23:59:59[Z]"
          )}&status.equals=OPEN&sort=entryTime,desc`;

  const { data: shopperList, isApiLoading: shopperListLoader } = useGet(
    url,
    "shopperList"
  );
  console.log(
    "🚀 ~ file: EntryEvents.js:73 ~ EntryEvents ~ shopperListLoader:",
    shopperListLoader
  );

  const { data: entriesImages, isApiLoading: entriesImagesLoader } = useGet(
    `${process.env.REACT_APP_STORE_URL}/user-activities/${exit?.id}`,
    "entriesImages"
  );
  console.log(
    "🚀 ~ file: EntryEvents.js:79 ~ EntryEvents ~ entriesImagesLoader:",
    entriesImagesLoader
  );

  const [select, setSelect] = useState();

  const onChangeTextArea = (e, name) => {
    setInput({ ...input, [name]: e.target.value });
  };

  const goNext = () => {
    if (select) {
      const payload = {
        ...select,
        userActivityId: exit?.id,
        performedBy: localStorage.getItem("user-name"),
        notes: input?.note,
        matchType: "manual",
      };

      const data = {
        entryTime: exit?.entryTime,
        checkoutDate: select?.exitTime,
        storeCode: exit?.retailStoreCode,
        sessionRef: exit?.cartRef,
        rootOrgId: exit?.rootOrgId,
      };

      axios({
        method: "PUT",
        url: `${process.env.REACT_APP_STORE_URL}/user-exit-activities/${select.id}/assign-user`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
        data: payload,
      })
        .then((res) => {
          history.push({
            pathname: "/support/match/openEvents",
            state: {
              data: data,
            },
          });
        })
        .catch(({ message }) => {
          if (message === "Request failed with status code 401") {
            history.push("/login");
          } else {
            toast.error(message);
          }
        });
    } else {
      alert.error(<div className="w-96">Match the correct event.</div>);
    }
  };

  useEffect(() => {
    const people = [];

    for (let i = 0; i < 5; i++) {
      people.push(
        entriesImages?.userImagePaths?.[i]
          ? entriesImages.userImagePaths?.[i]
          : ""
      );
    }
    setEntryImages(people);
  }, [entriesImages]);

  const onSelect = (data) => {
    setSelect(data);
  };

  const shopperLoop = (entriesImages) => {
    return entriesImages?.shopperVideoPaths?.find(
      (ele) => ele.name === "ShopperLoop"
    );
  };

  const trackingVideo = (entriesImages) => {
    return entriesImages?.shopperVideoPaths?.filter(
      (ele) => ele.name !== "ShopperLoop"
    );
  };

  const next = () => {
    if (shopperNo < data.length - 1) {
      setShopperNo((prev) => prev + 1);
    } else {
      history.push({
        pathname: "/support/match/processedEvents",
        state: { data: exit, navigateFrom },
      });
    }
  };

  useEffect(() => {
    setExit({ ...data[shopperNo], through: "processedEntry" });
  }, [shopperNo]);

  return (
    <div className="h-[calc(100vh-60px)] w-full bg-gray-300">
      {(shopperListLoader || entriesImagesLoader) && (
        <CircularProgressLoader open={true} />
      )}
      <div className="h-8 flex items-center ">
        <div className="absolute mx-4 flex items-center">
          <BiArrowBack
            onClick={() => history.push("/support/hawkeye")}
            className="h-6 w-6 cursor-pointer"
          />
        </div>

        <div className="w-full flex justify-center items-center">
          <h2 className="font-bold text-2xl">
            {exit?.through === "processedEntry"
              ? "User Session"
              : "Review User Session"}{" "}
            {prevTabData?.status ? `- ${prevTabData?.status}` : ""}
          </h2>
        </div>
      </div>

      <div className="w-full h-[calc(100vh-57px-35px)] flex rounded-t-xl pt-8 bg-white space-x-[10px]">
        {/* entry images */}
        <div className="w-[73%] h-full flex flex-col">
          <div className="flex w-full  justify-center items-center">
            {entryImages?.length > 0 ? (
              entryImages?.map((url, index) => (
                <EntryImages url={url} index={index} key={index} />
              ))
            ) : (
              <img src={NoImage} className="w-40 h-44" alt="entryimage" />
            )}
          </div>

          <p>
            <span className="font-bold mt-1">Entry Time : </span>{" "}
            {getTime(exit.entryTime, exit?.storeTimeZone)}
          </p>

          <div className="mt-3 ml-4">
            <div className="flex justify-between items-center mr-2 h-[50px]">
              <h2 className="font-bold text-lg">
                {exit?.through === "processedEntry"
                  ? `Matched`
                  : `Choose the matching exit`}
              </h2>

              {exit?.through !== "processedEntry" && (
                <ManualExit userId={exit?.id} timeZone={exit?.storeTimeZone} />
              )}
            </div>

            <div className="h-[56vh] w-[4/5%] pt-2 pr-6 space-y-2 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 ">
              {shopperList?.length > 0 ? (
                shopperList?.map((rowData, index) => {
                  return (
                    <div
                      key={index}
                      className="border-b-4 border-gray-400 space-y-2 h-52 flex flex-col justify-between"
                    >
                      <div>
                        {exit?.through === "processedEntry" ? (
                          <div className="flex flex-col">
                            <AlreadySelected
                              data={rowData}
                              index={index}
                              setSelect={setSelect}
                              select={select}
                              key={index}
                            />
                          </div>
                        ) : (
                          <SelectEvent
                            data={rowData}
                            index={index}
                            onSelect={onSelect}
                            select={select}
                            key={index}
                            name={"userImagePaths"}
                            idName={"id"}
                          />
                        )}
                      </div>

                      <div className="h-10 flex justify-center items-end">
                        {rowData?.exitTime && (
                          <p>
                            <span className="font-bold">Exit Time : </span>
                            {getTime(rowData?.exitTime, rowData?.storeTimeZone)}
                          </p>
                        )}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="border-b-4 border-gray-400 space-x-4">
                  {exit?.through === "processedEntry" ? (
                    <div className="flex flex-col">
                      <AlreadySelected
                        data={shopperList}
                        setSelect={setSelect}
                        select={select}
                      />
                    </div>
                  ) : exit?.through === "openEntry" ? (
                    <SelectEvent
                      data={shopperList}
                      onSelect={onSelect}
                      select={select}
                      name={"userImagePaths"}
                      idName={"id"}
                    />
                  ) : null}

                  {shopperList?.exitTime && (
                    <p>
                      <span className="font-bold">Exit Time : </span>
                      {getTime(
                        shopperList?.exitTime,
                        shopperList?.storeTimeZone
                      )}
                    </p>
                  )}
                </div>
              )}

              {/* video exit session links passed in modal */}

              <div className="w-full flex justify-between items-center ">
                {shopperLoop(entriesImages) ? (
                  <ShopperThumbnail
                    imageUrl={shopperLoop(entriesImages)?.thumbnail}
                    videoUrl={shopperLoop(entriesImages)?.videoUrl}
                    index={1}
                  />
                ) : (
                  <img src={NoVideo} className="w-44 h-44" alt="exitimage" />
                )}

                <div className="w-[58%] flex items-center mt-24 justify-end ">
                  <button
                    type="button"
                    onClick={openModal}
                    className="cursor-pointer rounded-md bg-green-500 bg-opacity-80 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                  >
                    See all session videos
                  </button>
                </div>
              </div>

              <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-black bg-opacity-60" />
                  </Transition.Child>

                  <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <Dialog.Panel className="w-full max-w-[48rem] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900 flex justify-between items-center"
                          >
                            <p>Session Videos</p>

                            <IoIosClose
                              onClick={closeModal}
                              size={28}
                              className="bg-gray-500 rounded-full bg-opacity-20 shadow-lg cursor-pointer"
                            />
                          </Dialog.Title>

                          {exit?.through === "processedEntry" && (
                            <div className="mt-6 grid grid-cols-4  gap-y-12">
                              {trackingVideo(entriesImages)?.length > 0 ? (
                                trackingVideo(entriesImages)?.map(
                                  (ele, index) => {
                                    return (
                                      <div className="flex flex-col items-center justify-center">
                                        <StoreOpenCardThumbnail
                                          imageUrl={ele?.thumbnail}
                                          videoUrl={ele?.videoUrl}
                                          camera={ele?.name}
                                          index={index}
                                        />
                                        <p>{ele.name}</p>
                                      </div>
                                    );
                                  }
                                )
                              ) : (
                                <img
                                  src={NoVideo}
                                  className="w-44 h-4"
                                  alt="exitimage"
                                />
                              )}
                            </div>
                          )}
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition>
            </div>
          </div>
        </div>

        {/* right  */}
        <div className={`w-[25.5%] `}>
          <div className="bg-gray-200 w-full h-full flex flex-col space-y-8 rounded-lg p-3">
            <div className="w-full flex flex-col items-start">
              {exit?.noOfGroupShoppers > 1 ? (
                <div className=" w-full flex text-left text-[0.94rem]">
                  <p className="w-[118px] font-bold text-[0.95rem]">
                    Group Shopper
                  </p>
                  <p>{` :  ${shopperNo + 1}`}</p>
                </div>
              ) : (
                <div className=" w-full flex text-left text-[0.94rem]">
                  <p className="w-[118px] font-bold">Shopper#</p>
                  <p>{` :  ${exit?.noOfGroupShoppers}`}</p>
                </div>
              )}

              <div className="w-full flex text-left text-[0.94rem]">
                <p className="w-[118px] font-bold">Name: </p>
                <p>{` :  ${exit?.name}`}</p>
              </div>

              <div className="w-full flex text-left text-[0.94rem]">
                <p className="w-[118px] font-bold">Store ID</p>
                <p>{` :  ${exit?.retailStoreCode}`}</p>
              </div>

              <div className=" w-full flex text-left text-[0.94rem]">
                <p className="w-[118px] font-bold">Cart Ref</p>
                <p>{` :  ${exit?.cartRef}`}</p>
              </div>

              {exit?.through === "processedEntry" ? (
                <>
                  <div className="w-full flex text-left text-[0.94rem]">
                    <p className="w-[118px] font-bold">Performed By</p>
                    <p>{` :  ${exit?.performedBy ? exit.performedBy : ""}`}</p>
                  </div>

                  <div className="w-full flex text-left text-[0.94rem]">
                    <p className="w-[118px] font-bold">Match Type</p>
                    <p>{` :  ${exit?.matchType}`}</p>
                  </div>

                  <div className="w-full flex text-left text-[0.94rem]">
                    <p className="w-[118px] font-bold">Comment</p>
                    <p>{` :  ${exit?.notes ? exit?.notes : ""}`}</p>
                  </div>
                </>
              ) : (
                <div className="w-full flex text-left text-[0.94rem]">
                  <p className="w-[118px] font-bold">Performed By</p>
                  <p>{` :  ${localStorage?.getItem("user-name")}`}</p>
                </div>
              )}
            </div>
            {select ? (
              <div className="w-full flex flex-col px-4 py-2 bg-white rounded justify-center items-center mt-[20px] ">
                <div className="my-2 w-full flex justify-center items-center space-x-4">
                  {entryImages.length > 0 && (
                    <img
                      src={entryImages[0]}
                      className="w-28 h-60 rounded"
                      alt="selectimage"
                    />
                  )}

                  {select?.userImagePaths?.length > 0 ? (
                    <img
                      src={select?.userImagePaths[0]}
                      alt="event"
                      className="w-28 h-64 rounded"
                    />
                  ) : (
                    <img
                      src={NoImage}
                      className="w-28 h-64 rounded"
                      alt="selectimage"
                    />
                  )}
                </div>

                <div className="flex w-[300px] justify-center mt-2 text-xs space-x-[1px]">
                  <h2 className="w-[60px] text-left">Entry Time </h2>
                  <h2 className="">{`: ${getTime(
                    exit?.entryTime,
                    exit?.storeTimeZone
                  )}`}</h2>
                </div>

                <div className="flex w-[300px]  justify-center mt-2 text-xs space-x-[1px]">
                  <h2 className="w-[60px] text-left">Exit Time </h2>
                  <h2 className="">{`: ${getTime(
                    select?.exitTime,
                    select?.storeTimeZone
                  )}`}</h2>
                </div>
              </div>
            ) : (
              <div className="w-full  flex flex-col px-4 py-2 bg-white rounded">
                <p>Select One Match Entry.</p>
              </div>
            )}

            <button
              className="w-1/2 mx-auto px-2 py-1 bg-green-500 rounded text-white"
              onClick={next}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EntryEvents;
