import React, { useEffect, useState } from "react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { BiArrowBack } from "react-icons/bi";
import { useHistory, useLocation } from "react-router-dom";
import { useGet } from "../../../../../../api/useGet";
import { usePut } from "../../../../../../api/usePut";
import FadeLoader from "react-spinners/FadeLoader";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import NoImage from "../../../../../../Images/noImage1.jpg";
import { toast } from "react-toastify";
import ImageModal from "./ImageModal";
import {
  getTimeWithoutYear,
  getTimeOnly,
  getTime,
} from "../../../../../../logic/useGetTime";
import VideoController from "../../../../../../custom-control/VideoController";
import ProductListTable from "./ProductListTable";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { MerchantsDetails } from "../../../../../../utils/action_types";
import BackArrowButton from "../../../../../../components/buttons/BackarrowButton";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import ModalTileVideoForm from "../../shopperActivity/edit/ModalTileVideoForm";
import ConfirmationModal from "./model/ConfirmRejectModel";
import CoffeeEventModel from "./model/CoffeeEventModel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const AlertDialog = ({ open, setOpen, handleConfirm }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle textAlign="center">
        Alert
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText textAlign="center">
          <br /> One or more products has quantity greater than 5. <br />
          <br /> Do you want to review?
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Button onClick={handleClose} variant="contained" color="success">
          Yes
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          color="error"
          sx={{ ml: 2 }}
        >
          No
        </Button>
      </DialogActions>
      <br />
    </Dialog>
  );
};

function FinalReview() {
  const history = useHistory();
  const {
    state: { data, navigationFrom },
  } = useLocation();
  console.log(
    "🚀 ~ file: FinalReview.js:33 ~ FinalReview ~ navigationFrom:",
    navigationFrom
  );
  console.log(
    "🚀 ~ file: FinalReview.js:33 ~ FinalReview ~ datzzzza:",
    data?.status
  );
  const [videoUrl, setVideoUrl] = useState();
  const [sessionAllVideos, setSessionAllVideos] = useState();
  const [storeData, setStoreData] = useState([]);
  console.log(
    "🚀 ~ file: FinalReview.js:28 ~ FinalReview ~ storeData:",
    storeData
  );

  const [submitItems, setSubmitItems] = useState([]);

  const [apicall, setApiCall] = useState(true);

  const [isTileVideoModalOpen, setIsTileVideoModalOpen] = useState(false);

  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const [isCoffeeEventModal, setIsCoffeeEventModal] = useState(false);

  const handleCloseCoffevent = () => setIsCoffeeEventModal(false);
  const handleOpenCoffevent = () => setIsCoffeeEventModal(true);
  const [open, setOpen] = useState(false);

  const onReviewSubmit = (id) => {
    setSubmitItems([...submitItems, id]);
  };

  const { data: images } = useGet(
    `${process.env.REACT_APP_STORE_URL}/user-activities?eventStatus.equals=${
      navigationFrom && navigationFrom === "superviser"
        ? "CHECKEDOUT_IN_REVIEW"
        : "CHECKEDOUT"
    }&sort=entryTime,asc&cartRef.equals=${
      data?.sessionRef
    }&eventTime.greaterThanOrEqual=${
      data?.entryTime
    }&eventTime.lessThanOrEqual=${data?.checkoutDate}&storeCode.equals=${
      data?.storeCode
    }`,
    "images"
  );

  const { data: storeCarts } = useGet(
    `${process.env.REACT_APP_SHOPPER_URL}/stores/carts?sessionRef.equals=${data?.sessionRef}&sort=createdDate,desc&page=0&size=50`,
    ["storesCart", apicall]
  );

  const { data: productList } = useGet(
    `${process.env.REACT_APP_BASE_URL}/scale-products?sort=createdDate%2Cdesc&rootOrgId.equals=${data?.rootOrgId}&retailStoreId.equals=${data?.storeId}&projection=ScaleProductListDTO&size=10&page=0`,
    "productList",
    data?.rootOrgId == 7601
  );

  const { data: storeCartsWithRackInfo, isLoading } = useGet(
    `${process.env.REACT_APP_STORE_URL}/store-carts?cartRef.equals=${data?.sessionRef}&sort=eventTime,desc&size=50`,
    "storeCartswithRackInfo"
  );

  const filterArray = () => {
    let temp = [];
    if (images[0]?.shopperVideoPaths?.length > 0) {
      temp = images[0]?.shopperVideoPaths?.filter(
        (ele) => ele?.name !== "ShopperLoop"
      );
    }
    return temp;
  };

  useEffect(() => {
    if (images?.length > 0) {
      setSessionAllVideos(filterArray());
      setVideoUrl(filterArray()[0]);
    }
  }, [images]);

  const { mutate } = usePut(
    data?.status == "APPROVED_IN_REVIEW" || data?.status == "REJECTED_IN_REVIEW"
      ? `${process.env.REACT_APP_SHOPPER_URL}/stores/supervisor-finalize`
      : `${process.env.REACT_APP_SHOPPER_URL}/stores/cart-finalize`,
    "hawkeyeList"
  );

  const { mutate: updateCartItem } = usePut(
    `${process.env.REACT_APP_SHOPPER_URL}/stores/modify-cart-item`,
    "updateCartItem"
  );

  const onUpdate = (item) => {
    console.log("🚀 ~ file: FinalReview.js:101 ~ onUpdate ~ item:", item);
    try {
      const paylaod = {
        id: storeData?.id,
        storeCartItems: [
          {
            id: item?.data?.id,
            quantity: item?.data?.reviewedQuantity,
          },
        ],
      };

      updateCartItem(paylaod);
      toast.success("updated sucessfully");
    } catch (err) {
      toast.error(err.message);
    }
  };

  const [loading, setLoading] = useState(true);

  const onCellValueChanged = (params) => {
    let updatedCartData = { ...storeData };

    const foundCartItem = updatedCartData?.storeCartItems?.find(
      (cartItem) => cartItem.id === params?.data?.id
    );

    if (foundCartItem) {
      foundCartItem.reviewedQuantity = params.data.reviewedQuantity;
      setStoreData(updatedCartData);
    }

    setStoreData(updatedCartData);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (storeCarts?.length > 0) {
      setStoreData(storeCarts[0]);
    }
  }, [storeCarts]);

  const findRack = (extRef, storeCartsWithRackInfo) => {
    if (extRef && storeCartsWithRackInfo.length > 0) {
      let find = storeCartsWithRackInfo?.find((ele) =>
        extRef.includes(ele.cartItemRef)
      );

      return find?.scaleInternalCode;
    }
  };

  const id = parseInt(JSON.parse(localStorage.getItem("dashboard-data"))?.id);

  const onOpenTileVideoModal = () => {
    setIsTileVideoModalOpen(true);
  };

  const onCloseTileVideoModal = () => {
    setIsTileVideoModalOpen(false);
  };

  if (loading)
    return (
      <div className="bg-black bg-opacity-80 absolute inset-0 flex items-center justify-center">
        <div className="w-full h-20 absolute z-10 text-white flex flex-col items-center justify-center">
          <h2 className="">
            {" "}
            {localStorage.getItem("way") === "indirect"
              ? `please wait`
              : `There are no open items for shopper duration in the store.`}
          </h2>
          <FadeLoader
            color="#FFFFFF"
            className=""
            loading={loading}
            size={150}
          />
        </div>
      </div>
    );

  if (isLoading)
    return (
      <div className="bg-black bg-opacity-80 absolute inset-0 flex items-center justify-center">
        <div className="w-full h-20 absolute z-10 text-white flex flex-col items-center justify-center">
          <h2 className="">
            {" "}
            {localStorage.getItem("way") === "indirect"
              ? `please wait`
              : `There are no open items for shopper duration in the store.`}
          </h2>
          <FadeLoader
            color="#FFFFFF"
            className=""
            loading={loading}
            size={150}
          />
        </div>
      </div>
    );

  const confirm = () => {
    if (
      storeData?.storeCartItems?.some((item) => item?.reviewedQuantity > 5) &&
      open === false
    ) {
      setOpen(true);
      return;
    }
    try {
      mutate({ ...storeData, status: "APPROVED" });
      setLoading(true);
      const timer = setTimeout(() => {
        setLoading(false);
        history.push("/support/shopper-activity");
      }, 2000);
      return () => clearTimeout(timer);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const rejected = () => {
    mutate({ ...storeData, status: "REJECTED" });
    history.push("/support/hawkeye");
  };

  const goBack = () => {
    history.goBack();
    localStorage.setItem("way", "reviewback");
  };

  const columnDefs = [
    {
      headerName: "Rack",
      field: "scaleInternalCode",
      cellRendererFramework: (params) => {
        return (
          <div className="">
            <p className="font-bold">
              {/* {findRack(params?.data?.externalRef, storeCartsWithRackInfo)
                ? findRack(params?.data?.externalRef, storeCartsWithRackInfo)
                : " - "} */}
              {params?.data?.scaleInternalCode
                ? params?.data?.scaleInternalCode
                : "-"}
            </p>
          </div>
        );
      },
    },
    {
      headerName: "Image",
      field: "imageUrl",
      cellRendererFramework: (params) => (
        <>
          {params?.data?.imageUrl ? (
            <div className="w-10 h-10  flex items-center justify-center">
              <img src={params?.data?.imageUrl} alt="productImg" />
            </div>
          ) : (
            <div className="w-[46px] h-[46px]  flex items-center justify-center">
              <img src={NoImage} alt="No Image" />
            </div>
          )}
        </>
      ),
    },
    {
      headerName: "Product Name",
      field: "name",
      minWidth: 300,
      cellRendererFramework: (params) => {
        console.log("🚀 ~ FinalReview ~ params:", params);
        return (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <p>{params?.data?.name}</p>
            </div>
          </>
        );
      },
    },
    {
      headerName: "Qty *",
      field: "reviewedQuantity",

      editable: false,
      cellRendererFramework: (params) => {
        return (
          <p className="w-full px-1">
            <RemoveIcon
              sx={{ cursor: "pointer", mr: 2, background: "#f0f0f0" }}
              onClick={() => {
                if (params?.data?.reviewedQuantity == 0) return;
                params?.setValue(params?.data?.reviewedQuantity - 1);
                onCellValueChanged(params);
              }}
            />
            {params?.data?.reviewedQuantity}
            <AddIcon
              sx={{ cursor: "pointer", ml: 2, background: "#f0f0f0" }}
              onClick={() => {
                params?.setValue(params?.data?.reviewedQuantity + 1);
                onCellValueChanged(params);
              }}
            />
          </p>
        );
      },
    },
    {
      headerName: "Time",
      field: "transactionDate",

      cellRendererFramework: (params) => {
        return (
          <p className="">
            {getTimeOnly(
              params?.data?.transactionDate,
              params?.data?.storeTimeZone
            )}
          </p>
        );
      },
    },
    {
      headerName: "Action",
      field: "id",

      cellRendererFramework: (params) => {
        console.log(
          "🚀 ~ file: FinalReview.js:316 ~ FinalReview ~ params:",
          params
        );
        return <Button onClick={() => onUpdate(params)}>Save</Button>;
      },
    },
    {
      headerName: "",
      fieldName: "imageUrl",
      checkboxSelection: true,
      maxWidth: 60,
    },
  ];

  const handleApiCall = async (productData) => {
    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_SHOPPER_URL}/stores/modify-cart`;
      await axios({
        method: "PUT",
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
        data: productData,
      });
      setApiCall(!apicall);
      toast.success("Item added!!");
      setLoading(false);
    } catch (error) {
      setLoading(false);

      // Handle any errors
      toast.error(error?.message);
      // console.error(error);
    }
  };

  const handleBack = () => history.goBack();

  const handleReject = () => {
    // Perform rejection logic here
    rejected();

    // Close the confirmation modal
    setConfirmationModalOpen(false);
  };

  const isStoreCartItems = storeData?.storeCartItems?.some(
    (item) => item?.reviewedQuantity > 0
  );

  return (
    <div className="w-full h-[calc(100vh)] px-8 flex flex-col justify-between items-center py-4">
      <div className="w-[100%] flex justify-between bg-gray-200 p-2">
        <div className="w-[40%] flex justify-start items-center space-x-4">
          <BackArrowButton onClick={() => handleBack()} />
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <img
              src={
                MerchantsDetails.hasOwnProperty(storeData?.storeCode)
                  ? MerchantsDetails?.[storeData?.storeCode]?.imgUrl
                  : "/images/noImage.jpg"
              }
              width="50px"
              height="auto"
              alt="/images/noImage.jpg"
            />
            <Typography variant="body2">{storeData?.storeCode}</Typography>
          </Box>
          <div
            className="flex flex-col justify-start"
            style={{ margin: "10px" }}
          >
            <div className="flex flex-row justify-between">
              <p className="font-bold mr-1">Entry Time </p>
              <Tooltip
                title={`Timezone: ${
                  data?.storeTimeZone ? data?.storeTimeZone : "CET"
                }`}
                arrow
              >
                <p>{` :  ${getTime(data?.entryTime, data?.storeTimeZone)} `}</p>
              </Tooltip>
            </div>
            <div className="flex flex-row justify-between">
              <p className="font-bold">Exit Time </p>
              <Tooltip
                title={`Timezone: ${
                  data?.storeTimeZone ? data?.storeTimeZone : "CET"
                }`}
                arrow
              >
                <p>{` :  ${getTime(
                  data?.checkoutDate,
                  data?.storeTimeZone
                )}`}</p>
              </Tooltip>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "25%",
          }}
        >
          <Typography variant="h6" fontWeight={700}>
            Cart Summary
          </Typography>
          <Typography variant="h6">{storeData?.sessionRef}</Typography>
        </div>
        <div className="w-[40%] flex justify-end items-center space-x-[6px] mr-2">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              height: "100%",
            }}
          >
            <Button variant="contained" onClick={handleOpenCoffevent}>
              Open coffee events
            </Button>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Button
              variant="outlined"
              sx={{ mb: 1 }}
              onClick={onOpenTileVideoModal}
            >
              Request Tile Videos
            </Button>
            {localStorage.getItem("navigate") === "approved" ||
            localStorage.getItem("navigate") === "rejected" ? (
              <button
                className={`focus:outline-none ${
                  localStorage.getItem("navigate") === "approved"
                    ? "bg-green-500"
                    : "bg-red-500"
                } ml-1 text-white font-bold py-1.5 px-4 rounded-full inline-flex items-center`}
              >
                <span className="mx-2 text-xl capitalize">Order Summary</span>
              </button>
            ) : (
              <Button
                variant="contained"
                onClick={() =>
                  history.push({
                    pathname: "/support/match/openEvents/AddItem",
                    state: {
                      data: storeData,
                      entryTime: data?.entryTime,
                      storeTimeZone: data?.storeTimeZone,
                      checkoutDate: data?.checkoutDate,
                    },
                  })
                }
                startIcon={<AiOutlineShoppingCart />}
              >
                Add Item
              </Button>
            )}
          </Box>
        </div>
      </div>

      {/* shopper's images */}
      <div className={`w-full h-44 flex items-center justify-between`}>
        <div className="w-[60%] h-full flex justify-between items-center">
          <div className="w-full flex space-x-2">
            {images?.length > 0 ? (
              images?.map((obj, index) => (
                <div key={index} className="rounded">
                  <ImageModal obj={obj} index={index} />
                </div>
              ))
            ) : (
              <img
                src={NoImage}
                alt="NoImage"
                className="w-44 h-44 rounded cursor-pointer"
              />
            )}
          </div>
        </div>
        {storeData?.rootOrgId == 7601 && (
          <div
            className="w-[80%] h-full flex justify-between items-center"
            style={{ marginLeft: "16px" }}
          >
            <ProductListTable
              productList={productList}
              handleApiCall={handleApiCall}
              storeData={storeData}
            />
          </div>
        )}
      </div>

      {/* session video & table */}
      <div className="w-full h-[60%] flex items-center my-1 space-x-4">
        <div className="w-[60%] h-[100%]">
          {videoUrl ? (
            <div className="w-full h-[100%] bg-black">
              <VideoController url={videoUrl?.videoUrl} playRate={4} />
            </div>
          ) : (
            <div className="w-full h-[100%] flex justify-center items-center border border-gray-400">
              No video available
            </div>
          )}
        </div>

        <div className="ag-theme-alpine w-[80%] h-full text-left">
          <AgGridReact
            defaultColDef={{
              flex: 1,
              resizable: true,
            }}
            suppressRowClickSelection={true}
            checkboxSelection={true}
            rowSelection={"multiple"}
            pagination={false}
            rowHeight={90}
            rowData={
              storeData?.storeCartItems?.length > 0 &&
              Array.isArray(storeData?.storeCartItems)
                ? storeData?.storeCartItems
                : []
            }
            columnDefs={columnDefs}
            // onCellValueChanged={onCellValueChanged}
            onRowSelected={({ data }) => onReviewSubmit(data?.id)}
          />
          {/* <ProductReviewModal
             open={edit}
             onCloseModal={onCloseEdit}
             data={formData}
           /> */}
        </div>
      </div>

      {/* session video btn */}
      <div className="w-full h-16 flex mt-2 space-x-2">
        <div className="w-[90%] grid grid-cols-9 gap-2">
          {sessionAllVideos?.map((ele, index) => {
            return (
              <button
                key={index}
                onClick={() => setVideoUrl(ele)}
                className={`bg-gray-200 px-4 py-1 ${
                  ele?.name === videoUrl?.name && "bg-[#558b2f] text-white"
                }`}
              >
                {ele?.name}
              </button>
            );
          })}
        </div>

        <div className="w-[10%] h-full flex  items-center ">
          {(localStorage.getItem("navigate") === "inReview" ||
            localStorage.getItem("navigate") === "inflight" ||
            localStorage.getItem("navigate") === "openEntryEvents" ||
            localStorage.getItem("navigate") === "processedEntryEvents") && (
            <div className="w-full h-full flex flex-col justify-between items-center space-y-2">
              {storeData?.storeCartItems?.length > 0 && isStoreCartItems ? (
                <>
                  <button
                    onClick={confirm}
                    className="bg-green-600 w-28 py-1 rounded text-white focus:outline-none "
                  >
                    Confirm
                  </button>
                  <button
                    onClick={() => setConfirmationModalOpen(true)}
                    className="bg-red-600 w-28 py-1 rounded text-white focus:outline-none  "
                  >
                    Reject
                  </button>
                </>
              ) : (
                <button
                  onClick={() => setConfirmationModalOpen(true)}
                  className="bg-red-600 w-28 py-1 rounded text-white focus:outline-none  "
                >
                  Reject
                </button>
              )}
            </div>
          )}
        </div>
      </div>

      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
        onReject={handleReject}
      />

      {isTileVideoModalOpen && (
        <ModalTileVideoForm
          open={isTileVideoModalOpen}
          onCloseModal={onCloseTileVideoModal}
          cartRef={storeData?.sessionRef}
          entryTime={data?.entryTime}
          exitTime={data?.checkoutDate}
          storeCode={storeData?.storeCode}
        />
      )}

      {isCoffeeEventModal && (
        <CoffeeEventModel
          open={isCoffeeEventModal}
          handleClose={handleCloseCoffevent}
          data={storeData}
        />
      )}

      {open && (
        <AlertDialog open={open} setOpen={setOpen} handleConfirm={confirm} />
      )}
    </div>
  );
}

export default FinalReview;
