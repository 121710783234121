import React, { useState, useMemo } from "react";
import { IoIosClose } from "react-icons/io";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import ImageUploader from "react-images-upload";
import { usePut } from "../../../../../../../../api/usePut";
import { useGet } from "../../../../../../../../api/useGet";
import i18next from "i18next";
import { Languages2 } from "../../../../../../../../Languages";

const detectedLan = (value) => {
  return Languages2?.find((item) => item?.code === value);
};

function UpdateLocal(props) {
  const [select, setSelect] = useState({
    lng: { value: "", label: detectedLan(props.data?.lang)?.label, code: "" },
    uom: { value: "", label: props?.data?.unitOfMeasure },
  });

  const lang = select?.lng?.value ? select.lng?.value : props?.data?.lang;

  const editLocal = usePut(
    `${process.env.REACT_APP_BASE_URL}/products/${props?.productId}/${lang}/${props?.data?.id}`,
    "productDetail"
  );

  const uomList = useGet(
    `${
      process.env.REACT_APP_BASE_URL
    }/unit-of-measures?sort=createdDate,desc&rootOrgId=${parseInt(
      JSON.parse(localStorage.getItem("dashboard-data"))?.id
    )}`,
    "uomList"
  );

  const [title, setTitle] = useState("Brand");
  const { t } = useTranslation();

  const [input, setInput] = useState({
    name: props?.data?.name,
    title: props?.data?.title,
    description: props?.data?.title,
  });

  const onSelect = (action, e) => {
    const name = e.name;
    setSelect({
      ...select,
      [name]: { value: action.value, label: action.label, code: action?.code },
    });

    if (name === "lng") {
      //console.log("lng: ", action.code)
      i18next.changeLanguage(action?.code);
    }
  };

  const onChange = (e, name) => {
    const value = e.target.value;
    setInput({ ...input, [name]: value });
  };

  const submit = () => {
    const data = {
      name: input?.name ? input?.name : props?.data?.name,
      id: props?.data?.id,
      description: input?.description
        ? input?.description
        : props?.data?.description,
      title: input?.title ? input?.title : props?.data?.title,
      lang: select?.lng?.value ? select?.lng?.value : props?.data?.lang,
      imageUrl: "",
      unitOfMeasure: select?.uom?.value
        ? select?.uom?.value
        : props?.data?.unitOfMeasure,
    };

    //editLocal.mutate(data);

    i18next.changeLanguage("en");
    props?.onCloseModal();
  };

  return (
    <>
      {props?.open && (
        <div className="bg-black bg-opacity-80 absolute inset-0 z-10 flex items-center justify-center">
          <div className="w-96 h-96  text-left select-none z-10 absolute top-1/2 left-1/2   transform -translate-x-1/2 -translate-y-1/2 ">
            <div className="w-full h-full bg-gray-100  rounded-lg shadow-lg ">
              <div className="w-full h-8 flex text-gray-500 px-1 justify-end">
                <IoIosClose
                  onClick={props?.onCloseModal}
                  className="h-10 w-10 cursor-pointer"
                />
              </div>

              <div className="mt-4 flex flex-col justify-center items-center">
                <div style={{ width: "255px" }}>
                  <Select
                    placeholder={t("Languages")}
                    value={select?.lng}
                    onChange={onSelect}
                    name="lng"
                    options={Languages2}
                  />
                </div>

                <input
                  placeholder={t("productName")}
                  value={input?.name}
                  className="w-64 border rounded my-2 px-2 focus:outline-none"
                  onChange={(e) => onChange(e, "name")}
                />

                <input
                  placeholder={t("localTitle")}
                  value={input?.title}
                  className="w-64 border rounded my-2 px-2 focus:outline-none"
                  onChange={(e) => onChange(e, "title")}
                />

                <div style={{ width: "255px" }} className="my-2">
                  <Select
                    placeholder={t("uom")}
                    maxMenuHeight={162}
                    value={select.uom}
                    name="uom"
                    onChange={onSelect}
                    // menuPortalTarget={document.body}
                    // menuPosition={"fixed"}
                    // styles={customStyles}
                    options={uomList?.data?.map((item, index) => {
                      return {
                        label: item.name,
                        value: item.name,
                        key: item.id,
                      };
                    })}
                  />
                </div>

                <textarea
                  placeholder={t("description")}
                  value={input?.description}
                  className="w-64 border rounded my-2 px-2 focus:outline-none"
                  onChange={(e) => onChange(e, "description")}
                />

                {/* <div className="h-16 w-full mx-4">
                                    <ImageUploader
                                        {...props}
                                        withIcon={false}
                                        withPreview={true}
                                        label={""}
                                        onChange={props?.onProject}
                                        imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                                        buttonText={t('uploadProductImage')}
                                    />
                                </div> */}

                <div className="flex my-2">
                  <button
                    onClick={submit}
                    className="w-32 h-9 bg-green-500 rounded flex items-center justify-center text-white"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default UpdateLocal;
