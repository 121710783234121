import React, { useState, useEffect } from "react";
import Action from "./Action";
import { GrView } from "react-icons/gr";
import { FiEdit } from "react-icons/fi";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import Select from "react-select";
import DashboardRoute from "./DashboardRoute";

import ModalView from "../../../../../../../modal/view/ModalTax";
import ModalEdit from "../../../../../../../modal/edit/ModalTax";
import axios from "axios";
import {toast} from 'react-toastify'
import {useHistory} from 'react-router-dom'

function All({ isLoading, error, data, url, viewUrl, editUrl, sortedData }) {
  const [checked, setChecked] = useState(false);
  const [value, setValue] = useState("");

  const [selectedData, setSelectedData] = useState({});
  const [tax, setTax] = useState({});
  const history = useHistory();

  const [view, setView] = useState(false);
  const onOpenView = () => setView(true);
  const onCloseView = () => setView(false);

  const [edit, setEdit] = useState(false);
  const onOpenEdit = () => setEdit(true);
  const onCloseEdit = () => setEdit(false);

  const fetchMerchant = async (url) => {

    try {
      const res = await axios({
        method: "GET",
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      });
  
      return res;
    } catch ({message}) {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message)
      }
    }
    
  };

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };

  const changeHandler = (value) => {
    setValue(value);
  };

  const editFn = async (data) => {
    //console.log("id: ", data?.id);
    setSelectedData(data);

    const res = await fetchMerchant(
      `${process.env.REACT_APP_BASE_URL}/tax-categories/${data?.id}`
    );

    setTax(res?.data);
    onOpenEdit();
  };

  const viewFn = async (data) => {
    const res = await fetchMerchant(
      `${process.env.REACT_APP_BASE_URL}/tax-categories/${data?.id}`
    );
    setTax(res?.data);
    setSelectedData(data);
    onOpenView();
  };

  return (
    <div className=" w-full h_das_table">
      <div className="flex h-10 mt-2 w-full items-center justify-between py-8 px-4">
        <input
          className="w-2/5 focus:outline-none py-1.5 px-2 border-2 border-gray-200 rounded-lg"
          placeholder="Search"
        />

        <div style={{ width: "40%" }}>
          <Select
            placeholder="Select"
            className="text-left"
            maxMenuHeight={162}
            value={value}
            onChange={changeHandler}
            options={sortedData?.map((item, index) => {
              return {
                label: item,
                value: item,
                key: index,
              };
            })}
          />
        </div>
        <button className="bg-green-600 py-1.5 px-5 mr-7 rounded text-white">
          Clear filters
        </button>
      </div>

      <div className="ag-theme-alpine  w-full h-full text-left">
        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            resizable: true,
          }}
          suppressRowClickSelection={true}
          rowSelection={"multiple"}
          pagination={true}
          paginationPageSize={10}
          rowData={data}
        >
          <AgGridColumn
            headerName="Category Name"
            field="name"
            minWidth={400}
            sortable={true}
            headerCheckboxSelection={true}
            headerCheckboxSelectionFilteredOnly={true}
            checkboxSelection={true}
            cellRendererFramework={(params) => (
              <DashboardRoute params={params} />
            )}
          />
          <AgGridColumn
            headerName="Category Value"
            field="value"
            minWidth={400}
            sortable={true}
          />

          <AgGridColumn
            field="status"
            minWidth={100}
            cellRendererFramework={(params) => (
              <Toggle
                onChange={handleChange}
                defaultChecked={checked}
                //icons={{ checked: null, unchecked: null }}
                className="react-switch "
              />
            )}
          />
          <AgGridColumn
            field="action"
            minWidth={100}
            cellRendererFramework={(params) => (
              <>
                <div className="flex w-16 h-10 items-center">
                  {/* <div className="border-2 border-gray-200 rounded-l-lg p-1 ">
                    <GrView
                      className="w-5 h-5 cursor-pointer "
                      onClick={() => viewFn(params?.data)}
                    />
                  </div> */}
                  <div className="border-2 border-gray-200 rounded-lg p-1">
                    <FiEdit
                      className="w-5 h-5 cursor-pointer"
                      onClick={() => editFn(params?.data)}
                    />
                  </div>
                </div>
              </>
            )}
          />
        </AgGridReact>
      </div>

      <ModalView
        open={view}
        onCloseModal={onCloseView}
        data={selectedData}
        tax={tax}
      />

      <ModalEdit
        open={edit}
        onCloseModal={onCloseEdit}
        data={selectedData}
        tax={tax}
      />

    </div>
  );
}

export default All;
