import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useHistory } from "react-router-dom";

const useOpenItem = (data) => {
  const [item, setItem] = useState();
  const history = useHistory();
  const [tab, setTab] = useState("assigned");

  const [assign, setAssign] = useState(false);

  const [select, setSelect] = useState({
    product: {
      value: "",
      label: "Product",
    },
  });

  useEffect(() => {
    if (data) {
      setItem(data);
    }
  }, [data]);

  useEffect(() => {
    if (item?.productId !== null) {
      const prod = item?.productInfos?.find(
        (ele) => ele?.productId === item?.productId
      );
      setSelect({
        ...select,
        product: {
          value: { ...prod, scaleId: item?.scaleId },
          label: <img src={prod?.imageUrl} height="25px" width="25px" />,
        },
      });
    } else {
      setSelect({
        ...select,
        product: {
          value: "",
          label: "Pick",
        },
      });
    }
  }, [item]);

  const [reviewQty, setReviewQty] = useState();

  const onSelect = (payload, name) => {
    const { value, label } = payload;

    if (name === "product") {
      setItem({
        ...item,
        productId: payload?.value?.productId,
        productInfos: [{ ...value, scaleId: item?.scaleId }],
      });
      setSelect({
        ...select,
        [name]: { label: label, value: { ...value, scaleId: item?.scaleId } },
      });
    } else {
      setSelect({ ...select, [name]: { label: label, value: value } });
    }
  };

  const approval = (item, selectedShopper, status) => {
    if (reviewQty !== "") {
      const payload = {
        ...item,
        reviewedQuantity: reviewQty,
        syncStatus: status,
        userInfos: [
          {
            id: selectedShopper?.id,
            name: selectedShopper?.name,
            login: selectedShopper?.login,
            userRole: selectedShopper?.userType,
            cartRef: selectedShopper?.cartRef,
          },
        ],
      };

      console.log("payload: ", payload);

      axios({
        method: "PUT",
        url: `${process.env.REACT_APP_STORE_URL}/store-carts/${item?.id}/review-event`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
        data: payload,
      })
        .then((res) => {
          setItem(res?.data);
        })
        .catch(({ message }) => {
          if (message === "Request failed with status code 401") {
            history.push("/login");
          } else {
            toast.error(message);
          }
        });
    } else {
      toast.error("Enter qty");
    }
  };

  const assignFn = (item, selectedShopper) => {
    if (
      item?.syncStatus === "ERRORED" ||
      item?.syncStatus === "CART_REVIEWED"
    ) {
      return null;
    } else {
      if (select?.product?.value !== "") {
        approval(item, selectedShopper, "APPROVED");
        setAssign(!assign);
      } else {
        toast.error(<div className="normal-case">No product selected</div>);
      }
    }
  };

  const findQty = (data) => {
    let qty = 0;

    if (data?.estimatedQuantity === data?.reviewedQuantity) {
      qty = data?.reviewedQuantity;
    } else if (data?.reviewedQuantity === 0) {
      qty = data?.estimatedQuantity;
    } else {
      qty = data?.reviewedQuantity;
    }

    return qty;
  };

  useEffect(() => {
    if (item?.productInfos.length === 1) {
      setReviewQty(findQty(item?.productInfos[0]));
    } else {
      setReviewQty(findQty(select?.product?.value));
    }
  }, [select, item]);

  return {
    item,
    setItem,
    setReviewQty,
    reviewQty,
    assignFn,
    select,
    onSelect,
    tab,
  };
};

export { useOpenItem };