import React from "react";

function OrderSummaryHeader({ path, status }) {
  return (
    <div className="w-full rounded-lg bg-white flex items-center h-10 px-4">

      <div className="w-32">
        <h2 className="font-bold">Product Image</h2>
      </div>

      <div className="w-full h-full items-center flex justify-between ml-10">
        
        <div className="w-1/5">
          <h2 className="font-bold">Product Name</h2>
        </div>

        <div className="w-1/5 flex justify-center">
          <h2 className="font-bold">Tax Type</h2>
        </div>

        <div className="w-1/5 flex justify-center">
          <h2 className="font-bold">Price/Piece</h2>
        </div>
        <div className="w-1/5 flex justify-center">
          <h2 className="font-bold">Quantity</h2>
        </div>
        <div className="w-1/5 flex justify-center">
          <h2 className="font-bold">Price</h2>
        </div>

      </div>
    </div>
  );
}

export default OrderSummaryHeader;
