import React, { useState, useEffect, useMemo, useRef } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TextField2 } from "../../../validation/TextField2";
import { useHistory } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import Select from "react-select";
import { useAlert } from "react-alert";
import { usePost } from "../../../../api/usePost";
import { useLocation } from "react-router-dom";
import axios from "axios";

const optionsMode = [
  { value: "PROD", label: "PROD" },
  { value: "Dev", label: "Dev" },
];

let validate;
validate = Yup.object({
  name: Yup.string().required("Required"),
  deviceIdentifier: Yup.string().required("Required"),
  macAddress: Yup.string().required("Required"),
});

function CreateMachine() {
  const [title, setTitle] = useState("Machine");
  const alert = useAlert();
  const history = useHistory();

  const [retailer, setRetailer] = useState([]);
  const [retailerStore, setRetailerStore] = useState([]);

  const [select, setSelect] = useState({
    retailer: "",
    retailerStore: "",
    mode: "",
  });

  useEffect(() => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}/orgs?sort=createdDate,desc`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
    }).then((res) => {
      setRetailer(res?.data);
    });
  }, []);

  const onChangeSelect = (value, name) => {
    setSelect({ ...select, [name]: value });
  };

  useEffect(() => {
    if (select?.retailer) {
      axios({
        method: "GET",
        url: `${
          process.env.REACT_APP_BASE_URL
        }/retail-stores?sort=createdDate,desc&rootOrgId.equals=${parseInt(
          select?.retailer?.value
        )}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      }).then((res) => {
        setRetailerStore(res?.data);
      });
    }
  }, [select?.retailer]);

  const createMerchant = usePost(
    `${process.env.REACT_APP_BASE_URL}/machines`,
    "machineList"
  );

  return (
    <>
      <div className="w-full h_screen flex">
        <div className="w-full h-full flex justify-center items-center bg-gray-200 ">
          <Formik
            initialValues={{
              name: "",
              deviceIdentifier: "",
              macAddress: "",
            }}
            validationSchema={validate}
            onSubmit={async (values) => {
            
              if (values.name !== "" && values.email !== "") {
                try {
                  const payload = {
                    deviceIdentifier: values.deviceIdentifier,
                    name: values.name,
                    mode: select?.mode?.value,
                    rootOrgId: select?.retailer?.value,
                    macAddress: values.macAddress,
                    retailStore: {
                      id: select?.retailerStore?.value, //retailer-store id
                    },
                  }

                  //console.log("data: ", payload)
                  createMerchant.mutate(payload);

                  if (
                    !createMerchant.isError &&
                    createMerchant.error === null &&
                    !createMerchant.isSuccess &&
                    createMerchant.status === "idle"
                  ) {
                    history.goBack();
                    alert.success(
                      <div style={{ textTransform: "none" }}>
                        Successfully Added!
                      </div>
                    );
                  } else {
                    alert.error(
                      <div style={{ textTransform: "none" }}>
                        Please Check all Fields!
                      </div>
                    );
                  }
                } catch (error) {
                  alert.error(
                    <div style={{ textTransform: "none" }}>
                      you are offline!
                    </div>
                  );
                }
              }
            }}
          >
            {() => (
              <Form className="w-4/5 mx-auto h-auto lg:h-4/5 bg-white rounded-lg shadow-lg">
                <div className="w-full h-16 flex rounded-t-lg bg-nav text-white ">
                  <div className="w-10 p-2 h-full flex items-center">
                    <BiArrowBack
                      onClick={() => history.goBack()}
                      className="h-10 w-10 cursor-pointer"
                    />
                  </div>
                  <div className=" h-full px-6 justify-center flex flex-col  select-none">
                    <h2 className="font-bold text-xs lg:text-2xl text-left">
                      {`Create new ${title}`}
                    </h2>
            
                  </div>
                </div>

                <div className="w-11/12 mx-auto h-auto px-6 my-2">
                  <div class="grid h-80 grid-cols-1 lg:grid-cols-2 gap-x-12 gap-y-12 py-16">
                    <div>
                      <div className="flex items-center h-10">
                        <TextField2 label="Name" name="name" type="text" />
                      </div>
                    </div>
                    <div>
                      <div className="flex items-center h-10">
                        <TextField2
                          label="Device Identifier"
                          name="deviceIdentifier"
                          type="text"
                        />
                      </div>
                    </div>

                    <div>
                      <div className=" flex items-center h-10">
                        <TextField2
                          label="Mac Address"
                          name="macAddress"
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="flex items-center h-10 ">
                      <div className="flex w-full select-none">
                        <div style={{ width: "400px" }}>
                          <Select
                            options={optionsMode}
                            placeholder="Select Mode"
                            maxMenuHeight={162}
                            value={select?.mode}
                            onChange={(value) => onChangeSelect(value, "mode")}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex items-center h-10">
                      <div className="flex  w-full select-none">
                        <div style={{ width: "400px" }}>
                          <Select
                            placeholder="Select Merchant"
                            maxMenuHeight={162}
                            value={select?.retailer}
                            onChange={(value) =>
                              onChangeSelect(value, "retailer")
                            }
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            options={retailer?.map((item, index) => {
                              return {
                                label: item?.name,
                                value: item?.rootOrgId,
                              };
                            })}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex items-center h-10 ">
                      <div className="flex   w-full select-none">
                        <div style={{ width: "400px" }}>
                          <Select
                            placeholder="Search Retailer Store"
                            maxMenuHeight={132}
                            value={select?.retailerStore}
                            onChange={(value) =>
                              onChangeSelect(value, "retailerStore")
                            }
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            options={retailerStore?.map((item, index) => {
                              return {
                                label: item?.name,
                                value: item?.id,
                              };
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full h-16 flex justify-end items-center px-4 -mt-2">
                    <button
                      type="submit"
                      className="bg-green-500 rounded-lg px-6 py-3  focus:outline-none text-white"
                    >
                      <span className="h-full flex items-center">
                        <svg
                          class="svg-icon"
                          viewBox="0 0 20 20"
                          width="24px"
                          height="24px"
                        >
                          <path
                            fill="#8A2BE2"
                            d="M19.404,6.65l-5.998-5.996c-0.292-0.292-0.765-0.292-1.056,0l-2.22,2.22l-8.311,8.313l-0.003,0.001v0.003l-0.161,0.161c-0.114,0.112-0.187,0.258-0.21,0.417l-1.059,7.051c-0.035,0.233,0.044,0.47,0.21,0.639c0.143,0.14,0.333,0.219,0.528,0.219c0.038,0,0.073-0.003,0.111-0.009l7.054-1.055c0.158-0.025,0.306-0.098,0.417-0.211l8.478-8.476l2.22-2.22C19.695,7.414,19.695,6.941,19.404,6.65z M8.341,16.656l-0.989-0.99l7.258-7.258l0.989,0.99L8.341,16.656z M2.332,15.919l0.411-2.748l4.143,4.143l-2.748,0.41L2.332,15.919z M13.554,7.351L6.296,14.61l-0.849-0.848l7.259-7.258l0.423,0.424L13.554,7.351zM10.658,4.457l0.992,0.99l-7.259,7.258L3.4,11.715L10.658,4.457z M16.656,8.342l-1.517-1.517V6.823h-0.003l-0.951-0.951l-2.471-2.471l1.164-1.164l4.942,4.94L16.656,8.342z"
                          ></path>
                        </svg>
                        <span className="px-2">Create {title}</span>
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default CreateMachine;
