import React, { useState } from "react";
import ModalImg from "./ModalImg";
import NoImage from "../../../../../../Images/noImage.jpg";

function EntryImages({ url, index }) {
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  return (
    <div className="mx-1.5 w-40 h-44" key={index}>
      {url ? (
        <img
          src={url}
          alt="event"
          className="w-28 h-44 rounded cursor-pointer border-2 border-black"
          onClick={onOpenModal}
        />
      ) : (
        <img
          src={NoImage}
          alt="event"
          className="w-28 h-44 rounded cursor-pointer border-2 border-black"
         // onClick={onOpenModal}
        />
      )}

      <p className="font-bold relative bottom-6 text-blue-400 w-full text-left pl-1">{`Image# : ${
        index + 1
      }`}</p>

      {url && <ModalImg open={open} onCloseModal={onCloseModal} img={url} />}
      
    </div>
  );
}

export default EntryImages;
