import React from "react";
import moment from "moment";

import { Link } from "react-router-dom";

function DashboardRoute({ params }) {
  //console .log('navi: ', navigate)


  const clicked = () => {
    localStorage.setItem("exitData", JSON.stringify(params.data));
  }

  return (
    <>
      <Link
        to={{
          pathname: "/admin/match/matchEvent",
          //data: params,
        }}
        onClick={clicked}
        className="text-blue-900 font-bold"
      >

        <p>
          {moment(params?.data?.exitTime).format(
            "MMM Do YYYY h:mm A"
          )}
        </p>
      </Link>
    </>
  );
}

export default DashboardRoute;
