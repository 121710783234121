import React, { useState } from "react";
import { FiLogOut, FiGlobe } from "react-icons/fi";
import * as s from './MainView.styles';
import Dashboard from '../../Dashboard'
import Header from '../Header'
import Select from 'react-select'
import i18next from 'i18next'

const options = [
  { value: 'en', label: 'English' },
  { value: 'de', label: 'Germany' },

]

const MainView = () => {
  const [select, setSelect] = useState({
    lng: { value: '', label: <FiGlobe className="ml-7 h-6 w-6 text-blue-500" /> }
  })
  const onSelect = (value) => {
    setSelect(value)
    //console.log("value: ", value?.value)
    i18next.changeLanguage(value?.value)

  }
  return (
    <s.MainViewContainer>

      {/* <div className='absolute right-64 top-3'>

        <div style={{ width: '120px' }}>
          <Select
            value={select?.lng}
            onChange={onSelect}
            options={options} />
        </div>

      </div> */}

      <Dashboard />

    </s.MainViewContainer>
  )
}

export default MainView