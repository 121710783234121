import React from "react";
import TableStr from "./TableStr";
import Enabled from "./options/Enabled";
import Disabled from "./options/Disabled";
import All from "./options/All";
import {useLocation} from "react-router-dom";


function SubCategory() {
  const location = useLocation();
  return (
    <div className="h-full w-full">
      <TableStr
        title={"Product Sub-Category"}
        url={ "/admin/merchant/dashboard/productManagement/uom/createUom"}
        viewUrl={"/admin/location/view"}
        editUrl={"/admin/location/edit"}
        listUrl={`${process.env.REACT_APP_BASE_URL}/categories?rootOrgId.equals=${parseInt(JSON.parse(localStorage.getItem("dashboard-data"))?.id)}&parentId.equals=${location?.state?.data?.id}`}
        getName={"subcategoryList"}
        Enabled={Enabled}
        Disabled={Disabled}
        parentData={location?.state?.data}
        All={All}
        showButton={false}
        Modal={"ModalSubCategory"}
        dashboard={true}
      />
    </div>
  );
}

export default SubCategory;
