import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TextField2 } from "../../../../../../validation/TextField2";
import { useHistory, useLocation } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { useAlert } from "react-alert";
import { usePut } from "../../../../../../../api/usePut";
import { useDisplayImage } from "../../../../../../../hook/useDisplayImage";

import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";

const login = [{ value: "system", label: "system" }];
const authorities = [
  { value: "ROLE_ADMIN", label: "ROLE_ADMIN" },
  { value: "system_user", label: "system_user" },
];

let validate;
validate = Yup.object({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().email().typeError("Not a number").required("Required"),
  phone: Yup.number().required("Required"),
});

const useStyles = makeStyles((theme) => ({
  root: {
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  large: {
    width: theme.spacing(25),
    height: theme.spacing(25),
  },
}));

function EmployeeEdit(props) {
  const [title, setTitle] = useState("Employee");
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  let initialValues = location?.state?.initialValues;
  const [gender, setGender] = useState(
    initialValues.gender ? initialValues.gender : null
  );
  const [status, setStatus] = useState(false);

  //console.log("Id: ", initialValues)

  const [select, setSelect] = useState({
    login: { value: "", label: "Select System" },
    authoritiesName: { value: "", label: "Select Authorizaties" },
  });

  const handleSelect = (action, e) => {
    const name = e.name;
    const value = action.value;
    setSelect({ ...select, [name]: { value: value, label: value } });
  };

  const genderType = (e) => {
    setGender(e.target.value);
  };

  const [image, setImage] = useState("");

  //console.log("inEmployee: ", initialValues)

  const editEmployee = usePut(
    `${process.env.REACT_APP_BASE_URL}/users`,
    "employeesList"
  );

  const classes = useStyles();

  const { result, uploader } = useDisplayImage();

  return (
    <>
      <div className="w-full h-screen flex text-left select-none">
        <div className="w-full h-full flex justify-center items-center bg-gray-200 ">
          <Formik
            initialValues={initialValues}
            validationSchema={validate}
            onSubmit={(values) => {
              console.log("values: ", values);
              if (values.name !== "" && values.email !== "") {
                try {
                  var body = {
                    userInfo: {
                      phone: values.phone,
                      gender: gender.toUpperCase(),
                      rootOrgId: parseInt(
                        JSON.parse(localStorage.getItem("dashboard-data"))?.id
                      ),
                    },
                    login: localStorage.getItem("user-name"),
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    // activated: status,
                    // authoritiesName: select.authoritiesName.value,
                    // authorities: [select.authoritiesName.value],
                  };
                  editEmployee.mutate(body);

                  if (editEmployee.isError === false) {
                    history.goBack();
                    alert.success(
                      <div style={{ textTransform: "none" }}>
                        Successfully Created!
                      </div>
                    );
                  } else if (
                    editEmployee?.error?.response?.data?.errorKey ===
                    "userexists"
                  ) {
                    alert.error(
                      <div style={{ textTransform: "none" }}>
                        {editEmployee?.error?.response?.data?.title}
                      </div>
                    );
                  } else {
                    alert.error(
                      <div style={{ textTransform: "none" }}>
                        Please Check all Fields!
                      </div>
                    );
                  }
                } catch (error) {
                  alert.error(
                    <div style={{ textTransform: "none" }}>
                      you are offline!
                    </div>
                  );
                }
              }
            }}
          >
            {({ onSubmit }) => (
              <Form className="w-full h-auto m-8 bg-white rounded-lg shadow-lg">
                <div className="w-full h-16 flex bg-dash text-white rounded-t-lg">
                  <div className="w-10 p-2 h-full flex items-center">
                    <BiArrowBack
                      onClick={() => history.goBack()}
                      className="h-10 w-10 cursor-pointer"
                    />
                  </div>
                  <div className="w-full h-full px-7 flex flex-col justify-center">
                    <h2 className="font-bold text-2xl">{`Edit ${title}`}</h2>
                  </div>
                </div>

                <div className="w-full h-0.88">
                  <div className="flex">
                    <div className="w-4/6  px-6 mt-2 ">
                      <div className="w-full h-20 flex mt-4  items-center">
                        <div className="flex  px-4 mr-4   w-full items-center select-none">
                          <TextField2
                            label="Phone No"
                            name="phone"
                            type="text"
                          />
                        </div>

                        <div className="flex  px-4 ml-4   w-full items-center select-none">
                          <h2 className="text-base pr-4">Gender: </h2>
                          <div className="flex h-10 items-center w-64 justify-between">
                            <input
                              type="radio"
                              name="gender"
                              value={gender === "MAle" ? gender : "Male"}
                              onChange={genderType}
                            />
                            <span>Male</span>
                            <input
                              type="radio"
                              name="gender"
                              value={gender === "FeMAle" ? gender : "FeMale"}
                              onChange={genderType}
                            />
                            <span>Female</span>
                            <input
                              type="radio"
                              name="gender"
                              value={gender === "OTHER" ? gender : "OTHER"}
                              onChange={genderType}
                            />
                            <span>Other</span>
                          </div>
                        </div>
                      </div>

                      <h2 className="ml-4 mt-4">Basic Details : </h2>

                      <div className="w-full h-20 flex mt-1  items-center">
                        <div className="flex  px-4 mr-4   w-full items-center select-none">
                          <TextField2
                            label="First Name"
                            name="firstName"
                            type="text"
                          />
                        </div>

                        <div className="flex  px-4 ml-4   w-full items-center select-none">
                          <TextField2
                            label="Last Name"
                            name="lastName"
                            type="text"
                          />
                        </div>
                      </div>

                      <div className="w-full h-20 flex items-center">
                        <div className="flex  px-4   w-full items-center select-none">
                          <TextField2 label="Email" name="email" type="email" />
                        </div>
                      </div>
                    </div>

                    <div className="w-1/3 h-72 my-2 mr-8  flex flex-col justify-center items-center">
                      <div className="w-full h-52 mt-6  rounded-lg  flex justify-center items-center">
                        <div className={classes.root}>
                          <input
                            accept="image/*"
                            className={classes.input}
                            id="icon-button-file"
                            type="file"
                            onChange={(e) => {
                              setImage(e.target.files[0]);
                              uploader(e);
                            }}
                          />
                          <label htmlFor="icon-button-file">
                            <IconButton
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                            >
                              <Avatar
                                src={result}
                                className={`${classes.large}`}
                              />
                            </IconButton>
                          </label>
                        </div>
                      </div>

                      <div className="w-full h-12 mt-2  flex justify-center items-center">
                        <button
                          type="submit"
                          onClick={onSubmit}
                          className="bg-green-500 rounded-lg px-6 py-3 focus:outline-none text-white"
                        >
                          <span className="h-full flex items-center">
                            <svg
                              class="svg-icon"
                              viewBox="0 0 20 20"
                              width="24px"
                              height="24px"
                            >
                              <path
                                fill="#8A2BE2"
                                d="M19.404,6.65l-5.998-5.996c-0.292-0.292-0.765-0.292-1.056,0l-2.22,2.22l-8.311,8.313l-0.003,0.001v0.003l-0.161,0.161c-0.114,0.112-0.187,0.258-0.21,0.417l-1.059,7.051c-0.035,0.233,0.044,0.47,0.21,0.639c0.143,0.14,0.333,0.219,0.528,0.219c0.038,0,0.073-0.003,0.111-0.009l7.054-1.055c0.158-0.025,0.306-0.098,0.417-0.211l8.478-8.476l2.22-2.22C19.695,7.414,19.695,6.941,19.404,6.65z M8.341,16.656l-0.989-0.99l7.258-7.258l0.989,0.99L8.341,16.656z M2.332,15.919l0.411-2.748l4.143,4.143l-2.748,0.41L2.332,15.919z M13.554,7.351L6.296,14.61l-0.849-0.848l7.259-7.258l0.423,0.424L13.554,7.351zM10.658,4.457l0.992,0.99l-7.259,7.258L3.4,11.715L10.658,4.457z M16.656,8.342l-1.517-1.517V6.823h-0.003l-0.951-0.951l-2.471-2.471l1.164-1.164l4.942,4.94L16.656,8.342z"
                              ></path>
                            </svg>
                            <span className="px-2">Save</span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default EmployeeEdit;
