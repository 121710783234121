import React, { useState, useEffect } from "react";
import TableStr from "./TableStr";
import Enabled from "./options/Enabled";
import Disabled from "./options/Disabled";
import All from "./options/All";


function Employee() {
  return (
    <div className="h-full w-full">
      <TableStr
        title={"Employees"}
        url={"/employee/newEmployee"}
        viewUrl={"/employee/view"}
        editUrl={"/employee/edit"}
        listUrl={`${process.env.REACT_APP_BASE_URL}/users?sort=createdDate,desc`}
        getName={"employeesList"}
        Enabled={Enabled}
        Disabled={Disabled}
        All={All}
      />
    </div>
  );
}

export default Employee;
