import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Button, Checkbox } from "@mui/material";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";
import { OPEN_CART_DATA } from "./DATA";
import { toast } from "react-toastify";
import Iconify from "../../../../../../components/iconify/Iconify";
import CarousalVideoModal from "./modal/VideoCarousalModal";
import { ProductRecognizationIcon } from "../../../../../../styles/icons/Icons";

const columns = [
  { id: "position", label: "Position" },
  { id: "image", label: "Image", align: "center" },
  // {
  //   id: "qty",
  //   label: "Est. Qty",
  //   align: "center",
  //   format: (value) => value.toLocaleString("en-US"),
  // },
  {
    id: "info",
    label: "Event Info",
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "video",
    label: "Event Video",

    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "recShopper",
    label: "Recco. Shopper",

    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "prodRec",
    label: "Recco. Product",

    align: "center",
    format: (value) => value.toFixed(2),
  },
  // {
  //   id: "action",
  //   label: "Action",
  //   align: "center",
  //   format: (value) => value.toFixed(2),
  // },
];

const columns2 = [
  { id: "position", label: "Position" },
  { id: "image", label: "Image" },
  // {
  //   id: "qty",
  //   label: "Est. Qty",
  //   align: "center",
  //   format: (value) => value.toLocaleString("en-US"),
  // },
  {
    id: "name",
    label: "Product Name",
    // align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "barcode",
    label: "Barcode",

    align: "center",
    format: (value) => value.toFixed(2),
  },
  // {
  //   id: "action",
  //   label: "Action",
  //   align: "center",
  //   format: (value) => value.toFixed(2),
  // },
];

export default function ProductStickyTable({
  opencartApiParams,
  openCartData,
  selectedProduct,
  selectedEvent,
  setSelectedEvent,
  setSelectedProduct,
  isEventTypeAvailable,
  quantity,
  allShelfData,
  handleOpenModal,
}) {
  console.log("allShelfData", allShelfData, selectedProduct);
  const [index, setIndex] = React.useState(-1);

  const [isCartHovered, setIsCartHovered] = React.useState(false);

  const isAllPutback = openCartData?.every((item) => item?.eventType === "PUT")

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", height: "100%" }}>
      <TableContainer sx={{ maxHeight: "100%" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>
                {selectedProduct && !openCartData?.every(
                          (item) => item?.eventType === "PUT"
                        ) &&  (
                  <Checkbox
                    sx={{ p: 0 }}
                    checked={
                      selectedEvent &&
                      openCartData &&
                      selectedEvent?.length == openCartData?.length
                    }
                    onClick={() => {
                      if (selectedEvent?.length == openCartData?.length) {
                        setSelectedEvent(() => []);
                      } else {
                        setSelectedEvent(() => [...openCartData]);
                      }
                    }}
                  />
                )}
              </TableCell>
              {columns?.map((column) => (
                <TableCell
                  // key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isAllPutback ? (
              <TableRow
                hover
                tabIndex={-1}
                sx={{ height: "200px", cursor: "pointer" }}
                onClick={() => {}}
              >
                <TableCell sx={{ py: 0, height: " 100%" }} />
                <TableCell sx={{ py: 0, height: " 100%" }} />
                <TableCell sx={{ py: 0, height: " 100%" }} />
                <TableCell sx={{ py: 0, height: " 100%" }} align="right">
                  {" "}
                  No product events available
                </TableCell>
                <TableCell sx={{ py: 0, height: " 100%" }} />
                <TableCell sx={{ py: 0, height: " 100%" }} />
                <TableCell sx={{ py: 0, height: " 100%" }} />
              </TableRow>
            ) : isEventTypeAvailable ? (
              openCartData?.map((event, i) => {
                console.log("OPEN_CART_DATAevent", event);
                let result;
                let imageResult;

                if (selectedProduct) {
                  result = `${event?.scaleInternalCode
                    ?.split("-")
                    .slice(0, 2)
                    .join("-")}-${
                    event?.scaleType === "SURFBOARD"
                      ? selectedProduct.productPosition
                        ? selectedProduct.productPosition
                        : selectedProduct.scaleId
                      : selectedProduct?.scaleId
                  }`;
                  imageResult = selectedProduct?.imageUrl;
                } else if (
                  event?.productId !== null &&
                  event?.productInfos?.findIndex(
                    (item) => item?.productId === event?.productId
                  ) > -1
                ) {
                  const productInfo = event.productInfos.find(
                    (item) => item?.productId === event?.productId
                  );
                  console.log('productinfo', productInfo, event?.scaleType)
                  result = `${event?.scaleInternalCode
                    ?.split("-")
                    .slice(0, 2)
                    .join("-")}-${
                    event?.scaleType === "SURFBOARD"
                      ? (productInfo?.productPosition
                        ? productInfo?.productPosition
                        : productInfo?.scaleId ? productInfo?.scaleId : productInfo?.productPosition)
                      : (productInfo?.scaleId  ? productInfo?.scaleId : productInfo?.productPosition)
                  }`;
                  imageResult = productInfo?.imageUrl;
                } else {
                  result = `${event?.scaleInternalCode
                    ?.split("-")
                    .slice(0, 2)
                    .join("-")}`;
                }

                console.log("RESULT", selectedProduct, result);

                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    sx={{ height: "70px", cursor: "pointer" }}
                    onClick={() => {
                      if (selectedProduct) {
                        const itemInd = selectedEvent?.findIndex(
                          (item) => item?.id === event?.id
                        );
                        if (itemInd > -1) {
                          const tempData = [...selectedEvent];
                          tempData.splice(itemInd, 1);
                          setSelectedEvent((data) => [...tempData]);
                        } else {
                          setSelectedEvent((data) => [...data, event]);
                        }
                      }
                    }}
                  >
                    <TableCell sx={{ py: 0, height: " 100%" }}>
                      <div
                        className={`h-[70px] w-4 ${
                          event?.eventType === "PUT"
                            ? "bg-[#B40000]"
                            : "bg-[#006600]"
                        }`}
                      />
                    </TableCell>
                    <TableCell
                      sx={{ py: 0, height: " 100%" }}
                      // onClick={() =>
                      //   navigator.clipboard.writeText(event?.id).then(() => {
                      //     toast.success("copied", {
                      //       autoClose: 1000,
                      //       position: toast.POSITION.TOP_CENTER,
                      //       // width: ,
                      //     });
                      //   })
                      // }
                    >
                      <div>
                        <p
                          className="mt-1 font-bold flex items-center"
                          onMouseEnter={() => setIsCartHovered(true)}
                          onMouseLeave={() => setIsCartHovered(false)}
                        >
                          <p>{result}</p>
                          {isCartHovered && (
                            <Iconify
                              onClick={(e) => {
                                e.stopPropagation();
                                navigator.clipboard
                                  .writeText(event?.id)
                                  .then(() => {
                                    toast.success("Copied event id", {
                                      autoClose: 1000,
                                      position: toast.POSITION.TOP_CENTER,
                                      // width: ,
                                    });
                                  });
                              }}
                              icon="ph:copy"
                            />
                          )}
                        </p>
                        {selectedProduct && (
                          <Checkbox
                            checked={selectedEvent?.some(
                              (item) => item?.id === event?.id
                            )}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell sx={{ py: 0, height: " 100%" }}>
                      <div className="flex items-center justify-center">
                        {imageResult ? (
                          <img
                            style={{ width: "60px", height: "60px" }}
                            src={imageResult}
                            alt=""
                          />
                        ) : (
                          <img
                            style={{ width: "60px", height: "60px" }}
                            src="/images/noImage.jpg"
                            alt=""
                          />
                        )}
                      </div>
                    </TableCell>
                    {/* <TableCell sx={{ py: 0, height: " 100%" }}>
                        <div className="flex justify-center">
                          <p className="mt-1 font-bold">{quantity}</p>
                        </div>
                      </TableCell> */}
                    <TableCell sx={{ py: 0, height: " 100%" }} align="center">
                      <div>
                        <p className="mt-1 font-bold">
                          {selectedProduct?.unitWeight}{" "}
                          {selectedProduct?.unitOfMeasure}
                        </p>
                        <div className="flex items-center">
                          <p className="mt-1 mr-1 font-bold">
                            {` ${parseFloat(
                              event?.productInfos[0]?.estimatedChangeInWeight
                            )?.toFixed(3)} ${
                              event?.productInfos[0]?.unitOfMeasure
                            }`}
                          </p>
                          {event?.eventType === "PUT" ? (
                            <MdArrowDownward className="w-5 h-5 text-red-500  ml-2  " />
                          ) : (
                            <MdArrowUpward className="w-5 h-5 text-green-600 ml-2" />
                          )}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell sx={{ py: 0, height: " 100%" }}>
                      <div
                        className="flex items-center justify-center"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (event?.storeCartVideoPaths?.length > 0)
                            setIndex(i);
                        }}
                      >
                        <img
                          style={{ width: "60px", height: "60px" }}
                          src={
                            event?.storeCartVideoPaths?.[0]?.thumbnail
                              ? event?.storeCartVideoPaths?.[0]?.thumbnail
                              : "/images/noImage.jpg"
                          }
                          alt=""
                        />
                      </div>
                      {index === i && (
                        <CarousalVideoModal
                          open={index === i}
                          handleClose={() => setIndex(-1)}
                          data={event?.storeCartVideoPaths}
                        />
                      )}
                    </TableCell>
                    <TableCell sx={{ py: 0, height: " 100%" }}>
                      <div className="flex justify-center">
                        <p className="mt-1 font-bold">
                          {event?.userInfos?.length === 1
                            ? event?.userInfos?.[0]?.cartRef
                            : "-"}
                        </p>
                      </div>
                    </TableCell>
                    <TableCell sx={{ py: 0, height: " 100%" }}>
                      {/* {event?.productId !== null && ( */}
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOpenModal(event);
                          }}
                        >
                          <ProductRecognizationIcon />
                        </div>
                      {/* )} */}
                    </TableCell>
                    {/* <TableCell sx={{ py: 0, height: " 100%" }}>
                    <Button variant="contained">Add</Button>
                  </TableCell> */}
                  </TableRow>
                );
              })
            ) : (
              <TableRow
                hover
                tabIndex={-1}
                sx={{ height: "200px", cursor: "pointer" }}
                onClick={() => {}}
              >
                <TableCell sx={{ py: 0, height: " 100%" }} />
                <TableCell sx={{ py: 0, height: " 100%" }} />
                <TableCell sx={{ py: 0, height: " 100%" }} />
                <TableCell sx={{ py: 0, height: " 100%" }} align="right">
                  {" "}
                  No product events available
                </TableCell>
                <TableCell sx={{ py: 0, height: " 100%" }} />
                <TableCell sx={{ py: 0, height: " 100%" }} />
                <TableCell sx={{ py: 0, height: " 100%" }} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Paper>
  );
}
