import React from "react";
import TableStr from "./TableStr";
import Enabled from "./options/Enabled";
import Disabled from "./options/Disabled";
import All from "./options/All";

function Schedule() {
  return (
    <div className="h-full w-full">
      <TableStr
        title={"Offer"}
        url={"/admin/merchant/dashboard/productManagement/offer/createOffer"}
        viewUrl={"/admin/merchant/dashboard/productManagement/offer/view"}
        editUrl={"/admin/merchant/dashboard/productManagement/offer/edit"}
        listUrl={`${
          process.env.REACT_APP_BASE_URL
        }/price-engine-configurations?sort=createdDate,desc&rootOrgId.equals=${parseInt(
          JSON.parse(localStorage.getItem("dashboard-data"))?.id
        )}`}
        getName={"scheduleList"}
        Enabled={Enabled}
        Disabled={Disabled}
        All={All}
      />
    </div>
  );
}

export default Schedule;
