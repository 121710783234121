import React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Select from "react-select";
import TextField from "@mui/material/TextField";
import { IoMdRemoveCircleOutline, IoMdAddCircleOutline } from "react-icons/io";

const week = [
  { label: "Monday", value: "MONDAY" },
  { label: "Tuesday", value: "TUESDAY" },
  { label: "Wednesday", value: "WEDNESDAY"},
  { label: "Thursday", value: "THURSDAY"},
  { label: "Friday", value: "FRIDAY"},
  { label: "Saturday", value: "SATURDAY"},
  { label: "Sunday", value: "SUNDAY"},
];

const customStyles = {
  control: (base) => ({
    ...base,
    height: 55,
    minHeight: 35,
  }),
};

const AddDynamically = ({ schedule, setSchedule }) => {

  const addClick = () => {
    setSchedule((prevState) => [
      ...prevState,
      {
        applicableDays: [],
        startTime: null,
        endTime: null,
      },
    ]);
  };

  const removeClick = (i) => {
    let scheduleTemp = [...schedule];
    scheduleTemp.splice(i, 1);
    setSchedule(scheduleTemp);
  };

  const handleChange = (i, value, name) => {
    
    let scheduleTemp = [...schedule];
    scheduleTemp[i] = { ...scheduleTemp[i], [name]: value };
    setSchedule(scheduleTemp);
  };

  const createUI = () => {
    return schedule.map((el, i) => (
      <div key={i} className="space-y-4">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div className="w-full grid grid-cols-1 gap-x-12 gap-y-12">
            <Select
              options={week}
              placeholder="Applicable Days"
              maxMenuHeight={170}
              value={el.applicableDays}
              isMulti
              styles={customStyles}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
              onChange={(value) => handleChange(i, value, "applicableDays")}
            />
          </div>

          <div className="w-full grid grid-cols-2 gap-x-12 gap-y-12 pt-4">
            <div>
              <TimePicker
                label="Start Time"
                value={el?.startTime}
                onChange={(value) => handleChange(i, value, "startTime")}
                renderInput={(params) => (
                  <TextField {...params} className="w-full" />
                )}
                disableOpenPicker={true}
              />
            </div>
            <div>
              <TimePicker
                label="End Time"
                value={el?.endTime}
                onChange={(value) => handleChange(i, value, "endTime")}
                renderInput={(params) => (
                  <TextField {...params} className="w-full" />
                )}
                disableOpenPicker={true}
              />
            </div>
          </div>

          <div className="flex justify-end space-x-2 pb-4">
            <IoMdRemoveCircleOutline onClick={() => removeClick(i)} size={28} />
            <IoMdAddCircleOutline onClick={() => addClick()} size={28} />
          </div>
        </LocalizationProvider>
      </div>
    ))
  }

  return (
    <div>
      {createUI()}
      {schedule.length === 0 && (
        <div className="flex space-x-2 pt-4">
          <button className="bg-gray-200 py-1.5 px-4 rounded" onClick={() => addClick()}> Add Schedule</button>
        </div>
      )}
    </div>
  )
}

export default AddDynamically
