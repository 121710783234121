import React from "react";
import { AiFillLike, AiFillDislike } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { useAlert } from 'react-alert'


function Health({ params, url, viewUrl, editUrl }) {
  const history = useHistory();
  const alert = useAlert();
  const Status = true ? AiFillLike : AiFillDislike

  const edit = () => {
    history.push({
      pathname: editUrl,
      //search: "?query=abc",-
      state: { data: params.data },
    });
  };

  const view = () => {
    history.push({
      pathname: viewUrl,
      //search: "?query=abc",-
      state: { data: params.data },
    });
  };
  return (
    <>
      <div className="flex w-16 h-10 items-center">
        <div className="border-2 border-gray-200 rounded-lg p-1 mx-2">
          <Status className="w-5 h-5 cursor-pointer text-blue-700" onClick={()=>alert.show("Please like me")} />
        </div>
        
      </div>
    </>
  );
}

export default Health;