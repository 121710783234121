/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import BackArrowButton from "../../../../../../components/buttons/BackarrowButton";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  Popover,
  Switch,
  Tooltip,
  styled,
} from "@mui/material";
import CustomSearch from "../../../../../../components/searchbar";
import ProductStickyTable from "./ProductStickyTable";
import CarouselVideo from "./VideoCarousal";
import useStyles from "../../../../../../theme/variants/Button";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";
import ProductRecognizationModal from "./expressCheckout/modal/ProductRecognizationModal";
import { ProductRecognizationIcon } from "../../../../../../styles/icons/Icons";
import Badge from "@mui/material/Badge";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import FinalCartReview from "./FinalCartReviewTable";
import ModalTileVideoForm from "../../shopperActivity/edit/ModalTileVideoForm";
import { useGet, useGetWithFilters } from "../../../../../../api/useGet";
import VideoController from "../../../../../../custom-control/VideoController";
import Slider from "react-slick";
import CarousalModal from "./modal/CarousalModal";
import { getTime, getTimeOnly } from "../../../../../../logic/useGetTime";
import axios from "axios";
import { removeEmptyStringKeys } from "../../../../../../utils/utils";
import { useOpeneventDataProvider } from "../context/OpeneventsContext";
import { useQuery } from "react-query";
import { openCartData } from "./DATA";
import moment from "moment";
import { toast } from "react-toastify";
import CoffeeEventModel from "../../hawkeye/dashboard/model/CoffeeEventModel";
import { usePut } from "../../../../../../api/usePut";
import AlertDialog from "./modal/AlertDialogbox";
import {
  CART_PROCESSING_APPROVED,
  CART_PROCESSING_REJECTED,
  CART_PROCESSING_STARTED,
  MerchantsDetails,
} from "../../../../../../utils/action_types";
import Iconify from "../../../../../../components/iconify/Iconify";
import ItemsInCart from "./modal/HoverProductInfoModal";
import ReactHover, { Trigger, Hover } from "react-hover";
import { usePost } from "../../../../../../api/usePost";
import { getDecryptedData } from "../../../../../../utils/localStorageUtils";
// import { OPEN_CART_DATA as openCartApiData } from "./DATA";

const settings = {
  // dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  width: 100,
  height: 150,
};

const sliderStyle = {
  width: "100px", // Set the desired width
  height: "150px", // Set the desired height
  // padding: "10px",
  // paddingTop: "0",
  // border: "1px solid black",
};

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

function CustomizedBadges({ color, number }) {
  console.log("numbernumbernumbernumber", number);
  return (
    <IconButton
      aria-label="cart"
      size="large"
      sx={{ color: color ? color : "black", p: 0, pr: 3 }}
    >
      <StyledBadge badgeContent={number ? number : 0} color="secondary">
        <ShoppingCartIcon />
      </StyledBadge>
    </IconButton>
  );
}

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#39393D" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const ProductEvent = ({ locationData, reset }) => {
  console.log("ProductEventlocationData", locationData);
  const classes = useStyles();
  const history = useHistory();

  const {
    state: { navigationFrom, data },
  } = useLocation();

  const classBlue =
    "w-[48%] p-2 h-[100%] flex items-center justify-center bg-[#2D78D2] overflow-x-auto cursor-pointer text-white relative";
  const classGray =
    "w-[48%] p-2 h-[100%] flex justify-center items-center bg-gray-300 overflow-x-auto cursor-pointer relative";

  const {
    opencartApiParams,
    setOpencartApiParams,
    selectedIndex,
    setSelectedIndex,
  } = useOpeneventDataProvider();

  console.log("opencartApiParams", opencartApiParams);

  const [currentTab, setCurrentTab] = useState(1);

  const [isTileVideoModalOpen, setIsTileVideoModalOpen] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [sessionAllVideos, setSessionAllVideos] = useState([]);

  const [videoUrl, setVideoUrl] = useState();

  const [openEntryCarousal, setOpenEntryCarousal] = useState(false);

  const [openExitCarousal, setOpenExitCarousal] = useState(false);

  const [openCartData, setOpetnData] = useState([]);

  const [selectedProduct, setSelectedProduct] = useState();

  const [selectedEvent, setSelectedEvent] = useState([]);

  const [allSelectedShelftProducts, setSelectedShelfProducts] = useState([]);

  const [recommendedShoppersList, setRecommendedShoppersList] = useState([]);

  const [selectedusersGrp, setSelectedUsersGrp] = useState();

  const [shoppersApiFilterParams, setShoppersApiFilterParams] = useState(null);

  const [shelfApiFilterParams, setShelfApiFilterParams] = useState(null);

  const [allShelfData, setAllShelfData] = useState(null);

  const [isCoffeeEventModal, setIsCoffeeEventModal] = useState(false);

  const [selectedShopper, setSelectedShopper] = useState();

  const [quantity, setQuantity] = useState(0);

  const [refreshApis, setRefreshApis] = useState(false);

  const [finalCartReivewData, setFinalCartReviewData] = useState([]);

  const [isProcessedCartApiCalled, setIsProcessedCartApiCalled] =
    useState(false);

  const [open, setOpen] = useState(false);

  const [isEventTypeAvailable, setIsEventTypeAvailable] = useState(false);

  const [isCartHovered, setIsCartHovered] = useState(false);

  const [openCartInItemModal, setOpenCartInItemModal] = useState(false);

  const [openCurItemIndex, setOpenCurItemIndex] = useState(0);

  const [openCartDataByGroupId, setOpenCartDataByGroupId] = useState([]);

  const [selectedData, setSelectedData] = useState();

  const [recommendedProductList, setRecommendedProductList] = useState([]);

  const [cartItemTask, setCartItemTask] = useState();

  const [preImageList, setPreImageList] = useState([]);

  const [postImageList, setPostImageList] = useState([]);

  const [allImageList, setAllImageList] = useState([]);

  console.log("openCurItemIndex", openCurItemIndex);

  const { data: images } = useGet(
    `${process.env.REACT_APP_STORE_URL}/user-activities?eventStatus.equals=${
      navigationFrom && navigationFrom === "superviser"
        ? "CHECKEDOUT_IN_REVIEW"
        : "CHECKEDOUT"
    }&sort=entryTime,asc&cartRef.equals=${
      locationData?.sessionRef
    }&eventTime.greaterThanOrEqual=${
      locationData?.entryTime
    }&eventTime.lessThanOrEqual=${
      locationData?.checkoutDate
    }&storeCode.equals=${locationData?.storeCode}`,
    "images"
  );

  useEffect(() => {
    if (images?.length > 0) {
      const filterData = images?.[0]?.shopperVideoPaths?.filter(
        (ele) => ele?.name !== "ShopperLoop"
      );
      const filterData2 = images?.[0]?.shopperVideoPaths?.filter(
        (ele) => ele?.name == "ShopperLoop"
      );

      setSessionAllVideos([...filterData2, ...filterData]);

      // setVideoUrl(filterData?.[0]);

      console.log("filterData", filterData2, filterData);
    }
  }, [images]);

  const fetchData = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_STORE_URL}/store-carts/open-cart?page=0&size=100&eventTime.greaterThanOrEqual=${locationData.entryTime}&eventTime.lessThanOrEqual=${locationData?.checkoutDate}&storeCode.equals=${locationData?.storeCode}&syncStatus.in=INITIALIZED,CONFLICT,ERRORED,CART_REVIEWED,PROCESSED&sort=eventTime,asc`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
        params: {
          ...removeEmptyStringKeys(opencartApiParams),
        },
      });
      return res.data;
    } catch ({ message }) {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      }
    }
  };

  const { data: openCartApiData } = useQuery(
    ["openItemList", refreshApis, opencartApiParams],
    async () => fetchData(),
    {
      enabled: true || refreshApis,
      // cacheTime: 0,
      // staleTime: 0,
    }
  );

  function groupByGroupId(data) {
    return Object.values(
      data.reduce((acc, item) => {
        (acc[item.groupId] = acc[item.groupId] || []).push(item);
        return acc;
      }, {})
    );
  }

  useEffect(() => {
    if (openCartApiData) {
      const filteredData = groupByGroupId(openCartApiData);
      console.log("filteredData", filteredData);
      setOpenCartDataByGroupId(filteredData);
    }
  }, [openCartApiData]);

  const flatData = (data) => data?.map((item) => item?.productInfos)?.flat();

  useEffect(() => {
    if (openCartApiData) {
      if (
        opencartApiParams?.["scaleInternalCode.contains"]?.trim() === "" ||
        opencartApiParams?.["scaleInternalCode.contains"] === undefined
      ) {
        const filterData = openCartDataByGroupId?.[openCurItemIndex];

        if (
          filterData?.[0]?.eventType !== undefined &&
          filterData?.[0]?.eventType !== null &&
          filterData?.[0]?.eventType !== "" &&
          openCartApiData?.length > 0
        ) {
          // if (filterData?.length === 1 && filterData?.[0]?.eventType == "PUT") {
          //   setIsEventTypeAvailable(false);
          // } else {
          setIsEventTypeAvailable(true);
          // }
        } else {
          setIsEventTypeAvailable(false);
        }

        // setRecommendedShoppersList(groupByCartRef(flatUserData(filterData)));

        const filterDataProducts = flatData(filterData);

        setOpetnData(() =>
          selectedIndex == 0
            ? filterData?.filter((item) => item?.syncStatus === "CART_REVIEWED")
            : filterData?.filter((item) => item?.syncStatus !== "CART_REVIEWED")
        );

        setSelectedShelfProducts(() => filterDataProducts);
      } else {
        if (
          openCartApiData?.[0]?.eventType !== undefined &&
          openCartApiData?.[0]?.eventType !== null &&
          openCartApiData?.[0]?.eventType !== "" &&
          openCartApiData?.length > 0
        ) {
          setIsEventTypeAvailable(true);
        } else {
          setIsEventTypeAvailable(false);
        }

        setOpetnData(() =>
          selectedIndex == 0
            ? openCartApiData?.filter(
                (item) => item?.syncStatus === "CART_REVIEWED"
              )
            : openCartApiData?.filter(
                (item) => item?.syncStatus !== "CART_REVIEWED"
              )
        );
      }
    }
  }, [openCartDataByGroupId, selectedIndex, openCurItemIndex]);

  useEffect(() => {
    if (openCartData?.length > 0) {
      let minTime = openCartData?.[0]?.eventTime;
      let maxTime = openCartData?.[0]?.checkoutTime;
      let rootOrgId = locationData?.rootOrgId || openCartData?.[0]?.rootOrgId;
      const shelfName = openCartData?.[0]?.scaleInternalCode?.split("-");
      const productEventIds = [];
      openCartData?.forEach((item) => {
        if (item?.eventTime < minTime) {
          minTime = item?.eventTime;
        }
        productEventIds.push(item?.id);
        console.log(
          "openCartDataopenCartDataopenCartData",
          minTime,
          productEventIds
        );
      });
      setShoppersApiFilterParams((data) => ({
        minTime,
        rootOrgId,
        maxTime,
        productIds: productEventIds?.join(","),
      }));
      setShelfApiFilterParams((data) => ({
        retailStoreCode: openCartData?.[0]?.storeCode,
        shelfName: `${shelfName?.[0]}-${shelfName?.[1]}`,
      }));
    }

    // if (openCartData?.length === 0) {
    //   // setAllShelfData([]);
    //   setRecommendedShoppersList([]);
    // }
  }, [openCartData]);

  const shoppersData = useGet(
    `${
      process.env.REACT_APP_STORE_URL
    }/user-activities/shoppers?entryTime.greaterThanOrEqual=${moment(
      openCartData?.[0]?.eventTime
    )
      .subtract(60, "minutes")
      .utc()
      .format()}&exitTime.greaterThanOrEqual=${moment(
      openCartData?.[0]?.eventTime
    )
      .utc()
      .format()}&sort=entryTime,asc&size=100&page=0&productEventId.equals=${
      openCartData?.[0]?.id
    }&rootOrgId.equals=${locationData?.rootOrgId}`,
    ["shoppersData", shoppersApiFilterParams],
    shoppersApiFilterParams !== null
  );

  useEffect(() => {
    if (shoppersData?.data) {
      let data = Object.values(
        shoppersData?.data?.reduce((acc, item) => {
          acc[item?.cartRef] = acc[item?.cartRef] || [];
          acc[item?.cartRef].push(item);
          return acc;
        }, {})
      );

      const index = data.findIndex(
        (item) => entryData?.data?.[0]?.cartRef === item?.[0]?.cartRef
      );

      if (index !== -1) {
        // Remove the object from its original position
        const [obj] = data.splice(index, 1);

        // Add the object to the start of the array
        data.unshift(obj);
      }

      setRecommendedShoppersList(data);

      console.log("shoppersDatashoppersDatashoppersDatashoppersData", data);
    }
  }, [shoppersData?.data]);

  useEffect(() => {
    if (recommendedShoppersList?.length > 0 && openCartData?.length > 0) {
      const index = openCartData?.findIndex(
        (item) => item?.userInfos?.length > 0
      );
      if (index > -1) {
        const selected = recommendedShoppersList?.find(
          (item) => item?.id == openCartData?.[index]?.userInfos?.[0]?.id
        );
        if (selected) setSelectedShopper(() => selected);
        console.log("selectedShoppper", selected);
      }
    }
  }, [recommendedShoppersList, openCartData]);

  const shelfApiCall = useGet(
    `${process.env.REACT_APP_BASE_URL}/scale-products/shelf?sort=column,asc&retailStoreCode.equals=${locationData?.storeCode}&shelfName.equals=${shelfApiFilterParams?.shelfName}`,
    ["shelfApiCall", shelfApiFilterParams],
    shelfApiFilterParams !== null
  );

  useEffect(() => {
    if (shelfApiCall?.data)
      setAllShelfData(() =>
        shelfApiCall?.data?.sort(
          (a, b) => Number(a?.scaleIdentifier) - Number(b?.scaleIdentifier)
        )
      );
  }, [shelfApiCall]);

  const entryData = useGet(
    `${process.env.REACT_APP_STORE_URL}/user-activities?sort=entryTime%2Casc&cartRef.equals=${locationData?.sessionRef}`,
    "entryData"
  );

  const exitData = useGet(
    `${process.env.REACT_APP_STORE_URL}/user-exit-activities?sort=exitTime%2Casc&cartRef.equals=${locationData?.sessionRef}`,
    "exitData"
  );

  const { data: shelveByID } = useGet(
    `${process.env.REACT_APP_BASE_URL}/shelves?internalCode.equals=${
      allShelfData?.[0]?.groupId
    }&retailStoreId.equals=${
      allShelfData?.[0]?.retailStoreId
    }&sort=name%2Casc&rootOrgId.equals=${
      locationData?.rootOrgId || openCartData?.[0]?.rootOrgId
    }`,
    ["shelveByID", allShelfData],
    allShelfData?.length > 0
  );

  const markDirtyApiCall = () => {
    if (shelveByID?.[0]) {
      console.log("markDirtyApiCall", shelveByID?.[0]);
      const { id, name } = shelveByID?.[0] || {};
      const payload = {
        id,
        name,
        status: "DIRTY",
        rootOrgId: openCartData?.[0]?.rootOrgId,
      };

      axios({
        method: "PATCH",
        url: `${process.env.REACT_APP_BASE_URL}/shelves/${id}/status`,
        headers: {
          "Content-Type": "application/merge-patch+json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
        data: payload,
      })
        .then(() => {
          setRefreshApis((flag) => !flag);
          toast.success(`Shelf-${name} is marked as dirty`);
        })
        .catch(({ message }) => {
          if (message === "Request failed with status code 401") {
            history.push("/login");
          } else {
            setRefreshApis((flag) => !flag);
            toast.error(message);
          }
        });
    }
  };

  const handleBack = () => history.goBack();

  const handleOpenModal = () => setIsModalOpen(true);

  const handleCloseModal = () => {
    setPreImageList(() => []);
    setPostImageList(() => []);
    setAllImageList(() => []);
    setIsModalOpen(false);
  };

  const onOpenTileVideoModal = () => setIsTileVideoModalOpen(true);

  const onCloseTileVideoModal = () => setIsTileVideoModalOpen(false);

  const handleOpenEntryCarousalModal = () => setOpenEntryCarousal(true);

  const handleCloseEntryCarousalModal = () => setOpenEntryCarousal(false);

  const handleOpenExitCarousalModal = () => setOpenExitCarousal(true);

  const handleCloseExitCarousalModal = () => setOpenExitCarousal(false);

  const handleCloseCoffevent = () => setIsCoffeeEventModal(false);

  const handleOpenCoffevent = () => setIsCoffeeEventModal(true);

  const combineUserImagePaths = (data) =>
    data.reduce((combined, obj) => {
      if (obj.userImagePaths && Array.isArray(obj.userImagePaths)) {
        combined.push(...obj.userImagePaths);
      }
      return combined;
    }, []);

  function groupByCartRef(data) {
    return Object.values(
      data?.reduce((acc, item) => {
        acc[item?.cartRef] = acc[item?.cartRef] || [];
        acc[item?.cartRef].push(item);
        return acc;
      }, {})
    );
  }

  const assignEvents = usePut(
    `${process.env.REACT_APP_STORE_URL}/store-carts/review-group-event`,
    "assignEvents"
  );

  useEffect(() => {
    if (assignEvents?.isSuccess) {
      setRefreshApis((flag) => !flag);
      // setCurrentTab(2);
      assignEvents?.reset();
      processCart();
      toast.success("Item added to cart successfully");
      setSelectedProduct(null);
      setSelectedEvent([]);
      setSelectedShopper();
    }
    // if (assignEvents?.isError) {
    //   assignEvents?.reset();
    //   toast.error("Please try again after some time");
    // }
  }, [assignEvents?.isSuccess]);

  const estimatedQuantity = usePut(
    `${process.env.REACT_APP_STORE_URL}/store-carts/compute-quantity`,
    "estQuantity"
  );

  useEffect(() => {
    console.log("selectedEvent", selectedEvent);
    console.log("selectedProduct", selectedProduct);
    if (selectedEvent?.length > 0 && selectedProduct) {
      const data = selectedEvent.map((item) => ({
        // ...item,
        id: item?.id,
        changeInWeight: item?.changeInWeight,
        productInfos: [
          {
            productId: selectedProduct?.productId,
            unitOfMeasure: selectedProduct?.unitOfMeasure,
            unitWeight: selectedProduct?.unitWeight,
          },
        ],
      }));
      estimatedQuantity.mutate(data);
    }
    if (selectedEvent?.length === 0) setQuantity(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEvent, selectedProduct]);

  useEffect(() => {
    // if (estimatedQuantity?.data?.data)

    console.log("estimatedQuantity?.data?.data", estimatedQuantity?.data?.data);
    setQuantity(estimatedQuantity?.data?.data);
  }, [estimatedQuantity?.data?.data]);

  const { data: storeCarts } = useGet(
    `${process.env.REACT_APP_SHOPPER_URL}/stores/carts?sessionRef.equals=${locationData?.sessionRef}&sort=createdDate,desc&page=0&size=50`,
    ["storesCart", refreshApis, isProcessedCartApiCalled]
  );

  useEffect(() => {
    if (storeCarts?.length > 0) {
      setFinalCartReviewData(storeCarts[0]);
    }
  }, [storeCarts]);

  const cartfinalize = usePut(
    data?.status == "APPROVED_IN_REVIEW" || data?.status == "REJECTED_IN_REVIEW"
      ? `${process.env.REACT_APP_SHOPPER_URL}/stores/supervisor-finalize`
      : `${process.env.REACT_APP_SHOPPER_URL}/stores/cart-finalize`,
    "hawkeyeList"
  );

  const loginInfo = getDecryptedData("loginInfo");
  const uniqID = localStorage.getItem("uniqID");

  const confirm = () => {
    if (
      finalCartReivewData?.storeCartItems?.some(
        (item) => item?.reviewedQuantity > 5
      ) &&
      open === false
    ) {
      setOpen(true);
      return;
    }
    try {
      cartfinalize.mutate({ ...finalCartReivewData, status: "APPROVED" });
      handleLogs.mutate({
        actionName: CART_PROCESSING_APPROVED,
        logTime: new Date().toISOString(),
        screenLoadingTime: `-`,
        logSessionId: uniqID,
        rootOrgId: locationData?.rootOrgId,
        cartRef: locationData?.sessionRef,
      });
    } catch (err) {
      toast.error(err.message);
    }
  };

  const rejected = () => {
    cartfinalize.mutate({ ...finalCartReivewData, status: "REJECTED" });
    handleLogs.mutate({
      actionName: CART_PROCESSING_REJECTED,
      logTime: new Date().toISOString(),
      screenLoadingTime: `-`,
      logSessionId: uniqID,
      rootOrgId: locationData?.rootOrgId,
      cartRef: locationData?.sessionRef,
    });
    // history.push("/support/hawkeye");
  };

  useEffect(() => {
    if (cartfinalize?.isSuccess) {
      toast.success("action taken successfully");
      cartfinalize.reset();
      history.push("/support/shopper-activity");
    }
  }, [cartfinalize]);

  const isStoreCartItems = finalCartReivewData?.storeCartItems?.some(
    (item) => item?.reviewedQuantity > 0
  );

  const processCart = async () => {
    const payload = {
      sessionRef: locationData?.sessionRef,
      rootOrgId: locationData?.rootOrgId
        ? locationData?.rootOrgId
        : locationData?.storeCartTokens[0]?.rootOrgId,
    };

    axios({
      method: "PUT",
      url: `${process.env.REACT_APP_SHOPPER_URL}/stores/cart-review`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
      data: payload,
    })
      .then(({ data }) => {
        localStorage.setItem("way", "indirect");
        setIsProcessedCartApiCalled((flag) => !flag);
        // history.push({
        //   pathname: "/support/hawkeye/cartSummary",
        //   state: { data: data },
        // });
      })
      .catch(({ message }) => {
        if (message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          toast.error(message);
        }
      });
  };

  // useEffect(() => {
  //   processCart();
  // }, []);

  useEffect(() => {
    if (selectedEvent?.length > 0) {
      const allImgs = selectedEvent?.flatMap(
        (item) => item?.productEventImageDTOS
      );
      const preImageList = allImgs?.filter((ele) => ele?.tag === "PRE");
      const postImageList = allImgs?.filter((ele) => ele?.tag === "POST");

      setPreImageList(() => preImageList);
      setPostImageList(() => postImageList);
      setAllImageList(() => allImgs);
    }
  }, [selectedEvent]);

  const handleOpenRecModal = (item) => {
    const allImgs = item?.productEventImageDTOS;
    const preImageList = allImgs?.filter((ele) => ele?.tag === "PRE");
    const postImageList = allImgs?.filter((ele) => ele?.tag === "POST");

    setPreImageList(() => preImageList);
    setPostImageList(() => postImageList);
    setAllImageList(() => allImgs);

    setTimeout(() => handleOpenModal(), 200);
  };

  const addItemApi = usePut(
    locationData?.state?.data?.status == "APPROVED_IN_REVIEW" ||
      locationData?.state?.data?.status == "REJECTED_IN_REVIEW"
      ? `${process.env.REACT_APP_SHOPPER_URL}/stores/modify-cart-approver`
      : `${process.env.REACT_APP_SHOPPER_URL}/stores/modify-cart`,
    "addCart"
  );

  const addItem = () => {
    const productId = selectedProduct?.id
      ? selectedProduct?.id
      : selectedProduct?.productId;

    if (quantity !== 0 && productId !== "" && productId !== undefined) {
      const payload = {
        id: storeCarts?.[0]?.id,
        storeCartItems: [
          {
            productId,
            quantity,
            // transactionDate: moment(
            //   locationData?.checkoutDate,
            //   "yyyy-MM-DDTHH:mm:ss.SSSZ",
            //   "Europe/Berlin"
            // )
            //   .time.utc()
            //   .format("yyyy-MM-DDTHH:mm:ss.SSSZ"),
            activityType: "PICK",
            storeCode: locationData?.storeCode,
            note: "",
            isStockClearanceDiscount:
              selectedProduct?.isStockClearanceDiscount || false,
            scaleGroupId: allShelfData?.[0]?.scaleGroupId
              ? allShelfData?.[0]?.scaleGroupId
              : "",
            scaleIdentifier: allShelfData?.[0]?.scaleIdentifier
              ? allShelfData?.[0]?.scaleIdentifier
              : "",
          },
        ],
      };

      addItemApi.mutate(payload);
      // history.goBack();
      console.log(payload);
    } else {
      toast.error("Select one product or enter qty");
    }
  };

  useEffect(() => {
    if (addItemApi?.isSuccess) {
      toast.success("Product Added successfully");
      setIsProcessedCartApiCalled((flag) => !flag);
      addItemApi?.reset();
    }
  }, [addItemApi]);

  const onChangeTabs = (value) => {
    setSelectedIndex(value);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);

  const handleHover = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenPopup(true);
  };

  const handleHoverClose = () => {
    setAnchorEl(null);
    setOpenPopup(false);
  };

  function countRecordsUntilIndex(openCartDataByGroupId, openCurItemIndex) {
    return openCartDataByGroupId
      ?.slice(0, openCurItemIndex + 1)
      ?.reduce((acc, group) => acc + group.length, 0);
  }

  const numberOfRecords = countRecordsUntilIndex(
    openCartDataByGroupId,
    openCurItemIndex
  );

  console.log(numberOfRecords, "countRecordsUntilIndex");

  useEffect(() => {
    if (openCartData?.length > 0) {
      const index = openCartData?.findIndex(
        (item) =>
          item?.productId !== null &&
          item?.productId !== undefined &&
          item?.productId !== ""
      );
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_STORE_URL}/cart-item-tasks/store-cart/${
          (index > -1 ? openCartData?.[index] : openCartData?.[0])?.id
        }`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      }).then(({ data }) => {
        setCartItemTask(data);
        setRecommendedProductList(data?.recommendedDecision?.product);

        if (data?.recommendedDecision?.product.length > 0) {
          setSelectedData(data?.recommendedDecision?.product[0]);
        }
      });
    }
  }, [openCartData]);

  // useEffect(() => {
  //   if (openCartData?.length > 0 && allShelfData?.length > 0) {
  //     const index = openCartData?.findIndex(
  //       (item) =>
  //         item?.productId !== null &&
  //         item?.productId !== undefined &&
  //         item?.productId !== ""
  //     );
  //     if (index > -1) {
  //       const selectedItem = allShelfData
  //         ?.flatMap((item) => item?.productInfos)
  //         ?.find(
  //           (item) => item?.prproductId == openCartData?.[index]?.prproductId
  //         );
  //       setSelectedProduct(() => selectedItem);
  //       // const recommendedProduct;
  //       console.log("selectedItem", selectedItem);
  //     }
  //   }
  // }, [allShelfData]);

  const estimatedWeight = selectedEvent?.reduce(
    (acc, item) =>
      (Number(item?.changeInWeight) ? Number(item?.changeInWeight) : 0) + acc,
    0
  );

  const handleLogs = usePost(
    `${process.env.REACT_APP_STORE_URL}/app-logging-infos`,
    "handleCaptureLogs"
  );
  console.log("loginInfo", loginInfo);

  useEffect(() => {
    handleLogs.mutate({
      actionName: CART_PROCESSING_STARTED,
      logTime: new Date().toISOString(),
      screenLoadingTime: `-`,
      logSessionId: uniqID,
      rootOrgId: locationData?.rootOrgId,
      cartRef: locationData?.sessionRef,
    });
    // return handleLogs.mutate({
    //   actionName: CART_PROCESSING_STARTED,
    //   logTime: new Date().toISOString(),
    //   screenLoadingTime: `-`,
    //   logSessionId: "-",
    // });
  }, []);

  const { data: storeCartGroupEvent, mutate: storeCartGroupEventMutation } =
    usePost(
      `${process.env.REACT_APP_STORE_URL}/cart-item-tasks/store-cart-group-event`,
      ["storeCartGroupEvent"],
      selectedEvent?.length > 0,
      selectedEvent?.reduce((acc, data) => [...acc, data?.id], [])
    );

  const markAsNoise = usePut(
    `${process.env.REACT_APP_STORE_URL}/cart-item-tasks/reject-task-group-event`,
    "markAsNoise"
  );

  useEffect(() => {
    if (selectedEvent?.length > 0) {
      storeCartGroupEventMutation({
        idList: selectedEvent?.reduce((acc, data) => [...acc, data?.id], []),
      });
    }
  }, [selectedEvent]);

  const rejectItem = () => {
    if (storeCartGroupEvent?.data?.length > 0) {
      markAsNoise.mutate({
        idList: storeCartGroupEvent?.data?.reduce(
          (acc, data) => [...acc, data?.id],
          []
        ),
      });
    } else {
      toast.error("Please select events");
    }
  };

  useEffect(() => {
    if (markAsNoise?.isSuccess) {
      reset();
      toast.success("Marked as noise sucessfully");
      markAsNoise?.reset();
    }
  }, [markAsNoise]);

  console.log("storeCartGroupEvent", storeCartGroupEvent);

  return (
    <>
      <div className="w-[100vw] h-[100vh]  text-left  z-50 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className="w-full h-full bg-white flex flex-col justify-between items-center p-4">
          <div className="h-[5%] w-[100%] flex flex-row justify-between items-center">
            <div className="h-[40%] flex items-center justify-center w-[3%]">
              <BackArrowButton onClick={() => handleBack()} />
            </div>
            <div
              className={currentTab === 1 ? classBlue : classGray}
              onClick={() => setCurrentTab(1)}
            >
              <p>Product Events Review</p>
            </div>
            <div
              className={currentTab === 2 ? classBlue : classGray}
              onClick={() => setCurrentTab(2)}
              onMouseEnter={handleHover}
              onMouseLeave={handleHoverClose}
              aria-owns="cart-popover"
              aria-haspopup="true"
            >
              <CustomizedBadges
                color={currentTab === 2 ? "white" : "black"}
                number={
                  finalCartReivewData?.storeCartItems?.length
                    ? finalCartReivewData?.storeCartItems?.length
                    : 0
                }
              />
              <p className="ml-2">Final Cart Review</p>
            </div>
            {openPopup && (
              <div className="absolute right-5 z-[10000] top-[8%]  w-[45%] bg-white flex items-center justify-center p-5 border-2 border-black">
                <div className="w-[50%]">
                  <ItemsInCart data={finalCartReivewData?.storeCartItems} />
                </div>
              </div>
            )}
          </div>
          <div className="h-[94%]  w-[100%] flex flex-row justify-between items-center">
            <div className="h-[100%] relative bg-gray-300 w-[20%] flex flex-col items-center p-5">
              <img
                src={
                  MerchantsDetails.hasOwnProperty(locationData?.storeCode)
                    ? MerchantsDetails?.[locationData?.storeCode]?.imgUrl
                    : "/images/noImage.jpg"
                }
                width="80px"
                height="auto"
                alt="/images/noImage.jpg"
                // className="w-[100px] h-[100px]"
                // className="mt-3"
              />
              <div className="flex flex-col justify-between p-1 text-center">
                <p className="font-bold text-xl">
                  {entryData?.data?.[0]?.retailStoreCode}
                </p>
                <p
                  className="font-bold text-xl cursor-pointer flex items-center justify-center"
                  onClick={() =>
                    navigator.clipboard
                      .writeText(entryData?.data?.[0]?.cartRef)
                      .then(() => {
                        console.log(
                          "Text copied to clipboard",
                          entryData?.data?.[0]?.cartRef
                        );
                        toast.success("Copied cart ref", {
                          autoClose: 1000,
                          position: toast.POSITION.TOP_CENTER,
                          // width: ,
                        });
                      })
                  }
                  onMouseEnter={() => setIsCartHovered(true)}
                  onMouseLeave={() => setIsCartHovered(false)}
                >
                  {/* {entryData?.data?.[0]?.cartRef?.split("_")?.[1]} */}
                  <p>{entryData?.data?.[0]?.cartRef} </p>
                  {isCartHovered && (
                    <Iconify
                      // onClick={() =>
                      //   navigator.clipboard
                      //     .writeText(entryData?.data?.[0]?.cartRef)
                      //     .then(() => {
                      //       console.log(
                      //         "Text copied to clipboard",
                      //         entryData?.data?.[0]?.cartRef
                      //       );
                      //       toast.success("copied", {
                      //         autoClose: 1000,
                      //         position: toast.POSITION.TOP_CENTER,
                      //         // width: ,
                      //       });
                      //     })
                      // }
                      icon="ph:copy"
                    />
                  )}
                </p>
                <p>
                  <b>Entry : </b>
                  {` ${getTime(
                    entryData?.data?.[0]?.entryTime,
                    entryData?.data?.[0]?.storeTimeZone
                  )} (${entryData?.data?.[0]?.storeTimeZone})`}
                </p>
                <p>
                  <p>
                    <b>Exit : </b>
                    {` ${getTime(
                      exitData?.data?.[0]?.exitTime,
                      exitData?.data?.[0]?.storeTimeZone
                    )} (${exitData?.data?.[0]?.storeTimeZone})`}
                  </p>
                </p>
                {/* <p>
                  <span className="font-bold">Cart Processed by</span> : Mark
                </p> */}
              </div>
              <div className="flex items-center justify-center w-[95%] mt-3">
                <div className="w-[100%] ">
                  <div style={sliderStyle}>
                    {combineUserImagePaths(entryData?.data || [])?.length >
                    0 ? (
                      <Slider {...settings}>
                        {combineUserImagePaths(entryData?.data || [])?.map(
                          (image, j) => (
                            <div
                              key={j}
                              style={{ border: "1px solid black !important" }}
                              onClick={handleOpenEntryCarousalModal}
                            >
                              <img
                                src={image}
                                alt=""
                                // height="80px"
                                // width="90px"
                                style={{
                                  width: "100px",
                                  height: "150px",
                                  // marginTop: "5px",
                                }}
                              />
                            </div>
                          )
                        )}
                      </Slider>
                    ) : (
                      <img
                        src="/images/noImage.jpg"
                        alt=""
                        height="150px"
                        width="100px"
                        // style={{ marginTop: "10px" }}
                        style={{
                          width: "100px",
                          height: "150px",
                          // marginTop: "5px",
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="w-[100%] pl-2">
                  <div style={sliderStyle}>
                    {combineUserImagePaths(exitData?.data || [])?.length > 0 ? (
                      <Slider {...settings}>
                        {combineUserImagePaths(exitData?.data || [])?.map(
                          (image, i) => (
                            <div key={i} onClick={handleOpenExitCarousalModal}>
                              <img
                                src={image}
                                alt=""
                                // height="70px"
                                // width="80px"
                                style={{
                                  width: "100px",
                                  height: "150px",
                                  // marginTop: "5px",
                                }}
                              />
                            </div>
                          )
                        )}
                      </Slider>
                    ) : (
                      <img
                        src="/images/noImage.jpg"
                        alt=""
                        height="150px"
                        width="100px"
                        // style={{ marginTop: "10px" }}
                        style={{
                          width: "100px",
                          height: "150px",
                          // marginTop: "5px",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="absolute bottom-5 grid grid-cols-2 gap-3 p-4">
                {sessionAllVideos?.length > 0 &&
                  sessionAllVideos?.map((ele, index) => {
                    return (
                      <Button
                        key={index}
                        onClick={() => setVideoUrl(ele)}
                        sx={{ width: 150 }}
                        variant="contained"
                        color={
                          ele?.name === videoUrl?.name ? "success" : "primary"
                        }
                      >
                        {ele?.name}
                      </Button>
                    );
                  })}
              </div>
              {sessionAllVideos?.length === 0 && (
                <div className="w-[100%] h-[100%] flex justify-center items-center">
                  <p className="w-[100%] text-center">No video available</p>
                </div>
              )}
            </div>
            {currentTab === 1 ? (
              <div className="h-[100%] w-[79%] flex flex-col justify-between items-center">
                <div className="h-[11%] w-[100%] flex justify-between p-2 items-center bg-gray-300">
                  <CustomSearch
                    placeholder="Search by shelf to see events or search by shelf to add the items manually"
                    // name="sessionRef.contains"
                    // value={inputValue["sessionRef.contains"]}
                    // onChange={(e) => handleOnchangeSearch(e)}
                    sx={{
                      width: "45%",
                      backgroundColor: "white",
                      borderRadius: "8px",
                    }}
                    onChange={(e) => {
                      let value = e.target.value;

                      // Convert lowercase letters to uppercase
                      value = value.toUpperCase();

                      // Allow only numbers, alphabets, and hyphens
                      value = value.replace(/[^A-Z0-9-]/g, "");

                      if (value.length > 6) {
                        value = value.substring(0, 6);
                      }

                      // Set the processed value back to the input field
                      e.target.value = value;

                      setOpencartApiParams({
                        ...opencartApiParams,
                        "scaleInternalCode.contains": e.target.value,
                      });
                      setShelfApiFilterParams((data) => ({
                        ...data,
                        shelfName: e.target.value,
                      }));
                      setSelectedProduct(null);
                      setSelectedEvent([]);
                      setSelectedShopper();
                    }}
                    // onKeyDown={(e) => handleKeyDown(e)}
                  />
                  <div className="h-[100%] pl-10 flex flex-row justify-between items-center">
                    <div className="flex w-[100%] h-[98%] flex-col justify-between items-center">
                      <Button
                        className={classes.greyButton}
                        sx={{ height: "47%", fontSize: "0.8em" }}
                        onClick={rejectItem}
                      >
                        Mark Noise
                      </Button>
                      <Button
                        className={classes.greyButton}
                        sx={{ mt: 1, height: "47%", fontSize: "0.8em" }}
                        onClick={markDirtyApiCall}
                      >
                        Mark Dirty
                      </Button>
                    </div>
                    <div className="flex w-[100%] h-[98%] ml-3 flex-col justify-between items-center">
                      <Button
                        className={classes.blueButton}
                        variant="contained"
                        sx={{ width: 200, height: "47%", fontSize: "0.8em" }}
                        onClick={handleOpenCoffevent}
                      >
                        Open Coffee Events
                      </Button>
                      <Button
                        className={classes.blueButton}
                        variant="contained"
                        sx={{
                          width: 200,
                          mt: 1,
                          height: "47%",
                          fontSize: "0.8em",
                        }}
                        onClick={onOpenTileVideoModal}
                      >
                        Request tile videos
                      </Button>
                    </div>
                  </div>
                  <div className="h-[100%] pl-2 flex flex-col justify-between">
                    <IconButton
                      aria-label="warranty"
                      size="large"
                      sx={{ p: 0 }}
                      onClick={() => handleOpenModal()}
                    >
                      <ProductRecognizationIcon />
                    </IconButton>
                    <div className="w-[75px] h-[30px] rounded-md bg-white flex items-center justify-center">
                      {" "}
                      {numberOfRecords} / {openCartApiData?.length}
                    </div>
                  </div>
                  <div className="h-[98%] pl-2 flex flex-col justify-between">
                    <div className="flex items-center">
                      <IOSSwitch
                        sx={{ m: 1 }}
                        checked={selectedIndex == 0}
                        onChange={(e) => {
                          // if (!e.target.checked) {
                          //   setOpetnData(
                          //     (data) =>
                          //       openCartApiData !== undefined &&
                          //       openCartApiData?.filter(
                          //         (ele) => ele?.syncStatus !== "CART_REVIEWED"
                          //       )
                          //   );
                          // } else {
                          //   setOpetnData(
                          //     (data) =>
                          //       openCartApiData !== undefined &&
                          //       openCartApiData?.filter(
                          //         (ele) => ele?.syncStatus === "CART_REVIEWED"
                          //       )
                          //   );
                          // }
                          selectedIndex === 1
                            ? onChangeTabs(0)
                            : onChangeTabs(1);
                        }}
                      />
                      <p>Assigned</p>
                    </div>
                    <Button
                      className={classes.greyButton}
                      variant="contained"
                      sx={{ width: 150, height: "45%", fontSize: "0.8em" }}
                      onClick={() => reset()}
                    >
                      Refresh
                    </Button>
                  </div>
                </div>
                <div className="h-[15%] w-[100%] flex justify-between items-cente ">
                  <div className="w-[100%] p-2 h-[100%] flex items-center bg-gray-300 overflow-x-auto">
                    {allShelfData?.map((cartData, i) =>
                      cartData?.productInfos?.map((product, index) => (
                        <Tooltip
                          title={
                            <>
                              <p>{`Product name: ${product?.productName}`}</p>
                              <p>{`Product barcode: ${product?.barCode}`}</p>
                              {product?.imageUrl ? (
                                <img
                                  style={{ width: "auto", height: "85%" }}
                                  src={product?.imageUrl}
                                  alt=""
                                />
                              ) : (
                                <img
                                  style={{ width: "auto", height: "85%" }}
                                  src="/images/noImage.jpg"
                                  alt=""
                                />
                              )}
                            </>
                          }
                        >
                          <div
                            className={`h-[95%] relative bg-[#868686] mr-2 cursor-pointer ${
                              product?.productId === selectedProduct?.productId
                                ? "border-4 border-black"
                                : ""
                            }`}
                            onClick={() => {
                              if (
                                product?.productId ===
                                selectedProduct?.productId
                              ) {
                                setSelectedProduct();
                                setSelectedEvent([]);
                                setSelectedShopper();
                                setOpencartApiParams((params) => ({
                                  ...params,
                                  "scaleGroupId.equals": "",
                                  "scaleIdentifier.equals": "",
                                }));
                              } else {
                                setSelectedProduct(product);
                                setSelectedEvent([]);
                                setSelectedShopper();
                                setOpencartApiParams((params) => ({
                                  ...params,
                                  "scaleIdentifier.equals":
                                    cartData?.scaleIdentifier,
                                  "scaleGroupId.equals": cartData?.scaleGroupId,
                                }));
                              }
                            }}
                          >
                            <p className="text-white absolute">
                              {`${cartData?.scaleIdentifier}`}
                            </p>
                            <div
                              key={i}
                              className="h-[90%] flex items-center justify-between flex-col p-3"
                            >
                              {product?.imageUrl ? (
                                <img
                                  style={{ width: "auto", height: "85%" }}
                                  src={product?.imageUrl}
                                  alt=""
                                />
                              ) : (
                                <img
                                  style={{ width: "auto", height: "85%" }}
                                  src="/images/noImage.jpg"
                                  alt=""
                                />
                              )}
                              {/* <ReactHover
                                options={{
                                  followCursor: true,
                                  shiftX: 0,
                                  shiftY: -300,
                                }}
                                style={{ height: "100%" }}
                              >
                                <Trigger type="trigger">
                                  {product?.imageUrl ? (
                                    <img
                                      style={{ width: "auto", height: "85%" }}
                                      src={product?.imageUrl}
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      style={{ width: "auto", height: "85%" }}
                                      src="/images/noImage.jpg"
                                      alt=""
                                    />
                                  )}
                                </Trigger>
                                <Hover type="hover">
                                  {product?.imageUrl ? (
                                    <img
                                      style={{ width: "auto", height: "85%" }}
                                      src={product?.imageUrl}
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      style={{ width: "auto", height: "85%" }}
                                      src="/images/noImage.jpg"
                                      alt=""
                                    />
                                  )}
                                </Hover>
                              </ReactHover> */}
                              <p className="text-white mt-1">
                                {cartData?.scaleType === "SURFBOARD"
                                  ? `${cartData?.scaleGroupName}-${
                                      product?.productPosition
                                        ? product?.productPosition
                                        : product?.scaleId
                                    }`
                                  : `${cartData?.scaleGroupName}-${product?.scaleId}`}
                              </p>
                            </div>
                          </div>
                        </Tooltip>
                      ))
                    )}
                  </div>
                </div>
                <div className="h-[43%] w-[100%] flex items-center justify-between">
                  <div className="w-[45%] h-[100%] bg-gray-300">
                    {/* <CarouselVideo /> */}
                    {videoUrl ? (
                      <div className="w-[100%] h-[100%] bg-black">
                        <VideoController
                          url={videoUrl?.videoUrl}
                          playRate={4}
                        />
                      </div>
                    ) : (
                      <div className="w-[100%] h-[100%] flex justify-center items-center">
                        {sessionAllVideos?.length > 0
                          ? "No video selected"
                          : "No video available"}
                      </div>
                    )}
                  </div>
                  <div className="w-[54%] h-[100%] bg-gray-300 p-2">
                    <ProductStickyTable
                      opencartApiParams={opencartApiParams}
                      openCartData={openCartData}
                      selectedProduct={selectedProduct}
                      selectedEvent={selectedEvent}
                      setSelectedEvent={setSelectedEvent}
                      isEventTypeAvailable={isEventTypeAvailable}
                      setSelectedProduct={setSelectedProduct}
                      quantity={quantity}
                      allShelfData={allShelfData}
                      handleOpenModal={handleOpenRecModal}
                    />
                  </div>
                </div>
                <div className="h-[19%] w-[100%] flex justify-between items-cente">
                  <div className="w-[100%] p-1 h-[100%] flex items-center bg-gray-300 overflow-x-auto bg">
                    {isEventTypeAvailable &&
                      !openCartData?.every(
                        (item) => item?.eventType === "PUT"
                      ) &&
                      recommendedShoppersList?.map((usersList, index) => {
                        let higherConfScore = 0;

                        usersList?.forEach((user) => {
                          if (user?.confidenceScore > higherConfScore) {
                            higherConfScore = user?.confidenceScore;
                          }
                        });

                        console.log(
                          "higherConfScore",
                          higherConfScore,
                          Math.max(...usersList?.map((o) => o?.confidenceScore))
                        );

                        return (
                          <div
                            className={`h-[100%] flex items-center flex-col justify-between ml-6 bg-[#A0A0A0] cursor-pointer relative p-1`}
                            style={{
                              border:
                                entryData?.data?.[0]?.cartRef ===
                                usersList?.[0]?.cartRef
                                  ? "4px solid black"
                                  : "",
                            }}
                            // onClick={() => setSelectedUsersGrp(usersList)}
                          >
                            <div className="h-[83%] w-[100%] flex items-center  justify-between">
                              {usersList?.map((user, ind) => (
                                <Tooltip
                                  title={
                                    <img
                                      style={{ width: "auto", height: "92%" }}
                                      src={
                                        user?.userImagePaths?.[0]
                                          ? user?.userImagePaths?.[0]
                                          : "/images/noImage.jpg"
                                      }
                                      alt=""
                                    />
                                  }
                                >
                                  <div
                                    key={ind}
                                    className={`h-[90%] w-[100%] flex items-center justify-center flex-col mx-2 ${
                                      selectedShopper?.id === user?.id
                                        ? "border-4 border-black"
                                        : ""
                                    }`}
                                    onClick={() => {
                                      if (selectedShopper?.id === user?.id) {
                                        setSelectedShopper();
                                      } else {
                                        setSelectedShopper(user);
                                      }
                                    }}
                                  >
                                    {user?.userImagePaths?.[0] ? (
                                      <img
                                        style={{
                                          width: "100%",
                                          height: "92%",
                                          minWidth: "125px",
                                        }}
                                        src={user?.userImagePaths?.[0]}
                                        alt=""
                                      />
                                    ) : (
                                      <img
                                        style={{
                                          width: "100%",
                                          height: "92%",
                                          minWidth: "125px",
                                        }}
                                        src="/images/noImage.jpg"
                                        alt=""
                                      />
                                    )}
                                  </div>
                                </Tooltip>
                              ))}
                            </div>

                            <p className="text-black font-bold">
                              {usersList?.[0]?.cartRef}
                            </p>
                            <p className="text-white border-[2px] border-black rounded-full p-1 bg-[#868686] absolute right-[-22px] top-0 z-10">
                              {higherConfScore
                                ? Math.round(higherConfScore)
                                : 0}
                              %
                            </p>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="h-[9%] w-[100%] flex justify-between items-center p-3 bg-gray-300">
                  <div className="w-[full] ml-3 flex justify-center items-center">
                    <div className="rounded-sm">
                      <p className="text-lg font-bold text-center">
                        {selectedProduct
                          ? `${allShelfData?.[0]?.scaleGroupName}-${selectedProduct?.productPosition}`
                          : `${
                              openCartData?.[0]?.scaleInternalCode
                                ? openCartData?.[0]?.scaleInternalCode
                                    ?.split("-")
                                    .slice(0, 2)
                                    .join("-")
                                : ""
                            } `}
                      </p>
                      <p className="text-lg font-bold text-center">
                        {openCartData?.[0]?.eventTime
                          ? getTimeOnly(
                              openCartData?.[0]?.eventTime,
                              openCartData?.[0]?.storeTimeZone
                            )
                          : ""}
                      </p>
                    </div>
                  </div>
                  <div className="h-[100%]">
                    {openCartData?.length > 0 &&
                      (selectedProduct && selectedProduct?.imageUrl ? (
                        <img
                          style={{ width: "90%", height: "100%" }}
                          src={selectedProduct?.imageUrl}
                          alt=""
                        />
                      ) : (
                        <img
                          style={{ width: "90%", height: "100%" }}
                          src="/images/noImage.jpg"
                          alt=""
                        />
                      ))}
                  </div>
                  <div className="">
                    <p className="text-lg font-bold text-center">
                      {selectedProduct?.productName}{" "}
                    </p>
                    <p className="text-lg font-bold text-center text-yellow-600">
                      {selectedProduct?.unitWeight}{" "}
                      {selectedProduct?.unitOfMeasure}
                    </p>
                  </div>
                  <div className="flex justify-between flex-col">
                    <p
                      className={`text-lg font-bold ${
                        quantity < 0 ? "text-red-500" : "text-green-600"
                      }`}
                    >
                      {` ${
                        !isNaN(parseFloat(estimatedWeight)?.toFixed(3))
                          ? parseFloat(estimatedWeight)?.toFixed(3)
                          : ""
                      } ${
                        openCartData?.[0]?.productInfos?.[0]?.unitOfMeasure
                          ? openCartData?.[0]?.productInfos?.[0]?.unitOfMeasure
                          : ""
                      }`}
                    </p>
                    {/* {openCartData?.length > 0 && isEventTypeAvailable && ( */}
                    <div className="h-[100%] flex justify-between items-center flex-col">
                      <div className="h-[100%] flex items-center">
                        <input
                          type="number"
                          value={quantity}
                          onChange={(e) => setQuantity(e.target.value)}
                          className="w-[75px] h-[30px] bg-white flex items-center justify-center text-center"
                        />
                        {quantity < 0 && isEventTypeAvailable && (
                          <MdArrowDownward className="w-5 h-5 text-red-500  ml-2  " />
                        )}{" "}
                        {quantity > 0 && isEventTypeAvailable && (
                          <MdArrowUpward className="w-5 h-5 text-green-600 ml-2" />
                        )}
                      </div>
                      <div></div>
                    </div>
                    {/* )} */}
                    {/* {(!isEventTypeAvailable ||  openCartData?.every(
                          (item) => item?.eventType === "PUT"
                        )) && selectedProduct && (
                      <input
                        type="number"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        className="w-[75px] h-[30px] bg-white flex items-center justify-center text-center"
                      />
                    )} */}
                  </div>
                  <div className="h-full w-[225px] flex justify-center flex-col">
                    {isEventTypeAvailable && (
                      <div className="flex items-center justify-center flex-col">
                        <div className="flex items-center justify-between  w-[100%]">
                          <Button
                            color="success"
                            sx={{ height: "80%", width: "49.5%" }}
                            variant="contained"
                            onClick={() => {
                              setSelectedProduct(null);
                              setSelectedEvent([]);
                              setSelectedShopper();
                              setOpenCurItemIndex((index) =>
                                Math.max(--index, 0)
                              );
                            }}
                          >
                            Previous
                          </Button>
                          <Button
                            color="success"
                            sx={{ height: "80%", ml: 2, width: "49.5%" }}
                            variant="contained"
                            onClick={() => {
                              setSelectedProduct(null);
                              setSelectedEvent([]);
                              setSelectedShopper();
                              setOpenCurItemIndex((index) =>
                                index === openCartDataByGroupId?.length - 1
                                  ? index
                                  : ++index
                              );
                            }}
                          >
                            Next
                          </Button>
                        </div>
                        {openCartData &&
                        openCartData?.every(
                          (item) => item?.eventType === "PUT"
                        ) ? null : (
                          <div className="flex items-center justify-between w-[100%]">
                            <Button
                              // className={classes.greenButton}
                              disabled={
                                !(
                                  selectedEvent?.length > 0 &&
                                  selectedProduct &&
                                  selectedShopper
                                )
                              }
                              color="success"
                              sx={{ height: "75%", fontSize: "0.8em" }}
                              variant="contained"
                              fullWidth
                              // onClick={() => {
                              //   if (
                              //     item?.syncStatus === "CART_REVIEWED" ||
                              //     item?.syncStatus === "PROCESSED"
                              //   ) {
                              //     unassignFn(item);
                              //   } else {
                              //     assignFn(item, selectedShopper);
                              //   }
                              // }}
                              onClick={() => {
                                if (quantity < 0) {
                                  toast.error(
                                    "Please enter valid quantity number"
                                  );
                                  return;
                                }
                                const ids = selectedEvent?.map((item) => ({
                                  id: item?.id,
                                }));
                                assignEvents.mutate({
                                  barCode: selectedProduct?.barCode,
                                  name: selectedProduct?.productName,
                                  userInfo: {
                                    cartRef: selectedShopper?.cartRef,
                                    id: selectedShopper?.id,
                                  },
                                  quantity,
                                  events: ids,
                                });
                              }}
                            >
                              Assign
                            </Button>
                          </div>
                        )}
                      </div>
                    )}
                    {(!isEventTypeAvailable ||
                      (openCartData &&
                        openCartData?.every(
                          (item) => item?.eventType === "PUT"
                        ))) && (
                      <Button
                        // className={classes.greenButton}
                        color="success"
                        variant="contained"
                        disabled={!selectedProduct}
                        onClick={() => {
                          if (quantity < 0) {
                            toast.error("Please enter valid quantity number");
                            return;
                          }
                          addItem();
                        }}
                      >
                        Add product
                      </Button>
                    )}
                  </div>
                  <div className="h-full w-[225px] flex justify-center flex-col">
                    {/* <div className="flex justify-between items-center">
                  <Button
                    className={classes.greyButton}
                    // onClick={() => previous()}
                    sx={{ width: "48%" }}
                  >
                    Previous
                  </Button>
                  <Button
                    className={classes.greyButton}
                    sx={{ width: "48%" }}
                    // onClick={() => next()}
                  >
                    Next
                  </Button>
                </div> */}
                    <Button
                      // className={classes.greenButton}
                      color="success"
                      variant="contained"
                      onClick={() => setCurrentTab(2)}
                    >
                      Final Cart Review
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="h-[100%] w-[79%] flex flex-col justify-between items-center">
                <div className="h-[82%] w-[100%] flex justify-center p-2 items-center bg-gray-300">
                  <div className="w-[98%] h-[100%] bg-gray-300 p-2">
                    <FinalCartReview
                      storeCarts={storeCarts}
                      setCurrentTab={setCurrentTab}
                      setRefreshApis={setRefreshApis}
                      finalCartReivewData={finalCartReivewData}
                      setFinalCartReviewData={setFinalCartReviewData}
                      processCart={processCart}
                    />
                  </div>
                </div>
                {(localStorage.getItem("navigate") === "inReview" ||
                  localStorage.getItem("navigate") === "inflight" ||
                  localStorage.getItem("navigate") === "openEntryEvents" ||
                  localStorage.getItem("navigate") ===
                    "processedEntryEvents") && (
                  <div className="w-[100%] h-[15%] p-2 flex items-center justify-end">
                    <Button
                      variant="contained"
                      color="error"
                      sx={{ mr: 2, height: 60, width: 204 }}
                      onClick={() => rejected()}
                    >
                      Reject
                    </Button>
                    {finalCartReivewData?.storeCartItems?.length > 0 &&
                      isStoreCartItems && (
                        <Button
                          variant="contained"
                          color="success"
                          startIcon={
                            <CustomizedBadges
                              color="white"
                              number={
                                finalCartReivewData?.storeCartItems?.length
                                  ? finalCartReivewData?.storeCartItems?.length
                                  : 0
                              }
                            />
                          }
                          sx={{ height: 60 }}
                          onClick={() => confirm()}
                        >
                          Generate Cart
                        </Button>
                      )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {isModalOpen && (
        <ProductRecognizationModal
          handleOpenModal={handleOpenModal}
          isModalOpen={isModalOpen}
          handleCloseModal={handleCloseModal}
          preImageList={preImageList}
          postImageList={postImageList}
          allImageList={allImageList}
          recommendedProductList={recommendedProductList}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
        />
      )}

      {isTileVideoModalOpen && (
        <ModalTileVideoForm
          open={isTileVideoModalOpen}
          onCloseModal={onCloseTileVideoModal}
          cartRef={locationData?.sessionRef}
          entryTime={locationData?.entryTime}
          exitTime={locationData?.checkoutDate}
          storeCode={locationData?.storeCode}
        />
      )}

      {combineUserImagePaths(entryData?.data || [])?.length > 0 &&
        openEntryCarousal && (
          <CarousalModal
            open={openEntryCarousal}
            handleClose={handleCloseEntryCarousalModal}
            data={combineUserImagePaths(entryData?.data || [])}
          />
        )}

      {combineUserImagePaths(exitData?.data || [])?.length > 0 &&
        openExitCarousal && (
          <CarousalModal
            open={openExitCarousal}
            handleClose={handleCloseExitCarousalModal}
            data={combineUserImagePaths(exitData?.data || [])}
          />
        )}

      {open && (
        <AlertDialog open={open} setOpen={setOpen} handleConfirm={confirm} />
      )}

      {isCoffeeEventModal && (
        <CoffeeEventModel
          open={isCoffeeEventModal}
          handleClose={handleCloseCoffevent}
          data={finalCartReivewData}
        />
      )}
    </>
  );
};

export default ProductEvent;
