/* eslint-disable eqeqeq */
import React, { useState, useEffect, Fragment } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useHistory, useLocation } from "react-router-dom";
import SelectEvent from "./SelectEvent";
import AlreadySelected from "./AlreadySelected";
import { useGet } from "../../../../../../api/useGet";
import moment from "moment";
import axios from "axios";
import { useAlert } from "react-alert";
import EntryImages from "./EntryImages";
import StoreOpenCardThumbnail from "./StoreOpenCardThumbnail";
import NoVideo from "../../../../../../Images/novideo.jpg";
import NoImage from "../../../../../../Images/noImage.jpg";
import { getTime } from "../../../../../../logic/useGetTime";
import ManualExit from "../../../components/ManualExit";
import ShopperThumbnail from "../../../../../../components/StoreOpenCardThumbnail";
import { IoMdRefresh } from "react-icons/io";
import { Dialog, Transition } from "@headlessui/react";
import { IoIosClose } from "react-icons/io";
import { toast } from "react-toastify";
import ModalTileVideoForm from "../edit/ModalTileVideoForm";
import { Box, Button, Grid, Typography } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Iconify from "../../../../../../components/iconify/Iconify";
import VideoController from "../../../../../../custom-control/VideoController";
import ImageSlider from "./ImagesSlider";
import StickyHeadTable from "./ProductEventSummaryTable";
import BackArrowButton from "../../../../../../components/buttons/BackarrowButton";
import RequestPriorityVideoModal from "./modal/RequestPriorityVideoModal";
import { usePatch } from "../../../../../../api/usePatch";
import CustomDropdown from "../../../../../../components/dropdown";
import {
  EXIT_RESONS,
  OPEN_ENTRY_EVENT_CONFIRMATION,
  OPEN_ENTRY_EVENT_STARTED,
} from "../../../../../../utils/action_types";
import { usePost } from "../../../../../../api/usePost";
import { getDecryptedData } from "../../../../../../utils/localStorageUtils";

const settings = {
  // dots: true,
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <Iconify color="black" icon="ooui:next-ltr" />,
  prevArrow: <Iconify color="black" icon="ooui:previous-ltr" />,
};

// Custom CSS styles for the slider container
const sliderStyle = {
  maxWidth: "500px", // Set the desired width
  maxHeight: "300px", // Set the desired height
  padding: "10px",
  paddingTop: "0",
  // marginLeft: "50px",
  // marginRight: "50px",
  // display: "flex",
  // justifyContent: "center",
  // alignItems: "center",
};

const videoSliderStyle = {
  maxWidth: "400px", // Set the desired width
  maxHeight: "200px", // Set the desired height
  padding: "10px",
  paddingTop: "0",
  // marginLeft: "130px",
  // marginRight: "50px",
  // display: "flex",
  // justifyContent: "center",
  // alignItems: "center",
};

function MatchEvent() {
  const history = useHistory();
  const {
    state: { data: exit },
  } = useLocation();
  const alert = useAlert();
  console.log("exit", exit);

  let [isOpen, setIsOpen] = useState(false);
  let [isTileVideoModalOpen, setIsTileVideoModalOpen] = useState(false);
  const [allStoreData, setAllStoreData] = useState(null);
  let [isRequestTileVideoModalOpen, setIsRequestTileVideoModalOpen] =
    useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const onOpenRequestTileVideoModal = () =>
    setIsRequestTileVideoModalOpen(true);

  const onOpenTileVideoModal = () => {
    setIsTileVideoModalOpen(true);
  };

  const onCloseTileVideoModal = () => {
    setIsTileVideoModalOpen(false);
  };

  const [input, setInput] = useState({
    note: "",
  });

  const [entryImages, setEntryImages] = useState([]);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const url =
    exit?.through === "processedEntry"
      ? `${process.env.REACT_APP_STORE_URL}/user-exit-activities?userActivityId.equals=${exit?.id}`
      : `${
          process.env.REACT_APP_STORE_URL
        }/user-exit-activities?exitTime.greaterThanOrEqual=${
          exit?.entryTime
        }&exitTime.lesserThanOrEqual=${moment()
          .endOf("day")
          .format(
            "YYYY-MM-DDT23:59:59[Z]"
          )}&status.equals=OPEN&sort=entryTime,desc&retailStoreCode.equals=${
          exit?.retailStoreCode
        }`;

  const { data: shopperList } = useGet(url, "shopperList");

  const { data: entriesImages } = useGet(
    `${process.env.REACT_APP_STORE_URL}/user-activities/${exit?.id}`,
    "entriesImages"
  );

  const [select, setSelect] = useState();

  const onChangeTextArea = (e, name) => {
    setInput({ ...input, [name]: e.target.value });
  };

  const loginInfo = getDecryptedData("loginInfo");
  const uniqID = localStorage.getItem("uniqID");

  const goNext = () => {
    // if (input?.note == "") {
    //   alert.error("Please select the Select reason for manual exit");
    //   return;
    // }
    if (select) {
      const payload = {
        ...select,
        userActivityId: exit?.id,
        performedBy: localStorage.getItem("user-name"),
        notes: input?.note,
        matchType: "manual",
      };

      const data = {
        entryTime: exit?.entryTime,
        checkoutDate: select?.exitTime,
        storeCode: exit?.retailStoreCode,
        sessionRef: exit?.cartRef,
        rootOrgId: exit?.rootOrgId,
      };

      axios({
        method: "PUT",
        url: `${process.env.REACT_APP_STORE_URL}/user-exit-activities/${select.id}/assign-user`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
        data: payload,
      })
        .then((res) => {

          handleLogs.mutate({
            actionName: OPEN_ENTRY_EVENT_CONFIRMATION,
            logTime: new Date().toISOString(),
            screenLoadingTime: `-`,
            logSessionId: uniqID,
            rootOrgId: exit?.rootOrgId,
            userActivityId: exit?.id,
          });
          history.push({
            pathname: "/support/match/openEvents",
            state: {
              data: data,
            },
          });
        })
        .catch(({ message }) => {
          if (message === "Request failed with status code 401") {
            history.push("/login");
          } else {
            toast.error(message);
          }
        });
    } else {
      alert.error(<div className="w-96">Match the correct event.</div>);
    }
  };

  useEffect(() => {
    const people = [];

    for (let i = 0; i < 3; i++) {
      people.push(
        entriesImages?.userImagePaths?.[i]
          ? entriesImages.userImagePaths?.[i]
          : ""
      );
    }
    setEntryImages(people);
  }, [entriesImages]);

  const onSelect = (data) => {
    setSelect(data);
  };

  const shopperLoop = (entriesImages) => {
    return entriesImages?.shopperVideoPaths?.find(
      (ele) => ele.name === "ShopperLoop"
    );
  };

  const trackingVideo = (entriesImages) => {
    return entriesImages?.shopperVideoPaths?.filter(
      (ele) => ele.name !== "ShopperLoop"
    );
  };

  const entryVideo = (entriesImages) => {
    return entriesImages?.shopperVideoPaths?.filter(
      (ele) => ele.name === "Entry"
    );
  };

  const processCart = async () => {
    const payload = {
      sessionRef: exit?.cartRef,
      rootOrgId: exit?.rootOrgId,
    };

    axios({
      method: "PUT",
      url: `${process.env.REACT_APP_SHOPPER_URL}/stores/cart-review`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
      data: payload,
    })
      .then(({ data }) => {
        history.push({
          pathname: "/support/match/express-checkout",
          state: { data, entriesImages },
        });
      })
      .catch(({ message }) => {
        if (message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          toast.error(message);
        }
      });
  };

  const handleBack = () => history.goBack();

  const { data: enableExpressCheckout } = useGet(
    `${process.env.REACT_APP_BASE_URL}/retail-store-configurations/${allStoreData?.id}`,
    ["isStockClearanceDiscount", allStoreData],
    allStoreData !== null
  );

  const { data: storeData } = useGet(
    `${process.env.REACT_APP_BASE_URL}/retail-stores?page=0&sort=createdDate,desc&rootOrgId.equals=${exit?.rootOrgId}`,
    "storeData"
  );

  const { data: cartDetailsApiData } = useGet(
    `${process.env.REACT_APP_SHOPPER_URL}/stores/carts?page=0&size=1&sessionRef.equals=${exit?.cartRef}&rootOrgId.equals=${exit?.rootOrgId}`,
    "cartDetailsApi"
  );

  const captureMatrix = usePatch(
    `${process.env.REACT_APP_SHOPPER_URL}/stores/cart/${cartDetailsApiData?.[0]?.id}`,
    "captureMatrix"
  );

  useEffect(() => {
    const filterData = storeData?.find(
      (item) => item?.internalCode == exit?.retailStoreCode
    );
    setAllStoreData(filterData);
  }, [storeData]);

  const isExpressCheckout = enableExpressCheckout?.find(
    (item) => item?.configName === "ENABLE_EXPRESS_CHECKOUT"
  );

  const handleLogs = usePost(
    `${process.env.REACT_APP_STORE_URL}/app-logging-infos`,
    "handleCaptureLogs"
  );

  useEffect(() => {
    handleLogs.mutate({
      actionName: OPEN_ENTRY_EVENT_STARTED,
      logTime: new Date().toISOString(),
      screenLoadingTime: `-`,
      logSessionId: uniqID,
      rootOrgId: exit?.rootOrgId,
      userActivityId: exit?.id,
      cartRef: exit?.cartRef
    });
  }, []);

  console.log("moment", moment.utc().toISOString());

  return (
    <div className="h-[calc(100vh-60px)] w-full">
      <div className="h-8 flex items-center ">
        <div className="absolute mx-4 flex items-center">
          {/* <BiArrowBack
            onClick={() => history.goBack()}
            className="h-6 w-6 cursor-pointer"
          /> */}
          <BackArrowButton onClick={() => handleBack()} />
        </div>

        <div className="w-full flex justify-center items-center">
          <h2 className="font-bold text-2xl">
            {/* {exit?.through === "processedEntry"
              ? "User Session"
              : "Review User Session"} */}
            Cart Summary
          </h2>
        </div>
      </div>
      <div class="border-b border-solid border-black"></div>

      <div className="w-full h-[calc(100vh-57px-35px)] flex rounded-t-xl pt-8 bg-white space-x-[10px]">
        {/* entry images */}
        <div className="w-[73%] h-full flex flex-col">
          <div className="w-[90%] flex justify-between">
            <div className="flex justify-center items-center">
              {entryImages?.length > 0 ? (
                entryImages?.map((url, index) => (
                  <EntryImages url={url} index={index} key={index} />
                ))
              ) : (
                <img src={NoImage} className="w-40 h-44" alt="entryimage" />
              )}
            </div>
            <div style={videoSliderStyle}>
              <VideoController
                url={entriesImages?.shopperVideoPaths?.[0]?.videoUrl}
              />
              {/* {entryVideo?.length > 0 ? (
                <Slider {...settings}>
                  {entriesImages?.shopperVideoPaths
                    ?.filter((item) => item?.name == "Entry")
                    ?.map((item) => (
                      <div>
                        <VideoController url={item?.videoUrl} />
                      </div>
                    ))}
                </Slider>
              ) : (
                <img src={NoVideo} className="w-44 h-44" alt="exitimage" />
              )} */}
            </div>
          </div>
          <p>
            <span className="font-bold mt-1">Entry Time : </span>{" "}
            {getTime(exit.entryTime, exit?.storeTimeZone)}
          </p>

          <div className="mt-20 ml-4">
            <div className="flex justify-between items-center mr-2 h-[90px]">
              <h2 className="font-bold text-lg">
                {exit?.through === "processedEntry"
                  ? `Matched`
                  : `Choose the matching exit`}
              </h2>

              {exit?.through !== "processedEntry" && (
                <ManualExit
                  userId={exit?.id}
                  timeZone={exit?.storeTimeZone}
                  input={input}
                  cartRef={exit?.cartRef}
                  rootOrgId={exit?.rootOrgId}
                />
              )}
            </div>

            <div className="h-[56vh] w-[4/5%] pt-2 pr-6 space-y-2 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 ">
              {shopperList?.length > 0 ? (
                shopperList?.map((rowData, index) => {
                  return (
                    <div
                      key={index}
                      className="border-b-4 border-gray-400 space-y-2 flex flex-col justify-between"
                    >
                      <div>
                        {exit?.through === "processedEntry" ? (
                          <div className="flex flex-col">
                            <AlreadySelected
                              data={rowData}
                              index={index}
                              setSelect={setSelect}
                              select={select}
                              key={index}
                              isConfidenceScore
                            />
                          </div>
                        ) : (
                          <SelectEvent
                            data={rowData}
                            index={index}
                            onSelect={onSelect}
                            select={select}
                            key={index}
                            name={"userImagePaths"}
                            idName={"id"}
                            cartRef={exit?.cartRef}
                            isConfidenceScore
                          />
                        )}
                      </div>

                      <div className="h-10 flex justify-center items-end">
                        {rowData?.exitTime && (
                          <p className="pl-10">
                            <span className="font-bold">Exit Time : </span>
                            {getTime(rowData?.exitTime, rowData?.storeTimeZone)}
                          </p>
                        )}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="border-b-4 border-gray-400 space-x-4">
                  {exit?.through === "processedEntry" ? (
                    <div className="flex flex-col">
                      <AlreadySelected
                        data={shopperList}
                        setSelect={setSelect}
                        select={select}
                      />
                    </div>
                  ) : exit?.through === "openEntry" ? (
                    <SelectEvent
                      data={shopperList}
                      onSelect={onSelect}
                      select={select}
                      name={"userImagePaths"}
                      idName={"id"}
                    />
                  ) : null}

                  {shopperList?.exitTime && (
                    <p>
                      <span className="font-bold">Exit Time : </span>
                      {getTime(
                        shopperList?.exitTime,
                        shopperList?.storeTimeZone
                      )}
                    </p>
                  )}
                </div>
              )}

              {/* video exit session links passed in modal */}
              {/* <div className="w-full flex justify-between items-center pb-2">
                <div className="w-[40%] flex flex-col center items-center">
                  {shopperLoop(entriesImages) ? (
                    <ShopperThumbnail
                      imageUrl={shopperLoop(entriesImages)?.thumbnail}
                      videoUrl={shopperLoop(entriesImages)?.videoUrl}
                      index={1}
                    />
                  ) : (
                    <img src={NoImage} className="w-44 h-44" alt="exitimage" />
                  )}
                  <div className="w-[58%] flex items-center mt-2 justify-end ">
                    <button
                      type="button"
                      onClick={onOpenTileVideoModal}
                      className="cursor-pointer rounded-md bg-green-500 bg-opacity-80 px-4 py-2 text-sm font-medium text-white mr-3 hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                    >
                      Request Tile Videos
                    </button>
                    <button
                      type="button"
                      onClick={openModal}
                      className="cursor-pointer rounded-md bg-green-500 bg-opacity-80 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                    >
                      See all Tile videos
                    </button>
                  </div>
                </div>
                <div className="w-[60%] flex justify-between items-center">
                  <StickyHeadTable productEventSummary={productEventSummary} />
                </div>
              </div> */}

              {/* <div className="w-full flex justify-between items-center ">
                {shopperLoop(entriesImages) ? (
                  <ShopperThumbnail
                    imageUrl={shopperLoop(entriesImages)?.thumbnail}
                    videoUrl={shopperLoop(entriesImages)?.videoUrl}
                    index={1}
                  />
                ) : (
                  <img src={NoImage} className="w-44 h-44" alt="exitimage" />
                )}
                <div className="w-[58%] flex items-center mt-24 justify-end ">
                  <button
                    type="button"
                    onClick={onOpenTileVideoModal}
                    className="cursor-pointer rounded-md bg-green-500 bg-opacity-80 px-4 py-2 text-sm font-medium text-white mr-3 hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                  >
                    Request Tile Videos
                  </button>
                  <button
                    type="button"
                    onClick={openModal}
                    className="cursor-pointer rounded-md bg-green-500 bg-opacity-80 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                  >
                    See all Tile videos
                  </button>
                </div>
              </div> */}

              <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-black bg-opacity-60" />
                  </Transition.Child>

                  <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <Dialog.Panel className="w-full max-w-[48rem] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900 flex justify-between items-center"
                          >
                            <p>Session Videos</p>

                            <IoIosClose
                              onClick={closeModal}
                              size={28}
                              className="bg-gray-500 rounded-full bg-opacity-20 shadow-lg cursor-pointer"
                            />
                          </Dialog.Title>

                          {exit?.through === "processedEntry" && (
                            <div className="mt-6 grid grid-cols-4  gap-y-12">
                              {trackingVideo(entriesImages)?.length > 0 ? (
                                trackingVideo(entriesImages)?.map(
                                  (ele, index) => {
                                    return (
                                      <div className="flex flex-col items-center justify-center">
                                        <StoreOpenCardThumbnail
                                          imageUrl={ele?.thumbnail}
                                          videoUrl={ele?.videoUrl}
                                          camera={ele?.name}
                                          index={index}
                                        />
                                        <p>{ele.name}</p>
                                      </div>
                                    );
                                  }
                                )
                              ) : (
                                <img
                                  src={NoVideo}
                                  className="w-44 h-4"
                                  alt="exitimage"
                                />
                              )}
                            </div>
                          )}
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition>
              <ModalTileVideoForm
                open={isTileVideoModalOpen}
                onCloseModal={onCloseTileVideoModal}
                cartRef={exit?.cartRef}
                entryTime={exit?.entryTime}
                exitTime={exit?.exitTime}
                storeCode={exit?.retailStoreCode}
              />
            </div>
          </div>
        </div>

        {/* right  */}
        <div className={`w-[25.5%] h-[100%]`}>
          <div className="bg-gray-200 w-full h-[calc(100vh-100px)] flex flex-col space-y-5 rounded-lg p-3">
            <div className="w-full flex flex-col items-start">
              {exit?.noOfGroupShoppers > 1 ? (
                <div className=" w-full flex text-left text-[0.94rem]">
                  <p className="w-[118px] font-bold text-[0.95rem]">
                    Group Shoppers
                  </p>
                  <p>{` :  ${exit?.noOfGroupShoppers}`}</p>
                </div>
              ) : (
                <div className=" w-full flex text-left text-[0.94rem]">
                  <p className="w-[118px] font-bold">Shopper#</p>
                  <p>{` :  ${exit?.noOfGroupShoppers}`}</p>
                </div>
              )}

              <div className="w-full flex text-left text-[0.94rem]">
                <p className="w-[118px] font-bold">Name: </p>
                <p>{` :  ${exit?.name}`}</p>
              </div>

              <div className="w-full flex text-left text-[0.94rem]">
                <p className="w-[118px] font-bold">Store ID</p>
                <p>{` :  ${exit?.retailStoreCode}`}</p>
              </div>

              <div className=" w-full flex text-left text-[0.94rem]">
                <p className="w-[118px] font-bold">Cart Ref</p>
                <p>{` :  ${exit?.cartRef}`}</p>
              </div>

              {exit?.through === "processedEntry" ? (
                <>
                  <div className="w-full flex text-left text-[0.94rem]">
                    <p className="w-[118px] font-bold">Performed By</p>
                    <p>{` :  ${exit?.performedBy ? exit.performedBy : ""}`}</p>
                  </div>

                  <div className="w-full flex text-left text-[0.94rem]">
                    <p className="w-[118px] font-bold">Match Type</p>
                    <p>{` :  ${exit?.matchType}`}</p>
                  </div>

                  <div className="w-full flex text-left text-[0.94rem]">
                    <p className="w-[118px] font-bold">Comment</p>
                    <p>{` :  ${exit?.notes ? exit?.notes : ""}`}</p>
                  </div>
                </>
              ) : (
                <div className="w-full flex text-left text-[0.94rem]">
                  <p className="w-[118px] font-bold">Performed By</p>
                  <p>{` :  ${localStorage?.getItem("user-name")}`}</p>
                </div>
              )}
            </div>
            {select ? (
              <div className="w-full flex flex-col px-4 py-2 bg-white rounded-lg justify-center items-center mt-[20px] ">
                <div className="my-2 w-full flex justify-center items-center space-x-4">
                  {entryImages.length > 0 && (
                    <img
                      src={entryImages[0]}
                      className="w-28 h-60 rounded"
                      alt="selectimage"
                    />
                  )}

                  {select?.userImagePaths?.length > 0 ? (
                    <img
                      src={select?.userImagePaths[0]}
                      alt="event"
                      className="w-28 h-64 rounded"
                    />
                  ) : (
                    <img
                      src={NoImage}
                      className="w-28 h-64 rounded"
                      alt="selectimage"
                    />
                  )}
                </div>

                <div className="flex w-[300px] justify-center mt-2 text-xs space-x-[1px]">
                  <h2 className="text-left">Entry Time </h2>
                  <h2 className="">{`: ${getTime(
                    exit?.entryTime,
                    exit?.storeTimeZone
                  )}`}</h2>
                </div>

                <div className="flex w-[300px]  justify-center mt-2 text-xs space-x-[1px]">
                  <h2 className="w-[60px] text-left">Exit Time </h2>
                  <h2 className="">{`: ${getTime(
                    select?.exitTime,
                    select?.storeTimeZone
                  )}`}</h2>
                </div>
              </div>
            ) : (
              <div className="w-full  flex flex-col px-4 py-2 bg-white rounded">
                <p>Select One Match Entry.</p>
              </div>
            )}
            <div className="flex flex-col p-4 pt-2 rounded-lg justify-center items-center bg-white">
              <Typography variant="h6">Access Cart videos</Typography>
              <ShopperThumbnail
                imageUrl={shopperLoop(entriesImages)?.thumbnail}
                videoUrl={shopperLoop(entriesImages)?.videoUrl}
                index={1}
              />
              <button
                type="button"
                onClick={handleClickOpen}
                className="mt-2 cursor-pointer rounded-md bg-green-500 bg-opacity-80 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 w-[185px]"
              >
                Request Priority Video
              </button>
              <button
                type="button"
                onClick={onOpenTileVideoModal}
                className="mt-2 cursor-pointer rounded-md bg-green-500 bg-opacity-80 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 w-[185px]"
              >
                Request Tile Videos
              </button>
              <button
                type="button"
                onClick={openModal}
                className="mt-2 cursor-pointer rounded-md bg-green-500 bg-opacity-80 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 w-[185px]"
              >
                See all Tile videos
              </button>
            </div>

            {exit?.through === "processedEntry" ? (
              <>
                {exit?.cartRef?.length > 0 && (
                  <div className="w-full flex justify-center items-center">
                    <button
                      onClick={() => {
                        history.push({
                          pathname: "/support/match/openEvents",
                          state: {
                            data: {
                              entryTime: exit?.entryTime,
                              checkoutDate: exit?.exitTime,
                              storeCode: exit?.retailStoreCode,
                              sessionRef: exit?.cartRef,
                              rootOrgId: exit?.rootOrgId,
                            },
                          },
                        });
                        localStorage.setItem("mIndex", parseInt(0));
                        captureMatrix.mutate({
                          id: cartDetailsApiData?.[0]?.id,
                          reviewStartTime: moment.utc().toISOString(),
                        });
                      }}
                      className="bg-green-600 text-white w-[180px] h-8 rounded"
                    >
                      View Shopping Items
                    </button>
                    <button
                      onClick={() => {
                        history.push({
                          pathname: "/support/match/openEvents/beta",
                          state: {
                            data: {
                              entryTime: exit?.entryTime,
                              checkoutDate: exit?.exitTime,
                              storeCode: exit?.retailStoreCode,
                              sessionRef: exit?.cartRef,
                              rootOrgId: exit?.rootOrgId,
                            },
                          },
                        });
                        localStorage.setItem("mIndex", parseInt(0));
                        captureMatrix.mutate({
                          id: cartDetailsApiData?.[0]?.id,
                          reviewStartTime: moment.utc().toISOString(),
                        });
                      }}
                      className="bg-green-600 text-white w-[180px] h-8 rounded ml-3"
                    >
                      Review cart item (beta)
                    </button>
                  </div>
                )}
              </>
            ) : (
              <>
                {/* <textarea
                  placeholder="Comment"
                  className="rounded-lg px-4 py-2 focus:outline-none"
                  value={input?.note}
                  onChange={(e) => onChangeTextArea(e, "note")}
                /> */}
                <CustomDropdown
                  options={EXIT_RESONS}
                  name="note"
                  label={"Select reason for manual exit"}
                  value={input?.note}
                  onChange={(e, v) => {
                    setInput({ ...input, note: v });
                  }}
                />

                <div className="ml-10">
                  <IoMdRefresh
                    size={28}
                    className="cursor-pointer"
                    onClick={() => window.location.reload()}
                  />
                </div>

                <div className="">
                  <button
                    onClick={goNext}
                    className="bg-green-600 text-white w-24 h-8 rounded"
                  >
                    Confirm
                  </button>
                </div>
              </>
            )}
            {isExpressCheckout?.configValue?.toLowerCase() == "true" && (
              <Box display="flex" alignItems="center" justifyContent="center">
                <Button
                  variant="contained"
                  onClick={() => {
                    processCart();
                  }}
                  sx={{ width: "200px", bgcolor: "rgb(22, 163, 74)" }}
                >
                  Express Checkout
                </Button>
              </Box>
            )}
          </div>
        </div>
        {open && (
          <RequestPriorityVideoModal
            open={open}
            setOpen={setOpen}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            data={{
              entryTime: getTime(exit?.entryTime, exit?.storeTimeZone),
              checkoutTime: getTime(select?.exitTime, select?.storeTimeZone),
              cartRef: exit?.cartRef,
              storeCode: exit?.retailStoreCode,
            }}
          />
        )}
      </div>
    </div>
  );
}

export default MatchEvent;
