import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useGet } from "../../api/useGet";
import ModalUom from "../modal/ModalUom";
import ModalTax from "../modal/ModalTax";
import ModalBrand from "../modal/ModalBrand";
import ModalCategory from "../modal/ModalCategory";
import axios from "axios";

function TableStr({
  title,
  url,
  viewUrl,
  editUrl,
  listUrl,
  orgIdUrl,
  getOrgName,
  retailerIdUrl,
  getRetailerName,
  getName,
  Enabled,
  All,
  showInitialized,
  Disabled,
  Modal,
  dashboard,
  reatilerList,
  data1,
}) {
  const [click, setClicked] = useState("all");



  const { isLoading, error, data } = useGet(listUrl, getName);
  //const orgList = useGet(orgIdUrl, getOrgName);
  const retailerList = useGet(retailerIdUrl, getRetailerName);


  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

 

  function findField(filterData) {
    var tempArray = uniq(filterData);
    return tempArray.filter(
      (value, index) => tempArray.indexOf(value) === index
    );
  }

  function uniq(rawData) {
    var arr = [];
    rawData?.map((item) => {
      reatilerList ? arr.push(item?.retailStore?.name) : arr.push(item.name);
    });
    return arr;
  }

  

  const options = (e) => {
    const type = e.target.name;
    switch (type) {
      case "all":
        setClicked(type);
        break;
      case "enabled":
        setClicked(type);
        break;
      case "disabled":
        setClicked(type);
        break;
      case "initialized":
        setClicked(type);

      case "notInitialized":
        setClicked(type);
      default:
    }
  };

  return (
    <div className="w-full h-full shadow-lg select-none">
      <div
        className={`w-full ${
          dashboard ? "h-16" : "h-12"
        } flex justify-between p-6 items-center bg-gray-100`}
      >
        <div>
          <h2 className="font-bold text-2xl">{title}</h2>
        </div>

        
          <div>
            <Link
              to={{
                pathname: url,
                state: {
                 // orgList: orgList ? orgList : null,
                  retailerList: retailerList ? retailerList : null,
                },
              }}
            >
              <button
                className={`bg-green-500 rounded-sm ${
                  dashboard ? "py-2 px-4" : "py-1 px-3"
                } mx-4 focus:outline-none text-white`}
              >
                <span className="h-full flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 64 64"
                    width="28px"
                    height="28px"
                  >
                    <linearGradient
                      id="KJ7ka9GQp0CHqT_2YsWMsa"
                      x1="32"
                      x2="32"
                      y1="5.75"
                      y2="59.005"
                      gradientUnits="userSpaceOnUse"
                      spreadMethod="reflect"
                    >
                      <stop offset="0" stop-color="#1a6dff" />
                      <stop offset="1" stop-color="#c822ff" />
                    </linearGradient>
                    <path
                      fill="url(#KJ7ka9GQp0CHqT_2YsWMsa)"
                      d="M32,58C17.663,58,6,46.337,6,32S17.663,6,32,6s26,11.663,26,26S46.337,58,32,58z M32,8 C18.767,8,8,18.767,8,32s10.767,24,24,24s24-10.767,24-24S45.233,8,32,8z"
                    />
                    <linearGradient
                      id="KJ7ka9GQp0CHqT_2YsWMsb"
                      x1="32"
                      x2="32"
                      y1="5.75"
                      y2="59.005"
                      gradientUnits="userSpaceOnUse"
                      spreadMethod="reflect"
                    >
                      <stop offset="0" stop-color="#1a6dff" />
                      <stop offset="1" stop-color="#c822ff" />
                    </linearGradient>
                    <path
                      fill="url(#KJ7ka9GQp0CHqT_2YsWMsb)"
                      d="M32,52c-11.028,0-20-8.972-20-20s8.972-20,20-20s20,8.972,20,20S43.028,52,32,52z M32,14 c-9.925,0-18,8.075-18,18s8.075,18,18,18s18-8.075,18-18S41.925,14,32,14z"
                    />
                    <linearGradient
                      id="KJ7ka9GQp0CHqT_2YsWMsc"
                      x1="32"
                      x2="32"
                      y1="21.75"
                      y2="42.538"
                      gradientUnits="userSpaceOnUse"
                      spreadMethod="reflect"
                    >
                      <stop offset="0" stop-color="#6dc7ff" />
                      <stop offset="1" stop-color="#e6abff" />
                    </linearGradient>
                    <path
                      fill="url(#KJ7ka9GQp0CHqT_2YsWMsc)"
                      d="M41,30h-7v-7c0-0.552-0.448-1-1-1h-2c-0.552,0-1,0.448-1,1v7h-7c-0.552,0-1,0.448-1,1v2 c0,0.552,0.448,1,1,1h7v7c0,0.552,0.448,1,1,1h2c0.552,0,1-0.448,1-1v-7h7c0.552,0,1-0.448,1-1v-2C42,30.448,41.552,30,41,30z"
                    />
                  </svg>
                  <span className="px-2">{`Create ${title}`}</span>
                </span>
              </button>
            </Link>
          </div>
       
      </div>

      {Modal === "ModalUom" ? (
        <ModalUom open={open} onCloseModal={onCloseModal} />
      ) : Modal === "ModalTax" ? (
        <ModalTax open={open} onCloseModal={onCloseModal} />
      ) : Modal === "ModalBrand" ? (
        <ModalBrand open={open} onCloseModal={onCloseModal} />
      ) : Modal === "ModalCategory" ? (
        <ModalCategory open={open} onCloseModal={onCloseModal} />
      ) : null}

      <div className="w-full h-10 p-2  -mt-2 ">
        <div className="h-10 w-full  flex justify-start ">
          <button
            onClick={options}
            name="all"
            className={`mx-4 border-b-2 ${
              click === "all" ? "border-blue-500" : null
            } focus:outline-none`}
          >
            {title === "Hawkeye" ? "In Review" : "All"}
          </button>
          {!showInitialized ? (
            <button
              onClick={options}
              name="enabled"
              className={`mx-4 border-b-2 ${
                click === "enabled" ? "border-blue-500" : null
              } focus:outline-none`}
            >
              {title === "Hawkeye" ? "Approved" : "Enabled"}
            </button>
          ) : null}
          {!showInitialized ? (
            <button
              onClick={options}
              name="disabled"
              className={`mx-4 border-b-2 ${
                click === "disabled" ? "border-blue-500" : null
              } focus:outline-none`}
            >
              {title === "Hawkeye" ? "Abandoned" : "Disabled"}
            </button>
          ) : null}
          {showInitialized ? (
            <button
              onClick={options}
              name="initialized"
              className={`mx-4 border-b-2 ${
                click === "initialized" ? "border-blue-500" : null
              } focus:outline-none`}
            >
              Initialized
            </button>
          ) : null}

          {title === "Hawkeye" ? (
            <button
              onClick={options}
              name="initialized"
              className={`mx-4 border-b-2 ${
                click === "initialized" ? "border-blue-500" : null
              } focus:outline-none`}
            >
              Rejected
            </button>
          ) : null}

          {showInitialized ? (
            <button
              onClick={options}
              name="notInitialized"
              className={`mx-4 border-b-2 ${
                click === "notInitialized" ? "border-blue-500" : null
              } focus:outline-none`}
            >
              Not Initialized
            </button>
          ) : null}
        </div>
      </div>

      {click === "enabled" ? (
        <Enabled />
      ) : click === "disabled" ? (
        <Disabled />
      ) : click === "all" ? (
        <All
          data={data}
          isLoading={isLoading}
          error={error}
          viewUrl={viewUrl}
          editUrl={editUrl}
         // orgList={orgList}
          retailerList={retailerList}
          sortedData={findField(data)}
        />
      ): null}
    </div>
  );
}

export default TableStr;
