import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const ConfirmationModal = ({ isOpen, onClose, onReject }) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to reject?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onReject} color="secondary">
          Reject
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const YourComponent = () => {
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const handleReject = () => {
    // Perform rejection logic here
    // ...

    // Close the confirmation modal
    setConfirmationModalOpen(false);
  };

  return (
    <div>
      <Button onClick={() => setConfirmationModalOpen(true)}>
        Open Confirmation Modal
      </Button>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
        onReject={handleReject}
      />
    </div>
  );
};

export default ConfirmationModal;
