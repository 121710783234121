import React, { useState, useEffect } from "react";

import { BiArrowBack } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { useGet } from "../../../../../../api/useGet";

function TableStr({
  title,
  viewUrl,
  editUrl,
  listUrl,
  getName,
  Intialized,
  All,
  url,
}) {
  const [click, setClicked] = useState("all");
  const history = useHistory();

  const options = (e) => {
    const type = e.target.name;
    switch (type) {
      case "all":
        setClicked(type);
        break;
      case "Intialized":
        setClicked(type);
        break;

      default:
    }
  };

  return (
    <div className="w-full h-full shadow-lg select-none">
      <div
        className={`w-full h-16 flex justify-between p-6 items-center bg-gray-100`}
      >
        <div className="w-full h-12 flex bg-gray-100  items-center">
          <BiArrowBack
            onClick={() => history.goBack()}
            className="h-6 w-6 cursor-pointer"
          />
          <h2 className="font-bold text-2xl ml-3">{title}</h2>
        </div>
        <div>
          <button
            onClick={() => history.push(url)}
            className={`w-40 bg-green-500 rounded-sm h-9 justify-center flex items-center
               focus:outline-none text-white`}
          >
            <span className="h-full flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 64 64"
                width="28px"
                height="28px"
              >
                <linearGradient
                  id="KJ7ka9GQp0CHqT_2YsWMsa"
                  x1="32"
                  x2="32"
                  y1="5.75"
                  y2="59.005"
                  gradientUnits="userSpaceOnUse"
                  spreadMethod="reflect"
                >
                  <stop offset="0" stopColor="#1a6dff" />
                  <stop offset="1" stopColor="#c822ff" />
                </linearGradient>
                <path
                  fill="url(#KJ7ka9GQp0CHqT_2YsWMsa)"
                  d="M32,58C17.663,58,6,46.337,6,32S17.663,6,32,6s26,11.663,26,26S46.337,58,32,58z M32,8 C18.767,8,8,18.767,8,32s10.767,24,24,24s24-10.767,24-24S45.233,8,32,8z"
                />
                <linearGradient
                  id="KJ7ka9GQp0CHqT_2YsWMsb"
                  x1="32"
                  x2="32"
                  y1="5.75"
                  y2="59.005"
                  gradientUnits="userSpaceOnUse"
                  spreadMethod="reflect"
                >
                  <stop offset="0" stopColor="#1a6dff" />
                  <stop offset="1" stopColor="#c822ff" />
                </linearGradient>
                <path
                  fill="url(#KJ7ka9GQp0CHqT_2YsWMsb)"
                  d="M32,52c-11.028,0-20-8.972-20-20s8.972-20,20-20s20,8.972,20,20S43.028,52,32,52z M32,14 c-9.925,0-18,8.075-18,18s8.075,18,18,18s18-8.075,18-18S41.925,14,32,14z"
                />
                <linearGradient
                  id="KJ7ka9GQp0CHqT_2YsWMsc"
                  x1="32"
                  x2="32"
                  y1="21.75"
                  y2="42.538"
                  gradientUnits="userSpaceOnUse"
                  spreadMethod="reflect"
                >
                  <stop offset="0" stopColor="#6dc7ff" />
                  <stop offset="1" stopColor="#e6abff" />
                </linearGradient>
                <path
                  fill="url(#KJ7ka9GQp0CHqT_2YsWMsc)"
                  d="M41,30h-7v-7c0-0.552-0.448-1-1-1h-2c-0.552,0-1,0.448-1,1v7h-7c-0.552,0-1,0.448-1,1v2 c0,0.552,0.448,1,1,1h7v7c0,0.552,0.448,1,1,1h2c0.552,0,1-0.448,1-1v-7h7c0.552,0,1-0.448,1-1v-2C42,30.448,41.552,30,41,30z"
                />
              </svg>
              <span className="px-2">{`Create ${title}`}</span>
            </span>
          </button>
        </div>
      </div>

      <div className="w-full h-10 p-2  -mt-2 ">
        <div className="h-10 w-full  flex justify-start ">
          <button
            onClick={options}
            name="all"
            className={`mx-4 border-b-2 ${
              click === "all" ? "border-blue-500" : null
            } focus:outline-none`}
          >
            All
          </button>

          <button
            onClick={options}
            name="Intialized"
            className={`mx-4 border-b-2 ${
              click === "Intialized" ? "border-blue-500" : null
            } focus:outline-none`}
          >
            Intialized
          </button>
        </div>
      </div>

      {click === "all" ? (
        <All
          listUrl={listUrl}
          //isLoading={isLoading}
          // error={error}
          viewUrl={viewUrl}
          editUrl={editUrl}
          //sortedData={findField(dataFilter(data, "APPROVED"))}
        />
      ) : click === "Intialized" ? (
        <Intialized
          listUrl={listUrl}
          // isLoading={isLoading}
          // error={error}
          viewUrl={viewUrl}
          editUrl={editUrl}
          // sortedData={findField(dataFilter(data, "IN_REVIEW"))}
        />
      ) : null}
    </div>
  );
}

export default TableStr;
