import React from "react";
import { Backdrop, CircularProgress, makeStyles } from "@mui/material";

const CircularProgressLoader = ({ open }) => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: 999 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default CircularProgressLoader;
