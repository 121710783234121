import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  CardHeader,
  Button,
  CardActions,
  Divider,
  Box,
  Stack,
} from "@mui/material";
import { DatePicker } from "antd";

import CustomTabPanel from "./decisionTabs";
import { useGet } from "../../../../../../api/useGet";
import Iconify from "../../../../../../components/iconify/Iconify";
import SearchableDropdown from "../../../../../../components/dropdown";
import CustomSearch from "../../../../../../components/searchbar/index";
import {
  emptyAllValues,
  areAllValuesEmpty,
} from "../../../../../../utils/utils";

const styles = {
  cardHeader: {
    fontWeight: 700, // Set your desired font weight
  },
};

function DecisionEvent() {
  const { RangePicker } = DatePicker;

  const [associatedEntryData, setAssociatedEntryData] = useState({
    "retailStoreCode.equals": "",
    "cartRef.contains": "",
    "entryTime.greaterThanOrEqual": "",
    "entryTime.lessThanOrEqual": "",
  });

  const [openEntryData, setOpenEntryData] = useState({
    "retailStoreCode.equals": "",
    "cartRef.contains": "",
    "entryTime.greaterThanOrEqual": "",
    "entryTime.lessThanOrEqual": "",
  });

  const [selectedDateRange, setSelectedDateRange] = useState(["", ""]);

  const [ratailerStoreOptions, setRatailerStoreOptions] = useState([]);

  const [value, setValue] = React.useState(0);

  const {
    isLoading,
    error,
    data: retailerStoreData,
  } = useGet(
    `${process.env.REACT_APP_BASE_URL}/retail-stores?sort=createdDate%2Cdesc`,
    "retailerStore"
  );

  useEffect(() => {
    const tempData = retailerStoreData?.map((item) => {
      const { internalCode, name } = item || {};
      return {
        value: internalCode,
        label: name,
      };
    });
    setRatailerStoreOptions(tempData);
  }, [retailerStoreData]);

  useEffect(() => {
    if (value === 0) {
      setSelectedDateRange([
        associatedEntryData?.["entryTime.greaterThanOrEqual"],
        associatedEntryData?.["entryTime.lessThanOrEqual"],
      ]);
    } else {
      setSelectedDateRange([
        openEntryData?.["entryTime.greaterThanOrEqual"],
        openEntryData?.["entryTime.lessThanOrEqual"],
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [associatedEntryData, openEntryData, value]);

  const handleDateChange = (dates) => {
    if (dates.length === 2) {
      value === 0
        ? setAssociatedEntryData({
            ...associatedEntryData,
            "entryTime.greaterThanOrEqual": dates?.[0],
            "entryTime.lessThanOrEqual": dates?.[1],
          })
        : setOpenEntryData({
            ...openEntryData,
            "entryTime.greaterThanOrEqual": dates?.[0],
            "entryTime.lessThanOrEqual": dates?.[1],
          });
    }
  };

  const handleRetailarStoreOnChange = (e, v) => {
    value === 0
      ? setAssociatedEntryData({
          ...associatedEntryData,
          "retailStoreCode.equals": v?.value,
        })
      : setOpenEntryData({
          ...openEntryData,
          "retailStoreCode.equals": v?.value,
        });
  };

  const handleClearButton = () => {
    value === 0
      ? setAssociatedEntryData({ ...emptyAllValues(associatedEntryData) })
      : setOpenEntryData({ ...emptyAllValues(openEntryData) });
  };

  return (
    <>
      <Container maxWidth="2xl">
        <Card>
          <CardHeader
            title="ENTRY MANAGEMENT"
            sx={{ alignItems: "center", fontWeight: 700 }}
            titleTypographyProps={{
              style: styles.cardHeader, // Apply the styles here
            }}
            action={
              <CardActions sx={{ m: 0, textTransform: "none" }}>
                {/* <Button
                  size="small"
                  variant="contained"
                  sx={{ textTransform: "none" }}
                  onClick={() => {}}
                >
                  Create Manual Entry
                </Button> */}
              </CardActions>
            }
          />

          <Divider />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 2,
            }}
          >
            <Stack display="flex" flexDirection="row" gap={2}>
              <CustomSearch
                // placeholder="Search by Cart Reference"
                label="Search by Cart Reference"
                variant="outlined"
                size="small"
                value={
                  value === 0
                    ? associatedEntryData?.["cartRef.contains"]
                    : openEntryData?.["cartRef.contains"]
                }
                onChange={(e) =>
                  value === 0
                    ? setAssociatedEntryData({
                        ...associatedEntryData,
                        "cartRef.contains": e.target.value,
                      })
                    : setOpenEntryData({
                        ...openEntryData,
                        "cartRef.contains": e.target.value,
                      })
                }
              />
              <SearchableDropdown
                placeholder="Filter by Retailer Store"
                disableSearch
                label="Filter by Retailer Store"
                value={
                  value === 0
                    ? ratailerStoreOptions?.find(
                        (item) =>
                          item.value ===
                          associatedEntryData?.["retailStoreCode.equals"]
                      )?.label || ""
                    : ratailerStoreOptions?.find(
                        (item) =>
                          item.value ===
                          openEntryData?.["retailStoreCode.equals"]
                      )?.label || ""
                }
                options={ratailerStoreOptions || []}
                onChange={(e, value) => handleRetailarStoreOnChange(e, value)}
                sx={{ width: 300 }}
              />
              <RangePicker
                onChange={handleDateChange}
                value={selectedDateRange}
                clearIcon={null}
              />
              {(value === 0
                ? !areAllValuesEmpty(associatedEntryData)
                : !areAllValuesEmpty(openEntryData)) && (
                <Button
                  variant="soft"
                  sx={{ color: "#FF5630" }}
                  onClick={handleClearButton}
                  startIcon={<Iconify icon="eva:trash-2-outline" />}
                >
                  Clear
                </Button>
              )}
            </Stack>
          </Box>

          <Divider />

          <CustomTabPanel
            value={value}
            setValue={setValue}
            associatedEntryData={associatedEntryData}
            setAssociatedEntryData={setAssociatedEntryData}
            openEntryData={openEntryData}
            setOpenEntryData={setOpenEntryData}
          />
        </Card>
      </Container>
    </>
  );
}

export default DecisionEvent;
