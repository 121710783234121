import React, { useState } from "react";
import moment from "moment";

function SelectEvent({ data, index, setSelect, select }) {
  const selectEvent = (data) => {
    setSelect(data);
  };

  return (
    <div
      onClick={() => selectEvent(data)}
      // className="w-24 h-24 bg-red-500"
      key={index}
      className={`mr-8`}
    >
      <div
        className={`${
          select?.id === data?.id && "border-6 border-green-600 p-0.5"
        }`}
      >
        <img
          src={
            "https://res.cloudinary.com/akash-autonomo/image/upload/v1634847966/autonomo/entryperson_23_19_48_v1ywp6.jpg"
          }
          alt="event"
          className="rounded"
          style={{ width: "155px", height: "117px" }}
        />
      </div>

      <h2 className="my-1.5">{moment(data?.entryTime).format("h:mm A")}</h2>
    </div>
  );
}

export default SelectEvent;
