import React, { useState, useEffect } from "react";
import ExitImages from "./ExitImages";
import NoImage from "../../../../../../Images/noImage.jpg";
import VideoController from "../../../../../../custom-control/VideoController";
import NoVideo from "../../../../../../Images/novideo.jpg";

const videoSliderStyle = {
  maxWidth: "200px", // Set the desired width
  maxHeight: "200px", // Set the desired height
  padding: "10px",
  paddingTop: "0",
  // marginLeft: "130px",
  // marginRight: "50px",
  // display: "flex",
  // justifyContent: "center",
  // alignItems: "center",
};

function SelectEvent({
  data,
  index,
  onSelect,
  select,
  name,
  idName,
  cartRef,
  isConfidenceScore = false,
}) {
  console.log("🚀 ~ file: SelectEvent.js:20 ~ SelectEvent ~ data:", data, name);
  const [exitImages, setExitImages] = useState([]);

  useEffect(() => {
    const people = [];

    if (data?.[name]?.length > 0) {
      for (let i = 0; i < 3; i++) {
        people.push(data?.[name]?.[i] ? data?.[name]?.[i] : "");
      }
    }
    setExitImages(people);
  }, [data]);

  const confidenceScore = data?.suggestedUserActivities?.find(
    (item) => item?.cartRef == cartRef
  )?.confidenceScore;
  console.log("confidenceScore", confidenceScore, cartRef, isConfidenceScore);

  return (
    <>
      {Array.isArray(data) ? (
        <div className="flex justify-between w-[90%]">
          <div key={index} className={`flex h-44 w-full space-x-4 `}>
            <>
              {data?.length > 0 ? (
                <>
                  {exitImages?.length > 0 ? (
                    exitImages?.map((url, index) => (
                      <ExitImages url={url} index={index} through="openEntry" />
                    ))
                  ) : (
                    <img src={NoImage} alt="" className="w-44 h-44" />
                  )}
                </>
              ) : (
                <div className="w-full flex items-center justify-center font-bold">
                  No User Exit Activity Available
                </div>
              )}
            </>

            {data?.length > 0 && (
              <div className="w-28 h-36 flex flex-col justify-center items-center">
                <button
                  onClick={() => onSelect(data)}
                  className={`${
                    select?.[idName] === data?.[idName]
                      ? "bg-gray-500"
                      : "bg-green-400"
                  } w-24 rounded-full h-8 focus:outline-none text-white`}
                >
                  {select?.[idName] === data?.[idName] ? "selected" : "select"}
                </button>

                <button
                  className="focus:outline-none "
                  onClick={() => onSelect("")}
                >
                  Unselect
                </button>
              </div>
            )}
          </div>
          <div>
            <div style={videoSliderStyle}>
              {data?.exitVideoPaths?.length > 0 ? (
                <VideoController url={data?.exitVideoPaths?.[0]?.videoUrl} />
              ) : (
                <img src={NoVideo} className="w-44 h-44" alt="" />
              )}
              {/* <VideoController url={data?.exitVideoPaths?.[0]?.videoUrl} /> */}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-between w-[90%]">
          <div key={index} className={`flex h-40 space-x-4`}>
            <>
              {data ? (
                <>
                  {exitImages?.length > 0 ? (
                    exitImages?.map((url, index) => (
                      <ExitImages
                        key={index}
                        url={url}
                        index={index}
                        through="openEntry"
                      />
                    ))
                  ) : (
                    <img src={NoImage} alt="" className="w-44 h-44 border-2" />
                  )}
                </>
              ) : (
                <div className="w-full flex items-center justify-center font-bold">
                  No User Exit Activity Available
                </div>
              )}
            </>
            <div className="flex justify-between items-center flex-col h-44 p-1">
              {isConfidenceScore && (
                <div className="border-2 border-black px-3 py-5 mb-2">
                  <p>Confidence Score</p>
                  <p className="text-2xl font-bold">
                    {confidenceScore ? `${confidenceScore}%` : " 0%"}
                  </p>
                </div>
              )}
              {data && (
                <div className="lex flex-col justify-center items-center space-y-2">
                  <button
                    onClick={() => onSelect(data)}
                    className={`${
                      select?.[idName] === data?.[idName]
                        ? "bg-gray-500"
                        : "bg-green-400"
                    } w-24 rounded-full h-8 focus:outline-none text-white`}
                  >
                    {select?.[idName] === data?.[idName]
                      ? "selected"
                      : "select"}
                  </button>
                  {select?.[idName] === data?.[idName] && (
                    <button
                      className="focus:outline-none ml-2"
                      onClick={() => onSelect("")}
                    >
                      Unselect
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
          <div>
            <div style={videoSliderStyle}>
              {data?.exitVideoPaths?.length > 0 ? (
                <VideoController url={data?.exitVideoPaths?.[0]?.videoUrl} />
              ) : (
                <img src={NoVideo} className="w-44 h-44" alt="" />
              )}
              {/* <VideoController url={data?.exitVideoPaths?.[0]?.videoUrl} /> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SelectEvent;
