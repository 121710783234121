import React, { useState, useEffect } from "react";
import { useGet } from "../../api/useGet";

function TableStr({
  title,
  viewUrl,
  editUrl,
  listUrl,
  getName,
  Enabled,
  Refunded,
  refundUrl,
  All,
  Disabled,
}) {
  const initialRoute = (value) => {
    const type = value;

    switch (type) {
      case "all":
        return "all";

      case "refunds":
        return "refunds";

      default:
        return "all";
    }
  };

  const [click, setClicked] = useState(
    initialRoute(localStorage.getItem("navigate"))
  );

  const options = (name) => {
    const type = name;

    switch (type) {
      case "all":
        setClicked(type);
        localStorage.setItem("navigate", type);
        break;

      case "refunds":
        setClicked(type);
        localStorage.setItem("navigate", type);
        break;

      default:
    }
  };

  return (
    <div className="w-full h-full shadow-lg select-none">
      <div
        className={`w-full h-12 flex justify-between p-6 items-center bg-gray-100 text-center`}
      >
        <div>
          <h2 className="font-bold text-2xl">{title}</h2>
        </div>
      </div>

      <div className="w-full flex h-10 p-2  -mt-2 ">
        <div className="h-10 w-full  flex justify-start ">
          <button
            onClick={(e) => options(e.target.name)}
            name="all"
            className={`mx-4 border-b-2 ${
              click === "all" ? "border-blue-500" : null
            } focus:outline-none`}
          >
            All
          </button>

          <button
            onClick={(e) => options(e.target.name)}
            name="refunds"
            className={`mx-4 border-b-2 ${
              click === "refunds" ? "border-blue-500" : null
            } focus:outline-none`}
          >
            Refunded
          </button>
        </div>
      </div>

      {click === "refunds" ? (
        <Refunded listUrl={refundUrl} />
      ) : click === "disabled" ? (
        <Disabled />
      ) : click === "all" ? (
        <All
          // data={data}
          viewUrl={viewUrl}
          editUrl={editUrl}
          listUrl={listUrl}
        />
      ) : null}
    </div>
  );
}

export default TableStr;
