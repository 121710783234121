import React from "react";
// Components
import Sidebar from "../components/Sidebar/Sidebar";
import MainView from "../components/MainView/MainView";
import * as s from "../App.styles";

import * as Palette from "../colors";

function index() {
  const backgroundImage = "images/mountain.jpg";
  const sidebarHeader = {
    fullName: "Autonomo",
    shortName: "Au",
  };
  const menuItems = [
    { name: "Welcome", to: "/", icon: "/icons/home.svg", subMenuItems: [] },
    {
      name: "Administration",
      to: "/",
      icon: "/icons/destinations.svg",
      subMenuItems: [
        { name: "Merchant", to: "/admin/merchant" },
        { name: "Retailer Store", to: "/admin/retailer-store" },
        { name: "Machine", to: "/admin/machine" },
        { name: "Rack", to: "/admin/rack" },
        { name: "Hawkeye", to: "/admin/Hawkeye" },
        // { name: 'Match', to:'/admin/match'},
        { name: "Shopper Activity", to: "/admin/shopper-activity" },
      ],
    },

    {
      name: "Employees",
      to: "/employee",
      icon: "/icons/blog.svg",
      subMenuItems: [],
    },

    {
      name: "Orders",
      to: "/order",
      icon: "/icons/blog.svg",
      subMenuItems: [],
    },
    {
      name: "Change Password",
      to: "/password",
      icon: "/icons/home.svg",
      subMenuItems: [],
    },
  ];

  const fonts = {
    header: "ZCOOL KuaiLe",
    menu: "Poppins",
  };
  return (
    <s.App>
      <Sidebar
        backgroundImage={backgroundImage}
        sidebarHeader={sidebarHeader}
        menuItems={menuItems}
        fonts={fonts}
        colorPalette={Palette.gray}
      />
      <MainView />
    </s.App>
  );
}

export default index;
