import React, { useState, useEffect } from "react";
import TableStr from "./TableStr";
import Refunded from "./options/Refunded";
import Pending from "./options/Pending";
import All from "./options/All";
import axios from "axios";

function Order() {
  return (
    <div>
      {/* <div className="h-full w-full "> */}
      <TableStr
        title={"Orders"}
        listUrl={`${process.env.REACT_APP_SHOPPER_URL}/orders/summary?sort=createdDate,desc`}
        getName={"orderList"}
        All={All}
        Refunded={Refunded}
        Pending={Pending}
        refundUrl={`${process.env.REACT_APP_SHOPPER_URL}/order-refunds?projection=OrderListDTO&sort=createdDate,desc`}
      />
    </div>
  );
}

export default Order;
