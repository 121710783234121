import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { GrView } from "react-icons/gr";
import { MdAddToPhotos } from "react-icons/md";
import Confirm from "./Confirm";
import ModalImg from "../../../../../../../ModalImg";
import PriceSummary from "./PriceSummary";
import UpdatePriceSummary from "./UpdatePriceSummary";
import PushEsl from "./PushEsl";
import AddLocal from "./AddLocal";
import UploadImage from "../../../../components/UploadImage";
import { useGetByParams } from "../../../../../../../../api/useGetByParams";
import { usePost } from "../../../../../../../../api/usePost";
import { useGet } from "../../../../../../../../api/useGet";
import UpdateLocal from "./UpdateLocal";
import { Languages2 } from "../../../../../../../../Languages";
import Barcode from "react-barcode";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import Iconify from "../../../../../../../../components/iconify/Iconify";

const detectedLan = (value) => {
  return Languages2?.find((item) => item?.code === value);
};

function ProductView() {
  const [status, setStatus] = useState(false);

  const [isNameHovered, setIsNameHovered] = useState(false);

  const [isHSNCodeHovered, setIsHSNCodeHovered] = useState(false);

  const [isBarcodeHovered, setIsBarcodeHovered] = useState(false);

  const statusChecked = (e) => {
    setStatus(e.target.checked);
  };
  const {
    state: { data: lData },
  } = useLocation();
  const history = useHistory();

  const [data, setData] = useState();

  const { data: productViewData } = useGet(
    `${process.env.REACT_APP_BASE_URL}/products/${lData?.id}`,
    `productDetail`
  );

  const { mutate: PushEsl } = usePost(
    `${process.env.REACT_APP_BASE_URL}/products/${lData?.id}/esl-push`,
    ""
  );

  useEffect(() => {
    setData(productViewData);
  }, [productViewData]);

  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [priceSummary, setPriceSummary] = useState(false);
  const onOpenPrice = () => setPriceSummary(true);
  const onClosePrice = () => setPriceSummary(false);

  const [updatePrice, setUpdatePrice] = useState(false);
  const onOpenUpdate = () => setUpdatePrice(true);
  const onCloseUpdate = () => setUpdatePrice(false);

  // const [pushEsl, setPushEsl] = useState(false);
  // const onPushEsl = () => setPushEsl(true);
  // const onCloseEsl = () => setPushEsl(false);

  const [addLocal, setAddLocal] = useState(false);
  const onOpenLocal = () => setAddLocal(true);
  const onCloseLocal = () => setAddLocal(false);

  const [editLocal, setEditLocal] = useState(false);
  const onOpenEdit = () => setEditLocal(true);
  const onCloseEdit = () => setEditLocal(false);

  const [image, setImage] = useState(false);
  const onOpenImage = () => setImage(true);
  const onCloseImage = () => setImage(false);

  const edit = () => {
    history.push({
      pathname: "/admin/merchant/dashboard/productManagement/product/edit",
      state: { data: data },
    });
  };

  const esl = () => {
    const editData = {
      // "storeId": "89609366-c5a9-4eab-99a4-0a1362cd0c16",

      rootOrgId: parseInt(
        JSON.parse(localStorage.getItem("dashboard-data"))?.id
      ),
    };
    PushEsl(editData);
    toast.success("Successfully submitted");
  };

  const handleHover = (data) => {
    navigator.clipboard.writeText(data).then(() => {
      console.log("Text copied to clipboard", data);
      toast.success("copied", {
        autoClose: 1000,
        position: toast.POSITION.TOP_CENTER,
        // width: ,
      });
    });
  };

  return (
    <>
      {data ? (
        <div className="w-full h-screen text-left select-none flex">
          <div className="w-full h-full flex flex-col justify-center items-center bg-gray-200">
            <div className="w-full h-12 flex bg-gray-100  items-center">
              <BiArrowBack
                onClick={() => history.goBack()}
                className="h-6 w-6 cursor-pointer ml-5"
              />
              {/* <h2 className="font-bold px-6 text-xl">{data?.name}</h2> */}
            </div>

            <div className="w-full h_dasView flex p-2">
              <div className="w-7/10 h-full bg-white rounded-lg p-2.5">
                <div className="w-full border-b-2">
                  <h2 className="text-black text-bold text-lg select-none">
                    Information
                  </h2>
                </div>

                <div className="w-full flex justify-between">
                  <div className="w-48 flex h-20 mt-1 items-center select-none ">
                    <button
                      onClick={edit}
                      className="focus:outline-none bg-gray-200 border-2 border-black ml-1 hover:bg-grey text-grey-darkest font-bold py-1 px-4 rounded inline-flex items-center"
                    >
                      <FaEdit className="w-4 h-4" />
                      <span className="mx-2">Edit</span>
                    </button>

                    <Confirm />
                  </div>
                  <div className="flex h-20 mt-1 items-center select-none">
                    <button
                      onClick={onOpenPrice}
                      className="focus:outline-none bg-gray-200 border-2 border-black ml-1 hover:bg-grey text-grey-darkest font-bold py-1 px-4 rounded inline-flex items-center"
                    >
                      <GrView className="w-4 h-4" />
                      <span className="mx-2">Price Summary</span>
                    </button>

                    <button
                      onClick={onOpenUpdate}
                      className="focus:outline-none bg-gray-200 border-2 border-black ml-1 hover:bg-grey text-grey-darkest font-bold py-1 px-4 rounded inline-flex items-center"
                    >
                      <FaEdit className="w-4 h-4" />
                      <span className="mx-2">Update Price</span>
                    </button>

                    {/* <button
                      onClick={() => esl()}
                      className="focus:outline-none bg-gray-200 border-2 border-black ml-1 hover:bg-grey text-grey-darkest font-bold py-1 px-4 rounded inline-flex items-center"
                    >
                      <span className="mx-2">Push To ESL</span>
                    </button> */}

                    {/* <button
                      onClick={onOpenLocal}
                      className="focus:outline-none bg-gray-200 border-2 border-black ml-1 hover:bg-grey text-grey-darkest font-bold py-1 px-4 rounded inline-flex items-center"
                    >
                      
                      <span className="mx-2">Add Locale Specific Details</span>
                    </button> */}
                  </div>
                </div>

                <AddLocal
                  open={addLocal}
                  onCloseModal={onCloseLocal}
                  productId={data?.id}
                />
                <PriceSummary
                  open={priceSummary}
                  onCloseModal={onClosePrice}
                  priceData={data}
                />
                <UpdatePriceSummary
                  open={updatePrice}
                  onCloseModal={onCloseUpdate}
                  priceData={data}
                />

                {/* <PushEsl
                  open={pushEsl}
                  onCloseModal={onCloseEsl}
                  productViewData={productViewData}
                /> */}

                <div className="h-3/4 mt-3  scrollbar-thin scrollbar-thumb-gray-900  scrollbar-track-gray-100">
                  <div className="border-t-2 border-gray-200 py-4 px-4">
                    <div className="grid grid-cols-4 gap-4">
                      <div className="font-bold">Name</div>
                      <div
                        style={{ display: "flex" }}
                        onMouseEnter={() => setIsNameHovered(true)}
                        onMouseLeave={() => setIsNameHovered(false)}
                      >
                        <div className="">{data?.name}</div>
                        {isNameHovered && (
                          <Iconify
                            onClick={() => handleHover(data?.name)}
                            icon="ph:copy"
                          />
                        )}
                      </div>
                      <div className="font-bold">Title</div>
                      <div className="">{data?.title}</div>
                    </div>
                  </div>

                  <div className="border-t-2 border-gray-200 py-5 px-4">
                    <div className="grid grid-cols-2 gap-2">
                      <div className="font-bold">Description</div>
                      <div className="">{data?.description}</div>
                    </div>
                  </div>

                  {/* {data?.productCategories.length !== 0 ? (
                  <h2 className="font-bold mt-2 ml-4 text-base text-dash">
                    Product Categories:
                  </h2>
                ) : null} */}

                  {data?.productCategories?.map((type, index) => {
                    return (
                      <div className="border-t-2 border-gray-200 py-4 px-4">
                        <div className="grid grid-cols-4 gap-4" key={index}>
                          <div className="font-bold">Category</div>
                          <div className="">{type?.category}</div>
                          <div className="font-bold">Supplier</div>
                          <div classname="">
                            <div className="w-[200px]  ">
                              <button
                                onClick={() =>
                                  history.push({
                                    pathname:
                                      "/admin/merchant/dashboard/productManagement/supplier/view",
                                    state: { data: { id: data?.supplier?.id } },
                                  })
                                }
                                className="text-blue-500 cursor-pointer focus:outline-none underline"
                              >
                                {data?.supplier?.name}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  <div className="border-t-2 border-gray-200 py-4 px-4">
                    <div className="grid grid-cols-4 gap-4">
                      <div className="font-bold">Unit Weight</div>
                      <div className="">{data?.unitWeight}</div>
                      <div className="font-bold">Unit Of Measure</div>
                      <div className="">{data?.unitOfMeasure}</div>
                    </div>
                  </div>

                  <h2 className="font-bold mt-2 ml-4 text-base text-dash">
                    Net values:
                  </h2>

                  <div className="border-t-2 border-gray-200 py-4 px-4">
                    <div className="grid grid-cols-4 gap-4">
                      <div className="font-bold">Net Weight </div>
                      <div className="">{data?.netWeight}</div>
                      <div className="font-bold">Net Weight UOM</div>
                      <div className="">{data?.netWeightUOM}</div>
                    </div>
                  </div>

                  <h2 className="font-bold mt-2 ml-4 text-base text-dash">
                    Reference values:
                  </h2>

                  <div className="border-t-2 border-gray-200 py-4 px-4">
                    <div className="grid grid-cols-4 gap-4">
                      <div className="font-bold">Reference Weight</div>
                      <div className="">{data?.referenceWeight}</div>
                      <div className="font-bold">Reference Weight UOM</div>
                      <div className="">{data?.referenceUOM}</div>
                      <div className="font-bold">Reference Price</div>
                      <div className="">{data?.referencePrice?.toFixed(2)}</div>
                      <div className="font-bold">Reference status</div>
                      <div className="">
                        {data?.isRefWeightForDisplayEnabled === true
                          ? "enabled"
                          : "disabled"}
                      </div>
                    </div>
                  </div>

                  {/* {data ? (
                  <div className="border-t-2 border-gray-200 py-6 px-4">
                    <div className="grid grid-cols-4 gap-4">
                      <div className="font-bold">Currency Code</div>
                      <div className="">{data?.isoCurrencyCode}</div>
                      <div className="font-bold">Currency Symbol</div>
                      <div className="">{data?.currencySymbol}</div>
                    </div>
                  </div>
                ) : null} */}

                  <div className="border-t-2 border-gray-200 py-6 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="font-bold">Barcode</div>
                      <div>
                        <div
                          style={{ display: "flex" }}
                          onMouseEnter={() => setIsBarcodeHovered(true)}
                          onMouseLeave={() => setIsBarcodeHovered(false)}
                        >
                          <Barcode value={data?.barCode} displayValue={true} />
                          {isBarcodeHovered && (
                            <Iconify
                              onClick={() => handleHover(data?.barCode)}
                              icon="ph:copy"
                            />
                          )}
                        </div>
                      </div>
                      <div className="font-bold">HSN Code</div>
                      <div
                        style={{ display: "flex" }}
                        onMouseEnter={() => setIsHSNCodeHovered(true)}
                        onMouseLeave={() => setIsHSNCodeHovered(false)}
                      >
                        <div className="" style={{ marginRight: "8px" }}>
                          {data?.hsnCode}
                        </div>
                        {isHSNCodeHovered && (
                          <Iconify
                            onClick={() => handleHover(data?.hsnCode)}
                            icon="ph:copy"
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="border-t-2 border-gray-200 py-6 px-4">
                    <div className="grid grid-cols-4 gap-4">
                      <div className="font-bold">SKU Type</div>
                      <div className="">{data?.skuType}</div>
                      <div className="font-bold">Pricing Type</div>
                      <div className="">{data?.pricingType}</div>
                    </div>
                  </div>

                  <div className="border-t-2 border-gray-200 py-6 px-4">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="font-bold">Purchase Price</div>
                      <div className="">{data?.purchasePrice?.toFixed(2)}</div>
                      <div className="font-bold">Net Selling Price</div>
                      <div className="">{data?.sellPrice?.toFixed(2)}</div>
                      <div className="font-bold">Gross Selling Price</div>
                      <div className="">
                        {data?.sellPriceWithTax?.toFixed(2)}
                      </div>
                    </div>
                  </div>

                  {/* {data?.taxCategoryType ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="font-bold">Tax Category Type</div>
                      <div className="">{data?.taxCategoryType}</div>
                      <div className="font-bold">Tax Category Value</div>
                      <div className="">{`${data?.taxCategoryValue} %`}</div>
                    </div>
                  </div>
                ) : null} */}

                  {/* {data?.vegMark ? (
                  <div className="border-t-2 border-gray-200 py-1 px-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="font-bold">VegMark</div>
                      <div className="">{data?.vegMark}</div>
                      <div className="font-bold">Status</div>
                      <div className="">{data?.status}</div>
                    </div>
                  </div>
                ) : null} */}

                  {/* {data?.productCategories.length !== 0 ? (
                    <h2 className="font-bold mt-2 ml-4 text-base text-dash">
                      Product Categories:
                    </h2>
                  ) : null}

                  {data?.productCategories?.map((type, index) => {
                    return (
                      <div
                        className="border-t-2 border-gray-200 py-1 px-4"
                        key={index}
                      >
                        <div className="grid grid-cols-2 gap-4">
                          <div className="font-bold">Category</div>
                          <div className="">{type?.category}</div>
                        </div>
                      </div>
                    );
                  })}

                  {data?.supplier ? (
                    <h2 className="font-bold mt-2 ml-4 text-base text-dash">
                      Product Supplier:
                    </h2>
                  ) : null}

                  {data?.supplier?.name ? (
                    <div className="border-t-2 border-gray-200 py-1 px-4">
                      <div className="grid grid-cols-2 gap-4">
                        <div className="font-bold">Name</div>
                        <div className="">{data?.supplier?.name}</div>
                      </div>
                    </div>
                  ) : null} */}

                  {data ? (
                    <div className="border-t-2 border-gray-200 py-1 px-4">
                      <div className="grid grid-cols-6 gap-4">
                        {/* <div className="font-bold">Deposit Required</div>
                      <div className="">{data?.isDepositRequired}</div> */}
                        <div className="font-bold">Deposit Amount</div>
                        <div className="">{data?.depositAmount}</div>
                        <div className="font-bold">Currency Code</div>
                        <div className="">{data?.isoCurrencyCode}</div>
                        <div className="font-bold">Tax Category</div>
                        <div className="">{data?.taxCategoryType}</div>
                      </div>
                    </div>
                  ) : null}

                  <div className="border-t-2 border-gray-200 py-6 px-4">
                    <div className="grid grid-cols-6 gap-4">
                      <div className="font-bold">Country Origin</div>
                      <div className="">{data?.countryOfOrigin}</div>
                      <div className="font-bold">Trading Class</div>
                      <div className="">{data?.tradingClassification}</div>
                      <div className="font-bold">VegMark</div>
                      <div className="">
                        {data?.vegMark === "true" ? "Yes" : "No"}
                      </div>
                    </div>
                  </div>

                  {data ? (
                    <div className="border-t-2 border-gray-200 py-1 px-4">
                      <div className="grid grid-cols-2 gap-4">
                        <div className="font-bold">Image:</div>
                        <div className="w-40 h-40 rounded-lg ">
                          <img
                            onClick={onOpenModal}
                            src={data?.imageUrl}
                            className="w-40 h-40 object-fill cursor-pointer"
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <ModalImg
                    open={open}
                    onCloseModal={onCloseModal}
                    img={data?.imageUrl}
                  />
                </div>
                <div className="flex w-full justify-end pr-4 mt-0.5">
                  <button
                    onClick={onOpenImage}
                    className="bg-gray-500 w-52 h-8 text-white rounded flex  items-center focus:outline-none"
                  >
                    <span className="mx-2">
                      <MdAddToPhotos />
                    </span>
                    Upload Product Image
                  </button>
                </div>
              </div>

              <UploadImage
                open={image}
                onCloseImage={onCloseImage}
                productId={data?.id}
              />

              <div className="w-3/10 h-full ml-2 bg-white rounded-lg p-2">
                <div className="w-full pr-4">
                  <div className="w-full border-b-2">
                    <h2 className="text-black text-bold text-lg">
                      Locale Specific Details
                    </h2>
                  </div>
                </div>

                <div className="h_specific scrollbar-thin scrollbar-thumb-gray-900 scrollbar-track-gray-100 mt-2">
                  {data?.productsI18n?.map((localSpecific, index) => {
                    return (
                      <div className="h-38 pr-4" key={index}>
                        <div className="h-32">
                          <table className="table-auto rounded border-collapse border  w-full h-full">
                            <tbody className="text-left">
                              <tr>
                                <th className="w-32 border border-gray-300 text-sm bg-gray-200 px-2">
                                  Name :
                                </th>
                                <th className="border border-gray-300 px-2 text-sm text-gray-500">
                                  {localSpecific?.name}
                                </th>
                              </tr>
                              <tr>
                                <th className="w-32 border border-gray-300 text-sm bg-gray-200 px-2">
                                  Description :
                                </th>
                                <th className="border border-gray-300 px-2 text-sm text-gray-500">
                                  {localSpecific?.description}
                                </th>
                              </tr>
                              <tr>
                                <th className="w-32 border border-gray-300 text-sm bg-gray-200 px-2">
                                  Title :
                                </th>
                                <th className="border border-gray-300 px-2 text-sm text-gray-500">
                                  {localSpecific?.title}
                                </th>
                              </tr>
                              <tr>
                                <th className="w-32 border border-gray-300 text-sm bg-gray-200 px-2">
                                  Language :
                                </th>
                                <th className="border border-gray-300 px-2 text-sm text-gray-500 flex justify-between">
                                  {detectedLan(localSpecific?.lang)?.label}
                                </th>
                              </tr>
                              <tr>
                                <th className="w-32 border border-gray-300 bg-gray-200 px-2">
                                  uom :
                                </th>
                                <th className="border border-gray-300 px-2 text-sm text-gray-500">
                                  {localSpecific?.unitOfMeasure}
                                </th>
                              </tr>
                            </tbody>
                          </table>
                          <div className="flex justify-end">
                            <FaEdit
                              className="w-5 h-5 cursor-pointer mt-1"
                              onClick={onOpenEdit}
                            />
                          </div>
                        </div>

                        <UpdateLocal
                          data={localSpecific}
                          open={editLocal}
                          onCloseModal={onCloseEdit}
                          productId={data?.id}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-full flex justify-center items-center">
          <ClipLoader size={150} />
        </div>
      )}
    </>
  );
}

export default ProductView;
