import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { useAlert } from "react-alert";
import NumberFormat from "react-number-format";
import RefundItem from "./RefundItem";
import RefundSummaryHeader from "./RefundSummaryHeader";
import moment from "moment";
import { BsCloudDownload } from "react-icons/bs";
import { Languages1 } from "../../../Languages";
import Cookies from "universal-cookie";
import { usePost } from "../../../api/usePost";
import { usePut } from "../../../api/usePut";
import { useGet } from "../../../api/useGet";
import {toast} from 'react-toastify'

const refundOptions = [
  { value: "FULL", label: "Full Refund" },
  { value: "PARTIAL", label: "Partial Refund" },
];

const customStyles = {
  control: (base) => ({
    ...base,
    height: 35,
    minHeight: 35,
    width: 160,
  }),
};

function Refund({ header }) {
  const history = useHistory();
  const alert = useAlert();
  const cookies = new Cookies();
  const {
    state: { data, locationData, requestedData },
  } = useLocation();

  const [input, setInput] = useState({
    comments: "",
  });

  const onChangeInput = (value, name) => {
    setInput({ ...input, [name]: value });
  };

  const { data: refundData } = useGet(
    `${process.env.REACT_APP_SHOPPER_URL}/orders/${data?.id}`,
    "refund"
  );

  const refund = usePost(
    `${process.env.REACT_APP_SHOPPER_URL}/order-refunds`,
    "refund"
  );

  const pendingApi = usePost(
    `${process.env.REACT_APP_SHOPPER_URL}/orders/${refundData?.id}/submit-order-payment`,
    "refund"
  );
  const confirmApi = usePut(
    `${process.env.REACT_APP_SHOPPER_URL}/orders/${refundData?.id}/verify-order-payment`,
    "refund"
  );

  const [orderItems, setOrderItems] = useState();

  useEffect(() => {
    let temp = [];
    requestedData?.refundOrderItems?.map(({orderItemId, refundQuantity}) => {
      if (refundQuantity !== 0) {
        temp.push({ orderItemId, refundQuantity});
      }
    });
    setOrderItems(temp);
  }, [requestedData]);

  const [select, setSelect] = useState({
    refundType: "",
  });

  const [partial, setPartial] = useState({
    items: [],
  });

  const detectedLan = (code) => {
    return Languages1?.filter((item) => {
      return item?.code === code;
    });
  };

  const fetchDownload = (url, name) => {
    const res = fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      }),
    })
      .then((res) => res.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${name}.pdf`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  const download = async () => {
    fetchDownload(
      `${process.env.REACT_APP_SHOPPER_URL}/orders/${
        refundData?.id
      }/download?langKey=${detectedLan(cookies.get("i18next"))[0]?.value}`,
      `${refundData?.orderNumber}`
    );
  };

  const removeData = (array, value) => {
    const arr = array?.filter((sel) => {
      return sel !== value;
    });
    setPartial({
      ...partial,
      items: [...arr],
    });
  };

  const partialSelect = (data) => {
    const array = partial?.items;

    if (array.includes(data?.id) === false) {
      if (data?.id !== undefined) {
        setPartial({
          ...partial,
          items: [...array, data?.id],
        });
      }
    } else if (array.includes(data?.id) === true) {
      if (data?.id !== undefined) {
        removeData(array, data?.id);
      }
    }
  };

  const dataIsExit = (data, id) => {
    return data?.some((ele) => ele?.orderItemId === id);
  };

  const onChangeOrderItems = (value, id, name) => {
    let updatedData = [...orderItems];

    if (
      value !== "0" &&
      name === "refundQuantity" &&
      dataIsExit(updatedData, id)
    ) {
      updatedData.map((item) => {
        if (item?.orderItemId === id) {
          item.refundQuantity = value?.toString();
        }
      });

      setOrderItems(updatedData);
    } else if (
      value === "0" &&
      name === "refundQuantity" &&
      dataIsExit(updatedData, id)
    ) {
      setOrderItems(updatedData?.filter((item) => item?.orderItemId !== id));
    } else if (!dataIsExit(updatedData, id)) {
      updatedData?.push({ orderItemId: id, refundQuantity: value.toString() });
      setOrderItems(updatedData);
    }
  };

  const refundInit = () => {
    const payload = {
      refundAmount: refundData?.orderTotal,
      refundType: "FULL",
      comments: input?.comments,
      order: { id: refundData?.id },
      refundOrderItems: orderItems,
    };

   // console.log("payload: ", payload);
    if (input?.comments !== "") {
      refund.mutate(payload);
      const timer = setTimeout(() => {
        localStorage.setItem("navigate", "refunds");
        history.goBack();
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      toast.error("Comments Required");
    }
  };

  const pending = () => {
    pendingApi.mutate("");
  };
  const confirmPending = () => {
    confirmApi.mutate("");
  };

  return (
    <>
      {refundData ? (
        <div
          className={`w-full ${
            header ? "h_screen" : "h-screen pt-4"
          }  text-left flex select-none bg-gray-200`}
        >
          <div className="w-full h-full px-4 py-2">
            <div className="w-full  h-full  ">
              <div className="w-full h-8  flex bg-white rounded-lg">
                <div className="w-10 p-2 h-full flex items-center">
                  <BiArrowBack
                    onClick={() => history.goBack()}
                    className="h-10 w-10 cursor-pointer"
                  />
                </div>
                <div className="w-full h-full px-2 flex flex-col justify-center">
                  <h2 className="font-bold text-xl">
                    Order No : {refundData?.orderNumber}
                  </h2>
                </div>
              </div>

              <div className="w-full h-36 rounded-lg px-2 bg-white my-2 py-2 ">
                <h2 className="px-2 font-bold">Order Summary :</h2>

                <div className="w-full flex justify-between px-2 pt-2  ">
                  <div className="w-1/3 border-l-2 border-bg-500 text-gray-500 px-2">
                    <h2>
                      {`Login: `}
                      <NumberFormat
                        displayType={"text"}
                        value={refundData?.login}
                        format="###-###-####"
                      />
                    </h2>
                  </div>
                  <div className="w-1/3 border-l-2 border-bg-500 text-gray-500 px-2">
                    <h2>
                      {`Status: `} {refundData?.status}
                    </h2>
                  </div>
                  <div className="w-1/3 border-l-2 border-bg-500 text-gray-500 px-2">
                    <h2>
                      {`Retailer store:`}{" "}
                      {refundData?.storeName
                        ? refundData.storeName
                        : locationData?.storeName}
                    </h2>
                  </div>
                </div>
                {/* secondLine */}

                <div className="w-full flex justify-between px-2 pt-2  ">
                  <div className="w-1/3 border-l-2 border-bg-500 text-gray-500 px-2">
                    <h2>
                      {`Email:`}{" "}
                      {refundData?.email
                        ? refundData?.email
                        : locationData.email}
                    </h2>
                  </div>
                  <div className="w-1/3 border-l-2 border-bg-500 text-gray-500 px-2">
                    <h2>
                      {`Name: `}{" "}
                      {refundData?.firstName
                        ? refundData?.firstName
                        : locationData.email}{" "}
                      {refundData?.lastName
                        ? refundData?.lastName
                        : locationData.lastName}
                    </h2>
                  </div>
                  <div className="w-1/3 flex border-l-2 border-bg-500 text-gray-500 px-2">
                    <div className="mr-20">
                      <h2 className="">
                        {`Total :`} {refundData?.currencySymbol}{" "}
                        {parseFloat(refundData?.orderTotal).toFixed(2)}
                      </h2>
                    </div>
                    <div className="flex ">
                      <h2 className="mr-3">Receipt</h2>
                      <BsCloudDownload
                        className="w-5 h-5 mt-1 cursor-pointer hover:text-blue-500 "
                        onClick={download}
                      />
                    </div>
                  </div>
                </div>

                {/* thirdLine */}
                <div className="w-full flex justify-between px-2 pt-2  ">
                  <div className=" border-l-2 border-bg-500 text-gray-500 px-2">
                    <h2>
                      {`Order Time :`}{" "}
                      {moment(refundData?.orderTime).format(
                        "MMMM Do YYYY, h:mm A"
                      )}
                    </h2>
                  </div>

                  {/* <div className="mr-30 absolute right-6">
                <Select
                  placeholder="Refund"
                  styles={customStyles}
                  options={refundOptions}
                  value={select.refundType}
                  onChange={(value) => onSelect("refundType", value)}
                />
              </div> */}
                </div>
              </div>

              <div className="w-full h-7.1/10 rounded-lg bg-white flex flex-col ">
                <div>
                  <RefundSummaryHeader status={refundData?.status} />
                  <div className="h-88 mt-2  px-1.5 scrollbar-thin  scrollbar-thumb-gray-500 scrollbar-thumb-rounded-lg scrollbar-track-gray-100">
                    {refundData?.orderItems?.map((item, index) => {
                      return (
                        <RefundItem
                          data={refundData}
                          item={item}
                          index={index}
                          onChangeOrderItems={onChangeOrderItems}
                          orderItems={orderItems}
                          setOrderItems={setOrderItems}
                          partialSelect={partialSelect}
                          partial={partial}
                          maxAllowedRefunded={requestedData}
                          refundType={select?.refundType?.value}
                        />
                      );
                    })}
                    <h2
                      className={`w-full text-2xl text-gray-500  ${
                        refundData?.status === "PAYMENT_PROCESSED" ||
                        refundData?.status === "PAYMENT_PARTIALLY_REFUNDED"
                          ? "pr-16"
                          : "pr-24"
                      } flex justify-end items-center`}
                    >
                      <span className="mr-4">Total Order</span>
                      {refundData?.currencySymbol}{" "}
                      {parseFloat(refundData?.orderTotal).toFixed(2)}
                    </h2>
                  </div>
                </div>

                <div
                  className={`w-full flex  ${
                    refundData?.status === "PAYMENT_PROCESSED" ||
                    refundData?.status === "PAYMENT_PARTIALLY_REFUNDED"
                      ? "justify-between"
                      : "justify-end"
                  } pl-6 items-center`}
                >
                  {(refundData?.status === "PAYMENT_PROCESSED" ||
                    refundData?.status === "PAYMENT_PARTIALLY_REFUNDED") && (
                    <div className="w-1/2 flex justify-center  min-h-[30px]">
                      <textarea
                        placeHolder="Comment..."
                        onChange={(e) => {
                          onChangeInput(e.target.value, "comments");
                        }}
                        value={input?.comments}
                        className="focus:outline-none w-full border border-gray-500 rounded-lg px-2 py-1"
                      />
                    </div>
                  )}

                  {refundData?.status === "PAYMENT_PROCESSED" ||
                  refundData?.status === "PAYMENT_PARTIALLY_REFUNDED" ? (
                    <button
                      className="bg-gray-300 px-4 h-8 rounded-lg  mr-20 focus:outline-none"
                      onClick={refundInit}
                    >
                      Submit for Refund
                    </button>
                  ) : refundData?.status === "PAYMENT_CONFIRMATION_PENDING" ? (
                    <button
                      className="bg-gray-300 px-4 h-8 rounded-lg mb-1.5 mr-20 focus:outline-none"
                      onClick={confirmPending}
                    >
                      Verify Payment
                    </button>
                  ) : refundData?.status === "PAYMENT_PENDING" ? (
                    <button
                      className="bg-gray-300 px-4 h-8 rounded-lg mb-1.5 mr-20 focus:outline-none"
                      onClick={pending}
                    >
                      submit for payment
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h_screen flex justify-center items-center space-x-2">
          <BiArrowBack
            onClick={() => history.goBack()}
            className="h-7 w-7 cursor-pointer"
          />

          <p className="font-bold">Internal Server Error</p>
        </div>
      )}
    </>
  );
}

export default Refund;
