import React from "react";
import TableStr from "./TableStr";
import Enabled from "./options/Enabled";
import Disabled from "./options/Disabled";
import All from "./options/All";

function Support() {
  return (
    <div className="h-full w-full select-none">
      <TableStr
        title={"Merchant"}
        // url={"/merchant/newMerchant"}
        // viewUrl={"/merchant/view"}
        // editUrl={"/merchant/edit"}
        listUrl={`${process.env.REACT_APP_BASE_URL}/orgs?page=0&sort=createdDate,desc`}
        getName={"merchantList"}
        Enabled={Enabled}
        Disabled={Disabled}
        All={All}
        showButton={true}
        retailerIdUrl={`${process.env.REACT_APP_BASE_URL}/retail-stores?page=0&sort=createdDate`}
        
      />
    </div>
  );
}

export default Support;
