import React, { useState, useEffect, useRef } from "react";
import { IoMdRefresh } from "react-icons/io";

const initialRoute = (value) => {
  const type = value;

  switch (type) {
    case "openEntryEvents":
      return "openEntryEvents";

    case "matchedExit":
      return "matchedExit";

    case "openExitEvents":
      return "openExitEvents";

    case "processed":
      return "processed";

    default:
      return "openExitEvents";
  }
};

function TableStr({
  title,
  viewUrl,
  editUrl,
  listUrl,
  getName,
  OpenEntryEvents,
  Processed,
  OpenExitEvents,
  MatchedExit,
}) {

  const [refresh, setRefresh] = useState(false)

  const [click, setClicked] = useState(
    initialRoute(localStorage.getItem("tab"))
  );

  const options = (e) => {
    const type = e.target.name;
    switch (type) {
      case "openEntryEvents":
        setClicked(type);
        localStorage.setItem("tab", type);
        break;

      case "matchedExit":
        setClicked(type);
        localStorage.setItem("tab", type);
        break;

      case "openExitEvents":
        setClicked(type);
        localStorage.setItem("tab", type);
        break;

      case "processed":
        setClicked(type);
        localStorage.setItem("tab", type);
        break;

      default:
    }
  };



  return (
    <div className="w-full h-full shadow-lg select-none">
      <div
        className={` h-12 flex justify-between p-6 items-center bg-gray-100`}
      >
        <h2 className="font-bold text-2xl">{title}</h2>
        <IoMdRefresh
          onClick={()=>setRefresh(!refresh)}
          size={28}
          className="cursor-pointer"
        />
      </div>

      <div className="w-full flex h-10 p-2  -mt-2 ">
        <div className="h-10 w-full  flex justify-start ">
          <button
            onClick={options}
            name="openExitEvents"
            className={`mx-4 border-b-2 ${click === "openExitEvents" ? "border-blue-500" : null
              } focus:outline-none`}
          >
            Open Exit Events
          </button>

          <button
            onClick={options}
            name="openEntryEvents"
            className={`mx-4 border-b-2 ${click === "openEntryEvents" ? "border-blue-500" : null
              } focus:outline-none`}
          >
            Open Entry Events
          </button>

          <button
            onClick={options}
            name="matchedExit"
            className={`mx-4 border-b-2 ${click === "matchedExit" ? "border-blue-500" : null
              } focus:outline-none`}
          >
            Matched Exit
          </button>

          <button
            onClick={options}
            name="processed"
            className={`mx-4 border-b-2 ${click === "processed" ? "border-blue-500" : null
              } focus:outline-none`}
          >
            Processed
          </button>
        </div>
      </div>

      {click === "openEntryEvents" ? (
        <OpenEntryEvents
          listUrl={listUrl}
          viewUrl={viewUrl}
          editUrl={editUrl}
          refresh={refresh}
          setRefresh={setRefresh}

        />
      ) : click === "matchedExit" ? (
        <MatchedExit
          listUrl={listUrl}
          viewUrl={viewUrl}
          editUrl={editUrl}
          refresh={refresh}
          setRefresh={setRefresh}

        />
      ) : click === "openExitEvents" ? (
        <OpenExitEvents
          listUrl={listUrl}
          viewUrl={viewUrl}
          editUrl={editUrl}

        />
      ) : click === "processed" ? (
        <Processed
          listUrl={`${process.env.REACT_APP_SHOPPER_URL}/stores/carts?sort=createdDate,desc`}
          viewUrl={viewUrl}
          editUrl={editUrl}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      ) : null}
    </div>
  );
}

export default TableStr;
