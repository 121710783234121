import React from "react";
import { GrView } from "react-icons/gr";
import { useHistory } from "react-router-dom";


function Action({ params, url, viewUrl, editUrl, through }) {
  const history = useHistory();
 // const exitApi = usePut(`${process.env.REACT_APP_STORE_URL}/user-exit-activities/` , "exitApi")


  const view = (params) => {
    history.push({
      pathname: viewUrl,
      state:{data: {...params.data, through:through}}
      
    })
    
    localStorage.setItem("navigate","processedDecisionEvents")
    
  }

  return (
    <div className="flex w-16 h-10 items-center">
      <div className="border-2 border-gray-200 rounded-lg p-1 ">
        <GrView
          className="w-5 h-5 cursor-pointer "
          onClick={() => view(params)}
        />
      </div>
      
    </div>
  );
}

export default Action;
