import React from 'react'

function Disabled() {
    return (
        <div>
              <h2>Disabled</h2>
        </div>
    )
}

export default Disabled
