import React from "react";
import { useField } from "formik";
import { TextField } from "@material-ui/core";

export const TextField3 = ({ label, errors, touched, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="w-full flex flex-col justify-center">
      <div className="w-full flex justify-between space-x-4 items-center">
        <label className={`w-[30%] ${props?.fontType}`} for={label}>{label}</label>
        <input {...field} {...props} autoComplete="off" className="focus:outline-none w-[70%] bg-[#D9D9D9] py-2 px-6"/>
      </div>

      {meta.touched && meta.error ? (
        <p className="w-full text-right text-red-500">{meta.error}</p>
      ) : null}
    </div>
  );
};
