import React from 'react'
import TableStr from "./TableStr";
import Intialized from "./options/Intialized";
import All from "./options/All";
import {useLocation} from 'react-router-dom'

function Rack() {

  const location = useLocation() 
 
  const url = location?.state?.reatilerId ? `${
    process.env.REACT_APP_BASE_URL
  }/racks?retailStoreId.equals=${location?.state?.reatilerId}&sort=createdDate,desc&rootOrgId.equals=${parseInt(
    JSON.parse(localStorage.getItem("dashboard-data"))?.id
  )}` : `${
    process.env.REACT_APP_BASE_URL
  }/racks?sort=createdDate,desc&rootOrgId.equals=${parseInt(
    JSON.parse(localStorage.getItem("dashboard-data"))?.id
  )}`

  

  return (
    <div className="h-full w-full ">
      <TableStr
        title={"Rack"}
        viewUrl={"/admin/merchant/dashboard/rack/view"}
        editUrl={"/admin/merchant/dashboard/rack/edit"}
        listUrl={url}
        getName={"rackList"}
        All={All}
        Intialized={Intialized}
        url={`/admin/merchant/dashboard/rack/newRack`}
      />
    </div>
  );
}

export default Rack;
