import React from "react";

function RefundSummaryHeader({ path, status }) {
  return (
    <div className="w-full rounded-lg bg-white flex items-center h-10 px-4">
      <div className="w-32">
        <h2 className="font-bold">Product Image</h2>
      </div>

      <div className="w-full h-full items-center flex justify-between ">
        <div className="w-1/4 pl-8">
          <h2 className="font-bold">Product Name</h2>
        </div>

        {(status === "PAYMENT_PROCESSED" ||
          status === "PAYMENT_PARTIALLY_REFUNDED") && (
          <>
            <div className="w-1/4 flex justify-center">
              <h2 className="font-bold">Refund Qty</h2>
            </div>

            <div className="w-1/4 flex justify-center">
              <h2 className="font-bold">Allowed refund Qty</h2>
            </div>

           
          </>
        )}

        <div className="w-1/4 flex justify-center">
          <h2 className="font-bold ml-5">Ordered Qty</h2>
        </div>

        {/* {(status === "PAYMENT_PROCESSED" ||
          status === "PAYMENT_PARTIALLY_REFUNDED")&&( <div className="w-1/4 flex ">
              <h2 className="font-bold ml-2">Comment</h2>
            </div>)} */}

        <div className="w-1/4 flex justify-center">
          <h2 className="font-bold">Unit Cost</h2>
        </div>
        <div className="w-1/4 flex justify-center">
          <h2 className="font-bold">Net Amount</h2>
        </div>
      </div>
    </div>
  );
}

export default RefundSummaryHeader;
