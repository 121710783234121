import React, { useEffect, useState } from "react";
import Toggle from "react-toggle";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify'

function Confirm({ data }) {
  console.log("params : ", data)

  const history = useHistory();

  const [status, setStatus] = useState(true);

  useEffect(() => {
    const storeStatus = data?.status;
    console.log(storeStatus)

    if (storeStatus === 'STORE_CLOSED') {
      setStatus(false);
    } else {
      setStatus(true);
    }
  }, [data]);

  console.log("status: ", status)

  const statusChecked = (e) => {
    const value = e.target.checked;

    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            var body = {
              id: data?.id,
              name: data?.name,
              status: value ? "STORE_OPEN" : "STORE_CLOSED",
            };

            try {
              const res = await axios({
                method: "PUT",
                url: `${process.env.REACT_APP_BASE_URL}/retail-stores/${data?.id}/status`,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("user-info")}`,
                },
                data: body,
              });
  
              if (res.data.status) {
                const storeStatus = res.data?.status;
                setStatus(storeStatus === 'STORE_CLOSED' ? false : true);
                // window.location.reload();
                // history.push("/admin/machine")
              }
            } catch ({message}) {
              if (message === "Request failed with status code 401") {
                history.push("/login");
              }else{
                toast.error(message)
              }
            }

            
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  //console.log("use: ", status);

  return (
    <div className="font-bold py-2 px-4 rounded inline-flex items-center">
      <span className="mx-2">Store Status</span>
      <Toggle
        onChange={statusChecked}
        checked={status}
        //icons={{ checked: null, unchecked: null }}
        className="react-switch"
      />
    </div>
  );
}

export default Confirm;
