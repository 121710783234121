import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import StoreOpenCardThumbnail from "./StoreOpenCardThumbnail";
import NoVideo from "../../../../Images/novideo.jpg";

const exit1 = [
  {
    thumbnail:
      "https://192.168.1.13/hawkeye/shopperLoop/62ad9125cc3dd851018c39be_shopperLoopTracking_shopperthumnail.jpg",
    videoUrl:
      "https://192.168.1.13/hawkeye/person_tracking/2022-06-15/62a984bccc3dd8510186354b/D3.mp4",
    camera: "D3",
  },
  {
    thumbnail:
      "https://192.168.1.13/hawkeye/shopperLoop/62ad9125cc3dd851018c39be_shopperLoopTracking_shopperthumnail.jpg",
    videoUrl:
      "https://192.168.1.13/hawkeye/person_tracking/2022-06-15/62a984bccc3dd8510186354b/D4.mp4",
    camera: "D4",
  },
  {
    thumbnail:
      "https://192.168.1.13/hawkeye/shopperLoop/62ad9125cc3dd851018c39be_shopperLoopTracking_shopperthumnail.jpg",
    videoUrl:
      "https://192.168.1.13/hawkeye/person_tracking/2022-06-15/62a984bccc3dd8510186354b/D5.mp4",
    camera: "D5",
  },
  {
    thumbnail:
      "https://192.168.1.13/hawkeye/shopperLoop/62ad9125cc3dd851018c39be_shopperLoopTracking_shopperthumnail.jpg",
    videoUrl:
      "https://192.168.1.13/hawkeye/person_tracking/2022-06-15/62a984bccc3dd8510186354b/D6.mp4",
    camera: "D6",
  },
  {
    thumbnail:
      "https://192.168.1.13/hawkeye/shopperLoop/62ad9125cc3dd851018c39be_shopperLoopTracking_shopperthumnail.jpg",
    videoUrl:
      "https://192.168.1.13/hawkeye/person_tracking/2022-06-15/62a984bccc3dd8510186354b/D7.mp4",
    camera: "D7",
  },
  {
    thumbnail:
      "https://192.168.1.13/hawkeye/shopperLoop/62ad9125cc3dd851018c39be_shopperLoopTracking_shopperthumnail.jpg",
    videoUrl:
      "https://192.168.1.13/hawkeye/person_tracking/2022-06-15/62a984bccc3dd8510186354b/D8.mp4",
    camera: "D8",
  },
  {
    thumbnail:
      "https://192.168.1.13/hawkeye/shopperLoop/62ad9125cc3dd851018c39be_shopperLoopTracking_shopperthumnail.jpg",
    videoUrl:
      "https://192.168.1.13/hawkeye/person_tracking/2022-06-15/62a984bccc3dd8510186354b/D9.mp4",
    camera: "D9",
  },
  {
    thumbnail:
      "https://192.168.1.13/hawkeye/shopperLoop/62ad9125cc3dd851018c39be_shopperLoopTracking_shopperthumnail.jpg",
    videoUrl:
      "https://192.168.1.13/hawkeye/person_tracking/2022-06-15/62a984bccc3dd8510186354b/D13.mp4",
    camera: "D13",
  },
  {
    thumbnail:
      "https://192.168.1.13/hawkeye/shopperLoop/62ad9125cc3dd851018c39be_shopperLoopTracking_shopperthumnail.jpg",
    videoUrl:
      "https://192.168.1.13/hawkeye/person_tracking/2022-06-15/62a984bccc3dd8510186354b/D16.mp4",
    camera: "D16",
  },
  {
    thumbnail:
      "https://192.168.1.13/hawkeye/shopperLoop/62ad9125cc3dd851018c39be_shopperLoopTracking_shopperthumnail.jpg",
    videoUrl:
      "https://192.168.1.13/hawkeye/person_tracking/2022-06-15/62a984bccc3dd8510186354b/D20.mp4",
    camera: "D20",
  },
  {
    thumbnail:
      "https://192.168.1.13/hawkeye/shopperLoop/62ad9125cc3dd851018c39be_shopperLoopTracking_shopperthumnail.jpg",
    videoUrl:
      "https://192.168.1.13/hawkeye/person_tracking/2022-06-15/62a984bccc3dd8510186354b/D29.mp4",
    camera: "D29",
  },
  {
    thumbnail:
      "https://192.168.1.13/hawkeye/shopperLoop/62ad9125cc3dd851018c39be_shopperLoopTracking_shopperthumnail.jpg",
    videoUrl:
      "https://192.168.1.13/hawkeye/person_tracking/2022-06-15/62a984bccc3dd8510186354b/D31.mp4",
    camera: "D31",
  },
];

const AllSessionVideos = () => {
  const location = useLocation();
  const history = useHistory();

  console.log("data: ", location.state);

  return (
    <div className="w-full h-full">
      <div className="h-8 flex items-center">
        <div className="absolute mx-4 flex items-center space-x-2">
          <BiArrowBack
            onClick={() => history.goBack()}
            className="h-6 w-6 cursor-pointer"
          />
        </div>

        <div className="w-full flex justify-center items-center space-x-2">
          <h2 className="font-bold text-xl">Session Videos</h2>
        </div>
      </div>

      <div className="mt-12 grid grid-cols-4 gap-y-8 w-[80%] mx-auto">
        {exit1?.length > 0 ? (
          exit1?.map((ele, index) => (
            <div className="w-full flex flex-col items-center justify center">
              <StoreOpenCardThumbnail
                imageUrl={ele?.thumbnail}
                videoUrl={ele?.videoUrl}
                index={index}
                camera={ele?.camera}
              />
              <p>{ele.camera}</p>
            </div>
          ))
        ) : (
          <>
            <img src={NoVideo} className="w-64 h-48" alt="exitimage" />
          </>
        )}
      </div>
    </div>
  );
};

export default AllSessionVideos;
