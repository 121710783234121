import * as React from "react";
import { useLocation, useHistory } from "react-router-dom";

import CommonHeader from "../header/NotificatonHeader";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { flattenData } from "../../../../../../../utils/utils";

const templateKeys = {
  emailBodyDe: "Email Body DE",
  emailBodyEn: "Email Body EN",
  emailCcDe: "Email CC DE",
  emailCcEn: "Email CC EN",
  emailSubjectDe: "Email Subject DE",
  emailSubjectEn: "Email Subject EN",
  emailToDe: "Email TO DE",
  emailToEn: "Email TO EN",
  placeHolders: "Placeholders",
  smsBodyDe: "SMS Body DE",
  smsBodyEn: "SMS Body EN",
  smsToDe: "SMS TO DE",
  smsToEn: "SMS TO EN",
  template: "Template Name",
  rootOrgId: "Root organization ID",
  paplaceHolders: "Placeholders",
};

const CustomVerticalTable = ({ data }) => {
  const flattenedData = flattenData(data);

  const {
    emailBodyDe,
    emailBodyEn,
    emailCcDe,
    emailCcEn,
    emailSubjectDe,
    emailSubjectEn,
    emailToDe,
    emailToEn,
    placeHolders,
    smsBodyDe,
    smsBodyEn,
    smsToDe,
    smsToEn,
  } = data?.params || {};

  return (
    <TableContainer component={Paper} sx={{ hight: "100%", overflowY: "auto" }}>
      <Table aria-label="simple table">
        <TableBody>
          {Object.entries(flattenedData).map(([key, value]) => (
            <TableRow key={key}>
              <TableCell
                component="th"
                scope="row"
                style={{
                  fontWeight: "bold",
                  borderRight: "1px solid #e0e0e0",
                }}
              >
                {templateKeys[key] !== undefined ? templateKeys[key] : key}
              </TableCell>
              <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                {typeof value === "string" && value.includes("<html>")
                  ? "HTML Content"
                  : value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ViewTemplate = () => {
  const history = useHistory();
  const { state } = useLocation();
  const { template } = state?.data || {};

  const handleCreateButtonEvent = () => {
    history.push({
      pathname: "/admin/merchant/dashboard/notifications/update-template",
      state: { data: state?.data },
    });
  };

  return (
    <div>
      <CommonHeader
        isCreateButton
        headerTitle={template}
        createButtonTitle="Update Template"
        handleCreateButtonEvent={handleCreateButtonEvent}
      />
      <CustomVerticalTable data={state?.data} />
    </div>
  );
};

export default ViewTemplate;
