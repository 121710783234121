import React, { useState, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "react-toggle/style.css";
import Select from "react-select";
import DashboardRoute from "./DashboardRoute";
import NumberFormat from "react-number-format";
import moment from "moment";

function Approved({
  isLoading,
  error,
  data,
  url,
  viewUrl,
  editUrl,
  sortedData,
}) {
  useEffect(() => {
    localStorage.setItem("navigate", "approved");
  }, []);

  const [checked, setChecked] = useState(false);
  const [value, setValue] = useState("");

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };

  const changeHandler = (value) => {
    setValue(value);
  };


  return (
    <div className=" w-full h_table">
      <div className="flex h-10 w-full items-center mt-2 justify-between py-8 px-4">

        <div style={{ width: "40%" }}>
          <Select
            className="text-left"
            placeholder="Search Mearchant"
            maxMenuHeight={162}
            value={value}
            onChange={changeHandler}
            options={sortedData?.map((item, index) => {
              return {
                // label: item.retailerName,
                // value: item.retailerName,
                label: item,
                value: item,
                key: index,
              };
            })}
          />
        </div>
        <div style={{ width: "40%" }}>
          <Select
            className="text-left"
            placeholder="Search RetailerStore"
            maxMenuHeight={162}
            value={value}
            onChange={changeHandler}
            options={sortedData?.map((item, index) => {
              return {
                // label: item.retailerName,
                // value: item.retailerName,
                label: item,
                value: item,
                key: index,
              };
            })}
          />
        </div>
        <button className="bg-green-600 py-1.5 px-5 mr-7 rounded text-white">
          Clear filters
        </button>
      </div>

      <div className="ag-theme-alpine  w-full h-full text-left">
        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            resizable: true,
          }}
          suppressRowClickSelection={true}
          rowSelection={"multiple"}
          pagination={true}
          paginationPageSize={10}
          rowData={data}
        >
          <AgGridColumn
            headerName="Event Ref"
            field="sessionRef"
            minWidth={170}
            sortable={true}
            headerCheckboxSelection={true}
            headerCheckboxSelectionFilteredOnly={true}
            checkboxSelection={true}
            cellRendererFramework={(params) => (
              <DashboardRoute params={params} />
            )}
          />

          <AgGridColumn
            headerName="Retailer Name"
            field="retailerName"
            minWidth={110}
            sortable={true}
          />

          <AgGridColumn
            headerName="Login"
            field="login"
            minWidth={80}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <>
                  <NumberFormat
                    displayType={"text"}
                    value={params?.data?.login}
                    format="###-###-####"
                  />
                </>
              );
            }}
          />

          <AgGridColumn
            headerName="Event Time Stamp"
            field="checkoutDate"
            minWidth={250}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <>
                  <p>
                    {moment(params?.data?.orderTime).format("MMMM Do YYYY h:mm A")}
                  </p>
                </>
              );
            }}
          />

          <AgGridColumn
            headerName="Event Status"
            field="cartStatus"
            minWidth={120}
            sortable={true}
          />

          <AgGridColumn
            headerName = "Assignee"
            field="assignee"
            minWidth={150}
            minHeight={150}
            cellRendererFramework={(params) => (
              <select
                value="red"
                className="w-20 h-full m-1 focus:outline-none"
              >
                <option value="red"> option 1 </option>
                <option value="black"> option 2 </option>
                <option value="green"> option 3</option>
                <option value="yellow"> option 4</option>
                <option value="violet"> option 5 </option>
              </select>
            )}
          />
        </AgGridReact>
      </div>
    </div>
  );
}

export default Approved;
