import styled from '@emotion/styled';

export const MainViewContainer = styled.div`
  width: 100%;
 
  text-align: center;
  background-color: "#ffffff"

  h1 {
    font-size: 2rem
  }
`