import React, { useState, useEffect, useRef } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "react-toggle/style.css";
import DashboardRoute from "./DashboardRoute";
import Action from "./Action";
import { useQuery } from "react-query";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import { useHistory } from "react-router-dom";
import {toast} from "react-toastify";

function All({ listUrl, viewUrl, editUrl }) {
  const [activePage, setActivePage] = useState(1);
  const [allCount, setAllCount] = useState();
  const ref = useRef();
  const history = useHistory();

  const [search, setSearch] = useState({
    catby: "",
    nameby: "",
    barcodeby: "",
    filterby: "barc",
  });

  const findUrl = (name) => {
    switch (name) {
      case "catby":
        return `${listUrl}&category.contains=${search?.catby}*`;

      case "nameby":
        return `${listUrl}&name.contains=${search?.nameby}*`;

      case "barcodeby":
        return `${listUrl}&barCode.contains=${search?.barcodeby}*`;

      default:
        return `${listUrl}`;
    }
  };

  const fetchData = async (page) => {

    try {
      const res = await axios({
        method: "GET",
        url: `${findUrl(search?.filterby)}&page=${page - 1}&size=10`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      });
      setAllCount(res?.headers?.["x-total-count"]);
      return res.data;
    } catch ({message}) {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message)
      }
    }
  
  };

  const { data, isLoading, isIdle, error } = useQuery(
    ["offerList", activePage, search?.barcodeby, search?.nameby, search?.catby],
    async () => fetchData(activePage),
    {
      enabled:
        !!activePage ||
        !!search?.barcodeby ||
        !search?.nameby ||
        !!search?.catby,
      cacheTime: Infinity,
      staleTime: 0,
    }
  );

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };
  return (
    <div className=" w-full h-[calc(100vh-168px)] select-text">
      <div className="flex h-10 mt-2 w-full items-center justify-between py-8 px-4">
        <input
          className="w-2/5 focus:outline-none py-1.5 px-2 border-2 border-gray-200 rounded-lg"
          placeholder="Search"
        />

        <input
          className="w-2/5 focus:outline-none py-1.5 px-2 border-2 border-gray-200 rounded-lg"
          placeholder="Search"
        />
        <button className="bg-green-600 py-1.5 px-5 mr-7 rounded text-white">
          Clear filters
        </button>
      </div>

      <div className="ag-theme-alpine w-full h-full text-left mt-4">
        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            resizable: true,
          }}
          suppressRowClickSelection={true}
          pagination={false}
          rowData={data}
          onRowDoubleClicked={(params) =>
            history.push({
              pathname: viewUrl,
              state: { data: params.data },
            })
          }
        >
          <AgGridColumn
            headerName="Category Name"
            field="name"
            minWidth={280}
            sortable={true}
            headerCheckboxSelection={true}
            headerCheckboxSelectionFilteredOnly={true}
            checkboxSelection={true}
            cellRendererFramework={(params) => (
              <DashboardRoute params={params} />
            )}
          />
          <AgGridColumn
            headerName="Category"
            field="category"
            minWidth={200}
            sortable={true}
          />
          <AgGridColumn
            headerName="Product Name"
            field="productName"
            minWidth={200}
            sortable={true}
          />

          <AgGridColumn
            headerName="Discount (%)"
            field="discountPercent"
            minWidth={100}
          />

          <AgGridColumn
            headerName="Start Date"
            field="startDate"
            minWidth={100}
          />

          <AgGridColumn headerName="End Date" field="endDate" minWidth={100} />

          <AgGridColumn
            field="action"
            minWidth={100}
            cellRendererFramework={(params) => (
              <Action params={params} viewUrl={viewUrl} editUrl={editUrl} />
            )}
          />
        </AgGridReact>
      </div>
      <div className="  absolute bottom-8 right-12  flex justify-center item-center ">
        <Pagination
          count={
            !!allCount && allCount % 10 === 0
              ? parseInt(allCount / 10)
              : parseInt(allCount / 10) + 1
          }
          showFirstButton
          showLastButton
          page={activePage}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
}

export default All;
