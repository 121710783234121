import React from "react";
import { GrView } from "react-icons/gr";
import { FiEdit } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {toast} from 'react-toastify'

function Action({ params, url, viewUrl, editUrl }) {
  const history = useHistory();

  // console.log("initialValues", initialValues);

  const fetchMerchant = async (url) => {
    try {
      const res = await axios({
        method: "GET",
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      });
     
      return res;
    } catch ({message}) {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message)
      }
    }
    
  }

  const fetchIntialValues = () => {

    //const res = await fetchMerchant(`${process.env.REACT_APP_BASE_URL}/api/orgs/${params?.data?.id}`)
    
   // console.log("params: ", params)
    let initialValues = {};

    params?.data?.addresses.length !== 0
      ? params?.data?.addresses?.map((item) => {
          initialValues = {
            description: params?.data?.description ? params?.data?.description : "",
            name: params?.data?.name ? params?.data?.name : "",
            email: params?.data?.email ? params?.data?.email : "",
            website: params?.data?.website ? params?.data?.website : "",
            phone: params?.data?.phone ? params?.data?.phone : "",
            street1: item?.street1 ? item?.street1 : "",
            street2: item?.street2 ? item?.street2 : "",
            city: item?.cityOrVillage ? item?.cityOrVillage : "",
            administrativeArea: item?.administrativeArea1
              ? item?.administrativeArea1
              : "",
            country: item?.country ? item?.country : "",
            latitude: item?.lat ? item?.lat : "",
            longitude: item?.lng ? item?.lng : "",
            state: item?.state ? item?.state : "",
            zip: item?.zipCode ? item?.zipCode : "",
          };
        })
      : (initialValues = {
          description: params?.data?.description ? params?.data?.description : "",
          name: params?.data?.name ? params?.data?.name : "",
          email: params?.data?.email ? params?.data?.email : "",
          website: params?.data?.website ? params?.data?.website : "",
          phone: params?.data?.phone ? params?.data?.phone : "",
          street1:  "",
          street2:  "",
          city: "",
          country: "",
          administrativeArea: "",
          latitude:  "",
          longitude: "",
          state:  "",
          zip:  "",
        });

    return initialValues
  }

  const Edit = async () => {
   
   const initialValues = fetchIntialValues()
   console.log("init: ", initialValues)
    history.push({
      pathname: editUrl,
      //search: "?query=abc",-
      state: { data: initialValues, id:params?.data?.id  },
    });
    return null;
  };

  const view = async () => {
    const res = await fetchMerchant(`${process.env.REACT_APP_BASE_URL}/api/orgs/${params?.data?.id}`)
    const initialValues = fetchIntialValues()
    history.push({
      pathname: viewUrl,
      //search: "?query=abc",-
      state: { data: params.data, initialValues: initialValues},
    });
  };
  return (
    <>
      <div className="flex w-16 h-10 items-center">
        <div className="border-2 border-gray-200 rounded-l-lg p-1 ">
          <GrView className="w-5 h-5 cursor-pointer " onClick={view} />
        </div>
        <div className="border-2 border-gray-200 rounded-r-lg p-1">
          <FiEdit className="w-5 h-5 cursor-pointer" onClick={Edit} />
        </div>
      </div>
    </>
  );
}

export default Action;
