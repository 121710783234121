import React from "react";
import { useLocation } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { useGet } from "../../../../../../../api/useGet";

function User() {
  const location = useLocation();
  const history = useHistory();

  const { isLoading, error, data } = useGet(
    `${process.env.REACT_APP_BASE_URL}/api/orgs/${location?.state?.data?.id}`,
    "merchantView"
  );

  return (
    <>
      <div className="w-full h_screen flex">
        <div className="w-full h-full  bg-gray-200 p-2">
          <div className="w-full h-44 bg-white rounded-sm shadow-lg  ">
            <div className="w-full h-8 flex bg-gray-100  items-center">
              <BiArrowBack
                onClick={() => history.goBack()}
                className="h-6 w-6 cursor-pointer ml-2"
              />
              <h2 className="font-bold px-6 text-base select-none">
                User details
              </h2>
            </div>

            <div className="w-full h-36 grid grid-cols-2  gap-2 ">
              <div className="w-full h-full  px-2 py-1 select-none">
                <table className="table-auto rounded border-collapse border  w-full h-full">
                  <tbody className="text-left">
                    <tr>
                      <th className="border border-gray-300 bg-gray-200 px-2">
                        Name :
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        Akash
                      </th>
                    </tr>

                    <tr>
                      <th className="border border-gray-300 bg-gray-200 px-2">
                        {" "}
                        Phone Number :
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        9973565631
                      </th>
                    </tr>
                    <tr>
                      <th className="border border-gray-300 bg-gray-200 px-2">
                        Email Id :
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        meetshannow@gmail.com
                      </th>
                    </tr>
                    <tr>
                      <th className="border border-gray-300 bg-gray-200 px-2">
                        Wallet Amount :
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        $10
                      </th>
                    </tr>
                    <tr>
                      <th className="border border-gray-300 bg-gray-200 px-2">
                        Wallet Reserved Amount :
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        $10
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="w-full h-full py-1 px-2 select-none">
                <table className="table-auto rounded border-collapse border w-full h-full">
                  <tbody className="text-left">
                    <tr>
                      <th className="border border-gray-300 bg-gray-200 px-2">
                        Flat Number :
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        Akash
                      </th>
                    </tr>

                    <tr>
                      <th className="border border-gray-300 bg-gray-200 px-2">
                        Apartment :
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        9973565631
                      </th>
                    </tr>
                    <tr>
                      <th className="border border-gray-300 bg-gray-200 px-2">
                        Locality :
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        meetshannow@gmail.com
                      </th>
                    </tr>
                    <tr>
                      <th className="border border-gray-300 bg-gray-200 px-2">
                        City :
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        $10
                      </th>
                    </tr>
                    <tr>
                      <th className="border border-gray-300 bg-gray-200 px-2">
                        Pincode :
                      </th>
                      <th className="border border-gray-300 px-2 text-gray-500">
                        $10
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* second  */}

          <div className="w-full h-1/2 mt-2 grid grid-flow-col gap-3">
            <div className="col-span-4 select-none bg-white rounded-lg p-2">
              <div className="w-full h-8 flex items-center">
                <h2 className="font-bold px-1 text-base select-none">
                  Order History
                </h2>
              </div>

              <table className="table-auto rounded border-collapse w-full  ">
                <tbody className="text-left">
                  <tr>
                    <th className="border border-gray-300 bg-gray-200 px-2">
                      Flat Number :
                    </th>
                    <th className="border border-gray-300 px-2 text-gray-500">
                      Akash
                    </th>
                  </tr>

                  <tr>
                    <th className="border border-gray-300 bg-gray-200 px-2">
                      Apartment :
                    </th>
                    <th className="border border-gray-300 px-2 text-gray-500">
                      9973565631
                    </th>
                  </tr>
                  <tr>
                    <th className="border border-gray-300 bg-gray-200 px-2">
                      Locality :
                    </th>
                    <th className="border border-gray-300 px-2 text-gray-500">
                      meetshannow@gmail.com
                    </th>
                  </tr>
                  <tr>
                    <th className="border border-gray-300 bg-gray-200 px-2">
                      City :
                    </th>
                    <th className="border border-gray-300 px-2 text-gray-500">
                      $10
                    </th>
                  </tr>
                  <tr>
                    <th className="border border-gray-300 bg-gray-200 px-2">
                      Pincode :
                    </th>
                    <th className="border border-gray-300 px-2 text-gray-500">
                      $10
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="col-span-5 select-none bg-white rounded-lg p-2">
              <div className="w-full h-8 flex items-center">
                <h2 className="font-bold px-1 text-base select-none">
                  Wallet History
                </h2>
              </div>

              <table className="table-auto rounded border-collapse w-full  ">
                <tbody className="text-left">
                  <tr>
                    <th className="border border-gray-300 bg-gray-200 px-2">
                      Flat Number :
                    </th>
                    <th className="border border-gray-300 px-2 text-gray-500">
                      Akash
                    </th>
                  </tr>

                  <tr>
                    <th className="border border-gray-300 bg-gray-200 px-2">
                      Apartment :
                    </th>
                    <th className="border border-gray-300 px-2 text-gray-500">
                      9973565631
                    </th>
                  </tr>
                  <tr>
                    <th className="border border-gray-300 bg-gray-200 px-2">
                      Locality :
                    </th>
                    <th className="border border-gray-300 px-2 text-gray-500">
                      meetshannow@gmail.com
                    </th>
                  </tr>
                  <tr>
                    <th className="border border-gray-300 bg-gray-200 px-2">
                      City :
                    </th>
                    <th className="border border-gray-300 px-2 text-gray-500">
                      $10
                    </th>
                  </tr>
                  <tr>
                    <th className="border border-gray-300 bg-gray-200 px-2">
                      Pincode :
                    </th>
                    <th className="border border-gray-300 px-2 text-gray-500">
                      $10
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {/*   third    */}

          <div className="w-full h-20 mt-2 bg-white rounded-lg p-2">
            <div className="w-full h-8 flex items-center border-b-2 border-gray-200">
              <h2 className="font-bold px-1 text-base select-none">
                Subscription History
              </h2>
            </div>
            <div className="w-full h-12 flex items-center">
              <h2>No subscription history for this user.</h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default User;
