import React, { useState } from "react";
import NonInitializedAction from "./NonInitializedAction";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "react-toggle/style.css";
import RetailerStore from "./RetailerStore";
import Select from "react-select";
import DashboardRoute from "./DashboardRoute";
import Confirm from './Confirm';


function NotInitialized({ isLoading, error, data, url, viewUrl, editUrl, sortedData }) {
  const [checked, setChecked] = useState(false);
  const [value, setValue] = useState("");

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };

  const changeHandler = (value) => {
    setValue(value);
  };
  return (
    <div className=" w-full h_das_table select-none">
      <div className="flex h-10 w-full items-center mt-2 justify-between py-8 px-4">
        <input
          className="w-2/5 focus:outline-none py-1.5 px-2 border-2 border-gray-200 rounded-lg"
          placeholder="Search"
        />
        <div style={{ width: "40%" }}>
          <Select
            className="text-left"
            placeholder="Select"
            maxMenuHeight={162}
            value={value}
            onChange={changeHandler}
            options={sortedData?.map((item, index) => {
              return {
                label: item,
                value: item,
                key: index,
              };
            })}
          />
        </div>
        <button className="bg-green-600 py-1.5 px-5 mr-7 rounded text-white">
          Clear filters
        </button>
      </div>

      <div className="ag-theme-alpine  w-full h-full text-left">
        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            resizable: true,
          }}
          suppressRowClickSelection={true}
          rowSelection={"multiple"}
          pagination={true}
          paginationPageSize={10}
          rowData={data}
        >
          <AgGridColumn
            headerName="Name"
            field="name"
            minWidth={200}
            sortable={true}
            headerCheckboxSelection={true}
            headerCheckboxSelectionFilteredOnly={true}
            checkboxSelection={true}
            cellRendererFramework={(params) => (
              <DashboardRoute params={params} />
            )}
          />
         <AgGridColumn field="deviceIdentifier" minWidth={100} sortable={true} />
          <AgGridColumn field="mode" minWidth={100} sortable={true} />
          <AgGridColumn field="macAddress" minWidth={200} sortable={true} />
           <AgGridColumn
            field="retailStore"
            minWidth={100}
            cellRendererFramework={(params) => <RetailerStore params={params} />}
          />
          <AgGridColumn
            field="status"
            minWidth={100}
            cellRendererFramework={(params) => <Confirm params={params} />}
          />
          <AgGridColumn
            headerName="Action"
            minWidth={100}
            cellRendererFramework={(params) => (
              <NonInitializedAction
                params={params}
                url={url}
                viewUrl={viewUrl}
                editUrl={editUrl}
              />
            )}
          /> 
        </AgGridReact>
      </div>
    </div>
  )
}

export default NotInitialized
