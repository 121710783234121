import React from "react";
import TableStr from "./TableStr";
// import Enabled from "./options/Enabled";
// import Disabled from "./options/Disabled";
import All from "./options/All";
import { useTranslation } from 'react-i18next'

function DynamicPrice() {
  const { t } = useTranslation()
  return (
    <div className="h-full w-full ">
    
      <TableStr
        title={'Dynamic Price'}
        url={"/admin/merchant/dashboard/productManagement/dynamicPrice/create"}
        viewUrl={"/admin/merchant/dashboard/productManagement/dynamicPrice/view"}
        editUrl={"/admin/merchant/dashboard/productManagement/dynamicPrice/edit"}
        listUrl={`${process.env.REACT_APP_BASE_URL}/price-engine-configurations?rootOrgId.equals=${parseInt(
          JSON.parse(localStorage.getItem("dashboard-data"))?.id)}`}  // &sort=createdDate,desc
        getName={"dynamicPriceList"}
        // Enabled={Enabled}
        // Disabled={Disabled}
        All={All}
        showButton={true}
        dashboard={true}
      />
    </div>
  );
}

export default DynamicPrice;
