import React, { useState, useMemo, useEffect } from "react";
import { IoIosClose } from "react-icons/io";
import { useGet } from "../../../api/useGet";
import { MdArrowUpward, MdArrowDownward } from "react-icons/md";


function MapModal(props) {
  const { open, onCloseModal } = props;
  const[sessionCart, setSessionCart] = useState([])

 
  //${process.env.REACT_APP_SHOPPER_URL}/cart-session-summary?sessionRef=${localStorage.getItem("sessionRef")}`,"Carts"
  // const cart = useGet(
  //   `${process.env.REACT_APP_SHOPPER_URL}/cart-session-summary?sessionRef=${`Rp2h6DyDh0`}`,"Carts"
  // );



  useEffect(() => {
    // setSessionCart(cart?.data?.machineCartList[0])
  },[])

  return (
    <>
      {open && (
        <div className="bg-black bg-opacity-80 absolute inset-0 z-10 flex items-center justify-center">
          <div className="w-4/5 h-4/5 shadow-lg  bg-gray-100 " style={{borderRadius:20}}>
         
           <div
              onClick={onCloseModal}
              className="absolute mt-4 ml-4 cursor-pointer z-10 shadow-lg w-8 h-8 justify-center items-center flex rounded-full bg-white"
            >
              <IoIosClose onClick={onCloseModal} className="h-8 w-8" />
            </div>
          
           {
             sessionCart ? 
             <div className="w-full h-full px-4  py-16 rounded-l-lg">
             <div className="w-full h-8 flex items-center text-left pl-1">
               <div className="w-full flex justify-between pt-3 pb-4">
                 <h2 className=" text-md ">Session Carts</h2>
                 <h2 className=" text-md  ">
                   <span>Status :</span>{" "}
                   <span className="normal-case text-blue-500">
                     {sessionCart?.status}
                   </span>
                 </h2>
               </div>

               {/* <div className="w-full flex justify-end ">
                 <div className="flex cursor-pointer mr-2">
                   <BsEyeFill className="w-6 h-6 text-blue-400" />
                   <h2 className=" text-md ml-1">Review</h2>
                 </div>

                 <div className="flex cursor-pointer">
                   <FiEdit className="w-5 h-5 text-blue-400" />
                   <h2 className=" text-md mx-0.5">Edit</h2>
                 </div>
               </div> */}
             </div>

             {/* sessionCart */}

             {sessionCart?.machineCartItems?.map((item, index) => {
               return (
                 <div className="w-full h-auto mb-1 py-1  border-t-2  border-gray-900 flex  justify-between items-center">
                   <div className="h-full flex items-center">
                     <p className={`flex text-sm items-center  ${item?.activityType === "PICK" ? `text-green-500` : `text-red-500`} `}>
                       <span>
                         {item?.activityType === "PICK" ? (
                           <MdArrowUpward className="w-5 h-5 text-green-500 " />
                         ) : (
                           <MdArrowDownward className="w-5 h-5 text-red-500 " />
                         )}
                       </span>
                       <span>{item?.productName}</span>
                     </p>
                   </div>

                   <div className="flex items-center pt-1">
                     <p className={`px-2.5 bg-gray-200 rounded-xl ${item?.activityType === "PICK" ? `text-green-500` : `text-red-500`}`}>
                       {`${item?.durationStartedAt} sec`}
                     </p>
                     <p className={`ml-2 px-2 bg-gray-200 rounded-xl  ${item?.activityType === "PICK" ? `text-green-500` : `text-red-500`}`}>
                       Qty:
                       {item?.reviewedQuantity
                         ? Math.abs(item?.reviewedQuantity)
                         : Math.abs(item?.quantity)}
                     </p>
                   </div>
                 </div>
               );
             })}
           </div>
           : <div className="w-full h-full flex items-center justify-center">
               No Record Found
           </div>
           }

            

          </div>
        </div>
      )}
    </>
  );
}

export default MapModal;
